import * as React from "react";
import {SectionListData, SectionListRenderItemInfo, StyleSheet, View} from "react-native";
import {Translations} from "../../../@types/@react-i18next.d.ts";
import {InterpreterInboxMandatePreview} from "../../../@types/activities/mandate";
import {AvailabilityButton} from "../../../components/buttons/availability";
import {SessionActionsCallback} from "../../../components/buttons/session-actions";
import {Icon, IconKey} from "../../../components/icons";
import {SectionTitle} from "../../../components/list/items/section-title";
import {SessionElement, SessionElementProps} from "../../../components/list/items/session";
import {Welcome} from "../../../components/list/items/welcome";
import {SectionList} from "../../../components/scrollables/section-list";
import {Spacer} from "../../../components/spacer";
import {StatusBar} from "../../../components/status-bar";
import {Text} from "../../../components/texts/text";
import {Column} from "../../../components/views/columns/column";
import {Columns} from "../../../components/views/columns/columns";
import {SplashView} from "../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {InboxContext} from "../../../contexts/inbox";
import {ResponsiveContext} from "../../../contexts/responsive";
import {getUnvalidatedSessions} from "../../../requests/interpreters/activities/inbox";
import {CONTAINERS, DEFAULT_SPACING, EXTRA_LARGE_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {TranslationFeedbackKey} from "../../../utils/locales/translations";
import {Log} from "../../../utils/logs/logs";
import {InterpreterHomeTabNavigatorScreenProps} from "../../../utils/navigation/paramLists/interpreter-param-list";
import {randomString} from "../../../utils/randoms";
import {capitalize} from "../../../utils/strings";
import {BACKGROUND_COLOR, SUBTLE_2} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Partialize} from "../../../utils/types";
import {Session} from "../common/modals/session";

type Item = Partialize<SessionElementProps, "session"> & {id: string};

interface Section {
	icon: IconKey;
	key: SectionKey;
}

const keyExtractor = (item: Item): string => item?.session
	? item.session.activityId
	: item.id;

type SectionKey = keyof Pick<
Translations["screens"]["home"],
"availability" | "current" | "mandate" | "unvalidated" | "upcoming"
>;

export const Inbox = ({navigation, route}: InterpreterHomeTabNavigatorScreenProps<"Inbox">): JSX.Element => {
	const {t} = useTranslation();
	const {accountId} = React.useContext(AuthentifiedContext);
	const {columns} = React.useContext(ResponsiveContext);
	const {mandates, upcomings, unvalidated, setState, current, status} = React.useContext(InboxContext);
	const [sessionId, setSessionId] = React.useState<string | null>();
	const [unvalidatedErrorMessage, setUnvalidatedErrorMessage] = React.useState<TranslationFeedbackKey | null>(null);

	const actionCallback = React.useCallback(
		(action: SessionActionsCallback, activityId: InterpreterInboxMandatePreview["activityId"]) => {
			setSessionId(null);
			if (action === "updateSessionValidationSuccess") {
				setState(prev => ({
					...prev,
					unvalidated: prev.unvalidated.filter(session => session.activityId !== activityId),
				}));
			}
		},
		[setState],
	);

	/*
	 * Displays welcome sliders if they are not in AsyncStorage
	 *
	 * React.useEffect(
	 * () => {
	 *   AsyncStorage.getItem("Sliders")
	 *    .then((slidersJSON) => {
	 *     const lastViewedVersion = JSON.parse(slidersJSON)?.welcome;
	 *     if (locale === "de-DE" && // TODO: this test need to be removed when other languages are ready!
	 *      (!lastViewedVersion || lastViewedVersion < Images.sliders.welcome.version)) {
	 *       const {screen, params} = getStackPath("Slider", {sliderKey: "welcome"});
	 *       navigation.navigate(screen, params);
	 *     }
	 *    })
	 *    .catch(Log.error());
	 * },
	 * [locale, navigation],
	 * );
	 */

	React.useEffect(
		() => navigation.addListener(
			"focus",
			() => {
				getUnvalidatedSessions(accountId!)
					.then(value => setState(prev => ({...prev, unvalidated: value})))
					.then(_ => setUnvalidatedErrorMessage(null))
					.catch(error => {
						Log.error()(error);
						setUnvalidatedErrorMessage("feedbacks:getDataFailed");
					});
			},
		),
		[accountId, navigation, setState],
	);
	React.useEffect(
		() => {
			if (!sessionId && columns > 1) {
				const displayedSessionIds: (typeof sessionId)[] = [];
				if (current) {
					displayedSessionIds.push(current.activityId);
				}
				if (upcomings?.length > 0) {
					displayedSessionIds.push(upcomings[0].activityId);
				}
				if (mandates?.length > 0) {
					displayedSessionIds.push(mandates[0].activityId);
				}
				if (unvalidated?.length > 0) {
					displayedSessionIds.push(unvalidated[0].activityId);
				}
				if (displayedSessionIds.length > 0) {
					setSessionId(displayedSessionIds[0]);
				}
			} else if (columns === 1) {
				setSessionId(null);
			}
		},
		[columns, current, mandates, sessionId, unvalidated, upcomings],
	);
	const renderSectionHeader = React.useCallback(
		({
			section: {data, key, icon},
		}: {section: SectionListData<Item, Section>}): React.ReactElement => (
			<SectionTitle
				leftComponent={<View style={styles.containerWrapper}><Icon icon={icon}/></View>}
				subtitle={key === "unvalidated" ? t("screens:home.unvalidatedWarning") : undefined}
			>
				{t(`screens:home.${key}`, {count: data.length})}
			</SectionTitle>
		),
		[t],
	);
	const renderSectionFooter = React.useCallback(
		({
			section: {data, key},
		}: {section: SectionListData<Item, Section>}): React.ReactElement | null => data.length === 0
			? (
				<View style={[CONTAINERS.MAIN, styles.emptyWrapper]}>
					<SplashView
						loading={status === "loading"}
						message={key === "unvalidated" && unvalidatedErrorMessage
							? {translationKey: unvalidatedErrorMessage, type: "error"}
							: {
								translationKey: `screens:home.${`empty${capitalize(key)}` as SectionKey}`,
								type: "info",
							}
						}
						centered={status === "loading"}
						style={{padding: 0}}
					/>
				</View>
			)
			: null,
		[unvalidatedErrorMessage, status],
	);
	const onPress = React.useCallback(
		(session: InterpreterInboxMandatePreview) => () => {
			if (columns === 1) {
				navigation.navigate("SessionModal", {sessionId: session.activityId});
			} else {
				setSessionId(session.activityId);
			}
		},
		[columns, navigation],
	);
	const renderItem = React.useCallback(
		({item: {session, ...item}}: SectionListRenderItemInfo<Item, Section>) => item
			? session
				? <SessionElement selected={sessionId === session.activityId} session={session} {...item} />
				: <AvailabilityButton/>
			: <SplashView loading/>,
		[sessionId],
	);
	const availabilitySection: SectionListData<Item, Section> = React.useMemo(
		() => ({
			data: [
				{
					id: randomString(),
					session: undefined,
				},
			],
			icon: "availability",
			key: "availability",
		}),
		[],
	);
	const currentSection: () => SectionListData<Item, Section> = React.useCallback(
		() => ({
			data: [
				{
					actionCallback: (action) => actionCallback(action, current!.activityId),
					id: current!.activityId,
					onPress: onPress(current!),
					session: current!,
					showDate: true,
					showInterpreterActions: true,
				},
			],
			icon: "calendarAlert",
			key: "current",
		}),
		[actionCallback, current, onPress],
	);
	const upcomingsSection: SectionListData<Item, Section> = React.useMemo(
		() => ({
			data: upcomings.filter(Boolean).map(session => ({
				actionCallback: (action) => actionCallback(action, session.activityId),
				id: session.activityId,
				onPress: onPress(session),
				session,
				showDate: true,
				showInterpreterActions: true,
			})),
			icon: "calendarClock",
			key: "upcoming",
		}),
		[actionCallback, onPress, upcomings],
	);
	const mandatesSection: SectionListData<Item, Section> = React.useMemo(
		() => ({
			data: mandates.map(session => ({
				actionCallback: (action) => actionCallback(action, session.activityId),
				id: session.activityId,
				onPress: onPress(session),
				session,
				showDate: true,
			})),
			icon: "calendarQuestion",
			key: "mandate",
		}),
		[actionCallback, mandates, onPress],
	);
	const unvalidatedSection: SectionListData<Item, Section> = React.useMemo(
		() => ({
			data: unvalidated.filter(Boolean).map(session => ({
				actionCallback: (action) => actionCallback(action, session.activityId),
				id: session.activityId,
				onPress: onPress(session),
				session,
				showDate: true,
				showInterpreterActions: true,
			})),
			icon: "unvalidated",
			key: "unvalidated",
		}),
		[actionCallback, onPress, unvalidated],
	);
	const activitiesColumn = React.useMemo(
		() => {
			const activities: SectionListData<Item, Section>[] = [];
			activities.push(availabilitySection);
			if (current) {
				activities.push(currentSection());
			}
			activities.push(upcomingsSection, mandatesSection);
			if ((unvalidated.length > 0) || unvalidatedErrorMessage) {
				activities.push(unvalidatedSection);
			}
			return activities;
		},
		[
			availabilitySection,
			current,
			currentSection,
			mandatesSection,
			unvalidated.length,
			unvalidatedErrorMessage,
			unvalidatedSection,
			upcomingsSection,
		],
	);
	const ItemSeparatorComponent = React.useCallback(
		() => <Spacer size={SMALL_SPACING}/>,
		[],
	);

	return (
		<>
			<StatusBar/>
			<Columns>
				{columns >= 1 && (
					<Column>
						<SectionList
							stickySectionHeadersEnabled
							renderSectionHeader={renderSectionHeader}
							renderSectionFooter={renderSectionFooter}
							renderItem={renderItem}
							contentContainerStyle={[CONTAINERS.MAIN, styles.contentContainer]}
							ItemSeparatorComponent={ItemSeparatorComponent}
							keyExtractor={keyExtractor}
							ListHeaderComponent={Welcome}
							sections={activitiesColumn}
						/>
					</Column>
				)}
				{columns >= 2 && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
						<Column style={[styles.activitiesColumn, !sessionId && styles.centeredContent]}>
							{
								sessionId
									? (
										<Session
											navigation={navigation as any}
											route={{
												key: route.key,
												name: "SessionModal",
												params: {
													actionCallback: (action: SessionActionsCallback) => actionCallback(action, sessionId),
													sessionId,
												},
											}}
										/>
									)
									: <Text type="label">{t("forms:inputs.selectSession")}</Text>
							}
						</Column>
					</>
				)}
			</Columns>
		</>
	);
};

const styles = StyleSheet.create({
	activitiesColumn: {
		backgroundColor: BACKGROUND_COLOR,
		borderColor: SUBTLE_2,
		borderWidth: 1,
		...ELEVATIONS[4],
	},
	centeredContent: {
		alignItems: "center",
		justifyContent: "center",
	},
	containerWrapper: {
		marginRight: SMALL_SPACING,
	},
	contentContainer: {
		paddingBottom: DEFAULT_SPACING,
	},
	emptyWrapper: {
		paddingBottom: EXTRA_LARGE_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
});
