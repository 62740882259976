import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {modalScreenOptions} from "../../../components/views/modal-wrapper";
import {
	InterpreterAuthentifiedScreensGroupParamList,
} from "../../../utils/navigation/paramLists/interpreter-param-list";
import {AvailabilitiesModal} from "../../screens/interpreter/modals/availabilities";
import {EditProfileModal} from "../../screens/interpreter/modals/edit-profile";
import {FollowUpModal} from "../../screens/interpreter/modals/follow-up";
import {OverlappingSessionsModal} from "../../screens/interpreter/modals/overlapping-sessions";
import {RefuseSessionModal} from "../../screens/interpreter/modals/refuse-session";
import {SalaryModal} from "../../screens/interpreter/modals/salary";
import {SelectMonthModal} from "../../screens/interpreter/modals/select-month";
import {SelectTransportModal} from "../../screens/interpreter/modals/select-transport";
import {SelectValidationIssueModal} from "../../screens/interpreter/modals/select-validation-issue";
import {SelectValidationTypeModal} from "../../screens/interpreter/modals/select-validation-type";
import {SessionValidationModal} from "../../screens/interpreter/modals/session-validation";
import {UnavailabilityModal} from "../../screens/interpreter/modals/unavailability";
import {SharedScreensGroup} from "../shared-screens-group";
import {HomeTabNavigator} from "./home-tab-navigator";

const Stack = createNativeStackNavigator<InterpreterAuthentifiedScreensGroupParamList>();
export const AuthentifiedScreensGroup = (
	<Stack.Group screenOptions={modalScreenOptions}>
		{/* CAREFUL THE ORDER OF THE SCREENS IMPACTS THE NAVIGATION!!! FIRST SCREEN = DEFAULT SCREEN */}
		<Stack.Screen name="HomeTabNavigator" component={HomeTabNavigator}/>
		<Stack.Screen name="AvailabilitiesModal" component={AvailabilitiesModal}/>
		<Stack.Screen name="EditProfileModal" component={EditProfileModal}/>
		<Stack.Screen name="FollowUpModal" component={FollowUpModal}/>
		<Stack.Screen name="OverlappingSessionsModal" component={OverlappingSessionsModal}/>
		<Stack.Screen name="RefuseSessionModal" component={RefuseSessionModal}/>
		<Stack.Screen name="SalaryModal" component={SalaryModal}/>
		<Stack.Screen name="SelectMonthModal" component={SelectMonthModal}/>
		<Stack.Screen name="SelectTransportModal" component={SelectTransportModal}/>
		<Stack.Screen name="SelectValidationIssueModal" component={SelectValidationIssueModal}/>
		<Stack.Screen name="SelectValidationTypeModal" component={SelectValidationTypeModal}/>
		<Stack.Screen name="SessionValidationModal" component={SessionValidationModal}/>
		<Stack.Screen name="UnavailabilityModal" component={UnavailabilityModal}/>
		{SharedScreensGroup}
	</Stack.Group>
);
