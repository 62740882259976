import * as React from "react";
import {View} from "react-native";
import {IncidentIssue} from "../../../../@types/validation";
import {personIdentity} from "../../../../utils/identities";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SummaryRow} from "../../../inputs/summary-row";
import {Text} from "../../../texts/text";
import {DescriptionSummary} from "./description";

export const IncidentSummary = ({
	issue: {
		description, supervision, adminReview, intervision, person,
	}, t,
}: {issue: IncidentIssue; t: TranslationFunc}): JSX.Element => (
	<View>
		{!!description && <DescriptionSummary description={description} t={t} />}
		{person && (
			<SummaryRow label={t("activities:sessions.validate.issue.type.incident.person")}>
				{personIdentity(person)}
			</SummaryRow>
		)}
		<SummaryRow label={t("activities:sessions.validate.issue.type.incident.summary.label")}>
			{adminReview && (
				<Text type="emphasis_1">
					{t("activities:sessions.validate.issue.type.incident.summary.askForAdminReview")}
				</Text>
			)}
			{intervision && (
				<Text type="emphasis_1">
					{t("activities:sessions.validate.issue.type.incident.summary.askForIntervision")}
				</Text>
			)}
			{supervision && (
				<Text type="emphasis_1">
					{t("activities:sessions.validate.issue.type.incident.summary.askForSupervision")}
				</Text>
			)}
		</SummaryRow>
	</View>
);
