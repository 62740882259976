import i18next, {TOptions} from "i18next";
import {Namespace, TFunction} from "react-i18next";
import {Translations} from "../../@types/@react-i18next.d.ts";
import {capitalize, uncapitalize} from "../strings";

export type TranslationFunc = TFunction<Namespace<keyof Translations>>;

// To use when key can't be inferred as it is directly (not using a constant key string).
export type TranslationKey = Parameters<TranslationFunc>[0];
export type TranslationOpts = TOptions | string | undefined;

export type FeedbackKey = keyof Translations["feedbacks"];
export type TranslationFeedbackKey = `feedbacks:${FeedbackKey}`;

// default translate function
export const t: TranslationFunc = (key, options) => i18next.t(key, options);

// capitalize & translate
export const ct: TranslationFunc = (key: TranslationKey, options?: TranslationOpts) => capitalize(t(key, options) as any);

// uncapitalize & translate
export const uct: TranslationFunc = (key: TranslationKey, options?: TranslationOpts) => uncapitalize(t(key, options) as any);
