import {
	CalendarSyncActivityPreview,
	InterpreterCalendarActivityPreview,
	Recurrence,
	RequesterCalendarActivityPreview,
} from "../../@types/activities/activity";
import {
	CalendarSyncSessionPreview,
	Education,
	InterpreterInboxMandatePreview,
	InterpreterMandate,
	MandateStatus,
	Mediation,
	ProvidedServiceType,
	RequesterCalendarMandatePreview,
	Standard,
} from "../../@types/activities/mandate";
import {
	Communication,
	CommunicationType,
	InPerson,
	Phone,
	SessionAction,
	SessionCancelOrReschedule,
	SessionContext,
	SessionStatus,
	Video,
} from "../../@types/activities/session";
import {UnavailabilityPreview} from "../../@types/activities/unavailability";
import {AvailabilityState} from "../../@types/availability";
import {Corporation} from "../../@types/identities/corporation";
import {Interpreter} from "../../@types/identities/interpreter";
import {PersonIdentityPreview} from "../../@types/identities/person";
import {ImageMime} from "../../@types/medias/image";
import {Origin} from "../../navigation/screens/common/modals/select-origin";
import {Transport} from "../../navigation/screens/interpreter/modals/select-transport";
import {createDate} from "../../utils/date-time/helpers";
import {Images} from "../../utils/externals/images";
import {Log} from "../../utils/logs/logs";
import {SessionLanguagesKey} from "../../utils/sessions/languages";
import {s} from "../../utils/switch";
import {BasicSessionPreview, InboxSessionList} from "../@types/activities/session";
import {BusyEventRead, BusyEventType} from "../@types/busy-event";
import {TransformCommunicationRequest} from "../@types/communication";
import {Owner} from "../@types/person";
import {BackendSessionType, BackendStatus, BookType} from "../@types/session";
import {CombinedAvailabilities} from "../common/availabilities";
import {BackendInterpreter, Type} from "../interpreters/interpreter";
import {SESSION_END_DURATION} from "./constant";
import {transformGenderBackToFront} from "./transform-common";
import {transformBackendUserToCorporation, transformOwnerToRequester} from "./transform-person";

export const transformInterpreterSessionStatusBackToFront = (status: BackendStatus): MandateStatus => {
	let sessionStatus: MandateStatus;
	switch (status) {
		case "REFUSED": {
			sessionStatus = "refused";
			break;
		}
		case "PENDING": {
			sessionStatus = "pending";
			break;
		}
		case "CONFIRMED": {
			sessionStatus = "accepted";
			break;
		}
		default: {
			sessionStatus = "outdated";
			break;
		}
	}
	return sessionStatus;
};
export const transformToCommunication = (communicationRequest: TransformCommunicationRequest): Communication => {
	const {type, address, lat, lng, channelId, token, secret, phoneNumber, addressInfos} = communicationRequest;
	let communication: Communication;
	switch (type) {
		case "inPerson": {
			const inPersonCommunication: InPerson = {
				type,
				place: {
					address,
					geocode: {lat, lng},
				},
			};
			if (addressInfos) {
				inPersonCommunication.placeInfos = addressInfos;
			}
			communication = inPersonCommunication;
			break;
		}
		case "video": {
			const videoCommunication: Video = {
				channelId,
				token,
				secret,
				type,
			};
			communication = videoCommunication;
			break;
		}
		case "phone": {
			const phoneCommunication: Phone = {
				type,
				phoneNumber,
			};
			communication = phoneCommunication;
		}
	}
	return communication;
};
export const transformSessionContextBackToFront = (context: BookType): SessionContext["type"] => {
	let type: SessionContext["type"];
	switch (context) {
		case "BOOK": {
			type = "new";
			break;
		}
		case "FOLLOWUP": {
			type = "followUp";
			break;
		}
		case "RESCHEDULE": {
			type = "reschedule";
			break;
		}
	}
	return type;
};
export const transformSessionContextFrontToBack = (context: SessionContext): BookType => {
	let result: BookType;
	switch (context.type) {
		case "new": {
			result = "BOOK";
			break;
		}
		case "followUp": {
			result = "FOLLOWUP";
			break;
		}
		case "reschedule": {
			result = "RESCHEDULE";
			break;
		}
	}
	return result;
};

export const transformSessionTypeBackToFront =
	(activityType: BackendSessionType | "PRIVATE"): CommunicationType | "private" => {
		let type: CommunicationType | "private";
		switch (activityType) {
			case "TELEPHONE": {
				type = "phone";
				break;
			}
			case "INPERSON": {
				type = "inPerson";
				break;
			}
			case "VIDEO": {
				type = "video";
				break;
			}
			case "INTERCULTURALMEDIATION": {
				type = "inPerson";
				break;
			}
			case "COURSEMANAGEMENT": {
				type = "inPerson";
				break;
			}
			case "PRIVATE": {
				type = "private";
				break;
			}
			default :
		}
		return type;
	};
export const transformSessionTypeFrontToBack =
	(sessionType: CommunicationType, service: ProvidedServiceType = "standard"): BackendSessionType => {
		let type: BackendSessionType;
		if (service === "standard") {
			switch (sessionType) {
				case "phone": {
					type = "TELEPHONE";
					break;
				}
				case "inPerson": {
					type = "INPERSON";
					break;
				}
				case "video": {
					type = "VIDEO";
					break;
				}
				default:
			}
		} else {
			type = service === "mediation" ? "INTERCULTURALMEDIATION" : "COURSEMANAGEMENT";
		}
		return type;
	};
export const transformAvailabilityBackToFront = (availability: CombinedAvailabilities): AvailabilityState => {
	if (availability?.isCurrentAvailable) {
		return "available";
	} else {
		if (availability.unavailableSession) {
			return "inSession";
		} else if (availability.unavailableEvent) {
			return "inPrivateEvent";
		} else if (availability.unavailableWeekend) {
			return "inWeekend";
		} else if (availability.unavailableNights) {
			return "inNights";
		} else if (availability.unavailableImmediate) {
			return "inEmergenciesDisabled";
		}
	}
	Log.error()("Incorrect AvailabilityState", availability);
	throw new Error("Incorrect AvailabilityState");
};
export const transformCommunicationTypeFrontToBack =
	(communicationTypes: CommunicationType[]): BackendSessionType[] => {
		const types: BackendSessionType[] = [];
		communicationTypes.forEach(type => s(type, {
			phone: () => types.push("TELEPHONE"),
			inPerson: () => types.push("INPERSON", "INTERCULTURALMEDIATION", "COURSEMANAGEMENT"),
			video: () => types.push("VIDEO"),
		}));
		return types;
	};
const transformBackendResponseToUnavailabilityPreview = (event: BusyEventRead): UnavailabilityPreview => {
	const activity: UnavailabilityPreview = {
		activityId: event.id?.toString(),
		type: "unavailability",
		subject: event.busyEvent.description,
		start: new Date(event.start),
		end: new Date(event.end),
	};
	if (event.busyEvent.location) {
		activity.place = {
			address: event.busyEvent.location,
			geocode: {lat: event.latitude, lng: event.longitude},
		};
	}
	return activity;
};
const getBasicInboxSessionPreview = (session: InboxSessionList): BasicSessionPreview => {
	const {
		sessionType, startTime, endTime, status, sessionCategory, fromLang, toLang, locationDetails,
		bookingDate, latitude, longitude, ownerTel, sessionId, sessionStartTime, sessionEndTime, channelId,
		formattedAddress, token, secret, isDeleted, multipleNotifyInterpreters,
	} = session;
	const id = session.id || sessionId;
	const isInPersonSession = sessionType === "INPERSON" || sessionType === "INTERCULTURALMEDIATION" ||
		sessionType === "COURSEMANAGEMENT";
	const address = isInPersonSession ? formattedAddress : null;
	const addressInfos = isInPersonSession ? locationDetails : null;
	let sessionStart = new Date(startTime);
	let sessionEnd = new Date(endTime);
	if (startTime === 0 || endTime === 0) {
		sessionStart = generateSessionDate(bookingDate, "start");
		sessionEnd = generateSessionDate(bookingDate, "end");
	}
	if (status === "COMPLETED") {
		sessionStart = generateCompletedSessionStartDate(startTime, sessionStartTime);
		sessionEnd = generateCompletedSessionStartDate(endTime, sessionEndTime);
	}
	sessionStart.setSeconds(0, 0);
	sessionEnd.setSeconds(0, 0);
	const transformCommunicationRequest: TransformCommunicationRequest = {
		type: transformSessionTypeBackToFront(sessionType),
		address,
		lat: latitude,
		lng: longitude,
		channelId,
		token,
		secret,
		phoneNumber: ownerTel,
		addressInfos,
	};
	const communication: Communication = transformToCommunication(transformCommunicationRequest);
	return {
		activityId: id.toString(),
		communication,
		end: sessionEnd,
		language: transformLanguageISOCodeBackToFront(fromLang),
		start: sessionStart,
		status: transformSessionStatus(isDeleted ? "DELETED" : status),
		toLanguage: transformLanguageISOCodeBackToFront(toLang),
		immediate: sessionCategory === "IMMEDIATE",
		onlyRecipient: !multipleNotifyInterpreters,
	};
};
const transformBackendResponseToCalendarSyncSessionPreview = (event: InboxSessionList): CalendarSyncSessionPreview => {
	const owner: Owner = {
		id: event.ownerId,
		firstName: event.ownerName,
		lastName: event.ownerLast,
		telephone: event.ownerTel,
		email: event.ownerMail,
	};
	return {
		...getBasicInboxSessionPreview(event),
		type: "interpreterMandate",
		providers: event.interpreter ? transformBackendInterpreterToProviders(event.interpreter) : [],
		requester: transformOwnerToRequester(owner),
		providerComment: event.purpose,
	};
};
export const transformBackendResponseToCalendarRequesterMandatePreview =
	(session: InboxSessionList): RequesterCalendarMandatePreview => {
		const owner: Owner = {
			id: session.ownerId,
			firstName: session.ownerName,
			lastName: session.ownerLast,
			telephone: session.ownerTel,
			email: session.ownerMail,
		};
		let providedService: InterpreterMandate["providedService"];
		if (session.sessionType === "INTERCULTURALMEDIATION") {
			let involvedPeople: PersonIdentityPreview[];
			try {
				involvedPeople = JSON.parse(session.personInvolvedInConversation);
			} catch {
				involvedPeople = [];
			}
			let involvedCorporations: Corporation[];
			try {
				involvedCorporations = JSON.parse(session.otherInvolvedPositions);
			} catch {
				involvedCorporations = [];
			}
			providedService = {
				involvedPeople,
				involvedCorporations,
				type: "mediation",
				reason: session.reasonForConversation,
				goal: session.goalOfConversation,
				expectation: session.expectationOfMediation,
			} as Mediation;
		} else if (session.sessionType === "COURSEMANAGEMENT") {
			providedService = {
				type: "education",
			} as Education;
		} else {
			providedService = {
				type: "standard",
			} as Standard;
		}
		const userAccountData: InterpreterMandate["mandator"] = transformBackendUserToCorporation({
			firstName: session.userAccountManager.firstName,
			lastName: session.userAccountManager.lastName,
			email: session.userAccountManager.email,
			logo: session.logo,
			orgName: session.userAccountManager.orgName,
			countryCode: session.userAccountManager.countryCode,
			telephone: session.userAccountManager.telephone,
			role: session.userAccountManager.role,
			userAccountId: session.userAccountManager.id.toString(),
			credentials: {
				userId: session.userAccountManager.id.toString(),
			},
		});

		return {
			...getBasicInboxSessionPreview(session),
			type: "interpreterMandate",
			providers: session.interpreter ? transformBackendInterpreterToProviders(session.interpreter) : [],
			excludedInterpreters: session.excludedInterpreters
				? transformBackendInterpreterToProviders(
					session.excludedInterpreters)
				: [],
			requester: transformOwnerToRequester(owner),
			providedService,
			mandator: userAccountData,
		};
	};
export const transformBackendResponseToCalendarInterpreterMandatePreview =
	(session: InboxSessionList): InterpreterInboxMandatePreview => {
		const disabledActions: SessionAction[] = [];

		if (session.isFollowUpNotAllowed || session.isUserFollowUpNotAllowed) {
			disabledActions.push("followUp");
		}

		return ({
			...getBasicInboxSessionPreview(session),
			type: "interpreterMandate",
			locked: session.locked,
			disabledActions,
		});
	};
export const transformBackendResponseToCalendarInterpreterActivityPreview =
	(session: BusyEventRead | InboxSessionList): InterpreterCalendarActivityPreview =>
		!(session as InboxSessionList).status && (session as BusyEventRead).busyEvent
			? transformBackendResponseToUnavailabilityPreview(session as BusyEventRead)
			: ({
				...getBasicInboxSessionPreview(session as InboxSessionList),
				type: "interpreterMandate",
				paid: (session as InboxSessionList).paid,
			});
export const transformBackendResponseToCalendarRequesterActivityPreview =
	(session: BusyEventRead | InboxSessionList): RequesterCalendarActivityPreview =>
		!(session as InboxSessionList).status && (session as BusyEventRead).busyEvent
			? transformBackendResponseToUnavailabilityPreview(session as BusyEventRead)
			: transformBackendResponseToCalendarRequesterMandatePreview(session as InboxSessionList);
export const transformBackendResponseToCalendarSyncActivityPreview = (interpreterResponse: any): {
	[dayKey: string]: CalendarSyncActivityPreview[]
} => {
	const eventData = interpreterResponse.isSuccess ? interpreterResponse.data : {};
	const datedActivities: {[dayKey: string]: CalendarSyncActivityPreview[]} = {};
	Object.keys(eventData).forEach((dateIndex) => {
		datedActivities[dateIndex] = interpreterResponse.data[dateIndex].map((event: any) =>
			!event.status && event.busyEvent
				? transformBackendResponseToUnavailabilityPreview(event)
				: transformBackendResponseToCalendarSyncSessionPreview(event));
	});
	return datedActivities;
};
export const transformBackendInterpreterToFrontend = (interpreter: BackendInterpreter): Interpreter => {
	const {
		id, firstName, lastName, formattedAddress, telephone, email, travelMode, languagesSkills,
		profilePicName, country, gender, city, inPersonAvailability, videoCallAvailability, voiceCallAvailability,
		mediationAvailability, courseAvailability, interpreterAvailability, latitude, longitude,
		interpreterQualificationAndModuleData, description, dob,
	} = interpreter;
	const qualifications: Interpreter["qualifications"] = interpreterQualificationBackToFront(
		interpreterQualificationAndModuleData);
	const communicationTypes: CommunicationType[] = [];
	const providedServices: ProvidedServiceType[] = ["standard"];
	if (inPersonAvailability) {
		communicationTypes.push("inPerson");
	}
	if (voiceCallAvailability) {
		communicationTypes.push("phone");
	}
	if (videoCallAvailability) {
		communicationTypes.push("video");
	}
	if (courseAvailability) {
		if (!communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		providedServices.push("education");
	}
	if (mediationAvailability) {
		if (!communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		providedServices.push("mediation");
	}
	return {
		communicationTypes,
		qualifications,
		identityId: id.toString(),
		accountId: id.toString(),
		providedServices,
		type: "interpreter",
		place: {
			address: formattedAddress || city,
			geocode: {
				lat: latitude,
				lng: longitude,
			},
		},
		fullName: {
			firstName,
			lastName,
		},
		email,
		gender: transformGenderBackToFront(gender),
		transport: travelMode === "TRANSIT" ? "Public" as Transport : "Private" as Transport,
		languages: languagesSkills.map(skill => ({
			from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
			to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
		})),
		phone: telephone,
		picture: profilePicName
			? {
				mime: `image/${profilePicName.slice(
					profilePicName.lastIndexOf(".") + 1,
					profilePicName.lastIndexOf("?"),
				)}` as ImageMime,
				sourceType: "url" as Type,
				mediaType: "image",
				source: profilePicName,
			}
			: {
				mime: "image/png",
				sourceType: "require",
				mediaType: "image",
				source: Images.Avatar,
			},
		origin: country?.iso as Origin,
		dateOfBirth: createDate(dob),
		description,
		availability: interpreterAvailability ? transformAvailabilityBackToFront(interpreterAvailability) : undefined,
	};
};
export const transformBackendInterpreterToProviders =
	(interpreters: BackendInterpreter[]): InterpreterMandate["providers"] =>
		interpreters.map((interpreter) => ({
			...transformBackendInterpreterToFrontend(interpreter),
			status: transformInterpreterSessionStatusBackToFront(interpreter.status),
		}));
export const transformRescheduleProvidersType =
	(sessionStatus: BackendStatus): SessionCancelOrReschedule["sendOnlyToCurrentProvider"] =>
		sessionStatus === "CONFIRMED" || sessionStatus === "REFUSED";
export const transformSessionStatus = (sessionStatus: BackendStatus): SessionStatus => {
	let status: SessionStatus;
	switch (sessionStatus) {
		case "CANCELLED": {
			status = "canceled";
			break;
		}
		case "LATE CANCELLATION": {
			status = "canceled";
			break;
		}
		case "LATE RESCHEDULING": {
			status = "rescheduled";
			break;
		}
		case "INPROGRESS": {
			status = "inProgress";
			break;
		}
		case "MISSED": {
			status = "unvalidated";
			break;
		}
		case "PENDING": {
			status = "sent";
			break;
		}
		default: {
			status = sessionStatus.toLowerCase() as SessionStatus;
		}
	}
	return status;
};

export const transformSessionStatusFrontToBack = (frontendStatus: SessionStatus): BackendStatus => {
	let backendStatus: BackendStatus;
	switch (frontendStatus) {
		case "canceled": {
			backendStatus = "CANCELLED";
			break;
		}
		case "inProgress": {
			backendStatus = "INPROGRESS";
			break;
		}
		case "unvalidated": {
			backendStatus = "MISSED";
			break;
		}
		case "sent": {
			backendStatus = "PENDING";
			break;
		}
		default: {
			backendStatus = frontendStatus.toUpperCase() as BackendStatus;
		}
	}
	return backendStatus;
};
export const transformRecurrenceBackToFront = (backendType: BusyEventType): string => {
	let frontendType: string;
	switch (backendType) {
		case "WEEK": {
			frontendType = "weekly";
			break;
		}
		case "MONTH": {
			frontendType = "monthly";
			break;
		}
		case "ONCE": {
			frontendType = "never";
			break;
		}
		default: {
			frontendType = backendType.toLowerCase() as Recurrence;
		}
	}
	return frontendType;
};

export const transformRecurrenceFrontToBack = (frontendType: string): BusyEventType => {
	let backendType: BusyEventType;
	switch (frontendType) {
		case "weekly": {
			backendType = "WEEK";
			break;
		}
		case "monthly": {
			backendType = "MONTH";
			break;
		}
		case "never": {
			backendType = "ONCE";
			break;
		}
		default: {
			backendType = frontendType.toUpperCase() as BusyEventType;
		}
	}
	return backendType;
};

export const transformLanguageISOCodeFrontToBack = (frontendCode: SessionLanguagesKey): string => {
	let backendCode: string;
	switch (frontendCode) {
		case "khmer": {
			backendCode = "Khmer (Cambodia)";
			break;
		}
		case "kurdish-kurmanji-(syria)": {
			backendCode = "Kurdish Kurmanji (Syria)";
			break;
		}
		case "kurdish-kurmanji-(turkey)": {
			backendCode = "Kurdish Kurmanji (Turkey)";
			break;
		}
		case "tagalog": {
			backendCode = "Tagalog (Philippines)";
			break;
		}
		case "farsi": {
			backendCode = "Farsi (Persian)";
			break;
		}
		case "azerbaijani": {
			backendCode = "az";
			break;
		}
		case "berber": {
			backendCode = "br";
			break;
		}
		case "ukrainian-russian": {
			backendCode = "Ukrainian (Russian-speaking)";
			break;
		}
		case "twi": {
			backendCode = "tw";
			break;
		}
		case "pulaar": {
			backendCode = "Peul";
			break;
		}
		case "portugese-brasil": {
			backendCode = "Portuguese (Brasil)";
			break;
		}
		case "portugese-portugal": {
			backendCode = "Portuguese (Portugal)";
			break;
		}
		case "swiss-german": {
			backendCode = "Swiss-German";
			break;
		}
		default: {
			backendCode = frontendCode.includes("-")
				? `${frontendCode.split("-")[0].charAt(0)
					.toUpperCase()}${frontendCode.split("-")[0].slice(1)} ${frontendCode.split("-")[1].charAt(0)
					.toUpperCase()}${frontendCode.split("-")[1].slice(1)}`
				: `${frontendCode.charAt(0)
					.toUpperCase()}${frontendCode.slice(1)}`;
		}
	}
	return backendCode;
};

export const transformLanguageISOCodeBackToFront = (backendCode: string): SessionLanguagesKey => {
	let frontendCode: SessionLanguagesKey;
	switch (backendCode) {
		case "Khmer (Cambodia)": {
			frontendCode = "khmer";
			break;
		}
		case "Kurdish Kurmanji (Syria)": {
			frontendCode = "kurdish-kurmanji-(syria)";
			break;
		}
		case "Kurdish Kurmanji (Turkey)": {
			frontendCode = "kurdish-kurmanji-(turkey)";
			break;
		}
		case "Tagalog (Philippines)": {
			frontendCode = "tagalog";
			break;
		}
		case "Farsi (Persian)": {
			frontendCode = "farsi";
			break;
		}
		case "az": {
			frontendCode = "azerbaijani";
			break;
		}
		case "br": {
			frontendCode = "berber";
			break;
		}
		case "Ukrainian (Russian-speaking)": {
			frontendCode = "ukrainian-russian";
			break;
		}
		case "tw": {
			frontendCode = "twi";
			break;
		}
		case "Peul": {
			frontendCode = "pulaar";
			break;
		}
		case "Portuguese (Brasil)": {
			frontendCode = "portugese-brasil";
			break;
		}
		case "Portuguese (Portugal)": {
			frontendCode = "portugese-portugal";
			break;
		}
		default: {
			frontendCode = (backendCode.includes(" ")
				? `${backendCode.split(" ")[0]}-${backendCode.split(" ")[1]}`.toLowerCase()
				: backendCode.toLowerCase()) as SessionLanguagesKey;
		}
	}
	return frontendCode;
};

export const generateSessionDate = (bookingDate: number, dateType: string) =>
	dateType === "start" ? new Date(bookingDate) : new Date(bookingDate + SESSION_END_DURATION);

export const generateCompletedSessionStartDate = (startTime: number, sessionStartTime: number): Date =>
	new Date(sessionStartTime === 0 ? startTime : sessionStartTime);

export const generateCompletedSessionEndDate = (endTime: number, sessionEndTime: number): Date =>
	new Date(sessionEndTime === 0 ? endTime : sessionEndTime);

export const interpreterQualificationBackToFront = (
	interpreterQualificationAndModuleData: {[section: string]: string[]},
) => {
	const qualifications: Interpreter["qualifications"] = {};
	if (interpreterQualificationAndModuleData && Object.keys(interpreterQualificationAndModuleData).length > 0) {
		Object.keys(interpreterQualificationAndModuleData).forEach(el => {
			qualifications[el] = interpreterQualificationAndModuleData[el].map(qualif => ({
				id: qualif,
				value: qualif,
				default: false,
				defaultIsMandatory: false,
			}));
		});
	}
	return qualifications;
};
