import {common as enUSCommon} from "../en-US/common";

export const common: typeof enUSCommon = {
	about: "a proposito",
	accept: "accettare",
	account: "conto",
	add: "aggiungere",
	address: "indirizzo",
	address_plural: "indirizzi",
	addressNumber: "numero indirizzo",
	administrator: "amministratore",
	all: "tutti",
	availability: "disponibilità",
	availability_plural: "disponibilità",
	billingAddress: "indirizzo di fatturazione",
	billingAddress_plural: "indirizzi di fatturazione",
	booked: "riservato",
	calendar: "calendario",
	call: "chiamare",
	camera: "fotocamera",
	cancel: "annullare",
	category: "categoria",
	category_plural: "categorie",
	choose: "scegliete",
	client: "cliente",
	close: "chiudere",
	combinedNumber: "numero combinato",
	communicationType: "tipo di comunicazione",
	confirm: "confermare",
	copy: "copia",
	corporation: "corporazione",
	creationDate: "data di creazione",
	crop: "ritagliare",
	date: "data",
	day: "giorno",
	day_plural: "giorni",
	delete: "elimina",
	description: "descrizione",
	detail: "dettaglio",
	detail_plural: "dettagli",
	directPhone: "telefono diretto",
	duplicate: "duplicare",
	duration: "durata",
	education: "corso",
	email: "email",
	email_plural: "email",
	enabled: "attivato",
	evamNumber: "numero EVAM",
	event: "evento",
	event_plural: "eventi",
	exitAnyway: "uscire comunque",
	export: "esportare",
	filter: "filtro",
	filter_plural: "filtri",
	firstName: "nome",
	followUp: "rintracciamento",
	fromTo: "{{from}} a {{to}}",
	fullName: "nome e cognome",
	function: "funzione",
	gender: "genere",
	gender_plural: "generi",
	general: "generale",
	group: "gruppo",
	"half-hour": "mezz'ora",
	"half-hour_plural": "mwzz'ore",
	hello: "salve",
	help: "assistenza",
	home: "casa",
	hour: "tempo",
	hour_plural: "ore",
	ignore: "ignorare",
	immediate: "immediato",
	inbox: "casella",
	institution: "istituzione",
	interpreter: "interprete",
	interpreter_plural: "interpreti",
	interpreterMandate: "mandato di interprete",
	interpretersGroups: "gruppi di interpreti",
	ippNumber: "numero IPP",
	join: "partecipa",
	language: "lingua",
	language_plural: "lingue",
	languageUI: "lingua principale",
	lastName: "cognome",
	later: "più tardi",
	leave: "lasciare",
	library: "libreria",
	link: "link",
	loading: "caricamento in corso",
	login: "login",
	"madam-abr": "sig.ra",
	man: "uomo",
	man_plural: "uomini",
	mandate: "mandato",
	mandate_plural: "mandati",
	mandator: "mandatario",
	medicalProfessional: "operatore di intervento",
	medicalProfessional_plural: "operatori di intervento",
	message: "messaggio",
	message_plural: "messaggi",
	millisecond: "millisecondo",
	millisecond_plural: "millisecondi",
	minute: "minuto",
	minute_plural: "minuti",
	"mister-abr": "sig.",
	month: "mese",
	month_plural: "mese",
	name: "nome",
	navigate: "navigare",
	new: "nuovo",
	next: "prossimo",
	notRelevant: "non rilevante",
	notification: "notifica",
	notification_plural: "notifiche",
	offline: "offline",
	ok: "ok",
	online: "online",
	option: "opzione",
	option_plural: "opzioni",
	optional: "opzionale",
	origin: "origine",
	origin_plural: "origini",
	other: "altro",
	otherProfessional: "altro professionista",
	otherProfessional_plural: "altri professionisti",
	password: "password",
	paymentAuthority: "autorità di pagamento",
	paymentAuthority_plural: "autorità di pagamento",
	person: "persona",
	person_plural: "persone",
	phone: "telefono",
	phone_plural: "telefoni",
	phonePlaceholder: "(ad es. +41 xx xxx xx xx / +49 xxx xxx xxxx)",
	preview: "anteprima",
	previous: "precedente",
	professional: "professionista",
	professional_plural: "professionisti",
	providedService: "servizio",
	qualification: "qualifica",
	qualification_plural: "qualifiche",
	"quarter-hour": "quarto d'ora",
	"quarter-hour_plural": "quarti d'ora",
	read: "leggere",
	receiver: "beneficiario",
	receiver_plural: "beneficiari",
	recover: "recuperare",
	refuse: "refuse",
	refused: "rifiutato",
	requester: "richiedente",
	requester_plural: "richiedenti",
	required: "richiesto",
	reschedule: "rinegoziare l'appuntamento",
	reset: "reset",
	restart: "riavviare",
	result: "risultato",
	result_plural: "risultati",
	retry: "riprova",
	salary: "salario",
	salary_plural: "salari",
	save: "salvare",
	search: "cercare",
	second: "secondo",
	second_plural: "secondi",
	select: "selezionare",
	send: "inviare",
	service: "servizio",
	session: "sessione",
	session_plural: "sessioni",
	sessionCategory: "categoria di sessione",
	sessionCategory_plural: "categorie di sessione",
	sessionType: "tipo di sessione",
	setting: "impostazione",
	setting_plural: "impostazioni",
	share: "quota",
	signIn: "accedere",
	signOut: "uscire",
	start: "inizio",
	status: "stato",
	stop: "fermare",
	subject: "soggetto",
	title: "titolo",
	total: "totale",
	total_plural: "totali",
	translation: "traduzione",
	transport: "mezzo di trasporto",
	transport_plural: "mezzi di trasporto",
	transportPrivate: "trasporto personale",
	transportPublic: "trasporto pubblico",
	travel: "viaggio",
	travel_plural: "viaggi",
	type: "tipo",
	unknown: "sconosciuto",
	update: "aggiornamento",
	user: "utente",
	username: "username",
	validate: "convalidare",
	week: "settimana",
	week_plural: "settimane",
	woman: "donna",
	woman_plural: "donne",
	year: "anno",
	year_plural: "anni",
};
