import {Corporation} from "../../../../@types/identities/corporation";
import {Interpreter} from "../../../../@types/identities/interpreter";
import {Person} from "../../../../@types/identities/person";
import {MedicalProfessional, OtherProfessional} from "../../../../@types/identities/professional";
import {Receiver} from "../../../../@types/identities/receiver";
import {Images} from "../../../externals/images";
import {randomlyFrom, randomNumber} from "../../../randoms";
import {placesExamples} from "../places/data";
import {
	combinedCustomLabel,
	corporationIdentityId,
	evamCustomLabel,
	interpreterIdentityId,
	ippCustomLabel,
} from "./shared-data";
import {generateQualifications} from "./utils";

export const qualificationsExamples: Interpreter["qualifications"] = {
	autres: [
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "fec", value: "Formation bureau d'état civil"},
	],
	modules: [
		{default: true, defaultIsMandatory: true, id: "m1", value: "Module 1"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m2", value: "Module 2"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m3", value: "Module 3"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m4", value: "Module 4"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m4a", value: "Module 4a"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m5", value: "Module 5"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m6", value: "Module 6"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m7", value: "Module 7"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m8", value: "Module 8"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m9", value: "Module 9"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "m10", value: "Module 10"},
	],
	"qualification INTERPRET": [
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "TRAINING", value: "Formation INTERPRET"},
		{default: randomNumber(10) < 2, defaultIsMandatory: false, id: "BREVET", value: "Brevet fédéral"},
		{
			default: randomNumber(10) < 2,
			defaultIsMandatory: false,
			id: "IC-INTERPRET",
			value: "IC avec certificat INTERPRET",
		},
	],
};

export const corporationsExamples: Corporation[] = [
	{
		accountId: corporationIdentityId,
		email: "example@mail.com",
		group: {
			logo: {
				fullWidth: true,
				height: 354,
				mediaType: "image",
				mime: "image/jpeg",
				source: "https://storage.googleapis.com/bhaasha-assets/logo_image/1_1663868880035.png",
				sourceType: "url",
				width: 1063,
			},
			name: "Bhaasha",
		},
		identityId: corporationIdentityId,
		name: "Bhaasha Dev Team",
		phone: "0041 983 23 53",
		picture: {
			mediaType: "image",
			mime: "image/png",
			source: Images.AvatarTouchable,
			sourceType: "require",
		},
		type: "corporation",
	},
];

export const interpretersExamples: Interpreter[] = [
	{
		accountId: interpreterIdentityId,
		availability: "available",
		communicationTypes: ["inPerson", "video"],
		dateOfBirth: new Date(1993, 0, 17),
		email: "mathieu@bhaasha.ch",
		fullName: {
			firstName: "Orlando",
			lastName: "Maguilla",
		},
		gender: "man",
		identityId: interpreterIdentityId,
		languages: [
			{from: "french", to: "english"},
			{from: "french", to: "german"},
			{from: "french", to: "italian"},
			{from: "french", to: "spanish"},
			{from: "french", to: "swiss-german"},
			{from: "french", to: "greek"},
			{from: "french", to: "hebrew"},
			{from: "french", to: "kurdish-kurmanji-(turkey)"},
			{from: "french", to: "chinese-cantonese"},
			{from: "french", to: "polish"},
			{from: "french", to: "japanese"},
			{from: "french", to: "tshiluba"},
			{from: "french", to: "pidgin-english"},
			{from: "french", to: "tajik"},
			{from: "german", to: "tajik"},
		],
		origin: "CH",
		phone: "0041 79 000 00 00",
		picture: {
			mediaType: "image",
			mime: "image/png",
			source: Images.Avatar,
			sourceType: "require",
		},
		place: randomlyFrom(placesExamples),
		providedServices: ["standard", "mediation", "education"],
		qualifications: generateQualifications(qualificationsExamples),
		transport: "Public",
		type: "interpreter",
	}, {
		accountId: "iqwbc91823",
		availability: "inEmergenciesDisabled",
		communicationTypes: ["inPerson", "video", "phone"],
		email: "amby.dextre@bhaasha.ch",
		fullName: {
			firstName: "Amby",
			lastName: "Dextre",
		},
		gender: "woman",
		identityId: "iqwbc91823",
		languages: [
			{from: "french", to: "english"},
			{from: "english", to: "french"},
			{from: "english", to: "italian"},
			{from: "english", to: "bosnian"},
			{from: "english", to: "spanish"},
		],
		origin: "FR",
		phone: "0041 79 123 45 67",
		picture: {
			mediaType: "image",
			mime: "image/png",
			source: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=400&q=80",
			sourceType: "url",
		},
		place: randomlyFrom(placesExamples),
		providedServices: ["standard", "mediation"],
		qualifications: generateQualifications(qualificationsExamples),
		transport: "Public",
		type: "interpreter", // unavailable
	},
	{
		accountId: "oiqf6912as",
		availability: "inSession",
		communicationTypes: ["phone"],
		email: "kylo.ren@bhaasha.ch",
		fullName: {
			firstName: "Kylo",
			lastName: "Ren",
		},
		gender: "man",
		identityId: "oiqf6912as",
		languages: [
			{from: "french", to: "italian"},
			{from: "german", to: "french"},
			{from: "german", to: "italian"},
			{from: "german", to: "spanish"},
			{from: "german", to: "azerbaijani"},
		],
		origin: "GB",
		phone: "0041 79 345 67 89",
		picture: {
			mediaType: "image",
			mime: "image/png",
			source: Images.Avatar,
			sourceType: "require",
		},
		place: randomlyFrom(placesExamples),
		providedServices: ["standard"],
		qualifications: generateQualifications(qualificationsExamples),
		transport: "Public",
		type: "interpreter", // occupied
	},
];

export const receiverExamples: Receiver[] = [
	{
		ageGroup: "child",
		combinedNumber: {label: combinedCustomLabel, value: "C5719082387"},
		dateOfBirth: new Date(1983, 12, 30),
		description: "Handy Billington is a young british",
		email: "handy.billington@bhaasha.ch",
		evamNumber: {label: evamCustomLabel, value: "E6591812"},
		fullName: {
			firstName: "Handy",
			lastName: "Billington",
		},
		gender: "man",
		identityId: "aoiubr781a",
		insurance: {
			name: "unknown",
		},
		ippNumber: {label: ippCustomLabel, value: "I56018923"},
		origin: "GB",
		phone: "0123456789",
		type: "receiver",
	}, {
		ageGroup: "adult",
		dateOfBirth: new Date(1732, 2, 22),
		email: "george.washington@bhaasha.ch",
		fullName: {
			firstName: "George",
			lastName: "Washington",
		},
		gender: "man",
		identityId: "rg2fgdfg24g",
		insurance: {
			details: "Insurance",
			name: "other",
		},
		origin: "US",
		phone: "0123456789",
		type: "receiver",
	}, {
		combinedNumber: {label: combinedCustomLabel, value: "C178273"},
		dateOfBirth: new Date(1944, 5, 14),
		email: "george.lucas@bhaasha.ch",
		fullName: {
			firstName: "George",
			lastName: "Lucas",
		},
		gender: "man",
		identityId: "34c354235",
		ippNumber: {label: ippCustomLabel, value: "I764198723"},
		origin: "US",
		phone: "0123456789",
		type: "receiver",
	}, {
		dateOfBirth: new Date(1946, 9, 5),
		email: "freddy.mercury@bhaasha.ch",
		fullName: {
			firstName: "Freddy",
			lastName: "Mercury",
		},
		gender: "man",
		identityId: "r35n54asde",
		origin: "TZ",
		phone: "0123456789",
		type: "receiver",
	}, {
		combinedNumber: {label: combinedCustomLabel, value: "C7197623"},
		dateOfBirth: new Date(1958, 3, 18),
		evamNumber: {label: evamCustomLabel, value: "E64918728"},
		gender: "woman",
		identityId: "aouoive98",
		ippNumber: {label: ippCustomLabel, value: "I123123"},
		origin: "CH",
		phone: "0123456789",
		type: "receiver",
	},
];

export const peopleExamples: Person[] = [
	{
		dateOfBirth: new Date(1940, 10, 9),
		email: "john.lennon@bhaasha.ch",
		fullName: {
			firstName: "John",
			lastName: "Lennon",
		},
		gender: "man",
		identityId: "446j57164ffdc2",
		origin: "GB",
		phone: "0123456789",
		type: "person",
	}, {
		dateOfBirth: new Date(1942, 3, 25),
		email: "aretha.franklin@bhaasha.ch",
		fullName: {
			firstName: "Aretha",
			lastName: "Franklin",
		},
		gender: "woman",
		identityId: "5691872sdkvjh",
		origin: "US",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown1@bhaasha.ch",
		gender: "woman",
		identityId: "asf1uokael",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown2@bhaasha.ch",
		gender: "man",
		identityId: "kjha79812",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown3@bhaasha.ch",
		gender: "woman",
		identityId: "hzalv78091",
		origin: "ZA",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown4@bhaasha.ch",
		gender: "woman",
		identityId: "pojdf2789",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown5@bhaasha.ch",
		gender: "man",
		identityId: "ahvi8914",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown6@bhaasha.ch",
		gender: "man",
		identityId: "zacn982148",
		phone: "0123456789",
		type: "person",
	}, {
		email: "leia.skywalker@bhaasha.ch",
		fullName: {
			firstName: "Leia",
			lastName: "Skywalker",
		},
		gender: "woman",
		identityId: "123ghjaf678",
		phone: "0123456789",
		type: "person",
	}, {
		email: "harry.potter@bhaasha.ch",
		fullName: {
			firstName: "Harry",
			lastName: "Potter",
		},
		gender: "man",
		identityId: "0714jmyziw1",
		phone: "0123456789",
		type: "person",
	}, {
		email: "unknown7@bhaasha.ch",
		gender: "woman",
		identityId: "pfa25auhc",
		phone: "0123456789",
		type: "person",
	},
];

export const medicalProfessionalsExamples: MedicalProfessional[] = [
	{
		directPhone: "0567891234",
		email: "luke.skywalker@bhaasha.ch",
		fullName: {
			firstName: "Luke",
			lastName: "Skywalker",
		},
		function: "médecin",
		gender: "man",
		identityId: "1f32r32f5215",
		institution: "La force",
		phone: "0123456789",
		type: "medicalProfessional",
	}, {
		directPhone: "0567891234",
		email: "han.solo@bhaasha.ch",
		fullName: {
			firstName: "Han",
			lastName: "Solo",
		},
		function: "médecin",
		gender: "man",
		identityId: "miuk12341cuw46",
		institution: "Solo Team",
		phone: "0123456789",
		type: "medicalProfessional",
	}, {
		dateOfBirth: new Date(1756, 1, 27),
		directPhone: "0567891234",
		email: "amadeous.mozart@bhaasha.ch",
		fullName: {
			firstName: "Amadeous",
			lastName: "Mozart",
		},
		function: "médecin",
		gender: "man",
		identityId: "tg3466728n4w6",
		institution: "Classique",
		origin: "AT",
		phone: "0123456789",
		type: "medicalProfessional",
	},
];

export const otherProfessionalsExamples: OtherProfessional[] = [
	{
		directPhone: "0567891234",
		email: "john.doe@bhaasha.ch",
		fullName: {
			firstName: "John",
			lastName: "Doe",
		},
		function: "other",
		gender: "man",
		identityId: "81j80oixuo6i",
		phone: "0123456789",
		type: "otherProfessional",
	}, {
		directPhone: "0567891234",
		email: "james.smith@bhaasha.ch",
		fullName: {
			firstName: "James",
			lastName: "Smith",
		},
		function: "Other",
		gender: "man",
		identityId: "4hj1xp0e6m88",
		phone: "0123456789",
		type: "otherProfessional",
	},
];

export const identitiesExamples = [
	...interpretersExamples,
	...receiverExamples,
	...medicalProfessionalsExamples,
	...otherProfessionalsExamples,
	...peopleExamples,
	...corporationsExamples,
];
