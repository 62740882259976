import * as React from "react";
import {StatusBar as RNStatusBar, StatusBarStyle, StyleSheet, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {IS_ANDROID, IS_WEB} from "../utils/constants";
import {SUBTLE} from "../utils/styles/colors";

interface StatusBarProps {
	backgroundColor?: string;
	barStyle?: StatusBarStyle;
}

export const StatusBar = ({backgroundColor: backgroundColorProp = SUBTLE, barStyle = "dark-content"}: StatusBarProps): JSX.Element | null => {
	const insets = useSafeAreaInsets();
	if (IS_WEB) {
		return null;
	}
	const backgroundColor = backgroundColorProp ?? SUBTLE;
	return IS_ANDROID
		? <RNStatusBar backgroundColor={backgroundColor} barStyle={barStyle}/>
		: <View style={[styles.statusBar, {backgroundColor, paddingTop: insets.top}]}/>;
};

const styles = StyleSheet.create({
	statusBar: {
		zIndex: 1000,
	},
});
