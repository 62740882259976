import * as React from "react";
import {View} from "react-native";
import {WINDOW_HEIGHT} from "../../utils/constants";

interface Props {
	backgroundColor: string;
	position: "bottom" | "top";
}

export const BounceBackground = ({backgroundColor, position}: Props): JSX.Element => (
	<View
		style={[
			{backgroundColor, height: WINDOW_HEIGHT},
			position === "top" && {marginTop: -WINDOW_HEIGHT},
			position === "bottom" && {marginBottom: -WINDOW_HEIGHT},
		]}
	/>
);
