import {Account} from "../../../@types/accounts";
import {Receiver, ReceiverPreview} from "../../../@types/identities/receiver";
import {Page, Pagination} from "../../../@types/pagination";
import {createDateString} from "../../../utils/date-time/helpers";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {IUserPatientAddResponse, UserAccountPerson, UserAccountReceiver} from "../../@types/user";
import {transformPrefixFrontToBack} from "../../utils/transform-common";
import {transformInsuranceFrontToBack, transformPatientToReceiver} from "../../utils/transform-receiver";

export type UserAcountPatient = {
	userAccount: {id: string},
	patient: UserAccountPerson,
	id?: string,
};
export type UserPatientAddAndUpdateRequests = {
	POST: {
		body: UserAcountPatient,
	},
	PUT: {
		body: UserAcountPatient,
	},
};
export type UserPatientDeleteRequests = {
	DELETE: {
		pathParams: {
			patientId: string,
		},
	},
};
export type UserPatientsGetRequests = {
	GET: {
		pathParams: {
			userAccountId: string,
		},
		queryParams: {
			searchText?: string,
			limit?: string,
			offset?: string,
		},
	},
};

export const getClientReceivers = (
	accountId: Account["accountId"], pagination: Pagination): Promise<Page<ReceiverPreview>> =>
	searchClientReceivers(accountId, "", pagination);

export const addClientReceiver = async (accountId: Account["accountId"], addedReceiver: Receiver): Promise<Receiver> => {
	const {
		gender, fullName, email, phone, origin, description, combinedNumber, evamNumber, ippNumber,
		ageGroup, insurance, dateOfBirth,
	} = addedReceiver;
	const userResponse = await api(
		"userPatientAddAndUpdate",
		"POST",
		{
			body: {
				userAccount: {id: accountId},
				patient: {
					country: {iso: origin},
					firstName: fullName?.firstName,
					lastName: fullName?.lastName,
					email,
					prefix: transformPrefixFrontToBack(gender),
					telephone: phone,
					category: description,
					combinedNumber: combinedNumber?.value,
					dob: dateOfBirth ? createDateString(dateOfBirth) : undefined,
					evamNumber: evamNumber?.value,
					ippNumber: ippNumber?.value,
					patientGroup: ageGroup ? ageGroup === "adult" ? "Adults" : "Children" : undefined,
					patientInsurance: insurance ? transformInsuranceFrontToBack(insurance.name) : undefined,
					patientOtherInsurance: insurance?.details,
				},
			},
		},
	) as ApiResponse<IUserPatientAddResponse>;

	return userResponse.isSuccess
		? Promise.resolve(transformPatientToReceiver(userResponse.data.patientMap))
		: Promise.reject(new Error(userResponse?.message));
};

export const updateClientReceiver = async (accountId: Account["accountId"], updatedReceiver: Receiver): Promise<Receiver> => {
	const {
		identityId, gender, fullName, email, phone, origin, description, combinedNumber, evamNumber, ippNumber,
		ageGroup, insurance, dateOfBirth,
	} = updatedReceiver;
	const userResponse = await api(
		"userPatientAddAndUpdate",
		"PUT",
		{
			body: {
				userAccount: {id: accountId},
				id: identityId,
				patient: {
					id: identityId,
					country: {iso: origin},
					firstName: fullName?.firstName,
					lastName: fullName?.lastName,
					email,
					prefix: transformPrefixFrontToBack(gender),
					telephone: phone,
					category: description,
					combinedNumber: combinedNumber?.value,
					dob: dateOfBirth ? createDateString(dateOfBirth) : undefined,
					evamNumber: evamNumber?.value,
					ippNumber: ippNumber?.value,
					patientGroup: ageGroup ? ageGroup === "adult" ? "Adults" : "Children" : undefined,
					patientInsurance: insurance ? transformInsuranceFrontToBack(insurance.name) : undefined,
					patientOtherInsurance: insurance?.details,
				},
			},
		},
	) as ApiResponse<{}>;
	return userResponse.isSuccess ? updatedReceiver : Promise.reject(new Error(userResponse.message));
};

export const deleteClientReceiver = async (deletedReceiver: ReceiverPreview): Promise<void> => {
	const userResponse = await api(
		"userPatientDelete",
		"DELETE",
		{
			pathParams: {
				patientId: deletedReceiver.identityId,
			},
		},
	) as ApiResponse<{}>;
	return userResponse.isSuccess ? Promise.resolve() : Promise.reject(new Error(userResponse.message));
};

export const searchClientReceivers = async (accountId: Account["accountId"], search: string, pagination: Pagination):
Promise<Page<ReceiverPreview>> => {
	const {offset, limit} = pagination;
	const userResponse = await api(
		"userPatientsGet",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				searchText: search,
				limit: limit.toString(),
				offset: (offset + 1).toString(),
			},
		},
	) as ApiResponse<{totalPatients: number, patients: UserAccountReceiver[]}>;
	if (userResponse.isSuccess) {
		const {patients, totalPatients} = userResponse.data;
		const patientList = patients.map(data => transformPatientToReceiver(data));
		return {
			items: patientList,
			hasPreviousPage: offset > 0,
			hasNextPage: totalPatients > limit + (offset - 1),
			totalCount: totalPatients,
		};
	}
	throw new Error(userResponse.message);
};
