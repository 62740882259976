import {Platform} from "react-native";
import {ELEVATION_COLOR} from "./colors";

/*
 * Elevation tends to render differently on iOS Android & Web
 * Therefore is object can control this differences once and for all
 *
 * IS_WEB cannot be used from constants because of circular dependencies which breaks
 * on the web
 */
const IS_WEB = Platform.OS === "web";
export const ELEVATIONS = {
	0: {
		elevation: 0,
		shadowColor: ELEVATION_COLOR,
		shadowOffset: {height: 0, width: 0},
		shadowOpacity: 0,
		shadowRadius: 0,
	},
	1: {
		elevation: 1,
		shadowColor: ELEVATION_COLOR,
		shadowOffset: {height: 1, width: 0},
		shadowOpacity: 0.18,
		// eslint-disable-next-line unicorn/no-zero-fractions
		shadowRadius: IS_WEB ? 1.5 : 1.00,
	},
	4: {
		elevation: 4,
		shadowColor: ELEVATION_COLOR,
		shadowOffset: {height: 2, width: 0},
		shadowOpacity: 0.23,
		shadowRadius: IS_WEB ? 4 : 2.62,
	},
	12: {
		elevation: 12,
		shadowColor: ELEVATION_COLOR,
		shadowOffset: {height: 6, width: 0},
		shadowOpacity: 0.37,
		shadowRadius: IS_WEB ? 13 : 7.49,
	},
};
