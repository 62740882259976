import React from "react";
import {Animated, StyleSheet, View} from "react-native";
import {CONTAINERS, DEFAULT_SPACING} from "../../../utils/constants";
import {PRIMARY_2, SUBTLE_2} from "../../../utils/styles/colors";
import {Button, ButtonProps} from "../../buttons/button";
import {IconKey} from "../../icons";
import {Dots, DotsProps} from "./dots";

interface PaginationButtonProps {
	backgroundColor?: string;
	contentColor?: string;
	icon?: IconKey;
	onPress?: ButtonProps["onPress"];
	text?: string;
}

export interface PaginationProps {
	animatedIndex: Animated.AnimatedInterpolation<number>;
	currentIndex: number;
	onNextPress: ButtonProps["onPress"];
	onPreviousPress: ButtonProps["onPress"];
	slidesPagination: {
		buttons?: {
			left?: PaginationButtonProps;
			right?: PaginationButtonProps;
		};
		dots?: DotsProps["slidesDots"][0];
	}[];
}

export const Pagination = ({
	slidesPagination, animatedIndex, currentIndex, onPreviousPress, onNextPress,
}: PaginationProps): JSX.Element => {
	const {buttons} = slidesPagination[currentIndex];
	return (
		<View style={[CONTAINERS.MAIN, styles.paginationContainer]}>
			<Button
				icon="back"
				iconPosition="before"
				backgroundColor={buttons?.left?.backgroundColor ?? SUBTLE_2}
				contentColor={buttons?.left?.contentColor ?? PRIMARY_2}
				disabled={currentIndex === 0}
				onPress={buttons?.left?.onPress ?? onPreviousPress}
				text={buttons?.left?.text}
				style={currentIndex === 0 && {opacity: 0}}
			/>
			<Dots
				slidesDots={slidesPagination.map(({dots}) =>
					({activeColor: PRIMARY_2, inactiveColor: SUBTLE_2, ...dots}))}
				animatedIndex={animatedIndex}
			/>
			<Button
				icon="forward"
				iconPosition="after"
				backgroundColor={buttons?.right?.backgroundColor ?? SUBTLE_2}
				contentColor={buttons?.right?.contentColor ?? PRIMARY_2}
				onPress={buttons?.right?.onPress ?? onNextPress}
				text={buttons?.right?.text}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	paginationContainer: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: DEFAULT_SPACING,
	},
});
