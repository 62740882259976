import {api} from "../../../utils/network/api";

export type UserLogoutRequests = {
	GET: {},
};
export type UserLogoutResponse = {
	data: {
		id: number,
		role: string,
	},
	message: string,
	isSuccess: boolean,
};

export const logoutClient = async (): Promise<void> => {
	const logoutResponse = await api(
		"userLogout",
		"GET",
		{},
	) as UserLogoutResponse;
	return logoutResponse.isSuccess ? Promise.resolve() : Promise.reject(new Error(logoutResponse.message));
};
