import {Account} from "../../../@types/accounts";
import {IS_MOBILE} from "../../../utils/constants";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {Platform, PreferredLanguage} from "../../@types/organisation";

export type UserLoginRequests = {
	POST: {
		body: {
			platform?: Platform,
			password: string,
			preferredLanguage: PreferredLanguage,
			userId: string,
			isMobile?: string,
		},
	},
};
export type UserLoginResponse = {
	authToken: string,
	data: {
		enableDefaultQualification: boolean,
		id: number,
		isActive: boolean,
		isDeleted: boolean,
		isMultipleBilling: boolean,
		isUserGuideUploaded: boolean,
		organization: {
			id: number,
			interpreterAvailabilityDependent: boolean,
			isActive: boolean,
			isAllBhaashaInterpreterGroupSelected: boolean,
			isCancellationDefaultInput: boolean,
			isCourseManagementSessionEnabled: boolean,
			isDeleted: boolean,
			isEnableTermsAndCondition: boolean,
			isInterculturalMediationSessionEnabled: boolean,
			isInterpreterAddressShow: boolean,
			isInterpreterEmailShow: boolean,
			isInterpreterTelephoneShow: boolean,
			isRescheduleDefaultInput: boolean,
			isUserAllowed: boolean,
			isValidationEnabled: boolean,
		},
		userAccountManager: {
			description: string,
			firstName: string,
			id: number,
			isActive: boolean,
			isDeleted: boolean,
		},
	},
	isSuccess: boolean,
	errorCode?: string,
	name: string,
	message: string,
};

export const loginClient = async (
	identifier: string, password: string, locale: SupportedLocaleKey,
): Promise< {accountId: Account["accountId"]; authToken: Account["authToken"]}> => {
	const loginResponse = await api(
		"userLogin",
		"POST",
		{
			body: {
				password,
				userId: identifier,
				preferredLanguage: locale.slice(0, 2) as PreferredLanguage,
				isMobile: IS_MOBILE ? "true" : "false",
			},
		},
	) as UserLoginResponse;
	if (loginResponse.isSuccess) {
		return {authToken: loginResponse.authToken, accountId: loginResponse.data.id.toString()};
	}
	return (loginResponse.errorCode && loginResponse.errorCode === "loginFailed")
		? Promise.reject(new Error("loginFailed"))
		: Promise.reject(new Error(loginResponse.message));
};
