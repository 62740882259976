import * as React from "react";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales} from "../../../../utils/locales/locales";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {paginate} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";

export const transports = ["Public", "Private"] as const;
export type Transport = typeof transports[number];
type ListItem = Item<"id", {displayed: string; id: Transport}>;

const SelectTransport = ({
	navigation,
	route,
}: InterpreterStackNavigatorScreenProps<"SelectTransportModal">): JSX.Element => {
	const {onSelect: onSelectParam} = route.params ?? {};
	const {ct, t} = useTranslation();
	const onSelect = (transport: Transport) => () => {
		onSelectParam?.(transport);
		navigation.dispatch(forceBack);
	};
	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", ListItem>): JSX.Element => (
		<ListElement
			onPress={onSelect(item.id)}
			buttons={buttons}
		>
			{item.displayed}
		</ListElement>
	);
	const list: ListItem[] = transports.map(transport =>
		({displayed: ct(`common:transport${transport === "Private" ? "Private" : "Public"}`), id: transport}))
		.sort((a, b) => Locales.compare(a.displayed, b.displayed));

	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectTransport")}/>
			<SelectFlatList
				getRequest={(pagination) => Promise.resolve(paginate(list, pagination))}
				renderItem={renderItem}
				itemTranslationKey="forms:inputs.selectTransport"
				idKey="id"
			/>
		</>
	);
};

export const SelectTransportModal = (props: InterpreterStackNavigatorScreenProps<"SelectTransportModal">): JSX.Element => (
	<ModalWrapper>
		<SelectTransport {...props} />
	</ModalWrapper>
);
