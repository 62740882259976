import * as React from "react";
import {Text} from "../texts/text";

export const SummaryRow = ({children, label}: {children: React.ReactNode | string; label?: string}): JSX.Element => (
	<>
		{!!label && <Text type="label">{label}</Text>}
		{typeof children === "string"
			? (
				<Text type="emphasis_1">
					{children}
				</Text>
			)
			: children}
	</>
);
