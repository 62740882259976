import {origins as enUSOrigins} from "../en-US/origins";

export const origins: typeof enUSOrigins = {
	AD: "Andorra",
	AE: "Vereinte Arabische Emirate",
	AF: "Afghanistan",
	AG: "Antigua und Barbuda",
	AI: "Anguilla",
	AL: "Albanien",
	AM: "Armenien",
	AN: "Niederländische Antillen",
	AO: "Angola",
	AQ: "Antarktis",
	AR: "Argentinien",
	AS: "Amerikanisch-Samoa",
	AT: "Österreich",
	AU: "Australien",
	AW: "Aruba",
	AX: "Åland",
	AZ: "Aserbaidschan",
	BA: "Bosnien und Herzegowina",
	BB: "Barbados",
	BD: "Bangladesch",
	BE: "Belgien",
	BF: "Burkina Faso",
	BG: "Bulgarien",
	BH: "Bahrain",
	BI: "Burundi",
	BJ: "Benin",
	BL: "Saint-Barthélemy",
	BM: "Bermuda",
	BN: "Brunei",
	BO: "Bolivien",
	BR: "Brasilien",
	BS: "Bahamas",
	BT: "Bhutan",
	BV: "Bouvetinsel",
	BW: "Botswana",
	BY: "Weißrussland",
	BZ: "Belize",
	CA: "Kanada",
	CC: "Kokosinseln",
	CD: "Demokratische Republik Kongo",
	CE: "Tschetschenien",
	CF: "Zentralafrikanische Republik",
	CG: "Kongo",
	CH: "Schweiz",
	CI: "Elfenbeinküste",
	CK: "Cookinseln",
	CL: "Chile",
	CM: "Kamerun",
	CN: "China",
	CO: "Kolumbien",
	CR: "Costa Rica",
	CS: "Serbien und Montenegro",
	CU: "Kuba",
	CV: "Kap Verde",
	CW: "Curacao",
	CY: "Zypern",
	CZ: "Tschechische Republik",
	DE: "Deutschland",
	DJ: "Dschibuti",
	DK: "Dänemark",
	DM: "Dominica",
	DO: "Dominikanische Republik",
	DZ: "Algerien",
	EC: "Ecuador",
	EE: "Estland",
	EG: "Ägypten",
	EH: "Westsahara",
	ER: "Eritrea",
	ES: "Spanien",
	ET: "Äthiopien",
	FI: "Finnland",
	FJ: "Fidschi",
	FK: "Falkland-Inseln",
	FM: "Mikronesien",
	FO: "Färöer-Inseln",
	FR: "Frankreich",
	GA: "Gabun",
	GB: "Großbritannien",
	GD: "Grenada",
	GE: "Georgien",
	GF: "Französisch-Guayana",
	GG: "Guernsey",
	GH: "Ghana",
	GI: "Gibraltar",
	GL: "Grönland",
	GM: "Gambia",
	GN: "Guinea",
	GP: "Guadeloupe",
	GQ: "Äquatorialguinea",
	GR: "Griechenland",
	GT: "Guatemala",
	GU: "Guam",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HK: "Hongkong",
	HM: "Heard und McDonaldinseln",
	HN: "Honduras",
	HR: "Kroatien",
	HT: "Haiti",
	HU: "Ungarn",
	ID: "Indonesien",
	IE: "Irland",
	IL: "Israel",
	IM: "Isle of Man",
	IN: "Indien",
	IQ: "Irak",
	IR: "Iran",
	IS: "Island",
	IT: "Italien",
	JE: "Jersey",
	JM: "Jamaika",
	JO: "Jordanien",
	JP: "Japan",
	KE: "Kenia",
	KG: "Kirgisistan",
	KH: "Kambodscha",
	KM: "Komoren",
	KN: "St. Kitts und Nevis",
	KR: "Korea",
	KW: "Kuwait",
	KY: "Kaimaninseln",
	KZ: "Kasachstan",
	LA: "Laos",
	LB: "Libanon",
	LC: "St. Lucia",
	LI: "Liechtenstein",
	LK: "Sri Lanka",
	LR: "Liberia",
	LS: "Lesotho",
	LT: "Litauen",
	LU: "Luxemburg",
	LV: "Lettland",
	LY: "Libyen",
	MA: "Marokko",
	MC: "Monaco",
	MD: "Moldawien",
	ME: "Montenegro",
	MF: "St. Martin",
	MG: "Madagaskar",
	MH: "Marshallinseln",
	MK: "Mazedonien",
	ML: "Mali",
	MM: "Myanmar",
	MN: "Mongolei",
	MO: "Macao",
	MP: "Nördliche Marianen",
	MQ: "Martinique",
	MR: "Mauretanien",
	MS: "Montserrat",
	MT: "Malta",
	MU: "Mauritius",
	MV: "Malediven",
	MW: "Malawi",
	MX: "Mexiko",
	MY: "Malaysia",
	MZ: "Mosambik",
	NA: "Namibia",
	NC: "Neukaledonien",
	NE: "Niger",
	NF: "Norfolkinsel",
	NG: "Nigeria",
	NI: "Nicaragua",
	NL: "Niederlande",
	NO: "Norwegen",
	NP: "Nepal",
	NR: "Nauru",
	NU: "Niue",
	NZ: "Neuseeland",
	OM: "Oman",
	PA: "Panama",
	PE: "Peru",
	PF: "Französisch-Polynesien",
	PG: "Papua-Neuguinea",
	PH: "Philippinen",
	PK: "Pakistan",
	PL: "Polen",
	PM: "Saint-Pierre und Miquelon",
	PN: "Pitcairninseln",
	PR: "Puerto Rico",
	PS: "Palästinensische Autonomiegebiete",
	PT: "Portugal",
	PW: "Palau",
	PY: "Paraguay",
	QA: "Katar",
	RE: "Réunion",
	RO: "Rumänien",
	RS: "Serbien",
	RU: "Russland",
	RW: "Ruanda",
	SA: "Saudi-Arabien",
	SB: "Salomon-Inseln",
	SC: "Seychellen",
	SD: "Sudan",
	SE: "Schweden",
	SG: "Singapur",
	SH: "St. Helena",
	SI: "Slowenien",
	SJ: "Svalbard und Jan Mayen",
	SK: "Slowakei",
	SL: "Sierra Leone",
	SM: "San Marino",
	SN: "Senegal",
	SO: "Somalia",
	SR: "Suriname",
	SS: "Südsudan",
	ST: "São Tomé und Príncipe",
	STAT: "Staatenlos",
	SV: "El Salvador",
	SX: "Sint Maarten",
	SY: "Syrien",
	SZ: "Swasiland",
	TB: "Tibet",
	TC: "Turks- und Caicosinseln",
	TD: "Tschad",
	TG: "Togo",
	TH: "Thailand",
	TJ: "Tadschikistan",
	TK: "Tokelau",
	TL: "Osttimor",
	TM: "Turkmenistan",
	TN: "Tunesien",
	TO: "Tonga",
	TR: "Türkei",
	TT: "Trinidad und Tobago",
	TV: "Tuvalu",
	TW: "Taiwan",
	TZ: "Tansania",
	UA: "Ukraine",
	UG: "Uganda",
	US: "Vereinigte Staaten von Amerika",
	UY: "Uruguay",
	UZ: "Usbekistan",
	VA: "Vatikanstadt",
	VC: "St. Vincent und die Grenadinen",
	VE: "Venezuela",
	VG: "Britische Jungferninseln",
	VI: "Amerikanische Jungferninseln",
	VN: "Vietnam",
	VU: "Vanuatu",
	WF: "Wallis und Futuna",
	WS: "Samoa",
	XK: "Kosovo",
	YE: "Jemen",
	YT: "Mayotte",
	ZA: "Südafrika",
	ZM: "Sambia",
	ZW: "Simbabwe",
};
