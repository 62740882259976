/* eslint-disable quote-props */
export const activities = {
	calendar: {
		abbreviation: {
			day: "d",
			hour: "h",
			minute: "m",
		},
		days: {
			friday: "friday",
			monday: "monday",
			saturday: "saturday",
			sunday: "sunday",
			thursday: "thursday",
			tuesday: "tuesday",
			wednesday: "wednesday",
		},
		exportedEvent: {
			editWarning: "This event is exported from the Bhaasha app. Any modification would be lost after the next synchronization.",
			personalCalendar: "Personal Calendar",
		},
		month: {
			april: "april",
			august: "august",
			december: "december",
			february: "february",
			january: "january",
			july: "july",
			june: "june",
			march: "march",
			may: "may",
			november: "november",
			october: "october",
			september: "september",
		},
		today: "Today · {{date}}",
	},
	event: {
		confirmDelete: "Delete this event?",
		confirmDeletes: "Delete all events (recurrence)?",
		confirmUpdate: "Update this event?",
		confirmUpdates: "Update all events (recurrence)?",
		created: "Event created",
		recurrence: {
			connector: {
				end: "until",
				every: "every",
				everyDay: "every day",
				fromDate: "from",
				fromTime: "from",
				last: "last",
				month: "of the month",
				on: "on",
				start: "from",
				toDate: "to",
				toTime: "to",
			},
			error: {
				endTime: "The end time must be after the start time",
				startTime: "The start time must be before the end time",
			},
			explanation: {
				endTime: "To display recurrences, change the end date",
				summary: "Summary",
			},
			ordinal: {
				fifth: "fifth",
				first: "first",
				fourth: "fourth",
				second: "second",
				third: "third",
			},
			type: {
				daily: "Daily",
				monthly: "Monthly",
				never: "Never",
				weekly: "Weekly",
				yearly: "Yearly",
			},
		},
	},
	price: {
		abbrev: {
			base: "Basic",
			increased: "Incr.",
			reduced: "Reduced",
			travel: {
				distance: "Distance",
				flatRate: "Flat rate",
				time: "Travel time",
				total: "Total travel",
			},
		},
		base: "Basic rate",
		increased: "Increased rate",
		reduced: "Reduced rate",
	},
	sessions: {
		accept: {
			assigned: "Thank you. You have been assigned session #{{id}}.",
			validation: "Are you sure you want to accept this session?",
			viewAction: "View session",
		},
		acceptanceTime: "Acceptance time",
		address: "Exact address",
		addressInfos: "Location details (name of the institution, building, floor, room, ...)",
		appliedFilter: "{{count}} applied filter",
		appliedFilter_plural: "{{count}} applied filters",
		call: {
			done: "Call done {{duration}} ago",
			lessOneMinute: "less than one minute", // first letter must be lowercase!
			shareDescription: "In case a participant is unable to find the video session link, use the button below to send it back to them. The link is for clients use only and should never be used by the interpreter.",
			start: "Call will start in {{duration}}",
			started: "Call has started {{duration}} ago",
		},
		cancelConfirmation: "Do you really want to cancel this session?",
		canceled: "The session has been canceled",
		canceledByInterpreter: "Cancellation due to Interpreter (no billing)",
		cancellationReason: "Cancellation reason",
		communicationTypes: {
			inPerson: "In person",
			phone: "Phone",
			video: "Video",
		},
		context: {
			confirmReset: "Are you sure you want to reset this session? All the data you already entered will be erased.",
			message: {
				subtitle: "{{user}}: {{message}}",
				title: "New message",
			},
			refused: {
				subtitle: "No interpreter available for this session",
				title: "Session refused",
			},
			reschedule: {
				subtitle: "{{user}} wants to reschedule this session",
				title: "Session rescheduled",
			},
			type: {
				explanation: "If you do not complete the session creation process, the original session will not be rescheduled.",
				withProvider: {
					followUp: "Please enter the details to send the follow-up session to {{provider}}",
					new: "Please enter the details to send the request to {{provider}}",
					reschedule: "This rescheduled session will be sent to {{provider}}",
				},
				withoutProvider: {
					followUp: "This session is a follow-up session",
					new: "This session is a new session",
					reschedule: "This session is a rescheduled session",
				},
			},
			validate: {
				subtitle: "Please validate this session",
				title: "Validate session",
			},
		},
		createNew: "Create a new session",
		created: "The session has been created",
		duplicate: {
			title: "Choose a duplication option",
			withInterpreter: "With interpreter",
			withoutInterpreter: "Without interpreter",
		},
		durations: {
			"15": "15 minutes",
			"30": "30 minutes",
			"45": "45 minutes",
			"60": "1 hour",
			"75": "1 hour 15 minutes",
			"90": "1 hour 30 minutes",
			"105": "1 hour 45 minutes",
			"120": "2 hours",
			"135": "2 hours 15 minutes",
			"150": "2 hours 30 minutes",
			"165": "2 hours 45 minutes",
			"180": "3 hours",
			"195": "3 hours 15 minutes",
			"210": "3 hours 30 minutes",
			"225": "3 hours 45 minutes",
			"240": "4 hours (half day)",
			"255": "4 hours 15 minutes",
			"270": "4 hours 30 minutes",
			"285": "4 hours 45 minutes",
			"300": "5 hours",
			"315": "5 hours 15 minutes",
			"330": "5 hours 30 minutes",
			"345": "5 hours 45 minutes",
			"360": "6 hours",
			"375": "6 hours 15 minutes",
			"390": "6 hours 30 minutes",
			"405": "6 hours 45 minutes",
			"420": "7 hours",
			"435": "7 hours 15 minutes",
			"450": "7 hours 30 minutes",
			"465": "7 hours 45 minutes",
			"480": "8 hours (full day)",
		},
		exportTitle: "Export session",
		followUp: {
			calendar: "See calendar",
			create: "Create follow-up",
			created: "Thanks. The follow-up session has been successfully created.",
			notes: "Notes & comments",
			validation: "Are you sure to create this follow up session?",
		},
		internalComment: "Internal comments (not visible to interpreter)",
		internalCommentPlaceholder: "Enter comments as needed",
		interpreterComment: "Comments to interpreter",
		interpreterCommentPlaceholder: "Number of participants, content of the session, specific terms and other information helpful to the interpreter",
		mediation: {
			expectation: "Expectations of intercultural mediation",
			goal: "Goal of the conversation",
			information: "For an intercultural mediation, a preliminary telephone discussion between the contracting specialist and the mediating person is necessary in order to enable a successful conversation. The intercultural mediator will call the contracting specialist in advance for detailed information on the facts / case and will be available for a debriefing after the interview.",
			involvedCorporations: "Other involved positions",
			involvedPeople: "Persons involved in the conversation (roles)",
			reason: "Reasons for the conversation (problem, hypothesis)",
			title: "Mediation details",
		},
		newStatus: {
			done: "The session is now in done status and was {{duration}} long",
			evaluated: "The session is now in evaluated status",
			validated: "The session is now in validated status",
		},
		price: "Price",
		providedService: {
			education: "Course",
			mediation: "Mediation",
			standard: "Interpreting",
		},
		rating: "My rating",
		refusalReason: "Refusal reason",
		refuse: {
			validation: "Are you sure you want to refuse this session?",
		},
		reschedule: {
			accepted: "The rescheduled session has been accepted",
			confirmation: "Do you really want to reschedule this session?",
			excluded: "Do not request interpreters who were excluded from the previous booking",
			reason: "Reschedule reason",
			sent: "The rescheduled session has been sent",
			toAllInterpreters: "Send the new session to all the interpreters",
			toOneInterpreter: "Send the new session only to {{interpreter}}",
		},
		sent: "The session was sent to {{nInterpreters}} interpreters",
		shareInfo: "Session shared on",
		status: {
			canceled: "Canceled",
			completed: "Completed",
			confirmed: "Confirmed",
			deleted: "Deleted",
			inProgress: "In progress",
			refused: "Refused",
			rescheduled: "Rescheduled",
			sent: "Sent",
			stopped: "Stopped",
			unvalidated: "Unvalidated",
		},
		statusType: {
			alert: "Alert",
			confirmed: "Confirmed",
			history: "History",
			sent: "Sent",
		},
		subjectPlaceholder: "What will this session be about?",
		support: {
			cancelation: "Request cancelation for personal reasons",
			deletion: "Request deletion for wrong entry",
			other: "Other support request",
			"validation-cards": "Order validation cards",
		},
		timerImmediate: {
			allRefused: "Unfortunately, no interpreters are available at this time - please select an action to continue",
			cancel: "Cancel search",
			continue: "search continues for",
			expired: "The session will soon be refused automatically",
			inProgress: "Interpreter search in progress",
			notFound: "No interpreter found",
			research: "Search with another settings",
			responses: "Responses",
			sentTo: "Sent to",
		},
		toccoBillingInformation: {
			label: "Billing relevant information",
			placeholder: "Your details will be noted in the invoice. Please note data protection!",
		},
		toccoPeopleNumber: "No. of people",
		travelFees: "Travel fees",
		validate: {
			agreement: "I confirm that the data entered are complete and correspond to the course of the session.",
			issue: {
				description: "Description",
				label: "Add a modification or an issue",
				type: {
					absentReceiver: {
						label: "Beneficiary absent",
						person: "Absent person",
						warning: "Watch out! Add the \"beneficiary absent\" issue will cause you to lose the issue you have already reported.",
						warning_plural: "Watch out! Add the \"beneficiary absent\" issue will cause you to lose the {{count}} issues you have already reported.",
					},
					cancellationTime: {
						label: "Cancellation date and time",
					},
					communicationTypeChanged: {
						changedType: "Communication mode used",
						label: "Change of communication type",
						summary: "Changed from \"{{originalType}}\" to \"{{changedType}}\"",
					},
					effectiveEndTime: {
						effectiveEndTime: "Effective end time",
						error: "The effective end time must be after the effective start time",
						explanation: "Validate the actual time, not the booked time.",
						label: "Effective end time",
					},
					effectiveStartTime: {
						effectiveStartTime: "Effective start time",
						error: "The effective start time must be before the effective end time",
						label: "Effective start time",
						warning: "If the delay is not due to the interpreter, the effective start time should remains the same as the initial scheduled time.",
					},
					feedback: {
						label: "Feedback",
					},
					incident: {
						askForAdminReview: "Administrative review",
						askForIntervision: "In intervision",
						askForSupervision: "In supervision",
						label: "Signal an incident",
						person: "Person involved",
						summary: {
							askForAdminReview: "Administrative review",
							askForIntervision: "Review in intervision",
							askForSupervision: "Review in supervision",
							label: "Ask for",
						},
					},
					label: "Type of modification or issue",
					other: {
						label: "Comments",
					},
				},
			},
			sessionId: "Session's ID",
			status: {
				canceled: "Session canceled by the requester or the beneficiary",
				completed: "Session completed",
				deleted: "Session to delete",
				label: "Targeted status",
				select: "Final session's status",
				warning: "Watch out! Changing the targeted status will cause you to lose the issue you have already reported.",
				warning_plural: "Watch out! Changing the targeted status will cause you to lose the {{count}} issues you have already reported.",
			},
			type: {
				email: "Sign by email",
				label: "Type of validation",
				none: "No signature",
				numeric: "Numeric signing",
				picture: "Sign with picture",
				select: "Desired type of validation",
			},
			validatedDuration: "Validated duration",
			validation: "Session's validation",
		},
		"validation-card-picture": "Validation card",
	},
};
