import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";

interface Props {
	flex?: boolean;
	minSize?: number;
	mode?: "horizontal" | "vertical";
	size: number;
	style?: StyleProp<ViewStyle>;
}

export const Spacer = ({mode = "horizontal", size, style, minSize, flex}: Props): JSX.Element => (
	<View
		style={[
			flex
				? {flex: size}
				: (mode === "horizontal"
					? {height: size, width: "100%"}
					: {height: "100%", width: size}),
			style,
			!!minSize && (mode === "horizontal"
				? {minHeight: minSize}
				: {minWidth: minSize}),
		]}
	/>
);
