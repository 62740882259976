import * as React from "react";
import {StyleSheet, View} from "react-native";
import {SMALL_SPACING, WEEK_HEIGHT} from "../../utils/constants";
import {OnDatePress, Week as WeekProps} from "../../utils/date-time/activities";
import {compareDays} from "../../utils/date-time/helpers";
import {Day} from "./day";

interface Props {
	focusDate?: Date;
	onDayPress: OnDatePress;
	week: WeekProps;
	width?: number;
}

export const Week = ({focusDate, week, onDayPress, width}: Props): JSX.Element => (
	<View style={[styles.container, {width}]}>
		{week.map(({activity, day, disabled, excluded, today}) => (
			<Day
				key={day.getTime()}
				day={day}
				activity={activity}
				excluded={excluded}
				today={today}
				active={focusDate ? compareDays(focusDate, day) : false}
				onDayPress={onDayPress}
				disabled={disabled}
			/>
		))}
	</View>
);

export const WeekPlaceholder = ({width}: Pick<Props, "width">): JSX.Element => (
	<View style={[styles.container, styles.placeholderWrapper, {width}]} />
);

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		height: WEEK_HEIGHT,
		paddingHorizontal: SMALL_SPACING,
	},
	placeholderWrapper: {
		alignItems: "center",
		justifyContent: "center",
	},
});
