import {CommunicationType, Session, SessionSlot} from "../../../@types/activities/session";
import {InterpreterFilters} from "../../../@types/identities/filters";
import {InterpreterPreview, WaveInterpreterProvider} from "../../../@types/identities/interpreter";
import {Place} from "../../../@types/places";
import {Origin} from "../../../navigation/screens/common/modals/select-origin";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {AvailableSlot, BackendSlot} from "../../@types/session";
import {BackendInterpreter, GetInterpreterResponse} from "../../interpreters/interpreter";
import {transformLanguageISOCodeBackToFront, transformLanguageISOCodeFrontToBack} from "../../utils/transform";
import {transformGenderBackToFront, transformGenderFrontToBack} from "../../utils/transform-common";

export type UserSlotRequests = {
	POST: {
		body: AvailableSlot,
	},
};
export type UserImmediateInterpretersRequests = {
	POST: {
		body: AvailableSlot,
	},
};

export type UserBhaashaGroupsRequests = {
	GET: {},
};
export type CommonQualificationSkillsRequests = {
	GET: {
		queryParams: {
			bhaashaGroups: string,
		},
	},
};
export type UserInterpreterDataRequests = {
	POST: {
		body: {
			latitude?: number,
			longitude?: number,
			selectedInterpreter: number[],
		},
	},
};

export const getSessionsSlots = async (
	session: Partial<Session>, filters: InterpreterFilters, every15Min: boolean, initialProvider?: InterpreterPreview,
): Promise<SessionSlot[]> => {
	const {activityId, communication, providedService, duration, language, toLanguage} = session;
	const start = new Date(session.start);
	const availabilityDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
	const gender = filters?.gender ? transformGenderFrontToBack(filters.gender) : "notRelevant";
	const origins = filters?.origins || [];
	let booleanData;
	if (providedService.type === "standard") {
		switch (communication.type) {
			case "inPerson": {
				booleanData = {inPersonAvailability: true};
				break;
			}
			case "phone": {
				booleanData = {voiceCallAvailability: true};
				break;
			}
			case "video": {
				booleanData = {videoCallAvailability: true};
			}
		}
	} else if (providedService.type === "education") {
		booleanData = {courseAvailability: true};
	} else {
		booleanData = {mediationAvailability: true};
	}
	const current = new Date();
	let bhaashaGroups: number[] = [];
	if (filters?.interpretersGroups) {
		bhaashaGroups = filters?.interpretersGroups.map(group => Number.parseInt(group.id, 10));
	}
	let backendQualifications: string[] = [];
	if (filters?.qualifications) {
		backendQualifications = filters?.qualifications.map(qual => qual.value);
	}
	const response = await api(
		"userSlot",
		"POST",
		{
			body: {
				availabilityDate: availabilityDate.getTime(),
				availableSlotsTimeTo15Min: every15Min,
				bhaashaGroups,
				booleanData: booleanData as AvailableSlot["booleanData"],
				currentDateInMills: new Date(current.getFullYear(), current.getMonth(), current.getDate()).getTime(),
				currentHours: current.getHours(),
				currentMinutes: current.getMinutes(),
				// distanceMode: "DRIVING",
				duration: duration / 60_000,
				endTime: new Date(
					availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					19,
				).getTime(),
				fromLanguage: transformLanguageISOCodeFrontToBack(language),
				fromTime: new Date(
					availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					7,
				).getTime(),
				gender,
				interpreterId: initialProvider?.identityId || "",
				isReschedued: false,
				origins,
				qualificationSkill: backendQualifications,
				rescheduledEndTime: "",
				rescheduledId: activityId || "",
				rescheduledStartTime: "",
				sessionLatitude: communication.type === "inPerson" ? communication.place.geocode.lat : 0,
				sessionLongitude: communication.type === "inPerson" ? communication.place.geocode.lng : 0,
				startTime: new Date(availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					7).getTime(),
				timeZoneOffset: availabilityDate.getTimezoneOffset(),
				toLanguage: transformLanguageISOCodeFrontToBack(toLanguage),
				toTime: new Date(availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					19).getTime(),
			},
		},
	) as ApiResponse<{availableSlots: BackendSlot[]}>;
	return response.isSuccess
		? response.data.availableSlots.map(slot => ({
			start: new Date(slot.fromTime),
			end: new Date(slot.toTime),
			availableInterpreterIds: slot.interpretersData.map(data => data.id.toString()),
		}))
		: Promise.reject(response.message);
};

export const getInterpretersByIds = async (
	ids: InterpreterPreview["accountId"][], place?: Place): Promise<WaveInterpreterProvider[]> => {
	const response = await api(
		"userInterpreterData",
		"POST",
		{
			body: {
				latitude: place ? place.geocode.lat : undefined,
				longitude: place ? place.geocode.lng : undefined,
				selectedInterpreter: ids.map(id => Number.parseInt(id, 10)),
			},
		},
	) as ApiResponse<{interpreters: BackendInterpreter[]}>;
	if (response.isSuccess) {
		const {interpreters} = response.data;
		return interpreters.map(interpreter => {
			const {
				id, firstName, lastName, telephone, email, languagesSkills, country, gender,
				inPersonAvailability, videoCallAvailability, voiceCallAvailability,
				mediationAvailability, courseAvailability, wave,
			} = interpreter;
			const communicationTypes: CommunicationType[] = [];
			if (inPersonAvailability) {
				communicationTypes.push("inPerson");
			}
			if (voiceCallAvailability) {
				communicationTypes.push("phone");
			}
			if (videoCallAvailability) {
				communicationTypes.push("video");
			}
			if (courseAvailability && !communicationTypes.includes("inPerson")) {
				communicationTypes.push("inPerson");
			}
			if (mediationAvailability && !communicationTypes.includes("inPerson")) {
				communicationTypes.push("inPerson");
			}
			return {
				wave: wave === "ONE" ? "first" : "last",
				communicationTypes,
				identityId: id.toString(),
				accountId: id.toString(),
				providedServices: ["standard"],
				type: "interpreter",
				fullName: {
					firstName,
					lastName,
				},
				email,
				gender: transformGenderBackToFront(gender),
				languages: languagesSkills
					.map(skill => ({
						from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
						to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
					})),
				phone: telephone,
				origin: country?.iso as Origin,
			};
		});
	}
	throw new Error(response.message);
};

export const getInterpretersImmediate = async (
	session: Partial<Session>, filters: InterpreterFilters, initialProvider?: InterpreterPreview,
): Promise<WaveInterpreterProvider[]> => {
	const {communication, providedService, duration, language, toLanguage} = session;
	const {interpretersGroups, qualifications} = filters;
	const start = new Date();
	const availabilityDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
	const gender = filters?.gender ? transformGenderFrontToBack(filters.gender) : "notRelevant";
	const origins = filters?.origins || [];
	let booleanData;
	if (providedService.type === "standard") {
		switch (communication.type) {
			case "inPerson": {
				booleanData = {inPersonAvailability: true};
				break;
			}
			case "phone": {
				booleanData = {voiceCallAvailability: true};
				break;
			}
			case "video": {
				booleanData = {videoCallAvailability: true};
			}
		}
	} else if (providedService.type === "education") {
		booleanData = {courseAvailability: true};
	} else {
		booleanData = {mediationAvailability: true};
	}
	const current = new Date();
	let bhaashaGroups: number[] = [];
	if (interpretersGroups) {
		bhaashaGroups = interpretersGroups.map(group => Number.parseInt(group.id, 10));
	}
	let backendQualifications: string[] = [];
	if (qualifications) {
		backendQualifications = qualifications.map(qual => qual.value);
	}
	const response = await api(
		"userImmediateInterpreters",
		"POST",
		{
			body: {
				availabilityDate: availabilityDate.getTime(),
				bhaashaGroups,
				booleanData: booleanData as AvailableSlot["booleanData"],
				currentDateInMills: new Date(current.getFullYear(), current.getMonth(), current.getDate()).getTime(),
				currentHours: current.getHours(),
				currentMinutes: current.getMinutes(),
				duration: duration / 60_000,
				endTime: new Date(
					availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					19,
				).getTime(),
				fromLanguage: transformLanguageISOCodeFrontToBack(language),
				gender,
				interpreterId: initialProvider?.identityId || "",
				isReschedued: false,
				origins,
				qualificationSkill: backendQualifications,
				rescheduledEndTime: "",
				rescheduledId: "",
				rescheduledStartTime: "",
				sessionLatitude: communication.type === "inPerson" ? communication.place.geocode.lat : 0,
				sessionLongitude: communication.type === "inPerson" ? communication.place.geocode.lng : 0,
				startTime: new Date(availabilityDate.getFullYear(),
					availabilityDate.getMonth(),
					availabilityDate.getDate(),
					7).getTime(),
				timeZoneOffset: availabilityDate.getTimezoneOffset(),
				toLanguage: transformLanguageISOCodeFrontToBack(toLanguage),
			},
		},
	) as ApiResponse<GetInterpreterResponse[]>;
	if (response.isSuccess) {
		return response.data.map(interpreter => {
			const {
				id, firstName, lastName, telephone, email, languagesSkills, country, gender,
				inPersonAvailability, videoCallAvailability, voiceCallAvailability,
				mediationAvailability, courseAvailability, wave,
			} = interpreter;
			const communicationTypes: CommunicationType[] = [];
			if (inPersonAvailability) {
				communicationTypes.push("inPerson");
			}
			if (voiceCallAvailability) {
				communicationTypes.push("phone");
			}
			if (videoCallAvailability) {
				communicationTypes.push("video");
			}
			if (courseAvailability && !communicationTypes.includes("inPerson")) {
				communicationTypes.push("inPerson");
			}
			if (mediationAvailability && !communicationTypes.includes("inPerson")) {
				communicationTypes.push("inPerson");
			}
			return {
				wave: wave === "ONE" ? "first" : "last",
				communicationTypes,
				identityId: id.toString(),
				accountId: id.toString(),
				providedServices: ["standard"],
				type: "interpreter",
				fullName: {
					firstName,
					lastName,
				},
				email,
				gender: transformGenderBackToFront(gender),
				languages: languagesSkills
					.map(skill => ({
						from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
						to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
					})),
				phone: telephone,
				origin: country?.iso as Origin,
			};
		});
	}
	throw new Error(response.message);
};
