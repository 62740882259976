import * as React from "react";
import {LayoutChangeEvent, StyleSheet, View} from "react-native";
import {CONTENT_CONTAINER_MAX_WIDTH, IS_MOBILE, IS_WEB, VERTICAL_MENU_WIDTH} from "../utils/constants";
import {infos} from "../utils/infos";

const maxColumnWidth = CONTENT_CONTAINER_MAX_WIDTH;
const minColumnWidth = CONTENT_CONTAINER_MAX_WIDTH - 100;

export interface ResponsiveContextVal {
	columns: 1 | 2 | 3;
	contentWidth: number;
	displayMainMenu: boolean;
	height: number;
	likelyDevice: "computer" | "phone" | "tablet";
	mobileDisplay: boolean;
	verticalMenu: boolean;
	width: number;
}
const defaultVal: ResponsiveContextVal = {
	columns: 1,
	contentWidth: maxColumnWidth,
	displayMainMenu: false,
	height: 0,
	likelyDevice: "phone",
	mobileDisplay: true,
	verticalMenu: false,
	width: 0,
};

export const ResponsiveContext = React.createContext<ResponsiveContextVal>(defaultVal);
export const ResponsiveProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
	const [responsive, setResponsive] = React.useState<ResponsiveContextVal>(defaultVal);
	const onLayout = React.useCallback(
		({nativeEvent: {layout: {width, height}}}: LayoutChangeEvent) => {
			const isThreeColumn = (width - VERTICAL_MENU_WIDTH) >= minColumnWidth * 3;
			const isTwoColumn = !isThreeColumn && (width - VERTICAL_MENU_WIDTH) >= minColumnWidth * 2;
			const isOneColumn = !isThreeColumn && !isTwoColumn;
			setResponsive({
				columns: isOneColumn ? 1 : isTwoColumn ? 2 : 3,
				contentWidth: width <= maxColumnWidth * 3 + VERTICAL_MENU_WIDTH
					? isOneColumn
						? width
						: width - VERTICAL_MENU_WIDTH
					: maxColumnWidth * 3,
				displayMainMenu: !isOneColumn || IS_WEB,
				height,
				likelyDevice: isOneColumn
					? "phone"
					: isTwoColumn
						? "tablet"
						: "computer",
				mobileDisplay: isOneColumn || IS_MOBILE || infos["os.name"] === "Android" || infos["os.name"] === "iOS" || infos["os.name"] === "iPadOS",
				verticalMenu: !isOneColumn,
				width,
			});
		},
		[],
	);
	return (
		<ResponsiveContext.Provider value={responsive}>
			<View onLayout={onLayout} style={styles.wrapper}>
				{children}
			</View>
		</ResponsiveContext.Provider>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		flex: 1,
	},
});
