import {Activity, ActivityCommon} from "../../../../@types/activities/activity";
import {ActivitiesFilters} from "../../../../@types/activities/filters";
import {SessionStatus} from "../../../../@types/activities/session";

export const fakeActivitySort = (a: ActivityCommon, b: ActivityCommon): number => a.start <= b.start ? -1 : 1;

export const fakeActivityFilters = (activity: Activity, filters: ActivitiesFilters): boolean => {
	const activeFilters: {[key in keyof ActivitiesFilters]: boolean} = {};
	const status: SessionStatus[] = filters.status ? [...filters.status] : [];

	if (filters.id) activeFilters.id = activity.activityId === filters.id;
	if (activity.type === "unavailability") return true;
	if (activity.type === "interpreterMandate") {
		if (filters.communicationTypes) activeFilters.communicationTypes = filters.communicationTypes.includes(activity.communication.type);
		if (filters.mandator) activeFilters.mandator = activity.mandator.identityId === filters.mandator.identityId;
		if (filters.provider) activeFilters.provider = activity.providers.map(p => p.identityId).includes(filters.provider.identityId);
		if (filters.requester) activeFilters.requester = activity.requester.identityId === filters.requester.identityId;
		if (filters.receiver) activeFilters.receiver = activity.receiver?.identityId === filters.receiver.identityId;
		if (filters.showCanceledSessions) status.push("rescheduled", "canceled");
		if (filters.start) activeFilters.toLanguage = activity.start > filters.start;
		if (filters.status) activeFilters.status = [...status, ...filters.status].includes(activity.status);
		if (filters.toLanguage) activeFilters.toLanguage = activity.toLanguage === filters.toLanguage;
	}
	// eslint-disable-next-line unicorn/no-array-reduce
	return Object.values(activeFilters).reduce((f1, f2) => f1 && f2, true);
};
