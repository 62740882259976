import * as React from "react";
import {BillingAddress} from "../../../../@types/billing-address";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

const EditBillingAddress = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"EditBillingAddressModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {selectionOrBase: itemParam, onSelect} = route.params ?? {};
	const base = React.useMemo(
		() => itemParam ?? {} as BillingAddress,
		[itemParam],
	);
	const {loading: loadingForm, displayed, isUpdated, setUpdated, errorMessage} = useForm(base);
	const {address, department, name, postalCode, postOfficeBox, town} = displayed;

	const addOrUpdateTitle = itemParam
		? ct("forms:items.update", {item: t("common:billingAddress")})
		: ct("forms:items.add", {item: t("common:billingAddress")});

	const header = <HeaderMenu center={addOrUpdateTitle} exitConfirmation={isUpdated}/>;
	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}
	const inputs: FormProps["inputs"] = [
		{
			icon: "corporation",
			label: t("entities:billingAddress.name"),
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, name: value} satisfies BillingAddress)),
				value: name,
			},
		}, {
			icon: "category",
			label: t("entities:billingAddress.department"),
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, department: value} satisfies BillingAddress)),
				value: department,
			},
		}, {
			icon: "inbox",
			label: t("entities:billingAddress.postOfficeBox"),
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, postOfficeBox: value} satisfies BillingAddress)),
				value: postOfficeBox,
			},
		}, {
			icon: "place",
			label: t("entities:billingAddress.address"),
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, address: value} satisfies BillingAddress)),
				value: address,
			},
		}, {
			icon: "mail",
			label: t("entities:billingAddress.postalCode"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, postalCode: value} satisfies BillingAddress)),
				value: postalCode,
			},
		}, {
			icon: "home",
			label: t("entities:billingAddress.town"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, town: value} satisfies BillingAddress)),
				value: town,
			},
		},
	];

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: addOrUpdateTitle},
					onValidation: () => {
						onSelect?.(displayed);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const EditBillingAddressModal = (props: ClientStackNavigatorScreenProps<"EditBillingAddressModal">): JSX.Element => (
	<ModalWrapper>
		<EditBillingAddress {...props} />
	</ModalWrapper>
);
