import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";
import {SUBTLE_2} from "../../utils/styles/colors";
import {Separator} from "../separator";

export interface ListWrapperProps {
	bottomLine?: boolean;
	children?: React.ReactElement[];
	style?: StyleProp<ViewStyle>;
	topLine?: boolean;
}

export const ListInputWrapper = ({children, topLine = true, bottomLine = true, style}: ListWrapperProps): JSX.Element => {
	const renderSeparator = (size: number): JSX.Element => <Separator spacingSize={0} borderSize={size} color={SUBTLE_2} />;
	// The <View> is necessary for focus animation purposes
	return (
		<View style={style}>
			{children?.map((child, index) => {
				const preview = child.props?.preview;
				const first = index === 0;
				const last = index === children.length - 1;
				const firstInGroup = first || children[index - 1].key?.toString().includes("spacer");
				const lastInGroup = last || children[index + 1].key?.toString().includes("spacer");

				const separator = child.key?.toString().includes("spacer");
				return (
					<View key={child.key}>
						{topLine && firstInGroup && !separator && !preview && renderSeparator(1)}
						{child}
						{(bottomLine || !lastInGroup) && !separator && !preview && renderSeparator(1)}
					</View>
				);
			})}
		</View>
	);
};
