/* eslint-disable quote-props */
import {activities as enUSActivities} from "../en-US/activities";

export const activities: typeof enUSActivities = {
	calendar: {
		abbreviation: {
			day: "g",
			hour: "o",
			minute: "m",
		},
		days: {
			friday: "venerdì",
			monday: "lunedì",
			saturday: "sabato",
			sunday: "domenica",
			thursday: "giovedì",
			tuesday: "martedì",
			wednesday: "mercoledì",
		},
		exportedEvent: {
			editWarning: "Questo evento è esportato dall'applicazione Bhaasha. Qualsiasi modifica manuale all'evento sarà persa dopo la prossima sincronizzazione",
			personalCalendar: "Calendario personale",
		},
		month: {
			april: "aprile",
			august: "agosto",
			december: "dicembre",
			february: "febbraio",
			january: "gennaio",
			july: "luglio",
			june: "giugno",
			march: "marzo",
			may: "maggio",
			november: "novembre",
			october: "ottobre",
			september: "settembre",
		},
		today: "oggi - {{date}}",
	},
	event: {
		confirmDelete: "Cancellare questo evento?",
		confirmDeletes: "Cancellare tutti gli eventi (ricorrenza)?",
		confirmUpdate: "Aggiornare questo evento?",
		confirmUpdates: "Aggiornare tutti gli eventi (ricorrenza)?",
		created: "Evento creato",
		recurrence: {
			connector: {
				end: "fino a",
				every: "ogni",
				everyDay: "ogni giorno",
				fromDate: "dal",
				fromTime: "dalle",
				last: "ultimo",
				month: "del mese",
				on: "il",
				start: "dal momento che",
				toDate: "al",
				toTime: "alle",
			},
			error: {
				endTime: "L'ora di fine deve essere successiva all'ora di inizio",
				startTime: "L'ora di inizio deve essere precedente all'ora di fine",
			},
			explanation: {
				endTime: "Per visualizzare le ricorrenze, modificare la data finale",
				summary: "Sintesi",
			},
			ordinal: {
				fifth: "quinto",
				first: "primo",
				fourth: "quarto",
				second: "secondo",
				third: "terzo",
			},
			type: {
				daily: "Quotidiano",
				monthly: "Mensile",
				never: "Mai",
				weekly: "Settimanale",
				yearly: "Annuale",
			},
		},
	},
	price: {
		abbrev: {
			base: "Base",
			increased: "Suppl.",
			reduced: "Ridotto",
			travel: {
				distance: "Distanza di viaggio",
				flatRate: "Forfettario",
				time: "Tempo di viaggio",
				total: "Total spostamento",
			},
		},
		base: "Tasso di base",
		increased: "Tasso supplementare",
		reduced: "Tariffa ridotta",
	},
	sessions: {
		accept: {
			assigned: "Grazie. Vi è stata assegnata la sessione #{{id}}.",
			validation: "Seguire questa sessione?",
			viewAction: "Visualizzare sessione",
		},
		acceptanceTime: "Tempo di accettazione",
		address: "Indirizzo esatto",
		addressInfos: "Dettagli luogo (nome dell'istituzione, stabile, piano, sala, ...)",
		appliedFilter: "{{count}} filtro applicato",
		appliedFilter_plural: "{{count}} filtri applicati",
		call: {
			done: "Chiamata fatta {{duration}} fa",
			lessOneMinute: "Meno di un minuto", // first letter must be lowercase!
			shareDescription: "Se un partecipante non riesce a trovare il link alla sessione video, utilizzare il pulsante sottostante per inviarglielo. Questo link è riservato ai clienti e non deve essere utilizzato dall'interprete in nessun caso.",
			start: "La chiamata inizierà tra {{duration}}",
			started: "La chiamata è iniziata {{duration}} fa",
		},
		cancelConfirmation: "Vuoi davvero cancellare questa sessione?",
		canceled: "La sessione è stata annullata",
		canceledByInterpreter: "Cancellazione a causa dell'interprete (nessuna fatturazione)",
		cancellationReason: "Motivo della cancellazione",
		communicationTypes: {
			inPerson: "In persona",
			phone: "Audio",
			video: "Video",
		},
		context: {
			confirmReset: "Sei sicuro di voler resettare questa sessione? Tutti i dati che hai già inserito saranno cancellati.",
			message: {
				subtitle: "{{user}}: {{message}}",
				title: "Nuovo messaggio",
			},
			refused: {
				subtitle: "Nessun interprete disponibile per questa sessione",
				title: "Sessione rifiutata",
			},
			reschedule: {
				subtitle: "{{user}} vuole riprogrammare questa sessione",
				title: "Sessione riprogrammata",
			},
			type: {
				explanation: "Se non completate il processo di creazione della sessione, la sessione originale non sarà riprogrammata.",
				withProvider: {
					followUp: "Inserisci i dati per inviare la sessione di follow-up a {{provider}}",
					new: "Inserire i dati per inviare la richiesta a {{provider}}",
					reschedule: "Questa sessione riprogrammata sarà inviata a {{provider}}",
				},
				withoutProvider: {
					followUp: "Questa sessione è una sessione di follow-up",
					new: "Questa sessione è una nuova sessione",
					reschedule: "Questa sessione è una sessione riprogrammata",
				},
			},
			validate: {
				subtitle: "Si prega di convalidare questa sessione",
				title: "Convalidare la sessione",
			},
		},
		createNew: "Crea una nuova sessione",
		created: "La sessione è stata creata",
		duplicate: {
			title: "Selezionare un'opzione di duplicazione",
			withInterpreter: "Con interprete",
			withoutInterpreter: "Senza interprete",
		},
		durations: {
			"15": "15 minuti",
			"30": "30 minuti",
			"45": "45 minuti",
			"60": "1 ora",
			"75": "1 ora 15 minuti",
			"90": "1 ora 30 minuti",
			"105": "1 ora 45 minuti",
			"120": "2 ore",
			"135": "2 ore 15 minuti",
			"150": "2 ore 30 minuti",
			"165": "2 ore 45 minuti",
			"180": "3 ore",
			"195": "3 ore 15 minuti",
			"210": "3 ore 30 minuti",
			"225": "3 ore 45 minuti",
			"240": "4 ore (mezza giornata)",
			"255": "4 ore 15 minuti",
			"270": "4 ore 30 minuti",
			"285": "4 ore 45 minuti",
			"300": "5 ore",
			"315": "5 ore 15 minuti",
			"330": "5 ore 30 minuti",
			"345": "5 ore 45 minuti",
			"360": "6 ore",
			"375": "6 ore 15 minuti",
			"390": "6 ore 30 minuti",
			"405": "6 ore 45 minuti",
			"420": "7 ore",
			"435": "7 ore 15 minuti",
			"450": "7 ore 30 minuti",
			"465": "7 ore 45 minuti",
			"480": "8 ore (giornata intera)",
		},
		exportTitle: "Sessione export",
		followUp: {
			calendar: "Vedere il calendario",
			create: "Creare un follow-up",
			created: "Grazie. La sessione di follow-up è stata creata con successo.",
			notes: "Note e commenti",
			validation: "Creare o non creare questa sessione di follow-up?",
		},
		internalComment: "Commenti interni (non visibile all'interprete)",
		internalCommentPlaceholder: "Inserire i commenti necessari",
		interpreterComment: "Commenti per l'interprete",
		interpreterCommentPlaceholder: "Numero di partecipanti, contenuto della sessione, termini specifici e altre informazioni utili all'interprete",
		mediation: {
			expectation: "Aspettative di mediazione interculturale",
			goal: "Scopo della conversazione",
			information: "Per una mediazione interculturale, è necessaria una discussione telefonica preliminare tra lo specialista contraente e la persona mediatrice al fine di consentire una conversazione di successo. Il mediatore interculturale chiamerà in anticipo lo specialista contrattuale per informazioni dettagliate sui fatti / caso e sarà disponibile per un debriefing dopo il colloquio.",
			involvedCorporations: "Altri organismi coinvolti",
			involvedPeople: "Persone coinvolte nella conversazione (ruoli)",
			reason: "Motivo della conversazione (definizione del problema, ipotesi)",
			title: "Dettagli della mediazione",
		},
		newStatus: {
			done: "La sessione è ora in stato di completamento ed è durata 15 minuti {{duration}}",
			evaluated: "La sessione è ora in stato di valutazione",
			validated: "La sessione è ora in stato di validazione",
		},
		price: "Tasse",
		providedService: {
			education: "Corso",
			mediation: "Mediazione",
			standard: "Interpretazione",
		},
		rating: "La mia valutazione",
		refusalReason: "Motivo del rifiuto",
		refuse: {
			validation: "Rifiutare sul serio questa sessione?",
		},
		reschedule: {
			accepted: "La sessione riprogrammata è stata accettata",
			confirmation: "Vuoi davvero riprogrammare questa sessione?",
			excluded: "Non richiedere interpreti che sono stati esclusi dalla precedente prenotazione",
			reason: "Motivo della riprogrammazione",
			sent: "La sessione riprogrammata è stata inviata",
			toAllInterpreters: "Invia la nuova sessione a tutti gli interpreti",
			toOneInterpreter: "Invia la nuova sessione solo a {{interpreter}}",
		},
		sent: "La sessione è stata inviata a {{nInterpreters}} interpreti",
		shareInfo: "sessione condivisa del",
		status: {
			canceled: "Annullato",
			completed: "Completato",
			confirmed: "Confermato",
			deleted: "Cancellato",
			inProgress: "In corso",
			refused: "Rifiutato",
			rescheduled: "Untranslated",
			sent: "Inviato",
			stopped: "Fermato",
			unvalidated: "Non convalidato",
		},
		statusType: {
			alert: "Allerta",
			confirmed: "Confermato",
			history: "Storia",
			sent: "Inviato",
		},
		subjectPlaceholder: "Di che cosa tratterà questa sessione?",
		support: {
			cancelation: "Richiesta di cancellazione per motivi personali",
			deletion: "Richiesta di cancellazione per voce errata",
			other: "Altra richiesta di supporto",
			"validation-cards": "Carte di convalida dell'ordine",
		},
		timerImmediate: {
			allRefused: "Purtroppo in questo momento non ci sono interpreti disponibili - selezionare un'azione per continuare",
			cancel: "Annulla la ricerca",
			continue: "la ricerca continua per",
			expired: "La sessione verrà presto rifiutata automaticamente",
			inProgress: "Ricerca di interpreti in corso",
			notFound: "Nessun interprete trovato",
			research: "Ricerca con altri parametri",
			responses: "Risposte",
			sentTo: "Inviato a",
		},
		toccoBillingInformation: {
			label: "Informazioni sulla fattura",
			placeholder: "I vostri dati saranno riportati nella fattura. Si prega di tenere conto della protezione dei dati!",
		},
		toccoPeopleNumber: "N. di persone",
		travelFees: "Spese di viaggio",
		validate: {
			agreement: "Confermo che i dati inseriti sono completi e corrispondono alla sessione",
			issue: {
				description: "Descrizione",
				label: "Aggiungere un cambiamento o un incidente",
				type: {
					absentReceiver: {
						label: "Beneficiario assente",
						person: "Persona assente",
						warning: "Attenzione! Aggiungendo l'esito \"beneficiario assente\" perderai l'incidente che hai segnalato in precedenza.",
						warning_plural: "Attenzione! L'aggiunta dell'emissione \"beneficiario assente\" vi farà perdere gli {{count}} incidenti che avete segnalato in precedenza.",
					},
					cancellationTime: {
						label: "Data e ora della cancellazione",
					},
					communicationTypeChanged: {
						changedType: "Modalità di comunicazione utilizzata",
						label: "Il modo di comunicazione è cambiato",
						summary: "Cambiato da \"{{originalType}}\" a \"{{changedType}}\"",
					},
					effectiveEndTime: {
						effectiveEndTime: "Ora di fine effettiva",
						error: "L'ora di fine effettiva deve essere successiva all'ora di inizio effettiva.",
						explanation: "Convalida il tempo effettivo, non il tempo prenotato.",
						label: "Ora di fine effettiva",
					},
					effectiveStartTime: {
						effectiveStartTime: "Ora di inizio effettiva",
						error: "L'ora di inizio effettiva deve essere precedente all'ora di fine effettiva.",
						label: "Ora di inizio effettiva",
						warning: "Se il ritardo non è dovuto all'interprete, l'ora d'inizio effettiva rimane quella originariamente prevista per la sessione",
					},
					feedback: {
						label: "Feedback",
					},
					incident: {
						askForAdminReview: "Amministrativo",
						askForIntervision: "In intervisione",
						askForSupervision: "In supervisione",
						label: "Segnalare un incidente",
						person: "Persona coinvolta",
						summary: {
							askForAdminReview: "Amministrativo",
							askForIntervision: "In intervisione",
							askForSupervision: "In supervisione",
							label: "Richiedere una recensione",
						},
					},
					label: "Tipo di cambiamento o incidente",
					other: {
						label: "Commenti",
					},
				},
			},
			sessionId: "ID della sessione",
			status: {
				canceled: "Sessione annullata dal richiedente o dal beneficiario",
				completed: "Sessione completata",
				deleted: "Sessione da cancellare",
				label: "Stato finale",
				select: "Stato",
				warning: "Attenzione! Cambiare lo stato finale ti farà perdere l'incidente che hai segnalato in precedenza",
				warning_plural: "Attenzione! Cambiare lo stato finale ti farà perdere gli {{count}} incidenti che hai già segnalato",
			},
			type: {
				email: "Firma per e-mail",
				label: "Tipo di convalida",
				none: "Nessuna firma",
				numeric: "Firma digitale",
				picture: "Firma fotografica",
				select: "Tipo di convalida desiderato",
			},
			validatedDuration: "Durata convalidata",
			validation: "Convalida della sessione",
		},
		"validation-card-picture": "Carta di convalida",
	},
};
