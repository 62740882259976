import * as React from "react";
import {confirmation} from "../../../../components/feedbacks/confirmation";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {SectionListRenderItemParams, SelectSectionList} from "../../../../components/list/select-sectionlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {LocaleContext} from "../../../../contexts/locale";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales, sessionLanguageToLocale} from "../../../../utils/locales/locales";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {textContains} from "../../../../utils/searches";
import {SessionLanguagesKey, sessionLanguagesKeys} from "../../../../utils/sessions/languages";
import {forceBack} from "../../../navigation";

type ListItem = Item<"id", {displayed: string; id: SessionLanguagesKey}>;

const sort = (a: ListItem, b: ListItem): number => Locales.compare(a.displayed, b.displayed);

const SelectLanguage = ({
	route,
	navigation,
}: SharedScreenProps<"SelectLanguageModal">): JSX.Element => {
	const {
		includedLanguages,
		onSelect,
		hideSectionTitles,
		resetSessionContext = false,
		currentSessionLanguage,
	} = route.params ?? {};
	const {ct, t} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const sectionForItem = ({displayed}: ListItem): string => hideSectionTitles
		? ""
		: displayed.slice(0, 1).toUpperCase();
	const onLanguagePress = (languageKey: SessionLanguagesKey) => () => {
		if (resetSessionContext) {
			if (sessionLanguageToLocale(languageKey) === locale) {
				return navigation.dispatch(forceBack);
			} else if (currentSessionLanguage) { // Show reset confirmation only when a session language was selected (i.e. we started session creation)
				return confirmation({
					actions: [
						{
							icon: "check",
							key: "confirm",
							onPress: () => {
								onSelect?.(languageKey);
								navigation.dispatch(forceBack);
							},
							text: ct("common:reset"),
						}, {
							icon: "close",
							key: "closeDrawer",
							onPress: () => null,
							text: ct("common:cancel"),
							type: "secondary",
						},
					],
					content: t("activities:sessions.context.confirmReset"),
				});
			}
		}
		onSelect?.(languageKey);
		navigation.dispatch(forceBack);
	};
	const renderItem =
		({info: {item}, buttons}: SectionListRenderItemParams<"id", ListItem>): JSX.Element => (
			<ListElement
				onPress={onLanguagePress(item.id)}
				buttons={buttons}
			>
				{item.displayed}
			</ListElement>
		);
	const list: ListItem[] = [...(includedLanguages ?? new Set<SessionLanguagesKey>(sessionLanguagesKeys))]
		.map((language: SessionLanguagesKey) => ({displayed: t(`languages:${language}`), id: language}))
		.sort(sort);
	const showSearch = !includedLanguages || includedLanguages.size > 10;
	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectLanguage")}/>
			<SelectSectionList
				getRequest={(pagination) => Promise.resolve(paginate(list, pagination))}
				renderItem={renderItem}
				sectionForItem={sectionForItem}
				onSearch={showSearch
					? {
						request: (pagination, search) => Promise.resolve(paginate(
							list.filter(item => textContains(search, item.displayed, true)),
							pagination,
						)),
					}
					: undefined}
				itemTranslationKey="forms:inputs.selectLanguage"
				idKey="id"
			/>
		</>
	);
};

export const SelectLanguageModal = (props: SharedScreenProps<"SelectLanguageModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectLanguage {...props} />
	</ModalWrapper>
);
