import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {defaultStackScreenOptions} from "../../utils/navigation/defaults";
import {AuthScreensGroupParamList} from "../../utils/navigation/paramLists/auth-param-list";
import {ChangePassword} from "../screens/common/authentication/change-password";
import {Recover} from "../screens/common/authentication/recover";
import {SignIn} from "../screens/common/authentication/sign-in";

const Stack = createNativeStackNavigator<AuthScreensGroupParamList>();

export const AuthScreensGroup = (
	<Stack.Group screenOptions={{...defaultStackScreenOptions, gestureEnabled: false}}>
		{/* CAREFUL THE ORDER OF THE SCREENS IMPACTS THE NAVIGATION!!! FIRST SCREEN = DEFAULT SCREEN */}
		<Stack.Screen name="SignIn" component={SignIn}/>
		<Stack.Screen name="ChangePassword" component={ChangePassword}/>
		<Stack.Screen name="Recover" component={Recover}/>
	</Stack.Group>
);
