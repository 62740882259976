import {Page, Pagination} from "../@types/pagination";

export const defaultPagination: Pagination = {limit: 20, offset: 0};

export const paginate =
	<I>(array: I[], {offset, limit}: Pagination = defaultPagination): Page<I> => ({
		hasNextPage: offset + limit < array.length,
		hasPreviousPage: offset !== 0,
		items: array.slice(offset, offset + limit),
		totalCount: array.length,
	});

export const mapPageItems =
	<I, U>({items, ...others}: Page<I>, mapFunction: (value: I) => U):
	Page<U> => ({
		...others,
		items: items.map((element) => mapFunction(element)),
	});

export const updatePageItems = <I>(updated: I[], page: Page<I>): Page<I> => ({
	...page,
	items: updated,
});

export const nextPagination = ({offset, limit}: Pagination = defaultPagination): Pagination => ({
	limit,
	offset: offset + limit,
});
