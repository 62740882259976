import {Origin} from "../../navigation/screens/common/modals/select-origin";
import {Requalize, SubUnion} from "../../utils/types";
import {IdentityCommon, IdentityType} from "./identity";
import {Interpreter, InterpreterPreview} from "./interpreter";
import {
	MedicalProfessional,
	MedicalProfessionalPreview,
	OtherProfessional,
	OtherProfessionalPreview,
} from "./professional";
import {Receiver, ReceiverPreview} from "./receiver";

export type PersonIdentity = Interpreter | MedicalProfessional | OtherProfessional | Person | Receiver;
export type PersonIdentityPreview =
	| InterpreterPreview
	| MedicalProfessionalPreview
	| OtherProfessionalPreview
	| PersonPreview
	| ReceiverPreview;

export type PersonListedField<I extends PersonIdentity | PersonIdentityPreview> =
	keyof Omit<I, "fullName">
	| keyof Requalize<I, "fullName">["fullName"];
export type PersonListedFields<I extends PersonIdentity | PersonIdentityPreview> = Set<PersonListedField<I>>;
// export const example: PersonListedFields<Receiver> = new Set(["email", "firstName", "ippNumber"]);

export const genders = ["man", "woman"] as const;
export type Gender = typeof genders[number];

export interface Alias {
	label?: string;
	value: string;
}

export interface PersonCommon extends IdentityCommon {
	dateOfBirth?: Date;
	fullName?: {
		firstName?: string;
		lastName?: string;
	};
	gender: Gender;
	origin?: Origin;
	type: SubUnion<IdentityType, "interpreter" | "medicalProfessional" | "otherProfessional" | "person" | "receiver">;
}

export interface Person extends PersonCommon {
	type: "person";
}

export type PersonPreviewCommon = Pick<PersonCommon, "accountId" | "description" | "email" | "fullName" | "gender" | "identityId" | "origin" | "phone" | "type">;

export interface PersonPreview extends PersonPreviewCommon {
	type: "person";
}
