import * as React from "react";
import {Communication, Session} from "../../@types/activities/session";
import {
	EmailValidation,
	NoneValidation,
	NumericValidation,
	PictureValidation,
	SessionValidationType,
	sessionValidationTypes,
	ValidationTypesSettings,
} from "../../@types/validation";
import {getSessionValidationTypes} from "../../requests/interpreters/activities/actions/validate";
import {FeedbackKey, TranslationFeedbackKey} from "../locales/translations";
import {s} from "../switch";
import {Nullify} from "../types";

type NullableValidationType =
	NoneValidation
	| Nullify<EmailValidation, "email">
	| Nullify<NumericValidation, "signature">
	| Nullify<PictureValidation, "picture">;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useExcludedValidationType = (
	sessionId: Session["activityId"],
	validatedCommunicationType?: Communication["type"],
) => {
	const [authorizedValidationTypes, setAuthorizedValidationTypes] = React.useState<ValidationTypesSettings>({
		inPerson: [],
		phone: [],
		video: [],
	});
	const [excludedValidationTypes, setExcludedValidationTypes] = React.useState<SessionValidationType[]>([]);
	const [selectedValidation, setSelectedValidation] = React.useState<NullableValidationType>({type: "none"});
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const [loading, setLoading] = React.useState(true);

	const onChangeValidationType = React.useCallback(
		(vt: SessionValidationType) =>
			setSelectedValidation(s(vt, {
				email: {email: null, type: "email"},
				none: {type: "none"},
				numeric: {signature: null, type: "numeric"},
				picture: {picture: null, type: "picture"},
			})),
		[setSelectedValidation],
	);

	React.useEffect( // Get Session Validation Types
		() => {
			getSessionValidationTypes(sessionId)
				.then(setAuthorizedValidationTypes)
				.catch((error: FeedbackKey) => setErrorMessage(`feedbacks:${error}`))
				.finally(() => setLoading(false));
		},
		[sessionId],
	);

	React.useEffect(
		() => {
			if (validatedCommunicationType) {
				setExcludedValidationTypes(
					sessionValidationTypes.filter(t => !authorizedValidationTypes[validatedCommunicationType].includes(t)));
				if (authorizedValidationTypes[validatedCommunicationType]?.length === 1) {
					onChangeValidationType(authorizedValidationTypes[validatedCommunicationType][0]);
				}
			}
		},
		[authorizedValidationTypes, onChangeValidationType, validatedCommunicationType],
	);

	return {
		authorizedValidationTypes,
		errorMessage,
		excludedValidationTypes,
		loading,
		onChangeValidationType,
		selectedValidation,
		setSelectedValidation,
	};
};
