import {Communication} from "../../@types/activities/session";
import {IconKey} from "../../components/icons";
import {LIME, CYAN, MAGENTA} from "../styles/colors";
import {s} from "../switch";

export const getCommunicationTypeIcon = (type: Communication["type"]): IconKey => s(type, {
	inPerson: () => "location",
	phone: () => "phone",
	video: () => "video",
});

export const getTypeColor = (type: Communication["type"]): string => s(type, {
	inPerson: MAGENTA,
	phone: LIME,
	video: CYAN,
});
