import moment from "moment";
import {Translations} from "../../@types/@react-i18next.d.ts";
import {SessionPreview} from "../../@types/activities/session";
import {ps, s} from "../switch";

/*
 * UTC+1 is the same as GMT+1
 * Because the Javascript Date format is not serialisable by default by JSON.stringify,
 * but we still need to pass those data by url,
 * then we use the ISO string format.
 * Example: "2020-11-19T12:35:51.338Z".
 * "2020-11-19": Date
 * "T" split between Date and Time
 * "12:35:51.338"
 * "Z" the UTC Time Zone. More specifically, synthaxic sugar for "+00:00".
 * More details at https://en.wikipedia.org/wiki/ISO_8601
 */

export type ISODate = string;
export type TimeUnit =
	"day"
	| "half-hour"
	| "hour"
	| "millisecond"
	| "minute"
	| "month"
	| "quarter-hour"
	| "second"
	| "week"
	| "year";
const DEFAULT_PAST_MONTHS = 12;
const DEFAULT_FUTURE_MONTHS = 12;

// Order respects the start & order of javascript weeks
export const daysOfTheWeek: (keyof Translations["activities"]["calendar"]["days"])[] = [
	"sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday",
];
export type DayOfWeek = typeof daysOfTheWeek[number];

export const months: (keyof Translations["activities"]["calendar"]["month"])[] = [
	"january", "february", "march", "april", "may", "june", "july", "august",
	"september", "october", "november", "december",
];

export const createDate = (dateString: string, format = "DD/MM/YYYY"): Date =>
	moment(dateString, format).toDate();

export const createDateString = (date: Date, format = "DD/MM/YYYY") =>
	moment(date).format(format);

export const getDateTime = (date: Date): string =>
	date.toISOString().slice(0, 16);
export const getDate = (date: Date): string =>
	date.toISOString().slice(0, 10);
export const getTime = (date: Date): string =>
	date.toISOString().slice(11, 16);
export const getDateInMonth = (date: Date, whichDay: "first" | "last", whichTime: "first" | "last"): Date => {
	const d: Date = s(whichTime, {
		first: new Date(new Date(date).setHours(0, 0, 0, 0)),
		last: new Date(new Date(date).setHours(23, 59, 59, 999)),
	});
	const day = s(whichDay, {
		first: 1,
		last: nDaysInMonth(d),
	});
	const finalDate = new Date(d.getFullYear(), d.getMonth(), day);
	if (whichDay === "last") {
		finalDate.setHours(23, 59, 59, 999);
	}
	return finalDate;
};
export const nDaysInMonth = (date: Date): number =>
	new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
export const compareDates = (date1?: Date, date2?: Date): boolean =>
	date1?.getTime() === date2?.getTime();
export const compareMonths = (date1: Date, date2: Date): boolean =>
	date1?.getFullYear() === date2?.getFullYear() && date1.getMonth() === date2.getMonth();
export const compareDays = (date1: Date, date2: Date): boolean =>
	new Date(date1).setHours(0, 0, 0, 0) === new Date(date2).setHours(0, 0, 0, 0);

export const isDayBefore = (date1: Date, date2: Date): boolean =>
	new Date(date1).setHours(0, 0, 0, 0) < new Date(date2).setHours(0, 0, 0, 0);

export const isDayAfter = (date1: Date, date2: Date): boolean =>
	new Date(date1).setHours(0, 0, 0, 0) > new Date(date2).setHours(0, 0, 0, 0);

const addYearsDSTSafe = (date: Date, yearsToAdd: number): Date => new Date(
	date.getFullYear() + yearsToAdd,
	date.getMonth(),
	date.getDate(),
	date.getHours(),
	date.getMinutes(),
	date.getSeconds(),
	date.getMilliseconds(),
);
const addMonthsDSTSafe = (date: Date, monthToAdd: number): Date => new Date(
	date.getFullYear(),
	date.getMonth() + monthToAdd,
	date.getDate(),
	date.getHours(),
	date.getMinutes(),
	date.getSeconds(),
	date.getMilliseconds(),
);
const addDaysDSTSafe = (date: Date, daysToAdd: number): Date => new Date(
	date.getFullYear(),
	date.getMonth(),
	date.getDate() + daysToAdd,
	date.getHours(),
	date.getMinutes(),
	date.getSeconds(),
	date.getMilliseconds(),
);

/*
 * Adds time to a date.
 * The resulting date may be affected by DST depending on time unit:
 * - For days and bigger units, it adds the unit independently of DST. For example, adding a day will result in the date
 *   at the next day at the same hour, not necessarily adding 24h (either 23h or 25h if DST happens).
 * - For smaller units, it adds the duration in ms, so the resulting date is affected by DST.
 */
export const addTime = (date: Date, timeToAdd: number, unit: TimeUnit = "millisecond"): Date => ps(unit, {
	day: addDaysDSTSafe(date, timeToAdd),
	default: new Date(date.getTime() + timeToAdd * getTimeCoef(unit)),
	month: addMonthsDSTSafe(date, timeToAdd),
	week: addDaysDSTSafe(date, timeToAdd * 7),
	year: addYearsDSTSafe(date, timeToAdd),
});

// Be careful not to use `new Date(x, y, z)` because it includes timezone shift
export const dayKeyToDate = (key: string): Date => new Date(key);
// Get the number of month between two dates (without the starting & ending months)
export const dateInRange = (date: Date, from: Date, to: Date): boolean =>
	date >= from && date <= to;
export const getDateParts = (date: Date | undefined): {
	day: string;
	hours: string;
	milliseconds: string;
	minutes: string;
	month: string;
	seconds: string;
	year: string;
} => date
	? ({
		day: String(date.getDate()).padStart(2, "0"), // 0000-9999
		hours: String(date.getHours()).padStart(2, "0"), // 01-12
		milliseconds: String(date.getMilliseconds()).padStart(3, "0"), // 01-31
		minutes: String(date.getMinutes()).padStart(2, "0"), // 00-11
		month: String(date.getMonth() + 1).padStart(2, "0"), // 00-59
		seconds: String(date.getSeconds()).padStart(2, "0"), // 00-59
		year: String(date.getFullYear()).padStart(4, "0"), // 000-999
	})
	: ({
		day: "--",
		hours: "--",
		milliseconds: "---",
		minutes: "--",
		month: "--",
		seconds: "--",
		year: "----",
	});
export const getTimeCoef = (unit: Omit<TimeUnit, "month">): number => s(unit as string, {
	day: 1000 * 60 * 60 * 24,
	"half-hour": 1000 * 60 * 30,
	hour: 1000 * 60 * 60,
	millisecond: 1,
	minute: 1000 * 60,
	"quarter-hour": 1000 * 60 * 15,
	second: 1000,
	week: 1000 * 60 * 60 * 24 * 7,
});
export const roundTime = (date: Date, toClosest: Omit<TimeUnit, "month"> = "minute"):
Date => {
	const coeff = getTimeCoef(toClosest);
	return new Date(Math.round(date.getTime() / coeff) * coeff);
};
export const computeDuration = (from: Date, to: Date, unit: TimeUnit = "millisecond"): number =>
	unit === "month"
		? (to.getFullYear() - from.getFullYear()) * 12 - from.getMonth() + to.getMonth()
		: (to.getTime() - from.getTime()) / getTimeCoef(unit);
export const computeEndTime = (start: Date, ...durationsToSum: number[]): Date =>
	new Date(start.getTime() + durationsToSum.reduce((a, b) => a + b, 0));
export const isCurrentSession = (session: SessionPreview): boolean =>
	session.start.getTime() <= Date.now() && session.end.getTime() > Date.now();
export const isToday = (date: Date): boolean =>
	compareDays(date, new Date());
export const getDayInWeek = (date: Date, which: "monday" | "sunday" = "monday"): Date => {
	const d = new Date(date);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1) + (which === "sunday" ? 6 : 0); // adjust when day is sunday
	return new Date(d.setDate(diff));
};
// Determine whether two days are consecutive, given their keys.
export const consecutiveDays = (beforeKey: string, afterKey: string): boolean => {
	if (!beforeKey || !afterKey) {
		return false;
	}
	const before = dayKeyToDate(beforeKey);
	const after = dayKeyToDate(afterKey);
	const dayBeforeAfter = new Date(after.getTime());
	dayBeforeAfter.setDate(after.getDate() - 1);
	return compareDays(before, dayBeforeAfter);
};

// Determine nth weekday in month (first/second/third/.. tuesday in month)
export const getNthWeekdayInMonth = (date: Date): number => {
	const firstMonthDay = getDateInMonth(date, "first", "first");
	const diffDays = Math.floor(computeDuration(firstMonthDay, date, "day"));
	return Math.floor(diffDays / 7) + 1;
};
// Determine the next month same nth weekday
export const getNextMonthNthWeekday = (date: Date): Date => {
	// get next month first day
	const nextMonthNthWeekday = new Date(date.getFullYear(), date.getMonth() + 1, 1);
	// calculate next month nth weekday - https://stackoverflow.com/a/70156077
	const nextMonththDay = 1 + (7 - nextMonthNthWeekday.getDay() + date.getDay()) % 7 + (getNthWeekdayInMonth(date) - 1) *
		7;
	// if next month nth weekday is higher than the number of days in the month, we take the last weekday
	nextMonthNthWeekday.setDate(
		nextMonththDay <= nDaysInMonth(nextMonthNthWeekday) ? nextMonththDay : nextMonththDay - 7);
	return nextMonthNthWeekday;
};

// Determine if the time of date1 is before the time of date2
export const isTimeBefore = (date1: Date, date2: Date): boolean => {
	const sameDayDate2 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(),
		date2.getHours(), date2.getMinutes(), date2.getSeconds(),
	);
	return date1 < sameDayDate2;
};

export const isDayOfWeekBetween = (toCheck: number, min: number, max: number): boolean =>
	min <= max ? toCheck >= min && toCheck <= max : toCheck >= min || toCheck <= max;

export const moreThanOneDay = (start: Date, end: Date): boolean => start && end && !compareDays(start, end);
export const moreThanOneWeek = (start: Date, end: Date): boolean => start && end && computeDuration(start, end, "day") >
	7;
export const moreThanOneMonth = (start: Date, end: Date): boolean => start && end && end >
	getNextMonthNthWeekday(start);

export const FIRST_DAY_CALENDAR = getDateInMonth(addTime(new Date(), -DEFAULT_PAST_MONTHS, "month"), "first", "first");
export const LAST_DAY_CALENDAR = getDateInMonth(addTime(new Date(), DEFAULT_FUTURE_MONTHS, "month"), "last", "last");
export const FIRST_DAY_CURRENT_MONTH = getDateInMonth(new Date(), "first", "first");
