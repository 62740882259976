import {InterpreterCalendarMandatePreview} from "../../../../@types/activities/mandate";
import {FollowUpSettings, Session} from "../../../../@types/activities/session";
import {api} from "../../../../utils/network/api";
import {ApiResponse} from "../../../../utils/network/api-response";
import {BackendSessionType} from "../../../@types/session";
import {UserSession} from "../../../@types/user";
import {
	transformLanguageISOCodeFrontToBack,
	transformSessionStatusFrontToBack,
	transformSessionTypeFrontToBack,
} from "../../../utils/transform";

export type InterpreterFollowUpSessionAddRequests = {
	POST: {
		body: Partial<UserSession>,
	},
};
export type InterpreterFollowUpSessionGetRequests = {
	GET: {
		pathParams: {sessionId: string},
	},
};

interface FollowUpSettingResponse {
	enableDuration: number,
	isMethodChangeAllowed: boolean,
}

/*
 * The interpreter is going to fill the following values from the session it's based on:
 * start, end, (address), interpreterComment
 */
export const createFollowUp = async (session: Session): Promise<InterpreterCalendarMandatePreview> => {
	const {
		type, activityId, status, mandator, providers, providedService, requester,
		internalComment, receiver, providerComment, medicalProfessional, language,
		toLanguage, immediate, subject, start, end,
	} = session;
	let formattedAddress: string;
	let locationDetails: string;
	const {communication} = session;
	if (communication.type === "inPerson") {
		const {place, placeInfos} = communication;
		formattedAddress = place.address;
		locationDetails = placeInfos;
	}
	const associatedPatient = receiver ? {id: Number.parseInt(receiver.identityId, 10)} : null;
	const associatedUser = medicalProfessional ? {id: Number.parseInt(medicalProfessional.identityId, 10)} : null;
	const sessionType: BackendSessionType = transformSessionTypeFrontToBack(communication.type, providedService.type);
	const interpreterResponse = await api(
		"interpreterFollowUpSessionAdd",
		"POST",
		{
			body: {
				formattedAddress,
				locationDetails,
				purpose: providerComment || "",
				bookingDate: start.getTime(),
				bookingType: "FOLLOWUP",
				startTime: start.getTime(),
				endTime: end.getTime(),
				interpreter: {
					id: providers[0].identityId,
				},
				owner: {
					id: Number.parseInt(requester.identityId, 10),
				},
				patient: associatedPatient,
				user: associatedUser,
				userAccount: {
					id: Number.parseInt(mandator.identityId, 10),
				},
				id: Number.parseInt(activityId, 10),
				title: subject,
				fromLanguage: {
					isoCode: transformLanguageISOCodeFrontToBack(language),
				},
				toLanguage: {
					isoCode: transformLanguageISOCodeFrontToBack(toLanguage),
				},
				sessionCategory: immediate ? "IMMEDIATE" : "SCHEDULED",
				sessionType,
				status: transformSessionStatusFrontToBack(status),
				internalComments: internalComment,
			},
		},
	) as ApiResponse<{sessionId: number}>;
	if (interpreterResponse.isSuccess) {
		return ({
			communication,
			start,
			end,
			type,
			status,
			language,
			toLanguage,
			activityId: interpreterResponse.data.sessionId.toString(),
		});
	}
	throw new Error(interpreterResponse.errorId || interpreterResponse.interpreterMessage);
};

// minimum duration of a follow-up session in minutes. 0 means no limitation.
export const getFollowUpSettings = async (sessionId: Session["activityId"]): Promise<FollowUpSettings> => {
	const response = await api(
		"interpreterFollowUpDurationGet",
		"GET",
		{
			queryParams: {sessionId},
		},
	) as ApiResponse<FollowUpSettingResponse>;
	if (response.isSuccess) {
		return {
			minimumDuration: response.data.enableDuration ? 60 : 1, // In minutes. 0 means no limitation.
			disableCommunicationTypeChange: response.data.isMethodChangeAllowed,
		};
	}
	throw new Error(response.interpreterMessage);
};
