import * as React from "react";
import {CommunicationType, InPerson, Phone, Video} from "../../../../@types/activities/session";
import {IdentityEditableFields} from "../../../../@types/identities/identity";
import {PersonListedFields} from "../../../../@types/identities/person";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {CommunicationTypeChangedIssue, Issue, IssueCommon} from "../../../../@types/validation";
import {FeedbackOption} from "../../../../@types/validation-settings";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {getReceiverEditableFields, getReceiverListedFields} from "../../../../requests/common/activities/details";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {useCommunicationTypes} from "../../../../utils/hooks/use-communication-types";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getDescriptionLabel, receiverIdentity} from "../../../../utils/identities";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {TranslationFeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {Nullify, Partialize} from "../../../../utils/types";
import {forceBack} from "../../../navigation";
import {AddressListItem} from "../../client/modals/address-list";

type NullableCommunicationTypeChangedIssue = Partialize<IssueCommon, "description"> & {
	changed: {communication: Nullify<InPerson, "place"> | Nullify<Phone, "phoneNumber"> | Nullify<Video, "channelId">};
	type: "communicationTypeChanged";
};

type NullableIssue = Issue | NullableCommunicationTypeChangedIssue;

const SelectValidationIssue = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"SelectValidationIssueModal">): JSX.Element => {
	const {issue: issueProp, onSelect, sessionId, start, excludedCommunicationType, feedbackSetting} = route.params;
	const {ct, t} = useTranslation();
	const base = React.useMemo(
		() => issueProp as NullableIssue || {} as Partial<NullableIssue>,
		[issueProp],
	);
	const [receiverListedFields, setReceiverListedFields] = React.useState<PersonListedFields<Receiver>>();
	const [receiverEditableFields, setReceiverEditableFields] = React.useState<IdentityEditableFields<Receiver>>();
	const [loadingReceiverSettings, setLoadingReceiverSettings] = React.useState<boolean>(IS_INTERPRETER);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const {displayed, isUpdated, setUpdated} = useForm(base);
	const sessionStart = React.useMemo(() => new Date(start), [start]);
	const select = (i: Issue) => () => {
		onSelect?.(i);
		navigation.dispatch(forceBack);
	};
	const communicationTypes = useCommunicationTypes([excludedCommunicationType]);

	React.useEffect(
		() => {
			Promise.all([
				getReceiverListedFields(sessionId)
					.then(setReceiverListedFields),
				getReceiverEditableFields(sessionId)
					.then(setReceiverEditableFields),
			])
				.catch((error_) => {
					Log.error()(error_);
					setErrorMessage("feedbacks:getDataFailed");
				})
				.finally(() => setLoadingReceiverSettings(false));
		},
		[sessionId],
	);

	const header = (
		<HeaderMenu
			center={issueProp?.type === "feedback" && !!feedbackSetting?.label
				? feedbackSetting.label
				: t(`activities:sessions.validate.issue.type.${displayed.type}.label`)}
			exitConfirmation={isUpdated}
		/>
	);

	if (loadingReceiverSettings || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingReceiverSettings}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}

	const inputs: FormProps["inputs"] = [];
	switch (displayed?.type) {
		case "absentReceiver": {
			inputs.push({
				disabled: displayed.type === "absentReceiver",
				icon: "person",
				label: t("activities:sessions.validate.issue.type.absentReceiver.person"),
				requiredLabel: true,
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "screen",
					onChangeValue: (people: ReceiverPreview[]) => setUpdated(prev => ({...prev, person: people?.[0]})),
					params: {
						exportType: "sessionPerson",
						sessionId,
					},
					renderValue: (person: ReceiverPreview) => receiverIdentity(
						person,
						receiverListedFields,
						getDescriptionLabel(receiverEditableFields),
					),
					screen: "SelectPersonModal",
					value: displayed?.person,
				},
			});
			break;
		}
		case "feedback": {
			feedbackSetting?.options.map((option: FeedbackOption) =>
				inputs.push({
					icon: "feedback",
					label: option.text,
					rules: feedbackSetting.mandatory && [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "select",
						onChangeValue: (feedback: boolean) => feedback && setUpdated(prev => ({...prev, feedback: option})),
						value: displayed?.feedback?.id === option.id,
					},
				}),
			);
			break;
		}
		case "incident": {
			displayed.adminReview = true; // this line is temporary, as the commented ones below
			inputs.push(
				{
					icon: "comment",
					label: t("activities:sessions.validate.issue.description"),
					requiredLabel: true,
					rules: [
						{rule: Rules.notEmpty, type: "error"},
						{rule: Rules.max(2000), type: "error"},
					],
					type: {
						key: "text",
						onChangeValue: (description: string) => setUpdated(prev => ({...prev, description})),
						props: {multiline: true},
						value: displayed?.description,
					},
				},
				/*
				 * {
				 * label: t("activities:sessions.validate.issue.type.incident.person"),
				 * icon: "person",
				 * type: {
				 * key: "screen",
				 * screen: "SelectPersonModal",
				 *  onChangeValue: (people: PersonPreview[]) => setUpdated(prev => ({...prev, person: people?.[0]})),
				 *  renderValue: (person: PersonPreview) => personIdentity(person),
				 *  params: {
				 *    sessionId,
				 *    type: "sessionPerson",
				 *  },
				 *  value: issue?.person,
				 * },
				 *},
				 */
				{
					disabled: true,
					icon: "commentMoreOutline",
					label: t("activities:sessions.validate.issue.type.incident.askForAdminReview"),
					// rules: !issue.intervision && !issue.supervision && !issue.adminReview ? [{rule: Rules.notEmpty, type: "error"}] : [],
					type: {
						key: "checkbox",
						onChangeValue: (adminReview: boolean) => setUpdated(prev => ({...prev, adminReview})),
						value: displayed?.adminReview,
					},
				},
				/* {
				 *   label: t("activities:sessions.validate.issue.type.incident.askForIntervision"),
				 *   icon: "commentMoreOutline",
				 *   // rules: !issue.intervision && !issue.supervision && !issue.adminReview ? [{rule: Rules.notEmpty, type: "error"}] : [],
				 *   type: {
				 *     key: "checkbox",
				 *     onChangeValue: (intervision: boolean) => setUpdated(prev => ({...prev, intervision})),
				 *     value: issue?.intervision,
				 *   },
				 * }, {
				 *   label: t("activities:sessions.validate.issue.type.incident.askForSupervision"),
				 *   icon: "commentMoreOutline",
				 *   // rules: !issue.intervision && !issue.supervision && !issue.adminReview ? [{rule: Rules.notEmpty, type: "error"}] : [],
				 *   type: {
				 *     key: "checkbox",
				 *     onChangeValue: (supervision: boolean) => setUpdated(prev => ({...prev, supervision})),
				 *     value: issue?.supervision,
				 *  },
				 * }
				 */
			);
			break;
		}
		case "effectiveStartTime": {
			inputs.push({
				explanation: displayed?.effectiveStartTime > sessionStart
					? t("activities:sessions.validate.issue.type.effectiveStartTime.warning")
					: "",
				icon: "startTime",
				label: t("activities:sessions.validate.issue.type.effectiveStartTime.effectiveStartTime"),
				rules: [
					{
						rule: Rules.dateBefore(
							displayed?.effectiveEndTime,
							t("activities:sessions.validate.issue.type.effectiveStartTime.error"),
						),
						type: "error",
					},
				],
				type: {
					key: "time",
					onChangeValue: (effectiveStartTime: Date) => setUpdated(prev => ({...prev, effectiveStartTime})),
					props: {minuteInterval: 1},
					value: displayed?.effectiveStartTime,
				},
			});
			break;
		}
		case "effectiveEndTime": {
			inputs.push({
				explanation: t("activities:sessions.validate.issue.type.effectiveEndTime.explanation"),
				icon: "endTime",
				label: t("activities:sessions.validate.issue.type.effectiveEndTime.effectiveEndTime"),
				rules: [
					{
						rule: Rules.dateAfter(
							displayed?.effectiveStartTime,
							t("activities:sessions.validate.issue.type.effectiveEndTime.error"),
						),
						type: "error",
					},
				],
				type: {
					key: "time",
					onChangeValue: (effectiveEndTime: Date) => setUpdated(prev => ({...prev, effectiveEndTime})),
					props: {minimumDate: displayed?.effectiveStartTime, minuteInterval: 1},
					value: displayed?.effectiveEndTime,
				},
			});
			break;
		}
		case "communicationTypeChanged": {
			inputs.push({
				icon: "communicationMode",
				label: t("activities:sessions.validate.issue.type.communicationTypeChanged.changedType"),
				requiredLabel: true,
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: communicationTypes,
					getLabelText: (type: CommunicationType) => t(`activities:sessions.communicationTypes.${type}`),
					itemProps: (type: CommunicationType) => ({icon: getCommunicationTypeIcon(type)}),
					key: "inlineSelect",
					onChangeValue: (types: CommunicationType[] | null) => setUpdated(prev => ({
						...prev,
						changed: types?.[0] === "inPerson"
							? {communication: {place: null, type: "inPerson"}}
							: types?.[0] === "phone"
								? {communication: {phoneNumber: null, type: "phone"}}
								: {communication: {channelId: null, secret: undefined, token: undefined, type: "video"}},
					})),
					value: displayed?.changed?.communication.type && [displayed?.changed?.communication.type],
				},
			});
			if (displayed?.changed?.communication.type === "inPerson") {
				inputs.push({
					icon: "place",
					label: ct("activities:sessions.address"),
					requiredLabel: true,
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "screen",
						onChangeValue: (p: AddressListItem) => setUpdated(prev => ({
							...(prev ?? displayed),
							changed: {
								...((prev as CommunicationTypeChangedIssue).changed ?? displayed.changed),
								communication: {place: p?.displayed, type: "inPerson"},
							},
						})),
						params: {
							place: displayed?.changed?.communication.place ?? undefined,
						},
						screen: "SelectPlaceModal",
						value: displayed?.changed?.communication.place?.address,
					},
				});
			}
			break;
		}
		case "cancellationTime": {
			inputs.push({
				icon: "endTime",
				label: t("activities:sessions.validate.issue.type.cancellationTime.label"),
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "datetime",
					onChangeValue: (cancellationTime: Date) => setUpdated(prev => ({...prev, cancellationTime})),
					props: {maximumDate: new Date(), minuteInterval: 1},
					value: displayed?.cancellationTime,
				},
			});
			break;
		}
	}

	if (displayed?.type !== "incident") {
		inputs.push({
			icon: "comment",
			label: t("activities:sessions.validate.issue.description"),
			requiredLabel: true,
			rules: !displayed || (displayed &&
				displayed.type !== "absentReceiver" &&
				displayed.type !== "effectiveStartTime" &&
				displayed.type !== "effectiveEndTime" &&
				displayed.type !== "communicationTypeChanged" &&
				displayed.type !== "feedback"
			)
				? [{rule: Rules.notEmpty, type: "error"}, {rule: Rules.max(2000), type: "error"}]
				: null,
			type: {
				key: "text",
				onChangeValue: (description: string) => setUpdated(prev => ({...prev, description})),
				props: {multiline: true},
				value: displayed?.description,
			},
		});
	}
	return (
		<>
			{header}
			<Form
				hideReset
				validation={{
					buttonProps: {
						icon: "check",
						text: issueProp ? ct("common:update") : ct("common:add"),
					},
					onValidation: select(displayed as Issue),
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const SelectValidationIssueModal = (props: InterpreterStackNavigatorScreenProps<"SelectValidationIssueModal">): JSX.Element => (
	<ModalWrapper>
		<SelectValidationIssue {...props} />
	</ModalWrapper>
);
