import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Session} from "../../@types/activities/session";
import {URL} from "../../@types/medias/image";
import {AuthentifiedContext} from "../../contexts/authentified";
import {SessionCreationContext} from "../../contexts/session-creation";
import {onSessionCreationError} from "../../navigation/screens/client/creation/utils";
import {createSession} from "../../requests/clients/new-session/create-session";
import {CONTAINERS, DEFAULT_SPACING, EXTRA_LARGE_SPACING, IS_INTERPRETER, SMALL_SPACING} from "../../utils/constants";
import {tryShowingFeedbackModal} from "../../utils/feedbacks";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Log} from "../../utils/logs/logs";
import {SupportModal} from "../../utils/navigation/paramLists/common-screen-props";
import {linkToURL} from "../../utils/network/links";
import {BLUE, RED} from "../../utils/styles/colors";
import {Button} from "../buttons/button";
import {Icon} from "../icons";
import {CheckboxSelectInput} from "../inputs/list-inputs/checkbox-select-input";
import {Separator} from "../separator";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";

export const SessionFooter = ({
	onSessionCreationSuccess,
	onSessionCreationFailed,
	session,
	termsAndConditionsURL,
}: {
	onSessionCreationFailed?: () => void;
	onSessionCreationSuccess?: () => void;
	session: Session;
	termsAndConditionsURL?: URL;
}): JSX.Element => {
	const {ct, t} = useTranslation();
	const {accountId} = React.useContext(AuthentifiedContext);
	const {initialExcludedInterpreterIds, filters, reset} = React.useContext(SessionCreationContext);
	const [acceptTermsConditions, setAcceptTermsConditions] = React.useState(false);
	const navigation = useNavigation();
	const onPressTermsConditions = React.useCallback(
		() => {
			linkToURL(termsAndConditionsURL!).catch(Log.error());
		},
		[termsAndConditionsURL],
	);

	const onPressSupport = React.useCallback(
		(subject: NonNullable<SupportModal>["subject"]) =>
			() => navigation.navigate("SupportModal", {sessionId: session.activityId, subject}),
		[navigation, session.activityId],
	);

	const onFeedbackDone = React.useCallback(
		/*
		 * TODO: find a better way to do navigation to calendar than goBack + navigate in timeOut.
		 * See: https://app.asana.com/0/1202805761601569/1203227102715077
		 * In summary, we need to goBack to be on /new-session first before navigating in a setTimeout otherwise
		 * we navigate and the url stays on /new-session. The goBack is in the .then of createSession.
		 */
		() => setTimeout(onSessionCreationSuccess!, 1),
		[onSessionCreationSuccess],
	);

	const onPressSendSession = React.useCallback(
		() => createSession(session, initialExcludedInterpreterIds, filters)
			.then(session => {
				navigation.goBack();
				Log.success("sessionCreationSuccess")();
				reset({type: "new"});

				const action = "sessionCreation";
				tryShowingFeedbackModal(
					action,
					accountId!,
					() => navigation.navigate(
						"FeedbackModal",
						{action, onFeedbackDone, sessionId: session.activityId},
					),
					onFeedbackDone,
				);
			})
			.catch((error: Error) => onSessionCreationError("createSessionFailed", onSessionCreationFailed)(error)),
		[
			accountId,
			filters,
			navigation,
			onFeedbackDone,
			onSessionCreationFailed,
			initialExcludedInterpreterIds,
			reset,
			session,
		],
	);

	return (
		<View style={[CONTAINERS.MAIN, styles.supportContainer]}>
			<Separator/>
			{IS_INTERPRETER && session.status === "confirmed" && (
				<>
					<Spacer size={SMALL_SPACING}/>
					<Button
						fullWidth
						size="small"
						type="secondary"
						text={t("activities:sessions.support.cancelation")}
						onPress={onPressSupport("cancelation")}
					/>
					<Spacer size={SMALL_SPACING}/>
					<Button
						fullWidth
						size="small"
						type="secondary"
						text={t("activities:sessions.support.deletion")}
						onPress={onPressSupport("deletion")}
					/>
				</>
			)}
			{onSessionCreationSuccess
				? (
					<>
						{termsAndConditionsURL && (
							<View style={styles.termsConditionsContainer}>
								<Icon icon="check"/>
								<View style={styles.termsConditionsTextContainer}>
									<View style={styles.termsConditionsText}>
										<Text type="touchable_label">
											{`${ct("screens:creation.acceptTermsConditions")} `}
										</Text>
										<Text color={RED}>* </Text>
									</View>
									<Text
										type="touchable_label"
										onPress={onPressTermsConditions}
										style={styles.seeTermsConditions}
										color={BLUE}
									>
										{ct("screens:creation.seeTermsConditions")}
									</Text>
								</View>
								<CheckboxSelectInput
									type="checkbox"
									value={acceptTermsConditions}
									onChangeValue={setAcceptTermsConditions}
								/>
							</View>
						)}
						<Button
							fullWidth
							size="small"
							text={ct("common:send")}
							icon="check"
							iconPosition="after"
							onPress={onPressSendSession}
							disabled={!!termsAndConditionsURL && !acceptTermsConditions}
						/>
					</>
				)
				: (
					<>
						<Spacer size={SMALL_SPACING}/>
						<Button
							fullWidth
							size="small"
							type="secondary"
							text={IS_INTERPRETER ? t("activities:sessions.support.other") : t("screens:profile.support")}
							onPress={onPressSupport("other")}
						/>
					</>
				)
			}

		</View>
	);
};

const styles = StyleSheet.create({
	seeTermsConditions: {
		marginLeft: SMALL_SPACING,
		marginTop: SMALL_SPACING / 2,
	},
	supportContainer: {
		paddingBottom: EXTRA_LARGE_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
	termsConditionsContainer: {
		alignItems: "center",
		flexDirection: "row",
		marginBottom: DEFAULT_SPACING,
		marginTop: SMALL_SPACING,
	},
	termsConditionsText: {
		flexDirection: "row",
		marginLeft: SMALL_SPACING,
	},
	termsConditionsTextContainer: {
		flex: 1,
	},
});
