/*
 * Switch have tons of boilerplate. Let's create a simplified version of them
 * How it works: switch(value, {option1: value1, option2: ...})
 *
 * /!\ If the return value is a function, it's going to be called automatically
 *
 * (Cannot be called switch)
 *
 * @param value
 * @param possibilities
 */
export const s = <T extends string, U>(value: T, possibilities: {[key in T]: U | (() => U)}): U => {
	const returnValue = possibilities[value]; // Set in a const so Typescript can infer it
	return typeof returnValue === "function"
		? returnValue() as U
		: returnValue as U;
};

// Partial version of a switch
export const ps = <T extends string, U>(value: T, possibilities: {[key in T | "default"]?: U}): U => {
	const returnValue = possibilities[value]; // Set in a const so Typescript can infer it
	const defaultValue = possibilities.default; // Set in a const so Typescript can infer it
	return (
		returnValue
			? typeof returnValue === "function"
				? returnValue()
				: returnValue
			: typeof defaultValue === "function"
				? defaultValue()
				: defaultValue ?? null
	) as U;
};
