import {ActivityType, CalendarActivityPreview} from "../../@types/activities/activity";
import {CALENDAR_HEADER_HEIGHT, WEEK_HEIGHT} from "../constants";
import {getKeys} from "../objects";
import {formatDayKey} from "./format";
import {addTime, compareDays, compareMonths, computeDuration, getDateInMonth, getDayInWeek} from "./helpers";

export type OnDatePress = (date: Date) => void;

export interface Day {
	active?: boolean;
	activity?: boolean;
	day: Date;
	disabled?: boolean;
	excluded?: boolean;
	onDayPress?: OnDatePress;
	today?: boolean;
}

export type Week = Day[];
export type Month = Week[];

export interface MonthCalendar {
	height: number;
	month: Month;
	offset: number;
}

// The first array index represent the day of the month the second the activities of the day
export interface ActivitiesStructure {[dayKey: string]: ActivityType[]}

export interface ActivitiesCache {
	[dayKey: string]: {
		activities: (ActivityType | CalendarActivityPreview)[];
		state: "failed" | "fetched" | "fetching" | "stale" | "unfetched";
	};
}

export const createWeek = (
	dayInWeek: Date,
	activitiesStructure?: ActivitiesStructure,
	targetedMonth?: Date,
	dayDisabled?: (day: Date) => boolean,
): Week => Array.from({length: 7})
	.map((_, index) => {
		// can be in the previous or the next month than targetedMonth
		const day = addTime(getDayInWeek(dayInWeek), index, "day");
		return {
			activity: activitiesStructure && (getKeys(activitiesStructure).length > 0)
				? activitiesStructure[formatDayKey(day)]?.length > 0
				: false,
			day,
			disabled: dayDisabled?.(day) ?? false,
			excluded: targetedMonth ? !compareMonths(targetedMonth, day) : false,
			today: compareDays(day, new Date()),
		};
	});

export const createMonth = (
	dayInMonth: Date,
	offset: number,
	activitiesStructure: ActivitiesStructure,
	dayDisabled?: (day: Date) => boolean,
): MonthCalendar => {
	const firstDayInMonth = getDateInMonth(dayInMonth, "first", "first");
	const start = getDayInWeek(firstDayInMonth); // can be in the previous month
	const end = getDayInWeek(getDateInMonth(dayInMonth, "last", "first"), "sunday");
	const monthLength = Math.ceil(computeDuration(start, end, "week"));
	const height = monthLength * WEEK_HEIGHT + CALENDAR_HEADER_HEIGHT;
	const weeks = Array.from({length: monthLength})
		.map((_, index) => {
			const weekDate = addTime(start, index, "week");
			return createWeek(weekDate, activitiesStructure, dayInMonth, dayDisabled);
		});
	return {height, month: weeks, offset};
};

export const createMonthCalendar = (
	from: Date,
	to: Date,
	activitiesStructure: ActivitiesStructure,
	dayDisabled?: (day: Date) => boolean,
): MonthCalendar[] => {
	let offsetAcc = 0;
	return Array.from({length: Math.ceil(computeDuration(from, to, "month")) + 1})
		.map((_, index) => {
			const month = createMonth(
				addTime(from, index, "month"),
				offsetAcc,
				activitiesStructure,
				dayDisabled,
			);
			offsetAcc = month.offset + month.height;
			return month;
		});
};

export const createWeekCalendar = (
	from: Date,
	to: Date,
	activitiesStructure: ActivitiesStructure,
	dayDisabled?: (day: Date) => boolean,
): Week[] =>
	Array.from({length: Math.ceil(computeDuration(from, to, "week"))})
		.map((_, index) => {
			const weekDate = addTime(from, index, "week");
			return createWeek(weekDate, activitiesStructure, undefined, dayDisabled);
		});
