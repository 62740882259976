import {AvailabilityState} from "../../@types/availability";
import {Interpreter, InterpreterPreview} from "../../@types/identities/interpreter";
import {DataGetter} from "../../utils/hooks/use-websocket";

export type CombinedAvailabilities = {
	isCurrentAvailable: boolean,
	unavailableEvent: boolean,
	unavailableImmediate: boolean,
	unavailableNights: boolean,
	unavailableSession: boolean,
	unavailableWeekend: boolean,
};

export type WebSocketAvailability = {
	key: string,
	data: Record<string, AvailabilityState>,
};

export const getInterpreterAvailabilityData: DataGetter<Record<NonNullable<Interpreter["accountId"]>, Interpreter["availability"]>> = {
	key: "interpretersAvailabilities",
	getData: (message: WebSocketAvailability) => message.data,
};

export const getInterpretersAvailabilitiesMessage = (interpreterIds: InterpreterPreview["identityId"][]): {
	data: InterpreterPreview["identityId"][];
	key: string;
} => ({
	key: "interpretersAvailabilities",
	data: interpreterIds,
});
