import * as React from "react";
import {NotificationsChannel, NotificationTarget} from "../../../../@types/notifications";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {IS_ANDROID, IS_IOS, IS_WEB} from "../../../../utils/constants";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {infos} from "../../../../utils/infos";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {NotificationManager} from "../../../../utils/notifications/manager";
import {DeviceTokenHolder} from "../../../../utils/notifications/manager.common";
import {removeNonPhoneNumberCharacters} from "../../../../utils/strings";
import {ps} from "../../../../utils/switch";
import {Partialize} from "../../../../utils/types";
import {forceBack} from "../../../navigation";

export const isPushChannel = (channel: NotificationTarget["channel"]): boolean =>
	channel === "APNS" || channel === "APNS_SANDBOX" || channel === "FCM" || channel === "WEB";
const isMatchingPushChannel = (channel: NotificationTarget["channel"]): boolean =>
	((channel === "APNS" || channel === "APNS_SANDBOX") && IS_IOS) ||
	(channel === "FCM" && IS_ANDROID) ||
	(channel === "WEB" && IS_WEB);

const appropriatePushChannel: NotificationsChannel = NotificationManager.appropriatePushChannel();

const SelectNotificationTarget = ({
	route,
	navigation,
}: SharedScreenProps<"SelectNotificationTargetModal">): JSX.Element => {
	const {onSelect, selectionOrBase: item} = route.params ?? {};
	const {ct, t} = useTranslation();
	const base: Partialize<NotificationTarget, "displayed" | "id"> = React.useMemo(
		() => item ?? {
			channel: appropriatePushChannel,
			deletable: true,
			displayed: infos["device.name"],
			id: undefined,
			notified: true,
		},
		[item],
	);
	const {displayed, isUpdated, setUpdated} = useForm(base);

	React.useEffect(
		() => {
			if (isPushChannel(displayed.channel) && isMatchingPushChannel(displayed.channel) && !displayed.id) {
				setUpdated(prev => ({...prev, id: DeviceTokenHolder.token}));
			}
		},
		[setUpdated, displayed.channel, displayed.id],
	);

	const inputs: FormProps["inputs"] = [
		{
			disabled: true,
			icon: "communicationMode",
			label: ct("common:type"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: [appropriatePushChannel, "SMS", "EMAIL"],
				getLabelText: (channel: NotificationsChannel) =>
					ps(channel, {
						EMAIL: t("users:settings.notifications.eMailShort"),
						SMS: t("users:settings.notifications.SMSShort"),
						default: t("users:settings.notifications.pushShort"),
					}),
				key: "inlineSelect",
				onChangeValue: (channels?: NotificationsChannel[] | null) => setUpdated(prev => ({
					...prev,
					channel: channels?.[0],
					displayed: isPushChannel(channels?.[0]) ? infos["device.name"] : undefined,
					id: undefined,
				})),
				value: displayed.channel ? [displayed.channel] : null,
			},
		}, {
			disabled: !isPushChannel(displayed.channel),
			icon: "id",
			label: ct("common:name"),
			requiredLabel: true,
			rules: [
				{rule: Rules.notEmpty, type: "error"},
				{rule: Rules.min(4), type: "error"},
			],
			type: {
				key: "text",
				onChangeValue: (d: string) => setUpdated(prev => ({...prev, displayed: d})),
				value: displayed.displayed,
			},
		},
	];

	if (displayed.channel === "EMAIL") {
		inputs.push({
			disabled: true,
			icon: "mail",
			label: ct("common:email"),
			requiredLabel: true,
			rules: [
				{rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.email, type: "error"},
			],
			type: {
				key: "email",
				onChangeValue: (id: string) => setUpdated(prev => ({...prev, id})),
				value: displayed.id,
			},
		});
	}
	if (displayed.channel === "SMS") {
		inputs.push({
			disabled: true,
			icon: "phone",
			label: ct("common:phone"),
			requiredLabel: true,
			rules: [
				{rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.phone, type: "error"},
			],
			type: {
				key: "phone",
				onChangeValue: (p?: string) => setUpdated(prev => ({...prev, phone: p ? removeNonPhoneNumberCharacters(p) : undefined})),
				value: displayed.id,
			},
		});
	}

	inputs.push({
		icon: displayed.notified ? "notificationsOn" : "notificationsOff",
		label: ct("common:enabled"),
		rules: [{rule: Rules.notEmpty, type: "error"}],
		type: {
			key: "checkbox",
			onChangeValue: (notified: boolean) => setUpdated(prev => ({...prev, notified})),
			value: displayed.notified,
		},
	});
	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectNotificationTarget")} exitConfirmation={isUpdated}/>
			<Form
				hideReset
				validation={{
					buttonProps: {icon: "check", text: ct(item ? "common:update" : "common:confirm")},
					onValidation: () => {
						onSelect?.(displayed as NotificationTarget);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const SelectNotificationTargetModal = (props: SharedScreenProps<"SelectNotificationTargetModal">): JSX.Element => (
	<ModalWrapper>
		<SelectNotificationTarget {...props} />
	</ModalWrapper>
);
