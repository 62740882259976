import {useMemo} from "react";
import * as React from "react";
import {ToccoSetting} from "../../../../@types/settings";
import {CalendarList} from "../../../../components/calendar/calendar-list";
import {Icon} from "../../../../components/icons";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {createMonthCalendar} from "../../../../utils/date-time/activities";
import {
	addTime,
	FIRST_DAY_CALENDAR,
	FIRST_DAY_CURRENT_MONTH,
	isDayAfter,
	isDayBefore,
	LAST_DAY_CALENDAR,
} from "../../../../utils/date-time/helpers";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {forceBack} from "../../../navigation";

// Create months here instead of useMemo in component to avoid re-computing it everytime we open the modal
const DEFAULT_MONTHS = createMonthCalendar(FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR, {});

const SelectDateCalendar = ({
	route,
	navigation,
}: SharedScreenProps<"SelectDateCalendarModal">): JSX.Element => {
	const {
		firstAllowedBookingDate,
		focusDate: focusDateParam,
		inSessionCreation,
		lastAllowedBookingDate,
		months: monthsParam,
		onSelect: onSelectParam,
	} = route.params ?? {};

	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};

	const {t} = useTranslation();
	const [focusDate, setFocusDate] = React.useState(focusDateParam ?? new Date());
	const onTodayPress = React.useCallback(
		() => setFocusDate(new Date()),
		[],
	);

	const months = useMemo(
		() => {
			if (monthsParam) {
				return monthsParam;
			}

			if (!inSessionCreation) {
				return DEFAULT_MONTHS;
			}

			// Tocco users can create sessions up to 6 months in the future
			const toccoDateLimit = addTime(new Date(), 6, "month");
			return createMonthCalendar(
				FIRST_DAY_CURRENT_MONTH,
				usingTocco ? toccoDateLimit : LAST_DAY_CALENDAR,
				{},
				(day: Date) => {
					const today = new Date();
					const disableBeforeToday = isDayBefore(day, today);
					const disableAfterToccoLimit = usingTocco ? isDayBefore(toccoDateLimit, day) : false;
					const disableBeforeFirst = firstAllowedBookingDate ? isDayBefore(day, firstAllowedBookingDate) : false;
					const disableAfterLast = lastAllowedBookingDate ? isDayAfter(day, lastAllowedBookingDate) : false;

					return disableBeforeToday || disableAfterToccoLimit || disableBeforeFirst || disableAfterLast;
				},
			);
		},
		[firstAllowedBookingDate, inSessionCreation, lastAllowedBookingDate, monthsParam, usingTocco],
	);

	const selectDate = React.useCallback(
		(date: Date) => {
			onSelectParam?.(date);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelectParam],
	);

	const onSelect = React.useCallback(
		(date: Date) => {
			selectDate(date);
			/* TODO : Remove above line and add this for Sunday booking warning
			if (inSessionCreation && daysOfTheWeek.at(date.getDay()) === "sunday") {
				confirmation(
					{
						actions: [
							{
								icon: "check",
								key: "confirm",
								onPress: () => selectDate(date),
								text: ct("common:ok"),
							}, {
								icon: "close",
								key: "closeDrawer",
								onPress: () => null,
								text: t("screens:creation.selectDate.anotherDay"),
								type: "secondary",
							},
						],
						content: t("screens:creation.selectDate.sundayWarning"),
					},
				);
			} else {
				selectDate(date);
			}
			*/
		},
		[selectDate],
	);
	return (
		<>
			<HeaderMenu
				center={t("forms:inputs.selectDate")}
				right={{beforeHelp: <Icon icon="focus" onPress={onTodayPress}/>}}
			/>
			<CalendarList
				onDayPress={onSelect}
				focusDate={focusDate}
				months={months}
				activitiesFetching={false} // Must be set to false so that scrollToIndex works when clicking on Today button
				startDate={inSessionCreation ? FIRST_DAY_CURRENT_MONTH : undefined}
			/>
		</>
	);
};

export const SelectDateCalendarModal = (props: SharedScreenProps<"SelectDateCalendarModal">): JSX.Element => (
	<ModalWrapper>
		<SelectDateCalendar {...props} />
	</ModalWrapper>
);
