import {Place} from "../../@types/places";
import {SupportedLocaleKey} from "../../utils/locales/locales";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";

export type GoogleLocationsRequests = {
	GET: {
		queryParams: {
			limit: string,
			input: string,
			language: string,
			latitude?: string,
			longitude?: string,
		},
	},
};

export type GoogleLocationsResponse = {address: string, geocode: {lat: number, lng: number}};

export const getPlaceSuggestions = async (
	address: string, locale: SupportedLocaleKey, geocode?: Place["geocode"],
): Promise<Place[]> => {
	const response = await api(
		"googleLocations",
		"GET",
		{
			queryParams: {
				limit: "4",
				input: address,
				language: locale.slice(0, 2),
				latitude: geocode?.lat.toString(),
				longitude: geocode?.lng.toString(),
			},
		},
	) as ApiResponse<GoogleLocationsResponse[]>;
	return response.isSuccess ? response.data : Promise.reject(new Error(response.message));
};
