import {Receiver, ReceiverPreview} from "../../../@types/identities/receiver";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {Patient} from "../../@types/person";
import {transformGenderBackToFront} from "../../utils/transform-common";

export type InterpreterReceiverListRequests = {
	GET: {
		queryParams: {
			searchText?: string,
		},
	},
};

export const getInterpreterReceivers = async (pagination: Pagination):
Promise<Page<ReceiverPreview>> => {
	const interpreterResponse = await api(
		"interpreterReceiverList",
		"GET",
		{
			queryParams: {},
		},
	) as ApiResponse<{count: number, patient: Patient[]}>;
	if (interpreterResponse.isSuccess) {
		const patientData = interpreterResponse.data.patient;
		const patientList: Receiver[] = patientData.map(receiver => ({
			identityId: receiver.id.toString(),
			accountId: receiver.id.toString(),
			fullName: {
				firstName: receiver.firstName,
				lastName: receiver.lastName,
			},
			email: receiver.email,
			phone: receiver.telephone,
			gender: transformGenderBackToFront(receiver.gender),
			type: "receiver",
		}));
		const {count} = interpreterResponse.data;
		const {offset, limit} = pagination;
		return {
			items: patientList,
			hasPreviousPage: offset > 0,
			hasNextPage: count > (limit * (offset + 1)),
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};

export const searchInterpreterReceivers = async (search: string, pagination: Pagination):
Promise<Page<ReceiverPreview>> => {
	const interpreterResponse = await api(
		"interpreterReceiverList",
		"GET",
		{
			queryParams: {searchText: search},
		},
	) as ApiResponse<{count: number, patient: Patient[]}>;
	if (interpreterResponse.isSuccess) {
		const patientData = interpreterResponse.data.patient;
		const patientList = patientData.map(receiver => ({
			identityId: receiver.id.toString(),
			accountId: receiver.id.toString(),
			fullName: {
				firstName: receiver.firstName,
				lastName: receiver.lastName,
			},
			email: receiver.email,
			phone: receiver.telephone,
			gender: transformGenderBackToFront(receiver.gender),
			type: "receiver" as ReceiverPreview["type"],
		}));
		const {count} = interpreterResponse.data;
		const {offset, limit} = pagination;
		return {
			items: patientList,
			hasPreviousPage: offset > 0,
			hasNextPage: count > (limit * (offset + 1)),
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};
