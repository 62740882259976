import * as React from "react";
import {StyleSheet, View} from "react-native";
import {RatingValue} from "../utils/feedbacks";
import {GREEN, LIME, ORANGE, RED, YELLOW} from "../utils/styles/colors";
import {Icon, IconProps} from "./icons";
import {Pressable} from "./views/pressable";

const RATINGS: {color: string; value: RatingValue}[] = [
	{color: GREEN, value: 5},
	{color: LIME, value: 4},
	{color: YELLOW, value: 3},
	{color: ORANGE, value: 2},
	{color: RED, value: 1},
];

interface RatingIconProps {
	color: IconProps["color"];
	disabled?: boolean;
	iconFull: IconProps["icon"];
	iconOutline: IconProps["icon"];
	onPress: () => void;
	selected?: boolean;
}

const RatingIcon = ({iconFull, iconOutline, color, onPress, selected, disabled}: RatingIconProps): JSX.Element => {
	const [hovered, setHovered] = React.useState(false);
	return (
		<Pressable
			onPress={onPress}
			onHoverIn={() => { setHovered(true); }}
			onHoverOut={() => { setHovered(false); }}
		>
			<Icon
				size={32}
				icon={selected ? iconFull : iconOutline}
				color={(selected || hovered) ? color : undefined}
				disabled={disabled}
			/>
		</Pressable>
	);
};

export const Rating = ({
	value: valueProp,
	onChangeValue,
	disabled,
}: {
	disabled?: boolean;
	onChangeValue?: (value: RatingValue) => void;
	value?: RatingValue;
},
): JSX.Element => {
	const onPress = (value: RatingValue) => () => onChangeValue?.(value);
	return (
		<View style={styles.container}>
			{RATINGS.map(({color, value}) => (
				<RatingIcon
					key={color}
					iconFull={`rating${value}`}
					iconOutline={`rating${value}Outline`}
					color={color}
					onPress={onPress(value)}
					selected={valueProp === value}
					disabled={disabled}
				/>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
	},
});
