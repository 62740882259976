import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {ActivityIndicator, GestureResponderEvent, StyleSheet, View} from "react-native";
import {ProvidedService} from "../../../../@types/activities/mandate";
import {InPerson, Phone, SessionSlot, Video} from "../../../../@types/activities/session";
import {WaveInterpreterProvider} from "../../../../@types/identities/interpreter";
import {Button} from "../../../../components/buttons/button";
import {Icon} from "../../../../components/icons";
import {ButtonsBar} from "../../../../components/menus/buttons-bar";
import {HeaderMenu} from "../../../../components/menus/header";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {Separator} from "../../../../components/separator";
import {Spacer} from "../../../../components/spacer";
import {Text} from "../../../../components/texts/text";
import {TooltipWrapper} from "../../../../components/tooltip/tooltip-wrapper";
import {Drawer} from "../../../../components/views/drawer";
import {Pressable} from "../../../../components/views/pressable";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {getInterpretersByIds, getSessionsSlots} from "../../../../requests/clients/new-session/session-slots";
import {
	CONTAINERS,
	DEFAULT_SPACING,
	EXTRA_LARGE_SPACING,
	LARGE_SPACING,
	SMALL_SPACING,
} from "../../../../utils/constants";
import {
	customFormatDate,
	formatDate,
	formatDurationInHM,
	formatShortestDate,
	formatTime,
} from "../../../../utils/date-time/format";
import {addTime} from "../../../../utils/date-time/helpers";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {t} from "../../../../utils/locales/translations";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {
	BACKGROUND_COLOR,
	LIGHT_BLUE,
	PRIMARY_2,
	SUBTLE,
	SUBTLE_2,
	SUBTLE_4,
	WHITE,
	WHITE_OVERLAY,
} from "../../../../utils/styles/colors";
import {AddressListItem} from "../modals/address-list";
import {onSessionCreationError} from "./utils";

interface TimeSlotProps {
	disabled?: boolean;
	empty?: boolean;
	every15Min?: boolean;
	interpreters: number;
	left?: boolean;
	onPress: (event: GestureResponderEvent) => void;
	right?: boolean;
	selected?: boolean;
	startTime: Date;
}

type OverlayMessages = "error" | "noSlots";

const CONTENT_ACTIVE_COLOR = PRIMARY_2;
const BACKGROUND_ACTIVE_COLOR = LIGHT_BLUE;

export const LOADER_SIZE = 48;

const TimeSlot = ({
	disabled: disabledProp, left, right, interpreters, onPress, startTime, every15Min, selected, empty,
}: TimeSlotProps): JSX.Element => {
	const [pressed, setPressed] = React.useState(false);
	const togglePressed = (value: boolean) => () => setPressed(value);
	const disabled = disabledProp || interpreters <= 0;
	const color = selected
		? pressed ? WHITE : CONTENT_ACTIVE_COLOR
		: disabled
			? SUBTLE_4
			: pressed ? WHITE : PRIMARY_2;
	const style = [
		styles.timeSlot,
		{paddingHorizontal: every15Min ? SMALL_SPACING : DEFAULT_SPACING},
		selected && styles.selected,
		left && styles.left,
		right && styles.right,
	];
	return empty
		? <View style={style}/>
		: (
			<Pressable
				style={[styles.timeSlotNotEmpty, style, disabled && {backgroundColor: SUBTLE}]}
				underlayColor={PRIMARY_2}
				onPress={onPress}
				onPressIn={togglePressed(true)}
				onPressOut={togglePressed(false)}
				disabled={disabled}
			>
				<View>
					<View style={styles.timeSlotLine1}>
						<Icon
							icon={interpreters === 1 ? "person" : "people"}
							containerSize={15}
							size={15}
							containerStyle={styles.iconStyle}
							color={color}
						/>
						<Text type="label_2" color={color} selectable={false}>
							{interpreters}
						</Text>
					</View>
					<View>
						<Text
							color={color}
							type="button_1"
							size={every15Min ? 16 : 18}
							selectable={false}
						>
							{`${formatTime(startTime)}`}
						</Text>
					</View>
				</View>
			</Pressable>
		);
};

const getEmptySlots = (slots: SessionSlot[], every15Min: boolean): SessionSlot[] => {
	const emptySlots: SessionSlot[] = [];
	const firstSlot = slots[0];
	if (firstSlot) {
		const step = every15Min ? 15 : 30;
		const minutes = Number(customFormatDate(firstSlot.start, "mm"));
		Array.from({length: minutes / step}, (_, i) => (i + 1) * step).forEach(el => {
			const start = addTime(firstSlot.start, -el, "minute");
			emptySlots.unshift({
				availableInterpreterIds: [] as SessionSlot["availableInterpreterIds"],
				end: addTime(start, step, "minute"),
				start,
			});
		});
	}
	return emptySlots;
};

export const ScheduleStep = (
	{
		inlineMode,
		onBack,
		onValidate,
		onError,
	}: {
		inlineMode?: boolean;
		onBack: () => void;
		onError: () => void;
		onValidate: () => void;
	},
): JSX.Element => {
	const {ct} = useTranslation();
	const {
		session,
		setSession,
		setSlot,
		filters,
		initialProvider,
		initialExcludedInterpreterIds,
		slot: selectedSlot,
		loadingProviders,
		setLoadingProviders,
	} = React.useContext(SessionCreationContext);
	const {columns} = React.useContext(ResponsiveContext);
	const [{every15Min, emptySlots, slots, overlayMessage}, setState] = React.useState<{
		emptySlots: SessionSlot[];
		every15Min: boolean;
		overlayMessage?: OverlayMessages;
		slots: SessionSlot[];
	}>({emptySlots: [], every15Min: true, overlayMessage: undefined, slots: []});
	const {
		activityId,
		communication,
		context,
		duration,
		immediate,
		language,
		providedService,
		providers,
		start,
		toLanguage,
		type,
	} = session;
	const navigation = useNavigation();

	const setDataAndNavigate = React.useCallback(
		(slot: SessionSlot) => (fetchedProviders?: WaveInterpreterProvider[]) => {
			setSlot(slot);
			// when clicking on timeslot, we reset the excludedInterpreters
			setSession(prev => ({
				...prev,
				excludedInterpreters: [],
				providers: fetchedProviders ?? [...prev.providers, ...prev.excludedInterpreters],
			}));
			onValidate();
		},
		[setSlot, setSession, onValidate],
	);

	const onTimeSlotPress = React.useCallback(
		(slot: SessionSlot) => () => {
			/*
			 * Query only if we don't have the data we need.
			 */
			if (
				providers.length === slot.availableInterpreterIds.length &&
				providers.every(el => slot.availableInterpreterIds.includes(el.accountId))
			) {
				setDataAndNavigate(slot)();
			} else {
				setLoadingProviders(true);
				getInterpretersByIds(slot.availableInterpreterIds, (communication as InPerson)?.place)
					.then(setDataAndNavigate(slot))
					.catch(onSessionCreationError("getDataFailed", onError))
					.finally(() => setLoadingProviders(false));
			}
		},
		[communication, onError, providers, setDataAndNavigate, setLoadingProviders],
	);
	const onFilterPress = React.useCallback(
		() => navigation.navigate("SessionCreationFiltersModal"),
		[navigation],
	);
	const onInPersonButtonPress = React.useCallback(
		() => navigation.navigate(
			"SelectPlaceModal",
			{
				onSelect: (place: AddressListItem) => setSession(prev => ({
					...prev,
					communication: {
						place: place.displayed,
						placeInfos: (prev.communication as InPerson)?.placeInfos,
						type: "inPerson",
					},
				})),
				place: (communication as InPerson)?.place,
			},
		),
		[communication, navigation, setSession],
	);
	const onVideoButtonPress = React.useCallback(
		() => setSession(prev => ({
			...prev,
			communication: {
				channelId: (prev.communication as Video)?.channelId,
				secret: (prev.communication as Video)?.secret,
				token: (prev.communication as Video)?.token,
				type: "video",
			},
		})),
		[setSession],
	);
	const onPhoneButtonPress = React.useCallback(
		() => setSession(prev => ({
			...prev,
			communication: {phoneNumber: (prev.communication as Phone)?.phoneNumber, type: "phone"},
		})),
		[setSession],
	);

	React.useEffect(
		() => {
			if (!immediate) {
				setLoadingProviders(true);
				getSessionsSlots(
					/*
					 * Subset of session containing data we know are available when we reach this step.
					 * The idea is that we don't want to depend on the whole session to avoid doing
					 * getSessionSlots when values from details step are modified
					 */
					{
						activityId,
						communication,
						context,
						duration,
						immediate,
						language,
						providedService: {type: providedService?.type} as ProvidedService,
						start,
						toLanguage,
						type,
					},
					filters,
					every15Min,
					initialProvider,
				)
					.then(slots => {
						// filter the available interpreters with initialExcludedInterpreterIds
						slots.forEach(slot => {
							slot.availableInterpreterIds = slot.availableInterpreterIds.filter(
								id => !initialExcludedInterpreterIds.includes(id));
						});

						setState(prev => ({
							...prev,
							emptySlots: getEmptySlots(slots, prev.every15Min),
							overlayMessage: slots.length === 0 ? "noSlots" : undefined,
							slots,
						}));
						// reset the selected slot only if the new slots don't contain the current selectedSlot & same interpreters
						if (selectedSlot && !slots.some(s => s.start === selectedSlot.start &&
							s.end === selectedSlot.end &&
							s.availableInterpreterIds.length === selectedSlot.availableInterpreterIds.length &&
							s.availableInterpreterIds.every(id => selectedSlot.availableInterpreterIds.includes(id)),
						)) {
							setSlot(null);
						}
					})
					.catch(error => {
						onSessionCreationError("getDataFailed", onError)(error);
						setState(prev => ({
							...prev,
							overlayMessage: "error",
						}));
					})
					.finally(() => setLoadingProviders(false));
			}
		},
		// we don't want selectedSlot in the dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			activityId, context, communication, filters, immediate, language, every15Min, start, duration,
			providedService?.type, initialProvider, setSlot, toLanguage, type, onError,
		],
	);

	const on15MinPress = React.useCallback(() => {
		setState(prev => ({
			...prev,
			emptySlots: getEmptySlots(prev.slots, !prev.every15Min),
			every15Min: !prev.every15Min,
		}));
	}, []);

	const renderSlot = React.useCallback(
		(slot: SessionSlot, index: number, empty: boolean) => (
			<View style={[styles.slot, {flexBasis: every15Min ? "25%" : "50%"}]} key={formatTime(slot.start)}>
				<TimeSlot
					startTime={slot.start}
					interpreters={slot.availableInterpreterIds.length}
					onPress={onTimeSlotPress(slot)}
					left={index % (every15Min ? 4 : 2) === 0}
					right={index % (every15Min ? 4 : 2) === (every15Min ? 3 : 1)}
					every15Min={every15Min}
					selected={slot.start.getTime() === selectedSlot?.start.getTime()}
					disabled={immediate}
					empty={empty}
				/>
				<Spacer mode="vertical" size={2}/>
			</View>
		),
		[every15Min, immediate, onTimeSlotPress, selectedSlot?.start],
	);

	return (
		<>
			<HeaderMenu left={<Icon icon="back" onPress={onBack}/>} center={ct("screens:creation.myAppointment")}/>
			{immediate
				? (
					<View style={styles.overlay}>
						{loadingProviders
							? <ActivityIndicator color={SUBTLE_4} size={LOADER_SIZE}/>
							: providers.length > 0
								? (
									<Text type="title_2" centered>
										{ct("screens:creation.scheduleOverlay.immediate", {interpreters: providers.length})}
									</Text>
								)
								: (
									<>
										<Text type="title_2" centered>
											{ct("screens:creation.scheduleOverlay.noInterpreterAvailable")}
										</Text>
										<Text type="title_2" centered>
											{ct("screens:creation.scheduleOverlay.modifyForm")}
										</Text>
									</>
								)
						}
					</View>
				)
				: (
					<>
						<View style={styles.buttonsContainer}>
							<View style={[CONTAINERS.MAIN, styles.buttonsWrapper]}>
								<ButtonsBar
									viewProps={{style: styles.buttonsBar}}
									buttons={[
										!inlineMode && {
											icon: "calendar",
											key: "calendar",
											onPress: () => navigation.navigate(
												"SelectDateCalendarModal",
												{
													focusDate: start,
													inSessionCreation: true,
													onSelect: (value: Date) => setSession(prev => ({...prev, start: value})),
												},
											),
											text: (columns === 1 ? formatShortestDate : formatDate)(start!), // start can't be undefined in schedule step
										},
										{
											disabled: inlineMode,
											icon: "timer",
											key: "duration",
											onPress: () => navigation.navigate("SelectDurationModal", {
												onSelect: (value: number) => setSession(prev => ({...prev, duration: value})),
											}),
											text: `${inlineMode ? `${ct("common:duration")}: ` : ""}${formatDurationInHM(duration!, true)}`, // duration can't be undefined in schedule step
										}, {
											icon: every15Min ? "selectMarked" : "selectEmpty",
											key: "every15Minutes",
											onPress: on15MinPress,
											text: t(`screens:creation.${columns === 1 ? "every15MinShort" : "every15Min"}`),
										},
									]}
								/>
								<Spacer size={SMALL_SPACING} mode="vertical"/>
								<TooltipWrapper
									content={
										<>
											<Text>
												<Text color={WHITE}>{t("screens:creation.interpretersInfo.firstPart")}</Text>
												<Icon
													color={WHITE}
													icon="interpreters"
													size={20}
													containerSize={20}
													containerStyle={{verticalAlign: "bottom"}}
												/>
												<Text color={WHITE}>{t("screens:creation.interpretersInfo.secondPart")}</Text>
											</Text>
										</>
									}
								>
									<Icon icon="info" size={20} containerSize={24}/>
								</TooltipWrapper>
							</View>
						</View>
						<View style={styles.scrollView}>
							<ScrollView
								style={styles.scrollView}
								contentContainerStyle={[CONTAINERS.LIST, CONTAINERS.MAIN]}
							>
								<View style={styles.rowContainer}>
									{emptySlots.map((slot, index) => renderSlot(slot, index, true))}
									{slots.map((slot, index) => renderSlot(slot, index, false))}
								</View>
							</ScrollView>
							{columns === 1 && (overlayMessage ?? loadingProviders) && (
								<View
									style={[
										styles.overlay,
										{
											borderRadius: DEFAULT_SPACING,
											paddingHorizontal: every15Min ? LARGE_SPACING : EXTRA_LARGE_SPACING,
										},
									]}
								>
									{loadingProviders
										? <ActivityIndicator color={SUBTLE_4} size={LOADER_SIZE}/>
										: <Text type="title_2" centered>
											{!!overlayMessage && ct(`screens:creation.scheduleOverlay.${overlayMessage}`)}
										</Text>}
								</View>
							)}
						</View>
						{!inlineMode && (!initialProvider || (initialProvider?.communicationTypes.length > 1)) && (
							<Drawer verticalPosition="bottom">
								<View style={[CONTAINERS.MAIN, styles.drawerContainer, initialProvider && {width: undefined}]}>
									{(!initialProvider || initialProvider.communicationTypes.includes("inPerson")) && (
										<>
											<Button
												size="medium"
												icon={getCommunicationTypeIcon("inPerson")}
												type="secondary"
												contentActiveColor={CONTENT_ACTIVE_COLOR}
												backgroundActiveColor={BACKGROUND_ACTIVE_COLOR}
												active={communication?.type === "inPerson"}
												onPress={onInPersonButtonPress}
											/>
											<Spacer mode="vertical" size={SMALL_SPACING}/>
										</>
									)}
									{(!initialProvider || initialProvider.communicationTypes.includes("video")) && (
										<>
											<Button
												size="medium"
												icon={getCommunicationTypeIcon("video")}
												type="secondary"
												contentActiveColor={CONTENT_ACTIVE_COLOR}
												backgroundActiveColor={BACKGROUND_ACTIVE_COLOR}
												active={communication?.type === "video"}
												onPress={onVideoButtonPress}
											/>
											<Spacer mode="vertical" size={SMALL_SPACING}/>
										</>
									)}
									{(!initialProvider || initialProvider?.communicationTypes.includes("phone")) && (
										<Button
											size="medium"
											icon={getCommunicationTypeIcon("phone")}
											type="secondary"
											contentActiveColor={CONTENT_ACTIVE_COLOR}
											backgroundActiveColor={BACKGROUND_ACTIVE_COLOR}
											active={communication?.type === "phone"}
											onPress={onPhoneButtonPress}
										/>
									)}
									{!initialProvider && (
										<>
											<Separator mode="vertical"
												spacingSize={SMALL_SPACING}
												containerStyle={styles.separatorContainer}/>
											<View style={styles.drawerSelectorsRight}>
												<Button
													size="medium"
													icon="people"
													type="secondary"
													text={ct("common:filter_plural")}
													onPress={onFilterPress}
													fullWidth
													notif={filters ? Object.values(filters).filter(f => !!f).length : 0}
													notifBackgroundColor={PRIMARY_2}
												/>
											</View>
										</>
									)}
								</View>
							</Drawer>
						)}
						{columns > 1 && (overlayMessage || loadingProviders) && (
							<View style={[styles.overlay, every15Min && {paddingHorizontal: LARGE_SPACING}]}>
								{loadingProviders
									? <ActivityIndicator color={SUBTLE_4} size={LOADER_SIZE}/>
									: <Text type="title_2" centered>
										{!!overlayMessage && ct(`screens:creation.scheduleOverlay.${overlayMessage}`)}
									</Text>
								}
							</View>
						)}
					</>
				)
			}
		</>
	);
};

const styles = StyleSheet.create({
	buttonsBar: {
		flex: 1,
		flexDirection: "column",
	},
	buttonsContainer: {
		backgroundColor: SUBTLE,
	},
	buttonsWrapper: {
		alignItems: "center",
		flexDirection: "row",
		paddingHorizontal: DEFAULT_SPACING,
	},
	drawerContainer: {
		backgroundColor: BACKGROUND_COLOR,
		flexDirection: "row",
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	drawerSelectorsRight: {
		flex: 1,
	},
	iconStyle: {
		marginRight: 4,
	},
	left: {
		borderBottomLeftRadius: DEFAULT_SPACING / 4,
		borderTopLeftRadius: DEFAULT_SPACING / 4,
	},
	overlay: {
		alignItems: "center",
		backgroundColor: WHITE_OVERLAY,
		justifyContent: "center",
		paddingHorizontal: EXTRA_LARGE_SPACING,
		textAlign: "center",
		...StyleSheet.absoluteFillObject,
	},
	right: {
		borderBottomRightRadius: DEFAULT_SPACING / 4,
		borderTopRightRadius: DEFAULT_SPACING / 4,
	},
	rowContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		paddingHorizontal: DEFAULT_SPACING,
	},
	scrollView: {
		backgroundColor: SUBTLE,
		flex: 1,
	},
	selected: {
		backgroundColor: BACKGROUND_ACTIVE_COLOR,
	},
	separatorContainer: {
		height: 48,
	},
	slot: {
		flex: 0,
		flexDirection: "row",
		marginBottom: SMALL_SPACING,
	},
	timeSlot: {
		flex: 1,
		paddingVertical: SMALL_SPACING,
	},
	timeSlotLine1: {
		alignItems: "center",
		flexDirection: "row",
	},
	timeSlotNotEmpty: {
		backgroundColor: SUBTLE_2,
	},
});
