import * as ImagePickerExpo from "expo-image-picker";
import {ImagePickerAsset, ImagePickerResult} from "expo-image-picker";
import * as Linking from "expo-linking";
import {Base64Image, ImageMime} from "../../../@types/medias/image";
import {confirmation} from "../../../components/feedbacks/confirmation";
import {IS_IOS, IS_MOBILE, IS_WEB} from "../../constants";
import {Blobs} from "../../externals/blobs";
import {ct} from "../../locales/translations";
import {Log} from "../../logs/logs";

export interface ImagePickerOptions {
	// Should the picker let the image selection be cropped ?
	cropping: boolean;
	// From wich source the media comes from
	from: "both" | "library" | "takePicture";
	// Expect multiple files or not?
	multiple?: boolean;
	// Crop with square aspect
	square?: boolean;
}

export interface PickerImage extends Base64Image {
	// In byte
	blob: Blob;
	path?: string;
	size?: number; // Not available on web and android
}

export class ImagePicker {
	private static resolve: (value: PickerImage | null) => void;
	private static reject: (error: Error) => void;

	public static pick = async (options: ImagePickerOptions): Promise<PickerImage | null> => {
		// https://docs.expo.dev/versions/latest/sdk/imagepicker/#imagepickeroptions
		const pickerOptions: ImagePickerExpo.ImagePickerOptions = {
			allowsEditing: options.cropping,
			allowsMultipleSelection: options.multiple ?? false,
			aspect: options.square ? [1, 1] : undefined,
			base64: true,
			exif: true,
			mediaTypes: ImagePickerExpo.MediaTypeOptions.Images,
			quality: IS_IOS ? 0.7 : 0.9,
		};

		if (IS_MOBILE) {
			confirmation({
				actions: [
					options.from !== "takePicture" && {
						icon: "picture",
						key: "openLibrary",
						onPress: ImagePicker.pickImage("library", pickerOptions),
						text: ct("common:library"),
					},
					options.from !== "library" && {
						icon: "camera",
						key: "openCamera",
						onPress: ImagePicker.pickImage("camera", pickerOptions),
						text: ct("common:camera"),
					}, {
						icon: "close",
						key: "closeDrawer",
						onPress: () => null,
						text: ct("common:cancel"),
						type: "secondary",
					},
				],
				content: null,
			});
		} else {
			ImagePicker.pickImage("library", pickerOptions)();
		}

		return await new Promise((resolve, reject?) => {
			ImagePicker.resolve = resolve;
			ImagePicker.reject = reject;
		});
	};

	private static readonly getFirstImage = (pickerPromise: Promise<ImagePickerResult>): Promise<void> => pickerPromise
		.then((res) => {
			if (res.canceled) {
				return ImagePicker.resolve(null);
			}
			const image: ImagePickerAsset = Array.isArray(res.assets) ? res.assets[0] : res.assets;
			const {base64, uri, fileSize} = image;

			const mime = (IS_MOBILE
				? `image/${(uri.split(".").pop() && uri.split(".").pop() !== "jpg") ? uri.split(".").pop()! : "jpeg"}`
				: uri.split(";")[0].split(":")[1]
			) as ImageMime;

			if (!mime || !base64) {
				throw new Error("No mime or base64 data");
			}

			return Blobs.fromURI(uri)
				.then(blob => ImagePicker.resolve({
					...image,
					blob,
					mediaType: "image",
					mime,
					path: uri,
					size: fileSize,
					source: base64,
					sourceType: "base64",
				}));
		})
		.catch((error) => ImagePicker.reject(error),
		);

	private static readonly pickImage = (
		from: "camera" | "library", options: ImagePickerExpo.ImagePickerOptions) => () => {
		const isCamera = from === "camera";
		(isCamera
			? ImagePickerExpo.getCameraPermissionsAsync()
			: ImagePickerExpo.getMediaLibraryPermissionsAsync()
		).then(permissionResponse =>
			!permissionResponse.granted || permissionResponse.canAskAgain
				? isCamera
					? ImagePickerExpo.requestCameraPermissionsAsync()
					: ImagePickerExpo.requestMediaLibraryPermissionsAsync()
				: permissionResponse,
		)
			.then(permissionResponse =>
				IS_WEB || permissionResponse.granted
					? ImagePicker.getFirstImage(
						isCamera
							? ImagePickerExpo.launchCameraAsync(options)
							: ImagePickerExpo.launchImageLibraryAsync(options))
					: permissionResponse.canAskAgain &&
					ImagePicker.openSettings(ct(`screens:imagePicker.permissions.settings.${isCamera ? "camera" : "library"}`)),
			)
			.catch(Log.error());
	};

	private static readonly openSettings = (content: string): void =>
		confirmation(
			{
				actions: [
					{
						icon: "settings",
						key: "openLibrary",
						onPress: Linking.openSettings,
						text: ct("common:setting_plural"),
					},
					{
						icon: "close",
						key: "closeDrawer",
						onPress: () => null,
						text: ct("common:cancel"),
						type: "secondary",
					},
				],
				content,
			},
		);
}
