import {InterpreterFilters} from "../../@types/identities/filters";
import {Qualification} from "../../@types/qualifications";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";
import {BackendQualification} from "../@types/user";

export const getQualifications = async (interpretersGroups?: InterpreterFilters["interpretersGroups"]): Promise<Qualification[]> => {
	if (!interpretersGroups || interpretersGroups.length === 0) return [];
	const qualificationsResponse = await api(
		"commonQualificationSkills",
		"GET",
		{
			queryParams: {bhaashaGroups: interpretersGroups.map(group => group.id).toString()},
		},
	) as ApiResponse<BackendQualification[]>;
	if (qualificationsResponse.isSuccess) {
		return qualificationsResponse.data.map(skill => ({
			id: skill.id.toString(),
			value: skill.label,
			default: skill.isSelected,
			defaultIsMandatory: skill.isFixedQualificationEnabled,
		}));
	}
	throw new Error(qualificationsResponse.message);
};
