import * as React from "react";
import {View} from "react-native";
import {Session} from "../../../../@types/activities/session";
import {IdentityEditableFields} from "../../../../@types/identities/identity";
import {PersonListedFields} from "../../../../@types/identities/person";
import {Receiver} from "../../../../@types/identities/receiver";
import {AbsentReceiverIssue} from "../../../../@types/validation";
import {getReceiverListedFields, getReceiverEditableFields} from "../../../../requests/common/activities/details";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {getDescriptionLabel, receiverIdentity} from "../../../../utils/identities";
import {TranslationFeedbackKey, TranslationFunc} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {SummaryRow} from "../../../inputs/summary-row";
import {SplashView} from "../../../views/splash-view";
import {DescriptionSummary} from "./description";

export const AbsentReceiverSummary = (
	{issue: {description, person}, sessionId, t}: {issue: AbsentReceiverIssue; sessionId: Session["activityId"]; t: TranslationFunc},
): JSX.Element => {
	const [receiverListedFields, setReceiverListedFields] = React.useState<PersonListedFields<Receiver>>();
	const [receiverEditableFields, setReceiverEditableFields] = React.useState<IdentityEditableFields<Receiver>>();
	const [loadingReceiverSettings, setLoadingReceiverSettings] = React.useState<boolean>(IS_INTERPRETER);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();

	React.useEffect(
		() => {
			Promise.all([
				getReceiverListedFields(sessionId)
					.then(setReceiverListedFields),
				getReceiverEditableFields(sessionId)
					.then(setReceiverEditableFields),
			])
				.catch((error_) => {
					Log.error()(error_);
					setErrorMessage("feedbacks:getDataFailed");
				})
				.finally(() => setLoadingReceiverSettings(false));
		},
		[sessionId],
	);

	return loadingReceiverSettings || errorMessage
		? (
			<SplashView
				centered
				loading={loadingReceiverSettings}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		)
		: (
			<View>
				<SummaryRow label={t("activities:sessions.validate.issue.type.absentReceiver.person")}>
					{receiverIdentity(person, receiverListedFields, getDescriptionLabel(receiverEditableFields))}
				</SummaryRow>
				{!!description && <DescriptionSummary description={description} t={t} />}
			</View>
		);
};
