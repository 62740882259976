import * as React from "react";
import {View} from "react-native";
import {formatTime} from "../../../../utils/date-time/format";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SummaryRow} from "../../../inputs/summary-row";
import {DescriptionSummary} from "./description";

export const EffectiveTimeSummary = ({date, description, t}: {date: Date; description?: string; t: TranslationFunc}): JSX.Element => (
	<View>
		<SummaryRow>
			{formatTime(date)}
		</SummaryRow>
		{!!description && <DescriptionSummary description={description} t={t} />}
	</View>
);
