import React, {SetStateAction} from "react";
import {ActivitiesFilters} from "../@types/activities/filters";
import {toast} from "../components/feedbacks/toast";
import {getClientActivitiesStructure} from "../requests/clients/activities/activities-structure";
import {getInterpreterActivitiesStructure} from "../requests/interpreters/activities/activities-structure";
import {IS_CLIENT, IS_INTERPRETER, SMALL_SPACING} from "../utils/constants";
import {
	ActivitiesCache,
	ActivitiesStructure,
	createMonthCalendar,
	createWeekCalendar,
	MonthCalendar,
	Week,
} from "../utils/date-time/activities";
import {formatDayKey} from "../utils/date-time/format";
import {compareDates, consecutiveDays, FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR} from "../utils/date-time/helpers";
import {useActivitiesHeights} from "../utils/hooks/use-activities-heights";
import {useSessionStatusChangedWS} from "../utils/hooks/use-session-status-changed";
import {personIdentity} from "../utils/identities";
import {Log} from "../utils/logs/logs";
import {getKeys} from "../utils/objects";
import {isFunction} from "../utils/types";
import {AuthentifiedContext} from "./authentified";

const defaultInterpreterActivitiesFilters: ActivitiesFilters["status"] = [
	"confirmed",
	"unvalidated",
	"completed",
	"inProgress",
	"stopped",
];
const defaultUserActivitiesFilters: ActivitiesFilters["status"] = ["confirmed"];
export const allActivitiesFilters: ActivitiesFilters["status"] = [
	"sent",
	"confirmed",
	"refused",
	"canceled",
	"unvalidated",
	"completed",
	"inProgress",
	"stopped",
	"rescheduled",
];

export const defaultActivitiesFilters: ActivitiesFilters = {
	status: IS_INTERPRETER
		? defaultInterpreterActivitiesFilters
		: defaultUserActivitiesFilters,
};

interface State {
	activities: ActivitiesStructure;
	activitiesFetching: boolean;
	focusDate: Date;
	focusIndex: number;
}

export interface CalendarItem {
	key: string; // YYYY-MM-DD
	length: number; // Height of the calendar (depends on number of rows)
	offset: number; // Accumulator of previous lengths
}

export interface ActivitiesContextVal extends State {
	activitiesCache: React.MutableRefObject<ActivitiesCache>;
	/*
	 * Used to remount the activities when changing it to false.
	 * It changes back to true automatically if the calendar is mounted.
	 */
	activitiesMounted: boolean;
	filters: ActivitiesFilters;
	list: CalendarItem[];
	months: MonthCalendar[];
	reset: () => void;
	setActivitiesMounted: (value: boolean) => void;
	setFilters: React.Dispatch<SetStateAction<ActivitiesFilters>>;
	setFocusDate: React.MutableRefObject<(date: Date, transition?: boolean) => boolean>;
	transitionning: React.MutableRefObject<boolean>;
	updateActivities: (updatedfilters?: ActivitiesFilters, updateFocusDate?: boolean) => Promise<void>;
	weeks: Week[];
}

export const TOP_SECTION_SPACING = 0;
export const BOTTOM_SECTION_SPACING = 0;
export const BOTTOM_ITEM_SPACING = SMALL_SPACING;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getInitialValues = (filters: ActivitiesFilters | undefined) => ({
	activitiesCache: {},
	filters: filters ?? defaultActivitiesFilters,
	state: {
		activities: undefined,
		activitiesFetching: true,
		focusDate: undefined,
		focusIndex: undefined,
	} satisfies Partial<State>,
	transitionning: false,
});

export const ActivitiesContext = React.createContext<ActivitiesContextVal>({} as ActivitiesContextVal);
export const ActivitiesProvider = (
	{
		children,
		filters: filtersParam,
		focusDate: focusDateParam,
	}: {
		children: React.ReactNode;
		filters?: ActivitiesFilters;
		focusDate?: Date;
	},
): JSX.Element => {
	const {accountId} = React.useContext(AuthentifiedContext);
	const [
		sectionTitleHeight,
		sessionHeight,
		unavailabilityHeight,
		collapsedDatesHeight,
	] = useActivitiesHeights(IS_CLIENT);
	const initialValues = getInitialValues(filtersParam);
	// Don't use focusDateParam yet to create the state, because we need to fetch the activities structure first
	const [{activities, activitiesFetching, focusDate, focusIndex}, setState] = React.useState<Partial<State>>(initialValues.state);
	const [_, setRender] = React.useState<number>(0);
	const [filters, setFilters] = React.useState({activitiesMounted: true, filters: initialValues.filters});
	const activitiesCache = React.useRef<ActivitiesCache>(initialValues.activitiesCache);
	const transitionning = React.useRef(initialValues.transitionning);
	const customSetFilters = React.useCallback(
		(value: React.SetStateAction<ActivitiesFilters>) => setFilters(prev => ({
			activitiesMounted: false,
			filters: isFunction(value) ? value(prev.filters) : value,
		})),
		[],
	);

	const setActivitiesMounted = React.useCallback(
		(value: boolean) => setFilters(prev => ({...prev, activitiesMounted: value})),
		[],
	);

	const reset = React.useCallback(() => {
		const initialValues = getInitialValues(filtersParam);
		setState(initialValues.state);
		customSetFilters(initialValues.filters);
		activitiesCache.current = initialValues.activitiesCache;
		transitionning.current = initialValues.transitionning;
	}, [filtersParam, customSetFilters]);

	const getNearestFocusDate = React.useCallback(
		(date: Date, from?: ActivitiesStructure) => {
			const time = date.getTime();
			let res: {focusDate: Date; focusIndex: number} = {focusDate: new Date(), focusIndex: -1};
			const list = from ? getKeys(from) : [];
			list.some((dayKey, index) => {
				if (
					// Date found
					new RegExp(formatDayKey(date)).test(String(dayKey)) ||
					/*
					 * Gone past the date (we assume the dates are sorted and ascending)
					 * -> pick current date, which is the one right after the target date
					 */
					new Date(dayKey).getTime() > time ||
					/*
					 * It's the last element and date still not found
					 * -> there are only activities in the past -> pick current, which is the last one.
					 */
					index === list.length - 1
				) {
					res = {focusDate: new Date(dayKey), focusIndex: index};
					return true;
				}
				return false;
			});
			return res;
		},
		[],
	);
	// eslint-disable-next-line @typescript-eslint/no-extra-parens
	const setFocusDate = React.useRef<(fd: Date, transition?: boolean) => boolean>(() => false);

	React.useEffect(() => {
		setFocusDate.current = (fd: Date, transition = true) => {
			if (transition) {
				transitionning.current = true;
			}
			const nearestFocusDate = getNearestFocusDate(fd, activities);
			const currentFocusDate = focusDate;
			if (nearestFocusDate.focusDate && nearestFocusDate.focusIndex !== focusIndex) {
				setState((prev) => ({...prev, ...nearestFocusDate}));
			}
			return compareDates(nearestFocusDate.focusDate, currentFocusDate);
		};
	}, [focusIndex, getNearestFocusDate, activities, focusDate]);
	const weeks = React.useMemo(
		() => activities ? createWeekCalendar(FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR, activities) : [],
		[activities],
	);

	const months = React.useMemo(
		() => activities ? createMonthCalendar(FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR, activities) : [],
		[activities],
	);

	const list = React.useRef<CalendarItem[]>([]);

	const updateActivities = React.useCallback((updatedfilters?: ActivitiesFilters, updateFocusDate = false) => {
		// We want to show the loader only when activities were never fetched before (if activities are not defined)
		setState(prev => !prev.activitiesFetching && !prev.activities ? ({...prev, activitiesFetching: true}) : prev);
		if (updatedfilters) {
			customSetFilters(updatedfilters);
			transitionning.current = true;
		}
		return (IS_INTERPRETER
			? getInterpreterActivitiesStructure
			: getClientActivitiesStructure)(
			FIRST_DAY_CALENDAR,
			LAST_DAY_CALENDAR,
			updatedfilters ?? filters.filters,
		).then((data) => {
			activitiesCache.current = {};
			list.current = [];
			let offsetAcc = 0;
			const entries = Object.entries(data);
			entries.forEach(([dayKey, dayActivities], index) => {
				activitiesCache.current[dayKey] = activitiesCache.current[dayKey]
					? {activities: activitiesCache.current[dayKey].activities, state: "stale"}
					: {activities: dayActivities, state: "unfetched"};
				const hasCollapsedDates = !consecutiveDays(dayKey, entries[index + 1]?.[0]);
				const length =
					sectionTitleHeight +
					// https://github.com/microsoft/TypeScript/issues/14520
					((sessionHeight + BOTTOM_ITEM_SPACING) * dayActivities.filter(
						activity => activity === "interpreterMandate",
					).length) +
					((unavailabilityHeight + BOTTOM_ITEM_SPACING) * dayActivities.filter(
						activity => activity === "unavailability",
					).length) +
					TOP_SECTION_SPACING +
					(dayActivities.length > 0 ? BOTTOM_SECTION_SPACING : 0) +
					(hasCollapsedDates ? collapsedDatesHeight : 0);
				const offset = offsetAcc;
				offsetAcc += length;
				list.current.push({key: dayKey, length, offset});
			});
			setState(prev => ({
				...prev,
				activities: data,
				activitiesFetching: false,
				...(!prev.focusDate || updateFocusDate) && getNearestFocusDate(focusDateParam || new Date(), data),
			}));
		}).catch((error) => {
			Log.error("getCalendarDataFailed")(error);
			setState((prev) => ({...prev, activitiesFetching: false}));
		});
	}, [
		collapsedDatesHeight,
		customSetFilters,
		filters,
		focusDateParam,
		getNearestFocusDate,
		sectionTitleHeight,
		sessionHeight,
		unavailabilityHeight,
	]);

	useSessionStatusChangedWS({
		connect: !!accountId && IS_CLIENT,
		onData: (session) => {
			const dayKey = formatDayKey(session.start);
			const entry = activitiesCache.current[dayKey];
			const index = (entry?.activities ?? []).findIndex(
				el => typeof el !== "string" && el.activityId === session.activityId,
			);

			/*
			 * If session is in cache, it means it was on the list we are viewing -> update the session. Otherwise, it means
			 * the session would need to appear in the list we are viewing as it's not in it, so update activities again
			 */
			if (index === -1) {
				updateActivities(filters.filters).catch(Log.error());
			} else {
				activitiesCache.current[dayKey].activities[index] = session;
			}

			if (session.status === "confirmed") {
				toast({
					key: "sessionStatusChangedConfirmed",
					options: {id: session.activityId, interpreter: personIdentity(session.providers[0])},
					severity: "success",
				});
			} else if (session.status === "refused") {
				toast({
					key: "sessionStatusChangedRefused",
					options: {id: session.activityId},
					severity: "error",
				});
			}
			setRender(Date.now());
		},
	});

	return (
		<ActivitiesContext.Provider
			value={{
				activities: activities!,
				activitiesCache,
				activitiesFetching: activitiesFetching!,
				activitiesMounted: filters.activitiesMounted,
				filters: filters.filters,
				focusDate: focusDate!,
				focusIndex: focusIndex!,
				list: list.current,
				months,
				reset,
				setActivitiesMounted,
				setFilters: customSetFilters,
				setFocusDate,
				transitionning,
				updateActivities,
				weeks,
			}}
		>
			{children}
		</ActivitiesContext.Provider>
	);
};
