import {BUNDLE_IDENTIFIER, BUNDLE_VERSION, IS_IOS, PLAY_STORE_URL} from "../../utils/constants";

// https://github.com/kimxogus/react-native-version-check/blob/master/packages/react-native-version-check/src/providers/appStore.js
const getAppStoreLatestVersion = (): Promise<string | null> => {
	const dateNow = Date.now();

	return fetch(`https://itunes.apple.com/lookup?bundleId=${BUNDLE_IDENTIFIER}&date=${dateNow}`)
		.then(res => {
			return res.json();
		})
		.then(json => {
			if (json.resultCount) {
				return json.results[0].version;
			}
			return null;
		});
};

// https://github.com/kimxogus/react-native-version-check/blob/master/packages/react-native-version-check/src/providers/playStore.js
const getPlayStoreLatestVersion = (): Promise<string | null> => {
	const opt = {
		headers: {"sec-fetch-site": "same-origin"},
	};

	return fetch(PLAY_STORE_URL, opt)
		.then(res => res.text())
		.then(text => {
			const match = text.match(/Current Version.+?>([\d.-]+)<\/span>/);
			if (match) {
				return match[1].trim();
			}

			const matchNewLayout = text.match(/\[\[\["([\d.]+?)"]]/);
			if (matchNewLayout) {
				return matchNewLayout[1].trim();
			}
			return null;
		});
};

const getLatestVersion = (): Promise<string | null> =>
	IS_IOS ? getAppStoreLatestVersion() : getPlayStoreLatestVersion();

export const checkNeedUpdate = (): Promise<boolean> => {
	return getLatestVersion()
		.then(latestVersion => {
			// https://stackoverflow.com/a/65687141
			return !!latestVersion && latestVersion.localeCompare(BUNDLE_VERSION, undefined, {numeric: true, sensitivity: "base"}) > 0;
		});
};
