import {InterpreterFilters} from "../../../../@types/identities/filters";
import {Identity} from "../../../../@types/identities/identity";
import {Interpreter} from "../../../../@types/identities/interpreter";
import {PersonIdentity, PersonIdentityPreview} from "../../../../@types/identities/person";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {getDescriptionLabel, personIdentity, receiverIdentity} from "../../../identities";
import {Locales} from "../../../locales/locales";
import {ct, t} from "../../../locales/translations";
import {nRandomlyFrom, randomBoolean, randomNumber} from "../../../randoms";
import {textContains} from "../../../searches";
import {requesterReceiverEditableFields} from "../settings/shared-data";

export const generateQualifications = (qualificationsExamples: Interpreter["qualifications"]): Interpreter["qualifications"] => {
	let qualifications: Interpreter["qualifications"] = {};
	if (randomBoolean()) {
		qualifications = {
			...qualifications,
			"qualification INTERPRET": nRandomlyFrom(
				qualificationsExamples["qualification INTERPRET"],
				randomNumber(qualificationsExamples["qualification INTERPRET"].length - 1, 1),
			),
		};
	}
	if (randomBoolean()) {
		const quals = nRandomlyFrom(
			qualificationsExamples.modules, randomNumber(qualificationsExamples.modules.length - 1, 1));
		if (!quals.includes(qualificationsExamples.modules[0])) {
			quals.push(qualificationsExamples.modules[0]);
		}
		qualifications = {
			...qualifications,
			modules: quals,
		};
	} else {
		/*
		 * The module 1 is the default qualification in interpreters data.
		 * Doing that ensure we always have interpreters with the default filters
		 */
		qualifications = {
			...qualifications,
			modules: [qualificationsExamples.modules[0]],
		};
	}
	if (randomBoolean()) {
		qualifications = {
			...qualifications,
			autres: nRandomlyFrom(qualificationsExamples.autres, randomNumber(qualificationsExamples.autres.length - 1, 1)),
		};
	}
	return qualifications;
};

export const fakeIdentitySort = (a: Identity, b: Identity): number => {
	const comparedIdentity = (p: Identity): string =>
		p.type === "person" || p.type === "interpreter"
			? p.fullName?.lastName || p.fullName?.firstName || "-"
			: p.type === "receiver"
				? p.fullName?.lastName || p.fullName?.firstName || p.evamNumber?.value || p.ippNumber?.value || "-"
				: p.type === "corporation"
					? p.name
					: "-";
	const toCompareA = comparedIdentity(a);
	const toCompareB = comparedIdentity(b);

	return Locales.compare(toCompareA, toCompareB);
};

export const fakePersonFilter = (searched: string) => (person: PersonIdentity | PersonIdentityPreview): boolean => {
	const fullName = personIdentity(person);
	return textContains(searched, fullName === t("users:person.unknownPerson") ? ct(`common:${person.type}`) : fullName);
};

export const fakeReceiverFilter = (searched: string) => (receiver: Receiver | ReceiverPreview): boolean => {
	const fullName = receiverIdentity(
		receiver,
		new Set(["firstName", "lastName", "gender", "evamNumber", "ippNumber", "description"]),
		getDescriptionLabel(requesterReceiverEditableFields.value),
	);
	return textContains(
		searched, fullName === t("users:person.unknownPerson") ? ct(`common:${receiver.type}`) : fullName);
};

export const fakeFilterInterpreters = (interpreter: Interpreter, filters: InterpreterFilters = {}): boolean => {
	const activeFilters: {[key in keyof InterpreterFilters]: boolean} = {};
	if (filters.communicationTypes) {
		activeFilters.communicationTypes = filters.communicationTypes ===
			interpreter.communicationTypes;
	}
	if (filters.firstName) {
		activeFilters.firstName = interpreter.fullName.firstName.toLocaleLowerCase()
			.includes(filters.firstName.toLocaleLowerCase());
	}
	if (filters.language) {
		activeFilters.language = interpreter.languages.flatMap(l => [l.from, l.to])
			.includes(filters.language);
	}
	if (filters.gender) {
		activeFilters.gender = filters.gender === interpreter.gender;
	}
	if (filters.lastName) {
		activeFilters.lastName = interpreter.fullName.lastName.toLocaleLowerCase()
			.includes(filters.lastName.toLocaleLowerCase());
	}
	if (filters.origins) {
		activeFilters.origins = filters.origins.includes(interpreter.origin);
	}
	if (filters.qualifications) {
		const interpreterQualifications = new Set(Object.values(interpreter.qualifications).flat());
		activeFilters.qualifications = interpreter.qualifications
			// eslint-disable-next-line unicorn/no-array-reduce
			? filters.qualifications.map(q => interpreterQualifications.has(q)).reduce((a, b) => a && b, true)
			: false;
	}
	// eslint-disable-next-line unicorn/no-array-reduce
	return Object.values(activeFilters).reduce((f1, f2) => f1 && f2, true);
};
