import * as React from "react";
import {Animated, GestureResponderEvent} from "react-native";
import {Corporation} from "../../@types/identities/corporation";
import {AccountHeader} from "./account-header";

interface Props {
	buttons?: JSX.Element[];
	corporation: Pick<Corporation, "group" | "name" | "picture" | "type">;
	editable?: (event: GestureResponderEvent) => void;
	scrollY: Animated.Value;
}

export const ClientHeader = ({corporation, ...props}: Props): JSX.Element =>
	<AccountHeader person={corporation} {...props} />;
