import React from "react";
import {
	ActivityIndicator,
	SectionListData,
	SectionListRenderItem as RNSectionListRenderItem,
	SectionListRenderItemInfo,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
	ViewToken,
} from "react-native";
import {Popover} from "react-native-popper";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {useSelectList} from "../../utils/hooks/use-select-list";
import {useTranslation} from "../../utils/hooks/use-translation";
import {StackParamList} from "../../utils/navigation/paramLists/root-param-list";
import {getKeys} from "../../utils/objects";
import {BACKGROUND_COLOR, BLUE, SUBTLE_4} from "../../utils/styles/colors";
import {ELEVATIONS} from "../../utils/styles/elevations";
import {Button, Buttons} from "../buttons/button";
import {SectionList, SectionListProps} from "../scrollables/section-list";
import {SplashView} from "../views/splash-view";
import {ListEmpty} from "./items/list-empty";
import {Item, ListPropsCommon, OnlyKeysExtendingSelectListType} from "./list-props.common";

export type Section<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
> = SectionListData<I, {index: number; title: string}>;

export interface SectionListRenderItemParams<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
> {
	buttons: Buttons;
	currentSearch: string;
	focusedStyle?: StyleProp<ViewStyle>;
	info: SectionListRenderItemInfo<I, Section<IdKey, I>>;
}

type SectionListRenderItem<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
> = ({
	info,
	buttons,
	currentSearch,
	focusedStyle,
}: SectionListRenderItemParams<IdKey, I>) => React.ReactElement | null;

type Props<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> = ListPropsCommon<IdKey, I, RouteName> & {
	renderItem: SectionListRenderItem<IdKey, I>;
	sectionForItem: (item: I) => string;
	sectionListProps?: Omit<Partial<SectionListProps<I, Section<IdKey, I>>>, "renderItem">;
};

export const SelectSectionList = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	Screen extends OnlyKeysExtendingSelectListType<StackParamList, I>,
>(
	props: Props<IdKey, I, Screen>,
): JSX.Element => {
	const {
		renderItem: renderItemProp,
		sectionForItem,
		itemTranslation,
		itemTranslationKey,
		sectionListProps,
		dropDown,
		onSearch,
		onPressEnter,
		idKey,
	} = props;
	const [sections, setSections] = React.useState<Section<IdKey, I>[]>([]);
	const {ct} = useTranslation();
	const sectionListRef = React.useRef<SectionList<I, Section<IdKey, I>>>(null);
	const viewableItemsRef = React.useRef<ViewToken[]>([]);
	const {
		actionButtons,
		errorMessage,
		dropdownInput,
		headerComponent,
		footerComponent,
		items,
		isMoreAfter,
		keyExtractor,
		loading,
		loadMoreAfter,
		refreshControl,
		reloadItems,
		search,
		focusedItemIndex: focusedItemIndexInItems,
	} = useSelectList(props);
	const [focusedIndex, setFocusedIndex] = React.useState<{item: number; section: number}>(
		{item: focusedItemIndexInItems, section: 0});

	const sectionsFromItems = React.useCallback(
		(elements: readonly I[]): Section<IdKey, I>[] => {
			const sectionsByTitle: {[key: string]: I[]} = {};
			elements.forEach((item) => {
				if (!sectionsByTitle[sectionForItem(item)]) {
					sectionsByTitle[sectionForItem(item)] = [];
				}
				sectionsByTitle[sectionForItem(item)].push(item);
			});
			return getKeys(sectionsByTitle).map((key, index) => ({data: sectionsByTitle[key], index, title: String(key)}));
		},
		[sectionForItem],
	);

	const renderItem: RNSectionListRenderItem<I, Section<IdKey, I>> = React.useCallback(
		(info: SectionListRenderItemInfo<I, Section<IdKey, I>>) => renderItemProp({
			buttons: actionButtons(info.item),
			currentSearch: onSearch?.value ?? search,
			focusedStyle: !!onPressEnter &&
				info.index === focusedIndex.item &&
				info.section.index === focusedIndex.section &&
				styles.focusedItem,
			info,
		}),
		[actionButtons, focusedIndex.item, focusedIndex.section, onPressEnter, onSearch?.value, renderItemProp, search],
	);

	const handleViewableItemsChanged = React.useRef(
		({viewableItems}: {viewableItems: ViewToken[]}) => { viewableItemsRef.current = viewableItems; },
	);

	React.useEffect(
		() => {
			if (sectionListRef.current && focusedItemIndexInItems >= 0 && focusedIndex.section >= 0) {
				const item = items[focusedItemIndexInItems];
				const sectionIndex = sections.findIndex(s => s.title === sectionForItem(item));
				const itemIndex = sections[sectionIndex].data.findIndex(i => i[idKey] === item[idKey]);
				const focusedViewableItem = viewableItemsRef.current.find(i => i.key === item[idKey]);
				setFocusedIndex(prev => itemIndex !== prev.item || sectionIndex !== prev.section
					? ({item: itemIndex, section: sectionIndex})
					: prev,
				);
				if (!focusedViewableItem) {
					sectionListRef.current.scrollToLocation({
						animated: true,
						itemIndex,
						sectionIndex,
					});
				}
			}
		},
		// adding viewableItems in the dependencies break the mouse scroll
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[focusedItemIndexInItems, focusedIndex, idKey, items],
	);

	React.useEffect(
		() => { setSections(sectionsFromItems(items)); },
		[items, sectionsFromItems],
	);

	const sectionListContent = loading || errorMessage
		? (
			<View style={[styles.defaultLoadingContainer, styles.contentContainer]}>
				{/* cast as ReactNode to avoid multiple children were provided error */}
				{sectionListProps?.ListHeaderComponent as React.ReactNode}
				<SplashView
					loading={loading}
					centered
					message={!loading && errorMessage ? {translationKey: errorMessage, type: "error"} : undefined}
				/>
				{!loading && errorMessage && <Button
					fullWidth
					size="small"
					type="secondary"
					text={ct("common:retry")}
					icon="refresh"
					style={styles.retry}
					onPress={reloadItems}
				/>}
			</View>
		)
		: (
			<SectionList
				{...sectionListProps}
				ref={sectionListRef}
				contentContainerStyle={styles.contentContainer}
				keyboardDismissMode="none"
				keyboardShouldPersistTaps="handled"
				keyExtractor={keyExtractor}
				renderItem={renderItem}
				sections={sections}
				ListEmptyComponent={<ListEmpty {...(itemTranslation
					? {itemTranslation}
					: {itemTranslationKey: itemTranslationKey!})} />}
				onEndReached={loadMoreAfter}
				refreshControl={refreshControl || undefined}
				onViewableItemsChanged={handleViewableItemsChanged.current}
				viewabilityConfig={{
					itemVisiblePercentThreshold: 30,
				}}
				ListFooterComponent={
					isMoreAfter
						? (
							<ActivityIndicator color={SUBTLE_4} style={styles.placeHolderIndicator}/>
						)
						: sectionListProps?.ListFooterComponent
				}
			/>
		);

	const onPressOut = React.useCallback(
		() => dropDown?.setDropdownOpened(false),
		[dropDown],
	);

	return (
		<>
			{dropDown?.inlineSearch
				? headerComponent
				: !dropDown?.disableInput && dropdownInput
			}
			{dropDown
				? !(dropDown.closeIfNoResults && items.length === 0) && dropDown.trigger && (
					<Popover
						trigger={dropDown.trigger}
						isOpen={dropDown.dropdownOpened}
						mode="multiple"
					>
						<Popover.Backdrop onPressOut={onPressOut}/>
						<Popover.Content>
							<View style={[CONTAINERS.MAIN, styles.dropdown, {width: dropDown.width}]}>
								{!dropDown?.inlineSearch && headerComponent}
								{sectionListContent}
								{footerComponent}
							</View>
						</Popover.Content>
					</Popover>
				)
				: (
					<>
						{headerComponent}
						{sectionListContent}
						{footerComponent}
					</>
				)
			}
		</>
	);
};

const styles = StyleSheet.create({
	contentContainer: {
		paddingVertical: SMALL_SPACING,
	},
	defaultLoadingContainer: {
		flexBasis: 300,
	},
	dropdown: {
		...ELEVATIONS[12],
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: DEFAULT_SPACING,
		marginTop: 1,
		maxHeight: 300,
		shadowOffset: {height: 1, width: 0},
	},
	focusedItem: {
		borderColor: BLUE,
		borderWidth: 1,
	},
	placeHolderIndicator: {
		alignSelf: "center",
		padding: DEFAULT_SPACING,
	},
	retry: {
		paddingHorizontal: SMALL_SPACING,
	},
});
