import {ProvidedServiceType} from "../../@types/activities/mandate";
import {CommunicationType} from "../../@types/activities/session";
import {InterpreterFilters} from "../../@types/identities/filters";
import {Interpreter, InterpreterPreview} from "../../@types/identities/interpreter";
import {ImageMime} from "../../@types/medias/image";
import {Page, Pagination} from "../../@types/pagination";
import {Origin} from "../../navigation/screens/common/modals/select-origin";
import {Transport} from "../../navigation/screens/interpreter/modals/select-transport";
import {Images} from "../../utils/externals/images";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";
import {GetInterpreterResponse, Type} from "../interpreters/interpreter";
import {
	interpreterQualificationBackToFront,
	transformAvailabilityBackToFront,
	transformBackendInterpreterToFrontend,
	transformLanguageISOCodeBackToFront,
	transformLanguageISOCodeFrontToBack,
} from "../utils/transform";
import {transformGenderBackToFront, transformGenderFrontToBack} from "../utils/transform-common";

type InterpreterFiltersData = {
	limit?: string,
	offset?: string,
	searchText?: string,
	firstName?: string,
	lastName?: string,
	toLang?: string,
	gender?: string,
	qualifications?: string,
	origins?: string,
};
export type UserInterpretersListRequests = {
	GET: {},
	queryParams: InterpreterFiltersData,
};
export type UserInterpreterSessionsCountRequests = {
	GET: {},
	queryParams: {
		interpreterId?: string,
	},
};

export const interpreterDetails = async (id: Interpreter["identityId"]):
Promise<Interpreter> => {
	const userResponse = await api(
		"interpreter",
		"GET",
		{pathParams: {interpreterId: id}},
	) as ApiResponse<GetInterpreterResponse>;
	const {
		firstName, lastName, formattedAddress, telephone, email,
		travelMode, languagesSkills, profilePicName, country, gender, city,
		interpreterQualificationAndModuleData, latitude, longitude, inPersonAvailability,
		voiceCallAvailability, videoCallAvailability, courseAvailability, mediationAvailability,
		interpreterAvailability,
	} = userResponse.data;
	const communicationTypes: CommunicationType[] = [];
	const providedServices: ProvidedServiceType[] = ["standard"];
	if (inPersonAvailability) {
		communicationTypes.push("inPerson");
	}
	if (voiceCallAvailability) {
		communicationTypes.push("phone");
	}
	if (videoCallAvailability) {
		communicationTypes.push("video");
	}
	if (courseAvailability) {
		if (!communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		providedServices.push("education");
	}
	if (mediationAvailability) {
		if (!communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		providedServices.push("mediation");
	}
	const qualifications: Interpreter["qualifications"] = interpreterQualificationBackToFront(
		interpreterQualificationAndModuleData);
	return {
		availability: interpreterAvailability ? transformAvailabilityBackToFront(interpreterAvailability) : undefined,
		communicationTypes,
		qualifications,
		providedServices,
		type: "interpreter",
		fullName: {
			firstName,
			lastName,
		},
		email,
		identityId: id,
		gender: transformGenderBackToFront(gender),
		transport: travelMode === "TRANSIT" ? "Public" as Transport : "Private" as Transport,
		languages: languagesSkills.map(skill => ({
			from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
			to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
		})),
		place: formattedAddress || city
			? {
				address: formattedAddress || city,
				geocode: latitude && longitude
					? {
						lat: latitude,
						lng: longitude,
					}
					: null,
			}
			: null,
		phone: telephone,
		picture: profilePicName
			? {
				mime: `image/${profilePicName.slice(profilePicName.lastIndexOf(".") + 1, profilePicName.lastIndexOf("?"))}` as ImageMime,
				sourceType: "url" as Type,
				mediaType: "image",
				source: profilePicName,
			}
			: {
				mime: "image/png",
				sourceType: "require",
				mediaType: "image",
				source: Images.Avatar,
			},
		origin: country?.iso as Origin,
	};
};
export const getUpcomingSessionCount = async (id: InterpreterPreview["identityId"]): Promise<number> => {
	const userResponse = await api(
		"userInterpreterSessionsCount",
		"GET",
		{
			queryParams: {interpreterId: id},
		},
	) as ApiResponse<{count: number}>;
	if (userResponse.isSuccess) {
		const {count} = userResponse.data;
		return count;
	}
	throw new Error(userResponse.message);
};

export const getClientInterpreters = async (pagination: Pagination, filters?: InterpreterFilters):
Promise<Page<InterpreterPreview>> =>
	searchClientInterpreters("", pagination, filters);

export const searchClientInterpreters = async (search: string, pagination: Pagination, filters?: InterpreterFilters):
Promise<Page<InterpreterPreview>> => {
	const {limit, offset} = pagination;
	const requestData: InterpreterFiltersData = {
		limit: limit.toString(),
		offset: (offset + 1).toString(),
		searchText: search,
	};
	if (filters) {
		const {firstName, lastName, gender, origins, qualifications, language} = filters;
		if (firstName) {
			requestData.firstName = firstName;
		}
		if (lastName) {
			requestData.lastName = lastName;
		}
		if (gender) {
			requestData.gender = transformGenderFrontToBack(gender);
		}
		if (origins) {
			requestData.origins = origins.toString();
		}
		if (qualifications) {
			const qualification = qualifications.map(item => item.value).toString();
			requestData.qualifications = qualification;
		}
		if (language) {
			const toLang = transformLanguageISOCodeFrontToBack(language);
			requestData.toLang = toLang;
		}
	}
	const userResponse = await api(
		"userInterpretersList",
		"GET",
		{
			queryParams: requestData,
		},
	) as ApiResponse<{interpreters: GetInterpreterResponse[], totalInterpreters: number}>;
	if (userResponse.isSuccess) {
		const {totalInterpreters, interpreters} = userResponse.data;
		const interpreterList: Interpreter[] =
			interpreters.map(interpreter => transformBackendInterpreterToFrontend(interpreter));
		return {
			items: interpreterList,
			hasPreviousPage: offset > 0,
			hasNextPage: offset + limit < totalInterpreters,
			totalCount: totalInterpreters,
		};
	}
	throw new Error(userResponse.message);
};
