import * as React from "react";
import {Locales, SupportedLocaleKey} from "../utils/locales/locales";

export interface LocaleContextVal {
	locale: SupportedLocaleKey;
	setLocale: (value: SupportedLocaleKey) => Promise<void>;
}

export const LocaleContext = React.createContext<LocaleContextVal>({} as LocaleContextVal);
export const LocaleProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
	const [locale, setLocaleState] = React.useState<SupportedLocaleKey>(Locales.currentLocale);

	const setLocale = React.useCallback(
		(value: SupportedLocaleKey) => Locales.set(value).then(() => setLocaleState(value)),
		[],
	);

	return (
		<LocaleContext.Provider value={{locale, setLocale}}>
			{children}
		</LocaleContext.Provider>
	);
};
