import * as React from "react";
import {StyleProp, StyleSheet, TextInput as RNTextInput, View, ViewStyle} from "react-native";
import {DEFAULT_SPACING} from "../../utils/constants";
import {useTranslation} from "../../utils/hooks/use-translation";
import {PLACEHOLDER, PRIMARY, SUBTLE_2, SUBTLE_4, TRANSPARENT, WHITE} from "../../utils/styles/colors";
import {ButtonProps} from "../buttons/button";
import {Icon, IconKey, IconProps} from "../icons";
import {TextInput, TextInputProps} from "../texts/text-input";

interface Props extends TextInputProps {
	containerStyle?: StyleProp<ViewStyle>;
	disableButtonStyle?: boolean;
	fullWidth?: boolean;
	leftIcon?: IconKey | null; // null -> no left icon, undefined -> default left icon (search)
	rightIcon?: (Omit<IconProps, "onPress"> & {onPress?: ButtonProps["onPress"]}); // undefined -> no right icon
}

const BUTTON_ICON_SIZE = 20;
const ButtonInputRenderer = (
	{
		leftIcon = "search",
		rightIcon,
		fullWidth = true,
		containerStyle,
		value,
		onChangeText: onChangeTextProp,
		disableButtonStyle,
		...props
	}: Props,
	ref: React.Ref<RNTextInput>,
): JSX.Element => {
	const {ct} = useTranslation();
	const [text, setText] = React.useState(value ?? "");
	const rightIconProps = {
		color: text?.length > 0 ? PRIMARY : SUBTLE_2,
		containerStyle: buttonInputStyles.rightIcon,
		onPress: text?.length > 0 ? () => rightIcon?.onPress ?? onChangeText("") : undefined,
		size: BUTTON_ICON_SIZE,
		...rightIcon,
	};

	React.useEffect(
		() => setText(value ?? ""),
		[value],
	);

	const onChangeText = React.useCallback(
		(t: string) => {
			if (onChangeTextProp) {
				onChangeTextProp(t);
			}
			setText(t);
		},
		[onChangeTextProp],
	);

	return (
		<View style={[
			disableButtonStyle ? buttonInputStyles.container : buttonInputStyles.buttonStyle,
			containerStyle,
			fullWidth && {alignSelf: "stretch", flexGrow: 1},
		]}>
			{!!leftIcon && (
				<Icon
					icon={leftIcon}
					color={SUBTLE_4}
					containerStyle={buttonInputStyles.leftIcon}
					size={BUTTON_ICON_SIZE}
				/>
			)}
			<TextInput
				ref={ref}
				style={buttonInputStyles.input}
				placeholder={`${ct("common:search")}...`}
				placeholderTextColor={PLACEHOLDER}
				hitSlop={{
					left: DEFAULT_SPACING + (leftIcon ? BUTTON_ICON_SIZE : 0),
					right: DEFAULT_SPACING + (leftIcon ? BUTTON_ICON_SIZE : 0),
				}}
				{...props}
				value={text}
				onChangeText={onChangeText}
			/>
			{rightIcon
				? <Icon icon={rightIcon.icon} {...rightIconProps} />
				: <Icon
					icon={disableButtonStyle ? "close" : "cancel"}
					{...rightIconProps}
					color={text?.length > 0
						? disableButtonStyle
							? SUBTLE_4
							: SUBTLE_2
						: TRANSPARENT
					}
				/>
			}
		</View>
	);
};

export const ButtonInput = React.forwardRef(ButtonInputRenderer);

export const buttonInputStyles = StyleSheet.create({
	buttonStyle: {
		alignItems: "center",
		alignSelf: "center",
		backgroundColor: WHITE,
		borderColor: SUBTLE_2,
		borderRadius: 18,
		borderWidth: 2,
		flexDirection: "row",
		justifyContent: "center",
		minHeight: 34,
		paddingBottom: 3,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: 2,
	},
	container: {
		flexDirection: "row",
		marginRight: DEFAULT_SPACING - 4,
	},
	input: {
		flex: 1,
		maxHeight: 200,
		minHeight: 28,
		paddingVertical: 0,
		textAlign: "left",
	},
	leftIcon: {
		alignSelf: "center",
		marginLeft: -14,
		marginRight: 4,
	},
	rightIcon: {
		alignSelf: "center",
		marginLeft: 4,
		marginRight: -14,
	},
});
