import * as React from "react";
import {Modal as RNModal} from "react-native";
import {Alert} from "./feedbacks/alert";
import {Toast} from "./feedbacks/toast";
import {Icon} from "./icons";
import {HeaderLayout} from "./menus/header-layout";
import {StatusBar} from "./status-bar";
import {Text} from "./texts/text";
import {ModalWrapper} from "./views/modal-wrapper";

interface Props {
	children: React.ReactNode;
	onClose: () => void;
	open?: boolean;
	title?: string;
}
export const Modal = ({children, onClose, open, title}: Props): JSX.Element => (
	<RNModal transparent visible={open} onRequestClose={onClose}>
		<ModalWrapper onClose={onClose} overlayBackground>
			<>
				<StatusBar/>
				<HeaderLayout
					center={<Text type="emphasis_1" numberOfLines={1}>{title}</Text>}
					left={<Icon icon="close" onPress={onClose} />}
				/>
				{children}
			</>
		</ModalWrapper>
		{/* Need this, otherwise toasts/alerts will appear behind the overlay of the modal */}
		<Toast/>
		<Alert/>
	</RNModal>
);
