import {feedbacks as enUSFeedbacks} from "../en-US/feedbacks";

export const feedbacks: typeof enUSFeedbacks = {
	APICallFailed: "Erreur durant l'obtention des données",
	acceptSessionFailed: "Erreur durant l'acceptation de la session",
	acceptSessionSuccess: "Session acceptée",
	calendarAccessNotAuthorized: "L'accès au calendrier local n'est pas autorisé. Autorisez l'accès au calendrier local dans les paramètre de votre appareil.",
	calendarSynchronizationFailed: "Erreur durant la synchronisation avec votre calendrier local",
	calendarSynchronizationSuccess: "Vos évènements pour les 90 prochains jours ont bien été synchronisés avec votre calendrier local",
	cancelSessionFailed: "Erreur durant l'annulation de la session",
	cancelSessionSuccess: "La session a été annulée",
	changePasswordSuccess: "Votre mot de passe a été modifié",
	connectionLost: "Connexion au réseau perdue",
	connectionRestored: "Connexion au réseau rétablie",
	copyAddressSuccess: "L'adresse de destination a été copiée dans le presse papier",
	copyProfileSuccess: "Le profil a été copié dans le presse papier",
	copySupportNumberSuccess: "Le numéro du support a été copié dans le presse-papier.",
	createDataFailed: "Erreur durant la création de l'élément",
	createDataSuccess: "L'élément a bien été créé",
	createFollowUpFailed: "Erreur durant la création du suivi",
	createSessionFailed: "Erreur durant la création de la session",
	createUnavailabilityFailed: "Erreur durant la création d'une indisponibilité",
	createUnavailabilitySuccess: "Indisponibilité créée",
	deleteDataFailed: "Erreur durant la suppression de l'élément",
	deleteDataSuccess: "L'élément a bien été supprimé",
	deleteUnavailabilityFailed: "Erreur durant la suppression d'une indisponibilité",
	deleteUnavailabilitySuccess: "Indisponibilité supprimée",
	dependantDataError: "Les données relatives à cette session ont été modifiées/supprimées",
	emailSuccess: "Email envoyé avec succès",
	geocodeFailed: "Erreur durant le téléchargement de la carte",
	getCalendarDataFailed: "Erreur durant l'obtention des données du calendrier",
	getDataFailed: "Erreur durant l'obtention des données",
	getFollowUpParamsFailed: "Erreur durant l'obtention des informations de la session de suivi",
	getInterpreterAddressesFailed: "Erreur durant l'obtention des addresses de l'interprète",
	getInterpreterFailed: "Erreur durant l'obtention des données interprète",
	getInterpreterProfileEditRights: "Erreur durant l'obtention des droits d'éditions des paramètres de l'interprète",
	getInterpreterSalaryFailed: "Erreur durant l'obtention des informations de salaire de l'interprète",
	getInterpreterSettingsFailed: "Erreur durant l'obtention des paramètres de l'interprète",
	getMinimumDurationFailed: "Erreur durant l'obtention de la durée minimale d'une session de suivi",
	getPlaceSuggestions: "Erreur durant l'obtention des suggestions d'adresses",
	getSessionDetailsFailed: "Erreur durant l'obtention des détails de session",
	getSupportInfosFailed: "Erreur durant l'obtention des informations du support",
	getUnavailabilityFailed: "Erreur durant l'obtention de l'indisponibilité",
	getUserAddressesFailed: "Erreur durant l'obtention des addresses de l'utilisateur",
	getUserFailed: "Erreur durant l'obtention des données utilisateur",
	getUserSettingsFailed: "Erreur durant l'obtention des paramètres de l'utilisateur",
	hourLimitExceeded: "Votre organisation a dépassé la durée maximale de réservation autorisée",
	imagePickerFailed: "Erreur durant la sélection d'image",
	loginFailed: "Les identifiants entrés sont incorrects",
	logoutFailed: "Impossible de se déconnecter",
	messageSentFailed: "Le message n'a pas pu être envoyé",
	messageSentSuccess: "Message envoyé",
	notificationsPermissionsDisabled: "Nous n'avons pas pu activer les notifications push, vous devez les activer dans les options de l'appareil",
	notificationsPermissionsEnabled: "Notifications push activées",
	refuseSessionFailed: "Erreur durant le refus de la session",
	refuseSessionSuccess: "La session a été refusée",
	sendDataFailed: "Erreur durant l'envoi des données",
	serverDownError: "Une mise à jour du serveur est en cours. Veuillez réessayer plus tard",
	sessionAlreadyAttributed: "Cette session a déjà été attribuée à quelqu'un d'autre",
	sessionAlreadyValidated: "Votre administrateur a déjà validé cette session",
	sessionCreationSuccess: "La session a été créée avec succès",
	sessionStatusChangedConfirmed: "La session #{{id}} a été acceptée par {{interpreter}}.",
	sessionStatusChangedRefused: "La session #{{id}} a été refusée par tous les interprètes.",
	unavailableTimeslot: "Vous n'êtes pas disponible à ce créneau horaire",
	updateDataFailed: "Erreur durant la mise à jour de l'élément",
	updateDataSuccess: "L'élément a bien été mis à jour",
	updateInterpreterFailed: "Erreur durant la mise à jour des données interprète",
	updateInterpreterPictureFailed: "Erreur durant la mise à jour de l'image de profil",
	updateInterpreterProfileSuccess: "Votre profil a bien été mis à jour",
	updateSessionValidationFailed: "Erreur durant la validation de la session",
	updateSessionValidationSuccess: "La session a bien été validée",
	updateSettingsFailed: "Erreur durant la mise à jour des préférences",
	updateSettingsSuccess: "Préférences mises à jour",
	updateUnavailabilityFailed: "Erreur durant la mise à jour de l'indisponibilité",
	updateUnavailabilitySuccess: "Indisponibilité mise à jour",
	updateUserAddressFailed: "Erreur durant la mise à jour de vos adresses enregistrées",
	updateUserAddressSuccess: "Vos adresses enregistées ont bien été mises à jour",
	uploadSessionValidationPictureFailed: "Erreur durant le téléchargement de l'image de validation",
	videoCallCameraUsed: "Une autre application utilise votre caméra. Pour des raisons de confidentialité, vous devez d'abord la quitter et recharger cette page.",
	videoCallExpired: "Cet appel vidéo a expiré.",
	videoCallInitFailed: "Erreur durant la préparation de l'appel vidéo",
	videoCallJoinFailed: "Erreur durant la connexion à l'appel vidéo",
	videoCallNoCamera: "Aucune caméra détectée. Avez-vous donné l'autorisation?",
	videoCallNoMicro: "Aucun microphone détecté. Avez-vous donné l'autorisation?",
};
