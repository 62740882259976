import * as React from "react";
import {KeyboardAvoidingView as RNKeyboardAvoidingView, KeyboardAvoidingViewProps} from "react-native";
import {IS_IOS} from "../../utils/constants";
import {TRANSPARENT} from "../../utils/styles/colors";

/*
 * The official `KeyboardAvoidingView` component have a lot of edge cases and related bugs,
 * plus it's official documentation is very weak. Here is a better explanation:
 *
 * Before understanding how `KeyboardAvoidingView` actually works, we need to understand how IOS and Android handles
 * the relation between the app area and the keyboard.
 *
 * By default on IOS you dont have a choice. The keyboard goes over your app, that's it.
 * By default on Android it depends on the windowSoftInputMode "adjust" option. There are 4 options
 * (https://developer.android.com/guide/topics/manifest/activity-element):
 *
 *    - `adjustUnspecified`
 *    - `adjustResize`
 *    - `adjustPan`
 *    - `adjustNothing` (not documented but still exists)
 *
 * So which one to choose on Android?
 * I personnaly whish we could use `adjustNothing` so we would have the same behavior than IOS but this option
 * does not trigger the `KeyboardAvoidingView` magics for some reasons. `adjustUnspecified` is not recommended since
 * you wont handle a consistent behavior. `adjustPan` actually pans the view which creates some weird behaviors
 * especially if you have a long input list (form) and focus one of the latest.
 * Therefore you are left with `adjustResize`.
 *
 * Now that we know the behavior of the OS here is a bug you must know before you can use `KeyboardAvoidingView`
 * properly. Android `adjustResize` is not working when the app have no statusbar. It's a known bug of Android:
 * (https://issuetracker.google.com/issues/36911528)
 *
 * All of the behaviors will trigger only if the <KeyboardAvoidingView> is crossing the keyboard, if it's to high, it
 * wont. All behavior are applied to <KeyboardAvoidingView> as if it would be a normal <View> There are 3 possible
 * behavior for `KeyboardAvoidingView`, here is what seems to happen for each of them:
 *    - `height`, takes the specified height (style the keyboardAvoidingView) and substract keyboard height
 *    - `position`, moves the view with the keyboard independently
 *    - `padding`, adds a paddingBottom, of the height of the keyboard
 *
 * My recommandation is to create the same behavior between IOS and Android so you can handle the keyboard behavior
 * uniformally. To do so, wrapp the whole app with `KeyboardAvoidingView` and set the behavior as follow
 * `Platform.OS === "ios" ? "padding" : null`. Android does the same effect than the `KeyboardAvoidingView` with
 * `adjustResize` and we achieve the same behavior on IOS with `padding`.
 */
export const KeyboardAvoidingView = ({
	behavior = IS_IOS ? "padding" : undefined,
	style = {backgroundColor: TRANSPARENT},
	enabled = IS_IOS && true,
	children,
	...props
}: KeyboardAvoidingViewProps & {children?: React.ReactNode}): JSX.Element => (
	<RNKeyboardAvoidingView
		{...{behavior, enabled, style}}
		{...props}
	>
		{children}
	</RNKeyboardAvoidingView>
);
