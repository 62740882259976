import React from "react";
import {PRIMARY} from "../../utils/styles/colors";
import {Icon} from "../icons";
import {HeaderMenu} from "../menus/header";

export const InboxHeader: React.FunctionComponent = () => (
	<HeaderMenu
		left={null}
		equalColumns
		centerComponent={<Icon icon="appIcon" size={28} color={PRIMARY} />}
		right={{help: false}}
	/>
);
