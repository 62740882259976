import * as React from "react";
import {StyleSheet} from "react-native";
import {DEFAULT_SPACING} from "../../../utils/constants";
import {PRIMARY, SUBTLE_4} from "../../../utils/styles/colors";
import {Icon} from "../../icons";
import {Pressable, PressableProps} from "../../views/pressable";

export interface CheckboxSelectListProps {
	key: "checkbox" | "select";
	onChangeValue?: (value: boolean) => void;
	props?: Omit<PressableProps, "onPress">;
	value?: boolean;
}

type CheckboxSelectInputProps = Omit<CheckboxSelectListProps, "key"> & {
	disabled?: boolean;
	type: CheckboxSelectListProps["key"];
};

export const CheckboxSelectInput = ({
	type,
	onChangeValue,
	value,
	props,
	disabled,
}: CheckboxSelectInputProps): JSX.Element => {
	const onPressToggle = React.useCallback(
		() => {
			if (type === "checkbox") {
				onChangeValue?.(!value);
			}
			// Select cannot toggle the already selected one
			if (type === "select" && !value) {
				onChangeValue?.(!value);
			}
		},
		[type, onChangeValue, value],
	);

	return (
		<Pressable
			style={styles.toggle}
			hitSlop={{bottom: 10, left: 50, right: DEFAULT_SPACING, top: 10}}
			disabled={disabled}
			onPress={onPressToggle}
			{...props}
		>
			{type === "checkbox"
				? value
					? <Icon icon="checkBoxMarked" color={disabled ? SUBTLE_4 : PRIMARY}/>
					: <Icon icon="checkBoxEmpty" color={disabled ? SUBTLE_4 : PRIMARY}/>
				: value
					? <Icon icon="selectMarked" color={disabled ? SUBTLE_4 : PRIMARY}/>
					: <Icon icon="selectEmpty" color={disabled ? SUBTLE_4 : PRIMARY}/>}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	toggle: {
		justifyContent: "center",
	},
});
