export const users = {
	interpreter: {
		accepts: "{{firstName}} accepts:",
		availabilities: {
			available: "Available",
			inEmergenciesDisabled: "Unavailable for emergencies & immediate",
			inNights: "Unavailable during nights",
			inPrivateEvent: "Occupied in private event",
			inSession: "Occupied in session",
			inWeekend: "Unavailable during week-end",
			occupied: "Occupied",
			unavailable: "Unavailable until tomorrow",
		},
		copyAddress: "Copy",
		new: "New session",
		qualifications: {
			certificate: "INTERPRET certificate",
			exam: "Interpreter exam",
			federalCertificate: "Federal certificate",
			module1: "Module 1 INTERPRET",
			module2: "Module 2 INTERPRET",
			module3: "Module 3 INTERPRET",
			module4: "Module 4 INTERPRET",
			module4a: "Module 4a INTERPRET",
			module5: "Module 5 INTERPRET",
			module6: "Module 6 INTERPRET",
			module7: "Module 7 INTERPRET",
			module8: "Module 8 INTERPRET",
			module9: "Module 9 INTERPRET",
			module10: "Module 10 INTERPRET",
			qualificationA: "Qualification A",
			qualificationB: "Qualification B",
			qualificationC: "Qualification C",
		},
		ticket: "CFF tickets",
	},
	menu: {
		calendar: "Calendar",
	},
	person: {
		dateOfBirth: "Date of birth",
		unknownPerson: "Unknown person",
	},
	receiver: {
		ageGroup: {
			adult: "Adult",
			child: "Child or teenager",
			label: "Age group",
		},
		insurance: {
			asylblg: "AsylbLG",
			gkv: "GKV",
			label: "Insurance",
			none: "None",
			other: "Other",
			unknown: "Unknown",
		},
	},
	requester: {
		cancel: "Cancel",
	},
	settings: {
		availabilityScheduled: "Scheduled availability",
		availableImmediate: "Available for immediate sessions",
		availableNights: "Available at night ({{nightStart}} - {{nightEnd}})",
		availableWeekend: "Available during week-end ({{WEStart}} - {{WEEnd}})",
		exportAgreement: "The amounts and breakdowns are indicative and are subject to adjustments applied at the time of preparation of the salaries.",
		exportCSV: "Export CSV",
		exportPDF: "Export PDF",
		feedback: "Feedback",
		notifications: {
			SMS: "SMS notifications",
			SMSShort: "SMS",
			eMail: "Email notifications",
			eMailShort: "Email",
			push: "Push notifications",
			pushShort: "Push",
		},
		orderCards: "Order validation cards",
		personalisedData: "Personalised data",
		privacy: "Privacy",
		profile: "Profile",
		savedAddresses: "Saved addresses",
		savedSubjects: "Saved subjects",
		sessionCourse: "Course sessions",
		sessionInPerson: "In person sessions",
		sessionMediation: "Mediation sessions",
		sessionModels: "Session models",
		sessionPhone: "Phone sessions",
		sessionVideo: "Video sessions",
		unavailableImmediate: "Unavailable for immediate sessions",
		unavailableNights: "Unavailable at night ({{nightStart}} - {{nightEnd}})",
		unavailableWeekend: "Unavailable during week-end ({{WEStart}} - {{WEEnd}})",
		update: "Update settings",
	},
};
