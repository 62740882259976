import {useNavigation} from "@react-navigation/native";
import AgoraRTC, {
	AREAS,
	IAgoraRTCClient,
	IAgoraRTCRemoteUser,
	ICameraVideoTrack,
	IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import * as React from "react";
import {useState} from "react";
import {MediaDevice} from "../../../@types/medias/media-device";
import {forceBack} from "../../../navigation/navigation";
import {AGORA_APP_ID, ENV} from "../../constants";
import {Log} from "../../logs/logs";
import {ps} from "../../switch";
import {AgoraRTCError, MissingDeviceError} from "./agora-rtc-error.web";
import {AgoraUser, InitState, UNSET_UID, UseAgoraType} from "./use-agora.common";

AgoraRTC.setLogLevel(ENV === "development" ? 0 : 2);
AgoraRTC.setArea([AREAS.EUROPE]);

export const userTracks = {
	local: {
		audioTrack: null as IMicrophoneAudioTrack | null,
		videoTrack: null as ICameraVideoTrack | null,
	},
	users: [] as IAgoraRTCRemoteUser[],
};

export const useAgora: UseAgoraType = (channelId, token, sessionId, secret) => {
	const client = React.useRef<IAgoraRTCClient>();
	const navigation = useNavigation();
	const [initState, setInitState] = useState<InitState>({status: "initializing"});
	const [uid, setUid] = useState(UNSET_UID);
	const [cameraName, setCameraName] = React.useState("");
	const [audio, setAudio] = React.useState(true);
	const [video, setVideo] = React.useState(true);
	const [microphoneName, setMicrophoneName] = React.useState("");
	/*
	 * We have this state instead of using directly the global variable because we need to re-render when new users are
	 * set, so instead of updating global variable and force render + later map the global variable to get the info we
	 * want, we just store the info in this directly, thus re-rendering at the same time while doing the extraction once.
	 */
	const [usersInfo, setUsersInfo] = useState<AgoraUser[]>([]);

	const setUsers = React.useCallback(
		(users: IAgoraRTCRemoteUser[]) => {
			userTracks.users = users;
			// get the uids as number, because we know the react native part uses numbers and not uids
			setUsersInfo(users.map(({uid, hasAudio, hasVideo}) => ({hasAudio, hasVideo, id: uid as number})));
		},
		[],
	);

	const toggleAudio = React.useCallback(
		() => {
			userTracks.local.audioTrack?.setMuted(audio)
				.then(() => setAudio((prev) => !prev))
				.catch(Log.error());
		},
		[audio],
	);
	const toggleVideo = React.useCallback(
		() => {
			userTracks.local.videoTrack?.setMuted(video)
				.then(() => setVideo((prev) => !prev))
				.catch(Log.error());
		},
		[video],
	);

	const initTracks = React.useCallback(
		() => AgoraRTC.getDevices()
			.then(devices => {
				const audioDevice = devices.find((device) => device.kind === "audioinput");
				const videoDevice = devices.find((device) => device.kind === "videoinput");

				if (!audioDevice) {
					throw new MissingDeviceError("MISSING_AUDIO");
				} else if (!videoDevice) {
					throw new MissingDeviceError("MISSING_VIDEO");
				}

				setMicrophoneName(audioDevice.label);
				setCameraName(videoDevice.label);

				return Promise.all([
					AgoraRTC.createMicrophoneAudioTrack({microphoneId: audioDevice.deviceId}),
					AgoraRTC.createCameraVideoTrack({cameraId: videoDevice.deviceId}),
				]);
			})
			.then(([microphoneTrack, cameraTrack]) => {
				userTracks.local = {audioTrack: microphoneTrack, videoTrack: cameraTrack};
			}),
		[],
	);

	const joinChannel = React.useCallback(
		() => {
			if (!client.current) {
				return Log.error("videoCallJoinFailed")(
					"Connect - client could not be initialized",
					{channelId, secret, sessionId, token},
				);
			}

			if (secret) {
				/*
				 * Encryption as described in
				 * https://docs.agora.io/en/video-calling/develop/media-stream-encryption?platform=web
				 * except that we don't use a hex2ascii function to transform the encryption key.
				 * If we did, we wouldn't be able to connect with mobile users since the key would be different.
				 * We also can't use hex2ascii for mobile users either, since it doesn't work on iOS.

				 * After discussing the problem with the agora team to find the best solution, they told us the best (and only way)
				 * would be to use the key directly without using the hex2ascii function.
				 * More details here: https://agora-ticket.agora.io/issue/94ccfb6b67b04d30ae91a0d5a0ef464c
				 */
				client.current.setEncryptionConfig("aes-128-gcm", secret);
			}

			if (!AGORA_APP_ID) {
				return Log.error()("Connect - AGORA_APP_ID env var is missing");
			}
			client.current.join(AGORA_APP_ID, channelId, token || null)
				.then(uid => {
					if (!userTracks.local.audioTrack) {
						throw new MissingDeviceError("MISSING_AUDIO");
					}
					if (!userTracks.local.videoTrack) {
						throw new MissingDeviceError("MISSING_VIDEO");
					}
					return client.current!.publish([userTracks.local.audioTrack, userTracks.local.videoTrack])
						.then(() => uid);
				})
				.then(uid => {
					setUid(uid as number);
					Log.info(undefined, true)(`Connect - Participant joined session ${sessionId}`);
				})
				.catch((error: AgoraRTCError | MissingDeviceError) => {
					Log.error(
						ps(error?.code, {
							CAN_NOT_GET_GATEWAY_SERVER:
								error?.message.includes("dynamic key expired")
									? "videoCallExpired"
									: "videoCallJoinFailed",
							MISSING_AUDIO: "videoCallNoMicro",
							MISSING_VIDEO: "videoCallNoCamera",
							NOT_READABLE: "videoCallCameraUsed",
							default: "videoCallJoinFailed",
						}),
					)(error, {channelId, secret, sessionId, token});
					navigation.canGoBack()
						? navigation.dispatch(forceBack)
						: navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
				});
		},
		[channelId, navigation, token, secret, sessionId],
	);

	const leaveChannel = React.useCallback(
		() => {
			client.current?.leave().then(() => {
				userTracks.users = [];
				setUid(UNSET_UID);
				Log.info(undefined, true)(`Connect - Participant left session ${sessionId}`);
			}).catch(Log.error());
		},
		[client, sessionId],
	);

	const stopPreview = React.useCallback(
		() => {
			userTracks.local?.audioTrack?.stop();
			userTracks.local?.audioTrack?.close();
			userTracks.local?.videoTrack?.stop();
			userTracks.local?.videoTrack?.close();
		},
		[],
	);

	React.useEffect(
		() => {
			if (!client.current) {
				client.current = AgoraRTC.createClient({codec: "h264", mode: "rtc"});
				initTracks().then(() => setInitState({status: "ready"})).catch((error: AgoraRTCError | MissingDeviceError) => {
					setInitState(
						ps(error?.code, {
							MISSING_AUDIO: {feedback: "videoCallNoMicro", status: "error"},
							MISSING_VIDEO: {feedback: "videoCallNoCamera", status: "error"},
							NOT_READABLE: {feedback: "videoCallCameraUsed", status: "error"},
							// Case already handled in prompt; there we check periodically and can handle this error by case
							PERMISSION_DENIED: {status: "ready"},
							default: (() => {
								Log.error()(error);
								return {feedback: "videoCallInitFailed", status: "error"};
							})(),
						}),
					);
				});
			}

			const handleUserPublished = (user: IAgoraRTCRemoteUser, mediaType: "audio" | "video"): void => {
				client.current!.subscribe(user, mediaType)
					.then(() => setUsers([...client.current!.remoteUsers]))
					.catch(Log.error());
			};
			const handleUserUnpublished = (): void => setUsers([...client.current!.remoteUsers]);
			const handleUserJoined = (): void => setUsers([...client.current!.remoteUsers]);
			const handleUserLeft = (): void => setUsers([...client.current!.remoteUsers]);
			client.current.on("user-published", handleUserPublished);
			client.current.on("user-unpublished", handleUserUnpublished);
			client.current.on("user-joined", handleUserJoined);
			client.current.on("user-left", handleUserLeft);

			return () => {
				if (!client.current) {
					return;
				}
				client.current.off("user-published", handleUserPublished);
				client.current.off("user-unpublished", handleUserUnpublished);
				client.current.off("user-joined", handleUserJoined);
				client.current.off("user-left", handleUserLeft);
				leaveChannel();
				stopPreview();
			};
		},
		[client, setUsers, initTracks, leaveChannel, stopPreview],
	);

	const setCamera = React.useCallback(
		({deviceId, label}: MediaDevice): Promise<void> => {
			if (!userTracks.local.videoTrack) {
				throw new MissingDeviceError("MISSING_VIDEO");
			}
			return userTracks.local.videoTrack.setDevice(deviceId)
				.then(() => setCameraName(label)).catch(Log.error());
		},
		[],
	);

	const setMicrophone = ({deviceId, label}: MediaDevice): Promise<void> => {
		if (!userTracks.local.audioTrack) {
			throw new MissingDeviceError("MISSING_AUDIO");
		}
		return userTracks.local.audioTrack.setDevice(deviceId)
			.then(() => setMicrophoneName(label)).catch(Log.error());
	};

	return {
		audio,
		// Not supported on web
		backgroundBlur: false,
		cameraName,
		getCameraDevices: () => AgoraRTC.getCameras(),
		getMicrophoneDevices: () => AgoraRTC.getMicrophones(),
		initState,
		joinChannel,
		joined: uid !== UNSET_UID,
		leaveChannel,
		microphoneName,
		setCamera,
		setMicrophone,
		switchCamera: () => null,
		toggleAudio,
		toggleBackgroundBlur: () => null,
		toggleVideo,
		uid,
		users: usersInfo,
		video,
	};
};
