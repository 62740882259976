// Their type is not exported, so we copy it. Last updated on 4.16.0
export interface AgoraRTCError extends Error {
	readonly code: AgoraRTCErrorCode;
	constructor: (code: AgoraRTCErrorCode, message?: string, data?: any) => AgoraRTCError;
	readonly data?: any;
	readonly message: string;
	readonly name: string;
	print(level?: "error" | "warning"): AgoraRTCError;
	throw(): never;
	toString(): string;
}

export enum AgoraRTCErrorCode {
	API_INVOKE_TIMEOUT = "API_INVOKE_TIMEOUT",
	BEAUTY_PROCESSOR_INTERNAL_ERROR = "BEAUTY_PROCESSOR_INTERNAL_ERROR",
	CAN_NOT_GET_GATEWAY_SERVER = "CAN_NOT_GET_GATEWAY_SERVER",
	CAN_NOT_GET_PROXY_SERVER = "CAN_NOT_GET_PROXY_SERVER",
	CAN_NOT_PUBLISH_MULTIPLE_VIDEO_TRACKS = "CAN_NOT_PUBLISH_MULTIPLE_VIDEO_TRACKS",
	CHROME_PLUGIN_NOT_INSTALL = "CHROME_PLUGIN_NOT_INSTALL",
	CHROME_PLUGIN_NO_RESPONSE = "CHROME_PLUGIN_NO_RESPONSE",
	CONSTRAINT_NOT_SATISFIED = "CONSTRAINT_NOT_SATISFIED",
	CREATE_OFFER_FAILED = "CREATE_OFFER_FAILED",
	CROSS_CHANNEL_FAILED_JOIN_DEST = "CROSS_CHANNEL_FAILED_JOIN_DEST",
	CROSS_CHANNEL_FAILED_JOIN_SRC = "CROSS_CHANNEL_FAILED_JOIN_SEC",
	CROSS_CHANNEL_FAILED_PACKET_SENT_TO_DEST = "CROSS_CHANNEL_FAILED_PACKET_SENT_TO_DEST",
	CROSS_CHANNEL_SERVER_ERROR_RESPONSE = "CROSS_CHANNEL_SERVER_ERROR_RESPONSE",
	CROSS_CHANNEL_WAIT_STATUS_ERROR = "CROSS_CHANNEL_WAIT_STATUS_ERROR",
	CUSTOM_REPORT_FREQUENCY_TOO_HIGH = "CUSTOM_REPORT_FREQUENCY_TOO_HIGH",
	CUSTOM_REPORT_SEND_FAILED = "CUSTOM_REPORT_SEND_FAILED",
	DECODE_AUDIO_FILE_FAILED = "DECODE_AUDIO_FILE_FAILED",
	DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
	DISCONNECT_P2P = "DISCONNECT_P2P",
	ELECTRON_DESKTOP_CAPTURER_GET_SOURCES_ERROR = "ELECTRON_DESKTOP_CAPTURER_GET_SOURCES_ERROR",
	ELECTRON_IS_NULL = "ELECTRON_IS_NULL",
	ENUMERATE_DEVICES_FAILED = "ENUMERATE_DEVICES_FAILED",
	EXCHANGE_SDP_FAILED = "EXCHANGE_SDP_FAILED",
	EXIST_DISABLED_VIDEO_TRACK = "EXIST_DISABLED_VIDEO_TRACK",
	FETCH_AUDIO_FILE_FAILED = "FETCH_AUDIO_FILE_FAILED",
	GATEWAY_P2P_LOST = "GATEWAY_P2P_LOST",
	GET_LOCAL_CAPABILITIES_FAILED = "GET_LOCAL_CAPABILITIES_FAILED",
	GET_LOCAL_CONNECTION_PARAMS_FAILED = "GET_LOCAL_CONNECTION_PARAMS_FAILED",
	GET_VIDEO_ELEMENT_VISIBLE_ERROR = "GET_VIDEO_ELEMENT_VISIBLE_ERROR",
	ICE_FAILED = "ICE_FAILED",
	INIT_WEBSOCKET_TIMEOUT = "INIT_WEBSOCKET_TIMEOUT",
	INVALID_LOCAL_TRACK = "INVALID_LOCAL_TRACK",
	INVALID_OPERATION = "INVALID_OPERATION",
	INVALID_PARAMS = "INVALID_PARAMS",
	INVALID_PLUGIN = "INVALID_PLUGIN",
	INVALID_REMOTE_USER = "INVALID_REMOTE_USER",
	INVALID_TRACK = "INVALID_TRACK",
	INVALID_UINT_UID_FROM_STRING_UID = "INVALID_UINT_UID_FROM_STRING_UID",
	LIVE_STREAMING_CDN_ERROR = "LIVE_STREAMING_CDN_ERROR",
	LIVE_STREAMING_INTERNAL_SERVER_ERROR = "LIVE_STREAMING_INTERNAL_SERVER_ERROR",
	LIVE_STREAMING_INVALID_ARGUMENT = "LIVE_STREAMING_INVALID_ARGUMENT",
	LIVE_STREAMING_INVALID_RAW_STREAM = "LIVE_STREAMING_INVALID_RAW_STREAM",
	LIVE_STREAMING_PUBLISH_STREAM_NOT_AUTHORIZED = "LIVE_STREAMING_PUBLISH_STREAM_NOT_AUTHORIZED",
	LIVE_STREAMING_TASK_CONFLICT = "LIVE_STREAMING_TASK_CONFLICT",
	LIVE_STREAMING_TRANSCODING_NOT_SUPPORTED = "LIVE_STREAMING_TRANSCODING_NOT_SUPPORTED",
	LIVE_STREAMING_WARN_FAILED_LOAD_IMAGE = "LIVE_STREAMING_WARN_FAILED_LOAD_IMAGE",
	LIVE_STREAMING_WARN_FREQUENT_REQUEST = "LIVE_STREAMING_WARN_FREQUENT_REQUEST",
	LIVE_STREAMING_WARN_STREAM_NUM_REACH_LIMIT = "LIVE_STREAMING_WARN_STREAM_NUM_REACH_LIMIT",
	LOCAL_AEC_ERROR = "LOCAL_AEC_ERROR",
	LOW_STREAM_ENCODING_ERROR = "LOW_STREAM_ENCODING_ERROR",
	MEDIA_OPTION_INVALID = "MEDIA_OPTION_INVALID",
	METADATA_OUT_OF_RANGE = "METADATA_OUT_OF_RANGE",
	MULTI_UNILBS_RESPONSE_ERROR = "MULTI_UNILBS_RESPONSE_ERROR",
	NETWORK_ERROR = "NETWORK_ERROR",
	NETWORK_RESPONSE_ERROR = "NETWORK_RESPONSE_ERROR",
	NETWORK_TIMEOUT = "NETWORK_TIMEOUT",
	NOT_READABLE = "NOT_READABLE",
	NOT_SUPPORTED = "NOT_SUPPORTED",
	NO_ICE_CANDIDATE = "NO_ICE_CANDIDATE",
	OPERATION_ABORTED = "OPERATION_ABORTED",
	PC_CLOSED = "PC_CLOSED",
	PERMISSION_DENIED = "PERMISSION_DENIED",
	READ_LOCAL_AUDIO_FILE_ERROR = "READ_LOCAL_AUDIO_FILE_ERROR",
	REMOTE_USER_IS_NOT_PUBLISHED = "REMOTE_USER_IS_NOT_PUBLISHED",
	SENDER_NOT_FOUND = "SENDER_NOT_FOUND",
	SENDER_REPLACE_FAILED = "SENDER_REPLACE_FAILED",
	SET_ANSWER_FAILED = "SET_ANSWER_FAILED",
	SET_ENCODING_PARAMETER_ERROR = "SET_ENCODING_PARAMETER_ERROR",
	SHARE_AUDIO_NOT_ALLOWED = "SHARE_AUDIO_NOT_ALLOWED",
	SUBSCRIBE_FAILED = "SUBSCRIBE_FAILED",
	TIMEOUT = "TIMEOUT",
	TRACK_IS_DISABLED = "TRACK_IS_DISABLED",
	TRACK_STATE_UNREACHABLE = "TRACK_STATE_UNREACHABLE",
	UID_CONFLICT = "UID_CONFLICT",
	UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
	UNEXPECTED_RESPONSE = "UNEXPECTED_RESPONSE",
	UNSUBSCRIBE_FAILED = "UNSUBSCRIBE_FAILED",
	UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED",
	VOID_GATEWAY_ADDRESS = "VOID_GATEWAY_ADDRESS",
	WEBGL_INTERNAL_ERROR = "WEBGL_INTERNAL_ERROR",
	WEB_SECURITY_RESTRICT = "WEB_SECURITY_RESTRICT",
	WS_ABORT = "WS_ABORT",
	WS_DISCONNECT = "WS_DISCONNECT",
	WS_ERR = "WS_ERR",
}

// Custom error used when a device is missing
export class MissingDeviceError extends Error {
	// We name it "code" here so that it's the same field name as AgoraRTCError. That way we can switch on the same field.
	readonly code: MissingDeviceErrorCode;
	constructor (code: MissingDeviceErrorCode) {
		super(code);
		this.code = code;
	}
}

type MissingDeviceErrorCode = "MISSING_AUDIO" | "MISSING_VIDEO";
