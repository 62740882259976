import * as React from "react";
import {StyleSheet, View} from "react-native";
import {NotificationTarget} from "../../../../@types/notifications";
import {Button} from "../../../../components/buttons/button";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {SectionListRenderItemParams, SelectSectionList} from "../../../../components/list/select-sectionlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {
	deleteClientNotificationTarget,
	getClientNotificationTargets,
	updateClientNotificationTarget,
} from "../../../../requests/clients/settings/notifications";
import {
	deleteInterpreterNotificationTarget,
	getInterpreterNotificationTargets,
	updateInterpreterNotificationTarget,
} from "../../../../requests/interpreters/settings/notifications";
import {filterTruthy} from "../../../../utils/arrays";
import {DEFAULT_SPACING, IS_INTERPRETER, SMALL_SPACING} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {BACKGROUND_COLOR} from "../../../../utils/styles/colors";
import {ELEVATIONS} from "../../../../utils/styles/elevations";

type ListItem = Item<"id", NotificationTarget>;

const Notifications = (_: SharedScreenProps<"NotificationsModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {accountId, notificationsStatus, replacePushDevice} = React.useContext(AuthentifiedContext);

	const renderItem = (
		{info: {item: {channel, displayed, id, notified, deletable}}, buttons}: SectionListRenderItemParams<"id", ListItem>,
	): JSX.Element => (
		<ListElement
			buttons={filterTruthy(buttons).filter(b => b.key === "delete" ? deletable : true)}
			icon={notified ? "notificationsOn" : "notificationsOff"}
		>
			{channel === "WEB" || channel === "APNS" || channel === "APNS_SANDBOX" || channel === "FCM"
				? displayed
				: `${displayed} - ${id}`}
		</ListElement>
	);
	const sectionForItem = ({channel}: ListItem): string =>
		channel === "APNS" || channel === "APNS_SANDBOX" || channel === "FCM" || channel === "WEB"
			? t("users:settings.notifications.push")
			: (channel === "SMS")
				? t("users:settings.notifications.SMS")
				: t("users:settings.notifications.eMail");
	return (
		<>
			<HeaderMenu center={ct("common:notification_plural")}/>
			{notificationsStatus === "anotherDevice" && (
				<View style={styles.card}>
					<Text centered>
						{t("screens:notifications.anotherDevice")}
					</Text>
					<Button
						onPress={() => replacePushDevice(accountId!)}
						text={t("screens:notifications.replace")}
						style={styles.replaceButton}
						size="small"
						fullWidth
					/>
				</View>
			)}

			<SelectSectionList
				getRequest={(pagination) =>
					(IS_INTERPRETER
						? getInterpreterNotificationTargets
						: getClientNotificationTargets)(pagination)}
				deleteRequest={(removed: NotificationTarget) =>
					(IS_INTERPRETER
						? deleteInterpreterNotificationTarget
						: deleteClientNotificationTarget)(accountId!, removed.id)}
				renderItem={renderItem}
				sectionForItem={sectionForItem}
				onPressEdit={{
					request: (updated: NotificationTarget) =>
						(IS_INTERPRETER
							? updateInterpreterNotificationTarget
							: updateClientNotificationTarget)(updated),
					screen: {name: "SelectNotificationTargetModal"},
				}}
				itemTranslationKey="common:notification"
				idKey="id"
			/>
		</>
	);
};

const styles = StyleSheet.create({
	card: {
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: SMALL_SPACING,
		marginBottom: DEFAULT_SPACING,
		marginHorizontal: SMALL_SPACING,
		padding: DEFAULT_SPACING,
		...ELEVATIONS[4],
	},
	replaceButton: {
		paddingTop: SMALL_SPACING,
	},
});

export const NotificationsModal = (props: SharedScreenProps<"NotificationsModal">): JSX.Element => (
	<ModalWrapper>
		<Notifications {...props} />
	</ModalWrapper>
);
