import React from "react";
import {GestureResponderEvent} from "react-native";
import {CalendarActivityPreview} from "../../@types/activities/activity";
import {RequesterCalendarMandatePreview} from "../../@types/activities/mandate";
import {filterTruthy} from "../../utils/arrays";
import {IS_CLIENT} from "../../utils/constants";
import {personIdentity} from "../../utils/identities";
import {ct} from "../../utils/locales/translations";
import {SessionElement} from "../list/items/session";
import {UnavailabilityElement} from "../list/items/unavailability";

interface Props {
	activity: CalendarActivityPreview;
	onPressActivity: (activity: CalendarActivityPreview) => (event: GestureResponderEvent) => void;
	selected?: boolean;
}

export const DayActivity = ({activity, selected, onPressActivity}: Props): JSX.Element => (
	activity.type === "unavailability"
		? (
			<UnavailabilityElement
				event={activity}
				onPress={onPressActivity(activity)}
				selected={selected}
			/>
		)
		: (
			<SessionElement
				session={activity}
				details={IS_CLIENT
					? filterTruthy([
						!["sent", "refused"].includes((activity as RequesterCalendarMandatePreview).status) && {
							label: ct("common:interpreter"),
							value: personIdentity((activity as RequesterCalendarMandatePreview).providers?.[0]),
							width: 200,
						},
						{
							label: ct("common:requester"),
							value: personIdentity((activity as RequesterCalendarMandatePreview).requester),
							width: 200,
						},
					])
					: undefined}
				onPress={onPressActivity(activity)}
				selected={selected}
			/>
		)
);
