import {forms as enUSForms} from "../en-US/forms";

export const forms: typeof enUSForms = {
	inputs: {
		addEvent: "Termin erstellen",
		allDay: "Den ganzen Tag",
		defaultStatusExplanation: "Sie können den Sitzungsstatus auswählen, den Ihr Kalender standardmäßig anzeigen soll.",
		deleteAllEvents: "Alle Termine löschen",
		deleteEvent: "Termin löschen",
		deleteThisEvent: "Diesen Termin löschen",
		editAllEvents: "Alle Termine bearbeiten",
		editEvent: "Termin bearbeiten",
		endDate: "Endedatum",
		endTime: "Ende",
		logUnavailability: "Abwesenheit erfassen",
		recurrence: "Wiederholung",
		selectCommunicationType: "Kommunikationsart auswählen",
		selectDate: "Datum auswählen",
		selectDateTime: "Datum und Uhrzeit auswählen",
		selectDuration: "Dauer auswählen",
		selectGender: "Geschlecht auswählen",
		selectInterpretersFilter: "Filter auswählen",
		selectLanguage: "Sprache auswählen",
		selectMonth: "Monat auswählen",
		selectNotificationChannel: "Wählen Sie einen Benachrichtigungstyp",
		selectNotificationTarget: "Wählen Sie ein Benachrichtigungsziel aus",
		selectOneOption: "Wählen Sie eine der folgenden Optionen",
		selectOrigin: "Herkunftsland auswählen",
		selectPlace: "Vollständige Einsatzadresse auswählen",
		selectProvidedService: "Art der Leistung",
		selectQualification: "Qualifikation auswählen",
		selectQualificationInfo: "Es werden nur Dolmetscher:innen angezeigt, die alle ausgewählten Qualifikationen besitzen. Wenn Sie keine Auswahl treffen, werden alle Dolmetscher:innen durchsucht.",
		selectRecurrence: "Eine Wiederholung auswählen",
		selectSession: "Sitzung auswählen",
		selectSessionOptions: "Option auswählen",
		selectStatus: "Status auswählen",
		selectTransport: "Transportmittel auswählen",
		showCanceledSessions: "Abgesagte und verschobene Sitzungen anzeigen",
		showLess: "Weniger anzeigen",
		showMore: "Mehr anzeigen",
		showUnavailabilities: "Unverfügbarkeiten anzeigen",
		startDate: "Anfangsdatum",
		startTime: "Startzeit",
		toLanguage: "Zur Sprache",
		typeHere: "Hier schreiben...",
		typeTo: "Schreiben an {{name}}...",
		unavailability: "Abwesenheit",
		unsupportedFile: "Das Dateiformat wird nicht unterstützt (Erweiterung oder Name).",
	},
	items: {
		add: "{{item}} hinzufügen",
		delete: "{{item}} löschen",
		empty: "keine {{item}}",
		select: "{{item}} auswählen",
		update: "{{item}} aktualisieren",
	},
	rules: {
		atLeastOneDigit: "Dieses Feld muss eine Nummer enthalten",
		atLeastOneLetter: "Dieses Feld muss einen Buchstaben enthalten",
		atLeastOneSpecialLetter: "Dieses Feld muss ein Sonderzeichen enthalten",
		between: "Dieses Feld muss aus {{min}} bis {{max}} Zeichen bestehen",
		durationGreaterOrEqualTo: "die Dauer muss mindestens {{minimumDuration}} {{durationUnit}} betragen",
		email: "Diese E-Mail ist ungültig",
		equalTo: "Dieses Feld muss den Wert \"{{otherLabel}}\" haben",
		max: "Dieses Feld darf höchstens {{max}} Zeichen lang sein",
		min: "Dieses Feld muss mindestens {{min}} Zeichen lang sein",
		mustChange: "Sie müssen den Wert dieses Feldes ändern",
		notEmpty: "Sie müssen dieses Feld ausfüllen",
		onlyDigits: "Dieses Feld kann nur Nummern enthalten",
		phone: "Diese Telefonnummer ist ungültig",
		time: "Beispiel: 09.30 oder 14.45 (ausgedrückt in 24-Stunden-Format)",
		url: "Diese URL ist ungültig",
	},
};
