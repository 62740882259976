import * as React from "react";
import {LocaleContext} from "../../../../contexts/locale";
import {recoverClient} from "../../../../requests/clients/account/recover";
import {recoverInterpreter} from "../../../../requests/interpreters/account/recover";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {AuthScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {AuthTemplate} from "./auth-template";

export const Recover = ({navigation}: AuthScreenProps<"Recover">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const [username, setUsername] = React.useState("");

	return (
		<AuthTemplate
			label={t("screens:recover.label")}
			title={ct("common:recover")}
			inputs={[
				{
					icon: "person",
					label: ct("common:username"),
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "username",
						onChangeValue: setUsername,
						value: username,
					},
				},
			]}
			subAction={{
				onPress: () => navigation.navigate("SignIn"),
				text: ct("common:signIn"),
			}}
			validation={{
				onValidation: (): void => {
					(IS_INTERPRETER ? recoverInterpreter : recoverClient)(username, locale)
						.then(() => {
							Log.success("emailSuccess")();
							navigation.navigate("SignIn");
						})
						.catch((error: Error) => Log.error("serverDownError")(error));
				},
				text: ct("common:recover"),
			}}
		/>
	);
};
