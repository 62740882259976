import * as React from "react";
import {Form, FormProps} from "../../../../components/inputs/form";
import {
	TextInputDropdownSuggestionItem,
} from "../../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

const EditSubjectSuggestion = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"EditSubjectSuggestionModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {selectionOrBase: itemParam, onSelect} = route.params ?? {};
	const base = React.useMemo(
		() => itemParam ?? {} as TextInputDropdownSuggestionItem,
		[itemParam],
	);
	const {loading: loadingForm, displayed: displayedForm, isUpdated, setUpdated, errorMessage} = useForm(base);

	const {displayed} = displayedForm;
	const addOrUpdateTitle = ct(`forms:items.${itemParam ? "update" : "add"}`, {item: t("common:subject")});

	const header = <HeaderMenu center={addOrUpdateTitle} exitConfirmation={isUpdated}/>;
	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}
	const inputs: FormProps["inputs"] = [
		{
			icon: "id",
			label: ct("common:subject"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, displayed: value})),
				value: displayed,
			},
		},
	];

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: addOrUpdateTitle},
					onValidation: () => {
						onSelect?.(displayedForm);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const EditSubjectSuggestionModal = (props: ClientStackNavigatorScreenProps<"EditSubjectSuggestionModal">): JSX.Element => (
	<ModalWrapper>
		<EditSubjectSuggestion {...props} />
	</ModalWrapper>
);
