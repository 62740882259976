// Allows escaping regex special chars in a string so that it could be used inside a RegEx later
const escapeRegExpChars = (value: string): string =>
	value.replace(/[$()*+.?[\\\]^{|}]/g, "\\$&"); // $& means the whole matched string

// deaccent string being compared https://stackoverflow.com/a/51874002
const normalizeRegExpChars = (value: string): string =>
	value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();

const searchLikeRegex = (search: string, wordsBeginBySearch = false): RegExp =>
	new RegExp(`${wordsBeginBySearch ? "\\b" : ""}${normalizeRegExpChars(escapeRegExpChars(search))}`, "i"); // Ignore case

export const textContains = (search: string, text: string, wordsBeginBySearch = false): boolean =>
	searchLikeRegex(search, wordsBeginBySearch).test(normalizeRegExpChars(text));

export const searchIndexOf = (search: string, text: string, wordsBeginBySearch = false): number =>
	normalizeRegExpChars(text).search(searchLikeRegex(search, wordsBeginBySearch));
