import * as React from "react";
import {Session, sessionStatus} from "../../../../@types/activities/session";
import {ListElement} from "../../../../components/list/items/list-element";
import {SelectableItem} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {filterTruthy} from "../../../../utils/arrays";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales} from "../../../../utils/locales/locales";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";

type ListItem = SelectableItem<"id", {displayed: string; id: Session["status"]}>;

const SelectSessionStatus = ({
	route,
	navigation,
}: SharedScreenProps<"SelectSessionStatusModal">): JSX.Element => {
	const {selectionOrBase, onSelect, excludedSessionStatus = ["deleted"]} = route.params ?? {};
	const {t} = useTranslation();
	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", ListItem>): JSX.Element => (
		<ListElement
			onPress={selectionOrBase
				? filterTruthy(buttons).find(button => button.key === "select")?.onPress
				: () => {
					onSelect?.([item.id]);
					navigation.dispatch(forceBack);
				}
			}
			buttons={buttons}
		>
			{item.displayed}
		</ListElement>
	);
	const list: ListItem[] = sessionStatus
		.filter(status => !excludedSessionStatus.includes(status))
		.map(status => ({
			displayed: t(`activities:sessions.status.${status}`),
			id: status,
			selected: selectionOrBase?.includes(status) ?? false,
		}))
		.sort((a, b) => Locales.compare(a.displayed, b.displayed));

	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectStatus")}/>
			<SelectFlatList
				getRequest={(pagination) => Promise.resolve(paginate(list, pagination))}
				renderItem={renderItem}
				{...selectionOrBase && onSelect && {
					onPressSelect: {
						onPress: (items: ListItem[] | string) => {
							if (Array.isArray(items)) {
								onSelect(items.map(item => item.id));
							}
							navigation.dispatch(forceBack);
						},
					},
				}}
				itemTranslationKey="forms:inputs.selectStatus"
				idKey="id"
			/>
		</>
	);
};

export const SelectSessionStatusModal = (props: SharedScreenProps<"SelectSessionStatusModal">): JSX.Element => (
	<ModalWrapper>
		<SelectSessionStatus {...props} />
	</ModalWrapper>
);
