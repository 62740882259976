import * as React from "react";
import {StyleSheet, View} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {BLACK, RED, SUBTLE_4, WHITE} from "../../../utils/styles/colors";
import {Icon} from "../../icons";

interface VideoOverlayProps {
	hasAudio: boolean;
	hasVideo: boolean;
	size?: "full" | "small";
}

export const VideoOverlay = ({hasAudio, hasVideo, size = "full"}: VideoOverlayProps): JSX.Element => (
	<View style={styles.userOverlay}>
		{!hasVideo && (
			<View style={styles.noVideoOverlay}>
				<Icon icon="videoOff" size={64} color={BLACK}/>
			</View>
		)}
		{!hasAudio && (
			<Icon
				icon="microphoneOff"
				containerStyle={[styles.userMuted, size === "full" ? styles.userMutedFullSize : styles.userMutedSmallSize]}
				color={WHITE}
				size={size === "full" ? 24 : 18}
			/>
		)}
		{/* <Text style={styles.userName} type="emphasis_1" color={WHITE}>User Name</Text> */}
	</View>
);

const styles = StyleSheet.create({
	noVideoOverlay: {
		alignItems: "center",
		backgroundColor: SUBTLE_4,
		height: "100%",
		justifyContent: "center",
		width: "100%",
	},
	userMuted: {
		backgroundColor: RED,
		borderRadius: SMALL_SPACING,
		position: "absolute",
	},
	userMutedFullSize: {
		height: 36,
		right: DEFAULT_SPACING,
		top: DEFAULT_SPACING,
		width: 36,
	},
	userMutedSmallSize: {
		height: 28,
		right: DEFAULT_SPACING / 2,
		top: DEFAULT_SPACING / 2,
		width: 28,
	},
	/*
	 * userName: {
	 *  backgroundColor: OVERLAY,
	 *  borderRadius: SMALL_SPACING,
	 *  left: DEFAULT_SPACING,
	 *  lineHeight: 36,
	 *  paddingHorizontal: SMALL_SPACING,
	 *  position: "absolute",
	 *  top: DEFAULT_SPACING,
	 * },
	 */
	userOverlay: {
		...StyleSheet.absoluteFillObject,
	},
});
