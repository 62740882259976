import {Session} from "../../@types/activities/session";
import {Issues, IssueType, SessionValidation} from "../../@types/validation";
import {ValidationSettings} from "../../@types/validation-settings";
import {IconKey} from "../../components/icons";
import {s} from "../switch";

export const validateSession = (session: Session, validation: SessionValidation): Session => {
	if (session.status === "unvalidated" || session.status === "confirmed") {
		const validatedSession: Session = {...session, status: validation.status};
		if (validation.status === "completed" && validation.issues.effectiveStartTime) {
			validatedSession.start = validation.issues.effectiveStartTime.effectiveStartTime;
		}
		if (validation.status === "completed" && validation.issues.effectiveEndTime) {
			validatedSession.end = validation.issues.effectiveEndTime.effectiveEndTime;
		}
		if (validation.status === "completed" && validation.issues.communicationTypeChanged) {
			validatedSession.communication = validation.issues.communicationTypeChanged.changed.communication;
		}
		return validatedSession;
	}
	return session;
};

export const getDefaultCompletedStatusIssues = (
	startTime: Date, feedbackSetting: ValidationSettings["feedback"],
): Issues => {
	const completedStatusIssues: Issues = {
		effectiveEndTime: null,
		effectiveStartTime: {
			effectiveStartTime: startTime,
			type: "effectiveStartTime",
		},
	};
	if (feedbackSetting) {
		completedStatusIssues.feedback = null;
	}
	return completedStatusIssues;
};

export const getIssueTypeIcon = (issueType: IssueType): IconKey => s(issueType, {
	absentReceiver: () => "person",
	cancellationTime: () => "endTime",
	communicationTypeChanged: () => "communicationMode",
	effectiveEndTime: () => "endTime",
	effectiveStartTime: () => "startTime",
	feedback: () => "feedback",
	incident: () => "review",
	other: () => "comment",
});

export const getDefaultCanceledStatusIssues = (): Issues => ({cancellationTime: null});
