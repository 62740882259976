import {Account} from "../../../@types/accounts";
import {IS_MOBILE} from "../../../utils/constants";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {Platform, PreferredLanguage} from "../../@types/organisation";

export type InterpreterLoginRequests = {
	POST: {
		body: {
			password: string,
			platform?: Platform,
			preferredLanguage: PreferredLanguage,
			userId: string,
			isMobile?: string,
		},
	},
};
export type InterpreterLoginResponse = {
	authToken: string,
	data: {
		id: number,
		profilePic: string,
		profilePicName: string,
	},
	isSuccess: boolean,
	errorCode?: string,
	message: string,
};

export const loginInterpreter = async (
	identifier: string, password: string, locale: SupportedLocaleKey,
): Promise<{accountId: Account["accountId"]; authToken: Account["authToken"]}> => {
	const loginResponse = await api(
		"interpreterLogin",
		"POST",
		{
			body: {
				password,
				userId: identifier,
				preferredLanguage: locale.slice(0, 2) as PreferredLanguage,
				isMobile: IS_MOBILE ? "true" : "false",
			},
		},
	) as InterpreterLoginResponse;
	if (loginResponse.isSuccess) {
		return {authToken: loginResponse.authToken, accountId: loginResponse.data.id.toString()};
	}
	return (loginResponse.errorCode && loginResponse.errorCode === "loginFailed")
		? Promise.reject(new Error("loginFailed"))
		: Promise.reject(new Error(loginResponse.message));
};
