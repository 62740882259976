import * as React from "react";

type ElementWithChildren = React.ReactElement<{children: React.ReactElement | React.ReactElement[]}>;

// Inspired from https://stackoverflow.com/questions/32916786/react-children-map-recursively
export const mapRecursive = (
	children: ElementWithChildren | ElementWithChildren[],
	fn: (child: React.ReactElement, id: string) => React.ReactElement, depth = 0,
): React.ReactElement[] =>
	React.Children.map(children, (child, index) => {
		if (!React.isValidElement(child)) {
			return child;
		}

		const id = `${depth}@${index}`;

		if (child.props.children) {
			const newChildren = mapRecursive(child.props.children, fn, depth + 1);
			const elements = React.cloneElement(child, {children: newChildren});
			return fn(elements, id);
		}

		return fn(child, id);
	});
