import {common as enUSCommon} from "../en-US/common";

export const common: typeof enUSCommon = {
	about: "à propos",
	accept: "accepter",
	account: "compte",
	add: "ajouter",
	address: "adresse",
	address_plural: "adresses",
	addressNumber: "numéro d'adresse",
	administrator: "administrateur",
	all: "tous",
	availability: "disponibilité",
	availability_plural: "disponibilités",
	billingAddress: "adresse de facturation",
	billingAddress_plural: "adresses de facturation",
	booked: "réservé",
	calendar: "calendrier",
	call: "appel",
	camera: "appareil photo",
	cancel: "annuler",
	category: "catégorie",
	category_plural: "catégories",
	choose: "choisir",
	client: "client",
	close: "fermer",
	combinedNumber: "numéro combiné",
	communicationType: "mode de communication",
	confirm: "confirmer",
	copy: "copier",
	corporation: "société",
	creationDate: "date de création",
	crop: "rogner",
	date: "date",
	day: "jour",
	day_plural: "jours",
	delete: "supprimer",
	description: "description",
	detail: "détail",
	detail_plural: "détails",
	directPhone: "téléphone direct",
	duplicate: "dupliquer",
	duration: "durée",
	education: "cours",
	email: "e-mail",
	email_plural: "e-mails",
	enabled: "activé",
	evamNumber: "numéro EVAM",
	event: "évènement",
	event_plural: "évènements",
	exitAnyway: "quitter",
	export: "exporter",
	filter: "filtre",
	filter_plural: "filtres",
	firstName: "prénom",
	followUp: "suivi",
	fromTo: "{{from}} vers {{to}}",
	fullName: "nom et prénom",
	function: "fonction",
	gender: "genre",
	gender_plural: "genres",
	general: "général",
	group: "groupe",
	"half-hour": "demi-heure",
	"half-hour_plural": "demi-heures",
	hello: "hello",
	help: "aide",
	home: "Accueil",
	hour: "heure",
	hour_plural: "heures",
	ignore: "ignorer",
	immediate: "immédiate",
	inbox: "inbox",
	institution: "institution",
	interpreter: "interprète",
	interpreter_plural: "interprètes",
	interpreterMandate: "mandat d'interprétariat",
	interpretersGroups: "groupes d'interprètes",
	ippNumber: "numéro IPP",
	join: "rejoindre",
	language: "langue",
	language_plural: "langues",
	languageUI: "langue principale",
	lastName: "nom de famille",
	later: "plus tard",
	leave: "quitter",
	library: "bibliothèque",
	link: "lien",
	loading: "chargement",
	login: "identifiants",
	"madam-abr": "Mme.",
	man: "homme",
	man_plural: "hommes",
	mandate: "mandat",
	mandate_plural: "mandats",
	mandator: "mandataire",
	medicalProfessional: "personel intervenant",
	medicalProfessional_plural: "personel intervenant",
	message: "message",
	message_plural: "messages",
	millisecond: "milliseconde",
	millisecond_plural: "millisecondes",
	minute: "minute",
	minute_plural: "minutes",
	"mister-abr": "M.",
	month: "mois",
	month_plural: "mois",
	name: "nom",
	navigate: "naviguer",
	new: "nouveau",
	next: "suivant",
	notRelevant: "non pertinent",
	notification: "notification",
	notification_plural: "notifications",
	offline: "hors ligne",
	ok: "ok",
	online: "en ligne",
	option: "option",
	option_plural: "options",
	optional: "optionnel",
	origin: "origine",
	origin_plural: "origines",
	other: "autre",
	otherProfessional: "autre professionel",
	otherProfessional_plural: "autres professionels",
	password: "mot de passe",
	paymentAuthority: "authorité de paiement",
	paymentAuthority_plural: "authorités de paiement",
	person: "personne",
	person_plural: "personnes",
	phone: "téléphone",
	phone_plural: "téléphones",
	phonePlaceholder: "(p. ex. +41 xx xxx xx xx / +49 xxx xxx xxxx)",
	preview: "prévisualiser",
	previous: "précédent",
	professional: "professionel",
	professional_plural: "professionels",
	providedService: "service",
	qualification: "qualification",
	qualification_plural: "qualifications",
	"quarter-hour": "quart d'heure",
	"quarter-hour_plural": "quarts d'heure",
	read: "lire",
	receiver: "bénéficiaire",
	receiver_plural: "bénéficiaires",
	recover: "récupérer",
	refuse: "refuser",
	refused: "refusé",
	requester: "demandeur",
	requester_plural: "demandeurs",
	required: "obligatoire",
	reschedule: "replanifier",
	reset: "réinitialiser",
	restart: "redémarrer",
	result: "résultat",
	result_plural: "résultats",
	retry: "réessayer",
	salary: "salaire",
	salary_plural: "salaires",
	save: "enregistrer",
	search: "rechercher",
	second: "seconde",
	second_plural: "secondes",
	select: "sélectionner",
	send: "envoyer",
	service: "service",
	session: "session",
	session_plural: "sessions",
	sessionCategory: "catégorie de session",
	sessionCategory_plural: "catégories de session",
	sessionType: "type de session",
	setting: "paramètre",
	setting_plural: "paramètres",
	share: "partager",
	signIn: "se connecter",
	signOut: "se déconnecter",
	start: "démarrer",
	status: "status",
	stop: "arrêter",
	subject: "sujet",
	title: "appellation",
	total: "total",
	total_plural: "totaux",
	translation: "traduction",
	transport: "trajet",
	transport_plural: "trajets",
	transportPrivate: "véhicule personnel",
	transportPublic: "transports publics",
	travel: "déplacement",
	travel_plural: "déplacements",
	type: "type",
	unknown: "inconnu",
	update: "mettre à jour",
	user: "utilisateur",
	username: "nom d'utilisateur",
	validate: "valider",
	week: "semaine",
	week_plural: "semaines",
	woman: "femme",
	woman_plural: "femmes",
	year: "an",
	year_plural: "ans",
};
