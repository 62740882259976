import {Indexable} from "../objects";

/* REST methods. */
export type Methods = "DELETE" | "GET" | "PATCH" | "POST" | "PUT";

export type DataType = "arrayBuffer" | "blob" | "formData" | "json" | "raw" | "text";

export type Headers = Indexable<string>;

/* The different content-types for a request. */
export const enum ContentType {
	/* Binary content, such as file data. */
	BINARY = "application/octet-stream",

	/* Standard json format. */
	JSON = "application/json",

	/*
	 * A more powerful list of changes-based patch. [rfc6902]
	 *
	 * Useful when dealing with nested objects and arrays or explicit null values.
	 *
	 * It also provides some mechanisms to handle concurrent modifications to the
	 * resource.
	 */
	JSON_PATCH_JSON = "application/json-patch+json",

	/*
	 * A simple merge-based patch format. [rfc7396]
	 *
	 * Useful when patching a flat JSON structure and when concurrent modifications
	 * are not an issue.
	 */
	MERGE_PATCH_JSON = "application/merge-patch+json",

	/* Multipart data format. */
	MULTIPART = "multipart/form-data",
}

/*
 * Creates an init for an HTTP fetch.
 * If the body is an object, will set "Content-Type" to {@link ContentType.JSON}
 * by default.
 */
const requestOptions = (
	method: Methods, body?: any, headers?: Headers, response?: DataType,
): RequestInit => {
	const init: RequestInit = {
		headers,
		method,
	};

	if (body) {
		if (typeof body === "object") {
			if (response === "formData") {
				init.headers = {"Content-Type": ContentType.MULTIPART, ...init.headers};
				init.body = body;
			} else {
				init.headers = {"Content-Type": ContentType.JSON, ...init.headers};
				init.body = JSON.stringify(body);
			}
		} else {
			init.body = body;
		}
	}
	return init;
};

export const request = (
	url: string, method: Methods, body?: any, headers?: Headers, response?: DataType,
): Promise<Response> =>
	fetch(url, requestOptions(method, body, headers, response))
		.then(res => res.ok
			? Promise.resolve(res)
			: Promise.reject(res),
		);
