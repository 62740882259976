import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";
import {TimelineElement} from "../../@types/timeline";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {Separator} from "../separator";
import {Bubble} from "./bubble";

interface Props {
	contentContainerStyle?: StyleProp<ViewStyle>;
	data: TimelineElement[];
}

export const Timeline = ({data, contentContainerStyle}: Props): JSX.Element => {
	const renderSeparator = (): JSX.Element => <Separator borderSize={0} spacingSize={SMALL_SPACING} />;
	const renderItem = (item: TimelineElement): JSX.Element => <Bubble {...item} />;
	return (
		<View style={[CONTAINERS.MAIN, {paddingHorizontal: DEFAULT_SPACING}, contentContainerStyle]}>
			{data.map((item, index) => (
				<View key={item.date.toISOString()}>
					{index !== 0 && renderSeparator()}
					{renderItem(item)}
				</View>
			))}
		</View>
	);
};
