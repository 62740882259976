import * as React from "react";
import {Translations} from "../../@types/@react-i18next.d.ts";
import {CommunicationType} from "../../@types/activities/session";
import {ToccoSetting} from "../../@types/settings";
import {Item} from "../../components/list/list-props.common";
import {AuthentifiedContext} from "../../contexts/authentified";
import {textContains} from "../searches";
import {useTranslation} from "./use-translation";

export type SessionDurationKey = keyof Translations["activities"]["sessions"]["durations"];
export const sessionDurations: SessionDurationKey[] = [
	"15",
	"30",
	"45",
	"60",
	"75",
	"90",
	"105",
	"120",
	"135",
	"150",
	"165",
	"180",
	"195",
	"210",
	"225",
	"240",
	"255",
	"270",
	"285",
	"300",
	"315",
	"330",
	"345",
	"360",
	"375",
	"390",
	"405",
	"420",
	"435",
	"450",
	"465",
	"480",
];
export type DurationListItem = Item<"id", {displayed: string; id: SessionDurationKey}>;

export const useGetDurationListItems = (): (minimumDuration: number, communicationType?: CommunicationType, search?: string) => DurationListItem[] => {
	const {t} = useTranslation();
	const {settings: {getSetting, loading}} = React.useContext(AuthentifiedContext);
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};

	return React.useCallback(
		(minimumDurationParam: number, communicationType?: CommunicationType, search?: string) => {
			if (loading) {
				return [];
			}

			const minimumDuration = communicationType === "inPerson" && minimumDurationParam
				? minimumDurationParam
				: null;

			const maximumDuration = usingTocco
				? communicationType === "phone"
					? Number("60" satisfies SessionDurationKey)
					: communicationType === "video"
						? Number("120" satisfies SessionDurationKey)
						: null
				: null;

			return sessionDurations
				.filter(d => {
					const value = Number(d);
					const biggerThanMin = minimumDuration === null || value >= minimumDuration;
					const smallerThanMax = maximumDuration === null || value <= maximumDuration;
					return biggerThanMin && smallerThanMax;
				})
				.map((duration) => ({
					displayed: t(`activities:sessions.durations.${duration}`),
					id: duration,
				}))
				.filter(d => search
					? textContains(search, d.displayed, true) || textContains(search, d.id, true)
					: true,
				);
		},
		[loading, t, usingTocco],
	);
};
