import {routeTitles as enUSRouteTitles} from "../en-US/route-titles";

export const routeTitles: typeof enUSRouteTitles = {
	AddressesListModal: "Indirizzi salvati",
	AuthScreensGroup: "Accedi",
	AvailabilitiesModal: "Disponibilità",
	Calendar: "Calendario",
	CalendarModal: "Calendario",
	CancelOrRescheduleSessionModal: "Annullamento della sessione",
	ClientAccount: "Conto",
	ComponentsModal: "Components",
	ConfirmationModal: "Conferma",
	DebugModal: "Debug",
	DetailsStep: "Fase di Dettagli",
	EditCorporationModal: "Modifica della firma",
	EditPaymentAuthorityModal: "Modifica dell'autorità di pagamento",
	EditPersonModal: "Modifica della persona",
	EditProfileModal: "Modifica profilo",
	EditSessionCategoryModal: "Modifica della categoria di sessione",
	EditSubjectSuggestionModal: "Modifica soggetto",
	FeedbackModal: "Feedback",
	FollowUpModal: "Rintracciamento",
	HomeTabNavigator: "",
	Inbox: "Casella",
	Interpreter: "Interprete",
	InterpreterAccount: "Conto",
	InterpreterModal: "Interprete",
	InterpreterRootNavigator: "Casella",
	Interpreters: "Interpreti",
	NewSession: "Nuova sessione",
	NotificationsModal: "Notifiche",
	OverlappingSessionsModal: "Avviso di indisponibilità",
	SalaryModal: "Salari",
	ScheduleStep: "Fase di pianificazione",
	SearchInterpretersModal: "Ricerca",
	SearchSessionsModal: "Ricerca",
	SelectDateCalendarModal: "Selezionare una data",
	SelectDurationModal: "Selezionare una durata",
	SelectInterpreterModal: "Selezionare un interprete",
	SelectInterpretersFilterModal: "Selezionare un filtro",
	SelectLanguageModal: "Selezionare una lingua",
	SelectMediaDeviceModal: "Selezionare un dispositivo multimediale",
	SelectMonthModal: "Seleziona un mese",
	SelectNotificationTargetModal: "Selezionare un indirizzo di notifica",
	SelectOriginModal: "Seleziona un'origine",
	SelectPaymentAuthorityModal: "Selezionare un'autorità di pagamento",
	SelectPeopleNumberModal: "Selezionare il numero di persone",
	SelectPersonModal: "Selezionare una persona",
	SelectPlaceModal: "Selezionare un indirizzo",
	SelectQualificationModal: "Selezionare una qualifica",
	SelectReceiverModal: "Selezionare un beneficiario",
	SelectRequesterModal: "Selezionare un richiedente",
	SelectSessionCategoryModal: "Selezionare una categoria di sessione",
	SelectSessionModal: "Selezionare una sessione",
	SelectSessionOptionsModal: "Selezionare l'opzione",
	SelectSessionStatusModal: "Selezionare uno stato di sessione",
	SelectStatusModal: "Selezionare lo stato",
	SelectSubjectSuggestionModal: "Selezionare un soggetto",
	SelectTransportModal: "Seleziona il trasporto",
	SelectValidationIssueModal: "Convalida della sessione",
	SelectValidationTypeModal: "Convalida della sessione",
	SessionCreationFiltersModal: "Filtri",
	SessionModal: "Sessione",
	SessionValidationModal: "Convalida della sessione",
	SetupStep: "Fase di configurazione",
	ShareSessionModal: "Condividi",
	SignIn: "Accedi",
	Slider: "Slider",
	SupportModal: "Contattare il supporto",
	UnavailabilityModal: "Indisponibilità",
	UserRootNavigator: "Nuova sessione",
	VideoSession: "Sessione video",
};
