import * as React from "react";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {useInterpreterFiltersInputs} from "../../../../utils/hooks/use-interpreter-filters-inputs";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

const SessionCreationFilters = ({navigation}: ClientStackNavigatorScreenProps<"SessionCreationFiltersModal">): JSX.Element => {
	const {ct} = useTranslation();
	const {filters: contextFilters, setFilters: setContextFilters} = React.useContext(SessionCreationContext);
	const [filters, setFilters] = React.useState(contextFilters);

	const options = React.useMemo(
		() => ({
			filters: {filters, setFilters},
			firstName: false,
			lastName: false,
		}),
		[filters, setFilters],
	);

	const {inputs} = useInterpreterFiltersInputs(options);
	return (
		<>
			<HeaderMenu center={ct("common:filter")}/>
			<Form
				title={ct("common:interpreter")}
				inputs={inputs}
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: ct("common:save")},
					onValidation: () => {
						setContextFilters(filters);
						navigation.dispatch(forceBack);
					},
				}}
			/>
		</>
	);
};

export const SessionCreationFiltersModal = (props: ClientStackNavigatorScreenProps<"SessionCreationFiltersModal">): JSX.Element => (
	<ModalWrapper>
		<SessionCreationFilters {...props} />
	</ModalWrapper>
);
