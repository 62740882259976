import {Activity, ActivityType} from "../../../../@types/activities/activity";
import {
	Education,
	InterpreterMandate,
	MandateStatus,
	Mediation,
	ProvidedService,
	ProvidedServiceType,
	Standard,
	TravelPriceDetails,
} from "../../../../@types/activities/mandate";
import {
	Communication,
	communicationTypes,
	SessionContext,
	sessionContextTypes,
	SessionStatus,
} from "../../../../@types/activities/session";
import {SessionCategory} from "../../../../@types/activities/session-category";
import {Unavailability} from "../../../../@types/activities/unavailability";
import {Corporation} from "../../../../@types/identities/corporation";
import {PersonPreview} from "../../../../@types/identities/person";
import {MedicalProfessionalPreview, OtherProfessionalPreview} from "../../../../@types/identities/professional";
import {ReceiverPreview} from "../../../../@types/identities/receiver";
import {ExternalImageProps} from "../../../../@types/medias/image";
import {PaymentAuthority} from "../../../../@types/payment-authority";
import {TimelineElement} from "../../../../@types/timeline";
import {
	TextInputDropdownSuggestionItem,
} from "../../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {ArrayElementType} from "../../../arrays";
import {IS_INTERPRETER} from "../../../constants";
import {
	addTime,
	computeDuration,
	FIRST_DAY_CALENDAR,
	getTimeCoef,
	isToday,
	LAST_DAY_CALENDAR,
	nDaysInMonth,
} from "../../../date-time/helpers";
import {nRandomlyFrom, randomBoolean, randomDate, randomlyFrom, randomNumber, randomString} from "../../../randoms";
import {SessionLanguagesKey, sessionLanguagesKeys} from "../../../sessions/languages";
import {s} from "../../../switch";
import {
	corporationsExamples,
	interpretersExamples,
	medicalProfessionalsExamples,
	otherProfessionalsExamples,
	peopleExamples,
	receiverExamples,
} from "../identities/data";
import {mediasExamples} from "../medias/data";
import {paymentAuthoritiesExamples} from "../payment-authorities/data";
import {placesExamples} from "../places/data";
import {sessionCategoriesExamples} from "../session-categories/data";
import {fakeActivitySort} from "./utils";

// We use this one and without typing it correctly instead of using the sessionDuration defined in select-duration to avoid import cycles
export const sessionDurations = [
	"15",
	"30",
	"45",
	"60",
	"75",
	"90",
	"105",
	"120",
	"135",
	"150",
	"165",
	"180",
	"195",
	"210",
	"225",
	"240",
	"255",
	"270",
	"285",
	"300",
	"315",
	"330",
	"345",
	"360",
	"375",
	"390",
	"405",
	"420",
	"435",
	"450",
	"465",
	"480",
];

const fakeTimeline: TimelineElement[] = [
	{
		date: new Date(2019, 11, 31, 16, 30),
		message: ["activities:sessions.sent", {nInterpreters: 12}],
		side: "interpreter",
		sideName: "Orlando Maguilla",
		system: {connectedAfter: true, first: true, status: "sent"},
	},
	{
		date: new Date(2019, 11, 31, 17, 30),
		message: ["feedbacks:sessionStatusChangedConfirmed", {interpreter: "Orlando Maguilla"}],
		side: "client",
		sideName: "USMI Yverdon",
		system: {connectedAfter: true, connectedBefore: true, status: "confirmed"},
	},
	{
		date: new Date(2019, 11, 31, 18, 30),
		message: "activities:sessions.reschedule.sent",
		side: "client",
		sideName: "USMI Yverdon",
		system: {connectedBefore: true, status: "sent"},
	},
	/*
	 * {
	 *  // FIXME: we actually need a translation key, but at the moment user-side use raw text instead
	 *  message: "I can't at this time, would you agree on the 15 of April?",
	 *  system: null,
	 *  date: new Date(2019, 11, 31, 19, 30),
	 *  side: "interpreter",
	 *  sideName: "Orlando Maguilla",
	 * }, {
	 *  // FIXME: we actually need a translation key, but at the moment user-side use raw text instead
	 *  message: "Ok we do the modification" as TranslationKey,
	 *  system: null,
	 *  date: new Date(2019, 11, 31, 20, 30),
	 *  side: "client",
	 *  sideName: "USMI Yverdon",
	 * },
	 */
	{
		date: new Date(2019, 11, 31, 21, 30),
		message: "activities:sessions.reschedule.accepted",
		side: "interpreter",
		sideName: "Orlando Maguilla",
		system: {connectedAfter: true, status: "confirmed"},
	},
	{
		date: new Date(2019, 11, 31, 22, 30),
		message: ["activities:sessions.newStatus.done", {duration: "1:30"}],
		side: "client",
		sideName: "USMI Yverdon",
		system: {connectedAfter: true, connectedBefore: true, status: "unvalidated"},
	},
	{
		date: new Date(2019, 11, 31, 23, 30),
		message: "activities:sessions.newStatus.validated",
		side: "client",
		sideName: "USMI Yverdon",
		system: {connectedBefore: true, last: true, status: "completed"},
	},
];

const randomLanguages = (): {language: SessionLanguagesKey; toLanguage: SessionLanguagesKey} => {
	const languagesArray = [...sessionLanguagesKeys];
	return {
		language: randomlyFrom(languagesArray),
		toLanguage: randomlyFrom(languagesArray),
	};
};

const randomComment = (): string | undefined =>
	randomBoolean()
		? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut " +
		"labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut " +
		"aliquip ex ea commodo consequat."
		: undefined;

const randomInterpreterMandateGenerator = ({
	defaultCommunicationType,
	defaultStatus,
	defaultStartTime,
	defaultEndTime,
	defaultImmediate,
	defaultId,
	defaultProvider,
	defaultReceiver,
	defaultRequester,
	defaultMandator,
	isOnlyRecipient,
	defaultService,
	defaultOtherProfessional,
	defaultMedicalProfessional,
	defaultLocked,
	defaultContext,
	defaultPaymentAuthority,
	defaultCategory,
	pastSession = false,
}: {
	defaultCategory?: InterpreterMandate["category"];
	defaultCommunicationType?: InterpreterMandate["communication"]["type"];
	defaultContext?: InterpreterMandate["context"];
	defaultEndTime?: InterpreterMandate["end"];
	defaultId?: InterpreterMandate["activityId"];
	defaultImmediate?: InterpreterMandate["immediate"];
	defaultLocked?: InterpreterMandate["locked"];
	defaultMandator?: InterpreterMandate["mandator"];
	defaultMedicalProfessional?: InterpreterMandate["medicalProfessional"];
	defaultOtherProfessional?: InterpreterMandate["otherProfessional"];
	defaultPaymentAuthority?: InterpreterMandate["paymentAuthority"];
	defaultProvider?: ArrayElementType<InterpreterMandate["providers"]>;
	defaultReceiver?: InterpreterMandate["receiver"];
	defaultRequester?: InterpreterMandate["requester"];
	defaultService?: ArrayElementType<InterpreterMandate["providedService"]>;
	defaultStartTime?: InterpreterMandate["start"];
	defaultStatus?: InterpreterMandate["status"];
	isOnlyRecipient?: InterpreterMandate["onlyRecipient"];
	pastSession?: boolean;
}): InterpreterMandate => {
	const randomCommunicationType = (): {communication: Communication} => {
		const communicationType: InterpreterMandate["communication"]["type"] = defaultCommunicationType ??
			randomlyFrom(communicationTypes);
		if (communicationType === "inPerson") {
			return {
				communication: {
					place: randomlyFrom(placesExamples),
					placeInfos: "Scnd floor go right, then straight for 500m at room 101",
					type: communicationType,
				},
			};
		}
		if (communicationType === "phone") {
			return {
				communication: {
					phoneNumber: "0041792959940",
					type: communicationType,
				},
			};
		}
		if (communicationType === "video") {
			return {
				communication: {
					channelId: "test",
					secret: "abc",
					token: "xxx",
					type: communicationType,
				},
			};
		}
		throw new Error("missing communication type");
	};
	const communicationType = randomCommunicationType();
	const randomStatus = (): {status: SessionStatus} => ({
		status:
			defaultStatus ??
			randomlyFrom(pastSession
				? ["canceled", "unvalidated", "completed", "stopped", "rescheduled"]
				: [
					"sent",
					"confirmed",
					"refused",
				]),
	});
	const status = randomStatus();
	const randomContext = (): {context: SessionContext} => {
		const type = randomlyFrom(sessionContextTypes);
		const data = type === "reschedule" ? {reason: "Patient unavailable", selectedReasonId: "2"} : {};
		return {context: defaultContext ?? {rescheduleData: data, type}};
	};
	const randomTimes = (): {duration: number; end: Date; expectedEnd: Date; expectedStart: Date; start: Date} => {
		const start = defaultStartTime ??
			(pastSession ? randomDate(FIRST_DAY_CALENDAR, new Date()) : randomDate(new Date(), LAST_DAY_CALENDAR));
		// defaultEndTime or adds between 15 & 480 minutes to start
		const end = defaultEndTime ??
			new Date(start.getTime() + Number.parseInt(randomlyFrom(sessionDurations)) * getTimeCoef("minute"));
		const expectedStart = addTime(start, randomBoolean() ? randomNumber(15, 1) : -randomNumber(15, 1), "minute");
		const expectedEnd = addTime(end, randomNumber(15, 1), "minute");
		return {duration: computeDuration(start, end), end, expectedEnd, expectedStart, start};
	};
	const times = randomTimes();
	const randomExcludedInterpreters = (
		used: InterpreterMandate["providers"] = [],
	): {excludedInterpreters: InterpreterMandate["excludedInterpreters"]} => ({
		excludedInterpreters: [
			randomlyFrom(interpretersExamples.filter((el) => used.every((provider) => provider.accountId !== el.accountId))),
		],
	});
	const randomProviders = (): {providers: InterpreterMandate["providers"]} => {
		if (defaultProvider) {
			return {providers: [{...defaultProvider, status: "accepted"}]};
		}
		if (status.status === "sent") {
			const providers: InterpreterMandate["providers"] = nRandomlyFrom(
				interpretersExamples, randomNumber(interpretersExamples.length - 1)).map(
				(provider) => ({...provider, status: randomlyFrom<MandateStatus>(["refused", "pending"])}),
			);
			return {providers};
		}
		return {providers: [{...randomlyFrom(interpretersExamples), status: "accepted"}]};
	};
	const providers = randomProviders();
	const randomRequester = (): {requester: PersonPreview} => ({
		requester: defaultRequester ?? randomlyFrom(peopleExamples),
	});
	const randomMedicalProfessional = (): {medicalProfessional: MedicalProfessionalPreview} => ({
		medicalProfessional: defaultMedicalProfessional ?? randomlyFrom(medicalProfessionalsExamples),
	});
	const randomOtherProfessional = (): {otherProfessional: OtherProfessionalPreview} => {
		return ({
			otherProfessional: defaultOtherProfessional ?? randomlyFrom(otherProfessionalsExamples),
		});
	};
	const randomReceiver = (): {receiver: ReceiverPreview} => ({
		receiver: defaultReceiver || randomlyFrom(receiverExamples),
	});
	const randomMandator = (): {mandator: Corporation} => ({
		mandator: defaultMandator || randomlyFrom(corporationsExamples),
	});
	const randomService = (): {providedService: ProvidedService} => {
		const type: ProvidedServiceType = defaultService ?? randomlyFrom<ProvidedServiceType>(["standard", "mediation"]);
		return {
			providedService: s(type, {
				education: {
					type: "education",
				} as Education,
				mediation: {
					expectation: randomString(),
					goal: randomString(),
					involvedCorporations: nRandomlyFrom(corporationsExamples, randomNumber(5)),
					involvedPeople: nRandomlyFrom(peopleExamples, randomNumber(5)),
					reason: randomString(),
					type: "mediation",
				} as Mediation,
				standard: {
					type: "standard",
				} as Standard,
			}),
		};
	};
	const randomImmediate = (): {immediate: boolean} => ({
		immediate:
			defaultImmediate === undefined
				? pastSession || isToday(times.start) || times.start < new Date()
					? Math.random() < 0.2 // only past or today sessions can be immediate
					: false
				: defaultImmediate,
	});
	const randomId = (): {id: string} => ({id: defaultId ?? randomString()});
	const randomOnlyRecipient = (): {onlyRecipient: boolean} => ({
		onlyRecipient: isOnlyRecipient ?? providers.providers.length === 1,
	});
	const randomPrice = (): InterpreterMandate["price"] => {
		const base = randomNumber();
		const increased = randomBoolean() ? randomNumber() : 0;
		const reduced = randomBoolean() ? randomNumber() : 0;
		const travel: TravelPriceDetails =
			communicationType.communication.type !== "phone" && communicationType.communication.type !== "video"
				? {
					distance: randomNumber(),
					flatRate: randomNumber(),
					time: randomNumber(),
				}
				: {};
		return {
			base,
			currency: randomlyFrom(["CHF", "EUR"]),
			increased,
			reduced,
			travel,
		};
	};
	const randomEmailInfos = (): {ccEmail: string; receiverEmail: string; time: Date} => ({
		ccEmail: "cc@email.com",
		receiverEmail: randomlyFrom(["receiver@email.com", `${randomString()}@email.com`]),
		time: addTime(times.start, -randomNumber(), "day"),
	});
	const randomPaymentAuthority = (): {paymentAuthority: PaymentAuthority} => ({
		paymentAuthority: defaultPaymentAuthority ?? randomlyFrom(paymentAuthoritiesExamples),
	});
	const randomCategory = (): {category: SessionCategory} => ({
		category: defaultCategory ?? randomlyFrom(sessionCategoriesExamples),
	});
	return {
		activityId: String(randomNumber(900_000)),
		cancelable: randomBoolean(),
		creationDate: randomDate(addTime(times.start, -randomNumber(), "day"), times.start),
		disabledActions: [],
		internalComment: randomComment(),
		locked: defaultLocked === undefined
			? status.status === "unvalidated" &&
			addTime(times.end, 7, "day") < new Date()
			: defaultLocked,
		providerComment: randomComment(),
		subject: randomString(),
		timeline: randomBoolean() ? fakeTimeline : [],
		...randomContext(),
		...randomService(),
		...times,
		...randomImmediate(),
		...status,
		...communicationType,
		...randomId(),
		...randomLanguages(),
		...randomExcludedInterpreters(providers.providers),
		...providers,
		...randomReceiver(),
		...randomRequester(),
		...randomMedicalProfessional(),
		...randomOtherProfessional(),
		...randomMandator(),
		...randomOnlyRecipient(),
		...randomPaymentAuthority(),
		...randomCategory(),
		...(status.status === "confirmed" && {acceptanceInfos: randomEmailInfos()}),
		...(status.status === "confirmed" &&
			{shareInfos: Array.from({length: randomNumber(3, 0)}).map(() => randomEmailInfos())}),
		...(status.status === "completed" && communicationType.communication.type === "inPerson"
			? {validationPicture: mediasExamples[2] as ExternalImageProps}
			: {validation: undefined}),
		...(status.status === "completed" ? {paid: true, price: randomPrice()} : {price: undefined}),
		type: "interpreterMandate",
	};
};

const randomEventGenerator = (defaultStartTime?: Date): Unavailability => {
	const start = defaultStartTime ?? randomDate(FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR);
	// Adds between 15 & 120 minutes
	const end = randomDate(start, new Date(start.getTime() + (Math.random() * 105 + 15) * 60_000));
	return {
		activityId: `randomEventID-${randomString()}`,
		allDay: false,
		days: [],
		duration: computeDuration(start, end),
		end,
		place: randomlyFrom(placesExamples),
		recurrence: "monthly",
		start,
		subject: "Special holidays with the children",
		type: "unavailability",
	};
};

const fakeDayActivity = (date: Date): Activity => {
	const activityType: ActivityType = IS_INTERPRETER
		? (Math.random() < 0.2 ? "unavailability" : "interpreterMandate")
		: "interpreterMandate";
	const communicationType = communicationTypes[randomNumber(communicationTypes.length - 1)];
	return activityType === "unavailability"
		? randomEventGenerator(date)
		: randomInterpreterMandateGenerator({
			defaultCommunicationType: communicationType,
			defaultStartTime: date,
			pastSession: date < new Date(),
		});
};

const generateFakeActivities = (from: Date, to: Date): Activity[] => {
	const activities: Activity[] = [];
	Array.from({length: Math.ceil(computeDuration(from, to, "month"))}).forEach((_, monthIndex) => {
		const date = addTime(from, monthIndex, "month");
		Array.from({length: nDaysInMonth(date)}).forEach((__, dayIndex) =>
			Math.random() > 0.3
				? []
				: Array.from({length: Math.ceil(Math.random() * 5)}).forEach(() => {
					const startTime = new Date(addTime(date, dayIndex, "day").setHours(randomNumber(23), randomNumber(59), 0, 0));
					const activity = fakeDayActivity(startTime);
					activities.push(activity);
				}),
		);
	});
	return activities.sort(fakeActivitySort);
};

export const activitiesExamples = generateFakeActivities(FIRST_DAY_CALENDAR, LAST_DAY_CALENDAR);

export const subjectSuggestionsExamples: TextInputDropdownSuggestionItem[] = [
	{displayed: "Sujet 1", id: "sujet1"},
	{displayed: "Sujet 2", id: "sujet2"},
	{displayed: "Sujet 3", id: "sujet3"},
	{displayed: "Sujet 4", id: "sujet4"},
	{displayed: "Sujet 5", id: "sujet5"},
];
