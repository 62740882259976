import {BillingAddress} from "../../../../@types/billing-address";

export const billingAddressesExamples: BillingAddress[] = [
	{
		address: "address 1",
		department: "department",
		id: "ba1",
		name: "Billing address 1",
		postOfficeBox: "PO Box 1",
		postalCode: "12345",
		town: "Town 1",
	},
	{
		address: "address 2",
		department: "department",
		id: "ba2",
		name: "Billing address 2",
		postOfficeBox: "PO Box 2",
		postalCode: "12345",
		town: "Town 2",
	},
];
