import * as React from "react";
import {GestureResponderEvent, StyleSheet, View} from "react-native";
import {CalendarActivityPreview} from "../../../../@types/activities/activity";
import {SessionPreview} from "../../../../@types/activities/session";
import {Pagination} from "../../../../@types/pagination";
import {Button} from "../../../../components/buttons/button";
import {DayActivity} from "../../../../components/calendar/day-activity";
import {SectionHeader} from "../../../../components/calendar/section-header";
import {SectionListRenderItemParams, SelectSectionList} from "../../../../components/list/select-sectionlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {Spacer} from "../../../../components/spacer";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {BOTTOM_ITEM_SPACING} from "../../../../contexts/activities";
import {getSessionPreviews} from "../../../../requests/common/activities/preview";
import {CONTAINERS, DEFAULT_SPACING, EXTRA_LARGE_SPACING} from "../../../../utils/constants";
import {formatDayKey} from "../../../../utils/date-time/format";
import {dayKeyToDate} from "../../../../utils/date-time/helpers";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {SUBTLE, SUBTLE_3} from "../../../../utils/styles/colors";

const OverlappingSessions = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"OverlappingSessionsModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {sessionIds} = route.params;

	const onPressActivity = React.useCallback(
		(activity: CalendarActivityPreview) => (_event: GestureResponderEvent) => {
			return navigation.push("SessionModal", {sessionId: activity.activityId});
		},
		[navigation],
	);

	const getRequest = React.useCallback(
		(pagination: Pagination) => getSessionPreviews(sessionIds, pagination),
		[sessionIds],
	);

	const renderItem = React.useCallback(
		({info: {item}}: SectionListRenderItemParams<"activityId", SessionPreview>) => (
			<>
				<DayActivity activity={item} onPressActivity={onPressActivity}/>
				<Spacer size={BOTTOM_ITEM_SPACING}/>
			</>
		),
		[onPressActivity],
	);

	const sectionForItem = React.useCallback(
		(item: SessionPreview) => formatDayKey(item.start),
		[],
	);

	const renderSectionHeader = React.useCallback(
		({section: {title}}: any) => // type should be {section: Section<"activityId", SessionPreview, Session>} instead of any but typescript is doing strange things...
			<SectionHeader date={dayKeyToDate(title)}/>,
		[],
	);

	const onPressOk = React.useCallback(
		() => navigation.goBack(),
		[navigation],
	);

	return (
		<>
			<HeaderMenu/>
			<View style={[CONTAINERS.MAIN, styles.container]}>
				<View style={styles.explanation}>
					<Text type="title_2">
						{t("screens:overlappingSessions.explanation")}
					</Text>
				</View>
				<View style={styles.listContainer}>
					<SelectSectionList
						getRequest={getRequest}
						renderItem={renderItem}
						sectionForItem={sectionForItem}
						itemTranslationKey="common:session"
						idKey="activityId"
						sectionListProps={{
							ItemSeparatorComponent: null,
							renderSectionHeader,
							style: styles.list,
						}}
					/>
				</View>
				<Button
					size="small"
					text={ct("common:ok")}
					icon="check"
					style={styles.ok}
					innerStyle={styles.okInner}
					onPress={onPressOk}
				/>
			</View>
		</>
	);
};

export const OverlappingSessionsModal = (props: InterpreterStackNavigatorScreenProps<"OverlappingSessionsModal">): JSX.Element => (
	<ModalWrapper>
		<OverlappingSessions {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: EXTRA_LARGE_SPACING,
	},
	explanation: {
		marginBottom: DEFAULT_SPACING,
	},
	list: {
		backgroundColor: SUBTLE,
	},
	listContainer: {
		borderColor: SUBTLE_3,
		borderRadius: DEFAULT_SPACING,
		borderWidth: 1,
		flex: 1,
		overflow: "hidden",
	},
	ok: {
		marginVertical: DEFAULT_SPACING,
		width: "50%",
	},
	okInner: {
		width: "100%",
	},
});
