import * as React from "react";
import {NativeScrollEvent, NativeSyntheticEvent, StyleSheet, View} from "react-native";
import {Context, SessionPreview} from "../../../@types/activities/session";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {formatShortDateTime} from "../../../utils/date-time/format";
import {Debouncer} from "../../../utils/debouncer";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {personIdentity} from "../../../utils/identities";
import {getStatusColor} from "../../../utils/sessions/status";
import {BACKGROUND_COLOR, CONTRAST, PRIMARY, RED, SUBTLE_4} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Button, ButtonProps} from "../../buttons/button";
import {getSessionActions, SessionActions, SessionActionsCallback} from "../../buttons/session-actions";
import {IconKey} from "../../icons";
import {Map} from "../../maps/map";
import {ScrollView} from "../../scrollables/scroll-view";
import {Separator} from "../../separator";
import {Call} from "../../sessions/call";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";
import {SplashView} from "../../views/splash-view";
import {SessionPreviewElement} from "./session-preview";

const MAP_HEIGHT = 120;

export const getSessionHeight = (
	showCallout?: boolean,
	showDetails?: boolean,
	showActions?: boolean,
	showContext?: boolean,
): number => {
	let height = 85;
	if (showCallout) {
		height += MAP_HEIGHT + SMALL_SPACING;
	}
	if (showDetails) {
		height += 43;
	}
	if (showActions) {
		height += 30 + SMALL_SPACING;
	}
	if (showContext) {
		height += 40 + DEFAULT_SPACING;
	}
	return height;
};

/*
 * Those props should be temporary since the way a session is displayed should be related
 * to it's internal status.
 * For example a session in state state should bring an accept/refuse interaction,
 * a refused should bring a reschedule interaction, ...
 */
export interface SessionElementProps extends PressableProps {
	actionCallback?: (action: SessionActionsCallback) => void;
	context?: Context;
	details?: {label: string; value: string; width?: number}[];
	fetchFailed?: boolean;
	selected?: boolean;
	session: SessionPreview;
	showCallout?: boolean;
	showDate?: boolean;
	showIndicator?: boolean;
	showInterpreterActions?: boolean;
}

export const SessionElementPlaceHolder = ({
	context,
	fetchFailed,
	showCallout,
	showIndicator,
	details,
	showInterpreterActions,
	style,
}: Pick<SessionElementProps,
"context" | "details" | "fetchFailed" | "showCallout" | "showDate" | "showIndicator" | "showInterpreterActions" | "style">): JSX.Element => (
	<View
		style={[
			CONTAINERS.MAIN,
			styles.wrapper,
			{
				height: getSessionHeight(
					showCallout,
					!!details,
					showInterpreterActions,
					!!context,
				),
			},
			style,
		]}
	>
		<View style={styles.container}>
			<SplashView
				centered
				loading={showIndicator}
				message={fetchFailed ? {translationKey: "feedbacks:getDataFailed", type: "error"} : undefined}
			/>
		</View>
	</View>
);

export const SessionElement = ({
	session,
	details,
	showCallout,
	showInterpreterActions,
	context,
	showDate,
	style,
	actionCallback,
	selected,
	...touchableProps
}: SessionElementProps): JSX.Element => {
	const {t} = useTranslation();
	const {identity} = React.useContext(AuthentifiedContext);
	const scrollView = React.useRef<ScrollView>(null);
	const scrollDebouncer = React.useRef(new Debouncer());
	const sessionActions = getSessionActions("list", session, context);
	const onScroll = (_: NativeSyntheticEvent<NativeScrollEvent>): void => {
		scrollDebouncer.current.debounce(
			() => scrollView.current?.scrollTo({animated: true, x: 0, y: 0}),
			2000,
		);
	};
	const renderAction = (
		key: string,
		renderIcon: IconKey,
		label: string,
		onPress: ButtonProps["onPress"],
		buttonStyle?: {
			backgroundColor?: string;
			contentActiveColor?: string;
			contentColor?: string;
		},
		disabled?: boolean,
	): JSX.Element => (
		<Button
			key={key}
			size="xsmall"
			text={label}
			icon={renderIcon}
			style={styles.actionButton}
			disabled={disabled}
			allowFontScaling={false}
			{...{onPress, ...buttonStyle}}
		/>
	);
	return (
		<View
			style={[
				CONTAINERS.MAIN,
				styles.wrapper,
				{
					height: getSessionHeight(
						showCallout,
						!!details,
						showInterpreterActions,
						!!context,
					),
				},
				style,
			]}
		>
			<View style={[styles.container, selected && styles.selectedContainer]}>
				<ScrollView
					horizontal
					onScroll={onScroll}
					ref={scrollView}
					showsHorizontalScrollIndicator={false}
					style={styles.scroll}
					contentContainerStyle={styles.scrollContainer}
				>
					<Pressable
						disabled={!touchableProps.onPress}
						style={styles.pressableContainer}
						{...touchableProps}
					>
						<>
							{!!context && (
								<View style={styles.contextContainer}>
									<View style={styles.contextTitle}>
										<Text color={context === "refused" ? RED : PRIMARY}
											type="button_1"
											numberOfLines={1}
											allowFontScaling={false}>
											{t(`activities:sessions.context.${context}.title`)}
										</Text>
										<Text color={SUBTLE_4} numberOfLines={1} allowFontScaling={false}>
											{formatShortDateTime(new Date())}
										</Text>
									</View>
									<Text color={SUBTLE_4} type="default_2" numberOfLines={1} allowFontScaling={false}>
										{t(
											`activities:sessions.context.${context}.subtitle`,
											{
												message: "Please don't forget to bring your ID",
												user: identity && identity.type === "corporation"
													? identity.name
													// @ts-expect-error works in strict mode
													: personIdentity(identity),
											},
										)}
									</Text>
								</View>
							)}
							<SessionPreviewElement
								session={session}
								style={!details && {paddingBottom: 0}}
								showDate={showDate}
								allowFontScaling={false}
							/>
							{showCallout && (
								<View style={styles.calloutWrapper}>
									{session.communication.type === "inPerson"
										? (
											<Map
												place={session.communication.place}
												height={MAP_HEIGHT}
											/>
										)
										: session.status !== "refused" && session.status !== "canceled" && (
											<Call
												height={MAP_HEIGHT}
												start={session.start}
												end={session.end}
												communication={session.communication}
												sessionStatus={session.status}
												sessionId={session.activityId}
											/>
										)}
								</View>
							)}
							{details && (
								<View style={styles.detailsContainer}>
									{details.map(({label, value, width}, index) => (
										<React.Fragment key={label}>
											{index !== 0 && <Separator mode="vertical"/>}
											<View
												style={[
													styles.detailsColumn,
													!!width && {maxWidth: width},
													!!width && index !== details.length - 1 && {minWidth: width},
												]}
											>
												<Text type="label_2" numberOfLines={1} allowFontScaling={false}>{label}</Text>
												<Text type="label" numberOfLines={1} allowFontScaling={false}>{value}</Text>
											</View>
										</React.Fragment>
									),
									)}
								</View>
							)}
							{showInterpreterActions && sessionActions.length > 0 && (
								<View style={styles.actionsContainer}>
									<SessionActions
										mode="list"
										session={session}
										renderAction={renderAction}
										actions={sessionActions}
										callbacks={actionCallback}
									/>
								</View>
							)}
						</>
					</Pressable>
				</ScrollView>
				<View style={[styles.statusBar, {backgroundColor: getStatusColor(session.status)}]}/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	actionButton: {
		marginRight: SMALL_SPACING,
	},
	actionsContainer: {
		flexDirection: "row",
		paddingTop: SMALL_SPACING,
	},
	calloutWrapper: {
		borderRadius: DEFAULT_SPACING,
		marginTop: SMALL_SPACING,
		overflow: "hidden",
	},
	container: {
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: SMALL_SPACING,
		flexDirection: "row",
		height: "100%",
		padding: SMALL_SPACING,
		...ELEVATIONS[4],
	},
	contextContainer: {
		paddingBottom: DEFAULT_SPACING,
	},
	contextTitle: {
		alignItems: "baseline",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	detailsColumn: {
		flex: 1,
		flexDirection: "column",
	},
	detailsContainer: {
		flexDirection: "row",
		marginTop: SMALL_SPACING,
	},
	pressableContainer: {
		minWidth: "100%",
	},
	scroll: {
		flex: 1,
	},
	scrollContainer: {
		flexGrow: 1,
	},
	selectedContainer: {
		backgroundColor: CONTRAST,
	},
	statusBar: {
		borderRadius: 3,
		height: "100%",
		marginLeft: SMALL_SPACING,
		overflow: "hidden",
		width: 6,
	},
	wrapper: {
		paddingHorizontal: SMALL_SPACING,
	},
});
