import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {Corporation} from "../../@types/identities/corporation";
import {AuthentifiedContext} from "../../contexts/authentified";
import {InboxContext} from "../../contexts/inbox";
import {ResponsiveContext} from "../../contexts/responsive";
import {Navigation} from "../../navigation/navigation";
import {
	APP,
	BUNDLE_BUILD,
	BUNDLE_VERSION,
	CONTAINERS,
	DEFAULT_SPACING,
	IS_CLIENT,
	IS_INTERPRETER,
	IS_WEB,
	LARGE_SPACING,
	SMALL_SPACING,
	VERTICAL_MENU_WIDTH,
} from "../../utils/constants";
import {onPressVersion, SectionKey, useAccountSections} from "../../utils/hooks/use-account-sections";
import {useTranslation} from "../../utils/hooks/use-translation";
import {TranslationFunc} from "../../utils/locales/translations";
import {ClientHomeTabNavigatorParamList} from "../../utils/navigation/paramLists/client-param-list";
import {InterpreterHomeTabNavigatorParamList} from "../../utils/navigation/paramLists/interpreter-param-list";
import {StackParamList} from "../../utils/navigation/paramLists/root-param-list";
import {getScreenKey} from "../../utils/navigation/utils";
import {
	BLUE,
	MENU_ACTIVE_WEB,
	MENU_BG,
	OVERLAY,
	PRIMARY_2,
	SUBTLE,
	SUBTLE_4,
	TRANSPARENT,
	WHITE,
	WHITE_OVERLAY,
	YELLOW,
} from "../../utils/styles/colors";
import {ps} from "../../utils/switch";
import {Button} from "../buttons/button";
import {CustomIcon, IconKey} from "../icons";
import {Separator} from "../separator";
import {Text} from "../texts/text";

export const menuLabels = (
	ct: TranslationFunc,
	t: TranslationFunc,
) => (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList): string => {
	if (IS_INTERPRETER) { // interpreter app
		switch (routeName) {
			case "InterpreterAccount": {
				return ct("common:account");
			}
			case "Inbox": {
				return ct("common:inbox");
			}
			case "Calendar": {
				return ct("common:calendar");
			}
			default: {
				throw new Error(`No labels provided for routeName ${routeName}`);
			}
		}
	} else { // client app
		switch (routeName) {
			case "ClientAccount": {
				return ct("common:account");
			}
			case "Calendar": {
				return t("users:menu.calendar");
			}
			case "NewSession": {
				return ct("common:new");
			}
			case "Interpreters": {
				return ct("common:interpreter_plural");
			}
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			default: { throw new Error(`No labels provided for routeName ${routeName}`); }
		}
	}
};
export const menuIcons = (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList): IconKey => {
	if (IS_INTERPRETER) { // interpreter app
		switch (routeName) {
			case "InterpreterAccount": {
				return "person";
			}
			case "Inbox": {
				return "inbox";
			}
			case "Calendar": {
				return "calendar";
			}
			default: {
				throw new Error(`No icon provided for routeName ${routeName}`);
			}
		}
	} else { // client app
		switch (routeName) {
			case "ClientAccount": {
				return "institution";
			}
			case "Calendar": {
				return "calendar";
			}
			case "NewSession": {
				return "plusOutline";
			}
			case "Interpreters": {
				return "interpreters";
			}
			default: { throw new Error(`No icon provided for routeName ${routeName}`); }
		}
	}
};

interface MenusProps {
	currentKey: string;
	navigateTo: (menu: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList) => () => void;
	short: boolean;
	vertical: boolean;
}

const AccountMenus = ({
	currentKey,
	includedMenus,
}: {currentKey: string; includedMenus: Set<SectionKey>}): JSX.Element => {
	const submenus = useAccountSections();
	return (
		<>
			{submenus.filter(({key}) => includedMenus.has(key)).map(({key: sectionKey, data}, index) => (
				<React.Fragment key={sectionKey}>
					{index !== 0 && <Separator color={OVERLAY}/>}
					{data.filter(s => !!s).map(({key, icon, onPress, text}) => (
						<React.Fragment key={text}>
							<Button
								active={currentKey === key}
								icon={icon}
								onPress={onPress}
								text={text}
								type="secondary"
								size="small"
								align="left"
								backgroundColor={TRANSPARENT}
								backgroundActiveColor={WHITE_OVERLAY}
								contentActiveColor={PRIMARY_2}
								contentColor={WHITE}
								fullWidth
							/>
						</React.Fragment>
					))}
				</React.Fragment>
			))}
		</>
	);
};

const InterpreterMenus = ({short, navigateTo, currentKey, vertical}: MenusProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const {mandates} = React.useContext(InboxContext);
	const menus: (keyof InterpreterHomeTabNavigatorParamList)[] = ["Inbox", "Calendar", "InterpreterAccount"];
	return (
		<View style={[vertical && styles.filler, {flexDirection: vertical ? "column" : "row"}]}>
			{menus.map((menu, index) => (
				<React.Fragment key={menu}>
					{index > 0 && <View style={vertical ? {height: SMALL_SPACING} : {width: SMALL_SPACING}}/>}
					<Button
						active={currentKey === menu}
						icon={menuIcons(menu)}
						onPress={navigateTo(menu)}
						text={short ? undefined : menuLabels(ct, t)(menu)}
						type="secondary"
						size="small"
						align="left"
						backgroundColor={TRANSPARENT}
						backgroundActiveColor={MENU_ACTIVE_WEB}
						contentActiveColor={SUBTLE}
						contentColor={SUBTLE}
						fullWidth={vertical}
						notif={ps(menu, {
							Inbox: mandates.length,
							default: 0,
						})}
					/>
				</React.Fragment>
			))}
			<View style={styles.subMenuSpacer}/>
			{vertical && <AccountMenus currentKey={currentKey}
				includedMenus={new Set(["general", "personalised", "development"] as const)}/>}
		</View>
	);
};

const ClientMenus = ({short, navigateTo, currentKey, vertical}: MenusProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const menus: (keyof ClientHomeTabNavigatorParamList)[] = ["NewSession", "Calendar", "Interpreters", "ClientAccount"];
	return (
		<View style={[vertical && styles.filler, {flexDirection: vertical ? "column" : "row"}]}>
			{menus.map((menu, index) => (
				<React.Fragment key={menu}>
					{index > 0 && <View style={vertical ? {height: SMALL_SPACING} : {width: SMALL_SPACING}}/>}
					<Button
						active={currentKey === menu}
						icon={menuIcons(menu)}
						onPress={navigateTo(menu)}
						text={short ? undefined : menuLabels(ct, t)(menu)}
						type="secondary"
						size="small"
						align="left"
						backgroundColor={TRANSPARENT}
						backgroundActiveColor={MENU_ACTIVE_WEB}
						contentActiveColor={SUBTLE}
						contentColor={SUBTLE}
						fullWidth={vertical}
					/>
				</React.Fragment>
			))}
			<View style={styles.subMenuSpacer}/>
			{vertical && <AccountMenus currentKey={currentKey} includedMenus={new Set(["general", "development"] as const)}/>}
		</View>
	);
};

export const MainMenu = ({vertical}: {vertical: boolean}): JSX.Element => {
	const insets = useSafeAreaInsets();
	const navigation = useNavigation<NativeStackNavigationProp<ClientHomeTabNavigatorParamList & InterpreterHomeTabNavigatorParamList>>();
	const {columns, contentWidth} = React.useContext(ResponsiveContext);
	const {identity} = React.useContext(AuthentifiedContext);
	const [currentScreenKey, setCurrentScreenKey] = React.useState<string>(IS_INTERPRETER ? "Inbox" : "NewSession");
	const navigateTo = React.useCallback(
		(menu: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList) =>
			() => navigation.navigate(menu),
		[navigation],
	);
	React.useEffect(
		() => {
			const unsubscribe = Navigation.navigationRef.current?.addListener("state", () => {
				const {name, params} = Navigation.navigationRef.current?.getCurrentRoute() ?? {};
				if (name) {
					setCurrentScreenKey(
						getScreenKey(
							name as keyof StackParamList,
							params,
						));
				}
			});
			return unsubscribe;
		},
		[],
	);
	return (
		<View style={styles.wrapper}>
			<View
				style={[
					CONTAINERS.MAIN,
					vertical ? styles.verticalContainer : styles.horizontalContainer,
					vertical && {paddingBottom: insets.bottom + DEFAULT_SPACING, paddingTop: insets.top + DEFAULT_SPACING},
					!vertical && {maxWidth: contentWidth, paddingTop: insets.top + SMALL_SPACING},
				]}
			>
				<View style={vertical ? styles.filler : [styles.horizontalContainer, styles.horizontalInnerContainer]}>
					<View
						style={[
							styles.logoNameContainer,
							vertical ? styles.verticalLogoNameContainer : styles.horizontalLogoNameContainer,
						]}
					>
						<CustomIcon
							name="app-icon"
							color={APP === "interpreter" ? BLUE : YELLOW}
							size={26}
							onPress={navigateTo(IS_INTERPRETER ? "Inbox" : "NewSession")}
							style={vertical && styles.buttonAlignContainer}
						/>
						{IS_CLIENT && <Text
							color={WHITE}
							type="button_2"
							numberOfLines={vertical ? 5 : 2}
							style={styles.requesterName}
						>
							{(identity as Corporation).name}
						</Text>}
					</View>
					{vertical && <View style={styles.verticalSpacing}/>}
					{IS_INTERPRETER
						? <InterpreterMenus
							short={columns === 1}
							navigateTo={navigateTo}
							currentKey={currentScreenKey}
							vertical={vertical}
						/>
						: <ClientMenus
							short={columns === 1}
							navigateTo={navigateTo}
							currentKey={currentScreenKey}
							vertical={vertical}
						/>
					}
				</View>
				{vertical && IS_WEB &&
					<Text type="touchable_label"
						onPress={onPressVersion}
						style={[styles.version, styles.buttonAlignContainer]}>{`${BUNDLE_VERSION} - ${BUNDLE_BUILD}`}</Text>}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	buttonAlignContainer: {
		paddingLeft: SMALL_SPACING,
	},
	filler: {
		flex: 1,
	},
	horizontalContainer: {
		alignItems: "center",
		flex: 1,
		flexDirection: "row",
		padding: SMALL_SPACING,
	},
	horizontalInnerContainer: {
		padding: 0,
	},
	horizontalLogoNameContainer: {
		flex: 1,
	},
	logoNameContainer: {
		alignItems: "center",
		flexDirection: "row",
	},
	requesterName: {
		paddingHorizontal: SMALL_SPACING,
	},
	subMenuSpacer: {
		flex: 1,
		minHeight: DEFAULT_SPACING,
	},
	version: {
		color: SUBTLE_4,
		marginTop: DEFAULT_SPACING,
	},
	verticalContainer: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		paddingHorizontal: SMALL_SPACING,
		paddingVertical: DEFAULT_SPACING,
		width: VERTICAL_MENU_WIDTH,
	},
	verticalLogoNameContainer: {
		justifyContent: "space-between",
	},
	verticalSpacing: {
		minHeight: LARGE_SPACING,
	},
	wrapper: {
		backgroundColor: MENU_BG,
	},
});
