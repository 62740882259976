/* eslint-disable react-native/no-unused-styles */
// The styles are really used, even if ESLint thinks that it's not the case...!
import * as React from "react";
import {
	Platform,
	StyleProp,
	StyleSheet,
	TextInput as RNTextInput,
	TextInputProps as RNTextInputProps,
	TextStyle,
} from "react-native";
import {CONTENT, PRIMARY_2, SUBTLE_4} from "../../utils/styles/colors";
import {textStyles, TextType} from "./text";

export interface TextInputProps extends Omit<RNTextInputProps, "style"> {
	color?: string;
	style?: StyleProp<Omit<TextStyle, "color">>;
	type?: TextType;
}

export const TEXT_INPUT_MIN_HEIGHT = 28;

const TextInputRenderer = (
	{type = "emphasis_2", color, style, ...textInputProps}: TextInputProps, ref: React.Ref<RNTextInput>): JSX.Element => {
	const defaultColor = color || (type === "default_1" || type === "default_2"
		? CONTENT
		: type === "label" || type === "label_2" ? SUBTLE_4 : PRIMARY_2);
	return (
		<RNTextInput
			{...textInputProps}
			ref={ref}
			style={[
				Platform.select({android: textStyles.android, ios: textStyles.ios, web: textStyles.ios}),
				textStyles[type],
				styles.container,
				textInputProps?.multiline && styles.multiline,
				{color: defaultColor},
				style,
			]}
		/>
	);
};

export const TextInput = React.forwardRef(TextInputRenderer);

const styles = StyleSheet.create({
	container: {
		minHeight: TEXT_INPUT_MIN_HEIGHT,
		...Platform.select({
			web: {
				outlineStyle: "none",
			},
		}),
	},
	multiline: {
		minHeight: 80,
		textAlignVertical: "top",
	},
});
