import {entities as enUSEntities} from "../en-US/entities";

export const entities: typeof enUSEntities = {
	billingAddress: {
		address: "Strasse (mit Nr.)",
		department: "Abteilung",
		name: "Firma",
		postOfficeBox: "Postfach",
		postalCode: "PLZ",
		town: "Ort",
	},
};
