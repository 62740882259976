import * as React from "react";
import {Falsy, StyleSheet, View, ViewProps} from "react-native";
import {SUBTLE_4} from "../../../utils/styles/colors";
import {Text} from "../../texts/text";

export interface InfoListProps {
	key: "info";
	onChangeValue?: (value: any) => void;
	props?: ViewProps;
	renderValue?: (value: any) => Falsy | JSX.Element | string;
	value: any;
}

type InfoInputProps = Omit<InfoListProps, "key">;

export const InfoInput = ({
	renderValue,
	value,
	props,
}: InfoInputProps): JSX.Element => (
	<View
		{...props}
		style={[
			styles.renderValueWrapper,
			props?.style,
		]}
	>
		{renderValue
			? typeof renderValue(value) === "string"
				? (
					<Text type="emphasis_1" color={SUBTLE_4} selectable={false}>
						{renderValue(value)}
					</Text>
				)
				: renderValue(value)
			: <Text type="emphasis_1" color={SUBTLE_4} selectable={false}>{value}</Text>
		}
	</View>
);

const styles = StyleSheet.create({
	renderValueWrapper: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});
