import * as React from "react";
import {Session, SessionContext, SessionSlot} from "../@types/activities/session";
import {Corporation} from "../@types/identities/corporation";
import {InterpreterFilters} from "../@types/identities/filters";
import {InterpreterPreview} from "../@types/identities/interpreter";
import {InterpretersGroup} from "../@types/identities/interpreters-group";
import {SessionCreationSetting} from "../@types/settings";
import {NewSessionSteps} from "../navigation/screens/client/new-session";
import {Locales, localeToSessionLanguage} from "../utils/locales/locales";
import {AuthentifiedContext} from "./authentified";
import {ResponsiveContext} from "./responsive";

type SessionBase = Pick<
Session,
"excludedInterpreters" | "immediate" | "language" | "mandator" | "providedService" | "providers" | "type"
>;

export interface SessionCreationContextVal {
	activeStep: NewSessionSteps;
	detailStepDisabled: boolean;
	filters: InterpreterFilters;
	// the interpreters ids to whom we'll never send the session
	initialExcludedInterpreterIds: InterpreterPreview["accountId"][];
	// the interpreter to whom we'll send the session at the end
	initialProvider?: InterpreterPreview;
	loadingProviders: boolean;
	reset: (sessionContext: SessionContext, base?: Partial<SessionCreationContextVal>) => void;
	scheduleStepDisabled: boolean;
	// The session being created; the provider field is used to store the interpreter when they're chosen before creation
	session: Partial<Session> & SessionBase;
	setActiveStep: React.Dispatch<React.SetStateAction<SessionCreationContextVal["activeStep"]>>;
	setFilters: React.Dispatch<React.SetStateAction<SessionCreationContextVal["filters"]>>;
	setLoadingProviders: React.Dispatch<React.SetStateAction<SessionCreationContextVal["loadingProviders"]>>;
	setSession: React.Dispatch<React.SetStateAction<SessionCreationContextVal["session"]>>;
	setSetupStepValid: React.Dispatch<React.SetStateAction<SessionCreationContextVal["setupStepValid"]>>;
	setSlot: React.Dispatch<React.SetStateAction<SessionCreationContextVal["slot"]>>;
	setupStepValid: boolean;
	/*
	 * The selected time slot for the session.
	 * We can't use the start/end fields on the session directly since we use these to generate the slots to query.
	 */
	slot: SessionSlot | null;
}

const getDefaultStateFunc = (
	interpretersGroups: InterpretersGroup[], identity: Corporation,
): Pick<SessionCreationContextVal, "filters" | "initialExcludedInterpreterIds" | "initialProvider" | "loadingProviders" | "session" | "slot"> => ({
	filters: {
		interpretersGroups,
	},
	initialExcludedInterpreterIds: [],
	initialProvider: undefined,
	loadingProviders: false,
	session: {
		context: {type: "new"},
		excludedInterpreters: [],
		immediate: false,
		language: localeToSessionLanguage(Locales.currentLocale),
		mandator: identity,
		providedService: {type: "standard"},
		providers: [],
		type: "interpreterMandate",
	},
	slot: null,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStepDisabledStates = (
	session: SessionCreationContextVal["session"],
	filters: SessionCreationContextVal["filters"],
	slot: SessionSlot | null,
	setupStepValid: boolean,
) => {
	const {
		communication,
		immediate,
		providedService,
		toLanguage,
		start,
		duration,
		providers,
		excludedInterpreters,
	} = session;
	const scheduleStepDisabled = !setupStepValid ||
		!toLanguage ||
		(!immediate && !start) ||
		!duration ||
		!providedService ||
		!communication?.type ||
		(communication?.type === "inPerson" && !communication.place) ||
		!filters?.interpretersGroups ||
		filters?.interpretersGroups?.length === 0;
	return {
		detailStepDisabled: scheduleStepDisabled ||
			(!immediate && (!slot || (providers.length === 0 && excludedInterpreters.length === 0))),
		scheduleStepDisabled,
	};
};

export const SessionCreationContext = React.createContext<SessionCreationContextVal>({} as SessionCreationContextVal);
export const SessionCreationProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
	const {columns} = React.useContext(ResponsiveContext);

	const {settings: {getSetting}, identity} = React.useContext(AuthentifiedContext);
	const {
		value: {
			interpreterFilters: {interpretersGroups},
		} = {
			interpreterFilters: {
				interpretersGroups: [] as SessionCreationSetting["value"]["interpreterFilters"]["interpretersGroups"],
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};

	const defaultState = getDefaultStateFunc(interpretersGroups, identity! as Corporation);
	const [session, setSession] = React.useState<SessionCreationContextVal["session"]>(defaultState.session);
	const [filters, setFilters] = React.useState<SessionCreationContextVal["filters"]>(defaultState.filters);
	const [slot, setSlot] = React.useState<SessionSlot | null>(defaultState.slot);
	const [activeStep, setActiveStep] = React.useState<NewSessionSteps>("SelectLanguageStep");
	const [
		initialProvider,
		setInitialProvider,
	] = React.useState<SessionCreationContextVal["initialProvider"]>(defaultState.initialProvider);
	const [
		initialExcludedInterpreterIds,
		setInitialExcludedInterpretersIds,
	] = React.useState<SessionCreationContextVal["initialExcludedInterpreterIds"]>(
		defaultState.initialExcludedInterpreterIds);
	const [loadingProviders, setLoadingProviders] = React.useState<boolean>(defaultState.loadingProviders);
	const [setupStepValid, setSetupStepValid] = React.useState(false);

	const {
		scheduleStepDisabled,
		detailStepDisabled,
	} = getStepDisabledStates(session, filters, slot, setupStepValid);

	const reset = React.useCallback(
		(sessionContext: SessionContext, base: Partial<SessionCreationContextVal> = {}) => {
			const state = getDefaultStateFunc(interpretersGroups, identity as Corporation);
			const session = {...state.session, ...base.session, context: sessionContext};
			if (sessionContext.type !== "reschedule") {
				delete session.start;
				delete session.end;
				delete session.duration;
			} else if (session.start && session.start < new Date()) {
				delete session.start;
				delete session.end;
			}

			delete session.status;
			delete session.shareFeedbacks;
			delete session.confirmedFeedback;
			delete session.validationPicture;
			delete session.creationDate;
			delete session.price;
			session.immediate = session.immediate ?? false;
			session.providers = [];
			session.excludedInterpreters = [];

			setSession(session);

			const slot = base.slot ?? state.slot;
			setSlot(slot);
			const filters = base.filters ?? state.filters;
			/*
			 * We need to keep the old qualifications in case the interpreter groups are the same as before.
			 *
			 * Qualifications are fetched in useInterpreterFilterInputs if the selected interpreter groups ids change, so if
			 * the interpreter groups stay the same but the qualifications are dropped, qualifications would not be refetched.
			 *
			 * Note that if interpreter groups are not kept (if they would be set in base.filters), it still works since
			 * useInterpreterFilterInputs will refetch them.
			 */
			setFilters(prev => ({qualifications: prev?.qualifications, ...filters}));
			setInitialProvider(base.initialProvider ?? state.initialProvider);
			setInitialExcludedInterpretersIds(base.initialExcludedInterpreterIds ?? state.initialExcludedInterpreterIds);
			const setupStepValid = sessionContext.type !== "new";
			setSetupStepValid(setupStepValid);

			const {scheduleStepDisabled} = getStepDisabledStates(session, filters, slot, setupStepValid);
			setActiveStep(
				sessionContext.type === "new" || columns === 1
					? "SelectLanguageStep"
					: scheduleStepDisabled
						? "SetupStep"
						: session.immediate
							? "DetailsStep"
							: "ScheduleStep",
			);
		},
		[columns, identity, interpretersGroups],
	);

	return (
		<SessionCreationContext.Provider
			value={{
				activeStep,
				detailStepDisabled,
				filters,
				initialExcludedInterpreterIds,
				initialProvider,
				loadingProviders,
				reset,
				scheduleStepDisabled,
				session,
				setActiveStep,
				setFilters,
				setLoadingProviders,
				setSession,
				setSetupStepValid,
				setSlot,
				setupStepValid,
				slot,
			}}
		>
			{children}
		</SessionCreationContext.Provider>
	);
};
