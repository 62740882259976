// An object that can be indexed.
export interface Indexable<T = any> {
	[key: string]: T;
}

/**
 * Determines whether an entity is an object.
 *
 * @param {object} obj - The object to evaluate.
 * @returns {boolean} - Whether the parameter is an object.
 */
export const isObject = (obj: any): obj is {} =>
	typeof obj === "object" && !Array.isArray(obj) && obj !== null;

// Deeply copies an object.
export const clone = <T>(obj: T): T => {
	if (typeof obj !== "object" || obj === undefined || obj === null) {
		return obj;
	}
	if (Array.isArray(obj)) {
		return obj.map(o => clone(o)) as unknown as T;
	}

	return Object.entries(obj)
		.filter(e => e[1] !== undefined)
		// eslint-disable-next-line unicorn/no-array-reduce,no-sequences,no-return-assign
		.reduce((acc, [k, v]) => (acc[k] = clone(v), acc), Object.create(Object.getPrototypeOf(obj)));
};

type Merge<T, U> = {
	[key in keyof T | keyof U]: MergedProperty<key, T, U>
};

type MergedProperty<Key, T, U> = Key extends keyof T
	? Key extends keyof U
		? MergedConflictingProperty<Key, T, U>
		: T[Key]
	: Key extends keyof U
		? U[Key]
		: never;

type MergedConflictingProperty<Key extends keyof T & keyof U, T, U> = T[Key] extends {}
	? U[Key] extends {}
		? Merge<T[Key], U[Key]>
		: U[Key]
	: U[Key];

export const removeKeysRecursively = (o: any, keys: string[]): any => {
	if (!o) {
		return o;
	}
	if (!keys) {
		return o;
	}
	keys.forEach((k) => {
		getKeys(o).forEach((key) => {
			if (key === k) {
				// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-dynamic-delete
				delete o[key];
			} else if (Array.isArray(o[key])) {
				o[key].forEach((e: any) => {
					removeKeysRecursively(e, [k]);
				});
			} else if (typeof o[key] === "object") {
				removeKeysRecursively(o[key], [k]);
			}
			// else: it's probably a primitive
		});
	});

	return o;
};

export const isSubset = (superset: Record<string, unknown>, subset: Record<string, unknown>): boolean => {
	if (
		(typeof superset !== "object" || superset === null) ||
		(typeof subset !== "object" || subset === null)
	) {
		return false;
	}

	if (
		(superset instanceof Date || subset instanceof Date)
	) {
		return superset.valueOf() === subset.valueOf();
	}

	return getKeys(subset).every((key) => {
		if (!Object.prototype.propertyIsEnumerable.call(superset, key)) {
			return false;
		}
		const subsetItem = subset[key] as Record<string, unknown>;
		const supersetItem = superset[key] as Record<string, unknown>;
		if (
			(typeof subsetItem === "object" && subsetItem !== null)
				? !isSubset(supersetItem, subsetItem)
				: supersetItem !== subsetItem
		) {
			return false;
		}

		return true;
	});
};

// Type-preserving Object.keys (which always returns string[])
export const getKeys = <T extends {}>(o: T): (keyof T)[] => Object.keys(o) as (keyof T)[];
export const getEntries = <T extends {}, K extends keyof T>(o: T): ([K, T[K]])[] => Object.entries(o) as ([K, T[K]])[];
