import {useNavigation} from "@react-navigation/native";
import React from "react";
import {GestureResponderEvent, SectionListData as RNSectionListData} from "react-native";
import {confirmation} from "../../components/feedbacks/confirmation";
import {IconKey} from "../../components/icons";
import {AuthentifiedContext} from "../../contexts/authentified";
import {LocaleContext} from "../../contexts/locale";
import {ResponsiveContext} from "../../contexts/responsive";
import {SessionCreationContext} from "../../contexts/session-creation";
import {logoutClient} from "../../requests/clients/account/logout";
import {logoutInterpreter} from "../../requests/interpreters/account/logout";
import {filterTruthy} from "../arrays";
import {ENV, IS_CLIENT, IS_INTERPRETER, IS_MOBILE, IS_WEB} from "../constants";
import {AsyncStorage} from "../externals/storages/async-storage";
import {Locales, localeToSessionLanguage, sessionLanguageToLocale, supportedLocales} from "../locales/locales";
import {Log} from "../logs/logs";
import {StackParamList} from "../navigation/paramLists/root-param-list";
import {getScreenKey} from "../navigation/utils";
import {linkToURL} from "../network/links";
import {getKeys} from "../objects";
import {SessionLanguagesKey} from "../sessions/languages";
import {ps} from "../switch";
import {useTranslation} from "./use-translation";

export type SectionKey = "development" | "general" | "personalised";

export interface SectionData {
	icon: IconKey;
	key: string;
	onPress: (event?: GestureResponderEvent) => void;
	text: string;
}

type SectionListData = RNSectionListData<SectionData> & {key: SectionKey};

export const onPressVersion = (): void => {
	linkToURL(IS_INTERPRETER
		? "https://bhaasha.notion.site/Interpreter-App-Releases-81d3a458cfd7438b9440f302868a2b39"
		: "https://bhaasha.notion.site/Client-App-Releases-76a487bb3d08494dae3cfca2df224f57",
	).catch(Log.error());
};

const onPressKnownIssues = (): () => Promise<unknown> => ps(Locales.currentLocale, {
	"de-DE": () => linkToURL("https://bhaasha.notion.site/Bekannte-Probleme-e5238719ce4142ffa4bd34c2a62efbce"),
	default: () => linkToURL("https://bhaasha.notion.site/Known-Issues-79559e4268444d2b8291007d92b8e201"),
	"fr-FR": () => linkToURL("https://bhaasha.notion.site/Probl-mes-connus-b90a6d17d67f41c9a04e3b9c239bcff1"),
});

const onPressGuide = (): () => Promise<unknown> => IS_INTERPRETER
	? ps(Locales.currentLocale, {
		"de-DE": () => linkToURL("https://bhaasha.notion.site/Interpreter-App-Deutsch-02b7bcf15b814c1dbf36fcb5fc1c4f7a"),
		default: () => linkToURL("https://bhaasha.notion.site/Interpreter-App-Deutsch-02b7bcf15b814c1dbf36fcb5fc1c4f7a"),
		"fr-FR": () => linkToURL("https://bhaasha.notion.site/Interpreter-App-Fran-ais-7cc26a9ef6754380b1e49d19a0ff0873"),
	})
	: ps(Locales.currentLocale, {
		"de-DE": () => linkToURL("https://bhaasha.notion.site/Client-App-Deutsch-610202e2e4c745f5b4e6456504259f59"),
		default: () => linkToURL("https://bhaasha.notion.site/Client-App-Deutsch-610202e2e4c745f5b4e6456504259f59"),
		"fr-FR": () => linkToURL("https://bhaasha.notion.site/Application-Client-5771ee1ccad94d668c4baa0e77b9e0b6"),
	});

export const useAccountSections = (): SectionListData[] => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation();
	const {columns} = React.useContext(ResponsiveContext);
	const {reset: resetCreation, session} = React.useContext(SessionCreationContext);
	const {signOut} = React.useContext(AuthentifiedContext);
	const {locale, setLocale} = React.useContext(LocaleContext);

	const onSelectedLanguage = (languageKey: SessionLanguagesKey): void => {
		setLocale(sessionLanguageToLocale(languageKey))
			.then(() => IS_CLIENT && resetCreation({type: "new"})).catch(Log.error());
	};

	const createScreenProperties = <S extends keyof StackParamList>(
		screen: S, params?: StackParamList[S]): {key: string; onPress: () => void} => ({
		key: getScreenKey(screen, params),
		// @ts-expect-error FIXME: Typescript error to FIX
		onPress: () => navigation.navigate(screen, params),
	});

	const includedLanguages = React.useMemo(
		() => {
			const languages = new Set<SessionLanguagesKey>();
			getKeys(supportedLocales).forEach(l => languages.add(localeToSessionLanguage(l)));
			return languages;
		},
		[],
	);

	const selectLanguagesData: SectionData = {
		icon: "language",
		text: ct("common:languageUI"),
		...createScreenProperties("SelectLanguageModal", {
			currentSessionLanguage: session?.toLanguage,
			hideSectionTitles: true,
			includedLanguages,
			onSelect: onSelectedLanguage,
			resetSessionContext: true,
		}),
	};

	const sections: SectionListData[] = IS_INTERPRETER
		? [
			{
				data: filterTruthy([
					locale === "de-DE" && {
						icon: "lightbulb",
						text: ct("screens:profile.slides"),
						...createScreenProperties("SliderModal", {sliderKey: "welcome"}),
					}, {
						icon: "guide",
						key: "guide",
						onPress: onPressGuide,
						text: ct("screens:profile.guide"),
					},
					columns === 1 && {
						icon: "calendarUser",
						text: t("screens:profile.edit"),
						...createScreenProperties("EditProfileModal"),
					},
					columns === 1 && {
						icon: "stats",
						text: ct("common:salary_plural"),
						...createScreenProperties("SalaryModal"),
					}, {
						icon: "availability",
						text: ct("common:availability_plural"),
						...createScreenProperties("AvailabilitiesModal"),
					}, {
						icon: "card",
						text: t("users:settings.orderCards"),
						...createScreenProperties("SupportModal", {subject: "validation-cards"}),
					}, {
						icon: "notificationsOn",
						text: ct("common:notification_plural"),
						...createScreenProperties("NotificationsModal"),
					},
					selectLanguagesData,
					{
						icon: "knownIssues",
						key: "knownIssues",
						onPress: onPressKnownIssues,
						text: t("screens:profile.knownIssues"),
					}, {
						icon: "support",
						text: t("screens:profile.support"),
						...createScreenProperties("SupportModal"),
					}, {
						icon: "logout",
						key: "logout",
						onPress: () => confirmation({
							actions: [
								{
									icon: "logout",
									isPromise: true,
									key: "signOut",
									onPress: () => logoutInterpreter()
										.then(signOut)
										.catch(Log.error("logoutFailed")),
									text: ct("common:signOut"),
								}, {
									icon: "close",
									key: "closeDrawer",
									onPress: () => null,
									text: ct("common:cancel"),
									type: "secondary",
								},
							],
							content: t("screens:signOut.confirm"),
						}),
						text: ct("common:signOut"),
					},
				]),
				key: "general",
			},
		]
		: [
			{
				data: filterTruthy([
					(IS_MOBILE || (IS_WEB && (locale === "fr-FR" || locale === "de-DE"))) && {
						icon: "lightbulb",
						text: ct("screens:profile.slides"),
						...createScreenProperties("SliderModal", {sliderKey: IS_WEB ? "welcomeRequester" : "welcomeRequesterMobile"}),
					}, {
						icon: "guide",
						key: "guide",
						onPress: onPressGuide,
						text: ct("screens:profile.guide"),
					},
					{
						icon: "notificationsOn",
						text: ct("common:notification_plural"),
						...createScreenProperties("NotificationsModal"),
					},
					selectLanguagesData,
					{
						icon: "support",
						text: t("screens:profile.support"),
						...createScreenProperties("SupportModal"),
					}, {
						icon: "logout",
						key: "logout",
						onPress: () => confirmation({
							actions: [
								{
									icon: "logout",
									isPromise: true,
									key: "signOut",
									onPress: () => logoutClient()
										.then(() => resetCreation({type: "new"}))
										.then(signOut)
										.catch(Log.error("logoutFailed")),
									text: ct("common:signOut"),
								}, {
									icon: "close",
									key: "closeDrawer",
									onPress: () => null,
									text: ct("common:cancel"),
									type: "secondary",
								},
							],
							content: t("screens:signOut.confirm"),
						}),
						text: ct("common:signOut"),
					},
				]),
				key: "general",
				title: ct("common:general"),
			},
		];

	if (ENV === "development") {
		sections.push({
			data: [
				{
					icon: "delete",
					key: "deleteStorage",
					onPress: () => {
						AsyncStorage.removeAllItems().then(signOut).catch(Log.error());
					},
					text: "Delete Storage",
				}, {
					icon: "id",
					text: "Debug",
					...createScreenProperties("DebugModal"),
				}, {
					icon: "component",
					text: "Components",
					...createScreenProperties("ComponentsModal"),
				}, {
					icon: "cancel",
					key: "crash",
					onPress: () => {
						throw new Error("Test Log Service Crash");
					},
					text: "Crash",
				},
			],
			key: "development",
			title: "Development",
		});
	}

	return sections;
};
