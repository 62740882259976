import {LinkingOptions} from "@react-navigation/native";
import {IS_INTERPRETER} from "../../constants";
import {
	ClientAuthentifiedScreensGroupParamList,
	ClientHomeTabNavigatorParamList,
} from "../paramLists/client-param-list";
import {InterpreterHomeTabNavigatorParamList} from "../paramLists/interpreter-param-list";
import {StackParamList} from "../paramLists/root-param-list";
import {authPaths} from "./auth-paths";
import {clientHomeTabPaths, clientPaths} from "./client-paths";
import {interpreterHomeTabPaths, interpreterPaths} from "./interpreter-paths";
import {publicPaths} from "./public-paths";
import {sharedPaths} from "./shared-paths";

export const rootPaths: LinkingOptions<StackParamList>["config"] = {
	screens: {
		...authPaths,
		...(IS_INTERPRETER ? interpreterPaths : clientPaths),
		...(IS_INTERPRETER
			? interpreterHomeTabPaths
			: clientHomeTabPaths) as {[Key in keyof Pick<ClientAuthentifiedScreensGroupParamList, "HomeTabNavigator">]: LinkingOptions<ClientHomeTabNavigatorParamList | InterpreterHomeTabNavigatorParamList>["config"]},
		...publicPaths,
		...sharedPaths,
	},
};
