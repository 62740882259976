import * as React from "react";
import {FlatList, ListRenderItemInfo, StyleSheet} from "react-native";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {Pressable} from "../../../../components/views/pressable";
import {SplashView} from "../../../../components/views/splash-view";
import {ActivitiesContext} from "../../../../contexts/activities";
import {CONTAINERS, SMALL_SPACING} from "../../../../utils/constants";
import {compareMonths, months} from "../../../../utils/date-time/helpers";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {getKeys} from "../../../../utils/objects";
import {flatListDefaultProps} from "../../../../utils/scrollables";
import {CONTRAST_3, PRIMARY_2, SUBTLE, SUBTLE_4} from "../../../../utils/styles/colors";
import {forceBack} from "../../../navigation";

const MONTH_HEIGHT = 43;

interface ListItem {
	key: string; // YYYY-MM
}

const keyExtractor = (item: ListItem): string => item.key;

const getItemLayout = (_: ListItem[] | null | undefined, index: number): {
	index: number;
	length: number;
	offset: number;
} => ({
	index,
	length: MONTH_HEIGHT,
	/*
	 * +1 for separator
	 * +SMALL_SPACING for top padding from CONTAINERS.LIST
	 */
	offset: 0, // Should be: index * (MONTH_HEIGHT + 1) + SMALL_SPACING but it creates a weird top padding
});

const SelectMonth = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"SelectMonthModal">): JSX.Element => {
	const {onSelect: onSelectParam, selectionOrBase, from, to} = route.params ?? {};
	const {ct, t} = useTranslation();
	const {activities, updateActivities} = React.useContext(ActivitiesContext);

	React.useEffect(
		() => {
			const unsubscribe = navigation.addListener(
				"focus",
				() => {
					if (!activities) {
						updateActivities().catch(Log.error());
					}
				},
			);
			return unsubscribe;
		},
		[activities, navigation, updateActivities],
	);

	const list = React.useMemo(
		(): ListItem[] | null => {
			if (!activities) {
				return null;
			}
			const fromDate = new Date(from ?? "1900");
			const toDate = new Date(to ?? "2100");
			const dateSet = new Set<string>();
			getKeys(activities)
				.filter((key) => {
					const date = new Date(key);
					return date >= fromDate && date <= toDate;
				})
				.forEach((key) => dateSet.add(String(key).slice(0, 7)));
			return [...dateSet].sort().map((key) => ({key}));
		},
		[activities, from, to],
	);

	const onSelect = (date: Date): void => {
		onSelectParam?.(date);
		navigation.dispatch(forceBack);
	};
	const currentDate = new Date();
	const renderItem = ({item: {key}}: ListRenderItemInfo<ListItem>): JSX.Element => {
		const date = new Date(key);
		return (
			<Pressable onPress={() => onSelect(date)} style={styles.monthContainer} underlayColor={SUBTLE}>
				<Text type="title_2" color={compareMonths(date, selectionOrBase ?? currentDate) ? CONTRAST_3 : PRIMARY_2}>
					{ct(`activities:calendar.month.${months[date.getMonth()]}`)}
					<Text type="emphasis_1" color={SUBTLE_4}> {date.getFullYear()}</Text>
				</Text>
			</Pressable>
		);
	};
	return (
		<>
			<HeaderMenu
				center={t("forms:inputs.selectMonth")}
			/>
			{list
				? (
					<FlatList
						{...flatListDefaultProps}
						data={list}
						renderItem={renderItem}
						keyExtractor={keyExtractor}
						contentContainerStyle={CONTAINERS.LIST}
						getItemLayout={getItemLayout}
						scrollsToTop={false}
					/>
				)
				: (
					<SplashView
						loading={!list}
						centered
					/>
				)}
		</>
	);
};

const styles = StyleSheet.create({
	monthContainer: {
		alignItems: "center",
		height: MONTH_HEIGHT,
		padding: SMALL_SPACING,
	},
});

export const SelectMonthModal = (props: InterpreterStackNavigatorScreenProps<"SelectMonthModal">): JSX.Element => (
	<ModalWrapper>
		<SelectMonth {...props} />
	</ModalWrapper>
);
