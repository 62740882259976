import * as React from "react";
import {Falsy, StyleSheet, View} from "react-native";
import {Session} from "../../../@types/activities/session";
import {Issues, IssueType, SessionValidation, ValidationStatus} from "../../../@types/validation";
import {ValidationSettings} from "../../../@types/validation-settings";
import {filterTruthy} from "../../../utils/arrays";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {getDateParts} from "../../../utils/date-time/helpers";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {TranslationKey} from "../../../utils/locales/translations";
import {getKeys} from "../../../utils/objects";
import {getDefaultCanceledStatusIssues, getDefaultCompletedStatusIssues} from "../../../utils/sessions/validation";
import {PRIMARY, SUBTLE_2, SUBTLE_4} from "../../../utils/styles/colors";
import {confirmation} from "../../feedbacks/confirmation";
import {Icon, IconProps} from "../../icons";
import {Separator} from "../../separator";
import {Spacer} from "../../spacer";
import {Text} from "../../texts/text";

const ValueBox = ({char, border}: {border: "both" | "left" | "right"; char: string}): JSX.Element => (
	<View
		style={[
			styles.box,
			(border === "both" || border === "left") && {borderLeftWidth: 2},
			(border === "both" || border === "right") && {borderRightWidth: 2},
		]}
	>
		<Text type="emphasis_1">{char}</Text>
	</View>
);

const ValueBoxGroup = ({chars}: {chars: string}): JSX.Element => (
	<View style={styles.row}>
		{[...chars].map((char, i) => (
			<ValueBox
				// eslint-disable-next-line react/no-array-index-key
				key={`${i}-${char}`}
				char={char}
				border={i === chars.length - 1
					? "both"
					: "left"}
			/>
		))}
	</View>
);

const ValueBoxGroups = ({groups}: {groups: string[]}): JSX.Element => (
	<>
		{groups.map((chars, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<React.Fragment key={`${i}-${chars}`}>
				{i > 0 && <Spacer mode="vertical" size={SMALL_SPACING}/>}
				<ValueBoxGroup chars={chars}/>
			</React.Fragment>
		))}
	</>
);

const LabeledValueBoxGroups = ({label, groups}: {groups: string[]; label: string}): JSX.Element => (
	<View style={styles.labelAndValue}>
		<Text style={styles.label}>{label}</Text>
		<View style={styles.row}>
			<ValueBoxGroups groups={groups}/>
		</View>
	</View>
);

interface CheckBoxProps {
	disabled?: boolean;
	key: string;
	label?: string;
	onChangeValue?: (value: boolean) => void;
	select?: boolean;
	value: boolean;
}

const CheckBox = ({onChangeValue, value, disabled, select}: CheckBoxProps): JSX.Element => {
	const iconProps: Partial<IconProps> = {
		color: disabled ? SUBTLE_4 : PRIMARY,
		onPress: onChangeValue ? () => !disabled && onChangeValue(!value) : undefined,
	};
	return value
		? <Icon icon={select ? "selectMarked" : "checkBoxMarked"} {...iconProps} />
		: <Icon icon={select ? "selectEmpty" : "checkBoxEmpty"} {...iconProps} />;
};

const CheckBoxGroup = ({
	checkboxes,
	select,
}: {checkboxes: (CheckBoxProps | Falsy)[]; select?: boolean}): JSX.Element => {
	const displayedCheckboxes = filterTruthy(checkboxes);
	return (
		<View style={styles.row}>
			{displayedCheckboxes
				.map(({key, label, value, onChangeValue, disabled}) => (
					<View key={key} style={[styles.checkBoxAndLabel, {marginRight: SMALL_SPACING}]}>
						<CheckBox
							key={key}
							select={select}
							value={value}
							onChangeValue={onChangeValue}
							disabled={disabled}/>
						<Text>{label}</Text>
					</View>
				))}
		</View>
	);
};

interface SessionValidationCardProps {
	cancelable?: boolean;
	disableAbsentReceiver: boolean;
	disableCommunicationTypeChanged: boolean;
	disabled?: boolean;
	end?: Session["end"];
	feedbackSetting?: ValidationSettings["feedback"];
	id: Session["activityId"];
	immediate?: boolean;
	sessionValidation: SessionValidation;
	setSessionValidation: React.Dispatch<React.SetStateAction<SessionValidation>>;
	start: Session["start"];
}

export const SessionValidationCard = (
	{
		id,
		start,
		end,
		immediate,
		disabled,
		sessionValidation,
		setSessionValidation,
		cancelable,
		disableAbsentReceiver,
		disableCommunicationTypeChanged,
		feedbackSetting,
	}: SessionValidationCardProps,
): JSX.Element => {
	const {ct, t} = useTranslation();
	const startParts = getDateParts(start);
	const endParts = getDateParts(end);

	const askForConfirmation = (callback: () => void, issues: Issues, titleScope: TranslationKey): void => {
		const issuesCount = getKeys(issues)
			// these issues are not count as set issues as they are automatically added by default
			.filter(
				k => k !== "effectiveStartTime" && k !== "effectiveEndTime" && k !== "cancellationTime" && k !== "other" &&
					k !== "feedback")
			.length;
		if (issuesCount === 0) { // no risk
			callback();
		} else { // risk of loosing some issues if the change is done
			confirmation({
				actions: [
					{
						icon: "check",
						key: "confirm",
						onPress: () => callback(),
						text: ct("common:confirm"),
					}, {
						icon: "close",
						key: "closeDrawer",
						onPress: () => null,
						text: ct("common:cancel"),
						type: "secondary",
					},
				],
				content: t(titleScope, {count: issuesCount}) as string,
			});
		}
	};

	const setCheckedIssue = (
		issues: Issues,
		issueType: IssueType,
		checked: boolean,
	): void => {
		/*
		 * An issue is set to null when checked and his key is deleted when it's unchecked.
		 * An issue with a null value need to be filled by the interpreter before to allow him to validate.
		 * An issue with an object as value has already been filled.
		 */
		if (checked) {
			const addIssue = (): void => {
				setSessionValidation(prev => {
					const is: Issues = {...(prev.issues ?? issues)};
					// @ts-expect-error works in strict mode
					is[issueType] = null;
					return {...prev, issues: is};
				});
			};
			if (issueType === "absentReceiver") {
				// absentReceiver has been checked
				askForConfirmation(addIssue, issues, "activities:sessions.validate.issue.type.absentReceiver.warning");
			} else {
				addIssue();
			}
		} else {
			setSessionValidation(prev => {
				const is: Issues = {...prev.issues};
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete is[issueType];
				return {...prev, issues: is};
			});
		}
	};

	const onStatusChange = (val: boolean, status: ValidationStatus): void => {
		if (val && status !== sessionValidation.status) { // deactivate the unchecking of a checkbox
			// status has changed
			const saveStatusChange = (): void => {
				const issues = status === "completed"
					? getDefaultCompletedStatusIssues(start, feedbackSetting)
					: getDefaultCanceledStatusIssues();
				setSessionValidation(prev => ({...prev, issues, status}));
			};
			askForConfirmation(saveStatusChange, sessionValidation.issues, "activities:sessions.validate.status.warning");
		}
	};

	return (
		<>
			<View style={styles.cardRow}>
				<LabeledValueBoxGroups
					label={t("activities:sessions.validate.sessionId")}
					groups={[id]}
				/>
			</View>
			<Spacer size={DEFAULT_SPACING}/>
			<View style={styles.cardRow}>
				<LabeledValueBoxGroups
					label={t("forms:inputs.startDate")}
					groups={[startParts.day, startParts.month, startParts.year]}
				/>
			</View>
			<Spacer size={DEFAULT_SPACING}/>
			<View style={styles.cardRow}>
				<LabeledValueBoxGroups
					label={t("forms:inputs.startTime")}
					groups={[startParts.hours, startParts.minutes]}
				/>
				<View style={styles.separator}>
					<Text type="emphasis_1">—</Text>
				</View>
				<LabeledValueBoxGroups
					label={t("forms:inputs.endTime")}
					groups={[endParts.hours, endParts.minutes]}
				/>
			</View>
			<Spacer size={DEFAULT_SPACING}/>
			<CheckBoxGroup
				select
				checkboxes={[
					new Date() > start && {
						disabled,
						key: "completed",
						label: t("activities:sessions.validate.status.completed"),
						onChangeValue: (val: boolean) => onStatusChange(val, "completed"),
						value: sessionValidation.status === "completed",
					},
					cancelable && {
						disabled,
						key: "canceled",
						label: t("activities:sessions.validate.status.canceled"),
						onChangeValue: (val: boolean) => onStatusChange(val, "canceled"),
						value: sessionValidation.status === "canceled",
					},
				]}
			/>
			{sessionValidation.status === "completed" && (
				<>
					<Separator/>
					<CheckBoxGroup
						checkboxes={[
							!immediate && !disableAbsentReceiver && {
								disabled,
								key: "absentReceiver",
								label: t("activities:sessions.validate.issue.type.absentReceiver.label"),
								onChangeValue: (val: boolean) =>
									setCheckedIssue(sessionValidation.issues, "absentReceiver", val),
								value: sessionValidation.issues.absentReceiver !== undefined,
							},
							!disableCommunicationTypeChanged && {
								disabled: disabled || !!sessionValidation.issues.absentReceiver,
								key: "communicationTypeChanged",
								label: t("activities:sessions.validate.issue.type.communicationTypeChanged.label"),
								onChangeValue: (val: boolean) =>
									setCheckedIssue(sessionValidation.issues, "communicationTypeChanged", val),
								value: sessionValidation.issues.communicationTypeChanged !== undefined,
							}, {
								disabled: disabled || !!sessionValidation.issues.absentReceiver,
								key: "incident",
								label: t("activities:sessions.validate.issue.type.incident.label"),
								onChangeValue: (val: boolean) =>
									setCheckedIssue(sessionValidation.issues, "incident", val),
								value: sessionValidation.issues.incident !== undefined,
							},
						]}
					/>
				</>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	box: {
		alignItems: "center",
		borderBottomWidth: 2,
		borderColor: SUBTLE_2,
		borderTopWidth: 2,
		height: 40,
		justifyContent: "center",
		width: 30,
	},
	cardRow: {
		flexDirection: "row",
	},
	checkBoxAndLabel: {
		alignItems: "center",
		flexDirection: "row",
	},
	label: {
		marginBottom: 4,
	},
	labelAndValue: {
		flexDirection: "column",
	},
	row: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	separator: {
		justifyContent: "flex-end",
		marginBottom: 12,
		paddingHorizontal: SMALL_SPACING,
	},
});
