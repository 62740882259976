export const randomString = (): string => Math.random().toString(36).slice(2);
export const randomStrings = (max = 100, min = 0): string[] =>
	Array.from({length: randomNumber(max, min)}).map(_ => randomString());
/**
 * Return a random number between min and max (min and max included)
 */
export const randomNumber = (max = 100, min = 0): number => min + Math.round(Math.random() * (max - min));
export const randomBoolean = (): boolean => Math.random() > 0.5;
const randomIndex = <T>(array: T[] | readonly T[]): number => Math.floor(Math.random() * array.length);
export const randomlyFrom = <T>(array: T[] | readonly T[]): T => array[randomIndex(array)];
export const nRandomlyFrom = <T>(array: T[] | readonly T[], n: number): T[] => {
	const pool = [...array];
	return Array.from({length: Math.min(pool.length, n)}).map(_ => {
		const index = randomIndex(pool);
		const value = pool[index];
		pool.splice(index, 1);
		return value;
	});
};
export const randomDate = (start: Date, end: Date): Date =>
	new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
export const shuffle = <T>(array: T[]): T[] => {
	const shuffledArray = [...array];
	let currentIndex = array.length;
	let temporaryValue;
	let swapIndex;
	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		swapIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		// And swap it with the current element.
		temporaryValue = shuffledArray[currentIndex];
		shuffledArray[currentIndex] = shuffledArray[swapIndex];
		shuffledArray[swapIndex] = temporaryValue;
	}
	return shuffledArray;
};
