import React from "react";
import {StyleSheet, View} from "react-native";
import {DEFAULT_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {SUBTLE_4} from "../../../utils/styles/colors";
import {Text} from "../../texts/text";
import {ItemTranslation} from "../list-props.common";

export const ListEmpty = ({itemTranslationKey, itemTranslation}: ItemTranslation): JSX.Element => {
	const {ct, t} = useTranslation();
	return (
		<View style={styles.container}>
			<Text color={SUBTLE_4}>
				{ct("forms:items.empty", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation})}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flex: 1,
		justifyContent: "center",
		padding: DEFAULT_SPACING,
	},
});
