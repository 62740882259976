import {Session} from "../../../../@types/activities/session";
import {Issues, IssueType, SessionValidation, ValidationTypesSettings} from "../../../../@types/validation";
import {ValidationSettings} from "../../../../@types/validation-settings";
import {RequestTokenHolder} from "../../../../utils/auth";
import {API_HOST, HTTP_PROTOCOL, IS_WEB} from "../../../../utils/constants";
import {PickerImage} from "../../../../utils/inputs/pickers/image-picker";
import {api} from "../../../../utils/network/api";
import {ApiResponse} from "../../../../utils/network/api-response";
import {BackendIssue, ValidationBody} from "../../../@types/session";
import {transformSessionStatusFrontToBack} from "../../../utils/transform";

export type CommonSessionValidationTypesRequests = {
	GET: {
		queryParams?: {sessionId: string},
	},
};

export type CommonSessionValidationUpdateRequests = {
	POST: {
		body: ValidationBody,
	},
};

export type CommonSessionFeedbackValidationRequest = {
	GET: {
		queryParams?: {sessionId: string},
	},
};

export const getSessionValidationSettings = async (sessionId: Session["activityId"]): Promise<ValidationSettings> => {
	const response = await api(
		"commonSessionFeedbackValidation",
		"GET",
		{
			queryParams: {
				sessionId,
			},
		},
	) as ApiResponse<ValidationSettings>;
	if (response.isSuccess) {
		return response.data;
	}
	throw new Error(response.message);
};

export const getSessionValidationTypes = async (sessionId: Session["activityId"]): Promise<ValidationTypesSettings> => {
	const response = await api(
		"commonSessionValidationTypes",
		"GET",
		{
			queryParams: {
				sessionId,
			},
		},
	) as ApiResponse<{methods: ValidationTypesSettings}>;
	const {inPerson} = response.data.methods;
	if (response.isSuccess) {
		return {
			inPerson,
			video: ["none"],
			phone: ["none"],
		};
	}
	throw new Error(response.message);
};

export const updateSessionValidationPicture = async (sessionId: Session["activityId"], picture: PickerImage): Promise<void> => {
	let validationPictureName;
	switch (picture.mime) {
		case "image/jpeg": {
			validationPictureName = "validation_pic.jpeg";
			break;
		}
		case "image/png": {
			validationPictureName = "validation_pic.png";
			break;
		}
		case "image/gif": {
			validationPictureName = "validation_pic.gif";
			break;
		}
	}
	const formData = new FormData();
	formData.append(
		"file",
		IS_WEB
			? picture.blob
			: {
				uri: picture.path,
				type: picture.mime,
				name: validationPictureName,
			} as any as Blob,
	);
	const url = `${HTTP_PROTOCOL}://${API_HOST}/v4/inperson-session/${sessionId}/upload-image`;
	const response = await fetch(url, {
		method: "POST",
		body: formData,
		headers: {
			"x-xsrf-token": RequestTokenHolder.token,
		},
	});
	if (!response.ok) {
		throw new Error(response.status === 400 && "File size is too large");
	}
};

export const updateSessionValidation = async (sessionValidation: SessionValidation): Promise<void> => {
	const {status, sessionId, selectedValidation, issues} = sessionValidation;
	if (selectedValidation && selectedValidation.type === "picture") {
		selectedValidation.picture = {} as PickerImage;
	}
	const validationIssues = {...issues} as Issues;
	if (validationIssues.effectiveStartTime && validationIssues.effectiveEndTime &&
		(validationIssues.effectiveEndTime.effectiveEndTime.getTime() < validationIssues.effectiveStartTime.effectiveStartTime.getTime())) {
		throw new Error("Effective start time is greater than end time");
	}
	const backendIssues = Object.keys(validationIssues)
		.filter((issueKey: IssueType) => validationIssues[issueKey])
		.map((issueKey: IssueType) => {
			const issueData = validationIssues[issueKey];
			switch (issueData.type) {
				case "effectiveStartTime": {
					return {
						type: issueData.type,
						effectiveStartTime: issueData.effectiveStartTime.getTime(),
						description: issueData.description,
					};
				}
				case "effectiveEndTime": {
					return {
						type: issueData.type,
						effectiveEndTime: issueData.effectiveEndTime.getTime(),
						description: issueData.description,
					};
				}
				case "cancellationTime": {
					return {
						...issueData,
						cancellationTime: issueData.cancellationTime.getTime(),
					};
				}
				case "communicationTypeChanged": {
					return {
						...issueData,
						changed: {
							type: issueData.changed.communication.type,
							address: issueData.changed.communication.type === "inPerson"
								? (issueData.changed.communication).place.address
								: null,
						},
						type: "sessionTypeChanged",
					};
				}
				default: {
					return issueData;
				}
			}
		});
	const interpreterResponse = await api(
		"commonSessionValidationUpdate",
		"POST",
		{
			body: {
				/* eslint-disable-next-line react/destructuring-assignment,unicorn/consistent-destructuring */
				toccoNbPersons: sessionValidation.status === "completed" ? sessionValidation.toccoPeopleNumber : undefined,
				selectedValidation,
				issues: backendIssues as BackendIssue[],
				sessionId,
				status: transformSessionStatusFrontToBack(status),
			},
		},
	) as ApiResponse<{}>;

	if (!interpreterResponse.isSuccess) {
		throw interpreterResponse.errorCode && interpreterResponse.errorCode === "characterLimitExceeds"
			? new Error("characterLimitExceeds")
			: new Error("sessionAlreadyValidated");
	}
};
