import {SessionLanguagesKey} from "../../utils/sessions/languages";
import {Corporation} from "../identities/corporation";
import {InterpreterPreview, WaveInterpreterProvider} from "../identities/interpreter";
import {PersonIdentityPreview, PersonPreview, PersonPreviewCommon} from "../identities/person";
import {MedicalProfessionalPreview, OtherProfessionalPreview} from "../identities/professional";
import {ReceiverPreview} from "../identities/receiver";
import {PaymentAuthority} from "../payment-authority";
import {SessionCommon} from "./session";
import {SessionCategory} from "./session-category";

export interface TravelPriceDetails {
	distance?: number;
	flatRate?: number;
	time?: number;
}

export interface Price {
	base: number;
	currency: "CHF" | "EUR";
	increased: number;
	reduced: number;
	travel: TravelPriceDetails;
}

export const providedServiceTypes = ["standard", "mediation", "education"] as const;
export type ProvidedServiceType = typeof providedServiceTypes[number];

interface ProvidedServiceCommon {
	type: ProvidedServiceType;
}

export interface Standard extends ProvidedServiceCommon {
	type: "standard";
}

export interface Education extends ProvidedServiceCommon {
	type: "education";
}

export interface Mediation extends ProvidedServiceCommon {
	expectation: string;
	goal: string;
	involvedCorporations: Corporation[];
	involvedPeople: PersonIdentityPreview[];
	reason: string;
	type: "mediation";
}

export type ProvidedService = Education | Mediation | Standard;

// outdated if someone else accepted the mandate before this person answered
const mandateStatus = ["accepted", "refused", "pending", "outdated"] as const;
export type MandateStatus = typeof mandateStatus[number];

export interface PersonPreviewProviderCommon extends PersonPreviewCommon {
	status?: MandateStatus;
}

export interface MandateCommon extends SessionCommon {
	category?: SessionCategory;
	language: SessionLanguagesKey;
	mandator: Corporation | PersonPreview;
	medicalProfessional?: MedicalProfessionalPreview;
	onlyRecipient?: boolean;
	otherProfessional?: OtherProfessionalPreview;
	paymentAuthority?: PaymentAuthority;
	price?: Price;
	providedService: ProvidedService;
	providerComment?: string;
	// List of all the people that received the mandate offer
	providers: PersonPreviewProviderCommon[];
	receiver?: ReceiverPreview;
	requester: PersonPreview;
	type: "interpreterMandate";
}

export interface InterpreterMandate extends MandateCommon {
	// All the people that could have received the offer but were excluded by the requester during session creation
	excludedInterpreters: InterpreterPreview[];
	mandator: Corporation;
	// All the people that received the mandate offer
	providers: WaveInterpreterProvider[];
	toLanguage: SessionLanguagesKey;
	type: "interpreterMandate";
}

type InterpreterMandatePreviewCommon = Pick<InterpreterMandate, "activityId" | "communication" | "end" | "language" | "start" | "toLanguage" | "type">;

export type InterpreterCalendarMandatePreview =
	InterpreterMandatePreviewCommon
	& Pick<InterpreterMandate, "status">
	& {paid?: boolean};

export type CalendarSyncSessionPreview =
	InterpreterMandatePreviewCommon
	& Pick<InterpreterMandate, "providerComment" | "providers" | "requester">;

export type RequesterCalendarMandatePreview =
	InterpreterMandatePreviewCommon
	& Pick<InterpreterMandate, "excludedInterpreters" | "mandator" | "providedService" | "providers" | "requester" | "status">;

export type InterpreterInboxMandatePreview =
	InterpreterMandatePreviewCommon
	& Pick<InterpreterMandate, "disabledActions" | "locked" | "onlyRecipient" | "status">;

export type InterpreterSalaryMandatePreview =
	InterpreterMandatePreviewCommon
	& Pick<InterpreterMandate, "cancelable" | "immediate" | "onlyRecipient" | "price" | "status">;
