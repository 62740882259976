import {BottomTabBarProps, createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {TabNavigationState} from "@react-navigation/native";
import * as React from "react";
import {View} from "react-native";
import {FooterMenu} from "../../../components/menus/footer";
import {MainMenu, menuIcons, menuLabels} from "../../../components/menus/main";
import {InboxContext} from "../../../contexts/inbox";
import {ResponsiveContext} from "../../../contexts/responsive";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {defaultSceneContainerStyle} from "../../../utils/navigation/defaults";
import {ClientHomeTabNavigatorParamList} from "../../../utils/navigation/paramLists/client-param-list";
import {InterpreterHomeTabNavigatorParamList} from "../../../utils/navigation/paramLists/interpreter-param-list";
import {Calendar} from "../../screens/common/modals/calendar";
import {Inbox} from "../../screens/interpreter/inbox";
import {InterpreterAccount} from "../../screens/interpreter/interpreter-account";

const notifs = (routeNotifications: {[routeName in "Inbox"]: number}) => (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList): number => {
	switch (routeName) {
		case "Inbox": {
			return routeNotifications.Inbox || 0;
		}
		default: {
			return 0;
		}
	}
};

const TabBar = ({state, ...props}: BottomTabBarProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const {mandates} = React.useContext(InboxContext);
	return (
		<FooterMenu
			{...props}
			state={state as TabNavigationState<InterpreterHomeTabNavigatorParamList>}
			icons={menuIcons}
			labels={menuLabels(ct, t)}
			notifications={notifs({Inbox: mandates.length})}
		/>
	);
};

const Tab = createBottomTabNavigator<InterpreterHomeTabNavigatorParamList>();
export const HomeTabNavigator = (): JSX.Element => {
	const {columns, displayMainMenu} = React.useContext(ResponsiveContext);
	const verticalMenu = columns > 1;
	return (
		<View style={{flex: 1, flexDirection: verticalMenu ? "row" : "column"}}>
			{displayMainMenu && <MainMenu vertical={verticalMenu}/>}
			<Tab.Navigator
				initialRouteName="Inbox"
				tabBar={(props: BottomTabBarProps) => displayMainMenu
					? null
					: <TabBar {...props} />}
				sceneContainerStyle={defaultSceneContainerStyle}
				screenOptions={{headerShown: false}}
				backBehavior="history"
			>
				<Tab.Screen name="Inbox" component={Inbox}/>
				<Tab.Screen name="Calendar" component={Calendar as never}/>
				<Tab.Screen name="InterpreterAccount" component={InterpreterAccount}/>
			</Tab.Navigator>
		</View>
	);
};
