import * as React from "react";
import {Falsy, StyleSheet, View} from "react-native";
import Collapsible from "react-native-collapsible";
import {ImagePropsWithoutDimensions} from "../../../@types/medias/image";
import {filterTruthy} from "../../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {PRIMARY, SUBTLE} from "../../../utils/styles/colors";
import {Button, Buttons} from "../../buttons/button";
import {Icon, IconKey} from "../../icons";
import {Image} from "../../images/image";
import {Label} from "../../labels/label";
import {Spacer} from "../../spacer";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";

export interface ListElementProps extends PressableProps {
	badge?: {color: string; value: string};
	buttons?: Buttons;
	children: React.ReactElement | string | undefined;
	icon?: IconKey;
	image?: ImagePropsWithoutDimensions;
	label?: string;
	labelPosition?: "bottom" | "top";
	separators?: React.ReactElement | null;
	subElements?: (Falsy | {
		buttons?: Buttons;
		children: React.ReactElement | string | undefined;
		icon?: IconKey;
		light?: boolean;
	})[];
}

const PICTURE_SIZE = 32;

interface ListLabelProps extends PressableProps {
	label: ListElementProps["label"];
}

const ListLabel = ({
	disabled,
	label,
	...touchableProps
}: ListLabelProps): JSX.Element => (
	<Text
		type={!disabled && touchableProps.onPress ? "touchable_label" : "label"}
		style={styles.label}
		color={(!disabled && touchableProps.onPress) ? PRIMARY : undefined}
	>
		{label}
	</Text>
);

export const ListElement = ({
	labelPosition = "top",
	children,
	badge,
	icon,
	label,
	image,
	buttons,
	disabled,
	subElements,
	...touchableProps
}: ListElementProps): JSX.Element => {
	const [subElementsCollapsed, setSubElementsCollapsed] = React.useState(true);
	const Wrapper = !disabled && touchableProps.onPress ? Pressable : View;
	const toggleCollapse = (): void => setSubElementsCollapsed(prev => !prev);
	return (
		<>
			<Wrapper {...touchableProps} {...touchableProps.onPress && {underlayColor: SUBTLE}}>
				<View>
					<View style={[CONTAINERS.MAIN, styles.container]}>
						{!!image && (<Image
							source={image.source}
							fullWidth={image.fullWidth}
							fullHeight={image.fullHeight}
							resizeMode={image.resizeMode}
							mime={image.mime}
							sourceType={image.sourceType}
							width={PICTURE_SIZE}
							height={PICTURE_SIZE}
							style={styles.imageContainer}
						/>)}
						{!!icon && (
							<View style={[{marginTop: label ? 4 : -4}, styles.icon]}>
								<Icon icon={icon}/>
							</View>
						)}
						<View style={styles.content}>
							{labelPosition === "top" && !!label && (
								<ListLabel
									disabled={disabled}
									label={label}
									{...touchableProps}
								/>
							)}
							{typeof children === "string"
								? (
									<Text type={!disabled && touchableProps.onPress ? "emphasis_1" : "default_1"}>
										{children}
									</Text>
								)
								: children}
							{labelPosition === "bottom" && !!label && (
								<ListLabel
									disabled={disabled}
									label={label}
									{...touchableProps}
								/>
							)}
						</View>
						<View style={styles.right}>
							{subElements && subElements.length > 0 && (
								<Button
									type="secondary"
									size="small"
									icon={subElementsCollapsed ? "moreVertical" : "close"}
									onPress={toggleCollapse}
									disabled={disabled}
								/>
							)}
							{/* b => !!b let us do some x && ... in the array just like in styles */}
							{buttons && filterTruthy(buttons).map((buttonProps, i) => (
								<React.Fragment key={buttonProps.key}>
									{i > 0 && <Spacer size={SMALL_SPACING} mode="vertical"/>}
									<Button type="secondary" size="small" {...buttonProps} disabled={disabled}/>
								</React.Fragment>
							))}
							{badge && <Label size="small" backgroundColor={badge.color} text={badge.value}/>}
						</View>
					</View>
					{subElements && subElements.length > 0 && (
						<Collapsible collapsed={subElementsCollapsed}>
							<View style={[CONTAINERS.MAIN, styles.container, styles.subContainer]}>
								{filterTruthy(subElements).filter(se => !!se.children).map((element) => (
									<View
										key={typeof element.children === "string" ? element.children : element.children?.key}
										style={styles.subElement}
									>
										{!!element.icon && (
											<Icon
												icon={element.icon}
												containerSize={20}
												size={20}
												containerStyle={styles.iconContainer}
											/>
										)}
										<View style={styles.content}>
											<Text type={element.light ? "default_2" : "emphasis_2"}>{element.children}</Text>
										</View>
										<View style={styles.right}>
											{element.buttons && filterTruthy(element.buttons).map((buttonProps, i) => (
												<React.Fragment key={buttonProps.key}>
													{i > 0 && <Spacer size={DEFAULT_SPACING} mode="vertical"/>}
													<Button size="xsmall" type="secondary" {...buttonProps} disabled={disabled}/>
												</React.Fragment>
											))}
										</View>
									</View>
								))}
							</View>
						</Collapsible>
					)}
				</View>
			</Wrapper>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		minHeight: 48,
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	content: {
		flex: 1,
	},
	icon: {
		marginBottom: -2,
		marginRight: SMALL_SPACING,
	},
	iconContainer: {
		marginRight: SMALL_SPACING,
	},
	imageContainer: {
		borderRadius: PICTURE_SIZE / 2,
		marginLeft: -2,
		marginRight: SMALL_SPACING - 2,
		marginTop: 2,
	},
	label: {
		marginTop: -2, // Label being clearer, the visual effect of center needs to be compensated
	},
	right: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "center",
		marginLeft: SMALL_SPACING,
	},
	subContainer: {
		flexDirection: "column",
		marginTop: -6,
		paddingLeft: DEFAULT_SPACING + 36,
		paddingRight: DEFAULT_SPACING + 2,
		paddingTop: 0,
	},
	subElement: {
		alignItems: "center",
		flexDirection: "row",
		marginTop: 6,
		width: "100%",
	},
});
