import * as React from "react";
import {ViewProps} from "react-native";
import {SessionStatus} from "../../@types/activities/session";
import {useTranslation} from "../../utils/hooks/use-translation";
import {getStatusIcon, getStatusColor} from "../../utils/sessions/status";
import {WHITE, PRIMARY_2, SUBTLE_4} from "../../utils/styles/colors";
import {Label} from "./label";

interface Props extends ViewProps {
	defaultColors?: boolean;
	size?: "medium" | "small";
	status: SessionStatus;
}

export const StatusLabel = ({status, size, defaultColors, ...p}: Props): JSX.Element => {
	const {t} = useTranslation();
	const color = getStatusColor(status);
	return (
		<Label
			backgroundColor={WHITE}
			icon={size === "medium" ? getStatusIcon(status) : undefined}
			text={t(`activities:sessions.status.${status}`)}
			color={defaultColors ? PRIMARY_2 : color}
			size={size}
			borderColor={defaultColors ? SUBTLE_4 : color}
			{...p}
		/>
	);
};
