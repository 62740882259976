import {Receiver} from "../../../../@types/identities/receiver";
import {IdentityEditableFieldsSetting} from "../../../../@types/settings";
import {
	combinedCustomLabel,
	corporationIdentityId,
	descriptionCustomLabel,
	evamCustomLabel,
	ippCustomLabel,
} from "../identities/shared-data";

export const requesterReceiverEditableFields: IdentityEditableFieldsSetting<Receiver> = {
	context: "requester/receiver",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterReceiverEditableFields",
	value: {
		ageGroup: [true, true, null],
		combinedNumber: [true, false, null, combinedCustomLabel],
		dateOfBirth: [true, false, null, "dob"],
		description: [true, false, null, descriptionCustomLabel],
		email: [true, false, null, "Mail"],
		evamNumber: [true, true, null, evamCustomLabel],
		fullName: [true, true, {
			firstName: [true, false, null, "Prénom"],
			lastName: [true, true, null],
		}],
		gender: [true, false, null],
		insurance: [true, false, null],
		ippNumber: [true, false, null, ippCustomLabel],
		origin: [true, false, ["GB", "CH", "FR", "AR"]],
		phone: [true, false, null, "Tél"],
		type: [true, false, ["receiver"]],
	},
};
