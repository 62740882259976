import * as React from "react";
import {StyleSheet, View, NativeSyntheticEvent, NativeScrollEvent} from "react-native";
import {UnavailabilityPreview} from "../../../@types/activities/unavailability";
import {CONTAINERS, SMALL_SPACING} from "../../../utils/constants";
import {formatShortTimeRange} from "../../../utils/date-time/format";
import {Debouncer} from "../../../utils/debouncer";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {PRIMARY_2, SUBTLE_4, BACKGROUND_COLOR, CONTRAST} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Label} from "../../labels/label";
import {ScrollView} from "../../scrollables/scroll-view";
import {Spacer} from "../../spacer";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";
import {SplashView} from "../../views/splash-view";

export const getEventElementHeight = (): number => {
	const height = 85;
	return height;
};

interface Props extends PressableProps {
	event: UnavailabilityPreview;
	fetchFailed?: boolean;
	selected?: boolean;
	showIndicator?: boolean;
}

export const UnavailabilityElementPlaceHolder = ({fetchFailed, style, showIndicator}: Pick<Props, "fetchFailed" | "showIndicator" | "style">): JSX.Element => (
	<View style={[CONTAINERS.MAIN, styles.wrapper, {height: getEventElementHeight()}, style]}>
		<View style={styles.container}>
			<SplashView
				centered
				loading={showIndicator}
				message={fetchFailed ? {translationKey: "feedbacks:getDataFailed", type: "error"} : undefined}
			/>
		</View>
	</View>
);

export const UnavailabilityElement = ({style, event, selected, ...touchableProps}: Props): JSX.Element => {
	const {start, end, subject, place} = event;
	const {t} = useTranslation();
	const scrollView = React.useRef<ScrollView>(null);
	const scrollDebouncer = React.useRef(new Debouncer());
	const onScroll = (_: NativeSyntheticEvent<NativeScrollEvent>): void => {
		scrollDebouncer.current.debounce(
			() => scrollView.current?.scrollTo({animated: true, x: 0, y: 0}),
			2000,
		);
	};
	return (
		<View
			style={[
				CONTAINERS.MAIN,
				styles.wrapper,
				{height: getEventElementHeight()}, style,
			]}
		>
			<View style={[styles.container, selected && styles.selectedContainer]}>
				<ScrollView
					horizontal
					onScroll={onScroll}
					ref={scrollView}
					showsHorizontalScrollIndicator={false}
					style={styles.scroll}
					contentContainerStyle={styles.scrollContainer}
				>
					<Pressable
						disabled={!touchableProps.onPress}
						style={styles.pressableContainer}
						{...touchableProps}
					>
						<>
							<View style={styles.dateTextLine1}>
								<Text type="button_1" allowFontScaling={false}>
									{formatShortTimeRange(start, end)}
								</Text>
							</View>
							<View style={styles.labels}>
								<Label
									icon="calendar"
									backgroundColor={PRIMARY_2}
									size="small"
									allowFontScaling={false}
								/>
								<Spacer mode="vertical" size={SMALL_SPACING / 2} />
								<Label
									text={t("forms:inputs.unavailability")}
									borderColor={SUBTLE_4}
									backgroundColor={BACKGROUND_COLOR}
									color={PRIMARY_2}
									size="small"
									allowFontScaling={false}
								/>
							</View>
							<Text numberOfLines={1} type="label" color={PRIMARY_2} allowFontScaling={false}>
								{subject || place?.address}
							</Text>
						</>
					</Pressable>
				</ScrollView>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: SMALL_SPACING,
		height: "100%",
		justifyContent: "center",
		padding: SMALL_SPACING,
		...ELEVATIONS[4],
	},
	dateTextLine1: {
		alignItems: "center",
		flexDirection: "row",
		marginBottom: 4,
	},
	labels: {
		flexDirection: "row",
		marginBottom: 4,
	},
	pressableContainer: {
		minWidth: "100%",
	},
	scroll: {
		flex: 1,
	},
	scrollContainer: {
		flexGrow: 1,
	},
	selectedContainer: {
		backgroundColor: CONTRAST,
	},
	wrapper: {
		paddingHorizontal: SMALL_SPACING,
	},
});
