import * as React from "react";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {shareSessionByEmails, shareVideoSessionByEmails} from "../../../../requests/common/activities/share-session";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {forceBack} from "../../../navigation";

const ShareSession = ({route, navigation}: SharedScreenProps<"ShareSessionModal">): JSX.Element => {
	const {shareType, sessionId} = route.params;
	const {ct, t} = useTranslation();
	const [emails, setEmails] = React.useState<string[]>([]);
	return (
		<>
			<HeaderMenu center={t(`screens:shareSession.${shareType === "video" ? "titleVideo" : "title"}`)}/>
			<Form
				validation={{
					buttonProps: {
						icon: "check",
						text: ct("common:send"),
					},
					onValidation: () => {
						(shareType === "video" ? shareVideoSessionByEmails : shareSessionByEmails)(sessionId, emails)
							.then(() => {
								Log.success("messageSentSuccess")();
								navigation.dispatch(forceBack);
							})
							.catch(Log.error("messageSentFailed"));
					},
				}}
				inputs={[
					{
						disabled: true,
						icon: "id",
						key: "sessionId",
						label: t("activities:sessions.validate.sessionId"),
						type: {
							key: "text",
							value: `#${sessionId}`,
						},
					},
					{
						icon: "mail",
						key: "emails",
						label: ct("common:email_plural"),
						requiredLabel: true,
						resetable: false,
						rules: [{rule: Rules.notEmpty, type: "error"}],
						type: {
							getItems: {
								// Used only when resetting
								onChangeValue: () => setEmails([]),
								screen: "AddEmailModal",
							},
							getLabelText: (item: string) => item,
							key: "multiSelect",
							keyExtractor: (item: string) => item,
							onAddItem: {
								request: (item: string) => {
									setEmails(prev => prev.includes(item) ? prev : [...prev, item]);
									return Promise.resolve();
								},
								screen: {
									name: "AddEmailModal",
								},
							},
							onDeleteItem: (item: string) => {
								setEmails(prev => prev.filter(el => el !== item));
								return Promise.resolve();
							},
							value: emails,
						},
					},
				]}
			/>
		</>
	);
};

export const ShareSessionModal = (props: SharedScreenProps<"ShareSessionModal">): JSX.Element => (
	<ModalWrapper>
		<ShareSession {...props} />
	</ModalWrapper>
);
