import {CommunicationType} from "../@types/activities/session";
import {InterpreterPreview} from "../@types/identities/interpreter";
import {AvailableSessionLanguagesCommunication} from "../@types/language-translation";
import {Locales, localeToSessionLanguage} from "./locales/locales";
import {getKeys} from "./objects";
import {SessionLanguagesKey} from "./sessions/languages";

export const generateAvailableLanguagesCommunication = (interpreter: Pick<InterpreterPreview, "communicationTypes" | "languages">): AvailableSessionLanguagesCommunication => {
	const availableLanguagesCommunications: Partial<AvailableSessionLanguagesCommunication> = {};

	interpreter.languages
		.forEach(l => {
			if (!availableLanguagesCommunications[l.from]) {
				availableLanguagesCommunications[l.from] = new Map<SessionLanguagesKey, Set<CommunicationType>>();
			}
			availableLanguagesCommunications[l.from]?.set(l.to, new Set(interpreter.communicationTypes));
		});
	return availableLanguagesCommunications as AvailableSessionLanguagesCommunication;
};

export const getAllAvailableLanguages = (availableLanguagesCommunications: AvailableSessionLanguagesCommunication): Set<SessionLanguagesKey> => {
	const languages = new Set<SessionLanguagesKey>();
	if (availableLanguagesCommunications) {
		getKeys(availableLanguagesCommunications).forEach(from => {
			languages.add(from);
			[...availableLanguagesCommunications[from].keys()].forEach(
				(language: SessionLanguagesKey) => languages.add(language));
		});
	}
	return languages;
};

export const getAvailableToLanguages = (
	availableLanguagesCommunications: AvailableSessionLanguagesCommunication,
	from: SessionLanguagesKey = localeToSessionLanguage(Locales.currentLocale),
): Set<SessionLanguagesKey> => {
	const availableToLanguages = new Set<SessionLanguagesKey>();
	getKeys(availableLanguagesCommunications).forEach((mainLanguage: SessionLanguagesKey) => {
		if (from === mainLanguage) {
			[...availableLanguagesCommunications[from].keys()].forEach(
				(language: SessionLanguagesKey) => availableToLanguages.add(language));
		} else if (availableLanguagesCommunications[mainLanguage].has(from)) {
			availableToLanguages.add(mainLanguage);
		}
	});
	return availableToLanguages;
};

export const getAvailableFromLanguages = (
	availableLanguagesCommunications: AvailableSessionLanguagesCommunication,
	ignoreCurrentFrom = true,
): Set<SessionLanguagesKey> => {
	const from = localeToSessionLanguage(Locales.currentLocale);
	const languages = new Set<SessionLanguagesKey>();
	if (availableLanguagesCommunications) {
		(ignoreCurrentFrom
			? getKeys(availableLanguagesCommunications).filter(l => l !== from)
			: getKeys(availableLanguagesCommunications)
		).forEach((l: SessionLanguagesKey) => languages.add(l));
	}
	return languages;
};
