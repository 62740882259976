import {feedbacks as enUSFeedbacks} from "../en-US/feedbacks";

export const feedbacks: typeof enUSFeedbacks = {
	APICallFailed: "Errore durante il recupero dei dati",
	acceptSessionFailed: "Errore durante l'accettazione della sessione",
	acceptSessionSuccess: "Sessione accettata",
	calendarAccessNotAuthorized: "L'accesso al calendario locale non è consentito. Consenti l'accesso al calendario locale nelle impostazioni del tuo dispositivo",
	calendarSynchronizationFailed: "Errore durante la sincronizzazione con il calendario locale",
	calendarSynchronizationSuccess: "I tuoi eventi per i prossimi 90 giorni sono stati sincronizzati con successo con il tuo calendario locale",
	cancelSessionFailed: "Errore durante la cancellazione della sessione",
	cancelSessionSuccess: "La sessione è stata cancellata",
	changePasswordSuccess: "La tua password è stata cambiata",
	connectionLost: "Connessione alla rete persa",
	connectionRestored: "Connessione di rete ripristinata",
	copyAddressSuccess: "L'indirizzo di destinazione è stato copiato negli appunti",
	copyProfileSuccess: "Il profilo è stato copiato negli appunti",
	copySupportNumberSuccess: "Il numero di supporto è stato copiato negli appunti.",
	createDataFailed: "Errore durante la creazione dell'elemento",
	createDataSuccess: "L'elemento è stato creato con successo",
	createFollowUpFailed: "Errore durante la creazione di un seguito",
	createSessionFailed: "Errore durante la creazione della sessione",
	createUnavailabilityFailed: "Errore durante la creazione di una indisponibilità",
	createUnavailabilitySuccess: "Indisponibilità creata",
	deleteDataFailed: "Errore durante la cancellazione dell'elemento",
	deleteDataSuccess: "L'elemento è stato cancellato",
	deleteUnavailabilityFailed: "Errore durante la cancellazione di una indisponibilità",
	deleteUnavailabilitySuccess: "Indisponibilità cancellata",
	dependantDataError: "I dati che dipendono da questa sessione sono stati modificati/rimossi",
	emailSuccess: "Email inviata con successo",
	geocodeFailed: "Errore durante il recupero della mappa",
	getCalendarDataFailed: "Errore durante il recupero dei dati del calendario",
	getDataFailed: "Errore durante il recupero dei dati",
	getFollowUpParamsFailed: "Errore durante il recupero dei dati di rintracciamento",
	getInterpreterAddressesFailed: "Errore durante il recupero degli indirizzi dell'interprete",
	getInterpreterFailed: "Errore durante l'ottenimento di dati interpretati",
	getInterpreterProfileEditRights: "Errore durante l'ottenimento dei diritti di modifica dei parametri dell'interprete",
	getInterpreterSalaryFailed: "Errore durante l'ottenimento delle informazioni sul salario dell'interprete",
	getInterpreterSettingsFailed: "Errore durante l'ottenimento dei parametri dell'interprete",
	getMinimumDurationFailed: "Errore nell'ottenere la durata minima di una sessione di monitoraggio",
	getPlaceSuggestions: "Errore durante il recupero dei suggerimenti di indirizzo",
	getSessionDetailsFailed: "Errore durante il recupero dei dettagli della sessione",
	getSupportInfosFailed: "Errore durante il recupero delle informazioni di supporto",
	getUnavailabilityFailed: "Errore durante l'ottenimento delle indisponibilità",
	getUserAddressesFailed: "Errore durante il recupero degli indirizzi utente",
	getUserFailed: "Errore durante l'ottenimento dei dati utente",
	getUserSettingsFailed: "Errore durante il recupero delle impostazioni dell'utente",
	hourLimitExceeded: "La tua organizzazione ha superato il limite di durata della prenotazione",
	imagePickerFailed: "Errore durante la selezione dell'immagine",
	loginFailed: "Combinazione errata di login o password",
	logoutFailed: "Non riesco a disconnettermi",
	messageSentFailed: "Il messaggio non può essere inviato",
	messageSentSuccess: "Messaggio inviato",
	notificationsPermissionsDisabled: "Non abbiamo potuto abilitare le notifiche push, dobbiamo abilitarle nelle opzioni del dispositivo",
	notificationsPermissionsEnabled: "Notifiche push abilitate",
	refuseSessionFailed: "Errore durante la negazione della sessione",
	refuseSessionSuccess: "La sessione è stata rifiutata",
	sendDataFailed: "Errore durante l'invio dei dati",
	serverDownError: "È in corso un aggiornamento del server. Si prega di riprovare più tardi",
	sessionAlreadyAttributed: "Questa sessione è già stata attribuita a qualcun altro",
	sessionAlreadyValidated: "Il tuo amministratore ha già convalidato questa sessione",
	sessionCreationSuccess: "La sessione è stata creata con successo",
	sessionStatusChangedConfirmed: "La sessione #{{id}} è stata accettata da {{interpreter}}.",
	sessionStatusChangedRefused: "La sessione #{id}} è stata rifiutata da tutti gli interpreti.",
	unavailableTimeslot: "Non sei disponibile in questa fascia oraria",
	updateDataFailed: "Errore durante l'aggiornamento dell'articolo",
	updateDataSuccess: "L'elemento è stato aggiornato con successo",
	updateInterpreterFailed: "Errore durante l'aggiornamento dei dati dell'interprete",
	updateInterpreterPictureFailed: "Errore durante l'aggiornamento dell'immagine del profilo",
	updateInterpreterProfileSuccess: "Il tuo profilo è stato aggiornato con successo",
	updateSessionValidationFailed: "Errore durante la convalida della sessione",
	updateSessionValidationSuccess: "La sessione è stata convalidata",
	updateSettingsFailed: "Errore durante l'aggiornamento delle preferenze",
	updateSettingsSuccess: "Preferenze aggiornate",
	updateUnavailabilityFailed: "Errore durante l'aggiornamento delle indisponibilità",
	updateUnavailabilitySuccess: "Indisponibilità aggiornata",
	updateUserAddressFailed: "Errore durante l'aggiornamento degli indirizzi registrati",
	updateUserAddressSuccess: "I vostri indirizzi registrati sono stati aggiornati",
	uploadSessionValidationPictureFailed: "Errore durante il caricamento dell'immagine di convalida",
	videoCallCameraUsed: "Un'altra applicazione sta usando la tua webcam. Per motivi di privacy, devi prima chiuderla e ricaricare questa pagina.",
	videoCallExpired: "Questa videochiamata è scaduta",
	videoCallInitFailed: "Errore durante la preparazione della videochiamata",
	videoCallJoinFailed: "Errore durante la connessione alla videochiamata",
	videoCallNoCamera: "Nessuna webcam rilevata. Ha fornito il permesso?",
	videoCallNoMicro: "Nessun microfono rilevato. Ha fornito il permesso?",
};
