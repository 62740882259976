import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {Falsy, StyleSheet} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {StackParamList} from "../../../utils/navigation/paramLists/root-param-list";
import {PLACEHOLDER, PRIMARY_2, SUBTLE_2, SUBTLE_4} from "../../../utils/styles/colors";
import {Item, OnlyKeysExtendingSelectListType} from "../../list/list-props.common";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";

export interface ScreenListProps<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> {
	key: "screen";
	onChangeValue?: (value: any) => void;

	params?: StackParamList[RouteName];
	props?: Omit<PressableProps, "onPress">;
	renderValue?: (value: any) => Falsy | JSX.Element | string;
	screen: RouteName;
	value?: any;
}

export const ScreenInput = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
>({
	screen,
	renderValue,
	onChangeValue,
	value,
	params,
	props,
	disabled,
	hasLabel,
	hasIcon,
}: Omit<ScreenListProps<IdKey, I, RouteName>, "key"> & {
	disabled?: boolean;
	hasIcon?: boolean;
	hasLabel?: boolean;
}): JSX.Element => {
	const {ct} = useTranslation();
	const navigation = useNavigation();

	const onPress = React.useCallback(
		// @ts-expect-error FIXME: Typescript error to FIX
		() => navigation.navigate(screen, {...params, onSelect: onChangeValue}),
		[navigation, onChangeValue, params, screen],
	);
	return (
		<Pressable
			hitSlop={{
				bottom: SMALL_SPACING,
				left: DEFAULT_SPACING + (hasIcon ? (24 + DEFAULT_SPACING) : 0),
				right: DEFAULT_SPACING,
				top: SMALL_SPACING + (hasLabel ? 10 : 0),
			}}
			{...props}
			style={[
				styles.renderValueWrapper,
				!disabled && styles.renderScreenValueWrapper,
				props?.style,
			]}
			disabled={disabled}
			onPress={onPress}
		>
			{value
				? renderValue
					? typeof renderValue(value) === "string"
						? (
							<Text type="emphasis_1" color={disabled ? SUBTLE_4 : PRIMARY_2} selectable={false}>
								{renderValue(value)}
							</Text>
						)
						: renderValue(value)
					: <Text type="emphasis_1" color={disabled ? SUBTLE_4 : PRIMARY_2} selectable={false}>{value}</Text>
				: <Text color={PLACEHOLDER} type="emphasis_1" selectable={false}>{`${ct("common:select")}...`}</Text>}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	renderScreenValueWrapper: {
		alignSelf: "flex-start",
		backgroundColor: SUBTLE_2,
		borderRadius: SMALL_SPACING,
		marginHorizontal: -6,
		marginTop: 3,
		paddingHorizontal: SMALL_SPACING,
		paddingVertical: 4,
	},
	renderValueWrapper: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});
