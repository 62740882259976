import * as Clipboard from "expo-clipboard";
import React from "react";
import {StyleSheet, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {Apps} from "../../../../components/banner/apps";
import {Banner} from "../../../../components/banner/banner";
import {Button} from "../../../../components/buttons/button";
import {Icon} from "../../../../components/icons";
import {Form, FormInput, FormProps} from "../../../../components/inputs/form";
import {Spacer} from "../../../../components/spacer";
import {StatusBar} from "../../../../components/status-bar";
import {Text} from "../../../../components/texts/text";
import {KeyboardAvoidingView} from "../../../../components/views/keyboard-avoiding-view";
import {LocaleContext} from "../../../../contexts/locale";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {
	ADMIN_APP_URL,
	APP,
	CLIENT_APP_URL,
	CONTAINERS,
	DEFAULT_SPACING,
	ENV,
	HTTP_PROTOCOL,
	INTERPRETER_APP_URL,
	IS_CLIENT,
	IS_INTERPRETER,
	IS_MOBILE,
	IS_WEB,
	REQUEST_ACCESS_URL,
	SMALL_SPACING,
} from "../../../../utils/constants";
import {AsyncStorage} from "../../../../utils/externals/storages/async-storage";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales, SupportedLocaleKey, supportedLocales} from "../../../../utils/locales/locales";
import {Log} from "../../../../utils/logs/logs";
import {linkToURL} from "../../../../utils/network/links";
import {DeviceTokenHolder} from "../../../../utils/notifications/manager.common";
import {getKeys} from "../../../../utils/objects";
import {
	BACKGROUND_COLOR,
	BLUE,
	CLIENT_BG,
	INTERPRETER_BG,
	PRIMARY_2,
	SUBTLE_2,
	YELLOW,
} from "../../../../utils/styles/colors";

interface AuthTemplateProps {
	inputs: FormInput[];
	label: string;
	subAction?: {
		onPress: () => void;
		text: string;
	};
	title: string;
	validation: {
		disableOnValidationFailed?: boolean;
		onValidation: () => void;
		text: string;
	};
}

const copyDeviceToken = (): void => {
	if (IS_MOBILE && (ENV === "development" || ENV === "staging")) {
		Clipboard.setStringAsync(DeviceTokenHolder.token).catch(Log.error());
	}
};

const handlePressRedirect = (redirectUrl: string | undefined, locale?: SupportedLocaleKey, newTab = false) => () =>
	!redirectUrl || !HTTP_PROTOCOL
		? Log.error()("Invalid protocol/redirect url", {HTTP_PROTOCOL, redirectUrl})
		: linkToURL(`${HTTP_PROTOCOL}://${locale ? `${Locales.localeToLanguage(locale)}.` : ""}${redirectUrl}`, !newTab);

export const AuthTemplate = ({
	label,
	inputs,
	subAction,
	title,
	validation,
}: AuthTemplateProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const {locale, setLocale: setLocaleContext} = React.useContext(LocaleContext);
	const {reset: resetCreation} = React.useContext(SessionCreationContext);

	const [neverLoggedIn, setNeverLoggedIn] = React.useState<boolean>();

	const setLocale = React.useCallback(
		(value: SupportedLocaleKey) => () => setLocaleContext(value)
			// Because of our context tree structure, the session creation context is always mounted for the client, so we need to reset it
			.then(() => IS_CLIENT && resetCreation({type: "new"})).catch(Log.error()),
		[resetCreation, setLocaleContext],
	);

	React.useEffect(
		() => {
			AsyncStorage.getItem("LastLogin")
				.then(lastLogin => setNeverLoggedIn(!lastLogin))
				.catch(Log.error());
		},
		[],
	);

	const header = React.useMemo(
		() => (
			<>
				{IS_WEB && (
					<Banner
						backgroundColor={IS_INTERPRETER ? INTERPRETER_BG : CLIENT_BG}
						open={!!neverLoggedIn}
						style={styles.bannerContainer}
					>
						<View>
							<Text centered type="button_1">{t("screens:signIn.requestAccess.title")}</Text>
							<Spacer size={SMALL_SPACING} mode="horizontal" />
							<Button
								text={t("screens:signIn.requestAccess.button")}
								size="small"
								backgroundColor={PRIMARY_2}
								icon="carretRight"
								iconPosition="after"
								onPress={handlePressRedirect(REQUEST_ACCESS_URL, undefined, true)}
							>
							</Button>
						</View>
					</Banner>
				)}

				<View style={styles.topSection}>
					<View style={styles.welcome}>
						<Icon icon="appIcon" size={54} color={APP === "interpreter" ? BLUE : YELLOW} onPress={copyDeviceToken}/>
						<Text type="title" style={styles.title} size={30}>{ct("common:hello")}</Text>
						<Text type="label">{label}</Text>
					</View>
				</View>
			</>
		),
		[ct, label, neverLoggedIn, t],
	);

	const validationProps: FormProps["validation"] = React.useMemo(
		() => ({
			buttonProps: {icon: "carretRight", iconPosition: "after", text: validation.text},
			disableOnValidationFailed: validation.disableOnValidationFailed,
			onValidation: validation.onValidation,
		}),
		[validation.disableOnValidationFailed, validation.onValidation, validation.text],
	);

	const inputsProps = React.useMemo(
		() => ({
			bottomLine: false,
			style: styles.inputs,
			topLine: false,
		}),
		[],
	);

	const footer = React.useMemo(
		() => (
			<View style={[CONTAINERS.MAIN, styles.footer]}>
				{IS_WEB && (
					<>
						<View>
							<View style={styles.redirectButtonSection}>
								<Button
									size="medium"
									type="secondary"
									fullWidth
									text={IS_INTERPRETER
										? `${t("screens:signIn.login")} ${ct("common:client")}`
										: `${t("screens:signIn.iam")} ${ct("common:interpreter")}`}
									icon={IS_INTERPRETER ? "institution" : "person"}
									onPress={handlePressRedirect(IS_INTERPRETER ? CLIENT_APP_URL : INTERPRETER_APP_URL)}
									style={styles.redirectButton}
									innerStyle={styles.redirectButtonInner}
									textProps={{size: 12, style: {textAlign: "left"}}}
								/>
								<Spacer size={SMALL_SPACING} mode={"vertical"}/>
								<Button
									size="medium"
									type="secondary"
									fullWidth
									text={ct("common:administrator")}
									icon="settings"
									onPress={handlePressRedirect(ADMIN_APP_URL)}
									style={styles.redirectButton}
									innerStyle={styles.redirectButtonInner}
									textProps={{size: 12}}
								/>
							</View>
						</View>

						<View style={styles.storeWrapper}>
							<Apps/>
						</View>
					</>
				)}
				{subAction && (
					<Text onPress={subAction.onPress} centered style={styles.subAction}>{subAction.text}</Text>
				)}
				<View style={styles.languages}>
					{getKeys(supportedLocales).map((element: SupportedLocaleKey, i) => (
						<Button
							key={element}
							onPress={setLocale(element)}
							size="small"
							text={Locales.localeToLanguage(element).toUpperCase()}
							type="secondary"
							active={element === locale}
							style={i !== 0 && {paddingLeft: SMALL_SPACING}}
							innerStyle={styles.language}
						/>
					))}
				</View>

			</View>
		),
		[ct, locale, setLocale, subAction, t],
	);

	return (
		<SafeAreaView style={styles.wrapper} edges={["left", "bottom", "right"]}>
			<KeyboardAvoidingView style={styles.wrapper}>
				<StatusBar backgroundColor={BACKGROUND_COLOR}/>
				<Form
					hideReset
					contentContainerStyle={styles.container}
					style={styles.container}
					contentStyle={[CONTAINERS.MAIN, styles.middleHalf]}
					headerComponent={header}
					footerComponent={footer}
					title={title}
					validation={validationProps}
					inputs={inputs}
					inputsProps={inputsProps}
				/>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	bannerContainer: {
		paddingHorizontal: 0,
		paddingVertical: DEFAULT_SPACING,
	},
	container: {
		flexGrow: 1,
		paddingTop: 0,
	},
	footer: {
		flexGrow: 1,
		justifyContent: "center",
		marginTop: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
	},
	inputs: {
		borderColor: SUBTLE_2,
		borderRadius: DEFAULT_SPACING,
		borderWidth: 1,
		marginHorizontal: DEFAULT_SPACING,
		overflow: "hidden",
	},
	language: {
		width: 52,
	},
	languages: {
		alignSelf: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: "auto",
	},
	middleHalf: {
		justifyContent: "center",
	},
	redirectButton: {
		flex: 1,
	},
	redirectButtonInner: {
		paddingLeft: 18,
	},
	redirectButtonSection: {
		flexDirection: "row",
	},
	storeWrapper: {
		alignItems: "center",
		flex: 1,
		justifyContent: "center",
		marginVertical: DEFAULT_SPACING,
	},
	subAction: {
		alignSelf: "center",
		marginTop: DEFAULT_SPACING,
	},
	title: {
		marginTop: DEFAULT_SPACING,
	},
	topSection: {
		alignItems: "center",
		flexGrow: 1,
		justifyContent: "center",
		marginVertical: DEFAULT_SPACING,
	},
	welcome: {
		alignItems: "center",
		flexGrow: 1,
		justifyContent: "center",
		marginVertical: DEFAULT_SPACING,
	},
	wrapper: {
		flex: 1,
	},
});
