import React from "react";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
	callback: () => void,
	interval: number,
	active = true,
): void => {
	const savedCallback = React.useRef(callback);
	// Remember the latest callback.
	React.useEffect(
		() => { savedCallback.current = callback; },
		[callback],
	);
	// Set up the interval.
	React.useEffect(
		() => {
			// eslint-disable-next-line unicorn/consistent-function-scoping
			const tick = (): void => savedCallback.current();
			if (active) {
				const id = setInterval(tick, interval);
				return () => clearInterval(id);
			}
			return () => null;
		},
		[active, interval],
	);
};
