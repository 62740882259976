import {RequesterCalendarMandatePreview} from "../../@types/activities/mandate";
import {getSessionStatusChangedData} from "../../requests/clients/activities/activities";
import {Log} from "../logs/logs";
import {useWebSocket} from "./use-websocket";

interface Props {
	connect?: boolean;
	onData: (data: RequesterCalendarMandatePreview) => void;
}

export const useSessionStatusChangedWS = ({onData, connect = true}: Props): void => {
	useWebSocket({
		connect,
		handlers: [{
			dataGetter: getSessionStatusChangedData,
			onData,
			updateOnAppFocus: false,
		}],
		onWSError: Log.error(),
	});
};
