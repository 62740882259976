export const entities = {
	billingAddress: {
		address: "Address",
		department: "Department",
		name: "Name/Company",
		postOfficeBox: "P.O. Box",
		postalCode: "Postal code",
		town: "Town",
	},
};
