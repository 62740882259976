import {users as enUSUsers} from "../en-US/users";

export const users: typeof enUSUsers = {
	interpreter: {
		accepts: "{{firstName}} accetta:",
		availabilities: {
			available: "Disponibile",
			inEmergenciesDisabled: "Non disponibile per emergenze e immediato",
			inNights: "Non disponibile di notte",
			inPrivateEvent: "Occupato in un evento privato",
			inSession: "Occupato in una sessione",
			inWeekend: "Non disponibile durante il fine settimana",
			occupied: "Occupato",
			unavailable: "Non disponibile fino a domani",
		},
		copyAddress: "Copia",
		new: "Nuova sessione",
		qualifications: {
			certificate: "Certificato INTERPRET",
			exam: "Esame interprete",
			federalCertificate: "Attestato federale",
			module1: "Modulo 1 INTERPRET",
			module2: "Modulo 2 INTERPRET",
			module3: "Modulo 3 INTERPRET",
			module4: "Modulo 4 INTERPRET",
			module4a: "Modulo 4a INTERPRET",
			module5: "Modulo 5 INTERPRET",
			module6: "Modulo 6 INTERPRET",
			module7: "Modulo 7 INTERPRET",
			module8: "Modulo 8 INTERPRET",
			module9: "Modulo 9 INTERPRET",
			module10: "Modulo 10 INTERPRET",
			qualificationA: "Qualification A",
			qualificationB: "Qualification B",
			qualificationC: "Qualification C",
		},
		ticket: "Biglietti CFF",
	},
	menu: {
		calendar: "Calendario",
	},
	person: {
		dateOfBirth: "Data di nascita",
		unknownPerson: "Persona sconosciuta",
	},
	receiver: {
		ageGroup: {
			adult: "Adulto",
			child: "Bambino o adolescente",
			label: "Gruppo di età",
		},
		insurance: {
			asylblg: "AsylbLG",
			gkv: "GKV",
			label: "Assicurazione",
			none: "Nessuna",
			other: "Altro",
			unknown: "Sconosciuto",
		},
	},
	requester: {
		cancel: "Annullare",
	},
	settings: {
		availabilityScheduled: "Disponibilità programmata",
		availableImmediate: "Disponibile per sessioni immediate",
		availableNights: "Disponibile di notte ({{nightStart}} - {{nightEnd}})",
		availableWeekend: "Disponibilità nel fine settimana ({{WEStart}} - {{WEEnd}})",
		exportAgreement: "Gli importi e le liquidazioni sono indicativi e sono soggetti ad aggiustamenti applicati al momento della preparazione dello stipendio",
		exportCSV: "Esportazione CSV",
		exportPDF: "Esportazione PDF",
		feedback: "Feedback",
		notifications: {
			SMS: "Notifiche via SMS",
			SMSShort: "SMS",
			eMail: "Notifiche via e-mail",
			eMailShort: "E-mail",
			push: "Notifiche push",
			pushShort: "Push",
		},
		orderCards: "Schede di convalida dell'ordine",
		personalisedData: "Dati personalizzati",
		privacy: "Privacy",
		profile: "Profilo",
		savedAddresses: "Indirizzi salvati",
		savedSubjects: "Soggetti salvati",
		sessionCourse: "Sessione del corso",
		sessionInPerson: "Sessioni in persona",
		sessionMediation: "Sessioni di mediazione",
		sessionModels: "Modelli di sessione",
		sessionPhone: "Sessioni audio",
		sessionVideo: "Sessioni video",
		unavailableImmediate: "Non disponibile per sessioni immediate",
		unavailableNights: "Non disponibile durante la notte ({{nightStart}} - {{nightEnd}})",
		unavailableWeekend: "Non disponibile nei fine settimana ({{WEStart}} - {{WEEnd}})",
		update: "Salvare le impostazioni",
	},
};
