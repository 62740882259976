import {Session} from "../../@types/activities/session";
import {IdentityEditableFields} from "../../@types/identities/identity";
import {AgeGroup, Receiver} from "../../@types/identities/receiver";
import {createDate} from "../../utils/date-time/helpers";
import {ps, s} from "../../utils/switch";
import {PatientInsurance, ReceiverListedFields} from "../@types/person";
import {ReceiverFormData} from "../@types/session";
import {UserAccountReceiver} from "../@types/user";
import {transformPrefixToGender} from "./transform-common";

export const transformPatientToReceiverPreview = ({
	id, firstName, lastName, email, telephone, prefix, evamNumber, ippNumber, patientGroup, patientInsurance, patientOtherInsurance, category, country,
}: UserAccountReceiver): Session["receiver"] => {
	const insurance: PatientInsurance = patientInsurance?.value
		? {
			name: transformInsuranceBackToFront(patientInsurance.value),
			details: patientOtherInsurance?.value,
		}
		: undefined;
	const ageGroup: AgeGroup = patientGroup?.value === "Adults" ? "adult" : patientGroup?.value === "Children" ? "child" : undefined;
	return {
		identityId: id.toString(),
		accountId: id.toString(),
		fullName: firstName || lastName
			? {
				firstName,
				lastName,
			}
			: undefined,
		email,
		phone: telephone,
		gender: transformPrefixToGender(prefix),
		type: "receiver",
		evamNumber,
		ippNumber,
		insurance,
		ageGroup,
		description: category?.value,
		origin: country?.iso,
	};
};
export const transformPatientToReceiver = (patient: UserAccountReceiver): Receiver => {
	const {dob, combinedNumber} = patient;
	return {
		...transformPatientToReceiverPreview(patient),
		type: "receiver",
		dateOfBirth: dob ? createDate(dob) : undefined,
		combinedNumber,
	};
};
export const transformFieldNameBackToFront =
	(fieldName: ReceiverFormData["fieldName"]): keyof Receiver => ps(fieldName, {
		dob: "dateOfBirth",
		email: "email",
		telephone: "phone",
		patientGroup: "ageGroup",
		patientInsurance: "insurance",
		category: "description",
		default: fieldName as keyof Receiver,
	});
export const transformReceiverListBackendToFrontend = (formData: ReceiverFormData[]): ReceiverListedFields[] => {
	const listedFields: ReceiverListedFields[] = formData
		.filter(item => item.isShow)
		.flatMap((item) => item.fieldName === "fullName"
			? ["firstName", "lastName"]
			: transformFieldNameBackToFront(item.fieldName),
		) as ReceiverListedFields[];
	return listedFields;
};
export const transformReceiverFormBackToFront =
(formData: ReceiverFormData[], type: string): IdentityEditableFields<Receiver> => {
	const authorizedFields: IdentityEditableFields<Receiver> = {};
	formData.forEach((item) => {
		if (type === "requester" ? item.isSelected : item.isShow) {
			switch (item.fieldName.toString()) {
				case "firstName":
				case "lastName": {
					authorizedFields.fullName = authorizedFields.fullName?.length > 2
						? [authorizedFields.fullName[0], authorizedFields.fullName[1], {
							...authorizedFields.fullName[2],
							[item.fieldName]: [true, item.isRequired, null, item.labelName],
						}, authorizedFields.fullName[3]]
						: [true, false, {
							[item.fieldName]: [true, item.isRequired, null, item.labelName],
						}];
					break;
				}
				case "fullName": {
					authorizedFields.fullName = [true, item.isRequired, {
						...(authorizedFields.fullName?.length > 2 ? authorizedFields.fullName[2] : {}),
					}, item.labelName];
					break;
				}
				default: {
					authorizedFields[transformFieldNameBackToFront(item.fieldName)] = [true, item.isRequired, null, item.labelName];
				}
			}
		}
	});
	return authorizedFields;
};
export const transformInsuranceBackToFront = (value: string): PatientInsurance["name"] =>
	value === "Notinsured" ? "none" : value.toLowerCase() as PatientInsurance["name"];
export const transformInsuranceFrontToBack = (value: PatientInsurance["name"]): string =>
	s(value, {
		asylblg: "AsylbLG",
		gkv: "GKV",
		none: "Notinsured",
		other: "Other",
		unknown: "Unknown",
	});
