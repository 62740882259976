import {feedbacks as enUSFeedbacks} from "../en-US/feedbacks";

export const feedbacks: typeof enUSFeedbacks = {
	APICallFailed: "Fehler beim Abrufen der Daten",
	acceptSessionFailed: "Fehler beim Akzeptieren der Sitzung",
	acceptSessionSuccess: "Sitzung angenommen",
	calendarAccessNotAuthorized: "Der Zugriff auf den Handy Kalender ist nicht gestattet. Gewähren Sie in den Einstellungen Ihres Geräts Zugriff auf den lokalen Kalender.",
	calendarSynchronizationFailed: "Fehler beim Synchronisieren von Sitzungen mit Ihrem Handy Kalender",
	calendarSynchronizationSuccess: "Ihre Sitzungen für die nächsten 90 Tage wurden erfolgreich mit Ihrem Handy Kalender synchronisiert.",
	cancelSessionFailed: "Fehler beim Abbrechen der Sitzung",
	cancelSessionSuccess: "Die Sitzung wurde abgebrochen",
	changePasswordSuccess: "Ihr Passwort wurde erfolgreich geändert",
	connectionLost: "Netzwerkverbindung verloren",
	connectionRestored: "Netzwerkverbindung wiederhergestellt",
	copyAddressSuccess: "Die Zieladresse wurde erfolgreich kopiert",
	copyProfileSuccess: "Das Profil wurde erfolgreich kopiert",
	copySupportNumberSuccess: "Die Supportnummer wurde erfolgreich kopiert.",
	createDataFailed: "Fehler beim Erstellen des Artikels",
	createDataSuccess: "Artikel erfolgreich erstellt",
	createFollowUpFailed: "Fehler beim Erstellen der Folgeeinsatz",
	createSessionFailed: "Fehler beim Erstellen der Sitzung",
	createUnavailabilityFailed: "Fehler beim Erstellen einer Abwesenheit",
	createUnavailabilitySuccess: "Abwesenheit erstellt",
	deleteDataFailed: "Fehler beim Löschen des Artikels",
	deleteDataSuccess: "Artikel erfolgreich gelöscht",
	deleteUnavailabilityFailed: "Fehler beim Löschen einer Abwesenheit",
	deleteUnavailabilitySuccess: "Abwesenheit gelöscht",
	dependantDataError: "Die von dieser Sitzung abhängigen Daten wurden geändert/entfernt",
	emailSuccess: "E-Mail wurde erfolgreich versendet",
	geocodeFailed: "Fehler beim Abrufen der Karte",
	getCalendarDataFailed: "Fehler beim Abrufen von Kalenderdaten",
	getDataFailed: "Fehler beim Abrufen der Daten",
	getFollowUpParamsFailed: "Fehler beim Abrufen der Folgeeinsatzdaten",
	getInterpreterAddressesFailed: "Fehler beim Abrufen von Dolmetscheradressen",
	getInterpreterFailed: "Fehler beim Abrufen der Dolmetscherdaten",
	getInterpreterProfileEditRights: "Fehler beim Abrufen der Profilbearbeitungsrechte",
	getInterpreterSalaryFailed: "Fehler beim Abrufen der Gehaltsinformationen des Dolmetschenden",
	getInterpreterSettingsFailed: "Fehler beim Abrufen der Einstellungen des Dolmetschenden",
	getMinimumDurationFailed: "Fehler beim Abrufen der Einstellung für die Mindestdauer der Folge-Einsatz",
	getPlaceSuggestions: "Fehler beim Abrufen die Honorar des Dolmetschenden",
	getSessionDetailsFailed: "Fehler beim Abrufen der Sitzungsdetails",
	getSupportInfosFailed: "Fehler beim Abrufen von Support-Infos",
	getUnavailabilityFailed: "Fehler beim Abrufen einer Abwesenheit",
	getUserAddressesFailed: "Fehler beim Abrufen von Kundenadressen",
	getUserFailed: "Fehler beim Abrufen der Benutzerdaten",
	getUserSettingsFailed: "Fehler beim Abrufen der Benutzereinstellungen",
	hourLimitExceeded: "Ihre Organisation hat die Höchstdauer der Sitzung überschritten",
	imagePickerFailed: "Fehler bei der Bildauswahl",
	loginFailed: "Falsche Login- oder Passwortkombination",
	logoutFailed: "Fehler beim Abmelden",
	messageSentFailed: "Nachricht konnte nicht gesendet werden",
	messageSentSuccess: "Nachricht erfolgreich gesendet",
	notificationsPermissionsDisabled: "Push-Benachrichtigungen konnten nicht aktiviert werden. Sie können sie weiterhin in Ihren Telefoneinstellungen aktivieren",
	notificationsPermissionsEnabled: "Push-Benachrichtigungen aktiviert",
	refuseSessionFailed: "Fehler beim Ablehnen der Sitzung",
	refuseSessionSuccess: "Die Sitzung wurde abgelehnt",
	sendDataFailed: "Fehler beim Senden der Daten",
	serverDownError: "Ein Server-Update ist im Gange. Bitte versuchen Sie es später noch einmal",
	sessionAlreadyAttributed: "Diese Sitzung wurde bereits jemand anderem zugewiesen",
	sessionAlreadyValidated: "Ihr Administrator hat diese Sitzung bereits validiert",
	sessionCreationSuccess: "Die Sitzung wurde erfolgreich erstellt",
	sessionStatusChangedConfirmed: "Die Sitzung #{{id}} wurde von {{interpreter}} angenommen.",
	sessionStatusChangedRefused: "Die Sitzung #{{id}} wurde von allen Dolmetschenden abgelehnt.",
	unavailableTimeslot: "Sie sind in diesem Zeitfenster nicht verfügbar",
	updateDataFailed: "Fehler beim Aktualisieren des Artikels",
	updateDataSuccess: "Artikel erfolgreich aktualisiert",
	updateInterpreterFailed: "Fehler beim aktualisieren der Dolmetscherdaten",
	updateInterpreterPictureFailed: "Fehler beim Aktualisieren des Dolmetscher:in-Profilbildes",
	updateInterpreterProfileSuccess: "Ihr Profil wurde aktualisiert",
	updateSessionValidationFailed: "Fehler beim validieren der Sitzung",
	updateSessionValidationSuccess: "Die Sitzung wurde validiert",
	updateSettingsFailed: "Fehler beim Aktualisieren der Dolmetschereinstellungen",
	updateSettingsSuccess: "Einstellungen aktualisiert",
	updateUnavailabilityFailed: "Fehler beim Aktualisieren einer Abwesenheit",
	updateUnavailabilitySuccess: "Abwesenheit aktualisiert",
	updateUserAddressFailed: "Fehler beim Aktualisieren Ihrer gespeicherten Adressen",
	updateUserAddressSuccess: "Ihre gespeicherten Adressen wurden aktualisiert",
	uploadSessionValidationPictureFailed: "Fehler beim Hochladen des Validierungsbildes",
	videoCallCameraUsed: "Eine andere Anwendung verwendet Ihre Webcam. Aus Gründen des Datenschutzes müssen Sie sie zuerst schließen und diese Seite neu laden.",
	videoCallExpired: "Dieser Videoanruf ist abgelaufen",
	videoCallInitFailed: "Fehler bei der Vorbereitung des Videoanrufs",
	videoCallJoinFailed: "Fehler beim Verbinden des Videoanrufs",
	videoCallNoCamera: "Keine Webcam erkannt. Haben Sie eine Genehmigung erteilt?",
	videoCallNoMicro: "Kein Mikrofon gefunden. Haben Sie eine Genehmigung erteilt?",
};
