/* eslint-disable quote-props */
import {activities as enUSActivities} from "../en-US/activities";

export const activities: typeof enUSActivities = {
	calendar: {
		abbreviation: {
			day: "Tag",
			hour: "h",
			minute: "m",
		},
		days: {
			friday: "Freitag",
			monday: "Montag",
			saturday: "Samstag",
			sunday: "Sonntag",
			thursday: "Donnerstag",
			tuesday: "Dienstag",
			wednesday: "Mittwoch",
		},
		exportedEvent: {
			editWarning: "Dieses Ereignis wurde aus der Bhaasha-App exportiert. Jede Änderung würde nach der nächsten Synchronisation verloren gehen.",
			personalCalendar: "Persönlicher Kalender",
		},
		month: {
			april: "April",
			august: "August",
			december: "Dezember",
			february: "Februar",
			january: "Januar",
			july: "Juli",
			june: "Juni",
			march: "März",
			may: "Mai",
			november: "November",
			october: "Oktober",
			september: "September",
		},
		today: "Heute · {{date}}",
	},
	event: {
		confirmDelete: "Diesen Termin löschen?",
		confirmDeletes: "Alle Termine löschen (Wiederholung)?",
		confirmUpdate: "Diesen Termin aktualisieren?",
		confirmUpdates: "Alle Termine aktualisieren (Wiederholung)?",
		created: "Termin erstellt",
		recurrence: {
			connector: {
				end: "Ende",
				every: "jeden",
				everyDay: "jeden tag",
				fromDate: "vom",
				fromTime: "von",
				last: "letzten",
				month: "im Monat",
				on: "am",
				start: "Start",
				toDate: "bis",
				toTime: "bis",
			},
			error: {
				endTime: "Die Endzeit muss nach der Startzeit liegen",
				startTime: "Die Startzeit muss vor der Endzeit liegen",
			},
			explanation: {
				endTime: "Ändern Sie das Enddatum zur Anzeige von wiederkehrenden Terminen",
				summary: "Zusammenfassung",
			},
			ordinal: {
				fifth: "fünften",
				first: "ersten",
				fourth: "vierten",
				second: "zweiten",
				third: "dritten",
			},
			type: {
				daily: "Täglich",
				monthly: "Monatlich",
				never: "Nie",
				weekly: "Wöchentlich",
				yearly: "Jährlich",
			},
		},
	},
	price: {
		abbrev: {
			base: "Einsatz",
			increased: "Erhöht",
			reduced: "Reduziert",
			travel: {
				distance: "Distanz",
				flatRate: "Pauschalbetrag",
				time: "Reisezeit",
				total: "Reisen insgesamt",
			},
		},
		base: "Grundtarif",
		increased: "Erhöhter Tarif",
		reduced: "Reduzierter Tarif",
	},
	sessions: {
		accept: {
			assigned: "Danke! Buchungs ID #{{id}} wurde Ihnen zugewiesen.",
			validation: "Sind Sie sicher, dass Sie diese Anfrage akzeptieren möchten?",
			viewAction: "Sitzung anzeigen",
		},
		acceptanceTime: "Abnahmezeit",
		address: "Vollständige Einsatzadresse",
		addressInfos: "Lage (Name der Institution, Gebäude, Stock, Raum, ...)",
		appliedFilter: "{{count}} Filter angewendet",
		appliedFilter_plural: "{{count}} Filter angewendet",
		call: {
			done: "Der Anruf wurde vor {{duration}} erledigt",
			lessOneMinute: "weniger als eine Minute", // first letter must be lowercase!
			shareDescription: "Falls ein Teilnehmer den Link zur Videositzung nicht finden kann, verwenden Sie die Schaltfläche unten, um ihn erneut zu senden. Dieser Link ist ausschließlich für die Kunden bestimmt und darf keinesfalls vom Dolmetscher verwendet werden.",
			start: "Der Anruf beginnt in {{duration}}",
			started: "Der Anruf hat vor {{duration}} begonnen",
		},
		cancelConfirmation: "Möchten Sie diese Sitzung wirklich abbrechen?",
		canceled: "Die Sitzung wurde abgebrochen",
		canceledByInterpreter: "Absage wegen Dolmetscher:in (keine Abrechnung)",
		cancellationReason: "Grund der Stornierung",
		communicationTypes: {
			inPerson: "Vor Ort",
			phone: "Telefon",
			video: "Video",
		},
		context: {
			confirmReset: "Sind Sie sicher, dass Sie diese Sitzung zurücksetzen wollen? Alle Daten, die Sie bereits eingegeben haben, werden gelöscht.",
			message: {
				subtitle: "{{user}}: {{message}}",
				title: "Neue Nachricht",
			},
			refused: {
				subtitle: "Für diese Sitzung ist kein Dolmetscher:in verfügbar",
				title: "Sitzung abgelehnt",
			},
			reschedule: {
				subtitle: "{{user}} möchte diese Sitzung verschieben",
				title: "Sitzung verschoben",
			},
			type: {
				explanation: "Sie sind dabei, eine Sitzung zu verschieben. Wenn Sie diesen Vorgang nicht abschliessen, bleibt der ursprüngliche Termin bestehen.",
				withProvider: {
					followUp: "Bitte tragen Sie die Details ein, um die Folgebuchung an {{provider}} zu versenden",
					new: "Bitte tragen Sie die Details ein, um die Anfrage an {{provider}} zu versenden",
					reschedule: "Diese verschobene Sitzung wird an {{provider}} gesendet",
				},
				withoutProvider: {
					followUp: "Diese Sitzung ist eine Folgesitzung",
					new: "Diese Sitzung ist eine neue Sitzung",
					reschedule: "Diese Sitzung ist eine verschobene Sitzung",
				},
			},
			validate: {
				subtitle: "Bitte validieren Sie diese Sitzung",
				title: "Sitzung validieren",
			},
		},
		createNew: "Erstellen Sie eine neue Sitzung",
		created: "Die Sitzung wurde erstellt",
		duplicate: {
			title: "Duplizierungsoption auswählen",
			withInterpreter: "Mit Dolmetscher:in",
			withoutInterpreter: "Ohne Dolmetscher:in",
		},
		durations: {
			"15": "15 Minuten",
			"30": "30 Minuten",
			"45": "45 Minuten",
			"60": "1 Stunde",
			"75": "1 Stunde 15 Minuten",
			"90": "1 Stunde 30 Minuten",
			"105": "1 Stunde 45 Minuten",
			"120": "2 Stunden",
			"135": "2 Stunden 15 minuten",
			"150": "2 Stunden 30 Minuten",
			"165": "2 Stunden 45 Minuten",
			"180": "3 Stunden",
			"195": "3 Stunden 15 Minuten",
			"210": "3 Stunden 30 Minuten",
			"225": "3 Stunden 45 Minuten",
			"240": "4 Stunden (halber Tag)",
			"255": "4 Stunden 15 Minuten",
			"270": "4 Stunden 30 Minuten",
			"285": "4 Stunden 45 Minuten",
			"300": "5 Stunden",
			"315": "5 Stunden 15 Minuten",
			"330": "5 Stunden 30 Minuten",
			"345": "5 Stunden 45 Minuten",
			"360": "6 Stunden",
			"375": "6 Stunden 15 Minuten",
			"390": "6 Stunden 30 Minuten",
			"405": "6 Stunden 45 Minuten",
			"420": "7 Stunden",
			"435": "7 Stunden 15 Minuten",
			"450": "7 Stunden 30 Minuten",
			"465": "7 Stunden 45 Minuten",
			"480": "8 Stunden (ganzer Tag)",
		},
		exportTitle: "Sitzung exportieren",
		followUp: {
			calendar: "Siehe Agenda",
			create: "Folgeeinsatz erstellen",
			created: "Vielen Dank. Der Folgeeinsatz wurde erfolgreich erstellt.",
			notes: "Notizen & Kommentare",
			validation: "Sind Sie sicher, diesen Folgeeinsatz zu erstellen?",
		},
		internalComment: "Interne Kommentare (nicht sichtbar für Dolmetscher:in)",
		internalCommentPlaceholder: "Kommentare bei Bedarf eintragen",
		interpreterComment: "Kommentare für den oder die Dolmetscher:in",
		interpreterCommentPlaceholder: "Anzahl der Teilnehmer, Inhalt der Sitzung, spezifische Begriffe und weitere für den oder die Dolmetscher:in hilfreiche Informationen",
		mediation: {
			expectation: "Erwartungen an die interkulturelle Vermittlung",
			goal: "Ziel des Gesprächs",
			information: "Für eine interkulturelle Vermittlung ist eine telefonische Vorbesprechung zwischen der auftraggebenden Fachperson und der vermittelnden Person erforderlich, um ein möglichst gelungenes Gespräch zu ermöglichen. Die interkulturell vermittelnde Person wird die auftraggebende Fachperson für detaillierte Informationen zum Sachverhalt/Fall vorab anrufen und nach dem Gespräch vor Ort für eine Nachbesprechung zur Verfügung stehen.",
			involvedCorporations: "Andere involvierte Stellen",
			involvedPeople: "Am Gespräch beteiligte Personen (Rollen)",
			reason: "Grund für das Gespräch (Problemstellung, Hypothesen)",
			title: "Vermittlungsdetails",
		},
		newStatus: {
			done: "Die Sitzung ist jetzt im Status erledigt und dauerte {{duration}}",
			evaluated: "Die Sitzung ist jetzt im Status bewertet",
			validated: "Die Sitzung ist jetzt im Status validiert",
		},
		price: "Honorar",
		providedService: {
			education: "Kurs",
			mediation: "Vermittlungen",
			standard: "Dolmetschen",
		},
		rating: "Meine Bewertung",
		refusalReason: "Grund für die Ablehnung",
		refuse: {
			validation: "Sind Sie sicher, dass Sie diese Anfrage ablehnen möchten?",
		},
		reschedule: {
			accepted: "Die verschobene Sitzung wurde angenommen",
			confirmation: "Möchten Sie diese Sitzung wirklich verschieben?",
			excluded: "Dolmetschende, die bei der ursprünglichen Buchung ausgeschlossen wurden, nicht anfragen",
			reason: "Grund für die Verschiebung",
			sent: "Die neu verschobene Sitzung wurde gesendet",
			toAllInterpreters: "Sende die neue Sitzung an alle Dolmetschende",
			toOneInterpreter: "Senden Sie die neue Sitzung nur an {{interpreter}}",
		},
		sent: "Die Sitzung wurde an {{nInterpreters}} Dolmetschende geschickt",
		shareInfo: "Geteilte Sitzung am",
		status: {
			canceled: "Abgebrochen",
			completed: "Abgeschlossen",
			confirmed: "Bestätigt",
			deleted: "Gelöscht",
			inProgress: "In Bearbeitung",
			refused: "Abgelehnt",
			rescheduled: "Verschoben",
			sent: "Offen",
			stopped: "Gestoppt",
			unvalidated: "Nicht validiert",
		},
		statusType: {
			alert: "Alert",
			confirmed: "Bestätigt",
			history: "Verlauf",
			sent: "Offen",
		},
		subjectPlaceholder: "Worum wird es in dieser Session gehen?",
		support: {
			cancelation: "Freigabe aus persönlichen Gründen beantragen",
			deletion: "Stornierung wegen falscher Eingabe anfordern",
			other: "Supportanfrage",
			"validation-cards": "Validierungskarten bestellen",
		},
		timerImmediate: {
			allRefused: "Leider sind zur Zeit keine Dolmetscher verfügbar - bitte wählen Sie eine Aktion, um fortzufahren",
			cancel: "Suche abbrechen",
			continue: "Suche wird fortgesetzt für",
			expired: "Die Sitzung wird bald automatisch abgelehnt",
			inProgress: "Dolmetscher:in wird gesucht",
			notFound: "Keine Dolmetschenden gefunden",
			research: "Suche mit anderen Parametern",
			responses: "Rückmeldung",
			sentTo: "Gesendet an",
		},
		toccoBillingInformation: {
			label: "Rechnungsrelevante Angaben",
			placeholder: "Ihre Angaben werden in der Rechnung vermerkt. Bitte Datenschutz beachten!",
		},
		toccoPeopleNumber: "Anz. Gesprächspersonen",
		travelFees: "Reisekosten",
		validate: {
			agreement: "Ich bestätige, dass die eingegebenen Daten vollständig sind und der Sitzung entsprechen.",
			issue: {
				description: "Beschreibung",
				label: "Eine Änderung oder ein Problem hinzufügen",
				type: {
					absentReceiver: {
						label: "Klientschaft abwesend",
						person: "Abwesende Person",
						warning: "Achtung! Wenn Sie das Problem \"Abwesenheit des Klientschaft\" hinzufügen, verlieren Sie das bereits gemeldete Problem.",
						warning_plural: "Achtung! Wenn Sie das Problem \"Abwesenheit des Klientschaft\" hinzufügen, verlieren Sie die {{count}} Probleme, die Sie bereits gemeldet haben.",
					},
					cancellationTime: {
						label: "Datum und Uhrzeit der Stornierung",
					},
					communicationTypeChanged: {
						changedType: "Kommunikationsmodus verwendet",
						label: "Änderung der Kommunikationsart",
						summary: "Geändert von \"{{originalType}}\" zu \"{{changedType}}\"",
					},
					effectiveEndTime: {
						effectiveEndTime: "Effektive Endzeit",
						error: "Die effektive Endzeit muss nach der effektiven Startzeit liegen",
						explanation: "Validiere die tatsächliche Zeit, nicht die gebuchte Zeit.",
						label: "Effektive Endzeit",
					},
					effectiveStartTime: {
						effectiveStartTime: "Effektive Startzeit",
						error: "Die effektive Startzeit muss vor der effektiven Endzeit liegen",
						label: "Effektive Startzeit",
						warning: "Wenn die Verzögerung nicht auf den Interpreter zurückzuführen ist, sollte die effektive Startzeit dieselbe sein wie die ursprünglich geplante Zeit.",
					},
					feedback: {
						label: "Rückmeldung",
					},
					incident: {
						askForAdminReview: "Administrative Überprüfung",
						askForIntervision: "In der Intervision",
						askForSupervision: "In Supervision",
						label: "Ein Ereignis melden",
						person: "Betroffene Person",
						summary: {
							askForAdminReview: "Administrative Überprüfung",
							askForIntervision: "Überprüfung in der Intervision",
							askForSupervision: "Überprüfun in der Supervision",
							label: "Nachfragen",
						},
					},
					label: "Art der Änderung oder des Problems",
					other: {
						label: "Kommentare",
					},
				},
			},
			sessionId: "Sitzungs-ID",
			status: {
				canceled: "Abbruch der Sitzung durch die auftraggebende Person oder den Begünstigten",
				completed: "Sitzung abgeschlossen",
				deleted: "Diese Sitzung sollte gelöscht werden",
				label: "Sitzungsstatus",
				select: "Sitzungsstatus",
				warning: "Achtung! Wenn Sie den Zielstatus ändern, verlieren Sie das bereits gemeldete Problem.",
				warning_plural: "Achtung! Wenn Sie den Zielstatus ändern, verlieren Sie die {{count}} Probleme, die Sie bereits gemeldet haben.",
			},
			type: {
				email: "E-Mail-Validierung",
				label: "Art der Validierung",
				none: "Keine Unterschrift",
				numeric: "Signaturvalidierung",
				picture: "Bildvalidierung",
				select: "Gewünschte Art der Validierung",
			},
			validatedDuration: "Validierte Dauer",
			validation: "Validierung der Sitzung",
		},
		"validation-card-picture": "Validierungskarte",
	},
};
