import * as React from "react";
import {StyleSheet} from "react-native";
import {Recurrence} from "../../../../@types/activities/activity";
import {Unavailability as UnavailabilityT} from "../../../../@types/activities/unavailability";
import {OverlappingSessionsError} from "../../../../@types/errors";
import {confirmation} from "../../../../components/feedbacks/confirmation";
import {Form, FormInput, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {ActivitiesContext} from "../../../../contexts/activities";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {
	createUnavailability,
	deleteUnavailability,
	getUnavailability,
	updateUnavailability,
} from "../../../../requests/interpreters/activities/unavailabilities";
import {formatDay, formatShortDate, formatTime} from "../../../../utils/date-time/format";
import {
	addTime,
	compareDays,
	computeDuration,
	DayOfWeek,
	daysOfTheWeek,
	getNthWeekdayInMonth,
	isDayOfWeekBetween,
	moreThanOneDay,
	moreThanOneMonth,
	moreThanOneWeek,
	roundTime,
} from "../../../../utils/date-time/helpers";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {PRIMARY, RED, WHITE} from "../../../../utils/styles/colors";
import {ps} from "../../../../utils/switch";
import {forceBack} from "../../../navigation";
import {AddressListItem} from "../../client/modals/address-list";

const ordinals = ["first", "second", "third", "fourth"] as const;
type DatetimePicker = "endDate" | "endTime" | "startDate" | "startTime";

const onChangeDate = (
	start: Date, end: Date, prevReccurence?: Recurrence, prevDays?: DayOfWeek[],
): {days?: DayOfWeek[]; recurrence?: Recurrence} => {
	const isMoreThanOneDay = moreThanOneDay(start, end);
	const isMoreThanOneWeek = moreThanOneWeek(start, end);
	return !isMoreThanOneDay && prevReccurence !== undefined
		? {days: undefined, recurrence: undefined}
		: (isMoreThanOneDay && (prevReccurence === undefined || prevReccurence === "daily")) ||
		(prevReccurence === "weekly" && !isMoreThanOneWeek) ||
		(prevReccurence === "monthly" && !moreThanOneMonth(start, end))
			? {
				days: daysOfTheWeek.filter(
					day => isMoreThanOneWeek || isDayOfWeekBetween(daysOfTheWeek.indexOf(day), start.getDay(), end.getDay())),
				recurrence: "daily",
			}
			: {days: prevDays, recurrence: prevReccurence};
};

export const Unavailability = ({
	route,
	navigation,
	inModal,
	onDelete,
}: InterpreterStackNavigatorScreenProps<"UnavailabilityModal"> & {inModal?: boolean; onDelete?: () => void},
): JSX.Element => {
	const {unavailabilityId, date} = route.params ?? {};
	const {t, ct} = useTranslation();
	const {accountId} = React.useContext(AuthentifiedContext);
	const {columns} = React.useContext(ResponsiveContext);
	const {updateActivities} = React.useContext(ActivitiesContext);
	const [loadingUnavailability, setLoadingUnavailability] = React.useState(!!unavailabilityId);
	const [preview, setPreview] = React.useState(!!unavailabilityId);
	const [datetimeOpened, setDatetimeOpened] = React.useState<DatetimePicker | null>(null);
	const baseStartTime = React.useMemo(
		() => roundTime(date ? new Date(date) : new Date(), "half-hour"),
		[date],
	);

	const getBase = React.useCallback(
		(): Promise<UnavailabilityT> => {
			if (unavailabilityId) {
				return getUnavailability(unavailabilityId)
					.then(pe => {
						unavailabilityRef.current = pe;
						return pe;
					})
					.finally(() => setLoadingUnavailability(false));
			}
			return Promise.resolve({
				activityId: "",
				allDay: true,
				days: undefined,
				duration: 60 * 60 * 1000,
				end: addTime(baseStartTime, 60, "minute"),
				place: undefined,
				recurrence: undefined,
				start: baseStartTime,
				subject: "",
				type: "unavailability",
			});
		},
		[unavailabilityId, baseStartTime],
	);

	const {displayed, loading: loadingForm, setUpdated, isUpdated, errorMessage} = useForm(
		getBase, "getUnavailabilityFailed");

	const unavailabilityRef = React.useRef<UnavailabilityT>(displayed);
	const {start, end, allDay, recurrence, subject, place, activityId, days} = displayed;

	const isMoreThanOneWeek = React.useMemo(
		() => moreThanOneWeek(start, end),
		[start, end],
	);

	const displayedDaysChoices = React.useMemo(
		() => {
			// displays the days since Monday (and not Sunday)
			const days = [...daysOfTheWeek];
			days.push(days.shift()!);
			return days;
		},
		[],
	);

	const renderSummaryRecurrence = React.useMemo(
		() => {
			let sentence = "";

			// render recurrence
			if (days?.length && (recurrence === "daily" || recurrence === "weekly")) {
				if (days.length === 7) {
					sentence += `${ct("activities:event.recurrence.connector.everyDay")}, `;
				} else {
					sentence += `${ct("activities:event.recurrence.connector.every")} `;
					days
						.sort((a, b) => displayedDaysChoices.indexOf(a) - displayedDaysChoices.indexOf(b))
						.forEach(d => {
							sentence += `${t(`activities:calendar.days.${d}`)}, `;
						});
				}
			} else if (recurrence === "monthly") {
				sentence += `${ct("activities:event.recurrence.connector.every")} `;
				const nthWeekdayInMonth = getNthWeekdayInMonth(start);
				sentence += nthWeekdayInMonth === 5
					? t("activities:event.recurrence.connector.last")
					: t(`activities:event.recurrence.ordinal.${ordinals[nthWeekdayInMonth - 1]}`);
				sentence += ` ${formatDay(start)} ${t("activities:event.recurrence.connector.month")}, `;
			}

			sentence += compareDays(start, end)
				? `${(sentence.length === 0 ? ct : t)("activities:event.recurrence.connector.on")} ${formatShortDate(start)}`
				: `${(sentence.length === 0 ? ct : t)("activities:event.recurrence.connector.fromDate")} ${formatShortDate(
					start)} ${t("activities:event.recurrence.connector.toDate")} ${formatShortDate(end)}`;

			if (!allDay) {
				sentence += ` ${t("activities:event.recurrence.connector.fromTime")} ${formatTime(start)}`;
				sentence += ` ${t("activities:event.recurrence.connector.toTime")} ${formatTime(end)}`;
			}
			return sentence;
		},
		[allDay, ct, days, displayedDaysChoices, end, recurrence, start, t],
	);

	const deleteEvent = (instance: "all" | "this"): Promise<void> => deleteUnavailability(unavailabilityId!, instance)
		.then(() => {
			Log.success("deleteUnavailabilitySuccess")();
			navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
			/*
			 * we don't need to update the activities when we are in one column because we navigate on the calendar which
			 * will trigger the update of the activities (through the focus)
			 */
			columns > 1 && updateActivities(undefined, true).catch(Log.error());
			onDelete?.();
		}).catch(Log.error("deleteUnavailabilityFailed"));

	const header = (
		<HeaderMenu
			center={t("forms:inputs.unavailability")}
			right={{help: false}}
			exitConfirmation={isUpdated}
		/>
	);

	if (loadingUnavailability || loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingUnavailability || loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
				style={styles.loadingContainer}
			/>
		);
	}

	const inputs: FormProps["inputs"] = [
		{
			disabled: preview,
			icon: "id",
			label: ct("common:subject"),
			requiredLabel: true,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (s: string) => setUpdated(prev => ({...prev, subject: s})),
				value: subject || "",
			},
		},
		"spacer",
		{
			disabled: preview,
			explanation: t("screens:unavailability.addressExplanation"),
			icon: "place",
			label: ct("common:address"),
			requiredLabel: true,
			type: {
				key: "screen",
				onChangeValue: (p: AddressListItem) => setUpdated(prev => ({...prev, place: p?.displayed})),
				params: {place},
				screen: "SelectPlaceModal",
				value: place?.address ?? "",
			},
		},
		"spacer",
		{
			collapsed: datetimeOpened !== "startDate",
			disabled: preview,
			icon: "startDate",
			label: t("forms:inputs.startDate"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			toggleCollapse: () => setDatetimeOpened(prev => prev === "startDate" ? null : "startDate"),
			type: {
				key: "date",
				onChangeValue: (s: Date) => {
					if (s) {
						setUpdated(prev => {
							const prevEnd = prev.end ?? end;
							const endTime = s > prevEnd
								? new Date(s.getFullYear(), s.getMonth(), s.getDate(), prevEnd.getHours(), prevEnd.getMinutes())
								: prevEnd;
							return ({
								...prev,
								end: endTime,
								start: s,
								...onChangeDate(s, endTime, prev.recurrence, prev.days),
							});
						});
					}
				},
				props: {
					minimumDate: new Date(),
				},
				value: start,
			},
		},
		{
			collapsed: datetimeOpened !== "endDate",
			disabled: preview,
			explanation: t("activities:event.recurrence.explanation.endTime"),
			icon: "endDate",
			label: t("forms:inputs.endDate"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			toggleCollapse: () => setDatetimeOpened(prev => prev === "endDate" ? null : "endDate"),
			type: {
				key: "date",
				onChangeValue: (e: Date) => {
					setUpdated(prev => e
						? ({
							...prev,
							end: e,
							...onChangeDate(prev.start, e, prev.recurrence, prev.days),
						})
						: prev,
					);
				},
				props: {
					minimumDate: start,
				},
				value: end,
			},
		},
	];

	if (!compareDays(start, end)) {
		inputs.push("spacer");

		if (recurrence === "daily" || recurrence === "weekly") {
			inputs.push({
				disabled: preview,
				icon: "day",
				label: ct("common:day_plural"),
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: displayedDaysChoices,
					disableBreakpoint: true,
					getLabelText: (day: DayOfWeek) => ct(`activities:calendar.days.${day}`).slice(0, 1),
					itemProps: (day: DayOfWeek) => ({
						backgroundActiveColor: PRIMARY,
						disabled: preview ||
							(!isMoreThanOneWeek && !isDayOfWeekBetween(daysOfTheWeek.indexOf(day), start.getDay(), end.getDay())),
					}),
					key: "inlineSelect",
					keyExtractor: (day: DayOfWeek) => day,
					multiple: true,
					onChangeValue: (days: DayOfWeek[] | null) => {
						setUpdated(prev => ({...prev, days: days ?? undefined}));
					},
					value: days && days.length > 0 ? days : undefined,
				},
			});
		}

		const recurrenceInput = (reccurenceType: Recurrence): FormInput => ({
			disabled: preview || !isMoreThanOneWeek,
			icon: "recover",
			label: t(`activities:event.recurrence.type.${reccurenceType}`),
			type: {
				key: "select",
				onChangeValue: (checked: boolean) => checked && setUpdated(prev => ({
					...prev,
					days: ps(reccurenceType, {
						daily: daysOfTheWeek.filter(day => isMoreThanOneWeek ||
							isDayOfWeekBetween(daysOfTheWeek.indexOf(day), prev.start.getDay(), prev.end.getDay())),
						default: undefined,
						weekly: [daysOfTheWeek[prev.start.getDay()]],
					}),
					recurrence: reccurenceType,

				})),
				value: recurrence === reccurenceType,
			},
		});

		// daily recurrence - only if there is at least one day's difference between start and end date
		inputs.push(recurrenceInput("daily"));

		// weekly recurrence - only if there is more than 7 days between start and end date
		if (isMoreThanOneWeek) {
			inputs.push(recurrenceInput("weekly"));

			// monthly recurrence - only if the end date is at least the same nth weekday of the next month
			if (moreThanOneMonth(start, end)) {
				inputs.push(recurrenceInput("monthly"));
			}
		}

		// We don't want the yearly recurrence because it's not yet supported by the backend
	}

	inputs.push("spacer", {
		disabled: preview,
		icon: "day",
		label: t("forms:inputs.allDay"),
		type: {
			key: "checkbox",
			onChangeValue: (ad: boolean) => setUpdated(prev => ({...prev, allDay: ad})),
			value: allDay,
		},
	});

	if (!allDay) {
		inputs.push({
			collapsed: datetimeOpened !== "startTime",
			disabled: preview,
			icon: "startTime",
			label: t("forms:inputs.startTime"),
			rules: [
				{
					rule: Rules.notEmpty,
					type: "error",
				}, {
					rule: Rules.timeBefore(end, t("activities:event.recurrence.error.startTime")),
					type: "error",
				},
			],
			toggleCollapse: () => setDatetimeOpened(prev => prev === "startTime" ? null : "startTime"),
			type: {
				key: "time",
				onChangeValue: (s: Date) => {
					if (s) {
						setUpdated(prev => {
							const prevStart = prev.start ?? start;
							const startTime = new Date(prevStart.getFullYear(), prevStart.getMonth(), prevStart.getDate(),
								s.getHours(), s.getMinutes(),
							);
							return ({...prev, end: new Date(prev.end ?? end), start: startTime});
						});
					}
				},
				props: {
					minimumDate: new Date(),
					minuteInterval: 1,
				},
				value: start,
			},
		}, {
			collapsed: datetimeOpened !== "endTime",
			disabled: preview,
			icon: "endTime",
			label: t("forms:inputs.endTime"),
			rules: [
				{
					rule: Rules.notEmpty,
					type: "error",
				}, {
					rule: Rules.timeAfter(start, t("activities:event.recurrence.error.endTime")),
					type: "error",
				},
			],
			toggleCollapse: () => setDatetimeOpened(prev => prev === "endTime" ? null : "endTime"),
			type: {
				key: "time",
				onChangeValue: (e: Date) => {
					if (e) {
						setUpdated(prev => {
							const prevEnd = prev.end ?? end;
							const endTime = new Date(prevEnd.getFullYear(), prevEnd.getMonth(), prevEnd.getDate(),
								e.getHours(), e.getMinutes(),
							);
							return ({
								...prev,
								duration: computeDuration(prev.start ?? start, endTime),
								end: endTime,
								start: new Date(prev.start ?? start),
							});
						});
					}
				},
				props: {
					minimumDate: new Date(),
					minuteInterval: 1,
				},
				value: end,
			},
		});
	}

	inputs.push(
		"spacer",
		{
			disabled: true,
			label: t("activities:event.recurrence.explanation.summary"),
			type: {
				key: "text",
				value: renderSummaryRecurrence,
			},
		},
	);

	return (
		<>
			{header}
			<Form
				hideReset
				validation={{
					buttonProps: {
						icon: preview ? "edit" : "check",
						text: ct(preview
							? "forms:inputs.editEvent"
							: "common:save"),
					},
					onValidation: () => preview
						? setPreview(false)
						: activityId
							? confirmation({
								actions: [
									{
										icon: "edit",
										key: "editEvent",
										onPress: () => updateUnavailability(displayed)
											.then(() => {
												Log.success("updateUnavailabilitySuccess")();
												navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
												/*
												 * we don't need to update the activities when we are in one column because we navigate
												 * on the calendar which will trigger the update of the activities (through the focus)
												 */
												columns > 1 && updateActivities(undefined, true).catch(Log.error());
												setPreview(true);
												navigation.dispatch(forceBack);
											})
											.catch((error: Error) => ps(error.message, {
												default: () => Log.error("updateUnavailabilityFailed")(error),
												unavailableTimeslot: (): void =>
													navigation.navigate(
														"OverlappingSessionsModal",
														{sessionIds: (error as OverlappingSessionsError).sessionIds},
													),
											})),
										text: t(`forms:inputs.edit${recurrence ? "AllEvents" : "Event"}`),
										type: "primary",
									}, {
										icon: "close",
										key: "closeDrawer",
										onPress: () => null,
										text: ct("common:cancel"),
										type: "secondary",
									},
								],
								content: t(`activities:event.confirmUpdate${recurrence ? "s" : ""}`),
							})
							: createUnavailability(accountId!, displayed)
								.then(() => {
									Log.success("createUnavailabilitySuccess")();
									navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
								})
								.catch((error: Error) => {
									ps(error.message, {
										default: () => Log.error("createUnavailabilityFailed")(error),
										unavailableTimeslot: (): void =>
											navigation.navigate(
												"OverlappingSessionsModal",
												{sessionIds: (error as OverlappingSessionsError).sessionIds},
											),
									});
								}),
					secondaryButtonProps: preview
						? {
							icon: "delete",
							onPress: () => confirmation({
								actions: [
									{
										backgroundColor: RED,
										contentColor: WHITE,
										icon: "delete",
										isPromise: true,
										key: "deleteThisUnavailability",
										onPress: () => deleteEvent("this"),
										text: ct(`forms:inputs.delete${recurrence ? "ThisEvent" : "Event"}`),
										type: "primary",
									},
									recurrence && {
										backgroundColor: RED,
										contentColor: WHITE,
										icon: "delete",
										isPromise: true,
										key: "deleteAllUnavailability",
										onPress: () => deleteEvent("all"),
										text: ct("forms:inputs.deleteAllEvents"),
										type: "primary",
									}, {
										icon: "close",
										key: "closeDrawer",
										onPress: () => null,
										text: ct("common:cancel"),
										type: "secondary",
									},
								],
								content: t(`activities:event.confirmDelete${recurrence ? "s" : ""}`),
							}),
							text: ct("forms:inputs.deleteEvent"),
						}
						: inModal
							? undefined
							: {
								icon: "close",
								onPress: () => {
									setPreview(true);
									setUpdated(prev => ({...prev, ...unavailabilityRef.current}));
									if (!unavailabilityId) {
										navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
									}
								},
								text: ct("common:cancel"),
								type: "secondary",
							},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const UnavailabilityModal = (props: InterpreterStackNavigatorScreenProps<"UnavailabilityModal">): JSX.Element => (
	<ModalWrapper>
		<Unavailability inModal {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	loadingContainer: {
		flexBasis: 551, // Content height estimation
	},
});
