import * as React from "react";
import {StyleSheet, View} from "react-native";
import {MediaDevice} from "../../../@types/medias/media-device";
import {ResponsiveContext} from "../../../contexts/responsive";
import {
	CONTENT_CONTAINER_MAX_WIDTH,
	DEFAULT_SPACING,
	IS_INTERPRETER,
	IS_WEB,
	SMALL_SPACING,
	VIDEO_VIEW_RATIO,
} from "../../../utils/constants";
import {InitState} from "../../../utils/hooks/use-agora/use-agora.common";
import {useInterval} from "../../../utils/hooks/use-interval";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {BLACK, LIGHT_BLUE, LIGHT_RED, LIGHT_YELLOW, WHITE} from "../../../utils/styles/colors";
import {s} from "../../../utils/switch";
import {ScrollView} from "../../scrollables/scroll-view";
import {Text} from "../../texts/text";
import {RtcLocalView} from "../../views/rtc-view/rtc-local-view";
import {VideoSessionActions, VideoSessionActionsProps} from "./actions";
import {VideoOverlay} from "./video-overlay";

interface VideoSessionOverlayProps extends VideoSessionActionsProps {
	getCameraDevices: () => Promise<MediaDevice[]>;
	getMicrophoneDevices: () => Promise<MediaDevice[]>;
	initState: InitState;
	// name: string,
	// onChangeName: (name: string) => void,
	onJoin: () => void;
	onLeave?: () => void;
}

const Issue = ({issue, severity}: {issue: string; severity: "error" | "info" | "warning"}): JSX.Element => (
	<View
		style={[
			styles.issueContainer,
			{
				backgroundColor: s(severity, {
					error: LIGHT_RED,
					info: LIGHT_BLUE,
					warning: LIGHT_YELLOW,
				}),
			},
		]}
	>
		<Text color={BLACK} centered>
			{issue}
		</Text>
	</View>
);

export const VideoSessionPrompt = ({
	audio,
	backgroundBlur,
	getCameraDevices,
	getMicrophoneDevices,
	initState,
	onAudioSelect,
	onJoin,
	onLeave,
	onVideoSelect,
	switchCamera,
	toggleAudio,
	toggleBackgroundBlur,
	toggleVideo,
	video,
}: VideoSessionOverlayProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const [cameras, setCameras] = React.useState<MediaDevice[]>();
	const [micros, setMicros] = React.useState<MediaDevice[]>();

	const {columns} = React.useContext(ResponsiveContext);

	const checkDevices = React.useCallback(
		() => {
			// If there is an error, it's because the devices can't be accessed -> simply set them to []
			getCameraDevices().then(setCameras).catch(() => setCameras([]));
			getMicrophoneDevices().then(setMicros).catch(() => setMicros([]));
		},
		[getCameraDevices, getMicrophoneDevices],
	);

	useInterval(checkDevices, 2000, IS_WEB);

	return (
		<ScrollView
			contentContainerStyle={[
				styles.container,
				columns === 1 ? styles.containerOneColumn : styles.containerMultipleColumns,
			]}
			style={styles.scrollView}
		>
			<>
				{initState.status === "error" && <Issue issue={ct(`feedbacks:${initState.feedback}`)} severity="error" />}
				{cameras?.length === 0 && <Issue issue={ct("feedbacks:videoCallNoCamera")} severity="error" />}
				{micros?.length === 0 && <Issue issue={ct("feedbacks:videoCallNoMicro")} severity="error" />}
			</>
			<View
				style={[
					styles.userView,
					columns === 1 ? styles.userViewOneColumn : styles.userViewMultipleColumns,
				]}
			>
				{initState.status === "ready" && <RtcLocalView style={styles.rtcView} />}
				<VideoOverlay hasVideo={video} hasAudio={audio} />
			</View>
			<View
				style={[
					styles.controls,
					columns > 1 && styles.controlsMultipleColumns,
				]}
			>
				<Text
					color={WHITE}
					type="title"
					size={columns === 1 ? 25 : 30}
					style={[
						styles.title,
						columns === 1 && styles.titleOneColumn,
					]}
				>
					{t("screens:videoSession.joinTitle")}
				</Text>
				{IS_INTERPRETER && (
					<Text
						color={WHITE}
						type="emphasis_1"
						style={styles.joinMessage}
					>
						{t("screens:videoSession.joinMessage")}
					</Text>
				)}
				{/*
					<ButtonInput
						value={name}
						onChangeText={onChangeName}
						containerStyle={styles.input}
						placeholder="Please enter your name"
						leftIcon="person"
					/>
				*/}
				<VideoSessionActions
					audio={audio}
					backgroundBlur={backgroundBlur}
					canJoin={initState.status === "ready" && (IS_WEB ? cameras && cameras.length > 0 && micros && micros.length > 0 : true)}
					onLeave={onLeave}
					onJoin={onJoin}
					onAudioSelect={onAudioSelect}
					onVideoSelect={onVideoSelect}
					showLabels
					switchCamera={switchCamera}
					toggleAudio={toggleAudio}
					toggleBackgroundBlur={toggleBackgroundBlur}
					toggleVideo={toggleVideo}
					video={video}
				/>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		backgroundColor: BLACK,
		flex: 1,
		justifyContent: "center",
		padding: DEFAULT_SPACING,
	},
	containerMultipleColumns: {
		flexDirection: "row",
	},
	containerOneColumn: {
		flexDirection: "column",
	},
	controls: {
		alignItems: "center",
		flexDirection: "column",
		width: "100%",
	},
	controlsMultipleColumns: {
		flex: 1,
	},
	/*
	 * input: {
	 *  alignSelf: "center",
	 *  height: 50,
	 *  marginVertical: DEFAULT_SPACING,
	 *  maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
	 *  width: "100%",
	 * },
	 */
	issueContainer: {
		borderRadius: DEFAULT_SPACING,
		borderWidth: 2,
		marginBottom: 4,
		marginHorizontal: DEFAULT_SPACING,
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
		width: "100%",
	},
	joinMessage: {
		marginTop: -DEFAULT_SPACING,
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		padding: SMALL_SPACING,
	},
	rtcView: {
		height: "100%",
		width: "100%",
	},
	scrollView: {
		backgroundColor: BLACK,
	},
	title: {
		marginBottom: DEFAULT_SPACING,
	},
	titleOneColumn: {
		marginTop: DEFAULT_SPACING,
	},
	userView: {
		aspectRatio: VIDEO_VIEW_RATIO,
		borderColor: BLACK,
		borderRadius: DEFAULT_SPACING,
		borderWidth: SMALL_SPACING / 2,
		marginBottom: SMALL_SPACING,
		maxHeight: "60%",
		overflow: "hidden",
	},
	userViewMultipleColumns: {
		flex: 1,
		maxWidth: "100%",
	},
	userViewOneColumn: {
		width: "100%",
	},
});
