import {ExternalMedia} from "../../../../@types/medias/medias";
import {PickerImage} from "../../../inputs/pickers/image-picker";
import {PickerImageExampleSource} from "./utils";

export const mediasExamples: ExternalMedia[] = [
	{
		height: 262,
		mediaType: "image",
		mime: "image/png",
		source: "https://i.ibb.co/SmVtwVs/interpreter-Picture.png",
		sourceType: "url",
		width: 262,
	}, {
		height: 262,
		mediaType: "image",
		mime: "image/png",
		source: "https://i.ibb.co/ckZgCNN/user-Picture.png",
		sourceType: "url",
		width: 262,
	}, {
		height: 2048,
		mediaType: "image",
		mime: "image/png",
		source: "https://i.ibb.co/r4tpCbS/validation-Picture.jpg",
		sourceType: "url",
		width: 1152,
	},
];

export const pickerImageExample: PickerImage = {
	blob: {
		size: 199_781,
		type: "image/jpeg",
	} as Blob,
	height: 960,
	mediaType: "image",
	mime: "image/jpeg",
	size: 20,
	source: PickerImageExampleSource,
	sourceType: "base64",
	width: 1280,
};
