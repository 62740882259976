import {Account} from "../../../@types/accounts";
import {Person, PersonPreview} from "../../../@types/identities/person";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {UserAccountPerson} from "../../@types/user";
import {transformPersonBackToFront, transformPersonFrontToBack} from "../../utils/transform-person";

export const getClientRequesters = (
	accountId: Account["accountId"], pagination: Pagination): Promise<Page<PersonPreview>> =>
	searchClientRequesters(accountId, "", pagination);

export const addClientRequester = async (accountId: Account["accountId"], addedRequester: Person): Promise<Person> => {
	const userResponse = await api(
		"userPersonAdd",
		"POST",
		{
			body: {
				userAccount: {id: accountId},
				user: {
					...transformPersonFrontToBack(addedRequester),
				},
			},
		},
	) as ApiResponse<{}>;
	return userResponse.isSuccess
		? Promise.resolve(transformPersonBackToFront(userResponse?.data))
		: Promise.reject(new Error(userResponse?.message));
};

export const updateClientRequester = async (accountId: Account["accountId"], updatedRequester: Person): Promise<Person> => {
	const {identityId} = updatedRequester;
	const userResponse = await api(
		"userPersonUpdate",
		"PUT",
		{
			pathParams: {userId: identityId},
			body: {
				userAccount: {id: accountId},
				user: {
					id: identityId,
					...transformPersonFrontToBack(updatedRequester),
				},
			},
		},
	) as ApiResponse<{}>;
	if (userResponse.isSuccess) {
		return updatedRequester;
	}
	throw new Error(userResponse.message);
};

export const deleteClientRequester = async (deletedRequester: PersonPreview): Promise<void> => {
	const userResponse = await api(
		"userPersonDelete",
		"DELETE",
		{
			pathParams: {
				userAccountUserId: deletedRequester.identityId,
			},
		},
	) as ApiResponse<{}>;
	if (!userResponse.isSuccess) {
		throw new Error(userResponse.message);
	}
};

export const searchClientRequesters = async (accountId: Account["accountId"], search: string, pagination: Pagination):
Promise<Page<PersonPreview>> => {
	const {offset, limit} = pagination;
	const userResponse = await api(
		"userPersonsGet",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				role: "user",
				searchText: search,
				limit: limit.toString(),
				offset: (offset + 1).toString(),
			},
		},
	) as ApiResponse<{users: UserAccountPerson[], totalUsers: number}>;
	if (userResponse.isSuccess) {
		const {users, totalUsers} = userResponse.data;
		const userList = users.map((requester) => transformPersonBackToFront(requester));
		return {
			items: userList,
			hasPreviousPage: offset > 0,
			hasNextPage: totalUsers > offset,
			totalCount: totalUsers,
		};
	}
	throw new Error(userResponse.message);
};
