import * as React from "react";
import {GestureResponderEvent} from "react-native";
import {ConfirmationContent, type ConfirmationButtonProps} from "../../../../components/feedbacks/confirmation";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {MODAL_TRANSITION_DURATION} from "../../../../utils/constants";
import {PublicScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {forceBack} from "../../../navigation";

const Confirmation = ({
	route,
	navigation,
}: PublicScreenProps<"ConfirmationModal">): JSX.Element => {
	const {
		content,
		actions,
		actionsLayout,
	} = route.params;

	const onAction = React.useCallback(
		(action: ConfirmationButtonProps) => (event: GestureResponderEvent) => {
			const mustGoBack = action.goBackOnPress === true || action.goBackOnPress === undefined;
			if (!action.isPromise && mustGoBack) {
				navigation.dispatch(forceBack);
			}
			return new Promise(resolve => setTimeout(
				() => resolve(
					// We want to close the confirmation popup after the action is completed.
					// We wrap it into a Promise.resolve to handle both synchronous and asynchronous actions similarly.
					Promise.resolve(action.onPress?.(event))
						.then(() => {
							if (action.isPromise && mustGoBack) {
								navigation.dispatch(forceBack);
							}
						}),
				),
				MODAL_TRANSITION_DURATION,
			));
		},
		[navigation],
	);

	return (
		<ConfirmationContent
			actions={actions}
			actionsLayout={actionsLayout}
			content={content}
			onAction={onAction}
		/>
	);
};

export const ConfirmationModal = (props: PublicScreenProps<"ConfirmationModal">): JSX.Element => (
	<ModalWrapper>
		<Confirmation {...props} />
	</ModalWrapper>
);
