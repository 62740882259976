import {CommonActions, createNavigationContainerRef, NavigationAction} from "@react-navigation/native";
import {StackParamList} from "../utils/navigation/paramLists/root-param-list";

interface PendingNavEventQueue<RouteName extends keyof StackParamList> {
	queue: (
		{
			action: NavigationAction;
			type: "action";
		} | {
			listener: {
				callback: Parameters<typeof Navigation.navigationRef.addListener>[1];
				type: Parameters<typeof Navigation.navigationRef.addListener>[0];
			};
			type: "listener";
		} | {
			params: StackParamList[RouteName];
			screen: RouteName; type: "navigation";
		}
	)[];
}

export const forceBack = {...CommonActions.goBack(), payload: {force: true}};

export class Navigation {
	public static navigationRef = createNavigationContainerRef<StackParamList>();

	private static readonly pendingNavigation: PendingNavEventQueue<any> = {queue: []};

	public static navigationReady = (): void => {
		if (Navigation.pendingNavigation.queue.length > 0) {
			const pendingNav = Navigation.pendingNavigation.queue.pop();
			if (pendingNav) {
				if (pendingNav.type === "navigation") {
					Navigation.navigate(pendingNav.screen, pendingNav.params);
				} else if (pendingNav.type === "listener") {
					Navigation.navigationRef.addListener(pendingNav.listener.type, pendingNav.listener.callback);
				} else {
					Navigation.navigationRef.dispatch(pendingNav.action);
				}
			}
		}
	};

	public static navigate = <
		RouteName extends keyof StackParamList,
	>(screen: RouteName, params?: StackParamList[RouteName]): void => {
		if (Navigation.navigationRef.isReady()) {
			// @ts-expect-error FIXME: Typescript error to FIX
			Navigation.navigationRef.navigate(screen, params);
		} else {
			Navigation.pendingNavigation.queue.push({params, screen, type: "navigation"});
		}
	};
}
