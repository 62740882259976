import {Dispatch, SetStateAction} from "react";
import * as React from "react";
import {CommunicationType} from "../../@types/activities/session";
import {Pagination} from "../../@types/pagination";
import {FormInput} from "../../components/inputs/form";
import {ResponsiveContext} from "../../contexts/responsive";
import {
	getPeopleNumberListItems,
	PeopleNumberListItem,
} from "../../navigation/screens/client/modals/select-people-number";
import {Rules} from "../inputs/rules/rules";
import {paginate} from "../pagination";
import {
	TOCCO_MAX_PERSON_NUMBER,
	TOCCO_MAX_PHONE_VIDEO_PERSON_NUMBER,
	TOCCO_MIN_PERSON_NUMBER,
} from "../tocco";
import {useTranslation} from "./use-translation";

export const useToccoPeopleNumberInput = <T extends {toccoPeopleNumber?: number}>(
	usingTocco: boolean,
	communicationType: CommunicationType | undefined,
	updateState: Dispatch<SetStateAction<T>>,
	state: T,
	preview?: boolean,
): FormInput | undefined => {
	const {ct} = useTranslation();
	const {mobileDisplay} = React.useContext(ResponsiveContext);

	const getToccoPeopleNumberLabel = React.useCallback(
		(value: PeopleNumberListItem) => value.displayed,
		[],
	);

	const toccoPeopleNumberBounds = React.useMemo(
		() => ({
			maximum: communicationType === "phone" || communicationType === "video"
				? TOCCO_MAX_PHONE_VIDEO_PERSON_NUMBER
				: TOCCO_MAX_PERSON_NUMBER,
			minimum: TOCCO_MIN_PERSON_NUMBER,
		}),
		[communicationType],
	);

	const getToccoPeopleNumberRequest = React.useCallback(
		(pagination: Pagination) => Promise.resolve(
			paginate(
				getPeopleNumberListItems(toccoPeopleNumberBounds.minimum, toccoPeopleNumberBounds.maximum),
				pagination,
			),
		),
		[toccoPeopleNumberBounds],
	);

	const onToccoPeopleNumberChange = React.useCallback(
		(value: PeopleNumberListItem[] | null) => updateState(prev => ({
			...prev,
			toccoPeopleNumber: value?.[0].id ? Number(value[0].id) : undefined,
		})),
		[updateState],
	);

	const onToccoPeopleNumberSearch = React.useMemo(
		() => ({
			request: (pagination: Pagination, search: string) => Promise.resolve(
				paginate(
					getPeopleNumberListItems(toccoPeopleNumberBounds.minimum, toccoPeopleNumberBounds.maximum, search),
					pagination,
				),
			),
		}),
		[toccoPeopleNumberBounds],
	);

	return React.useMemo(
		() => (
			usingTocco
				? {
					icon: "people",
					label: ct("activities:sessions.toccoPeopleNumber"),
					preview,
					resetable: false,
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: mobileDisplay
						? {
							key: "screen",
							onChangeValue: (toccoPeopleNumber: number) =>
								updateState(prev => ({
									...prev,
									toccoPeopleNumber,
								})),
							params: toccoPeopleNumberBounds,
							renderValue: String,
							screen: "SelectPeopleNumberModal",
							value: state.toccoPeopleNumber,
						}
						: {
							getLabelText: getToccoPeopleNumberLabel,
							getRequest: getToccoPeopleNumberRequest,
							idKey: "id",
							itemTranslationKey: "activities:sessions.toccoPeopleNumber",
							key: "dropdownSelect",
							onChangeValue: onToccoPeopleNumberChange,
							onSearch: onToccoPeopleNumberSearch,
							value: state.toccoPeopleNumber
								? [{
									displayed: String(state.toccoPeopleNumber),
									id: String(state.toccoPeopleNumber),
								}]
								: undefined,
						},
				} satisfies FormInput
				: undefined
		),
		[
			ct, getToccoPeopleNumberLabel, getToccoPeopleNumberRequest, mobileDisplay, onToccoPeopleNumberChange,
			onToccoPeopleNumberSearch, preview, state.toccoPeopleNumber, updateState, toccoPeopleNumberBounds, usingTocco,
		],
	);
};
