import {common as enUSCommon} from "../en-US/common";

export const common: typeof enUSCommon = {
	about: "über",
	accept: "akzeptieren",
	account: "Profil",
	add: "hinzufügen",
	address: "Adresse",
	address_plural: "Adressen",
	addressNumber: "Addressnummer",
	administrator: "Administrator:in",
	all: "alle",
	availability: "Verfügbarkeit",
	availability_plural: "Verfügbarkeiten",
	billingAddress: "Abweichende Rechnungsadresse",
	billingAddress_plural: "Abweichende Rechnungsadressen",
	booked: "gebucht",
	calendar: "Agenda",
	call: "Anruf",
	camera: "Kamera",
	cancel: "abbrechen",
	category: "Kategorie",
	category_plural: "Kategorien",
	choose: "wählen",
	client: "Klient:in",
	close: "schliessen",
	combinedNumber: "kombinierte Nummer",
	communicationType: "Kommunikationsart",
	confirm: "bestätigen",
	copy: "kopieren",
	corporation: "Firma",
	creationDate: "Erstellungsdatum",
	crop: "zuschneiden",
	date: "Datum",
	day: "Tag",
	day_plural: "Tagen",
	delete: "löschen",
	description: "Beschreibung",
	detail: "Detail",
	detail_plural: "Details",
	directPhone: "Direktwahl",
	duplicate: "duplizieren",
	duration: "Dauer",
	education: "Kurs",
	email: "E-Mail",
	email_plural: "E-Mail Adressen",
	enabled: "aktiviert",
	evamNumber: "EVAM-Nummer",
	event: "Termin",
	event_plural: "Termine",
	exitAnyway: "OK",
	export: "export",
	filter: "Filter",
	filter_plural: "Filter",
	firstName: "Vorname",
	followUp: "Folgeeinsatz",
	fromTo: "{{from}} bis {{to}}",
	fullName: "Name und Vorname",
	function: "Funktion",
	gender: "Geschlecht",
	gender_plural: "Geschlechter",
	general: "allgemeines",
	group: "Gruppe",
	"half-hour": "ein halbe Stunde",
	"half-hour_plural": "halbe Stunden",
	hello: "hallo",
	help: "Hilfe",
	home: "Startseite",
	hour: "Stunde",
	hour_plural: "Stunden",
	ignore: "ignorieren",
	immediate: "unmittelbar",
	inbox: "Inbox",
	institution: "Institution/Praxis",
	interpreter: "Dolmetscher:in",
	interpreter_plural: "Dolmetschende",
	interpreterMandate: "Sitzung",
	interpretersGroups: "Dolmetschergruppen",
	ippNumber: "IPP-Nummer",
	join: "beitreten",
	language: "Sprache",
	language_plural: "Sprachen",
	languageUI: "Hauptsprache",
	lastName: "Nachname",
	later: "später",
	leave: "verlassen",
	library: "Galerie",
	link: "Link",
	loading: "loading",
	login: "Anmeldung",
	"madam-abr": "Frau",
	man: "Mann",
	man_plural: "Männer",
	mandate: "Anfrage",
	mandate_plural: "Anfragen",
	mandator: "Auftraggebende Person",
	medicalProfessional: "Gesprächführende Person",
	medicalProfessional_plural: "Gesprächführende Personen",
	message: "Nachricht",
	message_plural: "Nachrichten",
	millisecond: "Millisekunde",
	millisecond_plural: "Millisekunden",
	minute: "Minute",
	minute_plural: "Minuten",
	"mister-abr": "Herr",
	month: "Monat",
	month_plural: "Monate",
	name: "Name",
	navigate: "navigieren",
	new: "neu",
	next: "nächste",
	notRelevant: "nicht relevant",
	notification: "Benachrichtigung",
	notification_plural: "Benachrichtigungen",
	offline: "offline",
	ok: "ok",
	online: "online",
	option: "Option",
	option_plural: "Optionen",
	optional: "optional",
	origin: "Herkunft",
	origin_plural: "Herkünfte",
	other: "andere",
	otherProfessional: "andere Fachperson",
	otherProfessional_plural: "andere Fachleute",
	password: "Passwort",
	paymentAuthority: "Zahlungsbehörde",
	paymentAuthority_plural: "Zahlungsbehörden",
	person: "Person",
	person_plural: "Personen",
	phone: "Telefon",
	phone_plural: "Telefone",
	phonePlaceholder: "(z.B. +41 xx xxx xx xx / +49 xxx xxx xxxx)",
	preview: "Vorschau",
	previous: "vorherige",
	professional: "Fachperson",
	professional_plural: "Fachleute",
	providedService: "Leistung",
	qualification: "Qualifikation",
	qualification_plural: "Qualifikationen",
	"quarter-hour": "eine Viertelstunde",
	"quarter-hour_plural": "viertelstunden",
	read: "lesen",
	receiver: "Klientschaft",
	receiver_plural: "Klientschaft",
	recover: "wiederherstellen",
	refuse: "ablehnen",
	refused: "abgelehnt",
	requester: "auftraggebende Person",
	requester_plural: "auftraggebende Personen",
	required: "erforderlich",
	reschedule: "verschieben",
	reset: "zurücksetzen",
	restart: "neu starten",
	result: "Ergebnis",
	result_plural: "Ergebnisse",
	retry: "nochmals versuchen",
	salary: "Gesamtbetrag",
	salary_plural: "Gesamtbeträge",
	save: "speichern",
	search: "suchen",
	second: "Sekunde",
	second_plural: "Sekunden",
	select: "wählen",
	send: "senden",
	service: "Leistung",
	session: "Sitzung",
	session_plural: "Sitzungen",
	sessionCategory: "Sitzungskategorie",
	sessionCategory_plural: "Sitzungskategorien",
	sessionType: "Sitzungstyp",
	setting: "Einstellung",
	setting_plural: "Einstellungen",
	share: "teilen",
	signIn: "einloggen",
	signOut: "ausloggen",
	start: "start",
	status: "Status",
	stop: "stop",
	subject: "Thema",
	title: "Titel",
	total: "Gesamtbetrag",
	total_plural: "Gesamtbeträge",
	translation: "Übersetzung",
	transport: "Transportmittel",
	transport_plural: "Transportmittel",
	transportPrivate: "private Transportmittel",
	transportPublic: "öffentliche Verkehrsmittel",
	travel: "Reise",
	travel_plural: "Reisen",
	type: "Art",
	unknown: "Unbekannt",
	update: "aktualisieren",
	user: "Benutzer",
	username: "Benutzername",
	validate: "Validierung",
	week: "Woche",
	week_plural: "Wochen",
	woman: "Frau",
	woman_plural: "Frauen",
	year: "Jahr",
	year_plural: "Jahren",
};
