import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterLogoutRequests = {
	GET: {},
};
export type InterpreterLogoutResponse = {
	data: {
		id: number,
		role: string,
	},
	message: string,
	isSuccess: boolean,
};
export const logoutInterpreter = async (): Promise<void> => {
	const response = await api(
		"interpreterLogout",
		"GET",
		{},
	) as ApiResponse<{}>;
	if (!response.isSuccess) {
		throw new Error(response.message);
	}
};
