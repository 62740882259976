import * as React from "react";
import {Falsy, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {filterTruthy} from "../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Button, ButtonProps} from "../buttons/button";
import {ScrollView} from "../scrollables/scroll-view";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";
import {FormValidation} from "./form-validation";
import {ListInput, ListInputProps} from "./list";
import {ListInputWrapper, ListWrapperProps} from "./list-wrapper";

type FilteredFormInput = ListInputProps | "spacer";
export type FormInput = Falsy | FilteredFormInput;

export interface FormProps {
	/*
	 * Called after reset has been done, with the keys and new values of the inputs that were reset.
	 * /!\ Only the inputs that have a key will be included in the "updated" object.
	 */
	afterReset?: (updated: Record<string, any>) => void;
	contentContainerStyle?: StyleProp<ViewStyle>;
	contentStyle?: StyleProp<ViewStyle>;
	disableMountOnShapeChanged?: boolean;
	footerComponent?: JSX.Element;
	headerComponent?: JSX.Element;
	// Determines if the reset button needs to be hidden
	hideReset?: boolean;
	inputs: FormInput[];
	inputsProps?: ListWrapperProps;
	// Replace the default onPressReset with a custom reset function
	onPressReset?: () => void;
	onValidationChange?: (valid: boolean) => void;
	// Determine if we want to add a "required/optional" label on each field
	requiredLabels?: boolean;
	resetPosition?: "bottom" | "top";
	scrollRef?: React.Ref<ScrollView>;
	sideTitleComponent?: Falsy | React.ReactElement;
	style?: StyleProp<ViewStyle>;
	title?: string;
	validation?: {
		buttonProps: Omit<ButtonProps, "onPress">;
		disableOnValidationFailed?: boolean;
		onValidation: () => Promise<void> | void;
		secondaryButtonPosition?: "after" | "before";
		secondaryButtonProps?: ButtonProps;
	};
}

/*
 * inputs keys are very important since the form can change shape
 * React may not update props if the index doesn't change (typically with index)
 */
const inputKey = (input: FilteredFormInput, index: number): string => input === "spacer"
	? `${index}-spacer`
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	: input.key ?? `${index}-${input.type.key}-${String(input.label)}`;

export const Form = ({
	inputs: inputProp,
	hideReset,
	onPressReset: onPressResetProp,
	afterReset,
	resetPosition = "top",
	contentContainerStyle,
	style,
	inputsProps,
	headerComponent,
	footerComponent,
	contentStyle,
	title,
	sideTitleComponent,
	validation,
	onValidationChange,
	scrollRef,
	disableMountOnShapeChanged,
	requiredLabels,
}: FormProps): JSX.Element => {
	const {
		buttonProps,
		disableOnValidationFailed = true,
		onValidation,
		secondaryButtonProps,
		secondaryButtonPosition = "before",
	} = validation ?? {
		buttonProps: undefined,
		disableOnValidationFailed: true,
		onValidation: undefined,
		secondaryButtonPosition: undefined,
		secondaryButtonProps: undefined,
	};
	const {ct} = useTranslation();
	const inputs = filterTruthy(inputProp);
	const onPressReset = (): void => {
		const newValues: Record<string, any> = {};
		inputs.forEach((input) => {
			if (typeof input !== "string" && !input.preview && !input.disabled && input.key) {
				if (input.type.key === "multiSelect") {
					newValues[input.key] = null;
					input.type.getItems.onChangeValue(null);
				} else if (input.type.key === "textSuggestions") {
					newValues[input.key] = null;
					input.type.onSearch?.onChangeSearch?.(null);
				} else {
					// onChangeValue have trouble computing its arguments type. The amount of OR statements can compute to `never`
					const value = (input.type.key === "date" || input.type.key === "datetime" || input.type.key === "time"
						? new Date()
						: null) as never;
					newValues[input.key] = value;
					input.type.onChangeValue?.(value);
				}
			}
		});
		// Remove the "undefined" (or other invalid keys) in case some inputs didn't have any key
		afterReset?.(Object.fromEntries(Object.entries(newValues).filter(Boolean)));
	};
	const resetButton = (
		<Button
			text={ct("common:reset")}
			type="secondary"
			icon="close"
			size={resetPosition === "top" ? "xsmall" : "small"}
			fullWidth={resetPosition === "bottom"}
			style={resetPosition === "bottom" && styles.bottomReset}
			onPress={onPressResetProp ?? onPressReset}
		/>
	);
	const secondaryButton = (
		<Button
			fullWidth
			size="small"
			type="secondary"
			{...secondaryButtonProps}
		/>
	);
	const content = (isValid: boolean): JSX.Element => (
		<>
			<ListInputWrapper {...inputsProps}>
				{inputs.map((input, index) => input === "spacer"
					? <Spacer key={inputKey(input, index)} size={DEFAULT_SPACING}/>
					: (
						<ListInput
							key={inputKey(input, index)}
							{...input}
							requiredLabel={input.requiredLabel ?? requiredLabels}
						/>
					))}
			</ListInputWrapper>
			<View style={[CONTAINERS.MAIN, styles.paddings]}>
				{!hideReset && resetPosition === "bottom" && resetButton}
				{secondaryButtonProps && secondaryButtonPosition === "before" && (
					<>
						{secondaryButton}
						<Spacer size={SMALL_SPACING}/>
					</>
				)}
				{buttonProps && (
					<Button
						fullWidth
						size="small"
						{...buttonProps}
						disabled={(disableOnValidationFailed && !isValid) || buttonProps.disabled}
						onPress={onValidation}
					/>
				)}
				{secondaryButtonProps && secondaryButtonPosition === "after" && (
					<>
						<Spacer size={SMALL_SPACING}/>
						{secondaryButton}
					</>
				)}
			</View>
		</>
	);

	return (
		<>
			<ScrollView
				contentContainerStyle={[styles.contentContainerStyle, contentContainerStyle]}
				ref={scrollRef}
				style={style}
			>
				{headerComponent}
				<View style={contentStyle}>
					{!!title && (
						<View style={[CONTAINERS.MAIN, styles.title]}>
							<Text type="title">{title}</Text>
							{sideTitleComponent}
							{!hideReset && resetPosition === "top" && resetButton}
						</View>
					)}
					<FormValidation
						key={disableMountOnShapeChanged ? undefined : inputs.map((input, i) => inputKey(input, i)).toString()}
						onValidationChange={onValidationChange}
					>
						{content}
					</FormValidation>
				</View>
				{footerComponent}
			</ScrollView>
		</>
	);
};

const styles = StyleSheet.create({
	bottomReset: {
		marginBottom: SMALL_SPACING,
	},
	contentContainerStyle: {
		paddingVertical: DEFAULT_SPACING,
	},
	paddings: {
		padding: DEFAULT_SPACING,
		paddingBottom: 0,
	},
	title: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: SMALL_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
	},
});
