import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Place} from "../../../../@types/places";
import {Button} from "../../../../components/buttons/button";
import {ButtonInput} from "../../../../components/inputs/button";
import {ListElement} from "../../../../components/list/items/list-element";
import {Map} from "../../../../components/maps/map";
import {defaultPlace} from "../../../../components/maps/maps.common";
import {HeaderMenu} from "../../../../components/menus/header";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {Separator} from "../../../../components/separator";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {LocaleContext} from "../../../../contexts/locale";
import {getClientPlaces} from "../../../../requests/clients/settings/places";
import {getPlaceSuggestions} from "../../../../requests/common/maps";
import {getInterpreterPlaces} from "../../../../requests/interpreters/account/places";
import {CONTAINERS, DEFAULT_SPACING, IS_INTERPRETER, SMALL_SPACING} from "../../../../utils/constants";
import {Debouncer} from "../../../../utils/debouncer";
import {GeoLocation, getGeoLocation} from "../../../../utils/geolocation";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {TranslationFeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {defaultPagination} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";
import {AddressListItem} from "../../client/modals/address-list";

const matchString = (a: AddressListItem): string => a.displayed.address;

export const transformPlaceToAddressListItem = (place: Place): AddressListItem => ({
	displayed: place,
	id: place.address,
});

export const SelectPlace = ({
	route,
	navigation,
}: SharedScreenProps<"SelectPlaceModal">): JSX.Element => {
	const {onSelect: onSelectParam, place: placeParam, title} = route.params ?? {};
	const {t} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const {accountId} = React.useContext(AuthentifiedContext);
	const suggestionsDebouncer = React.useRef(new Debouncer());
	const geoLocation = React.useRef<GeoLocation | undefined>();
	const [loading, setLoading] = React.useState(!placeParam);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const [places, setPlaces] = React.useState<AddressListItem[]>([]);
	const [selectedPlace, setSelectedPlace] = React.useState<AddressListItem | null>(
		placeParam ? transformPlaceToAddressListItem(placeParam) : null);
	const [address, setAddress] = React.useState<string>(placeParam?.address ?? "");
	const onSelect = (a: AddressListItem) => () => setSelectedPlace(a);

	const onValidate = (): void => {
		if (selectedPlace) {
			onSelectParam?.(selectedPlace);
		}
		navigation.dispatch(forceBack);
	};
	const renderItem = (a: AddressListItem, i: number): JSX.Element => (
		<React.Fragment key={`${i}-${a.id}`}>
			{i !== 0 && <Separator spacingSize={0}/>}
			<ListElement key={a.id} onPress={onSelect(a)}>{matchString(a)}</ListElement>
		</React.Fragment>
	);

	React.useEffect(() => {
		getGeoLocation()
			.then((cachedLocation) => { geoLocation.current = cachedLocation; })
			.catch(() => { geoLocation.current = undefined; });
	}, []);
	React.useEffect(
		() => {
			if (address.length > 0 || placeParam) {
				suggestionsDebouncer.current.debounce(
					() => getPlaceSuggestions(address, locale, geoLocation.current?.location)
						.then(suggestions => {
							setSelectedPlace(
								prev => (suggestions.length > 0) ? transformPlaceToAddressListItem(suggestions[0]) : prev);
							return suggestions.map(s => transformPlaceToAddressListItem(s));
						})
						.then(setPlaces)
						.catch((error) => {
							Log.error()(error);
							setErrorMessage("feedbacks:getPlaceSuggestions");
						}),
					300,
				);
			} else {
				(IS_INTERPRETER
					? getInterpreterPlaces
					: getClientPlaces)(accountId!, locale, defaultPagination, "mostUsed")
					.then(suggestions => suggestions.items.map(s => transformPlaceToAddressListItem(s)))
					.then(setPlaces)
					.catch((error) => {
						Log.error()(error);
						setErrorMessage(`feedbacks:${IS_INTERPRETER ? "getInterpreterAddressesFailed" : "getUserAddressesFailed"}`);
					})
					.finally(() => setLoading(false));
			}
		},
		[address, placeParam, accountId, locale],
	);

	const header = <HeaderMenu center={title ?? t("forms:inputs.selectPlace")}/>;

	return (
		<>
			{header}
			<View style={[CONTAINERS.MAIN, styles.inputWrapper]}>
				<ButtonInput
					fullWidth
					onChangeText={setAddress}
					value={address}
				/>
			</View>
			<>
				<ScrollView>
					{loading || errorMessage
						? (
							<SplashView
								loading={loading}
								centered
								message={errorMessage && {translationKey: errorMessage, type: "error"}}
								style={styles.loadingContainer}
							/>
						)
						: places.slice(0, 4).map((a, i) => renderItem(a, i))}
					<Map
						place={selectedPlace?.displayed ?? defaultPlace}
						zoom={13}
					/>
					<View style={[CONTAINERS.MAIN, styles.validationWrapper]}>
						<Button
							fullWidth
							disabled={!selectedPlace || loading || !!errorMessage}
							size="small"
							onPress={onValidate}
							icon="check"
							iconPosition="after"
							{...(selectedPlace && {text: selectedPlace?.displayed.address})}
						/>
					</View>
				</ScrollView>
			</>
		</>
	);
};

const styles = StyleSheet.create({
	inputWrapper: {
		paddingBottom: SMALL_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
	loadingContainer: {
		flexBasis: 195, // Content height estimation
	},
	validationWrapper: {
		padding: DEFAULT_SPACING,
	},
});

export const SelectPlaceModal = (props: SharedScreenProps<"SelectPlaceModal">): JSX.Element => (
	<ModalWrapper>
		<SelectPlace {...props} />
	</ModalWrapper>
);
