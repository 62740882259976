import {Account} from "../../../../@types/accounts";
import {NotificationTarget} from "../../../../@types/notifications";
import {Page, Pagination} from "../../../../@types/pagination";
import {NotificationTargetsSetting, Setting} from "../../../../@types/settings";
import {getAvailableToLanguages} from "../../../languages";
import {Log} from "../../../logs/logs";
import {fakeRequest} from "../../../network/fake-request";
import {paginate} from "../../../pagination";
import {SessionLanguagesKey} from "../../../sessions/languages";
import {organizationExamples} from "../accounts/data";
import {fakeDatabase} from "../database";
import {updateIdentityAPI} from "../identities/api";
import {availableLanguagesCommunicationInterpreters} from "./data";

export const getLanguageSuggestions = (): Set<SessionLanguagesKey> => getAvailableToLanguages(
	availableLanguagesCommunicationInterpreters) || new Set();

export const getSettingsAPI = <S extends Setting>(accountId: Account["accountId"]): Promise<S[]> =>
	fakeRequest(fakeDatabase.settings.filter(
		el => el.owner === accountId || el.owner === organizationExamples[0].organizationId,
	) as S[]);

export const updateSettingAPI = <S extends Setting>(updated: S): Promise<void> => {
	if (updated.key === "availability/availabilities") {
		updateIdentityAPI({
			availability: updated.value.immediate ? undefined : "inEmergenciesDisabled",
			identityId: updated.owner,
		}).catch(Log.error());
	}
	fakeDatabase.settings = fakeDatabase.settings.map(el => el.settingId === updated.settingId ? updated : el);
	return fakeRequest();
};

/*
 * TODO: we didn't pass user id until now when interacting with notification targets, but we should.
 * Because of that, we currently simply use the first NotificationTargetsSetting found instead.
 * It's also not consistent that we pass userId parameter when deleting but not when performing get/update.
 */
const findNotificationTargets = (pred: (el: NotificationTargetsSetting) => boolean = () => true): NotificationTarget[] =>
	(fakeDatabase.settings.find(el =>
		el.key === "notification/targets" && pred(el),
	) as NotificationTargetsSetting)?.value || [];

export const getNotificationTargetsAPI = (pagination: Pagination): Promise<Page<NotificationTarget>> =>
	fakeRequest(paginate(findNotificationTargets(), pagination));

export const updateNotificationTargetAPI = (target: NotificationTarget): Promise<NotificationTarget> => {
	const targets = findNotificationTargets();
	targets[targets.findIndex(el => el.id === target.id)] = target;
	return fakeRequest(target);
};

export const deleteNotificationTargetAPI = (
	accountId: Account["accountId"],
	targetId: string,
): Promise<void> => {
	const targets = findNotificationTargets(el => el.owner === accountId);
	targets.splice(targets.findIndex(el => el.id === targetId), 1);
	return fakeRequest();
};
