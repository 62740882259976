import {Falsy} from "react-native";

export const containsSameElements = <T>(array1: T[], array2: T[]): boolean => {
	if (array1.length === array2.length) {
		return array1.every((element, index) => {
			return element === array2[index];
		});
	}
	return false;
};

export const filterTruthy = <T>(a: (Falsy | T)[]): T[] => a.filter(Boolean) as T[];

export type ArrayElementType<ArrType> = ArrType extends readonly (infer ElementType)[]
	? ElementType
	: never;
