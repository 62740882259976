import {useNavigation} from "@react-navigation/native";
import React from "react";
import {StyleSheet, View} from "react-native";
import {RequesterCalendarMandatePreview} from "../../@types/activities/mandate";
import {SessionCreationContext} from "../../contexts/session-creation";
import {toRescheduleCreation} from "../../navigation/screens/client/modals/cancel-reschedule-session";
import {cancelSession} from "../../requests/clients/activities/cancel";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {formatSecondsDurationInMS} from "../../utils/date-time/format";
import {getTimeCoef} from "../../utils/date-time/helpers";
import {useInterval} from "../../utils/hooks/use-interval";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Log} from "../../utils/logs/logs";
import {SUBTLE} from "../../utils/styles/colors";
import {Button} from "../buttons/button";
import {CountdownCircleTimer} from "../countdown-timer";
import {Icon, IconKey} from "../icons";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";

interface TextIconProps {
	icon: IconKey;
	number?: number;
	text?: string;
}

const TextIcon = ({icon, text, number}: TextIconProps): JSX.Element => (
	<View style={styles.textIcon}>
		<Icon
			icon={icon}
			containerSize={20}
			size={20}
		/>
		{text && (
			<>
				<Spacer size={SMALL_SPACING / 2} mode="vertical"/>
				<Text type="default_2">{text}</Text>
			</>
		)}
		<Spacer size={SMALL_SPACING / 2} mode="vertical"/>
		<Text type="emphasis_2">{number}</Text>
	</View>
);

const defaultTimerHeight = 180;

interface TimerProps {
	session: RequesterCalendarMandatePreview;
}

const COUNTDOWN_TIMER_SIZE = 100;
const IMMEDIATE_TIMER_DURATION = 600;
const ADDITIONAL_TIMER_DURATION = 1200;

export const TimerImmediate = ({session}: TimerProps): JSX.Element => {
	const {t} = useTranslation();
	const navigation = useNavigation();
	const {reset} = React.useContext(SessionCreationContext);
	const [now, setNow] = React.useState(new Date());

	const callback = React.useCallback(
		() => setNow(new Date()),
		[],
	);

	const secondsCoef = getTimeCoef("second");

	const onPressReschedule = React.useCallback(
		() => toRescheduleCreation(session, {}, reset, navigation as never),
		[navigation, reset, session],
	);

	const onPressCancel = React.useCallback(
		() => cancelSession(
			session.activityId, {canceledByProvider: false, reason: t("activities:sessions.timerImmediate.notFound")})
			.then(Log.success("cancelSessionSuccess"))
			.catch(Log.error("refuseSessionFailed")),
		[session.activityId, t],
	);

	const notConfirmed = (text: string): JSX.Element => (
		<View style={styles.notConfirmed}>
			<Text type="button_2" centered>{text}</Text>
			<Spacer size={SMALL_SPACING} mode="horizontal"/>
			<Button
				size="small"
				type="primary"
				fullWidth
				text={t("activities:sessions.timerImmediate.research")}
				icon="edit"
				onPress={onPressReschedule}
			/>
			<Spacer size={SMALL_SPACING} mode="horizontal"/>
			<Button
				size="small"
				type="secondary"
				fullWidth
				text={t("activities:sessions.timerImmediate.cancel")}
				icon="close"
				onPress={onPressCancel}
			/>
		</View>
	);

	useInterval(callback, secondsCoef);

	const elapsedTime = (now.getTime() - session.start.getTime()) / secondsCoef;

	const nbProviders = session.providers.length;
	const nbRefused = session.providers.filter((p) => p.status === "refused").length;
	const nbPending = session.providers.filter((p) => p.status === "pending").length;

	return (
		<View style={styles.wrapper}>
			<View style={styles.container}>
				{nbProviders === nbRefused
					? notConfirmed(t("activities:sessions.timerImmediate.allRefused"))
					: elapsedTime < IMMEDIATE_TIMER_DURATION
						? (
							<>
								<Text type="button_2">{t("activities:sessions.timerImmediate.inProgress")}</Text>
								<Spacer size={DEFAULT_SPACING} mode="horizontal"/>
								<View style={[styles.timer, {height: COUNTDOWN_TIMER_SIZE}]}>
									<CountdownCircleTimer
										duration={IMMEDIATE_TIMER_DURATION}
										size={COUNTDOWN_TIMER_SIZE}
										elapsedTime={elapsedTime}
									/>
									<View>
										<Text type="button_2">{t("activities:sessions.timerImmediate.sentTo")}</Text>
										<Spacer size={SMALL_SPACING} mode="horizontal"/>
										<TextIcon
											icon={nbProviders > 1 ? "people" : "person"}
											number={nbProviders}
										/>
									</View>

									<View>
										<Text type="button_2">{t("activities:sessions.timerImmediate.responses")}</Text>
										<Spacer size={SMALL_SPACING} mode="horizontal"/>
										<TextIcon
											icon="close"
											text={t("activities:sessions.status.refused")}
											number={nbRefused}
										/>
										<Spacer size={SMALL_SPACING} mode="horizontal"/>
										<TextIcon
											icon="help"
											text={t("activities:sessions.status.inProgress")}
											number={nbPending}
										/>
									</View>
								</View>
							</>
						)
						: elapsedTime < IMMEDIATE_TIMER_DURATION + ADDITIONAL_TIMER_DURATION
							? notConfirmed(`${t("activities:sessions.timerImmediate.notFound")} - ${t(
								"activities:sessions.timerImmediate.continue")} ${formatSecondsDurationInMS(
								Math.ceil(ADDITIONAL_TIMER_DURATION - elapsedTime + IMMEDIATE_TIMER_DURATION))}`)
							: (
								<View style={styles.expired}>
									<Text type="button_2">{`${t("activities:sessions.timerImmediate.notFound")}`}</Text>
									<Spacer size={SMALL_SPACING} mode="horizontal"/>
									<Text type="button_2">{`${t("activities:sessions.timerImmediate.expired")}`}</Text>
								</View>
							)
				}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		backgroundColor: SUBTLE,
		borderRadius: SMALL_SPACING,
		flex: 1,
		justifyContent: "center",
		margin: SMALL_SPACING,
		padding: SMALL_SPACING,
	},
	expired: {
		alignItems: "center",
	},
	notConfirmed: {
		alignItems: "center",
		alignSelf: "stretch",
		paddingHorizontal: SMALL_SPACING,
	},
	textIcon: {
		alignItems: "center",
		flexDirection: "row",
	},
	timer: {
		alignSelf: "stretch",
		flexDirection: "row",
		justifyContent: "space-evenly",
	},
	wrapper: {
		alignItems: "stretch",
		height: defaultTimerHeight,
		position: "relative",
		width: "100%",
	},
});
