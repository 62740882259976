import {Origin} from "../../../navigation/screens/common/modals/select-origin";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {SessionLanguagesKey} from "../../../utils/sessions/languages";
import {transformLanguageISOCodeFrontToBack} from "../../utils/transform";

export type UserOriginsRequests = {
	GET: {
		queryParams: {
			toLang: string,
		},
	},
};
export const getOrigins = async (toLanguage: SessionLanguagesKey): Promise<Origin[]> => {
	const response = await api(
		"userOrigins",
		"GET",
		{
			queryParams: {
				toLang: transformLanguageISOCodeFrontToBack(toLanguage),
			},
		},
	) as ApiResponse<{countries: {iso: Origin}[]}>;
	if (response.isSuccess) {
		return response.data.countries.map(country => country.iso);
	}
	throw new Error(response.message);
};
