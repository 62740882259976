import * as React from "react";
import {FlatList, ListRenderItemInfo} from "react-native";
import {ActivitiesContextVal} from "../../contexts/activities";
import {CONTAINERS} from "../../utils/constants";
import {MonthCalendar as MonthCalendarT, OnDatePress} from "../../utils/date-time/activities";
import {formatMonthKey} from "../../utils/date-time/format";
import {addTime, computeDuration, FIRST_DAY_CALENDAR} from "../../utils/date-time/helpers";
import {flatListDefaultProps} from "../../utils/scrollables";
import {SplashView} from "../views/splash-view";
import {MonthCalendar} from "./calendar-month";

interface Props {
	activitiesFetching: ActivitiesContextVal["activitiesFetching"];
	focusDate: ActivitiesContextVal["focusDate"];
	months: ActivitiesContextVal["months"];
	onDayPress: OnDatePress;
	startDate?: Date;
}

export const CalendarList = ({onDayPress, months, focusDate, activitiesFetching, startDate}: Props): JSX.Element => {
	const flatListRef = React.useRef<FlatList<MonthCalendarT>>(null);

	const keyExtractor = React.useCallback(
		(_: MonthCalendarT, index: number): string =>
			formatMonthKey(addTime(startDate ?? FIRST_DAY_CALENDAR, index, "month")),
		[startDate],
	);

	const getIndex = React.useCallback(
		(date: Date) => date && Math.ceil(computeDuration(startDate ?? FIRST_DAY_CALENDAR, date, "month")),
		[startDate],
	);

	React.useEffect(
		() => {
			if (!activitiesFetching) {
				const index = getIndex(focusDate);
				const data = flatListRef.current?.props.data;
				if (data && data.length > 0 && index >= 0 && index < data.length) {
					flatListRef.current.scrollToIndex({
						animated: true,
						index,
						viewPosition: 0,
					});
				}
			}
		},
		[getIndex, focusDate, activitiesFetching],
	);

	const renderItem = React.useCallback(
		({item}: ListRenderItemInfo<MonthCalendarT>) => (
			<MonthCalendar
				month={item.month}
				onDayPress={onDayPress}
				focusDate={focusDate}
			/>
		),
		[focusDate, onDayPress],
	);

	const getItemLayout = React.useCallback(
		(data: MonthCalendarT[] | null | undefined, index: number) => ({
			index,
			length: data?.[index]?.height ?? 0,
			offset: data?.[index]?.offset ?? 0,
		}),
		/*
		 * Even if it's not a true dependency, we NEED focusDate here, otherwise the items won't re-render even when the
		 * focus date changes, which would not show the selected focus date correctly.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[focusDate],
	);

	return (
		activitiesFetching
			? <SplashView centered loading/>
			: (
				<FlatList
					{...flatListDefaultProps}
					ref={flatListRef}
					data={months}
					renderItem={renderItem}
					keyExtractor={keyExtractor}
					getItemLayout={getItemLayout}
					initialScrollIndex={getIndex(focusDate)}
					ItemSeparatorComponent={null}
					contentContainerStyle={CONTAINERS.MAIN}
					scrollsToTop={false}
				/>
			)
	);
};
