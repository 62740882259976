import * as React from "react";
import {View} from "react-native";
import {Communication} from "../../../../@types/activities/session";
import {CommunicationTypeChangedIssue} from "../../../../@types/validation";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SummaryRow} from "../../../inputs/summary-row";
import {DescriptionSummary} from "./description";

export const CommunicationTypeChangedSummary = (
	{
		issue,
		originalCommunicationType,
		ct,
		t,
	}: {
		ct: TranslationFunc;
		issue: Partial<CommunicationTypeChangedIssue>;
		originalCommunicationType: Communication["type"];
		t: TranslationFunc;
	},
): JSX.Element => (
	<View>
		<SummaryRow>
			{issue?.changed && !!originalCommunicationType && t(
				"activities:sessions.validate.issue.type.communicationTypeChanged.summary",
				{
					changedType: t(`activities:sessions.communicationTypes.${issue.changed.communication.type}`),
					originalType: t(`activities:sessions.communicationTypes.${originalCommunicationType}`),
				})}
		</SummaryRow>
		{issue?.changed?.communication.type === "inPerson" && (
			<SummaryRow label={ct("activities:sessions.address")}>
				{issue.changed.communication.place.address}
			</SummaryRow>
		)}
		{!!issue?.description && <DescriptionSummary description={issue.description} t={t} />}
	</View>
);
