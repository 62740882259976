import * as React from "react";
import {Animated, GestureResponderEvent} from "react-native";
import {AvailabilityState} from "../../@types/availability";
import {InterpreterPreview} from "../../@types/identities/interpreter";
import {useInterpretersAvailabilities} from "../../utils/hooks/use-interpreters-availabilities";
import {AccountHeader, InterpreterAccountHeader} from "./account-header";

interface Props {
	buttons?: JSX.Element[];
	// websocket is enabled by default
	disableAvailabilityWebsocket?: boolean;
	editable?: (event: GestureResponderEvent) => void;
	interpreter: Pick<InterpreterPreview, keyof InterpreterAccountHeader | "availability">;
	scrollY: Animated.Value;
}

export const InterpreterHeader = ({
	interpreter,
	disableAvailabilityWebsocket = false,
	...props
}: Props): JSX.Element => {
	const [availabilityState, setAvailabilityState] = React.useState<AvailabilityState | undefined>(
		interpreter.availability);

	useInterpretersAvailabilities({
		connect: !disableAvailabilityWebsocket,
		onData: (data) => {
			if (data[interpreter.identityId]) {
				setAvailabilityState(data[interpreter.identityId]);
			}
		},
	});

	React.useEffect(
		() => setAvailabilityState(interpreter.availability),
		[interpreter.availability],
	);

	return <AccountHeader availabilityState={availabilityState} person={interpreter} {...props}/>;
};
