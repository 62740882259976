import {Corporation} from "../@types/identities/corporation";
import {IdentityEditableFields} from "../@types/identities/identity";
import {Alias, Gender, PersonIdentity, PersonIdentityPreview, PersonListedFields} from "../@types/identities/person";
import {Receiver, ReceiverPreview} from "../@types/identities/receiver";
import {filterTruthy} from "./arrays";
import {ct, t} from "./locales/translations";
import {ps} from "./switch";

const fullNameIdentity = (
	{fullName, gender}: Pick<Partial<PersonIdentityPreview>, "fullName" | "gender">,
	listedFields: PersonListedFields<PersonIdentity> | PersonListedFields<Receiver>,
): string =>
	fullName?.firstName ?? fullName?.lastName
		? `${gender && listedFields.has("gender") ? getTitle(gender) : ""}${
			fullName.firstName && listedFields.has("firstName") ? `${fullName.firstName} ` : ""}${
			fullName.lastName && listedFields.has("lastName") ? `${fullName.lastName}` : ""}`
		: "";

const aliasIdentity = (alias: Alias, showLabel = true): string => alias?.label ?? alias?.value
	? `${showLabel && alias.label ? `${alias.label}: ` : ""}${alias.value}`
	: "";

const aliasesIdentity = (
	aliases: Alias[],
	showLabel = true,
): string => aliases
	? aliases.map(alias => aliasIdentity(alias, showLabel)).join(", ")
	: "";

export const personIdentity = (
	person: Pick<Partial<PersonIdentityPreview>, "fullName" | "gender"> | null | undefined,
	listedFields: PersonListedFields<PersonIdentity> = new Set(["firstName", "lastName", "gender"]),
): string => {
	const fullName = fullNameIdentity(person ?? {}, listedFields);
	return fullName === ""
		? t("users:person.unknownPerson")
		: fullName;
};

// [editable, mandatory, availableValues, optional fieldLabel]
export const getDescriptionLabel = (editableFields?: IdentityEditableFields<Receiver>): string | undefined =>
	editableFields?.description?.[3];

export const receiverIdentity = (
	receiver: Pick<ReceiverPreview, "description" | "evamNumber" | "fullName" | "gender" | "ippNumber"> | null | undefined,
	listedFields: PersonListedFields<Receiver> = new Set([
		"firstName",
		"lastName",
		"gender",
		"evamNumber",
		"ippNumber",
		"description",
	]),
	descriptionLabel?: string,
): string => {
	if (listedFields.size === 0) {
		listedFields = new Set(["firstName", "lastName", "gender", "evamNumber", "ippNumber", "description"]);
	}

	const parts = [
		fullNameIdentity(receiver ?? {}, listedFields),
		aliasesIdentity(filterTruthy([
			listedFields.has("evamNumber") && receiver?.evamNumber,
			listedFields.has("ippNumber") && receiver?.ippNumber,
		])),
		listedFields.has("description") && receiver?.description
			? `${descriptionLabel! ?? ct("common:description")}: ${receiver.description}`
			: undefined,
	].filter(part => part !== "" && !!part);
	return parts.length === 0
		? t("users:person.unknownPerson")
		: parts.join(", ");
};

export const getTitle = (gender: Gender): string => ps(gender, {
	default: "",
	man: `${ct("common:mister-abr")} `,
	woman: `${ct("common:madam-abr")} `,
});

export const corporationIdentity = (corporation: Pick<Corporation, "group" | "name"> | null): string =>
	corporation?.name ?? t("users:person.unknownPerson");
