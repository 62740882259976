import * as React from "react";
import {StyleSheet} from "react-native";
import {InterpreterFilters} from "../../../../@types/identities/filters";
import {Qualification} from "../../../../@types/qualifications";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {getQualifications} from "../../../../requests/common/qualifications";
import {filterTruthy} from "../../../../utils/arrays";
import {DEFAULT_SPACING} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Locales} from "../../../../utils/locales/locales";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {SUBTLE_4} from "../../../../utils/styles/colors";
import {forceBack} from "../../../navigation";

type ListItem = Item<"id", {displayed: Qualification; id: Qualification["id"]}>;

const SelectQualification = (
	{route, navigation}: SharedScreenProps<"SelectQualificationModal">,
): JSX.Element => {
	const {selectionOrBase, onSelect, interpreterFilters: interpreterFiltersParam} = route.params ?? {};
	const {t} = useTranslation();
	const interpreterFilters: InterpreterFilters = interpreterFiltersParam
		? JSON.parse(interpreterFiltersParam)
		: undefined;

	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", ListItem>): JSX.Element => (
		<ListElement
			onPress={selectionOrBase
				? filterTruthy(buttons).find(button => button.key === "select")?.onPress
				: () => {
					onSelect?.([item.displayed]);
					navigation.dispatch(forceBack);
				}
			}
			disabled={item.displayed.default && item.displayed.defaultIsMandatory}
			buttons={buttons}
		>
			{item.displayed.value}
		</ListElement>
	);

	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectQualification")}/>
			<Text color={SUBTLE_4} centered style={styles.subHeader}>
				{t("forms:inputs.selectQualificationInfo")}
			</Text>
			<SelectFlatList
				renderItem={renderItem}
				getRequest={(pagination) => getQualifications(interpreterFilters?.interpretersGroups)
					.then((q: Qualification[]) => q.sort((q1, q2) => Locales.compare(q1.value, q2.value))
						.map(qualification => ({
							displayed: qualification,
							id: qualification.id,
							selected: selectionOrBase?.map(s => s.value).includes(qualification.value),
						})))
					.then(q => paginate(q, pagination))
				}
				{...selectionOrBase && onSelect && {
					onPressSelect: {
						onPress: (items: ListItem[] | string) => {
							if (Array.isArray(items)) {
								onSelect(items.map(item => item.displayed));
							}
							navigation.dispatch(forceBack);
						},
					},
				}}
				itemTranslationKey="common:qualification"
				idKey="id"
			/>
		</>
	);
};

const styles = StyleSheet.create({
	subHeader: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
});

export const SelectQualificationModal = (props: SharedScreenProps<"SelectQualificationModal">): JSX.Element => (
	<ModalWrapper>
		<SelectQualification {...props} />
	</ModalWrapper>
);
