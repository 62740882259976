import * as React from "react";
import {GestureResponderEvent, StyleSheet, View} from "react-native";
import {CalendarActivityPreview} from "../../../../@types/activities/activity";
import {ActivitiesFilters} from "../../../../@types/activities/filters";
import {Button} from "../../../../components/buttons/button";
import {DefaultActivities} from "../../../../components/calendar/activities";
import {CalendarList} from "../../../../components/calendar/calendar-list";
import {CollapsibleBlock} from "../../../../components/collapsible-block";
import {SegmentedBar} from "../../../../components/menus/segmented-bar";
import {TitleBar} from "../../../../components/menus/title-bar";
import {Spacer} from "../../../../components/spacer";
import {StatusBar} from "../../../../components/status-bar";
import {Text} from "../../../../components/texts/text";
import {Column} from "../../../../components/views/columns/column";
import {Columns} from "../../../../components/views/columns/columns";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {
	ActivitiesContext,
	ActivitiesProvider,
	allActivitiesFilters,
	defaultActivitiesFilters,
} from "../../../../contexts/activities";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {CalendarSyncManager} from "../../../../utils/calendar/calendar-sync";
import {APP, DEFAULT_SPACING, IS_CLIENT, IS_INTERPRETER, IS_WEB, SMALL_SPACING} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {BACKGROUND_COLOR, SUBTLE_2} from "../../../../utils/styles/colors";
import {ELEVATIONS} from "../../../../utils/styles/elevations";
import {Unavailability} from "../../interpreter/modals/unavailability";
import {SearchSessions} from "./search-sessions";
import {Session} from "./session";

const getMergedFilters = (filters?: string): ActivitiesFilters => ({
	...defaultActivitiesFilters,
	...filters && JSON.parse(filters) as ActivitiesFilters,
});

const onPressSynchronize = (): Promise<void> => CalendarSyncManager.exportEvents(defaultActivitiesFilters);

const SEGMENTED_BAR_ICONS_THRESHOLD = 414;

export type CalendarScreenProps = SharedScreenProps<"CalendarModal"> & {
	inModal?: boolean;
	onActivityPress?: (activity: CalendarActivityPreview) => (event: GestureResponderEvent | null) => void;
	selectedActivity?: Pick<CalendarActivityPreview, "activityId" | "type"> | null;
};

const CalendarScreen = ({
	navigation,
	route,
	inModal,
	onActivityPress,
	selectedActivity,
}: CalendarScreenProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const {
		updateActivities,
		setFocusDate,
		focusDate,
		months,
		filters,
		setFilters,
		activitiesMounted,
		setActivitiesMounted,
	} = React.useContext(ActivitiesContext);
	const {columns} = React.useContext(ResponsiveContext);
	const {title} = route?.params ?? {};

	const onPressFilter = (): void => navigation.navigate("SearchSessionsModal");
	const onPressCalendar = (): void =>
		navigation.navigate(
			"SelectDateCalendarModal",
			{
				focusDate,
				months,
				onSelect: (date: Date) => setFocusDate.current(date),
			},
		);
	const onPressToday = (): void => {
		setFocusDate.current(new Date());
	};
	const changeFilters = (status: ActivitiesFilters["status"]): void => setFilters(prev => ({...prev, status}));

	// Allows fetching activities when the activities are being remounted (tab change, etc).
	React.useEffect(
		() => {
			if (!activitiesMounted) {
				updateActivities(undefined, true)
					.then(() => setActivitiesMounted(true))
					.catch(Log.error());
			}
		},
		[activitiesMounted, updateActivities, setActivitiesMounted],
	);

	// Update activities on focus for interpreters, as they need to have their calendar up to date when they're on it
	React.useEffect(
		() => navigation.addListener(
			"focus",
			() => {
				if (IS_INTERPRETER) {
					updateActivities().catch(Log.error());
				}
			},
		),
		[navigation, updateActivities],
	);

	// We want to fetch activities on mount on requester app, because there are no navigation focus event there
	React.useEffect(
		() => {
			if (IS_CLIENT) {
				updateActivities(undefined, true).catch(Log.error());
			}
		},
		// We want to fetch activities only on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const appliedFilters = React.useMemo(
		() => Object.entries(filters).filter(([key, val]) => key !== "status" && val).length,
		[filters],
	);

	return (
		<>
			<TitleBar
				title={title || (IS_INTERPRETER ? ct("common:calendar") : t("users:menu.calendar"))}
				buttonsText={
					columns >= 3 && appliedFilters > 0
						? t("activities:sessions.appliedFilter", {count: appliedFilters})
						: undefined
				}
				buttons={[
					IS_INTERPRETER && !IS_WEB && !inModal && {
						icon: "download",
						key: "download",
						onPress: onPressSynchronize,
					},
					{
						icon: "focus",
						key: "today",
						onPress: onPressToday,
					},
					columns < 3 && {
						icon: "calendarMonth",
						key: "calendar",
						onPress: onPressCalendar,
					},
					columns < 3 && !inModal && {
						icon: "search",
						key: "search",
						notif: appliedFilters,
						onPress: onPressFilter,
					},
					inModal && {
						icon: "close",
						key: "close",
						onPress: () => navigation.goBack(),
					},
				]}
			/>
			{APP === "client" && (
				<SegmentedBar
					iconsThreshold={SEGMENTED_BAR_ICONS_THRESHOLD}
					segments={[
						{
							active: filters?.status && filters.status.length === 1 && filters?.status[0] === "sent",
							icon: "send",
							onPress: () => changeFilters(["sent"]),
							text: t("activities:sessions.status.sent"),
						},
						{
							active: filters?.status && filters?.status.length === 1 && filters?.status[0] === "confirmed",
							icon: "check",
							onPress: () => changeFilters(["confirmed"]),
							text: t("activities:sessions.status.confirmed"),
						},
						{
							active: filters?.status && filters?.status.length === 1 && filters?.status[0] === "refused",
							icon: "alert",
							onPress: () => changeFilters(["refused"]),
							text: ct("common:refused"),
						},
						{
							active: filters?.status && filters?.status.length === allActivitiesFilters?.length,
							onPress: () => changeFilters(allActivitiesFilters),
							text: ct("common:all"),
						},
					]}
					style={styles.segments}
				/>
			)}
			{activitiesMounted
				? (
					<DefaultActivities
						onActivityPress={onActivityPress}
						selectedActivity={selectedActivity}
					/>
				)
				: <SplashView centered loading/>
			}
		</>
	);
};

export const Calendar = ({navigation, route}: SharedScreenProps<"CalendarModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {columns} = React.useContext(ResponsiveContext);
	const onPressAddUnavailability = React.useCallback(
		() => navigation.navigate(
			"UnavailabilityModal", {date: new Date().toISOString()}),
		[navigation],
	);
	const [selectedActivity, setSelectedActivity] = React.useState<Pick<CalendarActivityPreview, "activityId" | "type"> | null>(
		null);
	const {setFocusDate, activitiesFetching, focusDate, months} = React.useContext(ActivitiesContext);
	const onSectionPlusPress = React.useCallback(
		() => navigation.navigate(
			"UnavailabilityModal", {date: new Date().toISOString()}),
		[navigation],
	);

	React.useEffect(
		() => {
			if (columns === 1) {
				setSelectedActivity(null);
			}
		},
		[columns],
	);
	const onPressCalendar = React.useCallback(
		(date: Date) => setFocusDate.current(date),
		[setFocusDate],
	);

	const onActivityPress = React.useCallback(
		({activityId, type}: CalendarActivityPreview) => () => {
			if (columns === 1) {
				if (type === "unavailability") {
					navigation.push(
						"UnavailabilityModal", {unavailabilityId: activityId});
				} else {
					navigation.push("SessionModal", {sessionId: activityId});
				}
			} else {
				setSelectedActivity({activityId, type});
			}
		},
		[columns, navigation],
	);

	const onActivityDelete = React.useCallback(
		() => setSelectedActivity(null),
		[],
	);

	return (
		<>
			<StatusBar/>
			<Columns>
				{columns >= 3 && (
					<>
						<View style={styles.toolsColumn}>
							{IS_INTERPRETER && (
								<Button
									fullWidth
									icon="plus"
									text={t("forms:inputs.logUnavailability")}
									size="small"
									type="secondary"
									style={styles.addUnavailabilityCol1}
									onPress={onPressAddUnavailability}
								/>
							)}
							<Column style={styles.calendar}>
								<CalendarList
									onDayPress={onPressCalendar}
									activitiesFetching={activitiesFetching}
									focusDate={focusDate}
									months={months}
								/>
							</Column>
							<Spacer size={SMALL_SPACING}/>
							<CollapsibleBlock collapsedTitle={ct("common:search")}>
								<Column style={styles.filters}>
									<SearchSessions
										navigation={navigation as any}
										route={{key: route.key, name: "SearchSessionsModal"}}
									/>
								</Column>
							</CollapsibleBlock>
						</View>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
					</>
				)}
				{columns >= 1 && (
					<Column>
						<CalendarScreen
							navigation={navigation as any}
							route={{...route, params: {...route.params}}}
							onActivityPress={onActivityPress}
							selectedActivity={selectedActivity}
						/>
						{columns < 3 && IS_INTERPRETER && (
							<Button
								innerStyle={styles.addUnavailabilityCol2Inner}
								style={styles.addUnavailabilityCol2}
								onPress={onSectionPlusPress}
								icon="plus"
								size="large"
							/>
						)}
					</Column>
				)}
				{columns >= 2 && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
						<Column style={[styles.activitiesColumn, !selectedActivity && styles.centeredContent]}>
							{
								selectedActivity
									? selectedActivity.type === "unavailability"
										? (
											<Unavailability
												navigation={navigation as never}
												onDelete={onActivityDelete}
												route={{
													key: route.key,
													name: "UnavailabilityModal",
													params: {unavailabilityId: selectedActivity.activityId},
												}}
											/>
										)
										: (
											<Session
												navigation={navigation as never}
												route={{
													key: route.key,
													name: "SessionModal",
													params: {sessionId: selectedActivity.activityId},
												}}
											/>
										)
									: <Text type="label">{t("forms:inputs.selectSession")}</Text>
							}
						</Column>
					</>
				)}
			</Columns>
		</>
	);
};

export const CalendarModal = ({route, navigation}: SharedScreenProps<"CalendarModal">): JSX.Element => (
	<ModalWrapper>
		<ActivitiesProvider
			filters={getMergedFilters(route.params?.filters)}
			focusDate={route.params?.focusDate ? new Date(route.params.focusDate) : undefined}
		>
			<CalendarScreen inModal route={route} navigation={navigation}/>
		</ActivitiesProvider>
	</ModalWrapper>
);

const styles = StyleSheet.create({
	activitiesColumn: {
		backgroundColor: BACKGROUND_COLOR,
		borderColor: SUBTLE_2,
		borderWidth: 1,
		...ELEVATIONS[4],
	},
	addUnavailabilityCol1: {
		marginVertical: SMALL_SPACING,
	},
	addUnavailabilityCol2: {
		bottom: DEFAULT_SPACING,
		position: "absolute",
		right: DEFAULT_SPACING,
	},
	addUnavailabilityCol2Inner: {
		...ELEVATIONS[4],
	},
	calendar: {
		borderColor: SUBTLE_2,
		borderWidth: 1,
		flex: 1,
	},
	centeredContent: {
		alignItems: "center",
		justifyContent: "center",
	},
	filters: {
		borderColor: SUBTLE_2,
		borderWidth: 1,
		flex: -1,
	},
	segments: {
		marginTop: -SMALL_SPACING,
	},
	toolsColumn: {
		flex: -1,
		flexBasis: 350,
	},
});
