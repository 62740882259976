import {BottomTabBarProps} from "@react-navigation/bottom-tabs";
import {TabNavigationState} from "@react-navigation/native";
import * as React from "react";
import {EmitterSubscription, Keyboard, StyleSheet, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {ResponsiveContext} from "../../contexts/responsive";
import {CONTAINERS, FOOTER_HEIGHT, IS_IOS} from "../../utils/constants";
import {ClientHomeTabNavigatorParamList} from "../../utils/navigation/paramLists/client-param-list";
import {InterpreterHomeTabNavigatorParamList} from "../../utils/navigation/paramLists/interpreter-param-list";
import {MENU_ACTIVE_MOBILE, MENU_BG, MENU_CONTENT_INACTIVE_MOBILE} from "../../utils/styles/colors";
import {Button} from "../buttons/button";
import {IconKey} from "../icons";

interface Props extends Omit<BottomTabBarProps, "state"> {
	icons?: (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList) => IconKey;
	labels?: (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList) => string;
	notifications?: (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList) => number;
	state: TabNavigationState<ClientHomeTabNavigatorParamList & InterpreterHomeTabNavigatorParamList>;
}

export const FooterMenu = ({icons, labels, navigation, notifications, state}: Props): JSX.Element => {
	const {displayMainMenu} = React.useContext(ResponsiveContext);
	const insets = useSafeAreaInsets();
	const keyboardWillShowListenerRef = React.useRef<EmitterSubscription>();
	const keyboardWillHideListenerRef = React.useRef<EmitterSubscription>();
	const [keyboardOpened, setKeyboardOpened] = React.useState(false);

	React.useEffect(
		() => {
			keyboardWillShowListenerRef.current = Keyboard.addListener(
				IS_IOS ? "keyboardWillShow" : "keyboardDidShow",
				() => setKeyboardOpened(true),
			);
			keyboardWillHideListenerRef.current = Keyboard.addListener(
				IS_IOS ? "keyboardWillHide" : "keyboardDidHide",
				() => setKeyboardOpened(false),
			);
			return () => {
				keyboardWillShowListenerRef.current?.remove();
				keyboardWillHideListenerRef.current?.remove();
			};
		},
		[],
	);

	return (!displayMainMenu && !keyboardOpened)
		? (
			<View style={[styles.container, {height: FOOTER_HEIGHT + insets.bottom, paddingBottom: insets.bottom}]}>
				<View style={[CONTAINERS.MAIN, styles.innerContainer]}>
					{state.routes.map((route, index) => {
						const isFocused = state.index === index;
						const onPress = (): void => {
							const event = navigation.emit({
								canPreventDefault: true,
								target: route.key,
								type: "tabPress",
							});

							if (!isFocused && !event.defaultPrevented) {
								navigation.navigate(route.name);
							}
						};
						return (
							<Button
								key={route.key}
								type="blank"
								size="large"
								label={labels ? labels(route.name) : undefined}
								notif={notifications ? notifications(route.name) : undefined}
								icon={icons ? icons(route.name) : undefined}
								onPress={onPress}
								active={isFocused}
								style={styles.buttonCenter}
								contentActiveColor={MENU_ACTIVE_MOBILE}
								contentColor={MENU_CONTENT_INACTIVE_MOBILE}
								allowFontScaling={false}
								labelProps={{style: {top: -12}}}
							/>
						);
					})}
				</View>
			</View>
		)
		: <></>;
};

const styles = StyleSheet.create({
	buttonCenter: {
		alignItems: "center",
		flex: 1,
	},
	container: {
		alignItems: "center",
		backgroundColor: MENU_BG,
		flexDirection: "row",
		justifyContent: "space-evenly",
		paddingTop: 2,
		width: "100%", // look more centered
	},
	innerContainer: {
		flex: 1,
		flexDirection: "row",
	},
});
