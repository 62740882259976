import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";
import {SMALL_SPACING, SEPARATOR_SIZE} from "../utils/constants";
import {SUBTLE_2} from "../utils/styles/colors";
import {Spacer} from "./spacer";

interface Props {
	borderSize?: number;
	color?: string;
	containerStyle?: StyleProp<ViewStyle>;
	mode?: "horizontal" | "vertical";
	spacingSize?: number;
	style?: StyleProp<ViewStyle>;
}

export const Separator = ({
	borderSize = SEPARATOR_SIZE,
	spacingSize = SMALL_SPACING,
	color = SUBTLE_2,
	mode = "horizontal",
	style,
	containerStyle,
}: Props): JSX.Element => (
	<>
		{!!spacingSize && <Spacer size={spacingSize} mode={mode} />}
		<View
			style={[
				mode === "horizontal" ? {width: "100%"} : {height: "100%"},
				containerStyle,
			]}
		>
			<View
				style={[
					{backgroundColor: color},
					{
						height: mode === "horizontal" ? borderSize : "100%",
						width: mode === "horizontal" ? "100%" : borderSize,
					},
					style,
				]}
			/>
		</View>
		{!!spacingSize && <Spacer size={spacingSize} mode={mode} />}
	</>
);
