import {SessionCategory} from "../../../../@types/activities/session-category";
import {Page, Pagination} from "../../../../@types/pagination";
import {fakeRequest} from "../../../network/fake-request";
import {paginate} from "../../../pagination";
import {textContains} from "../../../searches";
import {fakeDatabase} from "../database";

export const getSessionCategoriesAPI = (pagination?: Pagination, search?: string): Promise<Page<SessionCategory>> =>
	fakeRequest(
		paginate(
			search
				? fakeDatabase.sessionCategories.filter(({name}) => textContains(search, name))
				: fakeDatabase.sessionCategories,
		),
		pagination,
	);

export const createSessionCategoryAPI = (toAdd: SessionCategory): Promise<SessionCategory> => {
	const newItem = {...toAdd, id: toAdd.name};
	fakeDatabase.sessionCategories.push(newItem);
	return fakeRequest(newItem);
};

export const updateSessionCategoryAPI = (update: SessionCategory): Promise<SessionCategory> => {
	const index = fakeDatabase.sessionCategories.findIndex(el => el.id === update.id);
	if (index !== -1) {
		fakeDatabase.sessionCategories[index] = {
			...fakeDatabase.sessionCategories[index],
			...update,
		};
	}
	return fakeRequest(fakeDatabase.sessionCategories[index]);
};

export const deleteSessionCategoryAPI = (toDelete: SessionCategory): Promise<void> => {
	const index = fakeDatabase.sessionCategories.findIndex(el => el.name === toDelete.name);
	fakeDatabase.sessionCategories.splice(index, 1);
	return fakeRequest();
};
