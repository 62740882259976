/* eslint-disable unicorn/consistent-destructuring */
import * as React from "react";
import {NativeScrollEvent, NativeSyntheticEvent, StyleSheet, View} from "react-native";
import {Session} from "../../@types/activities/session";
import {CONTAINERS, DEFAULT_SPACING, IS_INTERPRETER, SMALL_SPACING} from "../../utils/constants";
import {formatDate, formatShortTimeRange} from "../../utils/date-time/format";
import {Debouncer} from "../../utils/debouncer";
import {useTranslation} from "../../utils/hooks/use-translation";
import {getTypeColor, getCommunicationTypeIcon} from "../../utils/sessions/communications";
import {CONTRAST, PRIMARY_2, SUBTLE, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Label} from "../labels/label";
import {StatusLabel} from "../labels/session-status";
import {TranslationLabel} from "../labels/translation";
import {ScrollView} from "../scrollables/scroll-view";
import {Text} from "../texts/text";
import {BounceBackground} from "../views/bounce-background";

export const SessionHeader = ({session}: {session: Session}): JSX.Element => {
	const {ct} = useTranslation();
	const {communication, status, start, end, immediate, activityId, price} = session;
	const backgroundColor = immediate ? CONTRAST : SUBTLE;
	const scrollView = React.useRef<ScrollView>(null);
	const scrollDebouncer = React.useRef(new Debouncer());
	const onScroll = (_: NativeSyntheticEvent<NativeScrollEvent>): void => {
		scrollDebouncer.current.debounce(
			() => scrollView.current?.scrollTo({animated: true, x: 0, y: 0}),
			2000,
		);
	};
	return (
		<View style={{backgroundColor}}>
			<BounceBackground position="top" {...{backgroundColor}} />
			<View style={[CONTAINERS.MAIN, styles.container]}>
				<View style={styles.title}>
					{!!activityId && <Text type="title_2">{`#${activityId}`}</Text>}
					{!!status && <StatusLabel style={styles.labelSpacing} status={status}/>}
				</View>
				<Text type="title">{formatDate(start)}</Text>
				<Text type="emphasis_1">
					{`${formatShortTimeRange(start, end)}${immediate ? ` - ${ct("common:immediate")}` : ""}`}
				</Text>
				<ScrollView
					horizontal
					ref={scrollView}
					onScroll={onScroll}
					contentContainerStyle={styles.labels}
					showsHorizontalScrollIndicator={false}
				>
					{!!communication && (
						<Label
							icon={getCommunicationTypeIcon(communication.type)}
							backgroundColor={getTypeColor(communication.type)}
						/>
					)}
					{IS_INTERPRETER && (
						<Label
							icon={session.type === "interpreterMandate" && session.onlyRecipient ? "personOutline" : "peopleOutline"}
							style={styles.labelSpacing}
							backgroundColor={WHITE}
							color={PRIMARY_2}
							borderColor={SUBTLE_4}
						/>
					)}
					{!!price && (
						<Label
							icon="money"
							style={styles.labelSpacing}
							backgroundColor={WHITE}
							color={PRIMARY_2}
							borderColor={SUBTLE_4}
						/>
					)}
					{session.type === "interpreterMandate" && (
						<TranslationLabel
							style={styles.labelSpacing}
							from={session.language}
							to={session.toLanguage}
						/>
					)}
				</ScrollView>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
	labelSpacing: {
		marginLeft: 8,
	},
	labels: {
		marginBottom: SMALL_SPACING,
		marginTop: DEFAULT_SPACING,
	},
	title: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
	},
});
