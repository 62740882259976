import React from "react";
import {StyleSheet, View} from "react-native";
import Svg, {Path} from "react-native-svg";
import {formatSecondsDurationInMS} from "../utils/date-time/format";
import {PRIMARY} from "../utils/styles/colors";
import {Text} from "./texts/text";

export const getPathProps = (
	size: number,
	strokeWidth: number,
	rotation: "clockwise" | "counterclockwise",
): {path: string; pathLength: number} => {
	const halfSize = size / 2;
	const halfStrokeWidth = strokeWidth / 2;
	const arcRadius = halfSize - halfStrokeWidth;
	const arcDiameter = 2 * arcRadius;
	const rotationIndicator = rotation === "clockwise" ? "1,0" : "0,1";
	const pathLength = 2 * Math.PI * arcRadius;
	const path = `m ${halfSize},${halfStrokeWidth} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,${arcDiameter} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,-${arcDiameter}`;
	return {path, pathLength};
};

interface CountdownCircleTimerProps {
	color?: string;
	content?: JSX.Element;
	duration: number;
	elapsedTime: number;
	rotation?: "clockwise" | "counterclockwise";
	size: number;
	strokeWidth?: number;
}

export const CountdownCircleTimer = ({
	duration,
	color = PRIMARY,
	content,
	size,
	elapsedTime,
	strokeWidth = 12,
	rotation = "clockwise",
}: CountdownCircleTimerProps): JSX.Element => {
	const {path, pathLength} = React.useMemo(
		() => getPathProps(size, strokeWidth, rotation),
		[size, strokeWidth, rotation],
	);

	return (
		<View>
			<Svg width={size} height={size}>
				{elapsedTime !== duration && (
					<Path
						d={path}
						fill="none"
						stroke={color}
						strokeLinecap="round"
						strokeWidth={strokeWidth}
						strokeDasharray={pathLength}
						strokeDashoffset={pathLength * elapsedTime / duration}
					/>
				)}
			</Svg>
			<View style={styles.countdownText}>
				{content ?? (
					<Text type="button_1">{formatSecondsDurationInMS(Math.ceil(duration - elapsedTime))}</Text>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	countdownText: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		left: 0,
		position: "absolute",
		top: 0,
		width: "100%",
	},
});
