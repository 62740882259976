import * as React from "react";
import {NativeSyntheticEvent, TextInputKeyPressEventData} from "react-native";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {AuthScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {ps} from "../../../../utils/switch";
import {AuthTemplate} from "./auth-template";

export const SignIn = (_: AuthScreenProps<"SignIn">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {signIn} = React.useContext(AuthentifiedContext);
	const [username, setUsername] = React.useState<string>();
	const [password, setPassword] = React.useState<string>();

	const onValidation = React.useCallback(() => {
		if (!username) {
			setUsername("");
		}
		if (!password) {
			setPassword("");
		}
		if (!username || !password) {
			return;
		}
		signIn(username, password).catch((error: Error) =>
			ps(error.message, {
				default: () => Log.error("serverDownError")(error),
				loginFailed: () => Log.error("loginFailed", false)(error),
			}));
	}, [password, signIn, username]);

	const onKeyPress = React.useCallback((event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
		if (event.nativeEvent.key === "Enter") {
			onValidation();
		}
	}, [onValidation]);

	return (
		<AuthTemplate
			label={t("screens:signIn.label")}
			title={ct("common:login")}
			inputs={[
				{
					icon: "person",
					label: ct("common:username"),
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "username",
						onChangeValue: setUsername,
						props: {onKeyPress},
						value: username,
					},
				}, {
					icon: "key",
					label: ct("common:password"),
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "password",
						onChangeValue: setPassword,
						props: {onKeyPress},
						value: password,
					},
				},
			]}
			validation={{
				disableOnValidationFailed: false,
				onValidation,
				text: ct("common:signIn"),
			}}
		/>
	);
};
