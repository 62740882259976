import * as React from "react";
import {StyleSheet} from "react-native";
import {CommunicationType, FollowUpSettings, InPerson, Session} from "../../../../@types/activities/session";
import {PersonPreview} from "../../../../@types/identities/person";
import {sessionConfirmation} from "../../../../components/feedbacks/confirmation";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {getSessionDetails} from "../../../../requests/common/activities/details";
import {createFollowUp, getFollowUpSettings} from "../../../../requests/interpreters/activities/actions/follow-up";
import {addTime, computeDuration, roundTime} from "../../../../utils/date-time/helpers";
import {useCommunicationTypes} from "../../../../utils/hooks/use-communication-types";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {personIdentity, receiverIdentity} from "../../../../utils/identities";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {TranslationFeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {GREEN} from "../../../../utils/styles/colors";
import {ps} from "../../../../utils/switch";
import {forceBack} from "../../../navigation";
import {AddressListItem} from "../../client/modals/address-list";

const FollowUp = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"FollowUpModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const [followUpSettings, setFollowUpSettings] = React.useState<FollowUpSettings>({
		disableCommunicationTypeChange: false,
		minimumDuration: 1,
	});
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const [loading, setLoading] = React.useState<boolean>(true);
	const startDate = React.useRef<Date>();
	const getBase = React.useCallback(
		() => getSessionDetails(route.params.sessionId)
			.then((s) => {
				startDate.current = s.start < new Date() ? roundTime(new Date()) : s.start;
				const res: Session = {
					...s,
					end: addTime(startDate.current, computeDuration(s.start, s.end)),
					providerComment: undefined,
					start: startDate.current,
					status: "confirmed",
				};
				return res;
			}),
		[route.params.sessionId],
	);
	React.useEffect(
		() => {
			getFollowUpSettings(route.params.sessionId)
				.then(setFollowUpSettings)
				.catch(error => {
					Log.error()(error);
					setErrorMessage("feedbacks:getMinimumDurationFailed");
				})
				.finally(() => setLoading(false));
		},
		[route.params.sessionId],
	);
	const {
		displayed, isUpdated, setUpdated, errorMessage: errorMessageForm, loading: loadingForm,
	} = useForm(getBase, "getSessionDetailsFailed");
	const communicationTypes = useCommunicationTypes();

	const header = <HeaderMenu right={{help: false}} exitConfirmation={isUpdated}/>;

	const error = errorMessage ?? errorMessageForm;
	if (loadingForm ?? loading ?? error) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm || loading}
				message={error &&
					{translationKey: error, type: "error"}}
				style={styles.loadingContainer}
			/>
		);
	}

	const standardService = displayed.providedService.type === "standard";

	return (
		<>
			{header}
			<Form
				hideReset
				title={ct("common:followUp")}
				validation={{
					buttonProps: {icon: "plus", text: t("activities:sessions.followUp.create")},
					onValidation: () => sessionConfirmation({
						actions: [
							{
								goBackOnPress: false,
								isPromise: true,
								key: "createFollowUp",
								onPress: () => createFollowUp({
									...displayed,
									providerComment: displayed.providerComment,
								})
									.then((newSession) => {
										sessionConfirmation({
											actions: [
												{
													key: "accept",
													onPress: () => {
														// closes the follow-up creation screen before to navigate to the new session details screen
														navigation.dispatch(forceBack);
														return navigation.navigate(
															"SessionModal",
															{sessionId: newSession.activityId},
														);
													},
													text: t("activities:sessions.accept.viewAction"),
												}, {
													key: "closeDrawer",
													onPress: () => {
														// closes the follow-up creation screen before to navigate to the calendar screen
														navigation.dispatch(forceBack);
														return navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
													},
													text: ct("common:close"),
													type: "secondary",
												},
											],
											icon: "validationOk",
											iconColor: GREEN,
											session: newSession,
											text: t("activities:sessions.followUp.created"),
										});
									})
									.catch((error: Error) => ps(error.message, {
										default: () => Log.error("createFollowUpFailed")(error),
										dependantDataError: () => Log.error("dependantDataError", false)(error),
										hourLimitExceeded: () => Log.error("hourLimitExceeded", false)(error),
										unavailableTimeslot: () => Log.error("unavailableTimeslot", false)(error),
									})),
								text: t("activities:sessions.followUp.create"),
							},
							{
								key: "closeDrawer",
								onPress: () => null,
								text: ct("common:cancel"),
								type: "secondary",
							},
						],
						session: displayed,
						text: t("activities:sessions.followUp.validation"),
					}),
					secondaryButtonProps: {
						onPress: () =>
							navigation.navigate(
								"CalendarModal",
								{focusDate: displayed.start.toISOString()},
							),
						text: t("activities:sessions.followUp.calendar"),
					}, // Get form state here
				}}
				inputs={[
					!!displayed.subject && {
						icon: "id",
						label: ct("common:subject"),
						preview: true,
						requiredLabel: true,
						type: {key: "text", value: displayed.subject},
					},
					displayed.type === "interpreterMandate" && displayed.mandator && {
						icon: "person",
						label: ct("common:user"),
						preview: true,
						requiredLabel: true,
						type: {key: "text", value: displayed.mandator.name},
					},
					displayed.type === "interpreterMandate" && displayed.medicalProfessional && {
						icon: "medical",
						label: ct("common:medicalProfessional"),
						preview: true,
						requiredLabel: true,
						type: {
							key: "text",
							value: personIdentity(displayed.medicalProfessional),
						},
					},
					displayed.type === "interpreterMandate" && displayed.receiver && {
						icon: "personOutline",
						label: ct("common:receiver"),
						preview: true,
						requiredLabel: true,
						type: {key: "text", value: receiverIdentity(displayed.receiver)},
					},
					"spacer",
					!followUpSettings.disableCommunicationTypeChange && {
						icon: "communicationMode",
						label: ct("common:communicationType"),
						preview: !standardService,
						resetable: false,
						type: {
							choices: standardService ? communicationTypes : ["inPerson"],
							getLabelText: (type: CommunicationType) => t(`activities:sessions.communicationTypes.${type}`),
							itemProps: (type: CommunicationType) => ({icon: getCommunicationTypeIcon(type)}),
							key: "inlineSelect",
							onChangeValue: (types?: CommunicationType[] | null) => setUpdated(prev => {
								const type = types?.[0];
								switch (type) {
									case "inPerson": {
										return {
											...prev,
											communication: {
												place: (prev?.communication as InPerson)?.place,
												placeInfos: (prev?.communication as InPerson)?.placeInfos,
												type,
											},
										};
									}
									case "video": {
										return {
											...prev,
											communication: {
												channelId: "", // set by the backend
												type,
											},
										};
									}
									case "phone": {
										return {
											...prev,
											communication: {
												phoneNumber: "", // set by the backend
												type,
											},
										};
									}
									default: {
										return prev;
									}
								}
							}),
							value: displayed.communication.type ? [displayed.communication.type] : null,
						},
					},
					"spacer",
					{
						icon: "personOutline",
						label: ct("common:requester"),
						requiredLabel: true,
						rules: [{rule: Rules.notEmpty, type: "error"}],
						type: {
							key: "screen",
							onChangeValue: (people: PersonPreview[]) => setUpdated(prev => ({...prev, requester: people?.[0]})),
							params: {exportType: "requester"},
							renderValue: (person: PersonPreview) => personIdentity(person),
							screen: "SelectPersonModal",
							value: displayed.requester,
						},
					},
					"spacer",
					{
						icon: "startTime",
						label: t("forms:inputs.startTime"),
						rules: [
							{rule: Rules.notEmpty, type: "error"},
							{rule: Rules.mustChange(startDate.current!.toString()), type: "error"},
						],
						type: {
							key: "datetime",
							onChangeValue: (start: Date | null) => start && setUpdated(prev => {
								const end = addTime(
									start,
									prev.start && prev.end
										? computeDuration(prev.start, prev.end, "minute")
										: followUpSettings.minimumDuration === 1
											? 60
											: followUpSettings.minimumDuration,
									"minute",
								);
								return ({
									...prev,
									duration: computeDuration(start, end),
									end,
									start,
								});
							}),
							props: {
								maximumDate: addTime(new Date(), 1, "year"),
								minimumDate: startDate.current,
								minuteInterval: 5,
							},
							value: displayed.start,
						},
					},
					{
						icon: "endTime",
						label: t("forms:inputs.endTime"),
						rules: [
							{
								rule: Rules.durationGreaterOrEqualTo(displayed.start, followUpSettings.minimumDuration, "minute"),
								type: "error",
							},
						],
						type: {
							key: "time",
							onChangeValue: (e: Date | null) => e && setUpdated(prev => {
								// ATM end time should always be on the same day as the start time
								const end = new Date(`${prev.start.toDateString()} ${e.toTimeString()}`);
								return ({
									...prev,
									duration: computeDuration(prev.start, end),
									end,
								});
							}),
							props: {minuteInterval: 5},
							value: displayed.end,
						},
					},
					"spacer",
					displayed.communication.type === "inPerson" && {
						icon: "place",
						label: ct("activities:sessions.address"),
						rules: [{rule: Rules.notEmpty, type: "error"}],
						type: {
							key: "screen",
							onChangeValue: (p: AddressListItem) =>
								setUpdated(prev => ({...prev, communication: {...prev?.communication, place: p?.displayed}})),
							params: {
								place: displayed.communication.place,
							},
							screen: "SelectPlaceModal",
							value: displayed.communication.place?.address,
						},
					},
					displayed.type === "interpreterMandate" && {
						icon: "comment",
						label: t("activities:sessions.followUp.notes"),
						requiredLabel: true,
						type: {
							key: "text",
							onChangeValue: (providerComment?: string) =>
								setUpdated(prev => ({...prev, providerComment})),
							props: {multiline: true},
							value: displayed.providerComment,
						},
					},
				]}
			/>
		</>
	);
};

export const FollowUpModal = (props: InterpreterStackNavigatorScreenProps<"FollowUpModal">): JSX.Element => (
	<ModalWrapper>
		<FollowUp {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	loadingContainer: {
		flexBasis: 700, // Content height estimation
	},
});
