import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationOptions} from "@react-navigation/native-stack";
import * as React from "react";
import {Platform, StatusBar as RNStatusBar, StyleSheet, View} from "react-native";
import {SafeAreaProvider, useSafeAreaInsets} from "react-native-safe-area-context";
import {ResponsiveContext} from "../../contexts/responsive";
import {CONTAINERS, DEFAULT_SPACING, IS_IOS, IS_WEB} from "../../utils/constants";
import {BACKGROUND_COLOR, OVERLAY} from "../../utils/styles/colors";
import {Alert} from "../feedbacks/alert";
import {Toast} from "../feedbacks/toast";
import {KeyboardAvoidingView} from "./keyboard-avoiding-view";
import {Pressable} from "./pressable";

const NATIVE_MODAL_OFFSET = IS_IOS ? 10 : 0;

export const modalScreenOptions: NativeStackNavigationOptions = {
	contentStyle: {backgroundColor: IS_WEB ? OVERLAY : BACKGROUND_COLOR},
	gestureEnabled: true,
	headerShown: false,
	presentation: IS_WEB ? "containedTransparentModal" : "modal",
};

interface ModalWrapperProps {
	children: React.ReactNode;
	fullHeight?: boolean;
	onClose?: () => void;
	overlayBackground?: boolean;
}

export const ModalWrapper = ({children, onClose, fullHeight, overlayBackground}: ModalWrapperProps): JSX.Element => {
	const {likelyDevice} = React.useContext(ResponsiveContext);
	const navigation = useNavigation();
	const {top} = useSafeAreaInsets();
	const dismiss = React.useCallback(
		() => onClose
			? onClose()
			: navigation.goBack(),
		[navigation, onClose],
	);
	return IS_WEB
		? (
			<KeyboardAvoidingView
				style={[
					styles.filler,
					overlayBackground && styles.overlayBackground,
				]}
				keyboardVerticalOffset={top + NATIVE_MODAL_OFFSET}
			>
				<Pressable
					onPress={dismiss}
					style={[
						StyleSheet.absoluteFill,
						styles.defaultCursor,
					]}
				>
					{null}
				</Pressable>
				<View
					style={[
						CONTAINERS.MAIN,
						styles.wrapper,
						likelyDevice === "phone" && {justifyContent: "flex-end"},
					]}
				>
					<Pressable
						onPress={dismiss}
						style={[styles.filler, styles.defaultCursor]}
					>
						{null}
					</Pressable>
					<View
						style={[
							CONTAINERS.DRAWER,
							styles.container,
							fullHeight && styles.fullHeightContainer,
							likelyDevice === "phone" && {
								borderBottomLeftRadius: 0,
								borderBottomRightRadius: 0,
							},
						]}
					>
						{children}
					</View>
					<Pressable
						onPress={dismiss}
						style={[
							styles.filler,
							styles.defaultCursor,
							likelyDevice === "phone" && {flex: 0, minHeight: 0},
						]}
					>
						{null}
					</Pressable>
				</View>
			</KeyboardAvoidingView>
		)
		: (
			<SafeAreaProvider>
				<KeyboardAvoidingView
					style={[
						styles.filler,
						overlayBackground && styles.overlayBackgroundMobile,
					]}
					keyboardVerticalOffset={top + NATIVE_MODAL_OFFSET}
				>
					<RNStatusBar barStyle={IS_IOS ? "light-content" : "dark-content"}/>
					{children}
					{IS_IOS &&
						<>
							<Toast/>
							<Alert/>
						</>
					}
				</KeyboardAvoidingView>
			</SafeAreaProvider>
		);
};

const styles = StyleSheet.create({
	container: {
		borderRadius: DEFAULT_SPACING,
		flex: -1,
		overflow: "hidden",
	},
	// @ts-expect-error '{ cursor?: string | undefined; }' is not assignable to type 'ViewStyle | TextStyle | ImageStyle'.
	defaultCursor: {
		...Platform.select({
			web: {
				cursor: "pointer",
			},
		}),
	},
	filler: {
		flex: 1,
		minHeight: DEFAULT_SPACING,
	},
	fullHeightContainer: {
		height: "100%",
	},
	overlayBackground: {
		backgroundColor: OVERLAY,
	},
	overlayBackgroundMobile: {
		backgroundColor: BACKGROUND_COLOR,
	},
	wrapper: {
		flex: 1,
		justifyContent: "center",
	},
});
