import React, {Dispatch, SetStateAction} from "react";
import {FeedbackKey, TranslationFeedbackKey} from "../locales/translations";
import {Log} from "../logs/logs";
import {getKeys, isSubset} from "../objects";
import {isFunction} from "../types";

interface State<Data> {
	// The default values passed as argument
	base: Data;
	// The displayed settings are the base values overwritten with the updated values
	displayed: Data;
	isUpdated: boolean;
	// The updated settings are used to know which properties has changed
	updated: Partial<Data>;
}

// getBase must be put in a useMemo or a useCallback to avoid infinite or useless rerenders.
export const useForm = <Data extends {}>(getBase: Data | (() => Promise<Data>), feedbackKey?: FeedbackKey): {
	displayed: Data;
	errorMessage?: TranslationFeedbackKey;
	isUpdated: boolean;
	loading: boolean;
	setErrorMessage: (errorMessage: FeedbackKey) => void;
	setUpdated: Dispatch<SetStateAction<Data>>;
	updated: Partial<Data>;
} => {
	const [loading, setLoading] = React.useState(isFunction(getBase));
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const [state, setState] = React.useState<Partial<State<Data>>>(isFunction(getBase)
		? {
			base: {} as Data,
			displayed: {} as Data,
			isUpdated: false,
			updated: {},
		}
		: {
			base: getBase,
			displayed: getBase,
			isUpdated: false,
			updated: {},
		});

	// Only update with different values (avoids updated without differences & useless leave warning messages)
	const customSetUpdated = React.useCallback(
		(setStateAction: SetStateAction<Data>): void => loading
			? Log.error()("Form data are not loaded yet")
			: setState((prev) => {
				const displayed = isFunction(setStateAction) ? setStateAction(prev.displayed!) : setStateAction;
				const updated: Partial<Data> = {};
				Object.entries(displayed ?? {}).forEach(([key, value]) => {
					if (!isSubset(prev?.base ?? {}, {[key]: value})) {
						updated[key] = value;
					}
				});
				return {
					...prev,
					displayed,
					isUpdated: getKeys(updated).length > 0,
					updated,
				};
			}),
		[loading],
	);

	const customSetErrorMessage = React.useCallback(
		(value: FeedbackKey): void => setErrorMessage(`feedbacks:${value}`),
		[],
	);

	React.useEffect(
		() => {
			if (isFunction(getBase)) {
				getBase()
					.then(base => setState(prev => ({...prev, base, displayed: base})))
					.catch((error) => {
						Log.error(feedbackKey)(error);
						setErrorMessage(feedbackKey ? `feedbacks:${feedbackKey}` : error);
					})
					.finally(() => setLoading(false));
			} else {
				setState(prev => ({...prev, base: getBase, displayed: getBase}));
			}
		},
		[feedbackKey, getBase],
	);
	return {
		displayed: state.displayed!,
		errorMessage,
		isUpdated: state.isUpdated!,
		loading,
		setErrorMessage: customSetErrorMessage,
		setUpdated: customSetUpdated,
		updated: state.updated!,
	};
};
