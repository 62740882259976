import {useNavigation} from "@react-navigation/native";
import React from "react";
import {StyleSheet, View} from "react-native";
import {InterpreterPreview} from "../../../@types/identities/interpreter";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {InboxContext} from "../../../contexts/inbox";
import {SMALL_SPACING} from "../../../utils/constants";
import {formatDate} from "../../../utils/date-time/format";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {BannerUpdate} from "../../banner/update";
import {InboxHeader} from "../../headers/inbox-header";
import {InfoBox} from "../../info-box";
import {NotificationsPermission} from "../../notifications-permission";
import {SectionTitle} from "./section-title";

export const Welcome = (): JSX.Element => {
	const {t} = useTranslation();
	const navigation = useNavigation();
	const {identity} = React.useContext(AuthentifiedContext);
	const {calendarUpdateReminder, removeCalendarUpdateReminder} = React.useContext(InboxContext);

	const goToCalendar = React.useCallback(
		() => navigation.navigate("HomeTabNavigator", {screen: "Calendar"}),
		[navigation],
	);

	return (
		<View style={styles.homeWrapper}>
			<InboxHeader/>
			<BannerUpdate/>
			<NotificationsPermission/>
			{calendarUpdateReminder && (
				<InfoBox
					title={t("screens:home.unavailibilitiesUpdateReminder.title")}
					subTitle={t("screens:home.unavailibilitiesUpdateReminder.subtitle")}
					buttons={[
						{
							key: "removeCalendarUpdateReminder",
							onPress: removeCalendarUpdateReminder,
							text: t("screens:home.unavailibilitiesUpdateReminder.yes"),
						},
						{
							key: "goToCalendar",
							onPress: goToCalendar,
							text: t("screens:home.unavailibilitiesUpdateReminder.no"),
						},
					]}
				/>
			)}
			<SectionTitle
				bigTitle
				subtitle={t("activities:calendar.today", {date: formatDate(new Date())})}
			>
				{t("screens:home.welcome", {firstName: (identity as InterpreterPreview).fullName.firstName})}
			</SectionTitle>
		</View>
	);
};

const styles = StyleSheet.create({
	homeWrapper: {
		paddingTop: SMALL_SPACING,
	},
});
