import * as React from "react";
import {PaymentAuthority} from "../../../../@types/payment-authority";
import {SessionCreationSetting} from "../../../../@types/settings";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

const EditPaymentAuthority = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"EditPaymentAuthorityModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {detailsStep: {paymentAuthority}} = {
			detailsStep: {paymentAuthority: {label: ct("common:paymentAuthority")}},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};
	const {selectionOrBase: itemParam, onSelect} = route.params ?? {};
	const base = React.useMemo(
		() => itemParam ?? {} as PaymentAuthority,
		[itemParam],
	);
	const {loading: loadingForm, displayed, isUpdated, setUpdated, errorMessage} = useForm(base);
	const {name} = displayed;

	const addOrUpdateTitle = itemParam
		? ct("forms:items.update", {item: paymentAuthority?.label ?? t("common:paymentAuthority")})
		: ct("forms:items.add", {item: paymentAuthority?.label ?? t("common:paymentAuthority")});

	const header = <HeaderMenu center={addOrUpdateTitle} exitConfirmation={isUpdated}/>;
	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}
	const inputs: FormProps["inputs"] = [
		{
			icon: "payment",
			label: paymentAuthority?.label ?? t("common:paymentAuthority"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (value: string) => setUpdated(prev => ({...prev, name: value})),
				value: name,
			},
		},
	];

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: addOrUpdateTitle},
					onValidation: () => {
						onSelect?.(displayed);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const EditPaymentAuthorityModal = (props: ClientStackNavigatorScreenProps<"EditPaymentAuthorityModal">): JSX.Element => (
	<ModalWrapper>
		<EditPaymentAuthority {...props} />
	</ModalWrapper>
);
