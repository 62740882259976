import * as React from "react";
import {CountdownCircleTimer} from "../../../../components/countdown-timer";
import {Icon} from "../../../../components/icons";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {sendFeedback} from "../../../../requests/clients/feedback";
import {computeDuration} from "../../../../utils/date-time/helpers";
import {RatingValue, saveFeedback} from "../../../../utils/feedbacks";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

interface FeedbackData {
	comments: string;
	rating: RatingValue;
}

const CLOSE_FEEDBACK_TIMEOUT = 10;

const Feedback = ({route, navigation}: ClientStackNavigatorScreenProps<"FeedbackModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {accountId} = React.useContext(AuthentifiedContext);
	const feedbackDone = React.useRef(false);
	// We need a ref so that we can call it without needing to have it as useEffect dependency
	const sendFeedbackRef = React.useRef<(dismissed: boolean) => Promise<void>>();
	const {action, onFeedbackDone, sessionId} = route.params;
	const startDate = React.useRef(new Date());

	const [elapsedTime, setElapsedTime] = React.useState(
		computeDuration(startDate.current, new Date(), "second"),
	);

	const getBase = React.useMemo(
		() => ({} as FeedbackData),
		[],
	);
	const {displayed, isUpdated, setUpdated} = useForm<FeedbackData>(getBase);
	const {rating, comments} = displayed;

	const onValidation = React.useCallback(
		() => sendFeedbackRef.current?.(false),
		[],
	);

	// Send feedback on unmount if it wasn't done already
	React.useEffect(
		() => () => {
			if (!feedbackDone.current) {
				sendFeedbackRef.current?.(true).catch(Log.error());
			}
		},
		[],
	);

	// Keep the sendFeedbackRef up to date so that it will have the latest values when called
	React.useEffect(
		() => {
			// Explanation on the behavior below: https://app.asana.com/0/1202590388485659/1203334585107746
			sendFeedbackRef.current = (dismissed: boolean): Promise<void> => sendFeedback(
				accountId!,
				{action, comments, dismissed, rating, sessionId},
			)
				.then(() => saveFeedback(action, accountId!))
				.then(() => {
					if (!dismissed) {
						onFeedbackDone?.();
						navigation.dispatch(forceBack);
					}
				})
				.catch(error => {
					if (dismissed) {
						Log.error()(error);
					} else {
						Log.error("sendDataFailed")(error);
					}
				})
				.finally(() => {
					feedbackDone.current = true;
					if (dismissed) {
						onFeedbackDone?.();
					}
				});
		},
		[action, accountId, comments, navigation, onFeedbackDone, rating, sessionId],
	);

	React.useEffect(
		() => {
			const interval = setInterval(() => {
				setElapsedTime(computeDuration(startDate.current, new Date(), "second"));
			}, 100);
			return () => clearInterval(interval);
		},
		[],
	);

	const close = React.useCallback(() => navigation.goBack(), [navigation]);

	React.useEffect(
		() => {
			if (!isUpdated && elapsedTime >= CLOSE_FEEDBACK_TIMEOUT) {
				close();
			}
		},
		[close, elapsedTime, isUpdated],
	);

	return (
		<>
			<HeaderMenu
				center={t("screens:feedback.header")}
				exitConfirmation={isUpdated}
				left={isUpdated
					? undefined
					: (
						<CountdownCircleTimer
							duration={CLOSE_FEEDBACK_TIMEOUT}
							elapsedTime={elapsedTime}
							size={30}
							strokeWidth={2}
							content={<Icon icon="close" onPress={close}/>}
						/>
					)}
			/>
			<Form
				title={t("screens:feedback.title", {action: t(`screens:feedback.actions.${action}`)})}
				hideReset
				requiredLabels
				validation={{buttonProps: {text: ct("common:send")}, onValidation}}
				inputs={[
					{
						icon: "ratingFull",
						label: t("screens:feedback.rating"),
						rules: [{rule: Rules.notEmpty, type: "error"}],
						type: {
							key: "rating",
							onChangeValue: (value: RatingValue) => setUpdated(prev => ({...prev, rating: value})),
							value: rating,
						},
					},
					{
						icon: "commentMoreOutline",
						label: t("screens:feedback.comments"),
						type: {
							key: "text",
							onChangeValue: (value: string) => setUpdated(prev => ({...prev, comments: value})),
							props: {multiline: true, placeholder: t("screens:feedback.commentsPlaceholder")},
							value: comments,
						},
					},
				]}
			/>
		</>
	);
};

export const FeedbackModal = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"FeedbackModal">): JSX.Element => (
	<ModalWrapper>
		<Feedback route={route} navigation={navigation}/>
	</ModalWrapper>
);
