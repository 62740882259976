import {Account} from "../../../../@types/accounts";
import {Session, SessionRefusal} from "../../../../@types/activities/session";
import {SessionRefusalSetting} from "../../../../@types/settings";
import {api} from "../../../../utils/network/api";
import {ApiResponse} from "../../../../utils/network/api-response";
import {
	AcceptedSession,
	InterpreterSessionRejectResponse,
	SessionRefusalSettingsResponse,
} from "../../../@types/session";

export type InterpreterSessionAcceptRequests = {
	POST: {
		pathParams: {
			interpreterId: string,
			sessionId: string,
		},
	},
};
export type InterpreterSessionRejectRequests = {
	PUT: {
		pathParams: {
			sessionId: string,
		},
		body: SessionRefusal,
	},
};

export const acceptSession = async (accountId: Account["accountId"], sessionId: Session["activityId"]): Promise<void> => {
	const response = await api(
		"interpreterSessionAccept",
		"POST",
		{
			pathParams: {
				sessionId,
				interpreterId: accountId,
			},
		},
	) as ApiResponse<AcceptedSession>;
	if (!response.isSuccess) {
		throw new Error(response.errorId || "sessionAlreadyAttributed");
	}
};

export const refuseSession = async (accountId: Account["accountId"], sessionId: Session["activityId"], refusal: SessionRefusal): Promise<void> => {
	const response = await api(
		"interpreterSessionReject",
		"PUT",
		{
			body: refusal,
			pathParams: {sessionId},
		},
	) as InterpreterSessionRejectResponse;
	if (!response.isSuccess) {
		throw new Error(response.interpreterMessage);
	}
};

export const getRefusalSettings = async (sessionId: Session["activityId"]): Promise<SessionRefusalSetting> => {
	const response = await api(
		"sessionRefusalSettings",
		"GET",
		{pathParams: {sessionId}},
	) as SessionRefusalSettingsResponse;
	if (!response.isSuccess) {
		throw new Error(response.interpreterMessage);
	}

	const {
		refuseDefaultInput,
		refuseDefaultMandatory,
		refuseDropDownInput,
		refuseDropDownMandatory,
		sessionRefuseReasons,
	} = response.data;

	return {
		context: "organization/interpreters",
		key: "session/refusal",
		owner: "",
		settingId: "sessionRefusal",
		value: {
			dropdown: refuseDropDownInput && sessionRefuseReasons?.length > 0
				? {
					values: sessionRefuseReasons.map(({id, reason}) => ({id: id.toString(), reason})),
					mandatory: refuseDropDownMandatory,
				}
				: null,
			textField: refuseDefaultInput
				? {mandatory: refuseDefaultMandatory}
				: null,
		},
	};
};
