import {ActivityType} from "../../../@types/activities/activity";
import {ActivitiesFilters} from "../../../@types/activities/filters";
import {ActivitiesStructure} from "../../../utils/date-time/activities";
import {nDaysInMonth} from "../../../utils/date-time/helpers";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {
	transformCommunicationTypeFrontToBack,
	transformLanguageISOCodeFrontToBack,
	transformSessionStatusFrontToBack,
} from "../../utils/transform";

export type UserActivitiesStructureRequests = {
	GET: {
		pathParams: {
			startDate: string,
			endDate: string,
		},
		queryParams: {
			timeZoneOffset: string,
			sessions?: string,
			toLanguage?: string,
			requester?: string,
			status?: string,
			type?: string,
			interpreterId: string,
			showCanceledSessions?: string,
			receiver?: string,
		},
	},
};

type BackendStructure = {[dayKey: string]: ActivityType[]};

export const getClientActivitiesStructure = async (
	from: Date,
	to: Date,
	filters: ActivitiesFilters,
): Promise<ActivitiesStructure> => {
	const fromTime = new Date(from.getFullYear(), from.getMonth(), from.getDate());
	const toTime = new Date(to.getFullYear(), to.getMonth(), nDaysInMonth(to), 23, 59, 59);
	const {id, toLanguage, communicationTypes, provider, requester, status, receiver} = filters;
	// const status = "CONFIRMED,MISSED,COMPLETED,INPROGRESS,STOPPED,CANCELLED,RESCHEDULED,PENDING,REFUSED";
	const requestData = {
		sessions: id || null,
		toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
		status: status && status.length > 0 ? (status.map(st => transformSessionStatusFrontToBack(st))).toString() : "",
		type: communicationTypes && communicationTypes.length > 0
			? transformCommunicationTypeFrontToBack(communicationTypes).toString()
			: "",
		requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
		timeZoneOffset: from.getTimezoneOffset().toString(),
		interpreterId: provider ? provider.identityId : "",
		receiver: receiver?.identityId || "",
		// showCanceledSessions: showCanceledSessions ? "true" : "false",
	};
	const interpreterResponse = await api(
		"userActivitiesStructure",
		"GET",
		{
			pathParams: {
				startDate: fromTime.getTime().toString(),
				endDate: toTime.getTime().toString(),
			},
			queryParams: requestData,
		},
	) as ApiResponse<BackendStructure>;
	if (interpreterResponse.isSuccess) {
		return interpreterResponse.data;
	}
	throw new Error(interpreterResponse.message);
};
