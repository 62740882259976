/* eslint-disable react-native/no-unused-styles */
// The styles are really used, even if ESLint thinks that it's not the case...!
import * as React from "react";
import {Platform, StyleProp, StyleSheet, Text as RNText, TextProps as RNTextProps, TextStyle} from "react-native";
import {CONTENT, PRIMARY_2, SUBTLE_4} from "../../utils/styles/colors";
import {OpenSans} from "./fonts";

const textTypes = [
	"label",
	"label_2",
	"touchable_label",
	"emphasis_1",
	"emphasis_2",
	"emphasis_3",
	"title",
	"title_2",
	"default_1",
	"default_2",
	"button_1",
	"button_2",
] as const;
export type TextType = typeof textTypes[number];

export interface TextProps extends Omit<RNTextProps, "style"> {
	centered?: boolean;
	color?: string;
	size?: number;
	style?: StyleProp<Omit<TextStyle, "color">>;
	type?: TextType;
}

const TextRenderer = (
	{type = "emphasis_2", style, color, size, children, centered, ...textProps}: TextProps,
	ref: React.Ref<RNText>,
): JSX.Element => {
	const defaultColor = color ?? (type === "default_1" || type === "default_2"
		? CONTENT
		: type === "label" || type === "label_2" ? SUBTLE_4 : PRIMARY_2);
	return (
		<RNText
			{...textProps}
			ref={ref}
			style={[
				Platform.select({android: textStyles.android, ios: textStyles.ios, web: textStyles.ios}),
				textStyles[type],
				{color: defaultColor},
				style,
				!!size && {fontSize: size},
				!!centered && {textAlign: "center"},
			]}
		>
			{children}
		</RNText>
	);
};

export const Text = React.forwardRef(TextRenderer);

export const textStyles = StyleSheet.create({
	android: {
		top: 0,
	},
	button_1: {
		fontFamily: OpenSans.Bold,
		fontSize: 15,
	},
	button_2: {
		fontFamily: OpenSans.Bold,
		fontSize: 13,
	},
	default_1: {
		fontFamily: OpenSans.Regular,
		fontSize: 15,
	},
	default_2: {
		fontFamily: OpenSans.Regular,
		fontSize: 13,
	},
	emphasis_1: {
		fontFamily: OpenSans.SemiBold,
		fontSize: 15,
	},
	emphasis_2: {
		fontFamily: OpenSans.SemiBold,
		fontSize: 13,
	},
	emphasis_3: {
		fontFamily: OpenSans.SemiBold,
		fontSize: 10,
	},
	ios: {top: -1},
	label: {
		fontFamily: OpenSans.Regular,
		fontSize: 12,
	},
	label_2: {
		fontFamily: OpenSans.SemiBold,
		fontSize: 12,
	},
	title: {
		fontFamily: OpenSans.Bold,
		fontSize: 22,
	},
	title_2: {
		fontFamily: OpenSans.Bold,
		fontSize: 18,
	},
	touchable_label: {
		fontFamily: OpenSans.Bold,
		fontSize: 12,
	},
});
