import RNAsyncStorage from "@react-native-async-storage/async-storage";
import * as ExpoSecureStore from "expo-secure-store";
import {IS_MOBILE} from "../../constants";
import {Log} from "../../logs/logs";

export const unsecureStorageKeys = [
	"AccountId",
	"BannerApps",
	"Feedbacks",
	"LastLogin",
	"Locale",
	"Sliders",
] as const;

type UnsecureStorageKeys = typeof unsecureStorageKeys[number];

export const secureStorageKeys = ["AuthToken"] as const;
type SecureStorageKeys = typeof secureStorageKeys[number];

type AsyncStorageKeys = SecureStorageKeys | UnsecureStorageKeys;

// Expo SecureStore is not available for web: https://docs.expo.dev/versions/latest/sdk/securestore/
export class AsyncStorage {
	public static getItem = (key: AsyncStorageKeys): Promise<string | null> =>
		(IS_MOBILE && secureStorageKeys.includes(key as SecureStorageKeys)
			? ExpoSecureStore.getItemAsync
			: RNAsyncStorage.getItem)(key)
			.catch(AsyncStorage.onError);

	public static getItems = (keys: AsyncStorageKeys[]): Promise<(string | null)[]> =>
		Promise.all(keys.map(key => AsyncStorage.getItem(key)));

	public static setItem = (key: AsyncStorageKeys, value: string): Promise<void> =>
		(IS_MOBILE && secureStorageKeys.includes(key as SecureStorageKeys)
			? ExpoSecureStore.setItemAsync
			: RNAsyncStorage.setItem)(key, value)
			.catch(AsyncStorage.onError);

	public static setItems = (entries: [AsyncStorageKeys, string][]): Promise<void[]> =>
		Promise.all(entries.map((entry) => AsyncStorage.setItem(entry[0], entry[1])));

	public static removeItem = (key: AsyncStorageKeys): Promise<void> =>
		(IS_MOBILE && secureStorageKeys.includes(key as SecureStorageKeys)
			? ExpoSecureStore.deleteItemAsync
			: RNAsyncStorage.removeItem)(key)
			.catch(AsyncStorage.onError);

	public static removeItems = (keys: AsyncStorageKeys[]): Promise<void[]> =>
		Promise.all(keys.map(key => AsyncStorage.removeItem(key)));

	public static removeAllItems = (): Promise<void[]> =>
		Promise.all(
			[...unsecureStorageKeys, ...secureStorageKeys].map(key => AsyncStorage.removeItem(key as AsyncStorageKeys)));

	private static readonly onError = (error: Error): never => {
		Log.error()(error);
		throw error;
	};
}
