import {addTime, isTimeBefore, TimeUnit} from "../../date-time/helpers";
import {t} from "../../locales/translations";
import {EMAIL_VALIDATION, PHONE_VALIDATION, TIME_VALIDATION, URL_REGEX} from "./regex";

/*
 * A rule is a constraint on a string for it to be considered valid
 * Should return a resolved promise if valid, otherwise a rejected one with a descriptive text explaining why.
 */
export interface Rule {
	id: string;
	validate: (value: string) => Result;
}
type Result = Promise<boolean>;

// Predefined rules
export const Rules = {
	atLeast: {
		// Checks whether the value contains at least one digit
		oneDigit: {
			id: "oneDigit",
			validate: (val: string): Result =>
				/(?=.*\d)\d+/.test(val)
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.atLeastOneDigit")),
		},

		// Checks whether the value contains at least one letter
		oneLetter: {
			id: "oneLetter",
			validate: (val: string): Result =>
				/(?=.*[A-Za-z])[A-Za-z]+/.test(val)
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.atLeastOneLetter")),
		},

		// Checks whether the value contains at least one special character
		oneSpecialLetter: {
			id: "oneSpecialLetter",
			validate: (val: string): Result =>
				/[^\dA-Za-z]/.test(val)
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.atLeastOneSpecialLetter")),
		},
	},

	// Checks whether the value is between min and max. The check is inclusive
	between: (min: number, max: number): Rule => ({
		id: "between",
		validate: (val: string): Result =>
			min <= val.length && val.length <= max
				? Promise.resolve(true)
				: Promise.reject(t("forms:rules.between", {max, min})),
	}),

	dateAfter: (date?: Date, errorMessage?: string): Rule => ({
		id: "dateAfter",
		validate: (val: string): Result =>
			!!date && new Date(val) < addTime(date, 1, "minute")
				? Promise.reject(errorMessage)
				: Promise.resolve(true),
	}),

	dateBefore: (date?: Date, errorMessage?: string): Rule => ({
		id: "dateBefore",
		validate: (val: string): Result =>
			!!date && date < addTime(new Date(val), 1, "minute")
				? Promise.reject(errorMessage)
				: Promise.resolve(true),
	}),

	durationGreaterOrEqualTo: (
		date: Date,
		minimumDuration: number,
		durationUnit: TimeUnit,
	): Rule => ({
		id: "durationGreaterOrEqualTo",
		validate: (val: string): Result =>
			new Date(val) < addTime(date, minimumDuration, durationUnit)
				? Promise.reject(t("forms:rules.durationGreaterOrEqualTo", {
					durationUnit: t(`common:${durationUnit}${minimumDuration > 1 ? "_plural" : ""}`), minimumDuration,
				}))
				: Promise.resolve(true),
	}),

	equalTo: (otherVal: string, otherLabel: string): Rule => ({
		id: "equalTo",
		validate: (val: string): Result =>
			val === otherVal
				? Promise.resolve(true)
				: Promise.reject(t("forms:rules.equalTo", {otherLabel})),
	}),

	// Checks whether the value is equal or smaller that the max length
	max: (max: number): Rule => ({
		id: "max",
		validate: (val: string): Result =>
			val && val.length <= max
				? Promise.resolve(true)
				: Promise.reject(t("forms:rules.max", {max})),
	}),

	// Checks whether the value is equal or bigger that the min length
	min: (min: number): Rule => ({
		id: "min",
		validate: (val: string): Result =>
			val && val.length >= min
				? Promise.resolve(true)
				: Promise.reject(t("forms:rules.min", {min})),
	}),

	// Checks whether the value is different from the initialValue
	mustChange: (initialVal: string): Rule => ({
		id: "mustChange",
		validate: (val: string): Result =>
			initialVal === val
				? Promise.reject(t("forms:rules.mustChange"))
				: Promise.resolve(true),
	}),

	// Checks whether the value is not empty
	notEmpty: {
		id: "notEmpty",
		validate: (val: string): Result =>
			val && val.length > 0 && val !== "[]"
				? Promise.resolve(true)
				: Promise.reject(t("forms:rules.notEmpty")),
	},

	// Checks whether the value contains only digits
	onlyDigits: {
		id: "onlyDigits",
		validate: (val: string): Result => /^\d+$/.test(val)
			? Promise.resolve(true)
			: Promise.reject(t("forms:rules.onlyDigits")),
	},

	timeAfter: (date?: Date, errorMessage?: string): Rule => ({
		id: "timeAfter",
		validate: (val: string): Result =>
			!!date && isTimeBefore(new Date(val), date)
				? Promise.reject(errorMessage)
				: Promise.resolve(true),
	}),

	timeBefore: (date?: Date, errorMessage?: string): Rule => ({
		id: "timeBefore",
		validate: (val: string): Result =>
			!!date && isTimeBefore(date, new Date(val))
				? Promise.reject(errorMessage)
				: Promise.resolve(true),
	}),

	validation: {
		// Checks whether the email is valid
		email: {
			id: "email",
			validate: (val: string): Result =>
				EMAIL_VALIDATION.test(val)
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.email")),
		},
		// Check if the value is a valid phone number with at least 5 digits and a country code (with a +)
		phone: {
			id: "phone",
			validate: (val: string): Result =>
				PHONE_VALIDATION.test(val)
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.phone")),
		},
		// Checks if the value is a valid time (HH:MM)(14:32)
		time: {
			id: "time",
			validate: (val: string): Result =>
				TIME_VALIDATION.test(val) ? Promise.resolve(true) : Promise.reject(t("forms:rules.time")),
		},

		// Checks whether the url is valid. If strict is set, an empty url is not valid
		url: (strict = false): Rule => ({
			id: "url",
			validate: (val: string) =>
				((!strict && val.length === 0) || (URL_REGEX).test(val))
					? Promise.resolve(true)
					: Promise.reject(t("forms:rules.url")),
		}),
	},
};
