import * as React from "react";
import {StyleSheet} from "react-native";
import {LanguageTranslation} from "../../@types/language-translation";
import {useTranslation} from "../../utils/hooks/use-translation";
import {PRIMARY_2, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Text} from "../texts/text";
import {PressableProps} from "../views/pressable";
import {Label, LabelProps} from "./label";

export const TranslationLabel = ({
	from, to, disabled, style, size, allowFontScaling = true, ...touchableProps
}: LanguageTranslation & Pick<LabelProps, "allowFontScaling" | "size"> & PressableProps): JSX.Element => {
	const {t} = useTranslation();
	return (
		<Label
			backgroundColor={WHITE}
			{...touchableProps}
			style={[styles.container, {borderWidth: size === "small" ? 1 : 2}, style]}
			size={size}
			allowFontScaling={allowFontScaling}
		>
			<>
				<Text color={disabled ? SUBTLE_4 : PRIMARY_2} allowFontScaling={allowFontScaling}>
					{t(`languages:${from}`)}
				</Text>
				<Text color={SUBTLE_4} allowFontScaling={allowFontScaling}> · </Text>
				<Text color={disabled ? SUBTLE_4 : PRIMARY_2} allowFontScaling={allowFontScaling}>
					{t(`languages:${to}`)}
				</Text>
			</>
		</Label>
	);
};

const styles = StyleSheet.create({
	container: {
		borderColor: SUBTLE_4,
	},
});
