import * as React from "react";
import {ImageBackground as RNImage, LayoutChangeEvent, StyleSheet, View} from "react-native";
import {Base64ImageData, ImageProps, ImageMime, URL} from "../../@types/medias/image";
import {IMAGE_PLACEHOLDER} from "../../utils/styles/colors";

export const base64URI = (mime: ImageMime, data: Base64ImageData): string => `data:${mime};base64,${data}`;

export const Image = ({
	source, width, height, fullWidth, fullHeight, style, resizeMode = "cover", mime, sourceType: type,
}: Omit<ImageProps, "mediaType">): JSX.Element => {
	const [loaded, setLoaded] = React.useState(false);
	const [scale, setScale] = React.useState(1);
	const scaledWidth = width * scale;
	const scaledHeigth = height * scale;
	const onLoad = React.useCallback(
		() => setLoaded(true),
		[],
	);
	const sourceProp = React.useMemo(
		() => type === "require"
			? source as number
			: type === "base64" && mime
				? {uri: base64URI(mime, source as Base64ImageData)}
				: {uri: source as URL},
		[mime, source, type],
	);
	return (
		<RNImage
			onLoad={onLoad}
			onLayout={({nativeEvent: {layout}}: LayoutChangeEvent) =>
				layout.width > 0 && setScale(layout.width / width)}
			resizeMode={resizeMode}
			style={[
				{
					height: fullHeight ? "100%" : scaledHeigth,
					overflow: "hidden",
					width: fullWidth ? "100%" : scaledWidth,
				},
				style,
			]}
			source={sourceProp}
		>
			{!loaded && (
				<View
					style={[
						styles.loading,
						{borderRadius: width > height ? scaledWidth / 2 : scaledHeigth / 2},
					]}
				/>
			)}
		</RNImage>
	);
};

const styles = StyleSheet.create({
	loading: {
		backgroundColor: IMAGE_PLACEHOLDER,
		height: "100%",
		width: "100%",
	},
});
