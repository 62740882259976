import * as React from "react";
import {View} from "react-native";
import {CancellationTimeIssue} from "../../../../@types/validation";
import {formatDateTime} from "../../../../utils/date-time/format";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SummaryRow} from "../../../inputs/summary-row";
import {DescriptionSummary} from "./description";

export const CancellationTimeSummary = (
	{issue: {description, cancellationTime}, t}: {issue: CancellationTimeIssue; t: TranslationFunc},
): JSX.Element => (
	<View>
		<SummaryRow>
			{formatDateTime(cancellationTime)}
		</SummaryRow>
		{!!description && <DescriptionSummary description={description} t={t} />}
	</View>
);
