import * as React from "react";
import {StyleSheet, View} from "react-native";
import {CALENDAR_HEADER_HEIGHT, EXTRA_LARGE_SPACING, WEEK_HEIGHT} from "../../utils/constants";
import {Month as MonthProps, OnDatePress} from "../../utils/date-time/activities";
import {months} from "../../utils/date-time/helpers";
import {useTranslation} from "../../utils/hooks/use-translation";
import {SUBTLE_4} from "../../utils/styles/colors";
import {Text} from "../texts/text";
import {DaysRow} from "./days-row";
import {Month} from "./month";

interface Props {
	focusDate?: Date;
	month: MonthProps;
	onDayPress: OnDatePress;
}

export const MonthCalendar = ({month, onDayPress, focusDate}: Props): JSX.Element => {
	const {ct} = useTranslation();
	return (
		<>
			<View style={styles.sectionHeader}>
				<Text type="title_2" style={styles.sectionHeaderTitle}>
					{ct(`activities:calendar.month.${months[month[1][0].day.getMonth()]}`)}
					<Text type="emphasis_1" color={SUBTLE_4}> {month[1][0].day.getFullYear()}</Text>
				</Text>
				<DaysRow />
			</View>
			<Month
				month={month}
				onDayPress={onDayPress}
				focusDate={focusDate}
			/>
		</>
	);
};

export const MonthCalendarPlaceholder = ({month}: Pick<Props, "month">): JSX.Element => {
	const {ct} = useTranslation();
	return (
		<>
			<View style={styles.sectionHeader}>
				<Text type="title_2" style={styles.sectionHeaderTitle}>
					{ct(`activities:calendar.month.${months[month[1][0].day.getMonth()]}`)}
					<Text type="title_2" color={SUBTLE_4}>{month[1][0].day.getFullYear()}</Text>
				</Text>
			</View>
			<View style={[styles.placeholderWrapper, {height: WEEK_HEIGHT * month.length}]} />
		</>
	);
};

const styles = StyleSheet.create({
	placeholderWrapper: {
		alignItems: "center",
		justifyContent: "center",
	},
	sectionHeader: {
		alignItems: "center",
		height: CALENDAR_HEADER_HEIGHT,
		paddingTop: EXTRA_LARGE_SPACING,
	},
	sectionHeaderTitle: {
		marginBottom: 4,
	},
});
