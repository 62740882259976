import * as React from "react";
import {Keyboard, StyleSheet} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {ImagePicker, ImagePickerOptions, PickerImage} from "../../../utils/inputs/pickers/image-picker";
import {Log} from "../../../utils/logs/logs";
import {PLACEHOLDER} from "../../../utils/styles/colors";
import {Image} from "../../images/image";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";

export interface ImageListProps {
	key: "image";
	onChangeValue?: (value: PickerImage) => void;
	options?: ImagePickerOptions;
	props?: Omit<PressableProps, "onPress">;
	value?: PickerImage;
}

type ImageInputProps = Omit<ImageListProps, "key"> & {
	disabled?: boolean;
	hasIcon?: boolean;
	hasLabel?: boolean;
};

export const ImageInput = ({
	onChangeValue,
	value,
	props,
	options,
	disabled,
	hasLabel,
	hasIcon,
}: ImageInputProps): JSX.Element => {
	const {ct} = useTranslation();
	const onPressImage = React.useCallback(
		() => {
			if (onChangeValue) {
				Keyboard.dismiss();
				ImagePicker.pick({
					cropping: true,
					from: "both",
					...options,
				})
					.then(picked => picked && onChangeValue(picked))
					.catch(Log.error("imagePickerFailed"));
			}
		},
		[onChangeValue, options],
	);
	return (
		<Pressable
			hitSlop={{
				bottom: SMALL_SPACING,
				left: DEFAULT_SPACING + (hasIcon ? (24 + DEFAULT_SPACING) : 0),
				right: DEFAULT_SPACING,
				top: SMALL_SPACING + (hasLabel ? 10 : 0),
			}}
			{...props}
			style={[
				styles.renderValueWrapper,
				props?.style,
			]}
			disabled={disabled}
			onPress={onPressImage}
		>
			{value
				? (<Image
					source={value.source}
					fullHeight={value.fullHeight}
					mime={value.mime}
					sourceType={value.sourceType}
					width={value.width}
					height={value.height}
					resizeMode="contain"
					style={styles.contentImageContainer}
					fullWidth
				/>)
				: <Text color={PLACEHOLDER} type="emphasis_1">{`${ct("common:select")}...`}</Text>}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	contentImageContainer: {
		borderRadius: SMALL_SPACING,
		marginTop: 6,
	},
	renderValueWrapper: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});
