import {Account} from "../../../@types/accounts";
import {OtherProfessional, OtherProfessionalPreview} from "../../../@types/identities/professional";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {UserAccountPerson} from "../../@types/user";
import {
	transformOtherProfessionalBackToFront,
	transformOtherProfessionalFrontToBack,
} from "../../utils/transform-person";

export const getClientProfessionals = (
	accountId: Account["accountId"], pagination: Pagination): Promise<Page<OtherProfessionalPreview>> =>
	searchClientProfessionals(accountId, "", pagination);

export const addClientProfessional = async (
	accountId: Account["accountId"], added: OtherProfessional): Promise<OtherProfessional> => {
	const userResponse = await api(
		"userPersonAdd",
		"POST",
		{
			body: {
				userAccount: {id: accountId},
				user: {
					...transformOtherProfessionalFrontToBack(added),
					role: "OTHER_PROFESSIONAL",
				},
			},
		},
	) as ApiResponse<{}>; // TODO (Add Function and Direct telephone or mobile field)
	return userResponse.isSuccess
		? Promise.resolve(transformOtherProfessionalBackToFront(userResponse.data))
		: Promise.reject(new Error(userResponse.message));
};

export const updateClientProfessional = async (
	accountId: Account["accountId"], updated: OtherProfessional): Promise<OtherProfessional> => {
	const {identityId} = updated;
	const userResponse = await api(
		"userPersonUpdate",
		"PUT",
		{
			pathParams: {userId: identityId},
			body: {
				userAccount: {id: accountId},
				user: {
					id: identityId,
					...transformOtherProfessionalFrontToBack(updated),
					role: "OTHER_PROFESSIONAL",
				},
			},
		},
	) as ApiResponse<{}>; // TODO (Add Function and Direct telephone or mobile field)
	if (userResponse.isSuccess) {
		return updated;
	}
	throw new Error(userResponse.message);
};

export const deleteClientProfessional = async (deleted: OtherProfessionalPreview,
): Promise<void> => {
	const userResponse = await api(
		"userPersonDelete",
		"DELETE",
		{
			pathParams: {
				userAccountUserId: deleted.identityId,
			},
		},
	) as ApiResponse<{}>;
	if (!userResponse.isSuccess) {
		throw new Error(userResponse.message);
	}
};

export const searchClientProfessionals = async (accountId: Account["accountId"], search: string, pagination: Pagination):
Promise<Page<OtherProfessionalPreview>> => {
	const {offset, limit} = pagination;
	const userResponse = await api(
		"userPersonsGet",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				role: "other",
				searchText: search,
				limit: limit.toString(),
				offset: (offset + 1).toString(),
			},
		},
	) as ApiResponse<{totalUsers: number, users: UserAccountPerson[]}>;
	if (userResponse.isSuccess) {
		const {users, totalUsers} = userResponse.data;
		const userList = users.map(other => transformOtherProfessionalBackToFront(other));
		return {
			items: userList,
			hasPreviousPage: offset > 0,
			hasNextPage: totalUsers > offset + limit,
			totalCount: totalUsers,
		};
	}
	throw new Error(userResponse.message);
};
