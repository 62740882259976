import {Account} from "../../../@types/accounts";
import {BillingAddress} from "../../../@types/billing-address";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {
	BackendBillingAddress,
	transformBillingAddressBackToFront,
	transformBillingAddressFrontToBack,
} from "../../utils/transform-billing-addresses";

export type BillingAddressAddRequest = {
	POST: {
		body: BackendBillingAddress,
	}
};

export type BillingAddressUpdateRequest = {
	PUT: {
		body: BackendBillingAddress,
		pathParams: {
			id: string,
		},
	}
};

export type BillingAddressesRequest = {
	GET: {},
};

export type BillingAddressDeleteRequest = {
	DELETE: {
		pathParams: {
			id: string,
		},
	},
};

export const getBillingAddresses = async (accountId: Account["accountId"], pagination: Pagination, search?: string): Promise<Page<BillingAddress>> => {
	const {offset, limit} = pagination;
	const response = await api(
		"billingAddresses",
		"GET",
		{
			pathParams: {
				userAccountId: accountId.toString(),
			},
			queryParams: {
				limit: limit.toString(),
				offset: (offset + 1).toString(),
				...(search ? {searchText: search} : {}),
			},
		},
	) as ApiResponse<{total: number, userBillingAccounts: BackendBillingAddress[]}>;
	if (response.isSuccess) {
		return {
			items: response.data.userBillingAccounts.map((element) => transformBillingAddressBackToFront(element)),
			hasPreviousPage: offset > 0,
			hasNextPage: response.data.total > offset,
			totalCount: response.data.total,
		};
	}
	throw new Error(response.message);
};

export const addBillingAddress = async (accountId: Account["accountId"], added: BillingAddress): Promise<BillingAddress> => {
	const response = await api(
		"billingAddressAdd",
		"POST",
		{
			body: transformBillingAddressFrontToBack(added, accountId),
		},
	) as ApiResponse<BackendBillingAddress>;

	return response.isSuccess
		? Promise.resolve(transformBillingAddressBackToFront(response?.data))
		: Promise.reject(new Error(response?.message));
};

export const updateBillingAddress = async (accountId: Account["accountId"], updated: BillingAddress): Promise<BillingAddress> => {
	const response = await api(
		"billingAddressUpdate",
		"PUT",
		{
			pathParams: {
				id: updated.id,
			},
			body: transformBillingAddressFrontToBack(updated, accountId),
		},
	) as ApiResponse<BackendBillingAddress>;

	return response.isSuccess
		? Promise.resolve(transformBillingAddressBackToFront(response?.data))
		: Promise.reject(new Error(response?.message));
};

export const deleteBillingAddress = async (_accountId: Account["accountId"], deleted: BillingAddress): Promise<void> => {
	const response = await api(
		"billingAddressDelete",
		"DELETE",
		{
			pathParams: {
				id: deleted.id,
			},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? Promise.resolve() : Promise.reject(new Error(response.message));
};
