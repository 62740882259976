export const feedbacks = {
	APICallFailed: "Error while fetching data",
	acceptSessionFailed: "Error while accepting the session",
	acceptSessionSuccess: "Session accepted",
	calendarAccessNotAuthorized: "Access to the local calendar is not allowed. Grant access to local calendar in your device's settings.",
	calendarSynchronizationFailed: "Error while synchronizing sessions with your local calendar",
	calendarSynchronizationSuccess: "Your sessions for the next 90 days have been successfully synchronized with your local calendar",
	cancelSessionFailed: "Error while cancelling session",
	cancelSessionSuccess: "The session has been canceled",
	changePasswordSuccess: "Your password has been successfully changed",
	connectionLost: "Network connection lost",
	connectionRestored: "Network connection restored",
	copyAddressSuccess: "The destination address has been copied in the clipboard",
	copyProfileSuccess: "The profile has been copied in the clipboard",
	copySupportNumberSuccess: "The support number has been copied to the clipboard.",
	createDataFailed: "Error while creating item",
	createDataSuccess: "Item successfully created",
	createFollowUpFailed: "Error while creating follow up",
	createSessionFailed: "Error while creating the session",
	createUnavailabilityFailed: "Error while creating an unavailability",
	createUnavailabilitySuccess: "Unavailability created",
	deleteDataFailed: "Error while deleting item",
	deleteDataSuccess: "Item successfully deleted",
	deleteUnavailabilityFailed: "Error while deleting an unavailability",
	deleteUnavailabilitySuccess: "Unavailability deleted",
	dependantDataError: "The data dependant to this session has been modified/removed",
	emailSuccess: "Email sent successfully",
	geocodeFailed: "Error while fetching the map",
	getCalendarDataFailed: "Error while getting calendar data",
	getDataFailed: "Error while getting data",
	getFollowUpParamsFailed: "Error while fetching follow-up data",
	getInterpreterAddressesFailed: "Error while fetching interpreter addresses",
	getInterpreterFailed: "Error while fetching interpreter data",
	getInterpreterProfileEditRights: "Error while fetching interpreter's profile editting rights",
	getInterpreterSalaryFailed: "Error while fetching interpreter's salaries information",
	getInterpreterSettingsFailed: "Error while fetching interpreter's settings",
	getMinimumDurationFailed: "Error while fetching follow-up minimum duration's setting",
	getPlaceSuggestions: "Error while fetching address suggestions",
	getSessionDetailsFailed: "Error while fetching sessions details",
	getSupportInfosFailed: "Error while fetching support infos",
	getUnavailabilityFailed: "Error while fetching an unavailability",
	getUserAddressesFailed: "Error while fetching user addresses",
	getUserFailed: "Error while fetching user data",
	getUserSettingsFailed: "Error while fetching user's settings",
	hourLimitExceeded: "Your organisation exceeded the booking's duration limit",
	imagePickerFailed: "Error while selecting picture",
	loginFailed: "Incorrect login or password combination",
	logoutFailed: "Error while signing out",
	messageSentFailed: "Message could not be sent",
	messageSentSuccess: "Message sent successfully",
	notificationsPermissionsDisabled: "Push notifications failed to enable, you can still enable them in your phone settings",
	notificationsPermissionsEnabled: "Push notifications enabled",
	refuseSessionFailed: "Error while refusing the session",
	refuseSessionSuccess: "The session has been refused",
	sendDataFailed: "Error while sending the data",
	serverDownError: "A server update is in progress. Please try again later",
	sessionAlreadyAttributed: "This session has already been attributed to someone else",
	sessionAlreadyValidated: "Your administrator has already validated this session",
	sessionCreationSuccess: "The session has been successfully created",
	sessionStatusChangedConfirmed: "The session #{{id}} was accepted by {{interpreter}}.",
	sessionStatusChangedRefused: "The session #{{id}} was refused by all interpreters.",
	unavailableTimeslot: "You are not available at this timeslot",
	updateDataFailed: "Error while updating item",
	updateDataSuccess: "Item successfully updated",
	updateInterpreterFailed: "Error while updating interpreter data",
	updateInterpreterPictureFailed: "Error while updating interpreter profile picture",
	updateInterpreterProfileSuccess: "Your profile has been updated",
	updateSessionValidationFailed: "Error while validating the session",
	updateSessionValidationSuccess: "The session has been validated",
	updateSettingsFailed: "Error while updating interpreter settings",
	updateSettingsSuccess: "Settings updated",
	updateUnavailabilityFailed: "Error while updating an unavailability",
	updateUnavailabilitySuccess: "Unavailability updated",
	updateUserAddressFailed: "Error while updating your saved addresses",
	updateUserAddressSuccess: "Your saved addresses have been updated",
	uploadSessionValidationPictureFailed: "Error while uploading the validation picture",
	videoCallCameraUsed: "Another application is using your webcam. Because of privacy reasons, you need to close it first and reload this page.",
	videoCallExpired: "This video call has expired",
	videoCallInitFailed: "Error while preparing the video call",
	videoCallJoinFailed: "Error while connecting to video call",
	videoCallNoCamera: "No webcam detected. Do you have provided permission?",
	videoCallNoMicro: "No microphone detected. Do you have provided permission?",
};
