import * as DeviceInfo from "expo-device";
import * as DeviceDetect from "react-device-detect";
import {Platform} from "react-native";
import {API_HOST, APP, BUNDLE_BUILD, BUNDLE_IDENTIFIER, BUNDLE_VERSION, ENV, HTTP_PROTOCOL} from "./constants";

export const infos = {
	"api.URL": `${HTTP_PROTOCOL}://${API_HOST}`,
	app: APP,
	"build.number": BUNDLE_BUILD,
	"bundle.ID": BUNDLE_IDENTIFIER,
	"bundle.version": BUNDLE_VERSION,
	development: __DEV__ ? "true" : "false",
	"device.brand": DeviceInfo.brand,
	"device.manufacturer": DeviceInfo.manufacturer ?? DeviceDetect.osName,
	"device.model": DeviceInfo.modelName ?? DeviceDetect.browserName,
	"device.modelId": DeviceInfo.modelId ?? DeviceDetect.browserVersion,
	"device.name": DeviceInfo.deviceName ?? DeviceDetect.engineName,
	environment: ENV,
	"os.name": DeviceInfo.osName,
	"os.version": DeviceInfo.osVersion,
	platform: Platform.OS,
};
