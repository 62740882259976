import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {AuthentifiedContext} from "../contexts/authentified";
import {IS_IOS} from "../utils/constants";
import {useTranslation} from "../utils/hooks/use-translation";
import {InfoBox} from "./info-box";

export const NotificationsPermission = (): JSX.Element | null => {
	const {notificationsStatus, requestNotificationsPermissions} = React.useContext(AuthentifiedContext);
	const navigation = useNavigation();
	const [anotherDeviceReminder, setAnotherDeviceReminder] = React.useState(true);
	const {ct, t} = useTranslation();

	const closeAnotherDeviceReminder = React.useCallback(
		() => setAnotherDeviceReminder(false),
		[],
	);

	const onConfigureNotificationsPress = React.useCallback(
		() => navigation.navigate("NotificationsModal"),
		[navigation],
	);

	return notificationsStatus === "denied" && IS_IOS
		? (
			<InfoBox
				subTitle={t("screens:notifications.permissions.subtitle")}
				buttons={[
					{
						key: "requestPermission",
						onPress: requestNotificationsPermissions,
						text: t("screens:notifications.permissions.enable"),
					},
				]}
			/>
		)
		: notificationsStatus === "anotherDevice" && anotherDeviceReminder
			? (
				<InfoBox
					subTitle={t("screens:notifications.anotherDevice")}
					buttons={[
						{
							key: "closeAnotherDeviceReminder",
							onPress: closeAnotherDeviceReminder,
							text: ct("common:close"),
						},
						{
							key: "onConfigureNotificationsPress",
							onPress: onConfigureNotificationsPress,
							text: t("screens:notifications.configure"),
						},
					]}
				/>
			)
			: null;
};
