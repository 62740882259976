import * as React from "react";
import {LayoutChangeEvent, StyleSheet, View} from "react-native";
import {StackParamList} from "../../../utils/navigation/paramLists/root-param-list";
import {searchIndexOf} from "../../../utils/searches";
import {SUBTLE_2} from "../../../utils/styles/colors";
import {ListElement} from "../../list/items/list-element";
import {Item, ListPropsCommon, OnlyKeysExtendingSelectListType} from "../../list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../list/select-flatlist";
import {OpenSans} from "../../texts/fonts";
import {Text} from "../../texts/text";
import {TextInputProps as BaseTextInputProps} from "../../texts/text-input";

export type TextInputDropdownSuggestionListProps<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}> & TextInputDropdownSuggestionItem,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> = ListPropsCommon<IdKey, I, RouteName> & {
	key: "textSuggestions";
	onSearch: ListPropsCommon<IdKey, I, RouteName>["onSearch"];
	props?: Omit<BaseTextInputProps, "onChangeText" | "style">;
	style?: BaseTextInputProps["style"];
};

export type TextInputDropdownSuggestionItem = Item<"id", {displayed: string; id: string}>;

export const TextInputDropdownSuggestion = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}> & TextInputDropdownSuggestionItem,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
>
(props: TextInputDropdownSuggestionListProps<IdKey, I, RouteName>): JSX.Element => {
	const {getRequest, onSearch, onPressAdd, itemTranslationKey, idKey} = props;
	const [dropdownOpened, setDropdownOpened] = React.useState(false);
	const [width, setWidth] = React.useState<number>(0);
	const viewRef = React.useRef<View>(null);

	const onLayout = React.useCallback(
		({nativeEvent: {layout: {width}}}: LayoutChangeEvent) => {
			setWidth(width);
		},
		[],
	);

	const onPress = React.useCallback(
		(item: I) => () => {
			if (onSearch) {
				onSearch.onChangeSearch?.(item.displayed);
				setDropdownOpened(false);
			}
		},
		[onSearch],
	);

	const renderItem = React.useCallback(
		({info: {item}, buttons, currentSearch, focusedStyle}: FlatListRenderItemParams<IdKey, I>) => {
			const searchIndex = currentSearch ? searchIndexOf(currentSearch, item.displayed, true) : -1;
			return (
				<ListElement
					onPress={onPress(item)}
					buttons={buttons}
					style={[focusedStyle, onSearch?.value === item.displayed && {backgroundColor: SUBTLE_2}]}
				>
					{currentSearch && currentSearch.length > 0 && searchIndex > -1
						? (
							<Text>
								<Text type="emphasis_1" style={styles.regular}>
									{item.displayed.slice(0, searchIndex)}
								</Text>
								<Text type="emphasis_1" style={styles.bold}>
									{item.displayed.slice(searchIndex, searchIndex + currentSearch.length)}
								</Text>
								<Text type="emphasis_1" style={styles.regular}>
									{item.displayed.slice(searchIndex + currentSearch.length)}
								</Text>
							</Text>
						)
						: (
							<Text type="emphasis_1">
								{item.displayed}
							</Text>
						)}
				</ListElement>
			);
		},
		[onPress, onSearch?.value],
	);

	return (
		<View
			onLayout={onLayout}
			ref={viewRef}
		>
			<SelectFlatList
				getRequest={getRequest}
				onSearch={onSearch}
				onPressAdd={onPressAdd}
				itemTranslationKey={itemTranslationKey!}
				onPressEnter={onPress}
				idKey={idKey}
				renderItem={renderItem}
				dropDown={{
					closeIfNoResults: true,
					disableInput: false,
					dropdownOpened,
					inlineSearch: true,
					setDropdownOpened,
					trigger: viewRef,
					width,
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	bold: {
		fontFamily: OpenSans.Bold,
	},
	regular: {
		fontFamily: OpenSans.Regular,
	},
});
