import {Account, Organization} from "../../../../@types/accounts";

export const accountExamples: Account[] = [
	{
		accountId: "exampleInterpreter",
		login: "exampleInterpreter",
		password: "1234",
	}, {
		accountId: "exampleCorporation",
		login: "exampleCorporation",
		password: "1234",
	},
];

export const organizationExamples: Organization[] = [
	{
		organizationId: "organizationExample",
	},
];
