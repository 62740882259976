import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {modalScreenOptions} from "../../../components/views/modal-wrapper";
import {ClientAuthentifiedScreensGroupParamList} from "../../../utils/navigation/paramLists/client-param-list";
import {AddressesListModal} from "../../screens/client/modals/address-list";
import {CancelOrRescheduleSessionModal} from "../../screens/client/modals/cancel-reschedule-session";
import {EditBillingAddressModal} from "../../screens/client/modals/edit-billing-address";
import {EditCorporationModal} from "../../screens/client/modals/edit-corporation";
import {EditPaymentAuthorityModal} from "../../screens/client/modals/edit-payment-authority";
import {EditPersonModal} from "../../screens/client/modals/edit-person";
import {EditSessionCategoryModal} from "../../screens/client/modals/edit-session-category";
import {EditSubjectSuggestionModal} from "../../screens/client/modals/edit-subject-suggestion";
import {FeedbackModal} from "../../screens/client/modals/feedback";
import {InterpreterModal} from "../../screens/client/modals/interpreter";
import {SearchInterpretersModal} from "../../screens/client/modals/search-interpreters";
import {SelectBillingAddressModal} from "../../screens/client/modals/select-billing-address";
import {SelectDurationModal} from "../../screens/client/modals/select-duration";
import {SelectPaymentAuthorityModal} from "../../screens/client/modals/select-payment-authority";
import {SelectSessionCategoryModal} from "../../screens/client/modals/select-session-category";
import {SelectSubjectSuggestionModal} from "../../screens/client/modals/select-subject-suggestion";
import {SessionCreationFiltersModal} from "../../screens/client/modals/session-creation-filters";
import {SharedScreensGroup} from "../shared-screens-group";
import {HomeTabNavigator} from "./home-tab-navigator";

const Stack = createNativeStackNavigator<ClientAuthentifiedScreensGroupParamList>();

export const AuthentifiedScreensGroup = (
	<Stack.Group screenOptions={modalScreenOptions}>
		{/* CAREFUL THE ORDER OF THE SCREENS IMPACTS THE NAVIGATION!!! FIRST SCREEN = DEFAULT SCREEN */}
		<Stack.Screen name="HomeTabNavigator" component={HomeTabNavigator}/>
		<Stack.Screen name="AddressesListModal" component={AddressesListModal}/>
		<Stack.Screen name="CancelOrRescheduleSessionModal" component={CancelOrRescheduleSessionModal}/>
		<Stack.Screen name="EditBillingAddressModal" component={EditBillingAddressModal}/>
		<Stack.Screen name="EditCorporationModal" component={EditCorporationModal}/>
		<Stack.Screen name="EditPaymentAuthorityModal" component={EditPaymentAuthorityModal}/>
		<Stack.Screen name="EditPersonModal" component={EditPersonModal}/>
		<Stack.Screen name="EditSessionCategoryModal" component={EditSessionCategoryModal}/>
		<Stack.Screen name="EditSubjectSuggestionModal" component={EditSubjectSuggestionModal}/>
		<Stack.Screen name="FeedbackModal" component={FeedbackModal}/>
		<Stack.Screen name="InterpreterModal" component={InterpreterModal}/>
		<Stack.Screen name="SearchInterpretersModal" component={SearchInterpretersModal}/>
		<Stack.Screen name="SelectBillingAddressModal" component={SelectBillingAddressModal}/>
		<Stack.Screen name="SelectDurationModal" component={SelectDurationModal}/>
		<Stack.Screen name="SelectPaymentAuthorityModal" component={SelectPaymentAuthorityModal}/>
		<Stack.Screen name="SelectSessionCategoryModal" component={SelectSessionCategoryModal}/>
		<Stack.Screen name="SelectSubjectSuggestionModal" component={SelectSubjectSuggestionModal}/>
		<Stack.Screen name="SessionCreationFiltersModal" component={SessionCreationFiltersModal}/>
		{SharedScreensGroup}
	</Stack.Group>
);
