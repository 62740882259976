/*
 * Enables the debouncing of an action, calling it after a certain time if no
 * other calls have been made in that interval.
 */
export class Debouncer {
	private interval?: ReturnType<typeof setTimeout> = undefined;

	/*
	 * Runs the callback after the specified timeout. If another call to this
	 * method is made before `timeout` [ms], the former callback is not performed
	 * and the timeout is refreshed.
	 */
	public debounce = (callback: () => unknown, timeout: number): void => {
		clearTimeout(this.interval);
		this.interval = setTimeout(callback, timeout);
	};
}
