import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Popover, Tooltip as RNTooltip} from "react-native-popper";
import {SMALL_SPACING} from "../../utils/constants";
import {SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Text} from "../texts/text";

export interface TooltipProps {
	content: JSX.Element | string;
	isOpen: boolean;
	offset?: number;
	onPressOut?: () => void;
	wrapperRef: React.RefObject<View>;
}

export const Tooltip = ({content, isOpen, offset = 0, onPressOut, wrapperRef}: TooltipProps): JSX.Element => (
	<RNTooltip
		isOpen={isOpen}
		trigger={wrapperRef}
		offset={offset}
	>
		{onPressOut && <Popover.Backdrop onPressOut={onPressOut}/>}
		<RNTooltip.Content>
			<RNTooltip.Arrow style={styles.arrow}/>
			<View style={styles.content}>
				{typeof content === "string"
					? <Text color={WHITE}>{content}</Text>
					: content
				}
			</View>
		</RNTooltip.Content>
	</RNTooltip>
);

const styles = StyleSheet.create({
	arrow: {
		backgroundColor: SUBTLE_4,
		borderColor: SUBTLE_4,
	},
	content: {
		backgroundColor: SUBTLE_4,
		borderRadius: SMALL_SPACING,
		maxWidth: 300,
		paddingHorizontal: SMALL_SPACING,
		paddingVertical: 4,
	},
});
