import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {modalScreenOptions} from "../../components/views/modal-wrapper";
import {PublicScreensGroupParamList} from "../../utils/navigation/paramLists/public-param-list";
import {ConfirmationModal} from "../screens/common/modals/confirmation";
import {SelectMediaDeviceModal} from "../screens/common/modals/select-media-device";
import {VideoSessionModal} from "../screens/common/modals/video-session";

const Stack = createNativeStackNavigator<PublicScreensGroupParamList>();

export const PublicScreensGroup = (
	<Stack.Group screenOptions={modalScreenOptions}>
		{/* CAREFUL THE ORDER OF THE SCREENS IMPACTS THE NAVIGATION!!! FIRST SCREEN = DEFAULT SCREEN */}
		<Stack.Screen name="ConfirmationModal" component={ConfirmationModal}/>
		<Stack.Screen name="SelectMediaDeviceModal" component={SelectMediaDeviceModal}/>
		<Stack.Screen
			name="VideoSessionModal"
			component={VideoSessionModal}
			options={{presentation: "fullScreenModal"}}
		/>
	</Stack.Group>
);
