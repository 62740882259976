import * as React from "react";
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {DEFAULT_SPACING} from "../../../utils/constants";

interface Props {
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
}

export const Column = ({children, style}: Props): JSX.Element => (
	<View style={[styles.column, style]}>
		{children}
	</View>
);

const styles = StyleSheet.create({
	column: {
		borderRadius: DEFAULT_SPACING,
		flex: 1,
		overflow: "hidden",
	},
});
