import {entities as enUSEntities} from "../en-US/entities";

export const entities: typeof enUSEntities = {
	billingAddress: {
		address: "Addresse",
		department: "Département",
		name: "Nom/entreprise",
		postOfficeBox: "Case postale",
		postalCode: "Code postal",
		town: "Ville",
	},
};
