import * as Clipboard from "expo-clipboard";
import * as React from "react";
import {TFunction} from "react-i18next";
import {Animated, Falsy, ListRenderItem, StyleSheet, View} from "react-native";
import {Interpreter as InterpreterT, InterpreterPreview} from "../../../../@types/identities/interpreter";
import {ToccoSetting} from "../../../../@types/settings";
import {Button} from "../../../../components/buttons/button";
import {InterpreterHeader} from "../../../../components/headers/interpreter-header";
import {ProviderLabels} from "../../../../components/labels/provider";
import {TranslationLabel} from "../../../../components/labels/translation";
import {ListElement, ListElementProps} from "../../../../components/list/items/list-element";
import {HeaderMenu} from "../../../../components/menus/header";
import {StatusBar} from "../../../../components/status-bar";
import {Text} from "../../../../components/texts/text";
import {Drawer} from "../../../../components/views/drawer";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {getUpcomingSessionCount, interpreterDetails} from "../../../../requests/clients/interpreter";
import {filterTruthy} from "../../../../utils/arrays";
import {DEFAULT_SPACING, EXTRA_LARGE_SPACING, MENU_HEIGHT, SMALL_SPACING} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getTitle, personIdentity} from "../../../../utils/identities";
import {TranslationFeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {linkToCall, linkToMail} from "../../../../utils/network/links";
import {flatListDefaultProps} from "../../../../utils/scrollables";
import {capitalize} from "../../../../utils/strings";

const availableInfosOrder: (keyof InterpreterT)[] = [ // List of infos in order
	"languages", "qualifications", "origin", "gender", "transport", "place", "phone", "email",
];
const onPressMail = (email: InterpreterT["email"]) => () => linkToMail(email);
const onPressCall = (phone: InterpreterT["phone"]) => () => linkToCall(phone);
const getTransports = (transport: InterpreterT["transport"], ct: TFunction): string => {
	const privateTransport = transport.includes("Private") && ct("common:transportPrivate");
	const publicTransport = transport.includes("Public") && ct("common:transportPublic");
	const both = [privateTransport, publicTransport].filter(Boolean); // removes null values
	return both.join(", ");
};

export const createInterpreterPreviewInfo = (interpreter: InterpreterPreview, elementKey: typeof availableInfosOrder[number], usingTocco: boolean, ct: TFunction, t: TFunction): Falsy | ListElementProps => {
	switch (elementKey) {
		case "languages": {
			return {
				children: (
					<View style={styles.translationLabels}>
						{interpreter[elementKey].map((el) => (
							<TranslationLabel
								key={`${el.from}-${el.to}`}
								{...el}
								style={styles.translationLabel}
							/>
						))}
					</View>
				),
				icon: "language",
				label: ct("common:language", {count: interpreter.languages.length}),
			};
		}
		case "origin": {
			return {
				children: t(`origins:${interpreter[elementKey]}`),
				icon: "world",
				label: ct("common:origin"),
			};
		}
		case "gender": {
			return {
				children: getTitle(interpreter[elementKey]!),
				icon: "gender",
				label: ct("common:title"),
			};
		}
		case "phone": {
			return {
				buttons: [{icon: "phone", key: "call", onPress: onPressCall(interpreter[elementKey]!)}],
				children: interpreter[elementKey],
				icon: "phonePad",
				label: ct("common:phone"),
			};
		}
		case "email": {
			return usingTocco
				? null
				: {
					buttons: [{icon: "letter", key: "mail", onPress: onPressMail(interpreter[elementKey])}],
					children: interpreter[elementKey],
					icon: "mail",
					label: ct("common:email"),
				};
		}
	}
};

const createInterpreterInfo = (interpreter: InterpreterT, elementKey: typeof availableInfosOrder[number], usingTocco: boolean, ct: TFunction): Falsy | ListElementProps => {
	switch (elementKey) {
		case "qualifications": {
			return usingTocco
				? null
				: Object.entries(interpreter[elementKey]).length > 0 && {
					children: Object.entries(interpreter[elementKey]).map(([key, values]) =>
						`${capitalize(key)}:\n  ✓ ${values.map(v => v.value).join("\n  ✓ ")}`).join("\n\n"),
					icon: "school",
					label: ct("common:qualification_plural"),
				};
		}
		case "transport": {
			return usingTocco
				? null
				: {
					children: getTransports(interpreter[elementKey], ct),
					icon: "travel",
					label: ct("common:transport", {count: interpreter.transport.length}),
				};
		}
		case "place": {
			return usingTocco
				? null
				: {
					// buttons: [{icon: "navigate", onPress: onPressNavigate}],
					children: interpreter[elementKey].address,
					icon: "location",
					label: ct("common:address"),
				};
		}
	}
};

export const Interpreter = ({
	navigation,
	route,
	inModal,
}: ClientStackNavigatorScreenProps<"InterpreterModal"> & {inModal?: boolean}): JSX.Element => {
	const {ct, t} = useTranslation();

	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};
	const {reset} = React.useContext(SessionCreationContext);

	const [interpreter, setInterpreter] = React.useState<InterpreterT | null>(null);
	const [upcomingSessionCount, setUpcomingSessionCount] = React.useState<number>(0);
	const [loading, setLoading] = React.useState(true);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();

	const {place, phone, email, fullName, communicationTypes, gender, providedServices} = interpreter ?? {};
	const scrollY = React.useRef(new Animated.Value(0));

	const keyExtractor = React.useCallback(
		(item: ListElementProps) => item.label ?? "",
		[],
	);
	const renderItem: ListRenderItem<ListElementProps> = React.useCallback(
		({item}) => <ListElement {...item} />,
		[],
	);
	const onPressCall = React.useCallback(
		() => linkToCall(phone),
		[phone],
	);
	/*
	 * ListElementProps[] should be the return type but Animated.Flatlist types seems to be buggy telling us
	 * Type 'ListElementProps[]' is not assignable to type 'readonly WithAnimatedObject<ListElementProps>[]'.
	 */
	const createInfos = (): Animated.WithAnimatedObject<ListElementProps>[] => {
		const infos: (Falsy | ListElementProps)[] = [];
		availableInfosOrder.forEach((sessionKey) => {
			// Only add infos that exists in the session object
			if (interpreter?.[sessionKey]) {
				infos.push(createInterpreterPreviewInfo(interpreter, sessionKey, usingTocco, ct, t), createInterpreterInfo(interpreter, sessionKey, usingTocco, ct));
			}
		});
		return filterTruthy(infos);
	};
	const onPressInterpreterSessions = React.useCallback(
		() => navigation.push(
			"CalendarModal",
			{filters: JSON.stringify({provider: interpreter})},
		),
		[interpreter, navigation],
	);
	const onPressNewSession = React.useCallback(
		() => {
			reset(
				{type: "new"},
				{initialProvider: interpreter!},
			);
			navigation.navigate("HomeTabNavigator", {screen: "NewSession"});
		},
		[interpreter, navigation, reset],
	);
	const onPressCopy = React.useCallback(
		() => {
			const genderKV = gender ? `${ct("common:gender")}= ${gender}\n` : "";
			const telephonKV = phone ? `${ct("common:phone")}= ${phone}\n` : "";
			const emailKV = !usingTocco && email ? `${ct("common:email")}= ${email}\n` : "";
			const addressKV = !usingTocco && place?.address ? `${ct("common:address")}= ${place.address}\n` : "";
			Clipboard.setStringAsync(`${genderKV}${telephonKV}${emailKV}${addressKV}\n`.trimEnd()).catch(Log.error());
			Log.success("copyProfileSuccess")();
		},
		[ct, email, gender, phone, place?.address, usingTocco],
	);

	React.useEffect(
		() => {
			Promise.all([
				getUpcomingSessionCount(route.params.interpreterId)
					.then(setUpcomingSessionCount),
				interpreterDetails(route.params.interpreterId)
					.then(setInterpreter),
			]).catch((error) => {
				Log.error()(error);
				setErrorMessage("feedbacks:getInterpreterFailed");
			}).finally(() => setLoading(false));
		},
		[accountId, route.params.interpreterId],
	);

	const header = (
		<>
			{!inModal && <StatusBar/>}
			<HeaderMenu
				centerComponent={(
					<Animated.View
						style={
							{
								transform: [
									{
										translateY:
											scrollY.current.interpolate({
												inputRange: [Number.MIN_SAFE_INTEGER, 70, 140, Number.MAX_SAFE_INTEGER],
												outputRange: [-MENU_HEIGHT, -MENU_HEIGHT, 0, 0],
											}),
									},
								],
							}
						}
					>
						{interpreter && (
							<Text type="emphasis_1">
								{personIdentity(interpreter)}
							</Text>
						)}
					</Animated.View>
				)}
			/>
		</>
	);

	if (loading || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loading}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}

	return (
		<>
			{header}
			<Animated.FlatList
				{...flatListDefaultProps}
				keyExtractor={keyExtractor}
				onScroll={Animated.event(
					[{nativeEvent: {contentOffset: {y: scrollY.current}}}],
					{useNativeDriver: true},
				)}
				contentContainerStyle={styles.contentContainerStyle}
				ItemSeparatorComponent={null}
				renderItem={renderItem}
				data={createInfos()}
				ListHeaderComponent={(
					<InterpreterHeader
						scrollY={scrollY.current}
						buttons={[
							<Button
								key="callButton"
								type="secondary"
								size="medium"
								icon="phone"
								label={ct("common:call")}
								onPress={onPressCall}
							/>,
							/*
							 * <Button
							 *  key="messageButton"
							 *  type="secondary"
							 *  size="medium"
							 *  icon={"comment"}
							 *  label={ct("common:message")}
							 *  onPress={onPressMail}
							 * />,
							 */
							<Button
								key="sessionButton"
								type="secondary"
								size="medium"
								icon="calendar"
								notif={upcomingSessionCount}
								label={ct("common:session_plural")}
								onPress={onPressInterpreterSessions}
							/>,
							<Button
								key="copyProfile"
								type="secondary"
								size="medium"
								icon="copy"
								label={ct("common:copy")}
								onPress={onPressCopy}
							/>,
						]}
						interpreter={interpreter!}
					/>
				)}
			/>
			<Drawer verticalPosition="bottom">
				<View style={styles.drawerContainer}>
					<View style={styles.labelsContainer}>
						<Text type="label" style={styles.labelsText}>
							{t("users:interpreter.accepts", {firstName: fullName?.firstName})}
						</Text>
						<ProviderLabels
							communicationTypes={communicationTypes ?? []}
							providedServices={providedServices}
						/>
					</View>
					<Button
						size="small"
						text={t("users:interpreter.new")}
						icon="plus"
						onPress={onPressNewSession}
						fullWidth
					/>
				</View>
			</Drawer>
		</>
	);
};

export const InterpreterModal = (props: ClientStackNavigatorScreenProps<"InterpreterModal">): JSX.Element => (
	<ModalWrapper>
		<Interpreter inModal {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	contentContainerStyle: {
		paddingBottom: EXTRA_LARGE_SPACING,
	},
	drawerContainer: {
		alignItems: "center",
		padding: DEFAULT_SPACING,
	},
	labelsContainer: {
		alignItems: "center",
		flexDirection: "row",
		marginBottom: SMALL_SPACING,
	},
	labelsText: {
		marginRight: SMALL_SPACING,
	},
	translationLabel: {
		marginRight: SMALL_SPACING,
		marginTop: SMALL_SPACING,
	},
	translationLabels: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});
