import * as React from "react";
import {StyleSheet} from "react-native";
import {SessionRefusal} from "../../../../@types/activities/session";
import {SessionRefusalSetting} from "../../../../@types/settings";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {getRefusalSettings, refuseSession} from "../../../../requests/interpreters/activities/actions/accept";
import {useForm} from "../../../../utils/hooks/use-form";
import {useReasonInputs} from "../../../../utils/hooks/use-reason-inputs";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {forceBack} from "../../../navigation";

const formBase: SessionRefusal = {};
const RefuseSession = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"RefuseSessionModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {callbacks, session} = route.params;
	const {accountId} = React.useContext(AuthentifiedContext);
	const [sessionRefusalSetting, setRefusalSettings] = React.useState<SessionRefusalSetting>();

	const {
		displayed, isUpdated, setUpdated, errorMessage, loading: loadingForm,
	} = useForm<SessionRefusal>(formBase);

	const onPressRefuse = React.useCallback(
		(): Promise<void> => refuseSession(accountId!, session.activityId, displayed)
			.then(() => {
				callbacks?.("refuseSessionSuccess");
				Log.success("refuseSessionSuccess")();
				navigation.dispatch(forceBack);
			})
			.catch(Log.error("refuseSessionFailed")),
		[accountId, callbacks, displayed, navigation, session.activityId],
	);

	React.useEffect(
		() => {
			getRefusalSettings(session.activityId)
				.then(setRefusalSettings)
				.catch(Log.error("getDataFailed"));
		},
		[session.activityId],
	);

	const header = <HeaderMenu right={{help: false}} exitConfirmation={isUpdated}/>;
	const inputs = useReasonInputs(sessionRefusalSetting?.value, displayed, setUpdated, t("activities:sessions.refusalReason"));

	if (!sessionRefusalSetting || loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={!sessionRefusalSetting || loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
				style={styles.loadingContainer}
			/>
		);
	}

	return (
		<>
			{header}
			<Form
				hideReset
				title={ct("activities:sessions.refuse.validation")}
				validation={{
					buttonProps: {
						disabled: sessionRefusalSetting.value?.dropdown?.mandatory && !displayed.selectedReasonId,
						icon: "check",
						iconPosition: "before",
						text: ct("common:refuse"),
					},
					onValidation: onPressRefuse,
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const RefuseSessionModal = (
	props: InterpreterStackNavigatorScreenProps<"RefuseSessionModal">,
): JSX.Element => (
	<ModalWrapper>
		<RefuseSession {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	loadingContainer: {
		flexBasis: 370, // Content height estimation
	},
});
