import * as React from "react";
import {ListRenderItemInfo, StyleSheet} from "react-native";
import {InterpreterSalaryMandatePreview} from "../../../@types/activities/mandate";
import {ListElement} from "../../../components/list/items/list-element";
import {Spacer} from "../../../components/spacer";
import {Text} from "../../../components/texts/text";
import {Column} from "../../../components/views/columns/column";
import {Columns} from "../../../components/views/columns/columns";
import {ResponsiveContext} from "../../../contexts/responsive";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useAccountElements} from "../../../utils/hooks/use-account-elements";
import {SectionData, useAccountSections} from "../../../utils/hooks/use-account-sections";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {InterpreterHomeTabNavigatorScreenProps} from "../../../utils/navigation/paramLists/interpreter-param-list";
import {BACKGROUND_COLOR, SUBTLE, SUBTLE_2} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Session} from "../common/modals/session";
import {EditProfile} from "./modals/edit-profile";
import {Salary} from "./modals/salary";

export const InterpreterAccount = ({
	navigation,
	route,
}: InterpreterHomeTabNavigatorScreenProps<"InterpreterAccount">): JSX.Element => {
	const {t} = useTranslation();
	const {columns} = React.useContext(ResponsiveContext);
	const [sessionId, setSessionId] = React.useState<string>();

	const renderListItem = ({item}: ListRenderItemInfo<SectionData>): JSX.Element =>
		<ListElement icon={item.icon} onPress={item.onPress}>{item.text}</ListElement>;

	const sections = useAccountSections();

	const onSessionPress = React.useCallback(
		(id: InterpreterSalaryMandatePreview["activityId"]) => {
			if (columns <= 2) {
				navigation.navigate("SessionModal", {sessionId: id});
			} else {
				setSessionId(id);
			}
		},
		[columns, navigation],
	);

	const {header, menuList} = useAccountElements({renderListItem, sections});
	return (
		<>
			{header}
			<Columns style={{backgroundColor: columns === 1 ? BACKGROUND_COLOR : SUBTLE}}>
				{columns === 1 && menuList}
				{columns >= 2 && (
					<Column style={styles.mainColumn}>
						<EditProfile
							navigation={navigation as any}
							route={{key: route.key, name: "EditProfileModal"}}
							goBackOnSave={false}
						/>
					</Column>
				)}
				{columns >= 2 && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
						<Column>
							<Salary
								selectedSessionId={columns >= 3 ? sessionId : undefined}
								onSessionPress={columns >= 3 ? onSessionPress : undefined}
								navigation={navigation as any}
								route={{key: route.key, name: "SalaryModal"}}
							/>
						</Column>
					</>
				)}
				{columns >= 3 && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
						<Column style={[styles.activitiesColumn, !sessionId && styles.centeredContent]}>
							{
								sessionId
									? (
										<Session
											navigation={navigation as any}
											route={{key: route.key, name: "SessionModal", params: {sessionId}}}
										/>
									)
									: <Text type="label">{t("forms:inputs.selectSession")}</Text>
							}
						</Column>
					</>
				)}
			</Columns>
		</>
	);
};

const styles = StyleSheet.create({
	activitiesColumn: {
		backgroundColor: BACKGROUND_COLOR,
		borderColor: SUBTLE_2,
		borderWidth: 1,
		...ELEVATIONS[4],
	},
	centeredContent: {
		alignItems: "center",
		justifyContent: "center",
	},
	mainColumn: {
		borderColor: SUBTLE_2,
		borderRadius: DEFAULT_SPACING,
		borderWidth: 1,
	},
});
