import * as React from "react";
import {GestureResponderEvent, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {PRIMARY, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Icon, IconKey} from "../icons";
import {Text} from "../texts/text";
import {Pressable} from "../views/pressable";

export interface LabelProps {
	allowFontScaling?: boolean;
	backgroundColor?: string;
	borderColor?: string;
	children?: React.ReactNode | React.ReactNode[];
	color?: string;
	icon?: IconKey;
	onPress?: ((event: GestureResponderEvent) => void) | null;
	size?: "medium" | "small";
	style?: StyleProp<ViewStyle>;
	text?: number | string;
}

export const Label = ({
	size = "medium",
	backgroundColor = PRIMARY,
	color = WHITE,
	icon,
	text,
	children,
	style,
	onPress,
	borderColor,
	allowFontScaling = true,
}: LabelProps): JSX.Element => {
	const Wrapper = onPress ? Pressable : View;
	return (
		<Wrapper
			style={[
				styles.container,
				size === "medium" ? styles.medium : styles.small,
				{backgroundColor},
				icon && {paddingHorizontal: 4},
				icon && !text && {width: size === "medium" ? 30 : 22},
				!!borderColor && {borderColor, borderWidth: size === "medium" ? 2 : 1},
				style,
			]}
			{...onPress && {onPress, underlayColor: SUBTLE_4}}
		>
			<>
				{!!icon && (
					<Icon
						icon={icon}
						color={color}
						size={size === "medium" ? 20 : 14}
						containerSize={size === "medium" ? 30 : 22}
					/>
				)}
				{!!text && (
					<Text
						type="emphasis_2"
						color={color}
						style={icon && {paddingRight: size === "medium" ? 8 : 2}}
						allowFontScaling={allowFontScaling}
					>
						{text}
					</Text>
				)}
				{children}
			</>
		</Wrapper>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		alignSelf: "center",
		flexDirection: "row",
	},
	medium: {
		alignItems: "center",
		borderRadius: 15,
		height: 30,
		justifyContent: "center",
		minWidth: 30,
		paddingHorizontal: 12,
	},
	small: {
		alignItems: "center",
		borderRadius: 12,
		height: 22,
		justifyContent: "center",
		minWidth: 22,
		paddingHorizontal: 6,
	},
});
