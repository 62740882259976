import * as React from "react";
import {BillingAddress} from "../../../../@types/billing-address";
import {SessionCreationSetting} from "../../../../@types/settings";
import {ListElement} from "../../../../components/list/items/list-element";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {
	addBillingAddress,
	deleteBillingAddress,
	getBillingAddresses,
	updateBillingAddress,
} from "../../../../requests/clients/activities/billing-addresses";
import {formatBillingAddress} from "../../../../utils/billing-addresses";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

export const SelectBillingAddress = ({
	navigation,
	route,
}: ClientStackNavigatorScreenProps<"SelectBillingAddressModal">): JSX.Element => {
	const {onSelect} = route.params ?? {};
	const {ct} = useTranslation();
	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {detailsStep: {billingAddress}},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {value: {detailsStep: {}}};

	const onPress = React.useCallback(
		(item: BillingAddress) => () => {
			onSelect?.(item);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelect],
	);

	const request = React.useCallback(
		(item: BillingAddress) =>
			addBillingAddress(accountId!, item)
				.then((added) => {
					if (onSelect) {
						onPress(added)();
					}
					return added;
				}),
		[accountId, onPress, onSelect],
	);

	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", BillingAddress>): JSX.Element => (
		<ListElement
			key={item.id}
			onPress={onSelect && onPress(item)}
			buttons={buttons}
		>
			{formatBillingAddress(item)}
		</ListElement>
	);

	return (
		<>
			<HeaderMenu
				center={ct("forms:items.select", {item: ct("common:billingAddress")})}
			/>
			<SelectFlatList
				getRequest={(pagination) => getBillingAddresses(accountId!, pagination)}
				onSearch={{request: (pagination, search) => getBillingAddresses(accountId!, pagination, search)}}
				deleteRequest={!onSelect && billingAddress?.editRights?.canDelete
					? (removed: BillingAddress) => deleteBillingAddress(accountId!, removed)
					: undefined
				}
				onPressAdd={billingAddress?.editRights?.canAddNew
					? {
						request,
						screen: {
							name: "EditBillingAddressModal",
						},
					}
					: undefined
				}
				onPressEdit={!onSelect && billingAddress?.editRights?.canEdit
					? {
						request: (updated: BillingAddress) => updateBillingAddress(accountId!, updated),
						screen: {
							name: "EditBillingAddressModal",
						},
					}
					: undefined}
				itemTranslationKey="common:billingAddress"
				renderItem={renderItem}
				idKey="id"
			/>
		</>
	);
};

export const SelectBillingAddressModal = (props: ClientStackNavigatorScreenProps<"SelectBillingAddressModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectBillingAddress {...props} />
	</ModalWrapper>
);
