import {OpenSans_400Regular, OpenSans_600SemiBold, OpenSans_700Bold} from "@expo-google-fonts/open-sans";
import "expo-dev-client";
import {useFonts} from "expo-font";
import * as Updates from "expo-updates";
import * as React from "react";
import {LogBox} from "react-native";
import {OverlayProvider} from "react-native-popper";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {Alert} from "./components/feedbacks/alert";
import {Toast} from "./components/feedbacks/toast";
import {SplashView} from "./components/views/splash-view";
import {AuthentifiedProvider} from "./contexts/authentified";
import {LocaleProvider} from "./contexts/locale";
import {ResponsiveProvider} from "./contexts/responsive";
import {AppContainer} from "./navigation/navigators/root";
import {IS_MOBILE} from "./utils/constants";
import {infos} from "./utils/infos";
import {Locales} from "./utils/locales/locales";
import {Log} from "./utils/logs/logs";
import {LogsService} from "./utils/logs/logs-service";
import {configurePushNotifications} from "./utils/notifications/manager";
// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module
const logoFont = require("../assets/fonts/logo/logo.ttf");

configurePushNotifications();
Locales.init();

export const App = (): JSX.Element => {
	const [fontsLoaded] = useFonts({
		OpenSans_400Regular,
		OpenSans_600SemiBold,
		OpenSans_700Bold,
		logo: logoFont,
	});
	const [newOta, setNewOta] = React.useState(false);
	React.useEffect(() => {
		Log.init();
		Log.setTags(infos);
	}, []);

	// Check for OTA updates
	React.useEffect(
		() => {
			if (IS_MOBILE) {
				Updates.addListener((event) => {
					if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
						setNewOta(true);
					}
				});
			}
		},
		[],
	);

	return (
		<SafeAreaProvider>
			<LocaleProvider>
				{fontsLoaded
					? (
						<AuthentifiedProvider newOta={newOta}>
							<ResponsiveProvider>
								<OverlayProvider>
									<AppContainer/>
								</OverlayProvider>
								<Toast/>
								<Alert/>
							</ResponsiveProvider>
						</AuthentifiedProvider>
					)
					: (
						<SplashView centered loading/>
					)}
			</LocaleProvider>
		</SafeAreaProvider>
	);
};

export default LogsService.wrap(App);

LogBox.ignoreLogs([
	// React-Navigation modals may have a callback function
	"Non-serializable values were found in the navigation state",
]);
