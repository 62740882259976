import {Account} from "../../@types/accounts";
import {Session} from "../../@types/activities/session";
import {FeedbackAction, RatingValue} from "../../utils/feedbacks";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";
import {SessionFeedback} from "../@types/feedback";

export type FeedbackRequests = {
	POST: {
		body: SessionFeedback,
	},
};

export const sendFeedback = async (
	accountId: Account["accountId"],
	feedback: {
		action: FeedbackAction;
		comments?: string;
		dismissed?: boolean;
		rating?: RatingValue;
		sessionId?: Session["activityId"];
	},
): Promise<void> => {
	const response = await api(
		"sessionFeedback",
		"POST",
		{
			body: {
				clientId: accountId,
				comments: feedback.comments,
				dismissed: feedback.dismissed,
				sessionId: feedback.sessionId,
				event: feedback.action,
				feedbackDate: Date.now(),
				rating: feedback.rating,
			},
		},
	) as ApiResponse<{}>;

	return response.isSuccess ? Promise.resolve() : Promise.reject(new Error(response.errorId || response.message));
};
