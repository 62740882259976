import * as React from "react";
import {InterpreterFilters} from "../../../../@types/identities/filters";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useInterpreterFiltersInputs} from "../../../../utils/hooks/use-interpreter-filters-inputs";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";

interface Props extends ClientStackNavigatorScreenProps<"SearchInterpretersModal"> {
	inModal?: boolean;
	onQualificationsFetched?: () => void;
	title?: string;
}

export const SearchInterpreters = ({
	navigation,
	route,
	title,
	inModal,
	onQualificationsFetched,
}: Props): JSX.Element => {
	const {ct} = useTranslation();
	const {availableLanguages, availableOrigins, filters: filtersParam, onValidate} = route.params;
	const [filtersState, setFiltersState] = React.useState<InterpreterFilters>(JSON.parse(filtersParam));
	const qualificationsFetched = React.useRef(false);

	const options = React.useMemo(
		() => ({
			disableRules: true,
			filters: {filters: filtersState, setFilters: setFiltersState},
			interpretersGroups: false,
			languages: availableLanguages,
			origins: {includeNotRelevant: false, values: availableOrigins},
		}),
		[availableLanguages, availableOrigins, filtersState],
	);

	React.useEffect(() => {
		if (!inModal && !qualificationsFetched.current && filtersState.qualifications) {
			qualificationsFetched.current = true;
			onValidate?.(filtersState);
		}
	}, [filtersState, onValidate, inModal]);

	const {inputs} = useInterpreterFiltersInputs(options, onQualificationsFetched);
	const afterReset = React.useCallback(
		(updated: Record<keyof InterpreterFilters, any>) => onValidate?.({...filtersState, ...updated}),
		[filtersState, onValidate],
	);

	return (
		<>
			<HeaderMenu center={ct("common:search")}/>
			<Form
				title={title || ct("common:interpreter")}
				inputs={inputs}
				afterReset={afterReset}
				validation={{
					buttonProps: {icon: "search", text: ct("common:search")},
					onValidation: () => {
						onValidate?.(filtersState);
						if (inModal) {
							navigation.goBack();
						}
					},
				}}
			/>
		</>
	);
};

export const SearchInterpretersModal = (props: ClientStackNavigatorScreenProps<"SearchInterpretersModal">): JSX.Element => (
	<ModalWrapper>
		<SearchInterpreters {...props} inModal/>
	</ModalWrapper>
);
