import * as React from "react";
import {ListRenderItemInfo} from "react-native";
import {ListElement} from "../../../../components/list/items/list-element";
import {HeaderMenu} from "../../../../components/menus/header";
import {FlatList} from "../../../../components/scrollables/flat-list";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {CONTAINERS} from "../../../../utils/constants";
import {infos} from "../../../../utils/infos";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {getKeys} from "../../../../utils/objects";

const keyExtractor = (item: string, index: number): string => `${index}-${item}`;

const Debug = (_: SharedScreenProps<"DebugModal">): JSX.Element => {
	const data = getKeys(infos);
	const renderItem = ({item}: ListRenderItemInfo<keyof typeof infos>): JSX.Element => (
		<ListElement label={item} key={item}>{infos[item]}</ListElement>
	);
	return (
		<>
			<HeaderMenu center="Debug"/>
			<FlatList
				keyExtractor={keyExtractor}
				contentContainerStyle={CONTAINERS.LIST}
				contentInsetAdjustmentBehavior="always"
				data={data}
				renderItem={renderItem}
			/>
		</>
	);
};

export const DebugModal = (props: SharedScreenProps<"DebugModal">): JSX.Element => (
	<ModalWrapper>
		<Debug {...props} />
	</ModalWrapper>
);
