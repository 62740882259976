import * as React from "react";
import {Icon, IconKey} from "../../components/icons";
import {GREEN, PRIMARY_2, RED, SUBTLE_4} from "../styles/colors";
import {ps} from "../switch";

export type ValidationType = "disabled" | "error" | "info" | "ok" | "warning";
export type ValidationState = "disabled" | "invalid" | "unvalidated" | "valid" | "validating";

export const getWorstValidationType = (types: ValidationType[]): ValidationType => {
	const orderedTypes: ValidationType[] = ["disabled", "error", "warning", "info", "ok"];
	return types.sort((a, b) => orderedTypes.indexOf(a) - orderedTypes.indexOf(b))[0];
};

export const getValidationColor = (type: ValidationType): string => ps(type, {
	default: PRIMARY_2,
	disabled: SUBTLE_4,
	error: RED,
	ok: GREEN,
});

export const getValidationIcon = (
	state: ValidationState,
	defaultIcon: IconKey,
	messageTypes?: ValidationType[],
): JSX.Element => {
	const worstType = messageTypes ? getWorstValidationType(messageTypes) : "info";

	if (state === "disabled") {
		return <Icon icon={defaultIcon}/>;
	}
	if (state === "valid") {
		return <Icon icon={defaultIcon} color={GREEN}/>;
	}
	return <Icon icon={defaultIcon} color={getValidationColor(worstType)}/>;
	/*
	 * switch (worstType) {
	 *  case "error":
	 *   return <Icon icon="validationError" {...props} />;
	 *  case "ok":
	 *    return <Icon icon="validationOk" {...props} />;
	 *  case "warning":
	 *    return <Icon icon="validationError" {...props} />;
	 *  default:
	 *    return defaultIcon({...props});
	 * }
	 */
};
