import * as React from "react";
import {IS_WEB} from "../../utils/constants";
import {getKeys, Indexable} from "../../utils/objects";
import {mapRecursive} from "../../utils/react/children";

interface Props {
	children: (valid: boolean) => React.ReactElement;
	onValidationChange?: (valid: boolean) => void;
}

/*
 * The Form component will clone and add a `validation` function on every
 * children having a `rule` prop.
 *
 * A form is considered invalid if no validation is done or if one of the
 * validation function returns an invalid result.
 *
 * It will also clone and add a `disabled` field set to true if the form is
 * considered invalid on every children with an `onPress` prop.
 *
 * Note: Be aware of potentials performances issues when using a form with a
 * lot of children.
 */
export const FormValidation = ({children: childrenProp, onValidationChange}: Props): JSX.Element => {
	const [validations, setValidations] = React.useState<Indexable<boolean>>({});

	const validate = (valid: boolean, id: string): void =>
		setValidations(prev => ({...prev, [id]: valid}));
	const handleChild = (child: React.ReactElement, id: string): JSX.Element => (child.props.rules === undefined)
		? child
		: React.cloneElement(child, {validation: (valid: boolean) => validate(valid, id)});

	const isValid = Object.values(validations).every(Boolean) || getKeys(validations).length === 0;
	const children = childrenProp(isValid);
	const handledChildren = mapRecursive(children, (child, id) => handleChild(child, id));

	React.useEffect(
		() => onValidationChange?.(isValid),
		[isValid, onValidationChange],
	);

	const onSubmit = React.useCallback(
		(event: React.FormEvent<HTMLFormElement>) => event.preventDefault(),
		[],
	);

	return IS_WEB
		? (
			<form style={{display: "flex", flexDirection: "column"}} onSubmit={onSubmit}>
				{handledChildren}
			</form>
		)
		: <>{handledChildren}</>;
};
