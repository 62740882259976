import * as React from "react";
import {Form} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {forceBack} from "../../../navigation";

const AddEmail = ({route, navigation}: SharedScreenProps<"AddEmailModal">): JSX.Element => {
	const {onSelect} = route.params ?? {};
	const {ct} = useTranslation();
	const [email, setEmail] = React.useState("");
	const onValidation = React.useCallback(
		() => {
			onSelect?.(email);
			navigation.dispatch(forceBack);
		},
		[email, navigation, onSelect],
	);
	return (
		<>
			<HeaderMenu center={ct("screens:addEmail.title")}/>
			<Form
				validation={{
					buttonProps: {icon: "plus", text: ct("common:add")},
					onValidation,
				}}
				inputs={[
					{
						icon: "mail",
						label: ct("common:email"),
						requiredLabel: true,
						rules: [
							{rule: Rules.notEmpty, type: "error"},
							{rule: Rules.validation.email, type: "error"},
						],
						type: {
							key: "email",
							onChangeValue: setEmail,
							value: email,
						},
					},
				]}
			/>
		</>
	);
};

export const AddEmailModal = (props: SharedScreenProps<"AddEmailModal">): JSX.Element => (
	<ModalWrapper>
		<AddEmail {...props} />
	</ModalWrapper>
);
