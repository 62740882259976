import * as React from "react";
import {StyleSheet, View} from "react-native";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {SUBTLE} from "../../../utils/styles/colors";
import {Text} from "../../texts/text";

export const getSectionTitleHeight = (subtitle: boolean, bigTitle: boolean): number => {
	let height = 46;
	if (subtitle) {
		height += 11;
	}
	if (bigTitle) {
		height += 6;
	}
	return height;
};

export interface SectionTitleProps {
	bigTitle?: boolean;
	children: string;
	color?: string;
	leftComponent?: React.ReactElement;
	rightComponent?: React.ReactElement;
	subtitle?: string;
}

const SectionTitleRender = (
	{children, subtitle, rightComponent, leftComponent, bigTitle, color}: SectionTitleProps,
	ref: React.Ref<View>,
): JSX.Element => (
	<View
		style={[styles.wrapper, {height: getSectionTitleHeight(!!subtitle, !!bigTitle)}]}
		ref={ref}
	>
		<View style={[CONTAINERS.MAIN, styles.container]}>
			{/* <View style={[styles.underline, {bottom: subtitle ? 33 : 24}]} /> */}
			<View style={styles.row}>
				{!!leftComponent && leftComponent}
				<View style={styles.leftContent}>
					<Text type={bigTitle ? "title" : "title_2"} style={styles.title} color={color}>
						{children}
					</Text>
					{!!subtitle && <Text type="label" style={styles.subtitle}>{subtitle}</Text>}
				</View>
			</View>
			{!!rightComponent && rightComponent}
		</View>
	</View>
);
export const SectionTitle = React.forwardRef(SectionTitleRender);

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	leftContent: {
		flexDirection: "column",
	},
	row: {
		alignItems: "center",
		flex: 1,
		flexDirection: "row",
	},
	subtitle: {
		marginTop: -2, // The subtitle must look like bound to the title
	},
	title: {
		flex: 0,
		paddingRight: 4, // Let a little bit of space between title and underline
	},
	/*
	 * underline: {
	 *  backgroundColor: PRIMARY_2,
	 *  height: 1,
	 *  left: DEFAULT_SPACING,
	 *  position: "absolute",
	 *  right: DEFAULT_SPACING,
	 * },
	 */
	wrapper: {backgroundColor: SUBTLE},
});
