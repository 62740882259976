import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Account} from "../../../../@types/accounts";
import {InterpreterFilters} from "../../../../@types/identities/filters";
import {IdentityEditableFields} from "../../../../@types/identities/identity";
import {
	Person,
	PersonIdentity,
	PersonIdentityPreview,
	PersonListedFields,
	PersonPreview,
} from "../../../../@types/identities/person";
import {
	MedicalProfessional,
	MedicalProfessionalPreview,
	OtherProfessional,
	OtherProfessionalPreview,
} from "../../../../@types/identities/professional";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {
	IdentityEditableFieldsSetting,
	PersonListedFieldsSetting,
	SessionCreationSetting,
} from "../../../../@types/settings";
import {Buttons} from "../../../../components/buttons/button";
import {ListElement} from "../../../../components/list/items/list-element";
import {ListPropsCommon} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {
	addClientMedicalProfessional,
	deleteClientMedicalProfessional,
	getClientMedicalProfessionals,
	searchClientMedicalProfessionals,
	updateClientMedicalProfessional,
} from "../../../../requests/clients/activities/medical-professionals";
import {
	addClientProfessional,
	deleteClientProfessional,
	getClientProfessionals,
	searchClientProfessionals,
	updateClientProfessional,
} from "../../../../requests/clients/activities/other-professionals";
import {getClientInterpreters, searchClientInterpreters} from "../../../../requests/clients/interpreter";
import {
	addClientReceiver,
	deleteClientReceiver,
	getClientReceivers,
	searchClientReceivers,
	updateClientReceiver,
} from "../../../../requests/clients/settings/receivers";
import {
	addClientRequester,
	deleteClientRequester,
	getClientRequesters,
	searchClientRequesters,
	updateClientRequester,
} from "../../../../requests/clients/settings/requesters";
import {getReceiverEditableFields, getReceiverListedFields} from "../../../../requests/common/activities/details";
import {getSessionPeople, searchSessionPeople} from "../../../../requests/interpreters/activities/people";
import {
	getInterpreterReceivers,
	searchInterpreterReceivers,
} from "../../../../requests/interpreters/activities/receivers";
import {
	getInterpreterRequesters,
	searchInterpreterRequesters,
} from "../../../../requests/interpreters/activities/requesters";
import {filterTruthy} from "../../../../utils/arrays";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getDescriptionLabel, personIdentity, receiverIdentity} from "../../../../utils/identities";
import {TranslationFeedbackKey, TranslationFunc} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {
	SelectPerson as SelectPersonScreenParamsType,
} from "../../../../utils/navigation/paramLists/common-screen-props";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {SUBTLE_4} from "../../../../utils/styles/colors";
import {ps, s} from "../../../../utils/switch";
import {forceBack} from "../../../navigation";

export const selectPersonConfig = (
	type: SelectPersonScreenParamsType["exportType"],
	accountId: Account["accountId"],
	sessionId: SelectPersonScreenParamsType["sessionId"],
	onSelect?: SelectPersonScreenParamsType["onSelect"],
	filters?: InterpreterFilters,
	selectionOrBase?: SelectPersonScreenParamsType["selectionOrBase"],
	pool?: PersonIdentityPreview[],
): {
	context?: PersonListedFieldsSetting<PersonIdentity>["context"];
	get: ListPropsCommon<"identityId", PersonIdentityPreview, "SelectPersonModal">["getRequest"];
	onAdd?: NonNullable<ListPropsCommon<"identityId", PersonIdentityPreview, "SelectPersonModal">["onPressAdd"]>["request"];
	onDelete?: ListPropsCommon<"identityId", PersonIdentityPreview, "SelectPersonModal">["deleteRequest"];
	onSearch?: ListPropsCommon<"identityId", PersonIdentityPreview, "SelectPersonModal">["onSearch"];
	onSelect?: SelectPersonScreenParamsType["onSelect"];
	onUpdate?: NonNullable<ListPropsCommon<"identityId", PersonIdentityPreview, "SelectPersonModal">["onPressEdit"]>["request"];
} => {
	if (IS_INTERPRETER) {
		return ps(type, {
			receiver:
				{
					get: (pagination) => getInterpreterReceivers(pagination),
					onSearch: {request: (pagination, search) => searchInterpreterReceivers(search, pagination)},
					onSelect,
				},
			requester:
				{
					get: (pagination) => getInterpreterRequesters(pagination),
					onSearch: {request: (pagination, search) => searchInterpreterRequesters(search, pagination)},
					onSelect,
				},
			sessionPerson:
				{
					get: (pagination) => getSessionPeople(sessionId!, pagination),
					onSearch: {request: (pagination, search) => searchSessionPeople(search, sessionId!, pagination)},
					onSelect,
				},
		});
	}
	return ps(type, {
		interpreter: {
			context: "requester/interpreter",
			get: (pagination) => getClientInterpreters(pagination, filters).then(page => ({
				...page,
				items: page.items.map(
					item => ({...item, selected: !!selectionOrBase?.find(el => el.identityId === item.identityId)})),
			})),
			onSearch: {request: (pagination, search) => searchClientInterpreters(search, pagination)},
			onSelect,
		},
		interpreterPool: {
			get: (pagination) => {
				const page = paginate(pool ?? [], pagination);
				return Promise.resolve({
					...page,
					items: page.items.map(
						item => ({...item, selected: !!selectionOrBase?.find(el => el.identityId === item.identityId)})),
				});
			},
			onSelect,
		},
		medicalProfessional: {
			context: "requester/medicalProfessional",
			get: (pagination) => getClientMedicalProfessionals(accountId, pagination),
			// FIXME use MedicalProfessional instead of PersonIdentityPreview and remove as
			onAdd: (added: PersonIdentityPreview) => addClientMedicalProfessional(accountId, added as MedicalProfessional),
			onSearch: {request: (pagination, search) => searchClientMedicalProfessionals(accountId, search, pagination)},
			...(
				onSelect
					? {
						onSelect,
					}
					: {
						// FIXME use MedicalProfessionalPreview instead of PersonIdentityPreview and remove as
						onDelete: (removed: PersonIdentityPreview) => deleteClientMedicalProfessional(
							removed as MedicalProfessionalPreview),
						// FIXME use MedicalProfessionalPreview instead of PersonIdentityPreview and remove as
						onUpdate: (updated: PersonIdentityPreview) => updateClientMedicalProfessional(
							accountId, updated as MedicalProfessionalPreview),
					}
			),
		},
		otherProfessional: {
			context: "requester/otherProfessional",
			get: (pagination) => getClientProfessionals(accountId, pagination),
			// FIXME use OtherProfessional instead of PersonIdentityPreview and remove as
			onAdd: (added: PersonIdentityPreview) => addClientProfessional(accountId, added as OtherProfessional),
			onSearch: {request: (pagination, search) => searchClientProfessionals(accountId, search, pagination)},
			...(
				onSelect
					? {
						onSelect,
					}
					: {
						// FIXME use OtherProfessionalPreview instead of PersonIdentityPreview and remove as
						onDelete: (removed: PersonIdentityPreview) => deleteClientProfessional(removed as OtherProfessionalPreview),
						// FIXME use OtherProfessionalPreview instead of PersonIdentityPreview and remove as
						onUpdate: (updated: PersonIdentityPreview) => updateClientProfessional(
							accountId, updated as OtherProfessionalPreview),
					}
			),
		},
		receiver: {
			context: "requester/receiver",
			get: (pagination) => getClientReceivers(accountId, pagination),
			// FIXME use Receiver instead of PersonIdentityPreview and remove as
			onAdd: (added: PersonIdentityPreview) => addClientReceiver(accountId, added as Receiver),
			onSearch: {request: (pagination, search) => searchClientReceivers(accountId, search, pagination)},
			...(
				onSelect
					? {
						onSelect,
					}
					: {
						// FIXME use ReceiverPreview instead of PersonIdentityPreview and remove as
						onDelete: (removed: PersonIdentityPreview) => deleteClientReceiver(removed as ReceiverPreview),
						// FIXME use ReceiverPreview instead of PersonIdentityPreview and remove as
						onUpdate: (updated: PersonIdentityPreview) => updateClientReceiver(accountId, updated as ReceiverPreview),
					}
			),
		},
		requester: {
			context: "requester/requester",
			get: (pagination) => getClientRequesters(accountId, pagination),
			// FIXME use Person instead of PersonIdentityPreview
			onAdd: (added: PersonIdentityPreview) => addClientRequester(accountId, added as Person),
			onSearch: {request: (pagination, search) => searchClientRequesters(accountId, search, pagination)},
			...(
				onSelect
					? {
						onSelect,
					}
					: {
						// FIXME use PersonPreview instead of PersonIdentityPreview
						onDelete: (removed: PersonIdentityPreview) => deleteClientRequester(removed as PersonPreview),
						// FIXME use PersonPreview instead of PersonIdentityPreview
						onUpdate: (updated: PersonIdentityPreview) => updateClientRequester(accountId, updated as PersonPreview),
					}
			),
		},
	});
};

const setTitle = (
	translationKey: "forms:items.add" | "forms:items.select" | "forms:items.update",
	type: SelectPersonScreenParamsType["exportType"],
	receiver: SessionCreationSetting["value"]["detailsStep"]["receiver"],
	medicalProfessional: SessionCreationSetting["value"]["detailsStep"]["medicalProfessional"],
	otherProfessional: SessionCreationSetting["value"]["detailsStep"]["otherProfessional"],
	ct: TranslationFunc,
): string => ps(type, {
	default: ct(translationKey, {item: ct("common:person")}),
	medicalProfessional: ct(translationKey, {item: medicalProfessional?.label ?? ct("common:medicalProfessional")}),
	otherProfessional: ct(translationKey, {item: otherProfessional?.label ?? ct("common:otherProfessional")}),
	receiver: ct(translationKey, {item: receiver?.label ?? ct("common:receiver")}),
	requester: ct(translationKey, {item: ct("common:requester")}),
});

export const SelectPerson = ({
	route,
	navigation,
}: SharedScreenProps<"SelectPersonModal">): JSX.Element => {
	const {exportType, sessionId, onSelect: onSelectParam, filters, pool, selectionOrBase} = route.params;
	const {ct} = useTranslation();
	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			detailsStep: {
				medicalProfessional,
				otherProfessional,
				receiver,
				requester,
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {value: {detailsStep: {}}};
	const {value: listedFields} = getSetting<PersonListedFieldsSetting<Receiver>>(
		"person/listed-fields", "requester/receiver") || {
		value: new Set(["firstName", "lastName", "gender", "evamNumber", "ippNumber", "description"]),
	} as PersonListedFieldsSetting<Receiver>;
	const receiverSettingRequesterApp = getSetting<IdentityEditableFieldsSetting<Receiver>>(
		"identity/editable-fields", "requester/receiver");
	const [receiverListedFields, setReceiverListedFields] = React.useState<PersonListedFields<Receiver>>(listedFields);
	const [receiverEditableFields, setReceiverEditableFields] = React.useState<IdentityEditableFields<Receiver> | undefined>(
		receiverSettingRequesterApp?.value);
	const [loadingReceiverSettings, setLoadingReceiverSettings] = React.useState<boolean>(
		IS_INTERPRETER && exportType === "receiver");
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();

	React.useEffect(
		() => {
			if (IS_INTERPRETER && exportType === "receiver" && sessionId) {
				Promise.all([
					getReceiverListedFields(sessionId)
						.then(setReceiverListedFields),
					getReceiverEditableFields(sessionId)
						.then(setReceiverEditableFields),
				])
					.catch((error_) => {
						Log.error()(error_);
						setErrorMessage("feedbacks:getDataFailed");
					})
					.finally(() => setLoadingReceiverSettings(false));
			}
		},
		[sessionId, exportType],
	);

	const configs = React.useMemo(
		() => selectPersonConfig(
			exportType, accountId!, sessionId, onSelectParam, filters ? JSON.parse(filters) : undefined, selectionOrBase,
			pool,
		),
		[accountId, filters, pool, selectionOrBase, onSelectParam, sessionId, exportType],
	);

	const selectAndClose = React.useCallback(
		(item: PersonIdentityPreview) => {
			onSelectParam?.([item]);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelectParam],
	);

	const request = React.useCallback(
		(item: PersonIdentityPreview) => (
			configs.onAdd!(item)
				.then((added) => {
					if (onSelectParam) {
						selectAndClose(added);
					}
					return added;
				})
		),
		[configs, onSelectParam, selectAndClose],
	);

	const onPressAddConfig = React.useMemo(
		(): ListPropsCommon<"identityId", PersonIdentityPreview, "EditPersonModal">["onPressAdd"] => {
			const canAddNew = s(exportType, {
				interpreter: true,
				interpreterPool: true,
				medicalProfessional: medicalProfessional?.editRights?.canAddNew,
				otherProfessional: otherProfessional?.editRights?.canAddNew,
				receiver: receiver?.editRights?.canAddNew,
				requester: requester?.editRights?.canAddNew,
				sessionPerson: true,
			});
			if (configs.onAdd && canAddNew) {
				return ({
					request,
					screen: {
						name: "EditPersonModal",
						params: {
							context: configs.context!, // can't be undefined if configs.onAdd is not undefined
							title: setTitle(
								"forms:items.add",
								exportType,
								receiver,
								medicalProfessional,
								otherProfessional,
								ct,
							),
						},
					},
				});
			}
		},
		[configs.context, configs.onAdd, ct, exportType, medicalProfessional, otherProfessional, receiver, request, requester?.editRights?.canAddNew],
	);

	const onPressEditConfig = React.useMemo(
		(): ListPropsCommon<"identityId", PersonIdentityPreview, "EditPersonModal">["onPressEdit"] => {
			const canEdit = s(exportType, {
				interpreter: true,
				interpreterPool: true,
				medicalProfessional: medicalProfessional?.editRights?.canEdit,
				otherProfessional: otherProfessional?.editRights?.canEdit,
				receiver: receiver?.editRights?.canEdit,
				requester: requester?.editRights?.canEdit,
				sessionPerson: true,
			});
			if (configs.onUpdate && canEdit) {
				return ({
					request: configs.onUpdate,
					screen: {
						name: "EditPersonModal",
						params: {
							context: configs.context!, // can't be undefined if configs.onUpdate is not undefined
							title: setTitle(
								"forms:items.update",
								exportType,
								receiver,
								medicalProfessional,
								otherProfessional,
								ct,
							),
						},
					},
				});
			}
		},
		[configs.context, configs.onUpdate, ct, exportType, medicalProfessional, otherProfessional, receiver, requester?.editRights?.canEdit],
	);

	const onPressSelectConfig = React.useMemo(
		(): ListPropsCommon<"identityId", PersonIdentityPreview, "EditPersonModal">["onPressSelect"] => {
			if (selectionOrBase && onSelectParam) {
				return ({
					onPress: (items: PersonIdentityPreview[] | string) => {
						if (typeof items !== "string" && Array.isArray(items)) {
							onSelectParam(items);
						}
						navigation.dispatch(forceBack);
					},
				});
			}
		},
		[navigation, selectionOrBase, onSelectParam],
	);

	const onSelect = React.useCallback(
		(item: PersonIdentityPreview, buttons: Buttons) =>
			selectionOrBase
				? filterTruthy(buttons).find(button => button.key === "select")?.onPress
				: () => selectAndClose(item),
		[selectionOrBase, selectAndClose],
	);

	const renderItem = React.useCallback(
		({info: {item}, buttons}: FlatListRenderItemParams<"identityId", PersonIdentityPreview>) => (
			<ListElement
				key={item.identityId}
				onPress={onSelectParam && onSelect(item, buttons)}
				buttons={buttons}
			>
				<View>
					<Text type="emphasis_1">
						{item.type === "receiver"
							? receiverIdentity(item, receiverListedFields, getDescriptionLabel(receiverEditableFields))
							: personIdentity(item)
						}
					</Text>
					{listedFields.has("email") && (
						<View style={styles.emailPhone}>
							<Text type="emphasis_2" color={SUBTLE_4}>
								{`${ct("common:email")}: `}
							</Text>
							<Text type="emphasis_2" color={item.email ? undefined : SUBTLE_4}>
								{item.email ?? ct("common:unknown")}
							</Text>
						</View>
					)}
					{listedFields.has("phone") && (
						<View style={styles.emailPhone}>
							<Text type="emphasis_2" color={SUBTLE_4}>
								{`${ct("common:phone")}: `}
							</Text>
							<Text type="emphasis_2" color={item.phone ? undefined : SUBTLE_4}>
								{item.phone ?? ct("common:unknown")}
							</Text>
						</View>
					)}
					{item.type === "medicalProfessional" && (
						<View style={styles.emailPhone}>
							<Text type="emphasis_2" color={SUBTLE_4}>
								{`${ct("common:institution")}: `}
							</Text>
							<Text type="emphasis_2" color={item.institution ? undefined : SUBTLE_4}>
								{item.institution ?? ct("common:unknown")}
							</Text>
						</View>
					)}
					{(item.type === "medicalProfessional" || item.type === "otherProfessional") && (
						<>
							<View style={styles.emailPhone}>
								<Text type="emphasis_2" color={SUBTLE_4}>
									{`${ct("common:directPhone")}: `}
								</Text>
								<Text type="emphasis_2" color={item.directPhone ? undefined : SUBTLE_4}>
									{item.directPhone ?? ct("common:unknown")}
								</Text>
							</View>
							<View style={styles.emailPhone}>
								<Text type="emphasis_2" color={SUBTLE_4}>
									{`${ct("common:function")}: `}
								</Text>
								<Text type="emphasis_2" color={item.function ? undefined : SUBTLE_4}>
									{item.function ?? ct("common:unknown")}
								</Text>
							</View>
						</>
					)}
				</View>
			</ListElement>
		),
		[ct, listedFields, onSelect, receiverEditableFields, receiverListedFields, onSelectParam],
	);

	const header = (
		<HeaderMenu
			center={setTitle(
				"forms:items.select",
				exportType,
				receiver,
				medicalProfessional,
				otherProfessional,
				ct,
			)}
		/>
	);

	if (loadingReceiverSettings || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingReceiverSettings}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}

	return (
		<>
			{header}
			<SelectFlatList
				deleteRequest={(
					ps(exportType, {
						interpreter: true,
						interpreterPool: true,
						medicalProfessional: medicalProfessional?.editRights?.canDelete,
						otherProfessional: otherProfessional?.editRights?.canDelete,
						receiver: receiver?.editRights?.canDelete,
						requester: requester?.editRights?.canDelete,
						sessionPerson: true,
					})
				)
					? configs.onDelete
					: undefined}
				getRequest={configs.get}
				onPressAdd={onPressAddConfig}
				onPressEdit={onPressEditConfig}
				onPressSelect={onPressSelectConfig}
				itemTranslationKey="common:person"
				renderItem={renderItem}
				onSearch={configs.onSearch}
				idKey="identityId"
			/>
		</>
	);
};

export const SelectPersonModal = (props: SharedScreenProps<"SelectPersonModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectPerson {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	emailPhone: {
		flexDirection: "row",
	},
});
