import * as React from "react";
import {StyleSheet, Text as RNText, TextInput as RNTextInput} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {PLACEHOLDER, PRIMARY_2} from "../../../utils/styles/colors";
import {Text} from "../../texts/text";
import {
	TEXT_INPUT_MIN_HEIGHT,
	TextInput as BaseTextInputRN,
	TextInputProps as BaseTextInputProps,
} from "../../texts/text-input";

const defaultTextInputsProps: {[key in TextInputKey]: BaseTextInputProps} = {
	address: {
		autoCapitalize: "words",
		autoComplete: "street-address",
		keyboardType: "default",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "fullStreetAddress",
	},
	email: {
		autoCapitalize: "none",
		autoComplete: "email",
		keyboardType: "email-address",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "emailAddress",
	},
	evamNumber: {
		autoCapitalize: "none",
		autoComplete: "off",
		keyboardType: "number-pad",
		maxLength: 8,
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "none",
	},
	firstName: {
		autoCapitalize: "words",
		autoComplete: "name",
		keyboardType: "default",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "givenName",
	},
	lastName: {
		autoCapitalize: "words",
		autoComplete: "name",
		keyboardType: "default",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "familyName",
	},
	password: {
		autoCapitalize: "none",
		autoComplete: "password",
		keyboardType: "default",
		secureTextEntry: true,
		spellCheck: false,
		textContentType: "password",
	},
	phone: {
		autoCapitalize: "none",
		autoComplete: "tel",
		keyboardType: "phone-pad",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "telephoneNumber",
	},
	text: {
		autoCapitalize: "sentences",
		autoComplete: "off",
		keyboardType: "default",
		secureTextEntry: false,
		spellCheck: true,
		textContentType: "none",
	},
	username: {
		autoCapitalize: "none",
		autoComplete: "username",
		keyboardType: "default",
		secureTextEntry: false,
		spellCheck: false,
		textContentType: "username",
	},
};

export type TextInputKey =
	"address" | "email" | "evamNumber" | "firstName" | "lastName" | "password" | "phone" | "text" | "username";

export interface TextListProps {
	key: TextInputKey;
	onChangeValue?: (value: string) => void;
	props?: Omit<BaseTextInputProps, "onChangeText" | "style">;
	style?: BaseTextInputProps["style"];
	value?: string;
}

type TextInputProps = Omit<TextListProps, "key"> & {
	disabled?: boolean;
	hasIcon?: boolean;
	hasLabel?: boolean;
	type: TextListProps["key"];
};

const TextInputRenderer = (
	{type, onChangeValue, value, props, disabled, hasIcon, hasLabel, style}: TextInputProps,
	ref: React.Ref<RNText | RNTextInput>,
): JSX.Element => {
	const {ct, t} = useTranslation();
	const multiline = props?.multiline ?? type === "address";
	return disabled
		? (
			<Text
				{...defaultTextInputsProps[type]}
				ref={ref as React.Ref<RNText>}
				style={[
					styles.input,
					(props?.multiline ?? type === "address") && styles.multiline,
					style,
				]}
				color={disabled ? undefined : PRIMARY_2}
				type={onChangeValue ? "emphasis_1" : "default_1"}
				{...props}
			>
				{value ?? ""}
			</Text>
		)
		: (
			<BaseTextInputRN
				{...defaultTextInputsProps[type]}
				ref={ref as React.Ref<RNTextInput>}
				hitSlop={{
					bottom: SMALL_SPACING,
					left: DEFAULT_SPACING + ((hasIcon) ? (24 + DEFAULT_SPACING) : 0),
					right: DEFAULT_SPACING,
					top: SMALL_SPACING + (hasLabel ? 10 : 0),
				}}
				placeholderTextColor={PLACEHOLDER}
				placeholder={props?.placeholder ?? (
					type === "phone"
						? ct("common:phonePlaceholder")
						: t("forms:inputs.typeHere")
				)}
				clearButtonMode="never"
				style={[
					styles.input,
					multiline
						? styles.multiline
						: {minHeight: TEXT_INPUT_MIN_HEIGHT},
					style,
				]}
				type={onChangeValue ? "emphasis_1" : "default_1"}
				multiline={multiline}
				{...props}
				onChangeText={onChangeValue}
				value={value || ""}
			/>
		);
};

export const TextInput = React.forwardRef(TextInputRenderer);

const styles = StyleSheet.create({
	input: {
		paddingVertical: 0,
	},
	multiline: {
		minHeight: 80,
		textAlignVertical: "top",
	},
});
