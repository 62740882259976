import {routeTitles as enUSRouteTitles} from "../en-US/route-titles";

export const routeTitles: typeof enUSRouteTitles = {
	AddressesListModal: "Gespeicherte Adressen",
	AuthScreensGroup: "Einloggen",
	AvailabilitiesModal: "Verfügbarkeiten",
	Calendar: "Agenda",
	CalendarModal: "Agenda",
	CancelOrRescheduleSessionModal: "Sitzung abbrechen",
	ClientAccount: "Profil",
	ComponentsModal: "Components",
	ConfirmationModal: "Bestätigung",
	DebugModal: "Debug",
	DetailsStep: "Details Schritt",
	EditCorporationModal: "Firma bearbeiten",
	EditPaymentAuthorityModal: "Zahlungsbehörde bearbeiten",
	EditPersonModal: "Person bearbeiten",
	EditProfileModal: "Profil bearbeiten",
	EditSessionCategoryModal: "Sitzungskategorie bearbeiten",
	EditSubjectSuggestionModal: "Thema bearbeiten",
	FeedbackModal: "Feedback",
	FollowUpModal: "Folgeeinsatz",
	HomeTabNavigator: "",
	Inbox: "Inbox",
	Interpreter: "Dolmetscher:in",
	InterpreterAccount: "Profil",
	InterpreterModal: "Dolmetscher:in",
	InterpreterRootNavigator: "Inbox",
	Interpreters: "Dolmetschende",
	NewSession: "Neue Sitzung",
	NotificationsModal: "Benachrichtigungen",
	OverlappingSessionsModal: "Abwesenheitswarnung",
	SalaryModal: "Gesamtbeträge",
	ScheduleStep: "Zeitplan Schritt",
	SearchInterpretersModal: "Suchen",
	SearchSessionsModal: "Suchen",
	SelectDateCalendarModal: "Datum auswählen",
	SelectDurationModal: "Dauer auswählen",
	SelectInterpreterModal: "Dolmetscher:in auswählen",
	SelectInterpretersFilterModal: "Filter auswählen",
	SelectLanguageModal: "Sprache auswählen",
	SelectMediaDeviceModal: "Mediengerät auswählen",
	SelectMonthModal: "Monat auswählen",
	SelectNotificationTargetModal: "Wählen Sie ein Benachrichtigungsziel aus",
	SelectOriginModal: "Herkunftsland auswählen",
	SelectPaymentAuthorityModal: "Zahlungsbehörde auswählen",
	SelectPeopleNumberModal: "Anzahl der Gesprächspersonen auswählen",
	SelectPersonModal: "Wählen Sie eine Person aus",
	SelectPlaceModal: "Adresse auswählen",
	SelectQualificationModal: "Qualifikation auswählen",
	SelectReceiverModal: "Empfänger auswählen",
	SelectRequesterModal: "Auftraggebende Person auswählen",
	SelectSessionCategoryModal: "Sitzungskategorie auswählen",
	SelectSessionModal: "Sitzung auswählen",
	SelectSessionOptionsModal: "Option auswählen",
	SelectSessionStatusModal: "Sitzungsstatus auswählen",
	SelectStatusModal: "Status auswählen",
	SelectSubjectSuggestionModal: "Thema auswählen",
	SelectTransportModal: "Transportmittel auswählen",
	SelectValidationIssueModal: "Validierung der Sitzung",
	SelectValidationTypeModal: "Validierung der Sitzung",
	SessionCreationFiltersModal: "Filter",
	SessionModal: "Sitzung",
	SessionValidationModal: "Validierung der Sitzung",
	SetupStep: "Einrichtung Schritt",
	ShareSessionModal: "Teilen",
	SignIn: "Einloggen",
	Slider: "Slider",
	SupportModal: "Kontakt zum Support",
	UnavailabilityModal: "Abwesenheit",
	UserRootNavigator: "Neue Sitzung",
	VideoSession: "Videositzung",
};
