import {BottomTabBarProps, createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {TabNavigationState} from "@react-navigation/native";
import * as React from "react";
import {View} from "react-native";
import {FooterMenu} from "../../../components/menus/footer";
import {MainMenu, menuIcons} from "../../../components/menus/main";
import {ResponsiveContext} from "../../../contexts/responsive";
import {defaultSceneContainerStyle} from "../../../utils/navigation/defaults";
import {ClientHomeTabNavigatorParamList} from "../../../utils/navigation/paramLists/client-param-list";
import {InterpreterHomeTabNavigatorParamList} from "../../../utils/navigation/paramLists/interpreter-param-list";
import {ClientAccount} from "../../screens/client/client-account";
import {Interpreters} from "../../screens/client/interpreters";
import {NewSession} from "../../screens/client/new-session";
import {Calendar} from "../../screens/common/modals/calendar";

const notifs = (routeName: keyof ClientHomeTabNavigatorParamList | keyof InterpreterHomeTabNavigatorParamList): number => {
	switch (routeName) {
		// case "Calendar": return 2;
		default: {
			return 0;
		}
	}
};

const TabBar = ({state, ...props}: BottomTabBarProps): JSX.Element => (
	<FooterMenu
		{...props}
		state={state as TabNavigationState<ClientHomeTabNavigatorParamList & InterpreterHomeTabNavigatorParamList>}
		icons={menuIcons}
		notifications={notifs}
	/>
);

const Tab = createBottomTabNavigator<ClientHomeTabNavigatorParamList>();
export const HomeTabNavigator = (): JSX.Element => {
	const {verticalMenu, displayMainMenu} = React.useContext(ResponsiveContext);
	return (
		<View style={{flex: 1, flexDirection: verticalMenu ? "row" : "column"}}>
			{displayMainMenu && <MainMenu vertical={verticalMenu}/>}
			<Tab.Navigator
				initialRouteName="NewSession"
				tabBar={(props: BottomTabBarProps) => displayMainMenu
					? null
					: <TabBar {...props} />}
				sceneContainerStyle={defaultSceneContainerStyle}
				screenOptions={{headerShown: false}}
				backBehavior="history"
			>
				<Tab.Screen name="NewSession" component={NewSession}/>
				<Tab.Screen name="Calendar" component={Calendar as never}/>
				<Tab.Screen name="Interpreters" component={Interpreters}/>
				<Tab.Screen name="ClientAccount" component={ClientAccount}/>
			</Tab.Navigator>
		</View>
	);
};
