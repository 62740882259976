import React from "react";
import {RefreshControl, RefreshControlProps, StyleSheet} from "react-native";
import {Log} from "../logs/logs";
import {REFRESH_INDICATOR} from "../styles/colors";

export const useRefresh = (onRefresh: () => Promise<unknown>): React.ReactElement<RefreshControlProps> => {
	const [refreshing, setRefreshing] = React.useState(false);
	const refresh = (): void => {
		setRefreshing(true);
		onRefresh()
			.catch(Log.error())
			.finally(() => setRefreshing(false));
	};
	return (
		<RefreshControl
			style={styles.refreshControl}
			refreshing={refreshing}
			onRefresh={refresh}
			colors={[REFRESH_INDICATOR]}
			tintColor={REFRESH_INDICATOR}
		/>
	);
};

const styles = StyleSheet.create({
	refreshControl: {zIndex: 100},
});
