import * as React from "react";
import {StyleSheet, View} from "react-native";
import {checkNeedUpdate} from "../../packages/version/check";
import {APP_STORE_URL, IS_IOS, IS_MOBILE, IS_WEB, PLAY_STORE_URL, SMALL_SPACING} from "../../utils/constants";
import {getTimeCoef} from "../../utils/date-time/helpers";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Log} from "../../utils/logs/logs";
import {linkToURL} from "../../utils/network/links";
import {SUBTLE_3, TRANSPARENT} from "../../utils/styles/colors";
import {Button} from "../buttons/button";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";
import {Banner} from "./banner";

const storeRedirect = (): void => {
	linkToURL(IS_IOS ? APP_STORE_URL : PLAY_STORE_URL).catch(Log.error());
};

export const BannerUpdate = ({backgroundColor}: {backgroundColor?: string}): JSX.Element | null => {
	const {t} = useTranslation();
	const [showBannerUpdate, setShowBannerUpdate] = React.useState(false);

	const checkUpdate = React.useCallback(
		() => {
			if (IS_MOBILE) {
				checkNeedUpdate()
					.then((needUpdate) => needUpdate && setShowBannerUpdate(true))
					.catch((error) => Log.error()(
						"check need update failed",
						{message: typeof error === "string" ? error : error?.message},
					));
			}
		},
		[],
	);

	React.useEffect(
		() => {
			checkUpdate();
			setInterval(checkUpdate, getTimeCoef("hour"));
		},
		[checkUpdate],
	);

	const close = React.useCallback(
		() => setShowBannerUpdate(false),
		[],
	);

	if (IS_WEB) {
		return null;
	}

	return (
		<Banner backgroundColor={backgroundColor} open={showBannerUpdate} timer={60_000} style={styles.banner}>
			<View style={styles.wrapper}>
				<Text type="button_1">
					{t("screens:home.updateBanner.title")}
				</Text>
				<Text centered>
					{t("screens:home.updateBanner.subtitle", {store: t(`screens:home.updateBanner.${IS_IOS ? "appStore" : "googlePlay"}`)})}
				</Text>
				<Spacer size={SMALL_SPACING} mode="horizontal"/>
				<View style={styles.buttons}>
					<Button
						text={t("screens:home.updateBanner.later")}
						type="blank"
						size="xsmall"
						onPress={close}
					/>
					<Spacer size={SMALL_SPACING} mode="vertical"/>
					<Button
						text={t("screens:home.updateBanner.update")}
						type="primary"
						size="xsmall"
						onPress={storeRedirect}
					/>
				</View>
			</View>
		</Banner>
	);
};

const styles = StyleSheet.create({
	banner: {
		backgroundColor: TRANSPARENT,
		borderColor: SUBTLE_3,
		borderWidth: 1,
	},
	buttons: {
		flexDirection: "row",
	},
	wrapper: {
		alignItems: "center",
	},
});
