import * as React from "react";
import {ListRenderItemInfo, ScrollViewProps, SectionListRenderItemInfo, StyleSheet, View} from "react-native";
import {Separator} from "../components/separator";
import {Spacer} from "../components/spacer";
import {Text} from "../components/texts/text";
import {CONTAINERS, DEFAULT_SPACING, IS_WEB, SMALL_SPACING} from "./constants";
import {BACKGROUND_COLOR, SUBTLE_4} from "./styles/colors";

interface SectionArg<S, I> {
	highlighted: boolean;
	leadingItem: S;
	leadingSection: I;
	trailingItem: I;
	trailingSection: S;
}

const renderItemSectionList = <Item, Section>({item}: SectionListRenderItemInfo<Item, Section>): JSX.Element => <>{item}</>;
// eslint-disable-next-line @typescript-eslint/comma-dangle
const renderItemFlatList = <Item, >({item}: ListRenderItemInfo<Item>): JSX.Element => <>{item}</>;
const renderListSeparator = (): JSX.Element => <Separator spacingSize={0}/>;
const renderSectionSeparator = ({trailingItem, trailingSection}: SectionArg<unknown, unknown>): JSX.Element | null =>
	(trailingSection && !trailingItem)
		? <Spacer size={SMALL_SPACING}/>
		: null;

export const scrollViewDefaultProps: Partial<ScrollViewProps> = {
	alwaysBounceHorizontal: false,
	alwaysBounceVertical: false,
	contentInsetAdjustmentBehavior: "always",
	keyboardDismissMode: "none",
	keyboardShouldPersistTaps: "handled",
	scrollEventThrottle: 16,
	showsHorizontalScrollIndicator: IS_WEB,
	showsVerticalScrollIndicator: IS_WEB,
};

export const flatListDefaultProps = {
	...scrollViewDefaultProps,
	ItemSeparatorComponent: renderListSeparator,
	renderItem: renderItemFlatList,
};

const renderSectionHeader = ({section: {title}}: any): JSX.Element | null =>
	title
		? (
			<View style={styles.headerBackground}>
				<View style={[CONTAINERS.MAIN, styles.headerContainer]}>
					<Text type="emphasis_2" color={SUBTLE_4}>{title}</Text>
				</View>
			</View>
		)
		: null;

const styles = StyleSheet.create({
	headerBackground: {
		backgroundColor: BACKGROUND_COLOR,
	},
	headerContainer: {
		padding: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
});

export const sectionListDefaultProps = {
	...scrollViewDefaultProps,
	ItemSeparatorComponent: renderListSeparator,
	SectionSeparatorComponent: renderSectionSeparator,
	renderItem: renderItemSectionList,
	renderSectionHeader,
	stickySectionHeadersEnabled: true,
};
