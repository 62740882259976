import * as React from "react";
import {SessionValidationType, sessionValidationTypes} from "../../../../@types/validation";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {paginate} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";

type ListItem = Item<"id", {displayed: string; id: SessionValidationType}>;

const SelectValidationType = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"SelectValidationTypeModal">): JSX.Element => {
	const {onSelect: onSelectParam, excludedValidationTypes = []} = route.params;
	const {t} = useTranslation();
	const onSelect = (validationType: SessionValidationType) => () => {
		onSelectParam?.(validationType);
		navigation.dispatch(forceBack);
	};
	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", ListItem>): JSX.Element => (
		<ListElement
			onPress={onSelect(item.id)}
			buttons={buttons}
		>
			{item.displayed}
		</ListElement>
	);
	const list: ListItem[] = sessionValidationTypes
		.filter(validationType => !excludedValidationTypes.includes(validationType))
		.map(validationType => ({displayed: t(`activities:sessions.validate.type.${validationType}`), id: validationType}));
	return (
		<>
			<HeaderMenu center={t("activities:sessions.validate.type.select")}/>
			<SelectFlatList
				getRequest={(pagination) => Promise.resolve(paginate(list, pagination))}
				renderItem={renderItem}
				itemTranslationKey="activities:sessions.validate.type.select"
				idKey="id"
			/>
		</>
	);
};

export const SelectValidationTypeModal = (props: InterpreterStackNavigatorScreenProps<"SelectValidationTypeModal">): JSX.Element => (
	<ModalWrapper>
		<SelectValidationType {...props} />
	</ModalWrapper>
);
