import {SharedModalsScreensGroupParamList} from "../paramLists/shared-screens-param-list";
import {modalPaths} from "./modal-paths";

export const sharedPaths: {[Key in keyof SharedModalsScreensGroupParamList]: string} = {
	AddEmailModal: modalPaths.AddEmailModal,
	CalendarModal: modalPaths.CalendarModal,
	ComponentsModal: modalPaths.ComponentsModal,
	DebugModal: modalPaths.DebugModal,
	NotificationsModal: modalPaths.NotificationsModal,
	SearchSessionsModal: modalPaths.SearchSessionsModal,
	SelectDateCalendarModal: modalPaths.SelectDateCalendarModal,
	SelectLanguageModal: modalPaths.SelectLanguageModal,
	SelectNotificationTargetModal: modalPaths.SelectNotificationTargetModal,
	SelectOriginModal: modalPaths.SelectOriginModal,
	SelectPeopleNumberModal: modalPaths.SelectPeopleNumberModal,
	SelectPersonModal: modalPaths.SelectPersonModal,
	SelectPlaceModal: modalPaths.SelectPlaceModal,
	SelectQualificationModal: modalPaths.SelectQualificationModal,
	SelectSessionStatusModal: modalPaths.SelectSessionStatusModal,
	SessionModal: modalPaths.SessionModal,
	ShareSessionModal: modalPaths.ShareSessionModal,
	SliderModal: modalPaths.SliderModal,
	SupportModal: modalPaths.SupportModal,
};
