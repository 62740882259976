import {Place} from "../@types/places";
import {GOOGLE_MAPS_API_KEY} from "./constants";
import {Log} from "./logs/logs";

export interface GeoLocation {
	accuracy?: number;
	location?: Place["geocode"];
	timeStamp?: number;
}

const cache: GeoLocation = {
	accuracy: undefined,
	location: undefined,
	timeStamp: undefined,
};

const CACHE_TIMEOUT = 24 * 60 * 60 * 1000; // 24h

export const getGeoLocation = (): Promise<GeoLocation> => {
	if (!cache.timeStamp || Date.now() - cache.timeStamp > CACHE_TIMEOUT) {
		return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_API_KEY}`, {method: "POST"})
			.then(response => response.json())
			.then((data: Pick<GeoLocation, "accuracy" | "location">) => {
				cache.accuracy = data.accuracy;
				cache.location = data.location;
				cache.timeStamp = Date.now();
				return cache;
			})
			.catch(error => {
				Log.error()(error);
				cache.accuracy = undefined;
				cache.location = undefined;
				cache.timeStamp = undefined;
				return cache;
			});
	}
	return Promise.resolve(cache);
};
