import * as React from "react";
import {Falsy, Keyboard, LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {SMALL_SPACING} from "../../../utils/constants";
import {StackParamList} from "../../../utils/navigation/paramLists/root-param-list";
import {searchIndexOf} from "../../../utils/searches";
import {SUBTLE_2, SUBTLE_4} from "../../../utils/styles/colors";
import {ButtonProps} from "../../buttons/button";
import {Icon} from "../../icons";
import {ListElement} from "../../list/items/list-element";
import {Item, ListPropsCommon, OnlyKeysExtendingSelectListType} from "../../list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../list/select-flatlist";
import {OpenSans} from "../../texts/fonts";
import {Text} from "../../texts/text";
import {Pressable} from "../../views/pressable";
import {buttonInputStyles} from "../button";
import {InlineSelectInput, InlineSelectListProps} from "./inline-select-input";

export type DropdownSelectListProps<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> = ListPropsCommon<IdKey, I, RouteName> & {
	containerStyle?: StyleProp<ViewStyle>;
	disabled?: boolean;
	getLabelText: (item: I) => string;
	key: "dropdownSelect";
	multiple?: boolean;
	onChangeValue: (value: I[] | null) => void;
	onSearch: ListPropsCommon<IdKey, I, RouteName>["onSearch"];
	selectedItemsProps?: (item: I) => ButtonProps;
	value?: I[] | null;
	wordsBeginBySearch?: boolean;
};

export const DropdownSelectInput = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
>
(props: Omit<DropdownSelectListProps<IdKey, I, RouteName>, "key">): JSX.Element => {
	const {
		getRequest,
		getLabelText,
		selectedItemsProps,
		multiple,
		onChangeValue,
		onSearch,
		onPressAdd: onPressAddProp,
		value,
		disabled,
		idKey,
		itemTranslation,
		itemTranslationKey,
		wordsBeginBySearch,
	} = props;
	const [dropdownOpened, setDropdownOpened] = React.useState(false);
	const [inlineSelectCollapsed, setInlineSelectCollapsed] = React.useState(false);
	const [width, setWidth] = React.useState<number>(0);
	const ref = React.useRef<View>(null);

	const onPressDropdownItem = React.useCallback(
		(item: I) => () => {
			if (!multiple) {
				setDropdownOpened(false);
			}
			const hasValue = !!value?.map(v => v[idKey]).includes(item[idKey]);
			if (!hasValue) { // item not already selected, we add it
				return onChangeValue(multiple ? [...(value ?? []), item] : [item]);
			}
			if (hasValue) { // item already selected, we delete it
				const newValue = value!.filter(v => v[idKey] !== item[idKey]);
				return onChangeValue(multiple ? newValue : [item]);
			}
		},
		[idKey, multiple, onChangeValue, value],
	);

	const renderItem = React.useCallback(
		({info: {item}, buttons, currentSearch, focusedStyle}: FlatListRenderItemParams<IdKey, I>) => {
			const itemLabel = getLabelText(item);
			const searchIndex = searchIndexOf(currentSearch, itemLabel, !!wordsBeginBySearch);

			return (
				<ListElement
					onPress={onPressDropdownItem(item)}
					buttons={buttons}
					style={[focusedStyle, value?.map(v => v[idKey]).includes(item[idKey]) && {backgroundColor: SUBTLE_2}]}
				>
					{currentSearch?.length > 0 && searchIndex > -1
						? (
							<Text>
								<Text type="emphasis_1" style={styles.regular}>
									{itemLabel.slice(0, searchIndex)}
								</Text>
								<Text type="emphasis_1" style={styles.bold}>
									{itemLabel.slice(searchIndex, searchIndex + currentSearch.length)}
								</Text>
								<Text type="emphasis_1" style={styles.regular}>
									{itemLabel.slice(searchIndex + currentSearch.length)}
								</Text>
							</Text>
						)
						: (
							<Text type="emphasis_1">
								{itemLabel}
							</Text>
						)}
				</ListElement>
			);
		},
		[getLabelText, idKey, onPressDropdownItem, value, wordsBeginBySearch],
	);

	const onPressIcon = React.useCallback(
		(item: I) => () => {
			const index = value?.map(v => v[idKey]).indexOf(item[idKey]);
			const newValue = index === undefined || !value
				? []
				: [
					...(value.slice(0, index)),
					...(value.slice(index + 1)),
				];
			onChangeValue(newValue.length > 0 ? newValue : null);
		},
		[idKey, onChangeValue, value],
	);

	const itemProps: InlineSelectListProps["itemProps"] = React.useMemo(
		() => (item: I): ButtonProps => {
			// don't display the cross for the "notRelevant" value (e.g. in the Origin field)
			const removeIcon: ButtonProps | Falsy = item[idKey] !== "notRelevant" && {
				icon: "close",
				iconPosition: "after",
				onPressIcon: onPressIcon(item),
			};
			return {
				active: false,
				...removeIcon,
				...selectedItemsProps,
			};
		},
		[idKey, onPressIcon, selectedItemsProps],
	);

	const onPressAdd = React.useMemo(
		(): ListPropsCommon<IdKey, I, RouteName>["onPressAdd"] => onPressAddProp && ({
			...onPressAddProp,
			request: (added: I) => onPressAddProp?.request(added).then(result => {
				onPressDropdownItem(result)();
				return result;
			}),
		}),
		[onPressAddProp, onPressDropdownItem],
	);

	const toggleCollapse = React.useCallback(
		() => {
			Keyboard.dismiss();
			setInlineSelectCollapsed(prev => !prev);
		},
		[],
	);

	const onPressInlineSelectItem = React.useCallback(
		() => setDropdownOpened(true),
		[],
	);

	const onPressDropdownIcon = React.useCallback(
		() => setDropdownOpened(prev => !prev),
		[],
	);

	const onLayout = React.useCallback(
		({nativeEvent: {layout: {width}}}: LayoutChangeEvent) => {
			setWidth(width);
		},
		[],
	);

	return (
		<Pressable
			style={!!value && [buttonInputStyles.buttonStyle, styles.container]}
			onLayout={onLayout}
			onPress={onPressDropdownIcon}
			ref={ref}
		>
			<InlineSelectInput
				choices={value}
				getLabelText={getLabelText}
				itemProps={itemProps}
				multiple={multiple}
				onChangeValue={onChangeValue}
				value={value}
				disabled={disabled}
				toggleCollapse={toggleCollapse}
				collapsed={inlineSelectCollapsed}
				onPressItem={onPressInlineSelectItem}
			/>
			<SelectFlatList
				getRequest={getRequest}
				onSearch={onSearch}
				onPressAdd={onPressAdd}
				{...(itemTranslation ? {itemTranslation} : {itemTranslationKey: itemTranslationKey!})}
				idKey={idKey}
				renderItem={renderItem}
				onPressEnter={onPressDropdownItem}
				dropDown={{
					disableInput: !!value,
					dropdownOpened,
					setDropdownOpened,
					trigger: ref,
					width,
				}}
			/>
			{!!value && <Icon
				style={styles.rightIcon}
				icon="chevronBottom"
				containerSize={28}
				size={20}
				color={SUBTLE_4}
				onPress={onPressDropdownIcon}
			/>}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	bold: {
		fontFamily: OpenSans.Bold,
	},
	container: {
		justifyContent: "space-between",
		width: "100%",
	},
	regular: {
		fontFamily: OpenSans.Regular,
	},
	rightIcon: {
		alignSelf: "center",
		marginRight: -SMALL_SPACING,
	},
});
