import * as React from "react";
import {RatingValue} from "../../../utils/feedbacks";
import {Rating} from "../../rating";

export interface RatingListProps {
	key: "rating";
	onChangeValue?: (value: RatingValue) => void;
	value?: RatingValue;
}

type RatingInputProps = Omit<RatingListProps, "key"> & {
	disabled?: boolean;
};

export const RatingInput = ({onChangeValue, value, disabled}: RatingInputProps): JSX.Element =>
	<Rating value={value} onChangeValue={onChangeValue} disabled={disabled}/>;
