import * as React from "react";
import {StyleSheet, View} from "react-native";
import {LocaleContext} from "../../contexts/locale";
import {CONTAINERS, SMALL_SPACING, LABELS_HEIGHT} from "../../utils/constants";
import {daysOfTheWeek} from "../../utils/date-time/helpers";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Text} from "../texts/text";

export const DaysRow = ({startOfWeek: startOfWeekProps}: {startOfWeek?: number}): JSX.Element => {
	const {ct} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const startOfWeek = startOfWeekProps || 1;
	return (
		<View style={CONTAINERS.MAIN}>
			<View style={styles.container}>
				{daysOfTheWeek.map((day, index) => (
					<View key={day} style={styles.day}>
						<Text type="label">
							{(ct(`activities:calendar.days.${daysOfTheWeek[(index + startOfWeek) % daysOfTheWeek.length]}`))
								.slice(0, locale === "de-DE" ? 2 : 3)}
						</Text>
					</View>
				))}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		paddingHorizontal: SMALL_SPACING,
	},
	day: {
		alignItems: "center",
		height: LABELS_HEIGHT,
		justifyContent: "center",
		width: "14.2857142857%",
	},
});
