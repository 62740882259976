import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {NativeSyntheticEvent, StyleSheet, TextInputKeyPressEventData, View} from "react-native";
import {Page, Pagination} from "../../@types/pagination";
import {Button, Buttons} from "../../components/buttons/button";
import {confirmation} from "../../components/feedbacks/confirmation";
import {Icon, IconKey} from "../../components/icons";
import {ButtonInput, buttonInputStyles} from "../../components/inputs/button";
import {
	Item,
	ListPropsCommon,
	OnlyKeysExtendingSelectListType,
	OnlyParamListExtendingSelectListType,
} from "../../components/list/list-props.common";
import {Text} from "../../components/texts/text";
import {Pressable} from "../../components/views/pressable";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../constants";
import {Debouncer} from "../debouncer";
import {Log} from "../logs/logs";
import {StackParamList} from "../navigation/paramLists/root-param-list";
import {updatePageItems} from "../pagination";
import {PLACEHOLDER, PRIMARY, PRIMARY_2, RED, SUBTLE_2, SUBTLE_4, WHITE} from "../styles/colors";
import {usePagination} from "./use-pagination";
import {useTranslation} from "./use-translation";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSelectList = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	Screen extends OnlyKeysExtendingSelectListType<StackParamList, I>,
>({
	deleteRequest,
	getRequest,
	onPressAdd: onPressAddProp,
	onPressEdit: onPressEditProp,
	onPressSelect: onPressSelectProp,
	onSearch,
	itemTranslation,
	itemTranslationKey,
	idKey,
	refreshable = false,
	dropDown,
	onPressEnter,
}: ListPropsCommon<IdKey, I, Screen>) => {
	const {t, ct} = useTranslation();
	const searchDebouncerRef = React.useRef(new Debouncer());
	const [search, setSearch] = React.useState("");
	const [focusedItemIndex, setFocusedItemIndex] = React.useState<number>(-1);

	const searchRequest = React.useCallback(
		(pagination: Pagination) => {
			setFocusedItemIndex(-1);
			if (onSearch) {
				return onSearch?.request(pagination, onSearch?.value ?? search);
			}
			throw new Error("onSearch not defined!");
		},
		[onSearch, search],
	);
	const {
		items,
		setItems,
		errorMessage,
		isMoreAfter,
		loading,
		loadMoreAfter,
		loadingMoreAfter,
		reloadItems,
		refreshControl,
	} = usePagination((onSearch?.value ?? search) === "" ? getRequest : searchRequest);

	const navigation = useNavigation();
	const navigate = (
		screen: Screen,
		params?: OnlyParamListExtendingSelectListType<StackParamList, I>[Screen],
	) => () => {
		dropDown?.setDropdownOpened(false);
		// @ts-expect-error FIXME: Typescript error to FIX
		navigation.navigate(screen, params);
	};

	const onKeyPress = React.useCallback(
		(event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
			if (event.nativeEvent.key === "ArrowUp") {
				setFocusedItemIndex(prev => prev - 1 > 0 ? prev - 1 : 0);
			} else if (event.nativeEvent.key === "ArrowDown") {
				const listLength = items.length - 1;
				setFocusedItemIndex(prev => prev + 1 < listLength ? prev + 1 : listLength);
			} else if (event.nativeEvent.key === "Enter" && !!onPressEnter) {
				if (focusedItemIndex === -1) {
					dropDown?.setDropdownOpened(false);
				} else {
					onPressEnter(items[focusedItemIndex])();
				}
			}
		},
		[dropDown, focusedItemIndex, items, onPressEnter],
	);

	const onChangeText = React.useCallback(
		(text: string) => searchDebouncerRef.current.debounce(
			() => {
				if (onSearch?.onChangeSearch) {
					onSearch.onChangeSearch(text);
				} else {
					setSearch(text ?? "");
				}
			},
			400,
		),
		[onSearch],
	);

	React.useEffect(
		() => {
			if (!onSearch?.onChangeSearch) {
				searchDebouncerRef.current.debounce(
					() => setSearch(onSearch?.value ?? ""),
					400,
				);
			}
		},
		[onChangeText, onSearch?.onChangeSearch, onSearch?.value],
	);

	const onPressSelect = React.useCallback(
		() => {
			const selectedItems = items.filter(item => item.selected);
			return onPressSelectProp?.onPress(
				onPressSelectProp.canSelectSearch && selectedItems.length === 0
					? onSearch?.value ?? search
					: selectedItems,
			);
		},
		[items, onPressSelectProp, onSearch?.value, search],
	);

	const onDelete = (item: I): void => confirmation({
		actions: [
			{
				backgroundColor: RED,
				icon: "check",
				isPromise: true,
				key: "validate",
				onPress: () => deleteRequest?.(item)
					.then(() => {
						reloadItems();
						Log.success("deleteDataSuccess")();
					})
					.catch(Log.error("deleteDataFailed")),
				text: itemTranslationKey ?? itemTranslation
					? ct("forms:items.delete", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation})
					: ct("common:delete"),
			}, {
				icon: "close",
				key: "closeDrawer",
				onPress: () => null,
				text: ct("common:cancel"),
				type: "secondary",
			},
		],
		content: t("commonSentences:confirmDelete"),
	});
	const onPressEdit = (edited: I): (() => void) | undefined => onPressEditProp &&
		navigate(onPressEditProp.screen.name, {
			...onPressEditProp.screen.params,
			onSelect: (edited: I) => {
				onPressEditProp.request(edited)
					.then(() => {
						Log.success("updateDataSuccess")();
						reloadItems();
					})
					.catch(Log.error("updateDataFailed"));
			},
			selectionOrBase: edited,
			title: (onPressEditProp.screen.params as unknown as {title: string})?.title ||
				ct("forms:items.update", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation}),
		});
	const actionButtons = (item: I): Buttons => {
		const setSelectItem = (currentItems: Page<I>): Page<I> => {
			const newItems: I[] = [...currentItems.items];
			const itemIndex = newItems.findIndex(i => i[idKey] === item[idKey]);
			newItems[itemIndex].selected = !newItems[itemIndex].selected;
			return updatePageItems(newItems, currentItems);
		};
		return [
			onPressEditProp && {
				icon: "edit" as IconKey,
				key: "edit",
				onPress: onPressEdit(item),
			},
			deleteRequest && {
				icon: "delete" as IconKey,
				key: "delete",
				onPress: () => onDelete(item),
			},
			onPressSelectProp && !onPressSelectProp.disableMultipleSelection && {
				backgroundColor: item.selected ? PRIMARY : SUBTLE_2,
				contentColor: item.selected ? WHITE : PRIMARY_2,
				icon: "check" as IconKey,
				key: "select",
				onPress: () => setItems(setSelectItem),
			},
		];
	};
	const onPressAdd = onPressAddProp && navigate(onPressAddProp.screen.name, {
		...onPressAddProp.screen.params,
		onSelect: (added: I) => {
			onPressAddProp.request(added)
				.then(() => {
					reloadItems();
					Log.success("createDataSuccess")();
				})
				.catch(Log.error("createDataFailed"));
		},
		title: (onPressAddProp.screen.params as unknown as {title: string})?.title ||
			ct("forms:items.add", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation}),
	});

	const onFocus = React.useCallback(
		(): void => {
			if (dropDown) {
				return dropDown.setDropdownOpened(true);
			}
		},
		[dropDown],
	);

	const onPressDropdownIcon = React.useCallback(
		() => dropDown?.setDropdownOpened(prev => !prev),
		[dropDown],
	);

	const dropdownInput = !!dropDown && !!onSearch && !dropDown.disableInput && (
		<Pressable
			style={[buttonInputStyles.buttonStyle, styles.dropdownPressable]}
			onFocus={onFocus}
		>
			<Text color={PLACEHOLDER}>
				{onSearch.placeholder ?? `${ct("common:search")}...`}
			</Text>
			<Icon
				style={styles.icon}
				icon="chevronBottom"
				containerSize={28}
				size={20}
				color={SUBTLE_4}
				onPress={onPressDropdownIcon}
			/>
		</Pressable>
	);

	const headerComponent = (!!onSearch || !!onPressAddProp) && (
		<View style={dropDown
			? !dropDown.inlineSearch && styles.headerComponentInInlinedDropdown
			: [
				CONTAINERS.MAIN,
				styles.container,
			]}>
			{!!onSearch && (!dropDown?.inlineSearch || !dropDown?.disableInput) && (
				<ButtonInput
					fullWidth
					onChangeText={onChangeText}
					onFocus={onFocus}
					onKeyPress={onPressEnter ? onKeyPress : undefined}
					value={onSearch?.value ?? search}
					autoFocus={!!dropDown && !dropDown.inlineSearch}
					leftIcon={dropDown?.inlineSearch ? null : undefined}
					disableButtonStyle={dropDown?.inlineSearch}
					placeholder={
						onSearch?.placeholder ?? (
							dropDown?.inlineSearch
								? t("forms:inputs.typeHere")
								: `${ct("common:search")}...`
						)
					}
				/>
			)}
			{!!onPressAddProp && !dropDown && (
				<Button
					size="small"
					icon="plus"
					style={!!onSearch && styles.marginLeft}
					fullWidth={!onSearch}
					disabled={!!errorMessage}
					text={onSearch
						? undefined
						: (itemTranslationKey || itemTranslation
							? ct("forms:items.add", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation})
							: ct("common:add"))
					}
					onPress={onPressAdd}
				/>
			)}
		</View>
	);
	const selectFooterComponent = !!onPressSelectProp && (
		<View
			style={[
				CONTAINERS.MAIN,
				styles.selectContainer,
			]}
		>
			<Button
				fullWidth
				size="small"
				text={`${ct("common:select")}${onPressSelectProp?.disableMultipleSelection &&
				(onSearch?.value ?? search).length > 0
					? ` ${onSearch?.value ?? search}`
					: ""}`}
				icon="check"
				onPress={onPressSelect}
			/>
		</View>
	);

	const dropdownAddFooterComponent = !!onPressAddProp && !!dropDown && (
		<View
			style={[
				CONTAINERS.MAIN,
				styles.addContainer,
			]}
		>
			<Button
				size="small"
				icon="plus"
				fullWidth
				text={(itemTranslationKey || itemTranslation
					? ct("forms:items.add", {item: itemTranslationKey ? t(itemTranslationKey) : itemTranslation})
					: ct("common:add"))}
				onPress={onPressAdd}
			/>
		</View>
	);
	const keyExtractor = (item: I): string => String(item[idKey]);

	return ({
		actionButtons,
		dropdownInput,
		errorMessage,
		focusedItemIndex,
		footerComponent: selectFooterComponent || dropdownAddFooterComponent,
		headerComponent,
		isMoreAfter,
		items,
		keyExtractor,
		loadMoreAfter,
		loading,
		loadingMoreAfter,
		refreshControl: refreshable && refreshControl,
		reloadItems,
		search,
	});
};

const styles = StyleSheet.create({
	addContainer: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	container: {
		flexDirection: "row",
		minWidth: 40,
		paddingBottom: SMALL_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
	dropdownPressable: {
		justifyContent: "space-between",
		width: "100%",
	},
	headerComponentInInlinedDropdown: {
		margin: SMALL_SPACING,
	},
	icon: {
		alignSelf: "center",
		marginRight: -SMALL_SPACING,
	},
	marginLeft: {
		marginLeft: SMALL_SPACING,
	},
	selectContainer: {
		paddingBottom: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: SMALL_SPACING,
	},
});
