import {InterpreterInboxMandatePreview} from "../../../@types/activities/mandate";
import {Session, SessionPreview} from "../../../@types/activities/session";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {InboxSessionList} from "../../@types/activities/session";
import {transformBackendResponseToCalendarInterpreterMandatePreview} from "../../utils/transform";

export type SessionPreviewsRequests = {
	GET: {
		queryParams: {
			sessionIds: Session["activityId"],
			limit: string,
			offset: string,
		},
	},
};

export const getSessionPreviews = async (
	ids: Session["activityId"][],
	pagination: Pagination,
): Promise<Page<SessionPreview>> => {
	const {offset, limit} = pagination;
	const backendOffset = offset === 0 ? 1 : (offset / limit + 1);
	const sessionPreviewsResponse = await api(
		"fetchSessionPreviews",
		"GET",
		{
			queryParams: {
				sessionIds: ids.toString(),
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	) as ApiResponse<{sessionList: InboxSessionList[], total: number}>;

	if (sessionPreviewsResponse?.isSuccess) {
		const {sessionList, total} = sessionPreviewsResponse.data;
		const sessionPreviewList: InterpreterInboxMandatePreview[] = sessionList.map((session) =>
			transformBackendResponseToCalendarInterpreterMandatePreview(session));

		return {
			items: sessionPreviewList,
			hasPreviousPage: offset > 0,
			hasNextPage: total > offset,
			totalCount: total,
		};
	}
	throw new Error(sessionPreviewsResponse.message);
};
