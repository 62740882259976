import {CommunicationType} from "../../../../@types/activities/session";
import {Interpreter, InterpreterPreview} from "../../../../@types/identities/interpreter";
import {Person} from "../../../../@types/identities/person";
import {MedicalProfessional, OtherProfessional} from "../../../../@types/identities/professional";
import {Receiver} from "../../../../@types/identities/receiver";
import {AvailableSessionLanguagesCommunication} from "../../../../@types/language-translation";
import {
	AvailabilitySetting,
	IdentityEditableFieldsSetting,
	NotificationTargetsSetting,
	PersonListedFieldsSetting,
	SessionCancelAndRescheduleSetting,
	SessionCommunicationSetting,
	SessionCreationSetting,
	Setting,
	SupportInfosSetting,
	ToccoSetting,
} from "../../../../@types/settings";
import {SessionLanguagesKey} from "../../../sessions/languages";
import {interpretersExamples} from "../identities/data";
import {corporationIdentityId, interpreterIdentityId} from "../identities/shared-data";
import {requesterReceiverEditableFields} from "./shared-data";

const notificationTargets: NotificationTargetsSetting = {
	context: "account",
	key: "notification/targets",
	owner: interpreterIdentityId,
	settingId: "notificationTargets",
	value: [
		{
			channel: "APNS",
			deletable: true,
			displayed: "iPhone X of mister X",
			id: "fakeAPNSTokenID",
			notified: true,
		}, {
			channel: "FCM",
			deletable: true,
			displayed: "Samsung S10 of mister X",
			id: "fakeFCMTokenID",
			notified: false,
		}, {
			channel: "WEB",
			deletable: true,
			displayed: "Computer Name of mister X",
			id: "fakeWEBTokenID",
			notified: true,
		}, {
			channel: "EMAIL",
			deletable: false,
			displayed: "Cartias",
			id: "test@test.com",
			notified: true,
		}, {
			channel: "SMS",
			deletable: false,
			displayed: "Caritas",
			id: "0041791234567",
			notified: true,
		}, {
			channel: "APNS_SANDBOX",
			deletable: false,
			displayed: "Staging iPhone",
			id: "fakeAPNSSANDOBOXTokenID",
			notified: true,
		},
	],
};

const availabilityAvailabilities: AvailabilitySetting = {
	context: "account",
	key: "availability/availabilities",
	owner: interpreterIdentityId,
	settingId: "availabilityAvailabilities",
	value: {
		immediate: true,
		nights: {
			end: new Date(1970, 0, 1, 7, 0, 0),
			start: new Date(1970, 0, 0, 20, 0, 0),
			value: true,
		},
		weekend: {
			end: new Date(1970, 0, 5, 7, 0, 0),
			start: new Date(1970, 0, 2, 20, 0, 0),
			value: false,
		},
	},
};

const interpreterAccount: IdentityEditableFieldsSetting<Interpreter> = {
	context: "account",
	key: "identity/editable-fields",
	owner: interpreterIdentityId,
	settingId: "interpreterAccount",
	value: {
		// [editable, mandatory, shown in list, availableValues]
		communicationTypes: [false, true, null],
		dateOfBirth: [false, true, null],
		email: [false, true, null],
		fullName: [
			true, true, {
				firstName: [true, false, null],
				lastName: [true, true, null],
			},
		],
		gender: [true, true, null],
		languages: [false, true, null],
		origin: [true, true, ["GB", "CH", "FR", "AR"]],
		phone: [false, true, null],
		picture: [true, true, null],
		place: [true, true, null],
		providedServices: [false, true, null],
		qualifications: [false, true, null],
		transport: [true, true, null],
		type: [false, true, ["interpreter"]],
	},
};

const sessionCommunicationInterpreter: SessionCommunicationSetting = {
	context: "organization/all",
	key: "session/communication",
	owner: interpreterIdentityId,
	settingId: "sessionCommunicationInterpreter",
	value: {
		inPerson: true,
		phone: true,
		video: true,
	},
};

/*
 * const identityEditableFields: IdentityEditableFieldsSetting<Person> = {
 *  settingId: "identityEditableFields",
 *  owner: corporationIdentityId,
 *  key: "identity/editable-fields",
 *  context: "organization/all",
 *  value: {
 *    type: [true, false, ["person"]],
 *    gender: [true, true, null],
 *    fullName: [true, false, {
 *      firstName: [true, false, null],
 *      lastName: [true, true, null],
 *    }],
 *    dateOfBirth: [true, false, null],
 *    email: [true, true, null],
 *    phone: [true, true, null],
 *    origin: [true, true, ["GB", "CH", "FR", "AR"]],
 *  },
 * };
 */

const requesterRequesterEditableFields: IdentityEditableFieldsSetting<Person> = {
	context: "requester/requester",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterRequesterEditableFields",
	value: {
		dateOfBirth: [true, true, null],
		email: [true, true, null],
		fullName: [
			true, true, {
				firstName: [true, true, null],
				lastName: [true, true, null],
			},
		],
		gender: [true, true, null],
		origin: [true, true, ["GB", "CH", "FR", "AR"]],
		phone: [true, true, null],
		type: [true, true, ["person"]],
	},
};

const requesterInterpreterEditableFields: IdentityEditableFieldsSetting<Interpreter> = {
	context: "requester/interpreter",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterInterpreterEditableFields",
	value: {
		dateOfBirth: [true, true, null],
		email: [true, true, null],
		fullName: [
			true, true, {
				firstName: [true, true, null],
				lastName: [true, true, null],
			},
		],
		gender: [true, true, null],
		origin: [true, true, ["GB", "CH", "FR", "AR"]],
		phone: [true, true, null],
		type: [true, true, ["interpreter"]],
	},
};

const requesterPersonEditableFields: IdentityEditableFieldsSetting<Person> = {
	context: "requester/person",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterPersonEditableFields",
	value: {
		// [editable, mandatory, availableValues]
		dateOfBirth: [true, false, null],
		email: [true, false, null],
		fullName: [
			true, true, {
				firstName: [true, true, null],
				lastName: [true, true, null],
			},
		],
		gender: [true, false, null],
		origin: [true, false, ["GB", "CH", "FR", "AR"]],
		phone: [true, false, null],
		type: [true, false, ["person"]],
	},
};

const requesterMedicalProfessionalEditableFields: IdentityEditableFieldsSetting<MedicalProfessional> = {
	context: "requester/medicalProfessional",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterMedicalProfessionalEditableFields",
	value: {
		// [editable, mandatory, availableValues]
		dateOfBirth: [true, false, null],
		directPhone: [true, false, null],
		email: [true, false, null],
		fullName: [
			true, true, {
				firstName: [true, true, null],
				lastName: [true, true, null],
			},
		],
		function: [true, false, null],
		gender: [true, false, null],
		institution: [true, false, null],
		origin: [true, false, ["GB", "CH", "FR", "AR"]],
		phone: [true, false, null],
		type: [true, false, ["medicalProfessional"]],
	},
};

const requesterOtherProfessionalEditableFields: IdentityEditableFieldsSetting<OtherProfessional> = {
	context: "requester/otherProfessional",
	key: "identity/editable-fields",
	owner: corporationIdentityId,
	settingId: "requesterOtherProfessionalEditableFields",
	value: {
		// [editable, mandatory, availableValues]
		dateOfBirth: [true, false, null],
		directPhone: [true, false, null],
		email: [true, false, null],
		fullName: [
			true, true, {
				firstName: [true, true, null],
				lastName: [true, true, null],
			},
		],
		function: [true, false, null],
		gender: [true, false, null],
		origin: [true, false, ["GB", "CH", "FR", "AR"]],
		phone: [true, false, null],
		type: [true, false, ["otherProfessional"]],
	},
};

const requesterRequesterListedFields: PersonListedFieldsSetting<Person> = {
	context: "requester/requester",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterRequesterListedFields",
	value: new Set(["firstName", "lastName", "gender", "phone"]),
};

const requesterPersonListedFields: PersonListedFieldsSetting<Person> = {
	context: "requester/person",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterPersonListedFields",
	value: new Set(["firstName", "lastName", "gender", "email", "phone"]),
};

const requesterInterpreterListedFields: PersonListedFieldsSetting<Interpreter> = {
	context: "requester/interpreter",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterInterpreterListedFields",
	value: new Set(["firstName", "lastName", "gender", "email"]),
};

export const requesterReceiverListedFields: PersonListedFieldsSetting<Receiver> = {
	context: "requester/receiver",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterReceiverListedFields",
	value: new Set(["evamNumber"]),
};

const requesterMedicalProfessionalListedFields: PersonListedFieldsSetting<MedicalProfessional> = {
	context: "requester/medicalProfessional",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterMedicalProfessionalListedFields",
	value: new Set(["firstName", "lastName", "gender", "email", "phone", "directPhone", "function", "institution"]),
};

const requesterOtherProfessionalListedFields: PersonListedFieldsSetting<OtherProfessional> = {
	context: "requester/otherProfessional",
	key: "person/listed-fields",
	owner: corporationIdentityId,
	settingId: "requesterOtherProfessionalListedFields",
	value: new Set(["firstName", "lastName", "gender", "email", "phone", "directPhone", "function"]),
};

const supportInfosForCorporation: SupportInfosSetting = {
	context: "organization/all",
	key: "support/infos",
	owner: corporationIdentityId,
	settingId: "supportInfos",
	value: {
		email: "support@source.com",
		phone: "00418922783",
	},
};

const supportInfosForInterpreter: SupportInfosSetting = {
	context: "organization/all",
	key: "support/infos",
	owner: interpreterIdentityId,
	settingId: "supportInfos",
	value: {
		email: "support@source.com",
		phone: "00418922783",
	},
};

const sessionCommunicationOrganization: SessionCommunicationSetting = {
	context: "organization/all",
	key: "session/communication",
	owner: corporationIdentityId,
	settingId: "sessionCommunicationOrganization",
	value: {
		inPerson: true,
		phone: true,
		video: true,
	},
};

const generateAvailableLanguagesCommunicationInterpreters = (interpreters: Pick<InterpreterPreview, "communicationTypes" | "languages">[]): AvailableSessionLanguagesCommunication => {
	const availableLanguagesCommunications: Partial<AvailableSessionLanguagesCommunication> = {};

	interpreters.forEach(i => {
		i.languages
			.map(l => [l.from, l.to])
			.forEach(([from, to]) => {
				if (!availableLanguagesCommunications[from]) {
					availableLanguagesCommunications[from] = new Map<SessionLanguagesKey, Set<CommunicationType>>();
				}
				if (availableLanguagesCommunications[from]?.get(to)) {
					i.communicationTypes.forEach(c => {
						availableLanguagesCommunications[from]?.get(to)?.add(c);
					});
				} else {
					availableLanguagesCommunications[from]?.set(to, new Set(i.communicationTypes));
				}
			});
	});
	return availableLanguagesCommunications as AvailableSessionLanguagesCommunication;
};

export const availableLanguagesCommunicationInterpreters =
	generateAvailableLanguagesCommunicationInterpreters(interpretersExamples);

const sessionCreationOrganization: SessionCreationSetting = {
	context: "organization/all",
	key: "session/creation",
	owner: corporationIdentityId,
	settingId: "sessionCreationOrganization",
	value: {
		availableLanguagesCommunication: availableLanguagesCommunicationInterpreters,
		detailsStep: {
			billingAddress: {
				editRights: {
					canAddNew: true,
					canDelete: true,
					canEdit: true,
				},
			},
			expectation: {mandatory: true},
			goal: {mandatory: true},
			internalComment: {mandatory: false},
			interpreterComment: {mandatory: false},
			involvedCorporations: {mandatory: true},
			involvedPeople: {mandatory: true},
			medicalProfessional: {
				editRights: {
					canAddNew: true,
					canDelete: false,
					canEdit: true,
				},
				label: "Custom Label Medical Professional",
				mandatory: false,
			},
			otherProfessional: {
				editRights: {
					canAddNew: true,
					canDelete: true,
					canEdit: false,
				},
				label: "Custom Label Other Professional",
				mandatory: false,
			},
			paymentAuthority: {
				editRights: {
					canAddNew: true,
					canDelete: true,
					canEdit: true,
				},
				label: "Custom Label Payment authority",
				mandatory: false,
			},
			reason: {mandatory: true},
			// mediation fields below
			receiver: {
				editRights: {
					canAddNew: false,
					canDelete: false,
					canEdit: false,
				},
				label: "Custom Label Receiver",
				mandatory: false,
			},
			requester: {
				editRights: {
					canAddNew: true,
					canDelete: true,
					canEdit: true,
				},
				mandatory: false,
			},
			sessionCategory: {
				editRights: {
					canAddNew: true,
					canDelete: true,
					canEdit: true,
				},
				label: "Category",
				mandatory: false,
			},
			subject: {
				editRights: {
					canAddNew: false,
					canDelete: false,
				},
				mandatory: false,
			},
			toXInterpreter: {mandatory: true},
		},
		interpreterFilters: {
			genderMandatory: false,
			interpretersGroups: [
				{default: true, id: "1", name: "Interpreters Group 1"},
				{default: false, id: "2", name: "Interpreters Group 2"},
				{default: true, id: "3", name: "Interpreters Group 3"},
			],
			originMandatory: false,
			origins: [...new Set(interpretersExamples.map(i => i.origin))],
		},
		setupStep: {
			availableServices: ["education", "mediation", "standard"],
			availableSessionTypes: ["interpreterMandate"],
			// firstAllowedBookingDate: new Date(2024, 10, 27), // block new requests before this date
			// lastAllowedBookingDate: new Date(2024, 10, 30), // block new requests after this date
			minimumDuration: 60, // in minutes
		},
		termsConditionsURL: "www.google.com",
	},
};

const sessionCancelOrReschedule: SessionCancelAndRescheduleSetting = {
	context: "organization/requesters",
	key: "session/cancel-or-reschedule",
	owner: corporationIdentityId,
	settingId: "sessionCancelOrReschedule",
	value: {
		cancel: {
			displayCancelDueToInterpreter: true,
			dropdown: {
				mandatory: true,
				values: [
					{
						id: "1",
						reason: "cancel reason 1",
					}, {
						id: "2",
						reason: "cancel reason 2",
					}, {
						id: "3",
						reason: "cancel reason 3",
					},
				],
			},
			textField: {
				mandatory: true,
			},
		},
		reschedule: {
			displayCancelDueToInterpreter: true,
			dropdown: {
				mandatory: false,
				values: [
					{
						id: "1",
						reason: "reschedule reason 1",
					}, {
						id: "2",
						reason: "reschedule reason 2",
					}, {
						id: "3",
						reason: "reschedule reason 3",
					},
				],
			},
			textField: {
				mandatory: false,
			},
		},
	},
};

const tocco: ToccoSetting = {
	context: "organization/requesters",
	key: "tocco",
	owner: corporationIdentityId,
	settingId: "tocco",
	value: {
		usingTocco: true,
	},
};

export const settingsExamples: Setting[] = [
	availabilityAvailabilities,
	interpreterAccount,
	notificationTargets,
	requesterInterpreterEditableFields,
	requesterInterpreterListedFields,
	requesterPersonEditableFields,
	requesterPersonListedFields,
	requesterMedicalProfessionalEditableFields,
	requesterMedicalProfessionalListedFields,
	requesterOtherProfessionalEditableFields,
	requesterOtherProfessionalListedFields,
	requesterReceiverEditableFields,
	requesterReceiverListedFields,
	requesterRequesterEditableFields,
	requesterRequesterListedFields,
	supportInfosForCorporation,
	supportInfosForInterpreter,
	sessionCommunicationInterpreter,
	sessionCommunicationOrganization,
	sessionCreationOrganization,
	sessionCancelOrReschedule,
	tocco,
];
