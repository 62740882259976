import * as React from "react";
import {ActivityIndicator, Animated, StyleSheet, View} from "react-native";
import {AvailabilityState} from "../../@types/availability";
import {AvailabilitySetting} from "../../@types/settings";
import {AuthentifiedContext} from "../../contexts/authentified";
import {SMALL_SPACING} from "../../utils/constants";
import {
	AvailabilityKey,
	getAvailabilityColorFromReason,
	getAvailabilityFromState,
	getAvailabilityIconKey,
	useInterpretersAvailabilities,
} from "../../utils/hooks/use-interpreters-availabilities";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Log} from "../../utils/logs/logs";
import {WHITE} from "../../utils/styles/colors";
import {ELEVATIONS} from "../../utils/styles/elevations";
import {Icon} from "../icons";
import {Text} from "../texts/text";
import {Pressable} from "../views/pressable";

export const AvailabilityButton = (): JSX.Element => {
	const {t} = useTranslation();
	const {identity, settings: {getSetting, updateSetting}} = React.useContext(AuthentifiedContext);
	const [loading, setLoading] = React.useState(true);
	const [{availability, availabilityState}, setAvailability] = React.useState<{
		availability: AvailabilityKey;
		availabilityState?: AvailabilityState;
	}>({availability: "occupied", availabilityState: undefined});
	const pulse = React.useRef(new Animated.Value(1));

	const availabilityColor = getAvailabilityColorFromReason(availabilityState);
	const availabilityIcon = getAvailabilityIconKey(availability);

	const onPressAvailability = React.useCallback(
		() => {
			const availabilitySetting = getSetting<AvailabilitySetting>("availability/availabilities", "account");
			if (availabilitySetting) {
				updateSetting({
					...availabilitySetting,
					value: {
						...availabilitySetting.value,
						immediate: availability !== "available",
					},
				})
					.catch(Log.error());
			}
		},
		[availability, getSetting, updateSetting],
	);

	React.useEffect(
		() => {
			if (availability === "available") {
				Animated.loop(
					Animated.sequence([
						Animated.timing(pulse.current, {
							duration: 1000,
							toValue: 0,
							useNativeDriver: true,
						}),
						Animated.timing(pulse.current, {
							duration: 1000,
							toValue: 1,
							useNativeDriver: true,
						}),
					]),
				).start();
			} else {
				Animated.timing(pulse.current, {
					duration: 200,
					toValue: 1,
					useNativeDriver: true,
				}).start();
			}
		},
		[availability],
	);

	const {fetchAvailabilities} = useInterpretersAvailabilities({
		onData: (data) => {
			const availabilityState = data[identity!.identityId];
			if (availabilityState) {
				setAvailability({
					availability: getAvailabilityFromState(availabilityState),
					availabilityState,
				});
			}
			setLoading(false);
		},
	});

	React.useEffect(() => {
		fetchAvailabilities([identity!.identityId]);
	}, [fetchAvailabilities, identity]);

	return (
		<Pressable
			style={[
				styles.availabilityContainer,
				{backgroundColor: availabilityColor},
			]}
			disabled={loading || availability === "occupied"}
			onPress={onPressAvailability}
		>
			{loading
				? <ActivityIndicator color={WHITE} style={styles.availabilityIndicator}/>
				: (
					<>
						<View
							style={styles.availabilityStatus}
						>
							<Icon
								animated
								style={{opacity: pulse.current}}
								color={WHITE}
								icon={availabilityIcon}
							/>
							<Text color={WHITE}>
								{t(`users:interpreter.availabilities.${availabilityState ?? availability}`)}
							</Text>
						</View>
					</>
				)
			}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	availabilityContainer: {
		alignItems: "center",
		borderRadius: SMALL_SPACING,
		marginHorizontal: SMALL_SPACING,
		padding: SMALL_SPACING,
		...ELEVATIONS[4],
	},
	availabilityIndicator: {
		padding: 4,
	},
	availabilityStatus: {
		alignItems: "center",
		flexDirection: "row",
	},
});
