import * as React from "react";
import {Falsy, GestureResponderEvent, StyleSheet, View} from "react-native";
import {SessionPreview} from "../../@types/activities/session";
import {Navigation} from "../../navigation/navigation";
import {DEFAULT_SPACING, EXTRA_LARGE_SPACING, SMALL_SPACING} from "../../utils/constants";
import {Confirmation} from "../../utils/navigation/paramLists/common-screen-props";
import {Button, ButtonProps} from "../buttons/button";
import {Icon, IconKey} from "../icons";
import {SessionPreviewElement} from "../list/items/session-preview";
import {ScrollView} from "../scrollables/scroll-view";
import {Separator} from "../separator";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";

export type ConfirmationButtonProps = ButtonProps & {
	goBackOnPress?: boolean;
	isPromise?: boolean;
	key: string;
};

export interface ConfirmationContentProps {
	actions: (ConfirmationButtonProps | Falsy)[];
	actionsLayout?: "column" | "row";
	content: JSX.Element | string | null;
	onAction: (action: ConfirmationButtonProps) => (event: GestureResponderEvent) => void;
}

export const ConfirmationContent = ({
	actions,
	actionsLayout = "column",
	content,
	onAction,
}: ConfirmationContentProps): JSX.Element => (
	<>
		{
			typeof content === "string"
				? (
					<View style={styles.confirmationContent}>
						<Text type="emphasis_1" centered>
							{content}
						</Text>
					</View>
				)
				: content
		}
		<View style={[styles.actionsWrapper, actionsLayout === "row" && styles.actionsWrapperInRow]}>
			{actions.map((action, index) => action
				? (
					// Key is already contained in "action" which is spread
					// eslint-disable-next-line react/jsx-key
					<Button
						size="small"
						{...action}
						onPress={onAction(action)}
						style={[
							styles.button,
							actionsLayout === "row" && styles.rowButton,
							index < actions.length - 1 && (
								actionsLayout === "row"
									? styles.horizontalSpace
									: styles.verticalSpace
							),
						]}
						innerStyle={styles.innerButtons}
					/>
				)
				: null,
			)}
		</View>
	</>
);

export const confirmation = (params: Confirmation): void =>
	Navigation.navigate("ConfirmationModal", params);

export const sessionConfirmation = ({
	actions,
	icon,
	iconColor,
	session,
	text,
}: {
	actions: (ConfirmationButtonProps | Falsy)[];
	icon?: IconKey;
	iconColor?: string;
	session: SessionPreview;
	text: string;
}): void => {
	const content = (
		<View style={styles.drawerContent}>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
			>
				<SessionPreviewElement
					showDate
					session={session}
					style={styles.sessionPreview}
				/>
			</ScrollView>
			<Separator/>
			<Spacer size={DEFAULT_SPACING + SMALL_SPACING}/>
			{!!icon && (
				<>
					<Icon icon={icon} size={60} color={iconColor}/>
					<Spacer size={DEFAULT_SPACING}/>
				</>
			)}
			<Text type="emphasis_1" centered>{text}</Text>
		</View>
	);
	confirmation({actions, content});
};

const styles = StyleSheet.create({
	actionsWrapper: {
		flexDirection: "column",
		padding: DEFAULT_SPACING,
	},
	actionsWrapperInRow: {
		flexDirection: "row",
	},
	button: {
		alignSelf: "stretch",
	},
	confirmationContent: {
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: EXTRA_LARGE_SPACING,
	},
	drawerContent: {
		alignItems: "center",
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: DEFAULT_SPACING,
	},
	horizontalSpace: {
		marginRight: SMALL_SPACING,
	},
	innerButtons: {
		alignSelf: "stretch",
	},
	rowButton: {
		flex: 1,
	},
	sessionPreview: {
		marginBottom: SMALL_SPACING,
		width: "100%",
	},
	verticalSpace: {
		marginBottom: SMALL_SPACING,
	},
});
