import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Session, SessionPreview} from "../../../../@types/activities/session";
import {Gender, genders, PersonIdentityPreview} from "../../../../@types/identities/person";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {Page, Pagination} from "../../../../@types/pagination";
import {Place} from "../../../../@types/places";
import {Button} from "../../../../components/buttons/button";
import {Timeline} from "../../../../components/chat/timeline";
import {CustomIcon, Icon} from "../../../../components/icons";
import {Image} from "../../../../components/images/image";
import {ButtonInput} from "../../../../components/inputs/button";
import {Form} from "../../../../components/inputs/form";
import {ListInput} from "../../../../components/inputs/list";
import {DropdownSelectInput} from "../../../../components/inputs/list-inputs/dropdown-select-input";
import {TextInputDropdownSuggestionItem} from "../../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {ListInputWrapper} from "../../../../components/inputs/list-wrapper";
import {Label} from "../../../../components/labels/label";
import {ProviderLabels} from "../../../../components/labels/provider";
import {TranslationLabel} from "../../../../components/labels/translation";
import {InterpreterElement} from "../../../../components/list/items/interpreter";
import {ListElement} from "../../../../components/list/items/list-element";
import {SessionElement} from "../../../../components/list/items/session";
import {Item} from "../../../../components/list/list-props.common";
import {HeaderMenu} from "../../../../components/menus/header";
import {SectionList} from "../../../../components/scrollables/section-list";
import {Separator} from "../../../../components/separator";
import {Spacer} from "../../../../components/spacer";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {
	getSubjectSuggestions as getSubjectSuggestionsRequest,
	searchSubjectSuggestions as searchSubjectSuggestionsRequest,
} from "../../../../requests/clients/new-session/subject-suggestion";
import {
	addClientReceiver,
	getClientReceivers,
	searchClientReceivers,
} from "../../../../requests/clients/settings/receivers";
import {CONTAINERS, CONTENT_CONTAINER_MAX_WIDTH, DEFAULT_SPACING, SMALL_SPACING} from "../../../../utils/constants";
import {fakeDatabase} from "../../../../utils/development/fake/database";
import {interpretersExamples, peopleExamples} from "../../../../utils/development/fake/identities/data";
import {pickerImageExample} from "../../../../utils/development/fake/medias/data";
import {requesterReceiverEditableFields} from "../../../../utils/development/fake/settings/shared-data";
import {Images} from "../../../../utils/externals/images";
import {RatingValue} from "../../../../utils/feedbacks";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getDescriptionLabel, getTitle, personIdentity, receiverIdentity} from "../../../../utils/identities";
import {PickerImage} from "../../../../utils/inputs/pickers/image-picker";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Locales} from "../../../../utils/locales/locales";
import {ct} from "../../../../utils/locales/translations";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {linkToCall} from "../../../../utils/network/links";
import {paginate} from "../../../../utils/pagination";
import {textContains} from "../../../../utils/searches";
import {SessionLanguagesKey, sessionLanguagesKeys} from "../../../../utils/sessions/languages";
import {
	BLACK,
	CONTRAST,
	CONTRAST_2,
	CONTRAST_3,
	GREEN,
	PRIMARY,
	PRIMARY_2,
	RED,
	SUBTLE,
	SUBTLE_2,
	SUBTLE_3,
	SUBTLE_4,
	TRANSPARENT,
	WHITE,
} from "../../../../utils/styles/colors";
import {AddressListItem} from "../../client/modals/address-list";

const nothing = (): void => {
	// do nothing
};

interface FormValues {
	checkbox?: boolean;
	date?: Date;
	datetime?: Date;
	dropdownButtonLanguage?: DropdownListItem<SessionLanguagesKey>[];
	dropdownButtonLanguageMultiple?: DropdownListItem<SessionLanguagesKey>[];
	dropdownButtonPerson?: DropdownListItem<ReceiverPreview>[];
	dropdownButtonPersonMultiple?: DropdownListItem<ReceiverPreview>[];
	dropdownSelectMultipleLanguage?: DropdownListItem<SessionLanguagesKey>[];
	dropdownSelectMultiplePerson?: DropdownListItem<ReceiverPreview>[];
	dropdownSelectOneLanguage?: DropdownListItem<SessionLanguagesKey>[];
	dropdownSelectOnePerson?: DropdownListItem<ReceiverPreview>[];
	duration?: Date;
	image?: PickerImage;
	imagePreview?: PickerImage;
	inlineSelect?: Gender[];
	multiSelect?: PersonIdentityPreview[];
	rating?: RatingValue;
	screen?: Place;
	screenPreview?: Place;
	select1?: boolean;
	select2?: boolean;
	text?: string;
	textInputDropdown?: string;
	time?: Date;
}

const defaultFormValues: FormValues = {
	checkbox: false,
	date: new Date(),
	datetime: new Date(),
	dropdownButtonLanguage: undefined,
	dropdownButtonLanguageMultiple: undefined,
	dropdownButtonPerson: undefined,
	dropdownButtonPersonMultiple: undefined,
	dropdownSelectMultipleLanguage: undefined,
	dropdownSelectMultiplePerson: undefined,
	dropdownSelectOneLanguage: undefined,
	dropdownSelectOnePerson: undefined,
	duration: new Date(),
	image: undefined,
	imagePreview: pickerImageExample,
	inlineSelect: ["man"],
	multiSelect: undefined,
	rating: undefined,
	screen: undefined,
	screenPreview: {
		address: "Parc de la Perle du Lac",
		geocode: {lat: 6.148_675_6, lng: 46.221_140_5},
	},
	select1: false,
	select2: true,
	text: "This is a text value",
	textInputDropdown: undefined,
	time: new Date(),
};

type DropdownListItem<I> = Item<"id", {data: I; displayed: string; id: string}>;
const sort = (a: DropdownListItem<string>, b: DropdownListItem<string>): number => Locales.compare(
	a.displayed, b.displayed);

const itemSeparatorComponent = (): JSX.Element => <Separator color={TRANSPARENT}/>;
const getLabelTextLanguage = (language: DropdownListItem<SessionLanguagesKey>): string => language.displayed;
const getLabelTextPerson = (receiver: DropdownListItem<ReceiverPreview>): string => receiver.displayed;

const transformReceiverToDropdownListItem = (receiver: ReceiverPreview): DropdownListItem<ReceiverPreview> => ({
	data: receiver,
	displayed: receiverIdentity(
		receiver,
		new Set(["firstName", "lastName", "gender", "evamNumber", "ippNumber", "description"]),
		getDescriptionLabel(requesterReceiverEditableFields.value),
	),
	id: receiver.identityId,
});

const transformReceiverPage = (page: Page<ReceiverPreview>): Page<DropdownListItem<ReceiverPreview>> => ({
	...page,
	items: page.items.map(i => transformReceiverToDropdownListItem(i)),
});

const Components = (_: SharedScreenProps<"ComponentsModal">): JSX.Element => {
	const {t} = useTranslation();
	const {accountId} = React.useContext(AuthentifiedContext);
	const {mobileDisplay} = React.useContext(ResponsiveContext);
	const [people, setPeople] = React.useState<PersonIdentityPreview[]>(peopleExamples);
	const [formValues, setFormValues] = React.useState<FormValues>(defaultFormValues);

	const sessionLanguages: DropdownListItem<SessionLanguagesKey>[] = React.useMemo(
		() => [...sessionLanguagesKeys]
			.map((language: SessionLanguagesKey) => ({data: language, displayed: t(`languages:${language}`), id: language}))
			.sort(sort),
		[t],
	);

	const getLanguageRequest = React.useCallback(
		(pagination: Pagination): Promise<Page<DropdownListItem<SessionLanguagesKey>>> =>
			Promise.resolve(paginate(sessionLanguages, pagination)),
		[sessionLanguages],
	);

	const searchLanguageRequest = React.useCallback(
		(pagination: Pagination, search: string): Promise<Page<DropdownListItem<SessionLanguagesKey>>> =>
			Promise.resolve(paginate(
				sessionLanguages.filter(item => search ? textContains(search, item.displayed, true) : true),
				pagination,
			),
			),
		[sessionLanguages],
	);

	const onSearchLanguage = React.useMemo(
		() => ({
			request: searchLanguageRequest,
		}),
		[searchLanguageRequest],
	);

	const getPersonRequest = React.useCallback(
		(pagination: Pagination): Promise<Page<DropdownListItem<ReceiverPreview>>> =>
			getClientReceivers(accountId!, pagination)
				.then(transformReceiverPage),
		[accountId],
	);

	const searchPersonRequest = React.useCallback(
		(pagination: Pagination, search: string): Promise<Page<DropdownListItem<ReceiverPreview>>> =>
			searchClientReceivers(accountId!, search, pagination).then(transformReceiverPage),
		[accountId],
	);

	const onSearchPerson = React.useMemo(
		() => ({
			request: searchPersonRequest,
		}),
		[searchPersonRequest],
	);

	const getSubjectSuggestions = React.useCallback(
		(pagination: Pagination) => getSubjectSuggestionsRequest(pagination, accountId!),
		[accountId],
	);

	const searchSubjectSuggestions = React.useCallback(
		(pagination: Pagination, search: string) => searchSubjectSuggestionsRequest(pagination, search, accountId!),
		[accountId],
	);

	const onAdd = (added: DropdownListItem<Receiver>): Promise<DropdownListItem<Receiver>> =>
		addClientReceiver(accountId!, added.data)
			.then(transformReceiverToDropdownListItem);

	return (
		<>
			<HeaderMenu center="Components"/>
			<SectionList
				contentContainerStyle={CONTAINERS.LIST}
				ItemSeparatorComponent={itemSeparatorComponent}
				contentInsetAdjustmentBehavior="always"
				sections={[
					{
						data: [
							<DropdownSelectInput
								key="dropdownSelect1"
								getRequest={getLanguageRequest}
								onSearch={onSearchLanguage}
								getLabelText={getLabelTextLanguage}
								onChangeValue={(dropdownButtonLanguage: DropdownListItem<SessionLanguagesKey>[] | null) => setFormValues(
									prev => ({
										...prev,
										dropdownButtonLanguage: dropdownButtonLanguage ?? undefined,
									}))}
								value={formValues.dropdownButtonLanguage}
								itemTranslationKey="common:language"
								idKey="id"
							/>,
							<DropdownSelectInput
								key="dropdownSelect2"
								getRequest={getLanguageRequest}
								onSearch={onSearchLanguage}
								getLabelText={getLabelTextLanguage}
								onChangeValue={(dropdownButtonLanguageMultiple: DropdownListItem<SessionLanguagesKey>[] | null) =>
									setFormValues(
										prev => ({...prev, dropdownButtonLanguageMultiple: dropdownButtonLanguageMultiple ?? undefined}))}
								value={formValues.dropdownButtonLanguageMultiple}
								multiple
								itemTranslationKey="common:language"
								idKey="id"
							/>,
							<DropdownSelectInput
								key="dropdownSelect3"
								getRequest={getPersonRequest}
								onSearch={onSearchPerson}
								getLabelText={getLabelTextPerson}
								onChangeValue={(dropdownButtonPerson: DropdownListItem<ReceiverPreview>[] | null) =>
									setFormValues(prev => ({...prev, dropdownButtonPerson: dropdownButtonPerson ?? undefined}))}
								value={formValues.dropdownButtonPerson}
								itemTranslationKey="common:receiver"
								idKey="id"
								onPressAdd={{
									request: onAdd,
									screen: {
										name: "EditPersonModal",
										params: {
											context: "requester/receiver",
											title: ct("forms:items.add", {item: ct("common:receiver")}),
										},
									},
								}}
							/>,
							<DropdownSelectInput
								key="dropdownSelect4"
								getRequest={getPersonRequest}
								onSearch={onSearchPerson}
								getLabelText={getLabelTextPerson}
								onChangeValue={(dropdownButtonPersonMultiple: DropdownListItem<ReceiverPreview>[] | null) =>
									setFormValues(
										prev => ({...prev, dropdownButtonPersonMultiple: dropdownButtonPersonMultiple ?? undefined}))}
								value={formValues.dropdownButtonPersonMultiple}
								multiple
								idKey="id"
								itemTranslationKey="common:receiver"
								onPressAdd={{
									request: onAdd,
									screen: {
										name: "EditPersonModal",
										params: {
											context: "requester/receiver",
											title: ct("forms:items.add", {item: ct("common:receiver")}),
										},
									},
								}}
							/>,
							(<Form
								key="form"
								title="form"
								inputs={[
									{
										disabled: false,
										explanation: "This is an explanation",
										icon: "search",
										label: "This is a Label for a dropdown select",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getLabelText: getLabelTextPerson,
											getRequest: getPersonRequest,
											idKey: "id",
											itemTranslationKey: "common:receiver",
											key: "dropdownSelect",
											onChangeValue: (dropdownSelectOnePerson: DropdownListItem<ReceiverPreview>[] | null) =>
												setFormValues(
													prev => ({...prev, dropdownSelectOnePerson: dropdownSelectOnePerson ?? undefined})),
											onPressAdd: {
												request: onAdd,
												screen: {
													name: "EditPersonModal",
													params: {
														context: "requester/receiver",
														title: ct("forms:items.add", {item: ct("common:receiver")}),
													},
												},
											},
											onSearch: onSearchPerson,
											value: formValues.dropdownSelectOnePerson,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "search",
										label: "This is a Label for a dropdown select",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getLabelText: getLabelTextPerson,
											getRequest: getPersonRequest,
											idKey: "id",
											itemTranslationKey: "common:receiver",
											key: "dropdownSelect",
											multiple: true,
											onChangeValue: (dropdownSelectMultiplePerson: DropdownListItem<ReceiverPreview>[] | null) =>
												setFormValues(
													prev => ({...prev, dropdownSelectMultiplePerson: dropdownSelectMultiplePerson ?? undefined})),
											onPressAdd: {
												request: onAdd,
												screen: {
													name: "EditPersonModal",
													params: {
														context: "requester/receiver",
														title: ct("forms:items.add", {item: ct("common:receiver")}),
													},
												},
											},
											onSearch: onSearchPerson,
											value: formValues.dropdownSelectMultiplePerson,
										},
									},
									{
										icon: "id",
										label: "This is a Label for text suggestions",
										type: mobileDisplay
											? {
												key: "screen",
												onChangeValue: (subject: TextInputDropdownSuggestionItem | null) =>
													setFormValues(
														prev => ({...prev, textInputDropdown: subject?.displayed ?? undefined}),
													),
												params: {
													onPressSelect: {
														canSelectSearch: true,
														disableMultipleSelection: true,
														onPress: (subject: TextInputDropdownSuggestionItem[] | string) =>
															setFormValues(prev => ({
																...prev,
																textInputDropdown: Array.isArray(subject) ? subject?.[0].id : subject,
															})),
													},
												},
												renderValue: (value: string) => value,
												screen: "SelectSubjectSuggestionModal",
												value: formValues?.textInputDropdown,
											}
											: {
												getRequest: getSubjectSuggestions,
												idKey: "id",
												itemTranslationKey: "common:subject",
												key: "textSuggestions",
												onSearch: {
													onChangeSearch: (value: string | null) => setFormValues(
														prev => ({...prev, textInputDropdown: value ?? undefined}),
													),
													request: searchSubjectSuggestions,
													value: formValues?.textInputDropdown,
												},
											},
									},
									{
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										image: interpretersExamples[0].picture,
										label: "This is a Label for a text input",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "text",
											onChangeValue: (text: string) => setFormValues(prev => ({...prev, text})),
											value: formValues.text,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										image: interpretersExamples[0].picture,
										label: "This is a Label for a text input in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "text",
											onChangeValue: (text: string) => setFormValues(prev => ({...prev, text})),
											value: formValues.text,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a checkbox",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "checkbox",
											onChangeValue: (checkbox: boolean) => setFormValues(prev => ({...prev, checkbox})),
											value: formValues.checkbox,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a checkbox in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "checkbox",
											onChangeValue: (checkbox: boolean) => setFormValues(prev => ({...prev, checkbox})),
											value: formValues.checkbox,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a select",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "select",
											onChangeValue: (s: boolean) => setFormValues(prev => ({...prev, select1: s, select2: !s})),
											value: formValues.select1,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a select in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "select",
											onChangeValue: (s: boolean) => setFormValues(prev => ({...prev, select1: s, select2: !s})),
											value: formValues.select1,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										// image: interpretersExamples[0].picture,
										icon: "about",
										label: "This is a Label for a select",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "select",
											onChangeValue: (s: boolean) => setFormValues(prev => ({...prev, select1: !s, select2: s})),
											value: formValues.select2,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a select in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "select",
											onChangeValue: (s: boolean) => setFormValues(prev => ({...prev, select1: !s, select2: s})),
											value: formValues.select2,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a date",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "date",
											onChangeValue: (date: Date) => setFormValues(prev => ({...prev, date})),
											value: formValues.date,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										// image: interpretersExamples[0].picture,
										icon: "about",
										label: "This is a Label for a date in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "date",
											onChangeValue: (date: Date) => setFormValues(prev => ({...prev, date})),
											value: formValues.date,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a datetime",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "datetime",
											onChangeValue: (datetime: Date) => setFormValues(prev => ({...prev, datetime})),
											value: formValues.datetime,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a datetime in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "datetime",
											onChangeValue: (datetime: Date) => setFormValues(prev => ({...prev, datetime})),
											value: formValues.datetime,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										// image: interpretersExamples[0].picture,
										icon: "about",
										label: "This is a Label for a time",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "time",
											onChangeValue: (time: Date) => setFormValues(prev => ({...prev, time})),
											value: formValues.time,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a time in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "time",
											onChangeValue: (time: Date) => setFormValues(prev => ({...prev, time})),
											value: formValues.time,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a duration",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "duration",
											onChangeValue: (duration: Date) => setFormValues(prev => ({...prev, duration})),
											value: formValues.duration,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a duration in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "duration",
											onChangeValue: (duration: Date) => setFormValues(prev => ({...prev, duration})),
											value: formValues.duration,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for an image",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "image",
											onChangeValue: (image: PickerImage) => setFormValues(prev => ({...prev, image})),
											options: {
												cropping: false,
												from: "both",
												multiple: true,
											},
											value: formValues.image,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for an image in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "image",
											onChangeValue: (image: PickerImage) => setFormValues(prev => ({...prev, imagePreview: image})),
											options: {
												cropping: false,
												from: "both",
												multiple: true,
											},
											value: formValues.imagePreview,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a screen",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "screen",
											onChangeValue: (p: AddressListItem) => setFormValues(prev => ({...prev, screen: p?.displayed})),
											params: {place: formValues.screen},
											screen: "SelectPlaceModal",
											value: formValues.screen?.address,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a screen in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "screen",
											onChangeValue: (p: AddressListItem) =>
												setFormValues(prev => ({...prev, screenPreview: p?.displayed})),
											params: {place: formValues.screenPreview},
											screen: "SelectPlaceModal",
											value: formValues.screenPreview?.address,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a inlineSelect",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											choices: genders,
											getLabelText: (g: Gender) => getTitle(g),
											// itemProps: {},
											key: "inlineSelect",
											multiple: true,
											onChangeValue: (g?: Gender[] | null) => setFormValues(prev => ({...prev, inlineSelect: g ?? []})),
											value: formValues.inlineSelect,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a inlineSelect in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											choices: genders,
											getLabelText: (g: Gender) => getTitle(g),
											key: "inlineSelect",
											// itemProps: {},
											multiple: true,
											onChangeValue: (g?: Gender[] | null) => setFormValues(prev => ({...prev, inlineSelect: g ?? []})),
											value: formValues.inlineSelect,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a multiSelect",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getItems: {
												onChangeValue: (items: PersonIdentityPreview[] | null) => setFormValues(
													prev => ({...prev, multiSelect: items ?? []})),
												params: {
													exportType: "interpreterPool",
													pool: people,
													selectionOrBase: formValues.multiSelect ?? [],
												},
												screen: "SelectPersonModal",
											},
											// itemProps: {},
											getLabelText: (item: PersonIdentityPreview) => personIdentity(item),
											key: "multiSelect",
											keyExtractor: (item: PersonIdentityPreview) => item.identityId,
											onDeleteItem: (item: PersonIdentityPreview) => {
												setPeople(prev => {
													const value = (prev ?? [])
														.filter(el => el.identityId !== item.identityId);
													// Reset to null to trigger not empty rule if empty array
													return value.length > 0 ? value : [];
												});
												setFormValues(prev => {
													const value = (prev.multiSelect ?? [])
														.filter(el => el.identityId !== item.identityId);
													// Reset to null to trigger not empty rule if empty array
													return {...prev, multiSelect: value?.length > 0 ? value : []};
												});
												return Promise.resolve();
											},
											onUpdateItem: {
												request: (item: PersonIdentityPreview) => {
													setPeople(prev => {
														const index = prev.findIndex(i => i.identityId === item.identityId);
														return [
															...(prev?.slice(0, index) || []),
															item,
															...(prev?.slice(index + 1) || []),
														];
													});
													setFormValues(prev => {
														const index = prev.multiSelect?.findIndex(i => i.identityId === item.identityId);
														return {
															...prev,
															multiSelect: index === undefined
																? []
																: [
																	...(prev.multiSelect?.slice(0, index) ?? []),
																	item,
																	...(prev.multiSelect?.slice(index + 1) ?? []),
																],
														};
													});
													return Promise.resolve();
												},
												screen: {
													name: "EditPersonModal",
													params: {
														authorizedFields: {
															// [editable, mandatory, availableValues]
															description: [true, true, null],
															fullName: [
																true, true, {
																	firstName: [true, false, null],
																	lastName: [true, true, null],
																},
															],
															gender: [true, true, null],
														},
													},
												},
											},
											value: formValues.multiSelect,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "about",
										// image: interpretersExamples[0].picture,
										label: "This is a Label for a multiSelect in preview",
										preview: true,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getItems: {
												onChangeValue: (items: PersonIdentityPreview[] | null) => setFormValues(
													prev => ({...prev, multiSelect: items ?? []})),
												params: {
													exportType: "interpreterPool",
													pool: people,
													selectionOrBase: formValues.multiSelect ?? [],
												},
												screen: "SelectPersonModal",
											},
											// itemProps: {},
											getLabelText: (item: PersonIdentityPreview) => personIdentity(item),
											key: "multiSelect",
											keyExtractor: (item: PersonIdentityPreview) => item.identityId,
											onDeleteItem: (item: PersonIdentityPreview) => {
												setPeople(prev => {
													const value = (prev ?? [])
														.filter(el => el.identityId !== item.identityId);
													// Reset to null to trigger not empty rule if empty array
													return value.length > 0 ? value : [];
												});
												setFormValues(prev => {
													const value = (prev.multiSelect ?? [])
														.filter(el => el.identityId !== item.identityId);
													// Reset to null to trigger not empty rule if empty array
													return {...prev, multiSelect: value.length > 0 ? value : []};
												});
												return Promise.resolve();
											},
											onUpdateItem: {
												request: (item: PersonIdentityPreview) => {
													setPeople(prev => {
														const index = prev.findIndex(i => i.identityId === item.identityId);
														return [
															...prev.slice(0, index),
															item,
															...prev.slice(index + 1),
														];
													});
													setFormValues(prev => {
														const index = prev.multiSelect?.findIndex(i => i.identityId === item.identityId);
														return {
															...prev,
															multiSelect: index === undefined
																? []
																: [
																	...(prev.multiSelect?.slice(0, index) ?? []),
																	item,
																	...(prev.multiSelect?.slice(index + 1) ?? []),
																],
														};
													});
													return Promise.resolve();
												},
												screen: {
													name: "EditPersonModal",
													params: {
														authorizedFields: {
															// [editable, mandatory, availableValues]
															description: [true, true, null],
															fullName: [
																true, true, {
																	firstName: [true, false, null],
																	lastName: [true, true, null],
																},
															],
															gender: [true, true, null],
														},
													},
												},
											},
											value: people,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "ratingFull",
										label: "This is a Label for a rating input",
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											key: "rating",
											onChangeValue: (rating: RatingValue) => setFormValues(prev => ({...prev, rating})),
											value: formValues.rating,
										},
									},
								]}
							/>),
						],
						title: "Button Dropdown",
					}, {
						data: [
							(<View key="app-icon" style={styles.defaultContainer}><Icon icon="appIcon" size={54}/></View>),
							(<View key="logo-icon" style={styles.defaultContainer}><CustomIcon name="logo" size={20}/></View>),
							(<View key="logo-image" style={styles.defaultContainer}>
								<Image sourceType="require" mime="image/png" source={Images.Logo} width={100} height={17}/>
							</View>),
						],
						title: "Logos",
					}, {
						data: [
							(<View key="title" style={styles.defaultContainer}><Text type="title">Title</Text></View>),
							(<View key="emphasis1" style={styles.defaultContainer}><Text type="emphasis_1">Emphasis 1</Text></View>),
							(<View key="emphasis2" style={styles.defaultContainer}><Text type="emphasis_2">Emphasis 2</Text></View>),
							(<View key="label" style={styles.defaultContainer}><Text type="label">Label</Text></View>),
						],
						title: "Texts",
					}, {
						data: [
							(
								<View key="colors" style={[styles.defaultContainer, styles.colors]}>
									<View style={[styles.color, {backgroundColor: WHITE}]}/>
									<View style={[styles.color, {backgroundColor: SUBTLE}]}/>
									<View style={[styles.color, {backgroundColor: SUBTLE_2}]}/>
									<View style={[styles.color, {backgroundColor: SUBTLE_3}]}/>
									<View style={[styles.color, {backgroundColor: SUBTLE_4}]}/>
									<View style={[styles.color, {backgroundColor: BLACK}]}/>
									<View style={[styles.color, {backgroundColor: CONTRAST}]}/>
									<View style={[styles.color, {backgroundColor: CONTRAST_2}]}/>
									<View style={[styles.color, {backgroundColor: CONTRAST_3}]}/>
									<View style={[styles.color, {backgroundColor: GREEN}]}/>
									<View style={[styles.color, {backgroundColor: RED}]}/>
									<View style={[styles.color, {backgroundColor: PRIMARY}]}/>
									<View style={[styles.color, {backgroundColor: PRIMARY_2}]}/>
								</View>
							),
						],
						title: "Colors",
					}, {
						data: [
							(<Label key="label-small" size="small" backgroundColor={RED} text="2"/>),
							(<Label key="label-icon" backgroundColor={GREEN} icon="back"/>),
							(<Label key="label-text" text="Text"/>),
							(<TranslationLabel key="label-translation" from="french" to="english"/>),
							(<View key="label-session" style={styles.defaultContainer}>
								<ProviderLabels
									communicationTypes={["phone", "inPerson"]}
									providedServices={["standard", "mediation"]}
								/>
							</View>),
						],
						title: "Labels",
					}, {
						data: [
							(<View key="button-fullWidth" style={styles.defaultContainer}>
								<Button size="small" onPress={nothing} text="Test" icon="school" fullWidth/>
							</View>),
							(<Button
								key="button-secondary"
								onPress={nothing}
								text="Test"
								type="secondary"
								icon="school"
								notif={8}
								iconPosition="before"
							/>),
							(<Button key="button-text-only" onPress={nothing} text="Some Text"/>),
							(<Button key="button-icon" onPress={nothing} icon="school"/>),
							(<Button key="button-notif" onPress={nothing} icon="school" notif={18} label="Label"/>),
							(<Button key="button-notif-small" onPress={nothing} size="small" icon="school" notif={118}/>),
							(<Button
								key="button-secondary-small"
								onPress={nothing}
								size="small"
								type="secondary"
								icon="school"
								label="Label"
								text="Test"
							/>),
						],
						title: "Buttons",
					}, {
						data: [
							(
								<View key="lists" style={styles.defaultContainer}>
									<ListElement onPress={nothing} badge={{color: RED, value: "2"}}><Text>lists</Text></ListElement>
									<ListElement onPress={nothing} label="label"><Text>lists</Text></ListElement>
									<ListElement onPress={nothing} icon="school"><Text>lists</Text></ListElement>
									<ListElement
										onPress={nothing}
										label="label"
										icon="school"
										badge={{color: GREEN, value: "12"}}
									>
										<Text>lists</Text>
									</ListElement>
									<ListElement
										onPress={nothing}
										label="label"
										image={{
											mediaType: "image",
											mime: "image/png",
											source: Images.Avatar,
											sourceType: "require",
										}}
										buttons={[
											{icon: "video", key: "video", notif: 2, onPress: nothing, size: "small", type: "secondary"},
											{icon: "phone", key: "phone", onPress: nothing, size: "small", type: "secondary"},
										]}
									>
										<Text>lists</Text>
									</ListElement>
									<InterpreterElement interpreter={interpretersExamples[0]}/>
									<Spacer size={SMALL_SPACING}/>
									<InterpreterElement
										interpreter={interpretersExamples[0]}
										onPress={() => null}
										buttons={[
											{
												icon: "phone",
												key: "phone",
												onPress: () => linkToCall(interpretersExamples[0].phone),
											}, {
												icon: "plus",
												key: "createSession",
												onPress: () => null,
											},
										]}
									/>
									<Spacer size={SMALL_SPACING}/>
									<SessionElement
										session={fakeDatabase.activities.find(a => a.type === "interpreterMandate") as SessionPreview}/>
								</View>
							),
						],
						title: "Lists",
					}, {
						data: [
							<View key="input-button" style={styles.defaultContainer}>
								<ButtonInput leftIcon={null}/>
								<ButtonInput/>
								<ButtonInput
									leftIcon="edit"
									rightIcon={{icon: "send"}}
									placeholder="Write something..."
									multiline
								/>
							</View>,
							<ListInputWrapper key="input-list">
								<ListInput
									key="random-1"
									type={{key: "text", value: "Lorem Ipsum"}}
									icon="school"
								/>
								<ListInput
									key="random-2"
									type={{key: "text", value: "Lorem Ipsum"}}
									icon="school"
									label="label"
								/>
								<ListInput
									key="random-3"
									type={{key: "text", props: {placeholder: "Disabled"}, value: "Lorem Ipsum"}}
									icon="school"
									label="label"
									disabled
								/>
								<ListInput
									key="random-4"
									type={{key: "text", props: {multiline: true}, value: "Lorem Ipsum"}}
									icon="school"
									label="label"
								/>
							</ListInputWrapper>,
						],
						title: "Inputs",
					}, {
						data: [
							<HeaderMenu key="menu-header"/>,
							<HeaderMenu key="menu-header-title" center="Title"/>,
						],
						title: "Menu",
					}, {
						data: [
							(<Timeline
								key="timeline"
								data={(fakeDatabase.activities.find(a => a.type === "interpreterMandate") as Session).timeline ?? []}
							/>),
						],
						title: "Chat",
					}, {
						data: [
							(<Form
								key="form"
								title="form"
								inputs={[
									{
										disabled: false,
										explanation: "This is an explanation",
										icon: "search",
										label: "This is a Label for a dropdown select",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getLabelText: getLabelTextLanguage,
											getRequest: getLanguageRequest,
											idKey: "id",
											itemTranslationKey: "common:language",
											key: "dropdownSelect",
											onChangeValue: (dropdownSelectOne: any[] | null) =>
												setFormValues(prev => ({...prev, dropdownSelectOne})),
											onSearch: onSearchLanguage,
											value: formValues.dropdownSelectOneLanguage,
										},
									}, {
										disabled: false,
										explanation: "This is an explanation",
										icon: "search",
										label: "This is a Label for a dropdown select multiple",
										preview: false,
										resetable: true,
										rules: [{rule: Rules.notEmpty, type: "error"}],
										type: {
											getLabelText: getLabelTextLanguage,
											getRequest: getLanguageRequest,
											idKey: "id",
											itemTranslationKey: "common:language",
											key: "dropdownSelect",
											multiple: true,
											onChangeValue: (dropdownSelectMultiple: any[] | null) =>
												setFormValues(prev => ({...prev, dropdownSelectMultiple})),
											onSearch: onSearchLanguage,
											value: formValues.dropdownSelectMultipleLanguage,
										},
									},
								]}
							/>),
						],
						title: "form",
					},
				]}
			/>
		</>
	);
};

const styles = StyleSheet.create({
	color: {
		borderColor: BLACK,
		borderRadius: 30,
		borderWidth: 2,
		height: 60,
		margin: 10,
		width: 60,
	},
	colors: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	defaultContainer: {
		alignSelf: "center",
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		paddingHorizontal: DEFAULT_SPACING,
		width: "100%",
	},
});

export const ComponentsModal = (props: SharedScreenProps<"ComponentsModal">): JSX.Element => (
	<ModalWrapper>
		<Components {...props}/>
	</ModalWrapper>
);
