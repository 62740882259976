import * as React from "react";
import {MediaDevice} from "../../../../@types/medias/media-device";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {PublicScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {paginate} from "../../../../utils/pagination";
import {SUBTLE_2} from "../../../../utils/styles/colors";
import {forceBack} from "../../../navigation";

type ListItem = Item<"deviceId", MediaDevice>;

const SelectMediaDevice = ({route, navigation}: PublicScreenProps<"SelectMediaDeviceModal">): JSX.Element => {
	const {devices, selectionOrBase, onSelect: onSelectParam} = route.params;
	const {t} = useTranslation();

	const onSelect = (item: MediaDevice) => () => {
		onSelectParam?.(item);
		navigation.dispatch(forceBack);
	};

	const renderItem = ({info: {item}}: FlatListRenderItemParams<"deviceId", ListItem>): JSX.Element => (
		<ListElement
			onPress={onSelect(item)}
			style={item.label === selectionOrBase?.label ? {backgroundColor: SUBTLE_2} : {}}
		>
			{item.label}
		</ListElement>
	);

	return (
		<>
			<HeaderMenu center={t("screens:videoSession.selectDevice")} />
			<SelectFlatList
				getRequest={(pagination) => Promise.resolve(paginate(devices, pagination))}
				renderItem={renderItem}
				itemTranslationKey="forms:items.select"
				idKey="deviceId"
			/>
		</>
	);
};

export const SelectMediaDeviceModal = (props: PublicScreenProps<"SelectMediaDeviceModal">): JSX.Element => (
	<ModalWrapper>
		<SelectMediaDevice {...props} />
	</ModalWrapper>
);
