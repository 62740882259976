import React from "react";
import {DeviceEventEmitter} from "react-native";
import {randomString} from "../randoms";

export const emit = (layers: string[], content: any, mode: "all" | "last" = "all"): any =>
	mode === "all"
		? layers.forEach(layer => DeviceEventEmitter.emit(layer, content))
		: DeviceEventEmitter.emit(layers[layers.length - 1], content);

export const useDeviceEventEmit = (
	layers: string[],
	eventType: string,
	showContent: (content: any) => void,
): void => {
	React.useEffect(
		() => {
			const layerID = `${eventType}-${randomString()}`;
			layers.push(layerID);
			DeviceEventEmitter.addListener(layerID, showContent);
			return () => {
				layers.splice(layers.indexOf(layerID), 1);
				DeviceEventEmitter.removeAllListeners(layerID);
			};
		},
		// We want to push layerID only on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
};
