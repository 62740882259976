import {
	UserPersonAddRequests, UserPersonDeleteRequests, UserPersonsGetRequests,
	UserPersonUpdateRequests, UserSettingsGetRequests, UserSettingsUpdateRequests,
} from "../../requests/@types/user";
import {UserLoginRequests} from "../../requests/clients/account/login";
import {UserLogoutRequests} from "../../requests/clients/account/logout";
import {UserActivitiesStructureRequests} from "../../requests/clients/activities/activities-structure";
import {
	BillingAddressAddRequest,
	BillingAddressUpdateRequest,
	BillingAddressesRequest, BillingAddressDeleteRequest,
} from "../../requests/clients/activities/billing-addresses";
import {UserSessionCancelRequests} from "../../requests/clients/activities/cancel";
import {UserCategoriesRequests} from "../../requests/clients/activities/categories";
import {UserDaysActivitiesRequests} from "../../requests/clients/activities/day";
import {
	UserPaymentAuthoritiesRequests, UserPaymentAuthorityAddRequest, UserPaymentAuthorityUpdateRequest,
	UserPaymentAuthorityDeleteRequest,
} from "../../requests/clients/activities/payment-authorities";
import {UserPatientOriginsRequests, UserPersonRightsRequests, UserRequests} from "../../requests/clients/corporation";
import {FeedbackRequests} from "../../requests/clients/feedback";
import {UserInterpreterSessionsCountRequests, UserInterpretersListRequests} from "../../requests/clients/interpreter";
import {UserCreateSessionRequests} from "../../requests/clients/new-session/create-session";
import {UserOriginsRequests} from "../../requests/clients/new-session/origins";
import {
	CommonQualificationSkillsRequests, UserBhaashaGroupsRequests, UserImmediateInterpretersRequests,
	UserInterpreterDataRequests, UserSlotRequests,
} from "../../requests/clients/new-session/session-slots";
import {AddSubjectRequest, DeleteSubjectRequest, UserSessionBookingSubjectRequests} from "../../requests/clients/new-session/subject-suggestion";
import {UserSessionLanguagesRequests} from "../../requests/clients/new-session/target-translation-suggestion";
import {UserLocationAddRequests, UserLocationDeleteRequests, UserLocationsGetRequests} from "../../requests/clients/settings/places";
import {UserPatientAddAndUpdateRequests, UserPatientDeleteRequests, UserPatientsGetRequests} from "../../requests/clients/settings/receivers";
import {UserSupportGetRequests, SessionCreationSettingsRequests} from "../../requests/clients/settings/settings";
import {InterpreterSupportMailGetRequests, InterpreterSupportMailSendRequests} from "../../requests/common/account/support";
import {CommonGetSessionRequests, CommonGetReceiverListedFields} from "../../requests/common/activities/details";
import {SessionPreviewsRequests} from "../../requests/common/activities/preview";
import {
	InterpreterSessionSendRequests,
	UserVideoSessionLinkRequests,
} from "../../requests/common/activities/share-session";
import {GoogleLocationsRequests} from "../../requests/common/maps";
import {InterpreterChangePasswordRequests} from "../../requests/interpreters/account/change-password";
import {InterpreterLoginRequests} from "../../requests/interpreters/account/login";
import {InterpreterLogoutRequests} from "../../requests/interpreters/account/logout";
import {InterpreterEventsAddressesRequests} from "../../requests/interpreters/account/places";
import {InterpreterRecoverPasswordRequests} from "../../requests/interpreters/account/recover";
import {InterpreterExportSalaryRequests, InterpreterSalaryRequests} from "../../requests/interpreters/account/salary";
import {
	InterpreterSessionAcceptRequests,
	InterpreterSessionRejectRequests,
} from "../../requests/interpreters/activities/actions/accept";
import {InterpreterFollowUpSessionAddRequests, InterpreterFollowUpSessionGetRequests} from "../../requests/interpreters/activities/actions/follow-up";
import {
	CommonSessionValidationTypesRequests, CommonSessionFeedbackValidationRequest,
	CommonSessionValidationUpdateRequests,
} from "../../requests/interpreters/activities/actions/validate";
import {InterpreterFilteredActivitiesStructureRequests} from "../../requests/interpreters/activities/activities-structure";
import {InterpreterBackendDayActivitiesRequests} from "../../requests/interpreters/activities/day";
import {InterpreterSessionInvitationsRequests} from "../../requests/interpreters/activities/inbox";
import {InterpreterSessionPersonsRequests} from "../../requests/interpreters/activities/people";
import {InterpreterReceiverListRequests} from "../../requests/interpreters/activities/receivers";
import {InterpreterRequesterListRequests} from "../../requests/interpreters/activities/requesters";
import {
	InterpreterBusyEventEditRequests, InterpreterBusyEventGetRequests, InterpreterBusyEventRemoveRequests,
	InterpreterBusyEventsAddRequests,
} from "../../requests/interpreters/activities/unavailabilities";
import {InterpreterSessionsFilterRequests} from "../../requests/interpreters/activities/upcoming";
import {
	InterpreterAvailabilitiesRequests, InterpreterProfilePictureGetRequests,
	InterpreterProfilePictureUpdateRequests, InterpreterRequests,
} from "../../requests/interpreters/interpreter";
import {
	CommonNotificationsRequests, InterpreterFollowUpDurationGetRequests, InterpreterSettingsGetRequests,
	InterpreterSettingsUpdateRequests, SessionRefusalSettingsRequests,
} from "../../requests/interpreters/settings/settings";
import {logoutUtil, RequestTokenHolder} from "../auth";
import {API_HOST, HTTP_PROTOCOL} from "../constants";
import {getKeys} from "../objects";
import {DataType, Headers, Methods, request} from "./requests";
import {StatusCode} from "./status-codes";

type Endpoints =
	"addSubject" |
	"billingAddressAdd" |
	"billingAddressDelete" |
	"billingAddresses" |
	"billingAddressUpdate" |
	"commonGetReceiverListedFields" |
	"commonGetSession" |
	"commonNotifications" |
	"commonQualificationSkills" |
	"commonSessionFeedbackValidation" |
	"commonSessionsFilter" |
	"commonSessionValidationTypes" |
	"commonSessionValidationUpdate" |
	"deleteSubject" |
	"fetchSessionPreviews" |
	"googleLocations" |
	"interpreter" |
	"interpreterAvailabilities" |
	"interpreterBilledSessions" |
	"interpreterBusyEventEdit" |
	"interpreterBusyEventGet" |
	"interpreterBusyEventRemove" |
	"interpreterBusyEventsAdd" |
	"interpreterChangePassword" |
	"interpreterEventsAddresses" |
	"interpreterExportStatistics" |
	"interpreterFilteredActivitiesStructure" |
	"interpreterFilteredDayActivities" |
	"interpreterFollowUpDurationGet" |
	"interpreterFollowUpSessionAdd" |
	"interpreterFollowUpSessionGet" |
	"interpreterLogin" |
	"interpreterLogout" |
	"interpreterProfilePictureGet" |
	"interpreterProfilePictureUpdate" |
	"interpreterReceiverList" |
	"interpreterRecoverPassword" |
	"interpreterRequesterList" |
	"interpreterSessionAccept" |
	"interpreterSessionInvitations" |
	"interpreterSessionPersons" |
	"interpreterSessionReject" |
	"interpreterSessionSend" |
	"interpreterSettingsGet" |
	"interpreterSettingsUpdate" |
	"interpreterStatistics" |
	"interpreterSupportMailGet" |
	"interpreterSupportMailSend" |
	"interpreterValidationCardsMailSend" |
	"sessionCreationSettings" |
	"sessionFeedback" |
	"sessionRefusalSettings" |
	"user" |
	"userActivitiesStructure" |
	"userBhaashaGroups" |
	"userCategories" |
	"userCreateSession" |
	"userDaysActivities" |
	"userImmediateInterpreters" |
	"userInterpreterData" |
	"userInterpreterSessionsCount" |
	"userInterpretersList" |
	"userLocationAdd" |
	"userLocationDelete" |
	"userLocationsGet" |
	"userLogin" |
	"userLogout" |
	"userOrigins" |
	"userPatientAddAndUpdate" |
	"userPatientDelete" |
	"userPatientOrigins" |
	"userPatientsGet" |
	"userPaymentAuthorities" |
	"userPaymentAuthorityAdd" |
	"userPaymentAuthorityDelete" |
	"userPaymentAuthorityUpdate" |
	"userPersonAdd" |
	"userPersonDelete" |
	"userPersonRights" |
	"userPersonsGet" |
	"userPersonUpdate" |
	"userSessionBookingSubject" |
	"userSessionCancel" |
	"userSessionLanguages" |
	"userSettingsGet" |
	"userSettingsUpdate" |
	"userSlot" |
	"userSupportGet" |
	"userVideoSessionLink";

type Params = {
	[key: string]: string,
};

type Request = {
	queryParams?: Params,
	pathParams?: Params,
	body?: any,
	headers?: Headers,
};

interface ApiRequestPayload {
	root: {
		[endpoint in Endpoints]: {
			[method in Methods]?: Request;
		};
	},
}

interface ApiRequestsSpecs extends ApiRequestPayload {
	root: {
		billingAddressAdd: BillingAddressAddRequest,
		billingAddressDelete: BillingAddressDeleteRequest,
		billingAddresses: BillingAddressesRequest,
		billingAddressUpdate: BillingAddressUpdateRequest,
		interpreterLogin: InterpreterLoginRequests,
		interpreterLogout: InterpreterLogoutRequests,
		interpreterAvailabilities: InterpreterAvailabilitiesRequests,
		interpreter: InterpreterRequests,
		interpreterProfilePictureUpdate: InterpreterProfilePictureUpdateRequests,
		interpreterProfilePictureGet: InterpreterProfilePictureGetRequests,
		interpreterSessionInvitations: InterpreterSessionInvitationsRequests,
		commonSessionsFilter: InterpreterSessionsFilterRequests,
		interpreterFilteredActivitiesStructure: InterpreterFilteredActivitiesStructureRequests,
		interpreterFilteredDayActivities: InterpreterBackendDayActivitiesRequests,
		user: UserRequests,
		userInterpreterSessionsCount: UserInterpreterSessionsCountRequests,
		interpreterSessionAccept: InterpreterSessionAcceptRequests,
		interpreterSessionReject: InterpreterSessionRejectRequests,
		userLogin: UserLoginRequests,
		userLogout: UserLogoutRequests,
		interpreterBusyEventGet: InterpreterBusyEventGetRequests,
		interpreterBusyEventsAdd: InterpreterBusyEventsAddRequests,
		interpreterBusyEventRemove: InterpreterBusyEventRemoveRequests,
		interpreterBusyEventEdit: InterpreterBusyEventEditRequests,
		interpreterFollowUpSessionAdd: InterpreterFollowUpSessionAddRequests,
		interpreterFollowUpSessionGet: InterpreterFollowUpSessionGetRequests,
		commonGetSession: CommonGetSessionRequests,
		commonGetReceiverListedFields: CommonGetReceiverListedFields,
		userInterpretersList: UserInterpretersListRequests,
		interpreterStatistics: InterpreterSalaryRequests,
		interpreterBilledSessions: InterpreterSalaryRequests,
		interpreterExportStatistics: InterpreterExportSalaryRequests,
		interpreterSessionSend: InterpreterSessionSendRequests,
		userPersonsGet: UserPersonsGetRequests,
		userPersonDelete: UserPersonDeleteRequests,
		userPersonAdd: UserPersonAddRequests,
		userPersonUpdate: UserPersonUpdateRequests,
		userPatientsGet: UserPatientsGetRequests,
		userPatientDelete: UserPatientDeleteRequests,
		userPatientAddAndUpdate: UserPatientAddAndUpdateRequests,
		userLocationsGet: UserLocationsGetRequests,
		userLocationAdd: UserLocationAddRequests,
		userLocationDelete: UserLocationDeleteRequests,
		interpreterReceiverList: InterpreterReceiverListRequests,
		interpreterRequesterList: InterpreterRequesterListRequests,
		interpreterSessionPersons: InterpreterSessionPersonsRequests,
		commonSessionValidationUpdate: CommonSessionValidationUpdateRequests,
		userActivitiesStructure: UserActivitiesStructureRequests,
		userDaysActivities: UserDaysActivitiesRequests,
		commonSessionValidationTypes: CommonSessionValidationTypesRequests,
		commonSessionFeedbackValidation: CommonSessionFeedbackValidationRequest,
		googleLocations: GoogleLocationsRequests,
		interpreterEventsAddresses: InterpreterEventsAddressesRequests,
		commonNotifications: CommonNotificationsRequests,
		interpreterSettingsUpdate: InterpreterSettingsUpdateRequests,
		interpreterSettingsGet: InterpreterSettingsGetRequests,
		interpreterSupportMailGet: InterpreterSupportMailGetRequests,
		interpreterSupportMailSend: InterpreterSupportMailSendRequests,
		interpreterValidationCardsMailSend: InterpreterSupportMailSendRequests,
		interpreterFollowUpDurationGet: InterpreterFollowUpDurationGetRequests,
		sessionRefusalSettings: SessionRefusalSettingsRequests,
		userSettingsGet: UserSettingsGetRequests,
		userSettingsUpdate: UserSettingsUpdateRequests,
		userPersonRights: UserPersonRightsRequests,
		userPatientOrigins: UserPatientOriginsRequests,
		userSessionCancel: UserSessionCancelRequests,
		userSlot: UserSlotRequests,
		userImmediateInterpreters: UserImmediateInterpretersRequests,
		userBhaashaGroups: UserBhaashaGroupsRequests,
		sessionCreationSettings: SessionCreationSettingsRequests,
		commonQualificationSkills: CommonQualificationSkillsRequests,
		userCreateSession: UserCreateSessionRequests,
		userSessionLanguages: UserSessionLanguagesRequests,
		userSupportGet: UserSupportGetRequests,
		userInterpreterData: UserInterpreterDataRequests,
		userOrigins: UserOriginsRequests,
		userPaymentAuthorities: UserPaymentAuthoritiesRequests,
		userPaymentAuthorityAdd: UserPaymentAuthorityAddRequest,
		userPaymentAuthorityUpdate: UserPaymentAuthorityUpdateRequest,
		userPaymentAuthorityDelete: UserPaymentAuthorityDeleteRequest,
		userCategories: UserCategoriesRequests,
		interpreterRecoverPassword: InterpreterRecoverPasswordRequests,
		interpreterChangePassword: InterpreterChangePasswordRequests,
		userSessionBookingSubject: UserSessionBookingSubjectRequests,
		userVideoSessionLink: UserVideoSessionLinkRequests,
		sessionFeedback: FeedbackRequests,
		fetchSessionPreviews: SessionPreviewsRequests,
		addSubject: AddSubjectRequest,
		deleteSubject: DeleteSubjectRequest,
	},
}

const apiPaths: {[key in Endpoints]: string} = {
	billingAddressAdd: "/v4/user-billing-address",
	billingAddressDelete: "/v4/user-billing-address/:id",
	billingAddresses: "/v4/user-billing-address/:userAccountId",
	billingAddressUpdate: "/v4/user-billing-address/:id",
	interpreterLogin: "/v4/interpreters/login",
	interpreterLogout: "/v4/interpreter/logout",
	interpreterAvailabilities: "/v4/interpreters/availabilities",
	interpreter: "/v4/interpreters/:interpreterId",
	interpreterProfilePictureUpdate: "/v4/person/:personId/profile-pic",
	interpreterProfilePictureGet: "/v4/interpreter/profile-pic",
	interpreterSessionInvitations: "/v5/interpreter/session-invitations",
	commonSessionsFilter: "/v6/book-sessions",
	interpreterFilteredActivitiesStructure: "/v5/interpreter/datewise-structure/:startDate/:endDate",
	interpreterFilteredDayActivities: "/v6/interpreter/busy-event/:startAvailabilityDate/:endAvailabilityDate",
	user: "/v5/user-profile/:userAccountId",
	userInterpreterSessionsCount: "/v5/user/interpreter/sessions/count",
	interpreterSessionAccept: "/v4/confirm-session/:sessionId/interpreters/:interpreterId",
	interpreterSessionReject: "/v4/interpreter/session/:sessionId/session-refuse",
	userLogin: "/v4/users/login",
	userLogout: "/v4/user/logout",
	interpreterBusyEventGet: "/v5/interpreter/busy-event/availability/:availabilityId",
	interpreterBusyEventsAdd: "/v4/interpreters/busy-events",
	interpreterBusyEventRemove: "/v4/busy-Event/:availabilityId",
	interpreterBusyEventEdit: "/v4/interpreters/busy-events",
	interpreterFollowUpSessionAdd: "/v4/followup-session",
	interpreterFollowUpSessionGet: "/v4/sessions/:sessionId/FOLLOWUP",
	commonGetSession: "/v5/sessions/:sessionId",
	commonGetReceiverListedFields: "/v6/sessions/:sessionId",
	userInterpretersList: "/v5/org-inter-maps/interpreter",
	interpreterStatistics: "/v5/statistics/salary/:fromDate/:toDate",
	interpreterBilledSessions: "/v5/statistics/session-salary/:fromDate/:toDate",
	interpreterExportStatistics: "/v4/sessions/export",
	interpreterSessionSend: "/v4/session-template/:sessionId",
	userPersonAdd: "/v4/users",
	userPersonUpdate: "/v4/users/:userId",
	userPersonsGet: "/v4/user-accounts/:userAccountId/users",
	userPersonDelete: "/v4/users/:userAccountUserId",
	userPatientsGet: "/v5/user-account/:userAccountId/patients",
	userPatientDelete: "/v5/user-account/patient/delete/:patientId",
	userPatientAddAndUpdate: "/v4/user-account/patient",
	userLocationsGet: "/v4/user-accounts/:userAccountId/locations",
	userLocationAdd: "/v4/user-account/locations",
	userLocationDelete: "/v4/user-account/locations/delete",
	interpreterReceiverList: "/v5/patient-list",
	interpreterRequesterList: "/v5/user-list",
	interpreterSessionPersons: "/v5/interpreter/session/:sessionId",
	commonSessionValidationUpdate: "/v5/session-validate",
	userActivitiesStructure: "/v5/user/structure-sessions/:startDate/:endDate",
	userDaysActivities: "/v6/user/activity-sessions/:startAvailabilityDate/:endAvailabilityDate",
	commonSessionValidationTypes: "/v4/session-type-validation-methods",
	commonSessionFeedbackValidation: "/v5/org/validation-form",
	googleLocations: "/v6/address/autocomplete",
	interpreterEventsAddresses: "/v6/sessions/address",
	commonNotifications: "/v5/notify/device",
	interpreterSettingsUpdate: "/v5/update-interpreter-setting",
	interpreterSettingsGet: "/v5/interpreter-setting/:interpreterId",
	interpreterSupportMailGet: "/v5/interpreter/help-support-email-ids",
	// interpreterSupportMailSend: "/v4/support-mail",
	interpreterSupportMailSend: "/v5/help-support-mail",
	interpreterValidationCardsMailSend: "/v5/interpreter/validation-cards",
	interpreterFollowUpDurationGet: "/v5/intercultural-course-selection",
	sessionRefusalSettings: "/v5/user/interpreter-setting/:sessionId",
	userSettingsGet: "/v5/user-account-setting/:userAccountId",
	userSettingsUpdate: "/v5/update-user-account-setting/:userAccountId",
	userPersonRights: "/v5/users/:personType",
	userPatientOrigins: "/v4/patient/search-origin",
	userSessionCancel: "/v4/cancel-book-sessions/:sessionId",
	userSlot: "/v4/schedule-sessions/available-slots",
	userImmediateInterpreters: "/v4/immediate-sessions/available-interpreters",
	userBhaashaGroups: "/v4/user-bhaasha-inter-grps",
	sessionCreationSettings: "/v5/user/user-setting",
	commonQualificationSkills: "/v4/interpreter/qualification-skills",
	userCreateSession: "/v5/user/bookSessions",
	userSessionLanguages: "/v5/user/v5/suggested-language",
	userSupportGet: "",
	userInterpreterData: "/v4/slot-based/interpreters-list",
	// userCourseMediationGet: "/v4/org/:orgId/intercultural-course-selection",
	userOrigins: "/v4/countries",
	userPaymentAuthorities: "/v5/paymentAuthorities/org",
	userPaymentAuthorityAdd: "/v5/org/payment-authority",
	userPaymentAuthorityUpdate: "/v5/org/update-payment-authority",
	userPaymentAuthorityDelete: "/v4/payment-authority-option/:paymentAuthorityId",
	userCategories: "/v5/org/category",
	interpreterRecoverPassword: "/v4/interpreter/send-resetlink",
	interpreterChangePassword: "/v4/authentication/reset-password",
	userSessionBookingSubject: "/v5/subject/user-account/:userAccountId",
	userVideoSessionLink: "/v5/user/video-session-link/:sessionId",
	sessionFeedback: "/v4/user-feedback",
	fetchSessionPreviews: "/v4/fetch-sessions",
	addSubject: "/v5/add-subject",
	deleteSubject: "/v5/delete-subject/:subjectId",
};

const serialize = (params: Params): string => {
	const queries: string[] = [];
	if (params) {
		getKeys(params).forEach(p =>
			queries.push(`${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`),
		);
	}
	return queries.join("&");
};

const getApiUrl = (endpoint: Endpoints, queryParams: Params, pathParams: Params) => {
	let url = `${HTTP_PROTOCOL}://${API_HOST}${apiPaths[endpoint]}?${serialize(queryParams)}`;
	if (pathParams) {
		getKeys(pathParams).forEach(p => {
			url = url.replace(`:${p}`, pathParams[p]);
		});
	}
	return url;
};

export const api = <
	E extends Endpoints,
	M extends keyof ApiRequestsSpecs["root"][E],
>(
	endpoint: E,
	method: M,
	requestSpecs: ApiRequestsSpecs["root"][E][M],
	responseType?: DataType,
): Promise<any> => {
	const reqSpecs = requestSpecs as Request;
	const headers = {...reqSpecs?.headers};

	if (RequestTokenHolder.token) {
		headers["X-Xsrf-Token"] = RequestTokenHolder.token;
	}

	return request(
		getApiUrl(endpoint, reqSpecs?.queryParams, reqSpecs?.pathParams),
		method as Methods,
		reqSpecs?.body,
		headers,
		responseType,
	)
		.then(res => {
			switch (responseType) {
				case "arrayBuffer": {
					return res.arrayBuffer();
				}
				case "blob": {
					return res.blob();
				}
				case "formData": {
					return res.formData();
				}
				case "raw": {
					return res;
				}
				default: { return res.json();
				}
			}
		})
		.catch((error) => {
			if (error?.status === StatusCode.UNAUTHORIZED) {
				// Will removing local token and re-render tree (which brings to login)
				return logoutUtil();
			}
			throw error;
		});
};
