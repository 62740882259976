export enum StatusCode {
	OK = 200,
	CREATED = 201,
	ACCEPTED = 202,
	NO_CONTENT = 204,
	PERMANENT_REDIRECT = 308,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	PAYEMENT_REQUIRED = 402,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	CONFLICT = 409,
	TOO_MANY_REQUEST = 429,
	INTERNAL_SERVER_ERROR = 500,
	NOT_IMPLEMENTED = 501,
	SERVICE_UNAVAILABLE = 503,
}
