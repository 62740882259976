import {CalendarSyncActivityPreview, InterpreterCalendarActivityPreview} from "../../../@types/activities/activity";
import {ActivitiesFilters} from "../../../@types/activities/filters";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {InboxSessionList} from "../../@types/activities/session";
import {BusyEventRead} from "../../@types/busy-event";
import {
	transformBackendResponseToCalendarInterpreterActivityPreview,
	transformBackendResponseToCalendarSyncActivityPreview, transformCommunicationTypeFrontToBack,
	transformLanguageISOCodeFrontToBack,
} from "../../utils/transform";

export type InterpreterBackendDayActivitiesRequests = {
	GET: {
		pathParams: {
			startAvailabilityDate: string,
			endAvailabilityDate: string,
		},
		queryParams: {
			sessions?: string,
			toLanguage?: string,
			requester?: string,
			status?: string,
			type?: string,
			showCanceledSessions?: string,
			timeZoneOffset?: string,
			showUnavailabilities?: string
		},
	},
};
export type InterpreterFilteredBackendDayActivitiesRequests = {
	GET: {
		queryParams: {
			startAvailabilityDate: string,
			endAvailabilityDate: string,
			sessions?: string,
			toLanguage?: string,
			requester?: string,
			status?: string,
			type?: string,
			showUnavailabilities?: string
		},
	},
};

export const getInterpreterDaysActivities = async (
	days: Date[],
	filters: ActivitiesFilters,
): Promise<{[dayKey: string]: InterpreterCalendarActivityPreview[]}> => {
	const from = new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate());
	const to = new Date(days[days.length - 1].getFullYear(), days[days.length - 1].getMonth(),
		days[days.length - 1].getDate(), 23, 59, 59);
	const {id, toLanguage, communicationTypes, requester, showCanceledSessions, showUnavailabilities} = filters;
	let status = "CONFIRMED,MISSED,COMPLETED,INPROGRESS,STOPPED";
	if (showCanceledSessions) {
		status += ",CANCELLED,RESCHEDULED";
	}
	const interpreterResponse = await api(
		"interpreterFilteredDayActivities",
		"GET",
		{
			pathParams: {
				startAvailabilityDate: from.getTime().toString(),
				endAvailabilityDate: to.getTime().toString(),
			},
			queryParams: {
				sessions: id || null,
				toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
				status,
				type: communicationTypes && communicationTypes.length > 0
					? transformCommunicationTypeFrontToBack(communicationTypes).toString()
					: "",
				requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
				timeZoneOffset: new Date().getTimezoneOffset().toString(),
				showUnavailabilities: showUnavailabilities ? "true" : "false",
			},
		},
	) as ApiResponse<{[dayKey: string]: InboxSessionList[]}>;
	const eventData = interpreterResponse.isSuccess ? interpreterResponse.data : {};
	const datedActivities: {[dayKey: string]: InterpreterCalendarActivityPreview[]} = {};
	Object.keys(eventData).forEach((dateIndex) => {
		datedActivities[dateIndex] = interpreterResponse.data[dateIndex].map(
			(event: BusyEventRead | InboxSessionList) => transformBackendResponseToCalendarInterpreterActivityPreview(event));
	});
	return interpreterResponse.isSuccess
		? datedActivities
		: Promise.reject(new Error(interpreterResponse.message ?? "Unexpected error in fetching sessions"));
};

export const getInterpreterCalendarSyncDaysActivities = async (
	days: Date[],
	filters: ActivitiesFilters,
): Promise<{[dayKey: string]: CalendarSyncActivityPreview[]}> => {
	const from = new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate());
	const to = new Date(days[days.length - 1].getFullYear(), days[days.length - 1].getMonth(),
		days[days.length - 1].getDate(), 23, 59, 59);
	const {id, toLanguage, communicationTypes, requester, showCanceledSessions, showUnavailabilities} = filters;
	let status = "CONFIRMED,MISSED,COMPLETED,INPROGRESS,STOPPED";
	if (showCanceledSessions) {
		status += ",CANCELLED,RESCHEDULED";
	}
	const interpreterResponse = await api(
		"interpreterFilteredDayActivities",
		"GET",
		{
			pathParams: {
				startAvailabilityDate: from.getTime().toString(),
				endAvailabilityDate: to.getTime().toString(),
			},
			queryParams: {
				sessions: id || null,
				toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
				status,
				type: communicationTypes && communicationTypes.length > 0
					? transformCommunicationTypeFrontToBack(communicationTypes).toString()
					: "",
				requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
				timeZoneOffset: new Date().getTimezoneOffset().toString(),
				showUnavailabilities: showUnavailabilities ? "true" : "false",
			},
		},
	);
	return interpreterResponse.isSuccess
		? transformBackendResponseToCalendarSyncActivityPreview(interpreterResponse)
		: Promise.reject(new Error(interpreterResponse.message ?? "Unexpected error in fetching sessions"),
		);
};
