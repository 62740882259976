import React from "react";
import {getCollapsedDatesHeight} from "../../components/list/items/collapsed-dates";
import {getSectionTitleHeight} from "../../components/list/items/section-title";
import {getSessionHeight} from "../../components/list/items/session";
import {getEventElementHeight} from "../../components/list/items/unavailability";

export const useActivitiesHeights = (showDetails: boolean): [number, number, number, number] => {
	const sectionTitleHeight = React.useMemo(
		() => getSectionTitleHeight(true, false),
		[],
	);
	const sessionHeight = React.useMemo(
		() => getSessionHeight(false, showDetails, false, false),
		[showDetails],
	);
	const unavailabilityHeight = React.useMemo(
		() => getEventElementHeight(),
		[],
	);
	const collapsedDatesHeight = React.useMemo(
		() => getCollapsedDatesHeight(),
		[],
	);
	return [sectionTitleHeight, sessionHeight, unavailabilityHeight, collapsedDatesHeight];
};
