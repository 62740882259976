import {PersonPreview} from "../../../@types/identities/person";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {User} from "../../@types/person";
import {transformGenderBackToFront} from "../../utils/transform-common";

export type InterpreterRequesterListRequests = {
	GET: {
		queryParams: {
			searchText?: string,
			limit?: string,
			offset?: string,
		},
	},
};

export const getInterpreterRequesters = async (pagination: Pagination):
Promise<Page<PersonPreview>> =>
	searchInterpreterRequesters("", pagination);

export const searchInterpreterRequesters = async (search: string, pagination: Pagination):
Promise<Page<PersonPreview>> => {
	const {offset, limit} = pagination;
	const backendOffset = offset === 0 ? 1 : (offset / limit) + 1;
	const interpreterResponse = await api(
		"interpreterRequesterList",
		"GET",
		{
			queryParams: {
				searchText: search,
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	) as ApiResponse<{count: number, user: User[]}>;
	if (interpreterResponse.isSuccess) {
		const miniUsers = interpreterResponse.data.user;
		const miniUserList = miniUsers.map(requester => ({
			identityId: requester.id.toString(),
			accountId: requester.id.toString(),
			fullName: {
				firstName: requester.firstName,
				lastName: requester.lastName,
			},
			email: requester.email,
			phone: requester.telephone,
			gender: transformGenderBackToFront(requester.gender),
			type: "person" as PersonPreview["type"],
		}));
		const {count} = interpreterResponse.data;
		return {
			items: miniUserList,
			hasPreviousPage: offset > 0,
			hasNextPage: count > offset + limit,
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};
