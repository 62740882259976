import {languages as enUSLanguages} from "../en-US/languages";

export const languages: typeof enUSLanguages = {
	akuapem: "Akuapem",
	albanian: "Albanais",
	amharic: "Amharique",
	arab: "Arabe",
	"arab-maghreb": "Arabe (Maghrébin)",
	"arabic-classical": "Arabe (Classique)",
	"arabic-levantine": "Arabe (Levantin)",
	aramaic: "Araméen",
	armenian: "Arménien",
	asante: "Asante",
	azerbaijani: "Azerbaïdjanais",
	azeri: "Azéri",
	bambara: "Bambara",
	basque: "Basque",
	bengali: "Bengali",
	berber: "Berbère",
	bilen: "Bilen",
	bosnian: "Bosniaque",
	bulgarian: "Bulgare",
	catalan: "Catalan",
	chechen: "Tchétchène",
	"chinese-cantonese": "Chinois cantonais",
	"chinese-mandarin": "Chinois mandarin",
	creole: "Créole",
	croatian: "Croate",
	czech: "Tchèque",
	dari: "Dari",
	diakhanke: "Diakhanké",
	diola: "Diola",
	dutch: "Néerlandais",
	dyula: "Dioula",
	english: "Anglais",
	esan: "Esan",
	ewe: "Ewé",
	fante: "Fante",
	farsi: "Farsi (Persan)",
	french: "Français",
	fula: "Peul",
	fulfude: "Foulfouldé",
	ga: "Ga",
	gagauz: "Gagaouze",
	georgian: "Géorgien",
	german: "Allemand",
	greek: "Grec",
	guarani: "Guaraní",
	gujarati: "Gujarati",
	hausa: "Hausa",
	hazaragi: "Hazaragi",
	hebrew: "Hébreu",
	hindi: "Hindi",
	hungarian: "Hongrois",
	igbo: "Igbo",
	italian: "Italien",
	japanese: "Japonais",
	kazakh: "Kazakh",
	khmer: "Khmer (Cambodgien)",
	kikongo: "Kikongo",
	kinyarwanda: "Kinyarwanda",
	kirgisisch: "Kirghize",
	kirundi: "Kirundi",
	kissi: "Kissi",
	kiswahili: "Kiswahili",
	korean: "Coréen",
	"kurdish-badini": "Kurde badini",
	"kurdish-kurmanji": "Kurde kurmanji",
	"kurdish-kurmanji-(syria)": "Kurde Kurmanji (Syrie)",
	"kurdish-kurmanji-(turkey)": "Kurde Kurmanji (Turquie)",
	"kurdish-sorani": "Kurde sorani",
	"kurdish-zazaki": "Kurde zazaki",
	kutchi: "Katchi",
	lao: "Laotien",
	latvian: "Letton",
	lingala: "Lingala",
	macedonian: "Macédonien",
	malagasy: "Malgache",
	malayalam: "Malayalam",
	malaysian: "Malais",
	malinke: "Malinké",
	mandingo: "Mandingue",
	mandinka: "Mandinka",
	masalit: "Masalit",
	mbaye: "Mbaye",
	mina: "Mina",
	mongolian: "Mongol",
	nepali: "Népalais",
	ngambaye: "Ngambaye",
	nyabode: "Nyabode",
	oromo: "Oromo",
	pashto: "Pashto",
	persan: "Persan",
	"pidgin-english": "Anglais pidgin",
	polish: "Polonais",
	portugese: "Portugais",
	"portugese-brasil": "Portugais (Brésil)",
	"portugese-portugal": "Portugais (Portugal)",
	pulaar: "Peul",
	punjabi: "Panjabi",
	romani: "Romani",
	romanian: "Roumain",
	russian: "Russe",
	saho: "Saho",
	serbian: "Serbe",
	sidama: "Sidama",
	sinhalese: "Singhalais",
	slovakian: "Slovaque",
	slovenian: "Slovène",
	somali: "Somali",
	soninke: "Soninké",
	soussou: "Soussou",
	spanish: "Espagnol",
	swahili: "Swahili",
	"swiss-german": "Suisse allemand",
	tagalog: "Tagalog (Philippin)",
	tajik: "Tadjik",
	tamil: "Tamoul",
	telugu: "Télougou",
	thai: "Thaï",
	tibetan: "Tibétain",
	tigre: "Tigré",
	tigrinya: "Tigrigna",
	tshiluba: "Tshiluba",
	turkish: "Turc",
	turkmen: "Turkmène",
	twi: "Twi",
	ukrainian: "Ukrainien",
	"ukrainian-russian": "Ukrainien (Russophone)",
	urdu: "Ourdou",
	uyghur: "Ouïghour",
	uzbek: "Ouzbek ",
	vietnamien: "Vietnamien",
	wolof: "Wolof",
	yoruba: "Yoruba",
};
