import * as React from "react";
import {Place} from "../../../../@types/places";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {LocaleContext} from "../../../../contexts/locale";
import {
	addClientPlace,
	deleteClientPlace,
	getClientPlaces,
	searchClientPlace,
} from "../../../../requests/clients/settings/places";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {mapPageItems} from "../../../../utils/pagination";

export type AddressListItem = Item<"id", {displayed: Place; id: Place["address"]}>;

export const AddressesList = (_: ClientStackNavigatorScreenProps<"AddressesListModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const {accountId} = React.useContext(AuthentifiedContext);

	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", AddressListItem>): JSX.Element => (
		<ListElement buttons={buttons}>
			{item.displayed.address}
		</ListElement>
	);

	return (
		<>
			<HeaderMenu center={t("users:settings.savedAddresses")}/>
			<SelectFlatList
				getRequest={(pagination) => getClientPlaces(accountId!, locale, pagination, "alphabetical")
					.then(addresses =>
						mapPageItems<Place, AddressListItem>(addresses, (a: Place) => ({displayed: a, id: a.address})))}
				onPressAdd={{
					request: (added: AddressListItem) => addClientPlace(accountId!, added.displayed.address)
						.then(result => ({displayed: result, id: result.address})),
					screen: {
						name: "SelectPlaceModal",
						params: {
							title: ct("forms:items.add", {item: t("common:address")}),
						},
					},
				}}
				deleteRequest={(removed: AddressListItem) => deleteClientPlace(accountId!, removed.id)}
				onSearch={{
					request: (pagination, search) =>
						searchClientPlace(accountId!, locale, search, pagination)
							.then(addresses =>
								mapPageItems<Place, AddressListItem>(addresses, (a: Place) => ({displayed: a, id: a.address}))),
				}}
				renderItem={renderItem}
				itemTranslationKey="common:address"
				idKey="id"
			/>
		</>
	);
};

export const AddressesListModal = (props: ClientStackNavigatorScreenProps<"AddressesListModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<AddressesList {...props} />
	</ModalWrapper>
);
