import * as React from "react";
import {StyleSheet, View} from "react-native";
import {CONTAINERS, DEFAULT_SPACING, MENU_HEIGHT} from "../../utils/constants";
import {SUBTLE} from "../../utils/styles/colors";

export interface Props {
	backgroundColor?: string;
	center?: React.ReactNode;
	// Makes left, center, right columns have the same width. Useful to center stuff if unequal left & right width
	equalColumns?: boolean;
	left?: React.ReactNode;
	right?: React.ReactNode;
}

export const HeaderLayout = ({
	center, left, right, backgroundColor = SUBTLE, equalColumns,
}: Props): JSX.Element | null => (
	<View style={[CONTAINERS.MAIN, styles.container, {backgroundColor}]}>
		<View style={[styles.column, styles.first, equalColumns && styles.equalColumns]}>
			{left}
		</View>
		<View style={[styles.column, styles.center]}>
			{center}
		</View>
		<View style={[styles.column, styles.last, equalColumns && styles.equalColumns]}>
			{right}
		</View>
	</View>
);

const styles = StyleSheet.create({
	center: {
		flex: 1,
		justifyContent: "center",
	},
	column: {
		flexDirection: "row",
	},
	container: {
		alignItems: "center",
		flexDirection: "row",
		height: MENU_HEIGHT,
		paddingHorizontal: DEFAULT_SPACING,
	},
	equalColumns: {
		flex: 1,
	},
	first: {
		justifyContent: "flex-start",
	},
	last: {
		justifyContent: "flex-end",
	},
});
