import React from "react";
import {View} from "react-native";
import {Place} from "../../@types/places";
import {GOOGLE_MAPS_API_KEY} from "../../utils/constants";
import {defaultPlace, defaultZoom, MapsProps, mapStyle} from "./maps.common";

export const Map = ({place: placeProps, zoom = defaultZoom, height, style}: MapsProps): JSX.Element => {
	const place = placeProps || defaultPlace;
	const uniqueId = React.useRef(`map-${Math.random().toString().replace("0.", "")}`);
	const script = React.useRef<HTMLElementTagNameMap["script"]>();

	const initMap = React.useCallback(
		(location: Place) => {
			// @ts-expect-error TS2304: Cannot find name 'google'.
			const map = new google.maps.Map(
				document.querySelector(`#${uniqueId.current}`),
				{
					center: location.geocode,
					// Dont use any maps ui controls
					disableDefaultUI: true,
					draggableCursor: "pointer",
					// Disable all gesture an mouse features
					gestureHandling: "none",
					zoom,
					zoomControl: false,
				},
			);
			// Add stuff to map here

			// @ts-expect-error TS2304: Cannot find name 'google'.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const marker = new google.maps.Marker({
				map,
				position: location.geocode,
			});
		},
		[zoom],
	);

	React.useEffect(
		() => {
			// Add google maps library
			if (!(window as any).google || !(window as any).google.maps) {
				script.current = document.createElement("script");
				script.current.type = "text/javascript";
				script.current.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

				const x = document.querySelectorAll("script")[0];
				x.parentNode?.insertBefore(script.current, x);

				// We cannot access google.maps until it's finished loading
				script.current.addEventListener("load", _ => initMap(place));
			} else {
				initMap(place);
			}
		},
		[initMap, place],
	);

	return (
		<View style={[mapStyle, height ? {height} : null, style]} nativeID={uniqueId.current} />
	);
};
