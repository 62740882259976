import {SessionStatus} from "../../@types/activities/session";
import {IconKey} from "../../components/icons";
import {BLACK, ORANGE, BLUE, SUBTLE_4, GREEN, LIGHT_BLUE, DARK_RED, DARK_GREEN} from "../styles/colors";
import {ps, s} from "../switch";

export const getStatusType = (status: SessionStatus): "alert" | "confirmed" | "history" | "sent" => {
	switch (status) {
		case "sent": { return "sent";
		}
		case "confirmed": { return "confirmed";
		}
		default: { return "history";
		}
	}
};

export const getStatusIcon = (status: SessionStatus): IconKey | undefined =>
	ps(getStatusType(status), {
		alert: "notifications",
		confirmed: "calendar",
		default: undefined,
		history: "history",
		sent: "send",
	});

export const getStatusColor = (status: SessionStatus): string => s(status, {
	canceled: SUBTLE_4,
	completed: GREEN,
	confirmed: BLUE,
	deleted: BLACK,
	inProgress: LIGHT_BLUE,
	refused: DARK_RED,
	rescheduled: SUBTLE_4,
	sent: ORANGE,
	stopped: LIGHT_BLUE,
	unvalidated: DARK_GREEN,
});
