import React from "react";
import {
	Animated,
	GestureResponderEvent,
	Pressable as RNPressable,
	PressableProps as RNPressableProps,
	StyleProp,
	View,
	ViewProps,
	ViewStyle,
} from "react-native";
import createAnimatedComponent = Animated.createAnimatedComponent;

export interface PressableProps extends Omit<RNPressableProps, "hitSlop" | "style"> {
	activeOpacity?: number;
	children?: React.ReactNode;
	hitSlop?: ViewProps["hitSlop"];
	style?: StyleProp<ViewStyle>;
	underlayColor?: string;
}

const AnimatedPressable = createAnimatedComponent(RNPressable);

const PressableRender = (props: PressableProps, ref: React.Ref<View>): JSX.Element => {
	const {
		activeOpacity,
		children,
		onPressIn: onPressInProp,
		onPressOut: onPressOutProp,
		underlayColor,
		style,
		...otherProps
	} = props;
	const animated = React.useRef<Animated.Value>(new Animated.Value(1));
	const onPressIn = React.useCallback((event: GestureResponderEvent) => {
		Animated.timing(animated.current, {
			duration: 100,
			toValue: activeOpacity ?? 0.4,
			useNativeDriver: true,
		}).start();
		onPressInProp?.(event);
	}, [activeOpacity, animated, onPressInProp]);
	const onPressOut = React.useCallback((event: GestureResponderEvent) => {
		Animated.timing(animated.current, {
			duration: 200,
			toValue: 1,
			useNativeDriver: true,
		}).start();
		onPressOutProp?.(event);
	}, [animated, onPressOutProp]);

	return underlayColor
		? (
			<RNPressable
				ref={ref}
				{...props}
				style={({pressed}) => [style, pressed && {backgroundColor: underlayColor}]}
			>
				{children}
			</RNPressable>
		)
		: (
			<AnimatedPressable
				ref={ref}
				onPressIn={onPressIn}
				onPressOut={onPressOut}
				style={[{opacity: animated.current}, style]}
				{...otherProps}
			>
				{children}
			</AnimatedPressable>
		);
};

export const Pressable = React.forwardRef(PressableRender);
