import * as React from "react";
import {ReactElement} from "react";
import {SectionList as RNSectionList, SectionListProps as RNSectionListProps} from "react-native";
import {sectionListDefaultProps} from "../../utils/scrollables";

export type SectionList<Item, Section> = RNSectionList<Item, Section>;
export type SectionListProps<Item, Section> = RNSectionListProps<Item, Section>;

const SectionListRenderer = <Item, Section>(
	props: SectionListProps<Item, Section>, ref: React.Ref<SectionList<Item, Section>>): ReactElement =>
	<RNSectionList ref={ref} {...sectionListDefaultProps} {...props} />;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionList = React.forwardRef(SectionListRenderer);
