import {useNavigation} from "@react-navigation/native";
import React from "react";
import {StyleSheet, View, ViewStyle} from "react-native";
import {Phone, SessionStatus, Video} from "../../@types/activities/session";
import {SMALL_SPACING} from "../../utils/constants";
import {formatDuration} from "../../utils/date-time/format";
import {useInterval} from "../../utils/hooks/use-interval";
import {useTranslation} from "../../utils/hooks/use-translation";
import {linkToCall} from "../../utils/network/links";
import {DARK_GREEN, GREEN, LIGHT_GREEN, SUBTLE, SUBTLE_2, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {s} from "../../utils/switch";
import {Button, ButtonProps} from "../buttons/button";
import {Icon, IconKey} from "../icons";
import {Text} from "../texts/text";

interface CallProps {
	communication: Phone | Video;
	end: Date;
	height?: number;
	sessionId: string;
	sessionStatus: SessionStatus;
	start: Date;
	style?: ViewStyle;
}

export const defaultCallHeight = 180;
export const beforeCallDelay = 60 * 60 * 1000; // 60 minutes in milliseconds
const afterCallDelay = 60 * 60 * 1000; // 60 minutes in milliseconds

const CallSection = ({
	backgroundColor,
	button,
	contentColor,
	height,
	icon,
	text,
}: {
	backgroundColor: string;
	button?: Pick<ButtonProps, "backgroundColor" | "contentColor" | "disabled" | "onPress" | "text">;
	contentColor: string;
	height?: number;
	icon?: IconKey;
	text?: string;
}): JSX.Element => (
	<View style={[styles.container, {backgroundColor}, !!height && {height}]}>
		{!!icon && (
			<Icon
				color={contentColor}
				icon={icon}
				size={48}
				style={styles.icon}
			/>
		)}
		{!!text && (
			<Text type="emphasis_1" color={contentColor} centered style={styles.text}>
				{text}
			</Text>
		)}
		{button && (
			<Button
				backgroundColor={button.backgroundColor}
				contentColor={button.contentColor}
				disabled={button.disabled}
				icon="play"
				iconPosition="after"
				onPress={button.onPress}
				style={styles.button}
				text={button.text}
			/>
		)}
	</View>
);

export const Call = ({height, style, start, end, communication, sessionStatus, sessionId}: CallProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation();
	const [now, setNow] = React.useState(new Date());
	const callback = React.useCallback(
		() => setNow(new Date()),
		[],
	);
	useInterval(callback, 1000);
	const status = React.useMemo(
		() => now < start ? "future" : now < end ? "ongoing" : "past",
		[end, now, start],
	);
	const delayedStatus = React.useMemo(
		() => status === "ongoing"
			? "ongoing"
			: status === "future" && start.getTime() - now.getTime() < beforeCallDelay
				? "beforeDelay"
				: status === "past" && now.getTime() - end.getTime() < afterCallDelay
					? "afterDelay"
					: "outdated",
		[end, now, start, status],
	);
	const {backgroundColor, contentColor} = React.useMemo(
		() => ({
			backgroundColor: s(status, {
				future: LIGHT_GREEN,
				ongoing: GREEN,
				past: SUBTLE_2,
			}),
			contentColor: s(status, {
				future: DARK_GREEN,
				ongoing: WHITE,
				past: SUBTLE_4,
			}),
		}),
		[status],
	);
	const onJoinPress = React.useCallback(
		() => {
			if (communication.type === "phone") {
				return linkToCall(communication.phoneNumber);
			}
			if (communication.type === "video") {
				return navigation.navigate(
					"VideoSessionModal",
					{
						channelId: communication.channelId,
						secret: communication.secret,
						sessionId,
						token: communication.token!,
					},
				);
			}
			return Promise.resolve();
		},
		[communication, navigation, sessionId],
	);

	const onSharePress = React.useCallback(
		() => navigation.navigate("ShareSessionModal", {
			sessionId,
			shareType: "video",
		}),
		[navigation, sessionId],
	);

	const showJoinButton = delayedStatus !== "outdated" && !!sessionStatus && sessionStatus !== "sent";
	const canJoinVideo = showJoinButton && communication.type === "video" && !!communication.token;

	return (
		<View style={[styles.wrapper, style]}>
			<CallSection
				backgroundColor={backgroundColor}
				contentColor={contentColor}
				height={height}
				icon={communication.type === "phone" ? "phone" : "video"}
				text={
					status === "ongoing"
						? undefined
						: status === "past"
							? t("activities:sessions.call.done", {duration: formatDuration(end, now)})
							: t("activities:sessions.call.start", {duration: formatDuration(start, now)})
				}
				button={
					showJoinButton
						? {
							backgroundColor: WHITE,
							contentColor: DARK_GREEN,
							disabled: communication.type === "video" && !communication.token,
							onPress: onJoinPress,
							text: communication.type === "video" ? ct("common:join") : ct("common:call"),
						}
						: undefined
				}
			/>
			{canJoinVideo && (
				<CallSection
					backgroundColor={SUBTLE_2}
					contentColor={SUBTLE_4}
					height={height}
					text={t("activities:sessions.call.shareDescription")}
					button={{
						backgroundColor: WHITE,
						contentColor: SUBTLE_4,
						onPress: onSharePress,
						text: ct("common:share"),
					}}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		marginTop: SMALL_SPACING,
	},
	container: {
		alignItems: "center",
		backgroundColor: SUBTLE,
		borderRadius: SMALL_SPACING,
		flex: 1,
		justifyContent: "center",
		margin: SMALL_SPACING,
		minHeight: defaultCallHeight,
		padding: SMALL_SPACING,
	},
	icon: {
		marginBottom: SMALL_SPACING,
	},
	text: {
		marginBottom: SMALL_SPACING,
	},
	wrapper: {
		alignItems: "stretch",
		position: "relative",
		width: "100%",
	},
});
