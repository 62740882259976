import Constants from "expo-constants";
import {Dimensions, Platform, StyleSheet} from "react-native";
import Version from "../version.json";
import {SupportedLocaleKey} from "./locales/locales";
import {BACKGROUND_COLOR} from "./styles/colors";
import {ELEVATIONS} from "./styles/elevations";

export const APP_NAME = "Bhaasha";
export const {width: WINDOW_WIDTH, height: WINDOW_HEIGHT} = Dimensions.get("window");
export const IS_IOS = Platform.OS === "ios";
export const IS_ANDROID = Platform.OS === "android";
export const IS_WEB = Platform.OS === "web";
export const IS_MOBILE = IS_IOS || IS_ANDROID;

export interface ConfigEnvVars {
	ADMIN_APP_URL: string;
	AGORA_APP_ID: string;
	API_HOST: string;
	APP: string;
	AUTO_ADD_NOTIFICATION_TARGET: boolean;
	CLIENT_APP_URL: string;
	CONNECT_WS_HOST: string;
	DISABLE_WEBSOCKET: boolean;
	ENV: string;
	GOOGLE_MAPS_API_KEY_ANDROID: string;
	GOOGLE_MAPS_API_KEY_IOS: string;
	GOOGLE_MAPS_API_KEY_WEB: string;
	HTTP_PROTOCOL: string;
	I18N_DEFAULT_FALLBACK: boolean;
	I18N_DEFAULT_LANGUAGE: SupportedLocaleKey;
	INTERPRETER_APP_URL: string;
	REQUEST_ACCESS_URL: string;
	SENTRY_AUTH_TOKEN: string;
	SENTRY_DSN: string;
	USE_LOGS_SERVICE: boolean;
	WS_HOST: string;
	WS_PROTOCOL: string;
}

const configEnvVars: ConfigEnvVars = Constants.expoConfig?.extra as ConfigEnvVars;

export const {
	ADMIN_APP_URL,
	AGORA_APP_ID,
	API_HOST,
	APP,
	AUTO_ADD_NOTIFICATION_TARGET,
	CLIENT_APP_URL,
	CONNECT_WS_HOST,
	DISABLE_WEBSOCKET,
	ENV,
	GOOGLE_MAPS_API_KEY_ANDROID,
	GOOGLE_MAPS_API_KEY_IOS,
	GOOGLE_MAPS_API_KEY_WEB,
	HTTP_PROTOCOL,
	I18N_DEFAULT_FALLBACK,
	I18N_DEFAULT_LANGUAGE,
	INTERPRETER_APP_URL,
	REQUEST_ACCESS_URL,
	SENTRY_AUTH_TOKEN,
	SENTRY_DSN,
	USE_LOGS_SERVICE,
	WS_HOST,
	WS_PROTOCOL,
} = configEnvVars;
export const GOOGLE_MAPS_API_KEY = IS_WEB
	? GOOGLE_MAPS_API_KEY_WEB
	: IS_IOS
		? GOOGLE_MAPS_API_KEY_IOS
		: GOOGLE_MAPS_API_KEY_ANDROID;

export const BUNDLE_VERSION: string = Version.version;
export const BUNDLE_BUILD: string = Version.build;
export const IS_INTERPRETER = APP === "interpreter";
export const IS_CLIENT = APP === "client";
export const BUNDLE_IDENTIFIER = `com.bhaasha.app.${IS_INTERPRETER ? "interpreter" : "client"}${ENV === "development"
	? ".debug"
	: ENV === "staging" ? ".staging" : ""}`;
export const APP_STORE_URL = IS_INTERPRETER
	? "https://apps.apple.com/us/app/bhaasha-interpreter/id1549503724"
	: "https://apps.apple.com/us/app/bhaasha-client/id6444654098";
export const PLAY_STORE_URL = IS_INTERPRETER
	? "https://play.google.com/store/apps/details?id=com.bhaasha.app.interpreter"
	: "https://play.google.com/store/apps/details?id=com.bhaasha.app.client";

// Duration of the navigation transition for a modal (in ms)
export const MODAL_TRANSITION_DURATION = Platform.select({
	android: 512,
	default: 256,
	ios: 768,
});
export const DEFAULT_POLL_INTERVAL = 20_000;

export const DEFAULT_SPACING = 18;
export const SMALL_SPACING = 9;
export const LARGE_SPACING = 27;
export const EXTRA_LARGE_SPACING = 36;
export const MENU_HEIGHT = 44;
export const VERTICAL_MENU_WIDTH = 200;
export const FOOTER_HEIGHT = 62;
export const SEPARATOR_SIZE = 1;
export const LABELS_HEIGHT = 16;
export const CALENDAR_HEADER_HEIGHT = 73 + LABELS_HEIGHT;
export const TITLEBAR_HEIGHT = MENU_HEIGHT + 4;
export const DAY_HEIGHT = 36;
export const WEEK_SPACING = SMALL_SPACING;
export const WEEK_HEIGHT = DAY_HEIGHT + WEEK_SPACING;
export const CONTENT_CONTAINER_MAX_WIDTH = 496;
export const VIDEO_VIEW_RATIO = 16 / 9;
export const ACTUAL_CONTAINER_SIZE = WINDOW_WIDTH > CONTENT_CONTAINER_MAX_WIDTH
	? CONTENT_CONTAINER_MAX_WIDTH
	: WINDOW_WIDTH;
export const CONTAINERS = StyleSheet.create({
	DRAWER: {
		backgroundColor: BACKGROUND_COLOR,
		maxHeight: 1024,
		width: "100%",
		...ELEVATIONS["12"],
	},
	LIST: {
		paddingVertical: SMALL_SPACING,
	},
	MAIN: {
		alignSelf: "center",
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		width: "100%",
	},
});
