import * as React from "react";
import {Pagination} from "../../../../@types/pagination";
import {SessionCreationSetting} from "../../../../@types/settings";
import {ListElement} from "../../../../components/list/items/list-element";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {getTimeCoef} from "../../../../utils/date-time/helpers";
import {DurationListItem, SessionDurationKey, useGetDurationListItems} from "../../../../utils/hooks/use-get-duration-list-items";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {paginate} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";

const SelectDuration = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"SelectDurationModal">): JSX.Element => {
	const {onSelect: onSelectParam, communicationType} = route.params ?? {};
	const {t} = useTranslation();
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {setupStep: {minimumDuration}} = {setupStep: {minimumDuration: 1}},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};
	const getListItems = useGetDurationListItems();

	const onSelect = React.useCallback(
		(key: SessionDurationKey) => () => {
			onSelectParam?.(Number.parseInt(key, 10) * getTimeCoef("minute"));
			navigation.dispatch(forceBack);
		},
		[navigation, onSelectParam],
	);
	const renderItem = React.useCallback(
		({info: {item}, buttons}: FlatListRenderItemParams<"id", DurationListItem>) => (
			<ListElement
				onPress={onSelect(item.id)}
				buttons={buttons}
			>
				{item.displayed}
			</ListElement>
		),
		[onSelect],
	);
	const getRequest = React.useCallback(
		(pagination: Pagination) => Promise.resolve(
			paginate(
				getListItems(minimumDuration, communicationType),
				pagination,
			),
		),
		[communicationType, getListItems, minimumDuration],
	);

	return (
		<>
			<HeaderMenu center={t("forms:inputs.selectDuration")}/>
			<SelectFlatList
				getRequest={getRequest}
				renderItem={renderItem}
				itemTranslationKey="forms:inputs.selectDuration"
				idKey="id"
			/>
		</>
	);
};

export const SelectDurationModal = (props: ClientStackNavigatorScreenProps<"SelectDurationModal">): JSX.Element => (
	<ModalWrapper>
		<SelectDuration {...props} />
	</ModalWrapper>
);
