import {activities} from "./activities";
import {common} from "./common";
import {commonSentences} from "./common-sentences";
import {entities} from "./entities";
import {feedbacks} from "./feedbacks";
import {forms} from "./forms";
import {languages} from "./languages";
import {origins} from "./origins";
import {routeTitles} from "./route-titles";
import {screens} from "./screens";
import {users} from "./users";

export const enUS = {
	activities,
	common,
	commonSentences,
	entities,
	feedbacks,
	forms,
	languages,
	origins,
	routeTitles,
	screens,
	users,
};
