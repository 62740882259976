import {routeTitles as enUSRouteTitles} from "../en-US/route-titles";

export const routeTitles: typeof enUSRouteTitles = {
	AddressesListModal: "Adresses enregistrées",
	AuthScreensGroup: "Se connecter",
	AvailabilitiesModal: "Disponibilités",
	Calendar: "Calendrier",
	CalendarModal: "Calendrier",
	CancelOrRescheduleSessionModal: "Annuler la session",
	ClientAccount: "Compte",
	ComponentsModal: "Composants",
	ConfirmationModal: "Confirmation",
	DebugModal: "Debug",
	DetailsStep: "Étape de détails",
	EditCorporationModal: "Éditer la société",
	EditPaymentAuthorityModal: "Éditer l'authorité de paiement",
	EditPersonModal: "Éditer la personne",
	EditProfileModal: "Éditer le profil",
	EditSessionCategoryModal: "Éditer la catégorie de session",
	EditSubjectSuggestionModal: "Éditer le sujet",
	FeedbackModal: "Feedback",
	FollowUpModal: "Suivi",
	HomeTabNavigator: "",
	Inbox: "Inbox",
	Interpreter: "Interprète",
	InterpreterAccount: "Compte",
	InterpreterModal: "Interprète",
	InterpreterRootNavigator: "Inbox",
	Interpreters: "Interprètes",
	NewSession: "Nouvelle session",
	NotificationsModal: "Notifications",
	OverlappingSessionsModal: "Avertissement d'indisponibilité",
	SalaryModal: "Salaires",
	ScheduleStep: "Étape de planification",
	SearchInterpretersModal: "Recherche",
	SearchSessionsModal: "Recherche",
	SelectDateCalendarModal: "Sélectionner une date",
	SelectDurationModal: "Sélectionner une durée",
	SelectInterpreterModal: "Sélectionner un interprète",
	SelectInterpretersFilterModal: "Sélectionner un filtre",
	SelectLanguageModal: "Sélectionner une langue",
	SelectMediaDeviceModal: "Sélectionner un dispositif média",
	SelectMonthModal: "Sélectionner un mois",
	SelectNotificationTargetModal: "Sélectionner une adresse de notification",
	SelectOriginModal: "Sélectionner une origine",
	SelectPaymentAuthorityModal: "Sélectionner une authorité de paiement",
	SelectPeopleNumberModal: "Sélectionner le nombre de personnes",
	SelectPersonModal: "Sélectionner une personne",
	SelectPlaceModal: "Sélectionner une adresse",
	SelectQualificationModal: "Sélectionner une qualification",
	SelectReceiverModal: "Sélectionner un bénéficiaire",
	SelectRequesterModal: "Sélectionner un demandeur",
	SelectSessionCategoryModal: "Sélectionner une catégorie de session",
	SelectSessionModal: "Sélectionner une session",
	SelectSessionOptionsModal: "Sélectionner une option",
	SelectSessionStatusModal: "Sélectionner un status de session",
	SelectStatusModal: "Sélectionner un status",
	SelectSubjectSuggestionModal: "Sélectionner un sujet",
	SelectTransportModal: "Sélectionner un transport",
	SelectValidationIssueModal: "Validation de la session",
	SelectValidationTypeModal: "Validation de la session",
	SessionCreationFiltersModal: "Filtres",
	SessionModal: "Session",
	SessionValidationModal: "Validation de la session",
	SetupStep: "Étape de configuration",
	ShareSessionModal: "Partager",
	SignIn: "Se connecter",
	Slider: "Slider",
	SupportModal: "Contacter le support",
	UnavailabilityModal: "Indisponibilité",
	UserRootNavigator: "Nouvelle session",
	VideoSession: "Session vidéo",
};
