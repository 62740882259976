import {Session} from "../../../@types/activities/session";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterSessionSendRequests = {
	PUT: {
		pathParams: {
			sessionId: string,
		},
		body: {
			email: string,
		},
	},
};

export type UserVideoSessionLinkRequests = {
	PUT: {
		pathParams: {
			sessionId: string,
		},
		body: string[],
	},
};

export const shareSessionByEmails = async (sessionId: Session["activityId"], emails: string[]): Promise<void> => {
	if (emails.length === 0) {
		return;
	}
	const interpreterResponse = await api(
		"interpreterSessionSend",
		"PUT",
		{
			pathParams: {
				sessionId,
			},
			body: {
				email: emails[0],
			},
		},
	) as ApiResponse<{}>;
	return interpreterResponse.isSuccess ? Promise.resolve() : Promise.reject(new Error(interpreterResponse.message));
};

export const shareVideoSessionByEmails = async (sessionId: Session["activityId"], emails: string[]): Promise<void> => {
	if (emails.length === 0) {
		return;
	}
	const interpreterResponse = await api(
		"userVideoSessionLink",
		"PUT",
		{
			pathParams: {
				sessionId,
			},
			body: emails,
		},
	) as ApiResponse<{}>;
	return interpreterResponse.isSuccess ? Promise.resolve() : Promise.reject(new Error(interpreterResponse.message));
};
