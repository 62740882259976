import * as React from "react";
import {Animated, GestureResponderEvent, Modal, StyleSheet, View} from "react-native";
import {CONTENT_CONTAINER_MAX_WIDTH, DEFAULT_SPACING} from "../../utils/constants";
import {emit, useDeviceEventEmit} from "../../utils/hooks/use-device-event-emitter";
import {Log} from "../../utils/logs/logs";
import {OVERLAY, WHITE} from "../../utils/styles/colors";
import {ELEVATIONS} from "../../utils/styles/elevations";
import {type ConfirmationButtonProps, ConfirmationContent, type ConfirmationContentProps} from "./confirmation";

const alertLayers: string[] = [];
const ANIMATION_DURATION = 200;

type State = Pick<ConfirmationContentProps, "actions" | "actionsLayout" | "content"> & {visible: boolean};
type AlertContent = Omit<State, "visible">;

export const alert = (alert: AlertContent): any => emit(alertLayers, alert, "last");

export const Alert = (): JSX.Element => {
	const scaleValueRef = React.useRef(new Animated.Value(0));

	const [state, setState] = React.useState<State>({
		actions: [],
		actionsLayout: "row",
		content: "",
		visible: false,
	});
	const {actions, actionsLayout, content, visible} = state;

	const showContent = (alert: AlertContent): void => {
		setState(prev => ({...prev, ...alert, visible: true}));
		Animated.timing(scaleValueRef.current, {
			duration: ANIMATION_DURATION,
			toValue: 1,
			useNativeDriver: true,
		}).start();
	};

	useDeviceEventEmit(alertLayers, "Alert", showContent);

	const close = React.useCallback(
		() => {
			Animated.timing(scaleValueRef.current, {
				duration: ANIMATION_DURATION,
				toValue: 0,
				useNativeDriver: true,
			}).start();
			setTimeout(() => setState(prev => ({...prev, visible: false})), ANIMATION_DURATION / 2);
		},
		[],
	);

	const onAction = (action: ConfirmationButtonProps) => (event: GestureResponderEvent) =>
		Promise.resolve(action.onPress?.(event))
			.then(close)
			.catch(Log.error());

	return (
		<Modal transparent visible={visible}>
			<View style={styles.modalBackground}>
				<Animated.View
					style={[styles.modalContainer, {transform: [{scale: scaleValueRef.current}]}]}>
					<ConfirmationContent
						actions={actions}
						actionsLayout={actionsLayout}
						content={content}
						onAction={onAction}
					/>
				</Animated.View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalBackground: {
		alignItems: "center",
		backgroundColor: OVERLAY,
		flex: 1,
		justifyContent: "center",
	},
	modalContainer: {
		backgroundColor: WHITE,
		borderRadius: DEFAULT_SPACING,
		marginHorizontal: DEFAULT_SPACING,
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		...ELEVATIONS[4],
	},
});
