import * as React from "react";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {PLACEHOLDER} from "../../../../utils/styles/colors";
import {SummaryRow} from "../../../inputs/summary-row";
import {Text} from "../../../texts/text";

export const EmptyIssueSummary = (ct: TranslationFunc): JSX.Element => (
	<SummaryRow>
		<Text color={PLACEHOLDER} type="emphasis_1">
			{`${ct("common:select")}...`}
		</Text>
	</SummaryRow>
);
