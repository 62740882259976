import * as React from "react";
import {InterpreterFilters} from "../../@types/identities/filters";
import {InterpretersGroup} from "../../@types/identities/interpreters-group";
import {Gender, genders} from "../../@types/identities/person";
import {Pagination} from "../../@types/pagination";
import {Qualification} from "../../@types/qualifications";
import {SessionCreationSetting, ToccoSetting} from "../../@types/settings";
import {ListInputProps} from "../../components/inputs/list";
import {AuthentifiedContext} from "../../contexts/authentified";
import {ResponsiveContext} from "../../contexts/responsive";
import {
	getOriginList,
	notRelevantOrigin,
	Origin,
	OriginListItem,
	OriginsOrNotRelevant,
} from "../../navigation/screens/common/modals/select-origin";
import {getQualifications} from "../../requests/common/qualifications";
import {filterTruthy} from "../arrays";
import {Rules} from "../inputs/rules/rules";
import {Locales} from "../locales/locales";
import {Log} from "../logs/logs";
import {paginate} from "../pagination";
import {SessionLanguagesKey} from "../sessions/languages";
import {useTranslation} from "./use-translation";

interface Options {
	disableRules?: boolean;
	filters: {
		filters?: InterpreterFilters;
		setFilters: React.Dispatch<React.SetStateAction<InterpreterFilters>>;
	};
	firstName?: boolean;
	gender?: "includeNotRelevant" | "usual";
	interpretersGroups?: boolean;
	languages?: Set<SessionLanguagesKey>;
	lastName?: boolean;
	origins?: {includeNotRelevant?: boolean; values: Origin[]};
	qualifications?: boolean;
}

const getLabelOrigin = (o: OriginListItem): string => o.displayed;

export const useInterpreterFiltersInputs = (
	{
		firstName = true,
		lastName = true,
		languages = new Set(),
		gender = "usual",
		interpretersGroups = true,
		qualifications = true,
		origins = {includeNotRelevant: false, values: []},
		filters: {filters: interpreterFilters, setFilters: setInterpreterFilters},
		disableRules = false,
	}: Options,
	onQualificationsFetched?: () => void,
): {
	inputs: ListInputProps<keyof InterpreterFilters>[];
} => {
	const {ct, t} = useTranslation();
	const fetchingId = React.useRef<number>();
	const lastInterpreterGroups = React.useRef<InterpretersGroup[] | null | undefined>([]);
	const {mobileDisplay} = React.useContext(ResponsiveContext);
	const {settings: {getSetting, loading}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			interpreterFilters: {
				genderMandatory,
				interpretersGroups: interpretersGroupsSetting,
				originMandatory,
			},
		} = {
			interpreterFilters: {
				genderMandatory: false,
				interpretersGroups: [] as InterpretersGroup[],
				originMandatory: false,
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};

	// Fetches the qualifications when the selected interpreter groups change
	React.useEffect(
		() => {
			if (!loading) {
				// Use interpreter groups or default ones if none
				let interpretersGroups = interpreterFilters?.interpretersGroups;
				if (
					(!interpretersGroups || interpretersGroups.length === 0) && interpretersGroupsSetting.length > 0
				) {
					const defaultGroups = interpretersGroupsSetting.filter(igs => igs.default);
					interpretersGroups = defaultGroups?.length === 0 ? null : defaultGroups;
				} else if ((!interpretersGroups || interpretersGroups.length === 0) && interpretersGroupsSetting.length === 0) {
					return Log.error()("No interpreters groups available!");
				}

				// Don't do anything if same interpreter groups
				if (
					interpretersGroups?.length === lastInterpreterGroups.current?.length &&
					interpretersGroups?.every(group => lastInterpreterGroups.current?.some(last => last.id === group.id))
				) {
					return;
				}

				lastInterpreterGroups.current = interpretersGroups;

				const id = Date.now();
				fetchingId.current = id;
				getQualifications(interpretersGroups)
					.then((returnedQualifications: Qualification[]) => {
						// May happen when there are multiple fetch started at the same time. We do something only for the last one
						if (fetchingId.current !== id) {
							return;
						}

						// filter qualifications to keep only the ones that are still available in the current interpretersGroups
						setInterpreterFilters(prev => {
							if (!prev.qualifications) {
								const defaultsQualifications = returnedQualifications.filter(q => q.default);
								if (defaultsQualifications.length > 0) {
									return {...prev, interpretersGroups, qualifications: defaultsQualifications};
								}
								return {...prev, interpretersGroups};
							}
							const qualifications = prev.qualifications.filter(
								(q) => returnedQualifications.some(returned => q.id === returned.id));
							return ({...prev, interpretersGroups, qualifications: qualifications.length > 0 ? qualifications : null});
						});
						onQualificationsFetched?.();
					}).catch(Log.error());
			}
		},
		[
			interpreterFilters?.interpretersGroups,
			interpretersGroupsSetting,
			loading,
			onQualificationsFetched,
			setInterpreterFilters,
		],
	);

	React.useEffect(
		() => {
			if (interpreterFilters?.origins && origins?.values.length > 0) {
				const filteredOrigins = interpreterFilters.origins.filter(o => origins?.values.includes(o));
				setInterpreterFilters(prev => ({...prev, origin: filteredOrigins.length > 0 ? filteredOrigins : null}));
			}
		},
		[interpreterFilters?.origins, origins?.values, setInterpreterFilters],
	);

	const getOriginRequest = React.useCallback(
		(pagination: Pagination) => Promise.resolve(
			paginate(
				getOriginList(t, ct, origins.values.length > 0 ? origins.values : null, origins.includeNotRelevant),
				pagination,
			)),
		[ct, origins.includeNotRelevant, origins.values, t],
	);

	const searchOriginRequest = React.useCallback(
		(pagination: Pagination, search: string) => Promise.resolve(
			paginate(
				getOriginList(t, ct, origins.values.length > 0 ? origins.values : null, origins.includeNotRelevant, undefined, search),
				pagination,
			)),
		[ct, origins.includeNotRelevant, origins.values, t],
	);

	const onSearch = React.useMemo(
		() => ({request: searchOriginRequest}),
		[searchOriginRequest],
	);

	return {
		inputs: filterTruthy<ListInputProps<keyof InterpreterFilters>>([
			firstName && {
				icon: "person",
				key: "firstName",
				label: ct("common:firstName"),
				type: {
					key: "firstName",
					onChangeValue: (fn: string) => setInterpreterFilters(prev => ({...prev, firstName: fn})),
					value: interpreterFilters?.firstName,
				},
			},
			lastName && {
				icon: "person",
				key: "lastName",
				label: ct("common:lastName"),
				type: {
					key: "lastName",
					onChangeValue: (ln: string) => setInterpreterFilters(prev => ({...prev, lastName: ln})),
					value: interpreterFilters?.lastName,
				},
			},
			languages.size > 0 && {
				icon: "language",
				key: "language",
				label: ct("common:language"),
				type: {
					key: "screen",
					onChangeValue: (language: SessionLanguagesKey) => setInterpreterFilters(prev => ({...prev, language})),
					params: languages.size > 0
						? {includedLanguages: languages}
						: undefined,
					renderValue: (languageKey: SessionLanguagesKey) => t(`languages:${languageKey}`),
					screen: "SelectLanguageModal",
					value: interpreterFilters?.language,
				},
			},
			gender && {
				icon: "gender",
				key: "gender",
				label: ct("common:gender"),
				rules: !disableRules && genderMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: gender === "includeNotRelevant" ? ["notRelevant", ...genders] : genders,
					getLabelText: (g: Gender | "notRelevant") => ct(`common:${g}`),
					key: "inlineSelect",
					// Coding of values: undefined -> no value (if mandatory), null -> Not relevant, other -> value itself
					onChangeValue: (gs?: (Gender | "notRelevant")[] | null) => setInterpreterFilters(prev => ({
						...prev,
						gender: gs?.[0] === "notRelevant" ? null : gs?.[0],
					})),
					value: !disableRules && genderMandatory && interpreterFilters?.gender === undefined
						? undefined
						: [
							interpreterFilters?.gender === null || interpreterFilters?.gender === undefined
								? "notRelevant"
								: interpreterFilters.gender,
						],
				},
			},
			origins.values && {
				icon: "world",
				key: "origins",
				label: ct("common:origin"),
				rules: !disableRules && originMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: mobileDisplay
					? {
						key: "screen",
						onChangeValue: (o: OriginsOrNotRelevant) => setInterpreterFilters(prev => ({
							...prev,
							origins: o
								? o === notRelevantOrigin
									? null
									: o
								: undefined,
						})),
						params: {
							includeNotRelevant: origins.includeNotRelevant,
							includedOrigins: origins.values.length > 0 ? origins.values : undefined,
							onSelect: (origins: OriginsOrNotRelevant) => setInterpreterFilters(prev => ({
								...prev,
								origins: origins === notRelevantOrigin ? null : origins,
							})),
							selectionOrBase: interpreterFilters?.origins ?? [],
						},
						renderValue: (origins: OriginsOrNotRelevant) => origins === notRelevantOrigin
							? ct("common:notRelevant")
							: origins?.map(o => t(`origins:${o}`)).join(", "),
						// Coding of values: undefined -> no value (if mandatory), null -> Not relevant, other -> value itself
						screen: "SelectOriginModal",
						value: origins.includeNotRelevant && (interpreterFilters?.origins === null || origins.values.length === 0 ||
							(disableRules || (!originMandatory && interpreterFilters?.origins === undefined)))
							? notRelevantOrigin
							: interpreterFilters?.origins,
					}
					: {
						getLabelText: getLabelOrigin,
						getRequest: getOriginRequest,
						idKey: "id",
						itemTranslationKey: "common:origin",
						key: "dropdownSelect",
						multiple: true,
						onChangeValue: (origins: (OriginListItem)[] | null) => {
							setInterpreterFilters(prev => ({
								...prev,
								origins: origins && origins.length > 0
									? origins[origins.length - 1].id === notRelevantOrigin
										? null
										: origins.filter(o => o.id !== notRelevantOrigin).map(o => o.id) as Origin[]
									: undefined,
							}));
						},
						onSearch,
						value: origins.includeNotRelevant && (interpreterFilters?.origins === null || origins.values.length === 0 ||
							(disableRules || (!originMandatory && interpreterFilters?.origins === undefined)))
							? [{displayed: ct("common:notRelevant"), id: notRelevantOrigin}]
							: interpreterFilters?.origins
								? interpreterFilters.origins.map(o => ({displayed: t(`origins:${o}`), id: o}))
								: undefined,
						// Coding of values: undefined -> no value (if mandatory), null -> Not relevant, other -> value itself
						wordsBeginBySearch: true,
					},
			},
			!usingTocco && qualifications && {
				icon: "school",
				key: "qualifications",
				label: ct("common:qualification_plural"),
				resetable: false,
				type: {
					key: "screen",
					onChangeValue: (qs: Qualification[]) => setInterpreterFilters(prev => ({
						...prev,
						qualifications: qs?.length > 0 ? qs : null,
					})),
					params: {
						interpreterFilters: JSON.stringify(interpreterFilters),
						onSelect: (qs: Qualification[]) => setInterpreterFilters(prev => ({
							...prev,
							qualifications: qs ?? null,
						})),
						selectionOrBase: interpreterFilters?.qualifications ?? [],
					},
					renderValue: (qs: InterpreterFilters["qualifications"]) => qs?.map(q => q.value)?.join(", "),
					screen: "SelectQualificationModal",
					value: interpreterFilters?.qualifications,
				},
			},
			interpretersGroups && interpretersGroupsSetting?.length > 1 && {
				icon: "people",
				key: "interpretersGroups",
				label: ct("common:interpretersGroups"),
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: interpretersGroupsSetting.sort((a, b) => Locales.compare(a.name, b.name)),
					getLabelText: ({name}: InterpretersGroup) => name,
					key: "inlineSelect",
					multiple: true,
					onChangeValue: (igs?: InterpretersGroup[] | null) =>
						setInterpreterFilters(prev => ({...prev, interpretersGroups: igs?.length === 0 ? null : igs})),
					value: interpreterFilters?.interpretersGroups,
				},
			},
		]),
	};
};
