import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterChangePasswordRequests = {
	POST: {
		queryParams: {
			lang: string,
		},
		body: {
			password: string,
			resetCode: string,
		},
	},
};
export const changePasswordInterpreter = async (
	password: string, token: string, locale: SupportedLocaleKey,
): Promise<void> => {
	const response = await api(
		"interpreterChangePassword",
		"POST",
		{
			queryParams: {
				lang: locale.slice(0, 2),
			},
			body: {
				password,
				resetCode: token,
			},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? Promise.resolve() : Promise.reject(new Error(response.message));
};
