import * as React from "react";
import {StyleSheet, View} from "react-native";
import {ProvidedServiceType} from "../../@types/activities/mandate";
import {CommunicationType, communicationTypes as cts} from "../../@types/activities/session";
import {DEFAULT_SPACING} from "../../utils/constants";
import {getCommunicationTypeIcon} from "../../utils/sessions/communications";
import {getProvidedServiceIcon} from "../../utils/sessions/services";
import {PRIMARY, SUBTLE_2, SUBTLE_4} from "../../utils/styles/colors";
import {Separator} from "../separator";
import {Spacer} from "../spacer";
import {Label} from "./label";

interface Props {
	communicationTypes: CommunicationType[];
	providedServices?: ProvidedServiceType[];
}

const getColor = (active: boolean): string => active ? PRIMARY : SUBTLE_4;
const renderSpacer = (): JSX.Element => <Spacer mode="vertical" size={DEFAULT_SPACING / 4} />;

export const ProviderLabels = ({communicationTypes, providedServices}: Props): JSX.Element => (
	<View style={styles.container}>
		{cts.map((ct, index) => (
			<React.Fragment key={ct}>
				{index !== 0 && renderSpacer()}
				<Label
					size="small"
					icon={getCommunicationTypeIcon(ct)}
					color={getColor(communicationTypes.includes(ct))}
					backgroundColor={SUBTLE_2}
				/>
			</React.Fragment>
		))}
		{providedServices?.filter(ps => ps !== "standard").map((ps, index) => (
			<React.Fragment key={ps}>
				{index === 0
					? <Separator mode="vertical" />
					: renderSpacer()}
				<Label
					size="small"
					icon={getProvidedServiceIcon(ps)}
					color={PRIMARY}
					backgroundColor={SUBTLE_2}
				/>
			</React.Fragment>
		))}
	</View>
);

const styles = StyleSheet.create({
	container: {
		alignSelf: "flex-start",
		flexDirection: "row",
		height: 22,
	},
});
