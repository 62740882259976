import {getLocales} from "expo-localization";

interface LocaleContext {
	// en
	countryKey: string | null;
	// en-GB
	isRTL: boolean;
	languageKey: string;
	// GB
	localeKey: string; // Right To Left
}

/*
 * // Ordered from most preferred to least preferred.
 * Return example: [
 *   {countryCode: "GB", languageTag: "en-GB", languageCode: "en", isRTL: false},
 *   {countryCode: "US", languageTag: "en-US", languageCode: "en", isRTL: false},
 *   {countryCode: "FR", languageTag: "fr-FR", languageCode: "fr", isRTL: false},
 * ]
 */
export const contextLocales = (): LocaleContext[] =>
	getLocales().map((locale) => ({
		countryKey: locale.regionCode,
		isRTL: locale.textDirection === "rtl",
		languageKey: locale.languageCode,
		localeKey: locale.languageTag,
	}));
