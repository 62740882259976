import {SessionCategory} from "../../../../@types/activities/session-category";

export const sessionCategoriesExamples: SessionCategory[] = [
	{
		id: "catA",
		name: "Category A",
	},
	{
		id: "catB",
		name: "Category B",
	},
	{
		id: "catC",
		name: "Category C",
	},
];
