import {Account} from "../../../@types/accounts";
import {Page, Pagination} from "../../../@types/pagination";
import {TextInputDropdownSuggestionItem} from "../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {AddSubjectResponse} from "../../@types/subject";

export type UserSessionBookingSubjectRequests = {
	GET: {
		pathParams: {
			userAccountId: string,
		},
		queryParams: {
			limit: string,
			offset: string,
			searchText?: string,
		},
	},
};

export type AddSubjectRequest = {
	POST: {
		body: {
			title: string,
		},
	},
};

export type DeleteSubjectRequest = {
	DELETE: {
		pathParams: {
			subjectId: string,
		},
	},
};

export const getSubjectSuggestions = async (pagination: Pagination, accountId: Account["accountId"]): Promise<Page<TextInputDropdownSuggestionItem>> => {
	const {offset, limit} = pagination;
	const subjectResponse = await api(
		"userSessionBookingSubject",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				limit: limit.toString(),
				offset: (offset + 1).toString(),
			},
		},
	) as ApiResponse<Page<TextInputDropdownSuggestionItem>>;

	if (subjectResponse?.isSuccess) {
		return {
			items: subjectResponse?.data?.items?.length > 0 ? subjectResponse.data.items : [],
			hasPreviousPage: offset > 1,
			hasNextPage: subjectResponse.data.totalCount > offset + limit,
			totalCount: subjectResponse.data.totalCount,
		};
	}
	throw new Error(subjectResponse?.message);
};

export const searchSubjectSuggestions = async (pagination: Pagination, search: string, accountId: Account["accountId"]): Promise<Page<TextInputDropdownSuggestionItem>> => {
	const {offset, limit} = pagination;
	const subjectResponse = await api(
		"userSessionBookingSubject",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				limit: limit.toString(),
				offset: (offset + 1).toString(),
				searchText: search,
			},
		},
	) as ApiResponse<Page<TextInputDropdownSuggestionItem>>;

	if (subjectResponse?.isSuccess) {
		return {
			items: subjectResponse?.data?.items?.length > 0 ? subjectResponse.data.items : [],
			hasPreviousPage: offset > 1,
			hasNextPage: subjectResponse.data.totalCount > offset + limit,
			totalCount: subjectResponse.data.totalCount,
		};
	}
	throw new Error(subjectResponse?.message);
};

export const addSubjectSuggestion = async (_accountId: Account["accountId"], added: TextInputDropdownSuggestionItem): Promise<TextInputDropdownSuggestionItem> => {
	const subjectResponse = await api(
		"addSubject",
		"POST",
		{
			body: {
				title: added.displayed,
			},
		},
	) as ApiResponse<AddSubjectResponse>;

	if (subjectResponse?.isSuccess) {
		return {
			displayed: subjectResponse.data.description,
			id: subjectResponse.data.id.toString(),
		};
	}
	throw new Error(subjectResponse?.message);
};

export const deleteSubjectSuggestion = async (_accountId: Account["accountId"], deleted: TextInputDropdownSuggestionItem): Promise<void> => {
	const subjectResponse = await api(
		"deleteSubject",
		"DELETE",
		{
			pathParams: {
				subjectId: deleted.id,
			},
		},
	) as ApiResponse<TextInputDropdownSuggestionItem>;

	return subjectResponse.isSuccess
		? Promise.resolve()
		: Promise.reject(new Error(subjectResponse?.message));
};
