export const WHITE = "#FFFFFF";
export const SUBTLE = "#F9FAFB";
export const SUBTLE_2 = "#E5E7EB";
export const SUBTLE_3 = "#A3A8b0";
export const SUBTLE_4 = "#4B5563";
export const BLACK = "#000000";

export const GREEN = "#22C55E";
export const LIGHT_GREEN = "#86EFAC";
export const DARK_GREEN = "#166534";
export const RED = "#EF4444";
export const LIGHT_RED = "#FCA5A5";
export const DARK_RED = "#991B1B";
export const LIGHT_YELLOW = "#FDE047";
export const YELLOW = "#EAB308";
export const ORANGE = "#F97316";
export const BLUE = "#3B82F6";
export const LIGHT_BLUE = "#93C5FD";
export const CYAN = "#06B6D4";
export const MAGENTA = "#F43F5E";
export const LIME = "#84CC16";

export const CONTRAST = "#EFF6FF";
export const CONTRAST_2 = "#BFDBFE";
export const CONTRAST_3 = "#2563EB";

export const PRIMARY = BLUE;
export const PRIMARY_2 = "#1E293B";

export const CONTENT = "#171717";

export const PLACEHOLDER = "rgba(31, 41, 55, 0.6)";
export const IMAGE_PLACEHOLDER = "rgba(128, 128, 128, 0.2)";
export const BACKGROUND_COLOR = WHITE;
export const ELEVATION_COLOR = BLACK;
export const REFRESH_INDICATOR = SUBTLE_4;
export const TRANSPARENT = "transparent";
export const OVERLAY = "rgba(1, 1, 1, 0.4)";
export const WHITE_OVERLAY = "rgba(255, 255, 255, 0.7)";

export const MENU_BG = PRIMARY_2;
export const MENU_ACTIVE_WEB = "#145A92";
export const MENU_ACTIVE_MOBILE = CONTRAST;
export const MENU_CONTENT_INACTIVE_MOBILE = "#94A3B8";

export const CLIENT_BG = "#F1DB8D";
export const INTERPRETER_BG = LIGHT_BLUE;

export const CALENDAR_SUNDAY = "#6889b0";
