import * as React from "react";
import {Animated, StyleSheet, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {CONTAINERS, DEFAULT_SPACING, WINDOW_HEIGHT} from "../../utils/constants";
import {emit, useDeviceEventEmit} from "../../utils/hooks/use-device-event-emitter";
import {useTranslation} from "../../utils/hooks/use-translation";
import {FeedbackKey, TranslationOpts} from "../../utils/locales/translations";
import {Severity} from "../../utils/logs/logs";
import {LIGHT_BLUE, LIGHT_GREEN, LIGHT_RED, LIGHT_YELLOW, PRIMARY_2} from "../../utils/styles/colors";
import {ELEVATIONS} from "../../utils/styles/elevations";
import {s} from "../../utils/switch";
import {Text} from "../texts/text";
import {Pressable} from "../views/pressable";

const toastLayers: string[] = [];
const ANIMATION_DURATION = 1200;
const SHOW_DURATION = 3000;

interface Content {
	key: FeedbackKey;
	options?: TranslationOpts;
	severity: State["severity"];
}

export const toast = (content: Content): any => emit(toastLayers, content);

interface State {
	position: "top";
	severity: Severity;
	text: string;
}

export const Toast = (): JSX.Element => {
	const {t} = useTranslation();
	const insets = useSafeAreaInsets();
	const closeTimeout = React.useRef<number>();
	const [state, setState] = React.useState<State>({
		position: "top",
		severity: "info",
		text: "",
	});
	const {text, severity, position} = state;
	const visibilityRef = React.useRef(new Animated.Value(0));

	const showContent = ({severity, key, options}: Content): void => {
		clearTimeout(closeTimeout.current);
		setState(prev => ({...prev, severity, text: t(`feedbacks:${key}`, options)}));
		Animated.timing(visibilityRef.current, {
			duration: ANIMATION_DURATION,
			toValue: 1,
			useNativeDriver: true,
		}).start();
		closeTimeout.current = window.setTimeout(
			() => Animated.timing(visibilityRef.current, {
				duration: ANIMATION_DURATION,
				toValue: 0,
				useNativeDriver: true,
			}).start(),
			SHOW_DURATION + ANIMATION_DURATION,
		);
	};

	useDeviceEventEmit(toastLayers, "Toast", showContent);

	const close = (): void => Animated.timing(visibilityRef.current, {
		duration: ANIMATION_DURATION,
		toValue: 0,
		useNativeDriver: true,
	}).start();

	return (
		<Animated.View
			style={[
				styles.wrapper,
				position === "top" && {justifyContent: "flex-start"},
				{
					transform: [{
						translateY: visibilityRef.current.interpolate({
							inputRange: [0, 1],
							outputRange: [-WINDOW_HEIGHT, 0],
						}),
					}],
				},
			]}
			pointerEvents="box-none"
		>
			<Pressable onPress={close}>
				<View
					style={[
						styles.toast,
						{
							backgroundColor: s(severity, {
								debug: LIGHT_BLUE,
								error: LIGHT_RED,
								fatal: LIGHT_RED,
								info: LIGHT_BLUE,
								log: LIGHT_BLUE,
								success: LIGHT_GREEN,
								warning: LIGHT_YELLOW,
							}),
						},
						{paddingTop: insets.top + DEFAULT_SPACING},
					]}
				>
					<View style={CONTAINERS.MAIN}>
						<Text color={PRIMARY_2} centered>{text}</Text>
					</View>
				</View>
			</Pressable>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	toast: {
		...ELEVATIONS["4"],
		padding: DEFAULT_SPACING,
	},
	wrapper: {
		...StyleSheet.absoluteFillObject,
		flexDirection: "column",
	},
});
