import * as React from "react";
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import Collapsible from "react-native-collapsible";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {SUBTLE} from "../../utils/styles/colors";
import {Icon} from "../icons";

export interface BannerProps {
	backgroundColor?: string;
	children: React.ReactElement;
	closable?: boolean;
	open?: boolean;
	style?: StyleProp<ViewStyle>;
	timer?: number;
}

export const Banner = ({children, closable, style, timer, open = true, backgroundColor = SUBTLE}: BannerProps): JSX.Element => {
	const [visible, setVisible] = React.useState(open);

	React.useEffect(
		() => {
			setVisible(open);
		},
		[open],
	);

	React.useEffect(
		() => {
			if (timer) {
				setTimeout(() => setVisible(false), timer);
			}
		},
		[timer],
	);

	const close = React.useCallback(
		() => setVisible(false),
		[],
	);

	return (
		<View style={{backgroundColor}}>
			<Collapsible collapsed={!visible} duration={1000}>
				<View style={[styles.wrapper, style]}>
					<View style={styles.container}>
						{children}
					</View>
					{closable && (
						<Icon
							onPress={close}
							icon="close"
						/>
					)}
				</View>
			</Collapsible>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	wrapper: {
		alignItems: "center",
		borderRadius: DEFAULT_SPACING,
		flexDirection: "row",
		margin: SMALL_SPACING,
		padding: DEFAULT_SPACING,
	},
});
