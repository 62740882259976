import {Account} from "../../../@types/accounts";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {SessionLanguagesKey} from "../../../utils/sessions/languages";
import {PreferredLanguage} from "../../@types/organisation";
import {transformLanguageISOCodeBackToFront} from "../../utils/transform";

export type UserSessionLanguagesRequests = {
	GET: {
		queryParams: {
			lang: string,
		},
	},
};
export const getTargetTranslationSuggestions = async (_accountId: Account["accountId"], currentUILanguage: SupportedLocaleKey): Promise<Set<SessionLanguagesKey>> => {
	const response = await api(
		"userSessionLanguages",
		"GET",
		{
			queryParams: {lang: currentUILanguage.slice(0, 2) as PreferredLanguage},
		},
	) as ApiResponse<string[]>;
	return response.isSuccess
		? new Set(response.data.map(iso => transformLanguageISOCodeBackToFront(iso)))
		: Promise.reject(new Error(response.message));
};
