import {Alias, PersonCommon, PersonPreviewCommon} from "./person";

export const insurances = ["asylblg", "gkv", "unknown", "none", "other"] as const;
export type Insurance = typeof insurances[number];

interface ReceiverInsurance {
	details?: string;
	name: typeof insurances[number];
}

export const ageGroups = ["child", "adult"] as const;
export type AgeGroup = typeof ageGroups[number];

export interface Receiver extends PersonCommon {
	ageGroup?: AgeGroup;
	combinedNumber?: Alias;
	evamNumber?: Alias;
	insurance?: ReceiverInsurance;
	ippNumber?: Alias;
	type: "receiver";
}

export type ReceiverPreview =
	PersonPreviewCommon
	& Pick<Receiver, "ageGroup" | "evamNumber" | "insurance" | "ippNumber" | "type">;
