import {Account} from "../../../@types/accounts";
import {Recurrence} from "../../../@types/activities/activity";
import {Unavailability, UnavailabilityUpdateState} from "../../../@types/activities/unavailability";
import {OverlappingSessionsError} from "../../../@types/errors";
import {compareDays} from "../../../utils/date-time/helpers";
import {DataGetter} from "../../../utils/hooks/use-websocket";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {BusyEventRead, BusyEventsEdit, BusyEventsWrite, BusyEventType} from "../../@types/busy-event";
import {WebSocketUnavailabilitiesUpdateState} from "../../@types/session";
import {transformRecurrenceBackToFront, transformRecurrenceFrontToBack} from "../../utils/transform";

export type InterpreterBusyEventGetRequests = {
	GET: {
		pathParams: {
			availabilityId: string,
		},
	},
};
export type InterpreterBusyEventsAddRequests = {
	POST: {
		pathParams: {
			interpreterId: string,
		},
		queryParams: {
			busyEventType: BusyEventType,
		},
		body: BusyEventsWrite,
	},
};
export type InterpreterBusyEventRemoveRequests = {
	DELETE: {
		pathParams: {
			availabilityId: string,
		},
		queryParams: {
			effectAll: string,
		},
	},
};
export type InterpreterBusyEventEditRequests = {
	PUT: {
		queryParams: {zoneOffset: string},
		body: BusyEventsEdit,
	},
};

export const getUnavailability = async (eventId: Unavailability["activityId"]): Promise<Unavailability> => {
	const interpreterResponse = await api(
		"interpreterBusyEventGet",
		"GET",
		{
			pathParams: {
				availabilityId: eventId,
			},
		},
	) as ApiResponse<BusyEventRead>;
	if (interpreterResponse.isSuccess) {
		const currentEvent = interpreterResponse.data;
		let frontEvent: Unavailability;
		if (currentEvent) {
			const {busyEventType, startDate, endDate, description, location, customDays} = currentEvent.busyEvent;
			frontEvent = {
				activityId: currentEvent.id.toString(),
				start: new Date(startDate),
				end: new Date(new Date(endDate).setSeconds(0)),
				duration: new Date(currentEvent.end).getTime() - new Date(currentEvent.start).getTime(),
				type: "unavailability",
				subject: description,
				allDay: currentEvent.allDay,
				days: customDays,
			};
			if (location) {
				frontEvent.place = {
					address: location,
					geocode: {lat: currentEvent.latitude, lng: currentEvent.longitude},
				};
			}
			if (busyEventType !== "ONCE") {
				frontEvent.recurrence = transformRecurrenceBackToFront(busyEventType) as Recurrence;
			}
		}
		return frontEvent;
	}
	throw new Error(interpreterResponse.interpreterMessage);
};

export const createUnavailability = async (accountId: Account["accountId"], event: Unavailability): Promise<void> => {
	const {start, end, recurrence, subject, allDay, place, days} = event;
	let startDateTime: Date;
	let endDateTime: Date;
	const recurrenceType: BusyEventType = recurrence ? transformRecurrenceFrontToBack(recurrence) : "ONCE";
	const now = new Date();

	if (allDay) {
		startDateTime = compareDays(now, start)
			? now
			: new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
		endDateTime = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59);
	} else {
		startDateTime = new Date(start.getFullYear(), start.getMonth(), start.getDate(),
			start.getHours(), start.getMinutes(), start.getSeconds());
		endDateTime = new Date(end.getFullYear(), end.getMonth(), end.getDate(),
			end.getHours(), end.getMinutes(), end.getSeconds());
	}

	const interpreterResponse = await api(
		"interpreterBusyEventsAdd",
		"POST",
		{
			pathParams: {
				interpreterId: accountId,
			},
			body: {
				startDate: startDateTime.getTime(),
				endDate: endDateTime.getTime(),
				currentTime: Date.now(),
				busyEvents: [{
					allDay,
					customDays: days,
					description: subject,
					startTime: startDateTime.getTime(),
					endTime: endDateTime.getTime(),
					location: place ? place.address : "",
				}],
			},
			queryParams: {busyEventType: recurrenceType},
		},
	) as ApiResponse<string[]>;
	if (!interpreterResponse.isSuccess) {
		throw new OverlappingSessionsError("unavailableTimeslot", interpreterResponse.data);
	}
};

export const updateUnavailability = async (event: Unavailability): Promise<void> => {
	const {subject, recurrence, place, activityId, allDay, start, end, days} = event;
	let startDateTime: Date;
	let endDateTime: Date;
	const recurrenceType: BusyEventType = recurrence ? transformRecurrenceFrontToBack(recurrence) : "ONCE";
	const now = new Date();
	if (allDay) {
		startDateTime = compareDays(now, start)
			? now
			: new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
		endDateTime = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59);
	} else {
		startDateTime = new Date(start.getFullYear(), start.getMonth(), start.getDate(),
			start.getHours(), start.getMinutes(), start.getSeconds());
		endDateTime = new Date(end.getFullYear(), end.getMonth(), end.getDate(),
			end.getHours(), end.getMinutes(), end.getSeconds());
	}
	const interpreterResponse = await api(
		"interpreterBusyEventEdit",
		"PUT",
		{
			queryParams: {zoneOffset: new Date().getTimezoneOffset().toString()},
			body: {
				allDay,
				customDays: days,
				availabilityId: +activityId,
				description: subject,
				busyEventType: recurrenceType,
				startDate: startDateTime.getTime(),
				endDate: endDateTime.getTime(),
				location: place ? place.address : "",
			},
		},
	) as ApiResponse<string[]>;
	if (!interpreterResponse.isSuccess) {
		throw new OverlappingSessionsError("unavailableTimeslot", interpreterResponse.data);
	}
};

export const deleteUnavailability = async (
	eventId: Unavailability["activityId"],
	instances: "all" | "this" = "this",
): Promise<void> => {
	const interpreterResponse = await api(
		"interpreterBusyEventRemove",
		"DELETE",
		{
			pathParams: {
				availabilityId: eventId,
			},
			queryParams: {
				effectAll: instances === "all" ? "true" : "false",
			},
		},
	) as ApiResponse<{}>;
	if (!interpreterResponse.isSuccess) {
		throw new Error(interpreterResponse.interpreterMessage);
	}
};

export const getUnavailabilityUpdateStateData: DataGetter<UnavailabilityUpdateState> = {
	getData: (message: WebSocketUnavailabilitiesUpdateState) => message.data,
	key: "unavailabilityUpdateState",
};

export const sendUnavailabilityUpdateStateMessage = (updateState: UnavailabilityUpdateState): {
	data: UnavailabilityUpdateState;
	key: string;
} => ({
	key: "unavailabilityUpdateState",
	data: updateState,
});
