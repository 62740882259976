import {Place} from "../../../../@types/places";

export const placesExamples: Place[] = [
	{
		address: "Yverdon, Suisse",
		geocode: {lat: 46.807_715_2, lng: 7.103_259},
	}, {
		address: "Rue Gabrielle-Perret-Gentil 4, 1205 Genève",
		geocode: {lat: 46.193_902_2, lng: 6.161_219_3},
	}, {
		address: "Av. de la Gare des Eaux-Vives 3, 1207 Genève",
		geocode: {lat: 46.199_297_8, lng: 6.172_545_3},
	}, {
		address: "Rue du Bugnon 46, 1011 Lausanne",
		geocode: {lat: 46.523_022_2, lng: 6.638_757_9},
	}, {
		address: "Ave Alexandre-Vinet 30 1004, 1004 Lausanne",
		geocode: {lat: 46.522_092_8, lng: 6.637_520_6},
	}, {
		address: "Chem. de Montétan 16, 1004 Lausanne",
		geocode: {lat: 46.528_361_3, lng: 6.612_447_6},
	}, {
		address: "Av. de France 15, 1004 Lausanne",
		geocode: {lat: 46.528_361_3, lng: 6.612_447_6},
	}, {
		address: "Rue de Plaisance 2, 1400 Yverdon-les-Bains",
		geocode: {lat: 46.770_992_2, lng: 6.646_081_4},
	},
];
