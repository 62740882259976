/*
 * https://www.sbb.ch/fr/horaire/horaire-en-ligne/integration-de-l-horaire/instructions.html
 *
 * Language determines the url
 *
 * https://www.sbb.ch/de/kaufen/
 * https://www.sbb.ch/fr/acheter/
 * https://www.sbb.ch/it/acquistare/
 * https://www.sbb.ch/en/buying/
 *
 * verbindungSuchen: connection search
 * erweiterteSuche: advanced search
 *
 * example:
 * https://www.sbb.ch/fr/acheter/pages/fahrplan/fahrplan.xhtml?von=Z%C3%BCrich+HB&nach=Bern&datum=03.07.2018&zeit=16:00&suche=true
 *
 */
import {formatTime, customFormatDate} from "./date-time/format";
import {Locales, SupportedLocaleKey} from "./locales/locales";
import {objectToUriParams} from "./network/links";

const urls: {[key in SupportedLocaleKey]: string} = {
	"de-DE": "https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?",
	"en-US": "https://www.sbb.ch/en/buying/pages/fahrplan/fahrplan.xhtml?",
	"fr-FR": "https://www.sbb.ch/fr/acheter/pages/fahrplan/fahrplan.xhtml?",
	"it-IT": "https://www.sbb.ch/it/acquistare/pages/fahrplan/fahrplan.xhtml?",
};

interface Params {
	date: Date;
	departure: boolean;
	from: string;
	to: string; // Should the date be the departure (true) or arrival (false) ?
}

// The SBB urls parameters are in german...
interface SbbParams {
	// HH:mm
	an: boolean;
	datum: string;
	nach: string;
	// "true" | "false"
	verbindungSuchen: boolean;
	von: string;
	// jj.MM.aaaa
	zeit: string;
}

const paramsToSbbParams = (params: Params): SbbParams => ({
	an: params.departure,
	datum: customFormatDate(params.date, "DD.MM.YYYY"),
	nach: params.to,
	verbindungSuchen: false,
	von: params.from,
	zeit: formatTime(params.date),
});

export const swissPublicTransportUrl = (params: Params): string =>
	`${urls[Locales.currentLocale]}${objectToUriParams({...paramsToSbbParams(params)})}`;

export const fairtiqURL = (): string =>
	"https://use.fairtiq.com/launch";
