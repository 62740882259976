import {Gender} from "../../@types/identities/person";
import {s} from "../../utils/switch";
import {BackendGender} from "../@types/interpreter";
import {Prefix} from "../@types/person";

export const transformGenderBackToFront = (gender: BackendGender): Gender => gender === "MALE"
	? "man"
	: gender === "FEMALE" ? "woman" : undefined;

export const transformGenderFrontToBack = (gender: Gender): BackendGender => s(gender, {
	man: "MALE",
	woman: "FEMALE",
});

export const transformPrefixToGender = (prefix: string): Gender => {
	if (prefix === "Mr" || prefix === "M" || prefix === "Herr" || prefix === "Signor") {
		return "man";
	} else if (prefix === "Mrs" || prefix === "Mme" || prefix === "Frau" || prefix === "Signora") {
		return "woman";
	}
	return undefined;
};

export const transformPrefixFrontToBack = (gender: Gender): Prefix => gender === "man"
	? "Mr"
	: gender === "woman" ? "Mrs" : undefined;
