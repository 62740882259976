import * as React from "react";
import {SessionInfo} from "../../@types/activities/connect";
import {AuthentifiedContext} from "../../contexts/authentified";
import {getSessionInfos, sendKeepAliveMessage} from "../../requests/common/activities/connect";
import {CONNECT_WS_HOST, IS_INTERPRETER, WS_PROTOCOL} from "../constants";
import {Log} from "../logs/logs";
import {useWebSocket} from "./use-websocket";

interface Props {
	joined: boolean;
	onSessionInfoUpdated: (sessionInfo: SessionInfo) => void;
	sessionId: string;
	token: string;
	uid: number;
}

export const useConnect = ({
	joined,
	onSessionInfoUpdated,
	sessionId,
	token: tokenProps,
	uid,
}: Props): ReturnType<typeof useWebSocket> => {
	const {accountId} = React.useContext(AuthentifiedContext);

	const base = `${WS_PROTOCOL}://${CONNECT_WS_HOST}`;
	const role = IS_INTERPRETER ? "interpreter" : "client";
	const account = accountId ? `&accountId=${accountId}` : "";
	const token = encodeURIComponent(tokenProps);
	const url = `${base}?sessionId=${sessionId}&uid=${uid}&token=${token}&role=${role}${account}`;

	const {sendJsonMessage, ...others} = useWebSocket({
		connect: joined,
		handlers: [{
			dataGetter: getSessionInfos,
			onData: onSessionInfoUpdated,
		}],
		onWSError: (error) => Log.error()("Connect - Couldn't connect to the websocket", {base, error, sessionId, token, uid, url}),
		url,
	});

	React.useEffect(
		() => {
			let interval: NodeJS.Timer;
			if (joined) {
				interval = setInterval(() => sendJsonMessage(sendKeepAliveMessage()), 2000);
			}
			return () => clearInterval(interval);
		},
		[sendJsonMessage, joined, sessionId],
	);

	return {sendJsonMessage, ...others};
};
