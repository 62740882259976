import {Session} from "../../../@types/activities/session";
import {IdentityEditableFields} from "../../../@types/identities/identity";
import {PersonListedFields} from "../../../@types/identities/person";
import {Receiver} from "../../../@types/identities/receiver";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {BackendReceiver, GetDetailedSessionResponse} from "../../@types/session";
import {transformReceiverFormBackToFront, transformReceiverListBackendToFrontend} from "../../utils/transform-receiver";
import {transformCreateSessionBackToFront} from "../../utils/transform-session";

export type CommonGetSessionRequests = {
	GET: {
		pathParams: {
			sessionId: string,
		},
	},
};
export type CommonGetReceiverListedFields = {
	GET: {
		pathParams: {
			sessionId: string,
		},
	},
};

export const getSessionDetails = async (id: Session["activityId"]): Promise<Session> => {
	const interpreterResponse = await api(
		"commonGetSession",
		"GET",
		{
			pathParams: {
				sessionId: id,
			},
		},
	) as GetDetailedSessionResponse;
	if (interpreterResponse.isSuccess) {
		return transformCreateSessionBackToFront(interpreterResponse.data);
	}
	throw new Error(interpreterResponse.errorId === "sessionAlreadyAttributed"
		? interpreterResponse.errorId
		: interpreterResponse.interpreterMessage);
};

export const getReceiverListedFields = async (id: Session["activityId"]): Promise<PersonListedFields<Receiver>> => {
	const response = await api(
		"commonGetReceiverListedFields",
		"GET", {
			pathParams: {
				sessionId: id,
			},
		}) as ApiResponse<{receiver: BackendReceiver}>;
	if (response.isSuccess) {
		return new Set(transformReceiverListBackendToFrontend(response.data.receiver.formData));
	}
	throw new Error(response.message);
};

export const getReceiverEditableFields = async (id: Session["activityId"]): Promise<IdentityEditableFields<Receiver>> => {
	const response = await api(
		"commonGetReceiverListedFields",
		"GET", {
			pathParams: {
				sessionId: id,
			},
		},
	) as ApiResponse<{receiver: BackendReceiver}>;
	if (response.isSuccess) {
		return transformReceiverFormBackToFront(response.data.receiver.formData, "interpreter");
	}
	throw new Error(response.message);
};
