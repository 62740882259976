import {Account} from "../../@types/accounts";
import {Corporation} from "../../@types/identities/corporation";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";
import {BackendUser} from "../@types/user";
import {transformBackendUserToCorporation} from "../utils/transform-person";

export type UserRequests = {
	GET: {
		pathParams: {
			userAccountId: string,
		},
	},
};

export type UserPatientOriginsRequests = {
	GET: {
		queryParams: {
			limit?: string,
			offset?: string,
			searchText?: string,
		},
	},
};

export type UserPersonRightsRequests = {
	GET: {
		pathParams: {
			personType: string,
		},
	},
};

export const getCorporation = async (userAccountId: Account["accountId"]): Promise<Corporation> => {
	const userResponse = await api(
		"user",
		"GET",
		{
			pathParams: {
				userAccountId,
			},
		},
	) as ApiResponse<BackendUser>;
	let userData: Corporation;
	if (userResponse.isSuccess) {
		userData = transformBackendUserToCorporation(userResponse.data);
		return userData;
	}
	throw new Error(userResponse.message);
};
