import * as React from "react";
import {Falsy} from "react-native";
import {ActivitiesFilters} from "../../../../@types/activities/filters";
import {CommunicationType, communicationTypes} from "../../../../@types/activities/session";
import {InterpreterPreview} from "../../../../@types/identities/interpreter";
import {PersonPreview} from "../../../../@types/identities/person";
import {ReceiverPreview} from "../../../../@types/identities/receiver";
import {SessionCreationSetting, ToccoSetting} from "../../../../@types/settings";
import {Form} from "../../../../components/inputs/form";
import {ListInputProps} from "../../../../components/inputs/list";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {ActivitiesContext} from "../../../../contexts/activities";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {filterTruthy} from "../../../../utils/arrays";
import {IS_CLIENT, IS_INTERPRETER} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {personIdentity, receiverIdentity} from "../../../../utils/identities";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";

export const SearchSessions = (
	{navigation, inModal}: SharedScreenProps<"SearchSessionsModal"> & {inModal?: boolean},
): JSX.Element => {
	const {ct, t} = useTranslation();
	const {identity, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {filters: initialFilters, setFilters} = React.useContext(ActivitiesContext);

	const {
		value: {detailsStep: {receiver}},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {value: {detailsStep: {}}};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};

	const [sessionsFilters, setSessionsFilters] = React.useState<ActivitiesFilters>(initialFilters);

	const inputs: (Falsy | ListInputProps<keyof ActivitiesFilters>)[] = [
		{
			icon: "id",
			key: "id",
			label: "ID",
			type: {
				key: "text",
				onChangeValue: (id: ActivitiesFilters["id"]) => setSessionsFilters(prev => ({...prev, id})),
				value: sessionsFilters.id,
			},
		}, {
			icon: "language",
			key: "toLanguage",
			label: t("forms:inputs.toLanguage"),
			type: {
				key: "screen",
				onChangeValue: (toLanguage: ActivitiesFilters["toLanguage"]) =>
					setSessionsFilters(prev => ({...prev, toLanguage})),
				params: {
					includedLanguages: IS_INTERPRETER
						? new Set((identity as InterpreterPreview).languages.map(l => l.to))
						: undefined,
				},
				renderValue: (languageKey: ActivitiesFilters["toLanguage"]) => languageKey && t(`languages:${languageKey}`),
				screen: "SelectLanguageModal",
				value: sessionsFilters.toLanguage,
			},
		}, {
			icon: "communicationMode",
			key: "communicationTypes",
			label: ct("common:communicationType"),
			type: {
				choices: communicationTypes,
				getLabelText: (type: CommunicationType) => t(`activities:sessions.communicationTypes.${type}`),
				itemProps: (type: CommunicationType) => ({icon: getCommunicationTypeIcon(type)}),
				key: "inlineSelect",
				multiple: true,
				onChangeValue: (types?: CommunicationType[] | null) =>
					setSessionsFilters(prev => ({
						...prev,
						communicationTypes: !types || types?.length === 0 ? undefined : types,
					})),
				value: sessionsFilters.communicationTypes,
			},
		},
		IS_INTERPRETER && {
			icon: "status",
			key: "showCanceledSessions",
			label: t("forms:inputs.showCanceledSessions"),
			type: {
				key: "checkbox",
				onChangeValue: (showCanceledSessions: ActivitiesFilters["showCanceledSessions"]) =>
					setSessionsFilters(prev => ({...prev, showCanceledSessions})),
				value: sessionsFilters.showCanceledSessions,
			},
		}, {
			icon: "personOutline",
			key: "requester",
			label: ct("common:requester"),
			type: {
				key: "screen",
				onChangeValue: (people: PersonPreview[]) => setSessionsFilters(prev => ({...prev, requester: people?.[0]})),
				params: {exportType: "requester"},
				renderValue: (person: PersonPreview) => personIdentity(person),
				screen: "SelectPersonModal",
				value: sessionsFilters.requester,
			},
		},
		!!receiver && !usingTocco && {
			icon: "receiver",
			key: "receiver",
			label: receiver?.label ?? ct("common:receiver"),
			type: {
				key: "screen",
				onChangeValue: (receiver: ReceiverPreview[]) => setSessionsFilters(
					prev => ({...prev, receiver: receiver?.[0]})),
				params: {exportType: "receiver"},
				renderValue: (receiver: ReceiverPreview) => receiverIdentity(receiver),
				screen: "SelectPersonModal",
				value: sessionsFilters.receiver,
			},
		},
		IS_CLIENT && {
			icon: "personOutline",
			key: "provider",
			label: ct("common:interpreter"),
			type: {
				key: "screen",
				onChangeValue: (interpreters: InterpreterPreview[]) => setSessionsFilters(prev => ({
					...prev,
					provider: interpreters?.[0],
				})),
				params: {exportType: "interpreter"},
				renderValue: (interpreter: InterpreterPreview) => personIdentity(interpreter),
				screen: "SelectPersonModal",
				value: sessionsFilters.provider,
			},
		},
		IS_INTERPRETER && {
			icon: "calendar",
			key: "showUnavailabilities",
			label: t("forms:inputs.showUnavailabilities"),
			type: {
				key: "checkbox",
				onChangeValue: (showUnavailabilities: ActivitiesFilters["showUnavailabilities"]) =>
					setSessionsFilters(prev => ({...prev, showUnavailabilities})),
				value: sessionsFilters.showUnavailabilities,
			},
		},
	];

	const afterReset = React.useCallback(
		(updated: Record<keyof ActivitiesFilters, any>) => setFilters(prev => ({...prev, ...updated})),
		[setFilters],
	);

	return (
		<>
			<HeaderMenu center={ct("common:search")}/>
			<Form
				validation={{
					buttonProps: {icon: "search", text: ct("common:search")},
					onValidation: () => {
						setFilters(prev => ({
							...prev,
							// Filter session filters to keep only the values of the inputs (otherwise overwrites status for example)
							...(Object.fromEntries(
								Object.entries(sessionsFilters)
									.filter(([key]) => filterTruthy(inputs).find(el => el.key === key)),
							)),
						}));
						if (inModal) {
							navigation.goBack();
						}
					},
				}}
				inputs={inputs}
				afterReset={afterReset}
				resetPosition="bottom"
			/>
		</>
	);
};

export const SearchSessionsModal = (props: SharedScreenProps<"SearchSessionsModal">): JSX.Element => (
	<ModalWrapper>
		<SearchSessions {...props} inModal/>
	</ModalWrapper>
);
