import * as React from "react";
import {Animated, GestureResponderEvent, StyleSheet, View} from "react-native";
import {AvailabilityState} from "../../@types/availability";
import {Corporation} from "../../@types/identities/corporation";
import {InterpreterPreview} from "../../@types/identities/interpreter";
import {CONTAINERS, DEFAULT_SPACING, WINDOW_HEIGHT} from "../../utils/constants";
import {getAvailabilityColorFromReason} from "../../utils/hooks/use-interpreters-availabilities";
import {corporationIdentity, personIdentity} from "../../utils/identities";
import {t} from "../../utils/locales/translations";
import {PRIMARY_2, SUBTLE, WHITE} from "../../utils/styles/colors";
import {Icon} from "../icons";
import {Image} from "../images/image";
import {Text} from "../texts/text";
import {TooltipWrapper} from "../tooltip/tooltip-wrapper";
import {BounceBackground} from "../views/bounce-background";
import {Pressable} from "../views/pressable";

export type InterpreterAccountHeader = Pick<InterpreterPreview, "fullName" | "gender" | "identityId" | "picture" | "type">;

interface Props {
	availabilityState?: AvailabilityState;
	buttons?: JSX.Element[];
	editable?: (event: GestureResponderEvent) => void;
	person:
	| InterpreterAccountHeader
	| Pick<Corporation, "group" | "name" | "picture" | "type">;
	scrollY: Animated.Value;
}

const PICTURE_SIZE = 100;

export const AccountHeader = ({
	buttons, scrollY, editable, person, availabilityState,
}: Props): JSX.Element => {
	const personImageContent = (
		<>
			{person.picture && (
				<Image
					source={person.picture.source}
					fullWidth={person.picture.fullWidth}
					fullHeight={person.picture.fullHeight}
					resizeMode={person.picture.resizeMode}
					mime={person.picture.mime}
					sourceType={person.picture.sourceType}
					width={PICTURE_SIZE}
					height={PICTURE_SIZE}
					style={styles.avatarImageContainer}
				/>
			)}
			{editable
				? <Icon icon="edit" size={26} color={WHITE} containerStyle={styles.editIcon}/>
				: availabilityState && (
					<View style={styles.tooltip}>
						<TooltipWrapper
							content={(
								<Text color={WHITE}>
									{t(`users:settings.${availabilityState === "available" ? "availableImmediate" : "unavailableImmediate"}`)}
								</Text>
							)}
						>
							<View style={[styles.available, {backgroundColor: getAvailabilityColorFromReason(availabilityState)}]}/>
						</TooltipWrapper>
					</View>
				)
			}
		</>
	);

	return (
		<Animated.View style={styles.head}>
			<BounceBackground position="top" backgroundColor={SUBTLE}/>
			<Animated.View
				style={[
					styles.avatar,
					{
						transform: [
							{
								scale: scrollY.interpolate({
									inputRange: [Number.MIN_SAFE_INTEGER, -WINDOW_HEIGHT, 0, 90, Number.MAX_SAFE_INTEGER],
									outputRange: [4, 4, 1, 0.6, 0.6],
								}),
							}, {
								translateY: scrollY.interpolate({
									inputRange: [Number.MIN_SAFE_INTEGER, -WINDOW_HEIGHT, 0, 90, Number.MAX_SAFE_INTEGER],
									outputRange: [-PICTURE_SIZE, -PICTURE_SIZE, 0, PICTURE_SIZE / 2, PICTURE_SIZE / 2],
								}),
							},
						],
					},
				]}
			>
				{person.type === "interpreter" && (
					editable
						? <Pressable onPress={editable}>{personImageContent}</Pressable>
						: <View>{personImageContent}</View>
				)}
				{person.type === "corporation" && (
					<Image
						source={person.group.logo?.source}
						fullHeight={person.group.logo?.fullHeight}
						mime={person.group.logo?.mime}
						sourceType={person.group.logo?.sourceType}
						width={person.group.logo?.width}
						height={person.group.logo?.height}
						fullWidth={person.group.logo?.fullWidth}
						style={styles.corporationImage}
						resizeMode="contain"
					/>
				)}
			</Animated.View>
			<Animated.View
				style={{
					opacity: scrollY.interpolate({
						inputRange: [Number.MIN_SAFE_INTEGER, 90, 160, Number.MAX_SAFE_INTEGER],
						outputRange: [1, 1, 0, 0],
					}),
				}}
			>
				{person.type === "interpreter"
					? <Text type="title" centered>{personIdentity(person)}</Text>
					: person.type === "corporation" && (
						<>
							<Text type="title" centered>{corporationIdentity(person)}</Text>
							{person.group && <Text style={styles.group} type="label" centered>{person.group.name}</Text>}
						</>
					)
				}
			</Animated.View>
			{buttons && (
				<View style={[CONTAINERS.MAIN, styles.actions]}>
					{buttons.map((button) => <View key={button.key}>{button}</View>)}
				</View>
			)}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	actions: {
		flexDirection: "row",
		justifyContent: "space-evenly",
		marginTop: DEFAULT_SPACING,
	},
	available: {
		borderColor: SUBTLE,
		borderRadius: 13,
		borderWidth: 4,
		bottom: 2,
		height: 26,
		position: "absolute",
		right: 2,
		width: 26,
	},
	avatar: {
		alignSelf: "center",
		marginBottom: DEFAULT_SPACING,
		position: "relative",
	},
	avatarImageContainer: {
		borderRadius: PICTURE_SIZE / 2,
	},
	corporationImage: {
		alignSelf: "center",
		height: 100,
		justifyContent: "center",
		maxWidth: "100%",
	},
	editIcon: {
		backgroundColor: PRIMARY_2,
		borderRadius: 18,
		height: 36,
		position: "absolute",
		right: 0,
		top: 0,
		width: 36,
	},
	group: {
		marginTop: -3,
	},
	head: {
		backgroundColor: SUBTLE,
		paddingVertical: DEFAULT_SPACING,
	},
	tooltip: {
		bottom: 0,
		height: 3,
		position: "absolute",
		right: 0,
		width: 29,
	},
});
