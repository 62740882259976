import {CalendarSyncActivityPreview, RequesterCalendarActivityPreview} from "../../../@types/activities/activity";
import {ActivitiesFilters} from "../../../@types/activities/filters";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {InboxSessionList} from "../../@types/activities/session";
import {BusyEventRead} from "../../@types/busy-event";
import {
	transformBackendResponseToCalendarRequesterActivityPreview,
	transformBackendResponseToCalendarSyncActivityPreview,
	transformCommunicationTypeFrontToBack,
	transformLanguageISOCodeFrontToBack,
	transformSessionStatusFrontToBack,
} from "../../utils/transform";

export type UserDaysActivitiesRequests = {
	GET: {
		pathParams: {
			startAvailabilityDate: string,
			endAvailabilityDate: string,
		},
		queryParams: {
			sessions?: string,
			toLanguage?: string,
			requester?: string,
			status?: string,
			type?: string,
			interpreterId: string,
			showCanceledSessions?: string,
			timeZoneOffset?: string,
			receiver?: string,
		},
	},
};

export const getClientDaysActivities = async (
	days: Date[],
	filters: ActivitiesFilters,
): Promise<{[dayKey: string]: RequesterCalendarActivityPreview[]}> => {
	const from = new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate());
	const to = new Date(days[days.length - 1].getFullYear(), days[days.length - 1].getMonth(),
		days[days.length - 1].getDate(), 23, 59, 59,
	);
	const {id, toLanguage, communicationTypes, requester, provider, status, receiver} = filters;
	const interpreterResponse = await api(
		"userDaysActivities",
		"GET",
		{
			pathParams: {
				startAvailabilityDate: from.getTime().toString(),
				endAvailabilityDate: to.getTime().toString(),
			},
			queryParams: {
				sessions: id || null,
				toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
				status: status && status.length > 0
					? (status.map(st => transformSessionStatusFrontToBack(st))).toString()
					: "",
				type: communicationTypes && communicationTypes.length > 0
					? transformCommunicationTypeFrontToBack(communicationTypes).toString()
					: "",
				requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
				interpreterId: provider ? provider.identityId : "",
				timeZoneOffset: days[0].getTimezoneOffset().toString(),
				receiver: receiver?.identityId || "",
				// showCanceledSessions: showCanceledSessions ? "true" : "false",
			},
		},
	) as ApiResponse<{[dayKey: string]: InboxSessionList[]}>;
	if (!interpreterResponse.isSuccess) {
		return {};
	}
	const datedActivities: {[dayKey: string]: RequesterCalendarActivityPreview[]} = {};
	Object.keys(interpreterResponse.data).forEach((dateIndex) => {
		datedActivities[dateIndex] = interpreterResponse.data[dateIndex].map(
			(event: BusyEventRead | InboxSessionList) => transformBackendResponseToCalendarRequesterActivityPreview(event));
	});
	return datedActivities;
};

export const getClientCalendarSyncDaysActivities = async (
	days: Date[],
	filters: ActivitiesFilters,
): Promise<{[dayKey: string]: CalendarSyncActivityPreview[]}> => {
	const from = new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate());
	const to = new Date(days[days.length - 1].getFullYear(), days[days.length - 1].getMonth(),
		days[days.length - 1].getDate(), 23, 59, 59,
	);
	const {id, toLanguage, communicationTypes, requester, provider, status} = filters;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const interpreterResponse = await api(
		"userDaysActivities",
		"GET",
		{
			pathParams: {
				startAvailabilityDate: from.getTime().toString(),
				endAvailabilityDate: to.getTime().toString(),
			},
			queryParams: {
				sessions: id || null,
				toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
				status: status && status.length > 0
					? (status.map(st => transformSessionStatusFrontToBack(st))).toString()
					: "",
				type: communicationTypes && communicationTypes.length > 0
					? transformCommunicationTypeFrontToBack(communicationTypes).toString()
					: "",
				requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
				interpreterId: provider ? provider.identityId : "",
				timeZoneOffset: days[0].getTimezoneOffset().toString(),
				// showCanceledSessions: showCanceledSessions ? "true" : "false",
			},
		},
	);
	return interpreterResponse.isSuccess
		? transformBackendResponseToCalendarSyncActivityPreview(interpreterResponse)
		: await Promise.reject(new Error(interpreterResponse.message ?? "Unexpected error in fetching sessions"),
		);
};
