import {NavigationAction} from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import {useKeepAwake} from "expo-keep-awake";
import * as React from "react";
import {LayoutChangeEvent, ListRenderItemInfo, StyleSheet, View, ViewStyle} from "react-native";
import {SupportInfosSetting} from "../../../../@types/settings";
import {Button} from "../../../../components/buttons/button";
import {confirmation} from "../../../../components/feedbacks/confirmation";
import {ListElement} from "../../../../components/list/items/list-element";
import {Modal} from "../../../../components/modal";
import {FlatList} from "../../../../components/scrollables/flat-list";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {VideoSessionActions} from "../../../../components/sessions/videos/actions";
import {VideoSessionPrompt} from "../../../../components/sessions/videos/prompt";
import {VideoOverlay} from "../../../../components/sessions/videos/video-overlay";
import {StatusBar} from "../../../../components/status-bar";
import {Text} from "../../../../components/texts/text";
import {RtcLocalView} from "../../../../components/views/rtc-view/rtc-local-view";
import {RtcRemoteView} from "../../../../components/views/rtc-view/rtc-remote-view";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {filterTruthy} from "../../../../utils/arrays";
import {DEFAULT_SPACING, SMALL_SPACING, VIDEO_VIEW_RATIO} from "../../../../utils/constants";
import {getDuration} from "../../../../utils/date-time/format";
import {useAgora} from "../../../../utils/hooks/use-agora/use-agora";
import {useBeforeRemove} from "../../../../utils/hooks/use-before-remove";
import {useConnect} from "../../../../utils/hooks/use-connect";
import {useInterval} from "../../../../utils/hooks/use-interval";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ct, t} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {PublicScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {BLACK, RED, TRANSPARENT, WHITE} from "../../../../utils/styles/colors";

interface SessionCall {
	effectiveStart?: Date;
	name: string | null;
	timer: number | null;
}

const calculateLayout = (
	containerWidth: number,
	containerHeight: number,
	videoCount: number,
	aspectRatio: number = VIDEO_VIEW_RATIO,
): {cols: number; height: number; width: number} => {
	let bestLayout = {
		area: 0,
		cols: 0,
		height: 0,
		rows: 0,
		width: 0,
	};

	// brute-force search layout where video occupy the largest area of the container
	for (let cols = 1; cols <= videoCount; cols++) {
		const rows = Math.ceil(videoCount / cols);
		const hScale = containerWidth / (cols * aspectRatio);
		const vScale = containerHeight / rows;
		let width;
		let height;
		if (hScale <= vScale) {
			width = Math.floor(containerWidth / cols);
			height = Math.floor(width / aspectRatio);
		} else {
			height = Math.floor(containerHeight / rows);
			width = Math.floor(height * aspectRatio);
		}
		const area = width * height;
		if (area > bestLayout.area) {
			bestLayout = {
				area,
				cols,
				height,
				rows,
				width,
			};
		}
	}
	return bestLayout;
};

const computeTimer = (effectiveStart?: Date | null): number | null => effectiveStart
	? (Date.now() - effectiveStart.getTime())
	: null;

const MoreOptionsModal = ({onClose, open}: {onClose: () => void; open: boolean}): JSX.Element => {
	const {t} = useTranslation();
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {value: supportInfo} = getSetting<SupportInfosSetting>("support/infos", "organization/all") ?? {};

	const renderItem = React.useCallback(
		({item}: ListRenderItemInfo<{displayed: string; key: string; onPress: () => void}>): JSX.Element => (
			<ListElement key={item.key} onPress={item.onPress}>
				{item.displayed}
			</ListElement>
		),
		[],
	);

	return (
		<Modal onClose={onClose} open={open} title={t("screens:videoSession.otherOptions")}>
			<FlatList
				data={filterTruthy([
					!!supportInfo && {
						displayed: t("screens:videoSession.copySupportNumber"),
						key: "support",
						onPress: (): void => {
							Clipboard.setStringAsync(supportInfo.phone).catch(Log.error());
							Log.success("copySupportNumberSuccess")();
						},
					},
				])}
				renderItem={renderItem}
			/>
		</Modal>
	);
};

export const VideoSessionModal = ({navigation, route}: PublicScreenProps<"VideoSessionModal">): JSX.Element => {
	const {accountId} = React.useContext(AuthentifiedContext);
	const {channelId, sessionId, token, secret} = route.params ?? {};
	const {
		audio,
		backgroundBlur,
		cameraName,
		getCameraDevices,
		getMicrophoneDevices,
		initState,
		joinChannel,
		joined,
		leaveChannel,
		microphoneName,
		setCamera,
		setMicrophone,
		switchCamera,
		toggleAudio,
		toggleBackgroundBlur,
		toggleVideo,
		uid,
		users,
		video,
	} = useAgora(channelId, token, sessionId, secret);
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {value: supportInfo} = getSetting<SupportInfosSetting>("support/infos", "organization/all") ?? {};

	useKeepAwake(); // prevents the screen from sleeping

	const [layout, setLayout] = React.useState<{height: number; width: number}>();
	const [{effectiveStart, timer}, setSession] = React.useState<SessionCall>({
		effectiveStart: undefined,
		name: null,
		timer: null,
	});

	const [moreOptionsVisible, setMoreOptionsVisible] = React.useState(false);
	const showMoreOptions = React.useCallback(
		() => setMoreOptionsVisible(true),
		[],
	);
	const hideMoreOptions = React.useCallback(
		() => setMoreOptionsVisible(false),
		[],
	);

	const onAudioSelect = React.useCallback(
		() => {
			getMicrophoneDevices().then(devices =>
				navigation.navigate(
					"SelectMediaDeviceModal",
					{
						devices,
						onSelect: (device) => {
							setMicrophone(device).catch(Log.error());
						},
						selectionOrBase: devices.find(d => d.label === microphoneName),
					},
				)).catch(Log.error());
		},
		[getMicrophoneDevices, navigation, setMicrophone, microphoneName],
	);

	const onVideoSelect = React.useCallback(
		() => {
			getCameraDevices().then(devices =>
				navigation.navigate(
					"SelectMediaDeviceModal",
					{
						devices,
						onSelect: (device) => {
							setCamera(device).catch(Log.error());
						},
						selectionOrBase: devices.find(d => d.label === cameraName),
					},
				)).catch(Log.error());
		},
		[getCameraDevices, navigation, setCamera, cameraName],
	);

	const openConfirmationDrawer = React.useCallback(
		(action?: NavigationAction) => confirmation(
			{
				actions: [
					{
						backgroundColor: RED,
						icon: "check",
						key: "validate",
						onPress: () => !joined && action
							? navigation.dispatch(action)
							: leaveChannel(),
						text: ct("common:exitAnyway"),
					}, {
						icon: "close",
						key: "closeDrawer",
						onPress: () => null,
						text: ct("common:cancel"),
						type: "secondary",
					},
				],
				content: t("screens:videoSession.confirmVideoExit"),
			},
		),
		[navigation, joined, leaveChannel],
	);

	const onLeaveSetup = React.useCallback(
		() => {
			navigation.canGoBack()
				? navigation.goBack()
				: navigation.navigate("HomeTabNavigator", {screen: "Calendar"});
		},
		[navigation],
	);

	const onLeaveCall = React.useCallback(
		() => joined && openConfirmationDrawer(),
		[joined, openConfirmationDrawer],
	);

	const onLayout = React.useCallback(
		(event: LayoutChangeEvent) => {
			const {width, height} = event.nativeEvent.layout;
			setLayout({height, width});
		},
		[],
	);

	/*
	 * const onChangeName = React.useCallback(
	 *   (name: string) => setSession((prev) => ({...prev, name})),
	 *   [],
	 * );
	 */

	useBeforeRemove(openConfirmationDrawer);

	const updateTimer = React.useCallback(
		() => setSession((prev) => ({...prev, timer: computeTimer(effectiveStart)})),
		[effectiveStart],
	);
	useInterval(updateTimer, 20_000, !!effectiveStart);

	useConnect({
		joined,
		onSessionInfoUpdated: ({effectiveStart}) => setSession(prev => ({
			...prev,
			effectiveStart: effectiveStart ?? undefined,
			timer: computeTimer(effectiveStart),
		})),
		sessionId,
		token,
		uid,
	});

	if (!channelId || !token) {
		return (
			<SplashView
				centered
				message={{translationKey: "feedbacks:videoCallJoinFailed", type: "error"}}
			/>
		);
	}
	if (!joined) {
		return (
			<VideoSessionPrompt
				audio={audio}
				backgroundBlur={backgroundBlur}
				getCameraDevices={getCameraDevices}
				getMicrophoneDevices={getMicrophoneDevices}
				initState={initState}
				onAudioSelect={onAudioSelect}
				onJoin={joinChannel}
				onLeave={accountId ? onLeaveSetup : undefined}
				onVideoSelect={onVideoSelect}
				switchCamera={switchCamera}
				toggleAudio={toggleAudio}
				toggleBackgroundBlur={toggleBackgroundBlur}
				toggleVideo={toggleVideo}
				video={video}
				/*
				 * name
				 * onChangeName={onChangeName}
				 */
			/>
		);
	}

	const userCount = users.length + 1; // +1 for self
	const containerWidth = (layout?.width ?? 0) - DEFAULT_SPACING; // - DEFAULT_SPACING Because of padding
	const containerHeight = (layout?.height ?? 0) - DEFAULT_SPACING; // - DEFAULT_SPACING Because of padding
	const {width, height} = calculateLayout(containerWidth, containerHeight, userCount);
	const userContainerStyle = layout && {
		height,
		width,
	} satisfies ViewStyle;
	const {hours, minutes} = timer ? getDuration(timer) : {hours: undefined, minutes: undefined};
	const hoursString = `${(`00${String(hours)}`).slice(-2)}${t("activities:calendar.abbreviation.hour")}`;
	const minutesString = `${(`00${String(minutes)}`).slice(-2)}${t("activities:calendar.abbreviation.minute")}`;

	return (
		<>
			<StatusBar backgroundColor={BLACK} barStyle="light-content"/>
			<View style={styles.container}>
				<MoreOptionsModal onClose={hideMoreOptions} open={moreOptionsVisible} />
				<View style={styles.localUserContainer}>
					<View style={[styles.userView, styles.localUser]}>
						<RtcLocalView style={styles.rtcView}/>
						<VideoOverlay hasVideo={video} hasAudio={audio} size="small"/>
					</View>
				</View>
				<ScrollView
					onLayout={onLayout}
					style={styles.container}
					contentContainerStyle={styles.contentContainer}
				>
					<View style={styles.usersContainer}>
						{users.length > 0 && users.map(({id, hasAudio, hasVideo}, index) => (
							<View key={index} style={[styles.userView, userContainerStyle]}>
								<RtcRemoteView
									style={styles.rtcView}
									uid={id}
									zOrderMediaOverlay
								/>
								<VideoOverlay hasVideo={hasVideo} hasAudio={hasAudio}/>
							</View>
						))}
						{users.length === 0 && (
							<Text type="default_1" color={WHITE}>
								{t("screens:videoSession.noParticipants")}
							</Text>
						)}
					</View>
				</ScrollView>
				<View style={styles.topMessageContainer}>
					<Text type="title_2" color={WHITE} style={styles.topMessage}>
						{timer ? `${hoursString}:${minutesString}` : t("screens:videoSession.participantsPending")}
					</Text>
				</View>
				{!!supportInfo && (
					// Only include the button when support info are available as the only option in the menu is copying it
					<Button
						icon="moreVertical"
						onPress={showMoreOptions}
						size="xsmall"
						style={styles.moreButton}
						type="secondary"
					/>
				)}
				<VideoSessionActions
					audio={audio}
					backgroundBlur={backgroundBlur}
					onLeave={onLeaveCall}
					onAudioSelect={onAudioSelect}
					onVideoSelect={onVideoSelect}
					switchCamera={switchCamera}
					toggleAudio={toggleAudio}
					toggleBackgroundBlur={toggleBackgroundBlur}
					toggleVideo={toggleVideo}
					video={video}
				/>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: BLACK,
		flex: 1,
	},
	contentContainer: {
		alignItems: "center",
		flexGrow: 1,
		justifyContent: "center",
	},
	localUser: {
		height: 100,
		width: 100,
	},
	localUserContainer: {
		margin: DEFAULT_SPACING,
	},
	moreButton: {
		position: "absolute",
		right: DEFAULT_SPACING,
		top: DEFAULT_SPACING,
	},
	rtcView: {
		height: "100%",
		width: "100%",
	},
	topMessage: {
		backgroundColor: BLACK,
		maxWidth: 300,
		textAlign: "center",
	},
	topMessageContainer: {
		alignItems: "center",
		backgroundColor: TRANSPARENT,
		borderRadius: SMALL_SPACING,
		left: 0,
		marginLeft: "auto",
		marginRight: "auto",
		paddingHorizontal: SMALL_SPACING,
		paddingVertical: SMALL_SPACING / 2,
		position: "absolute",
		right: 0,
		top: 0,
	},
	userView: {
		borderColor: BLACK,
		borderRadius: DEFAULT_SPACING,
		borderWidth: SMALL_SPACING / 2,
		overflow: "hidden",
	},
	usersContainer: {
		alignItems: "center",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		padding: SMALL_SPACING,
	},
});
