import * as Clipboard from "expo-clipboard";
import * as React from "react";
import {Animated, EmitterSubscription, Falsy, Keyboard, ListRenderItemInfo, StyleSheet, View} from "react-native";
import {InterpreterMandate, Mediation} from "../../../../@types/activities/mandate";
import {EmailFeedback, InPerson, Session as SessionT} from "../../../../@types/activities/session";
import {IdentityEditableFields} from "../../../../@types/identities/identity";
import {InterpreterPreview, WaveInterpreterProvider} from "../../../../@types/identities/interpreter";
import {PersonIdentity, PersonIdentityPreview, PersonListedField, PersonListedFields} from "../../../../@types/identities/person";
import {MedicalProfessionalPreview, OtherProfessionalPreview} from "../../../../@types/identities/professional";
import {Receiver, ReceiverPreview} from "../../../../@types/identities/receiver";
import {IdentityEditableFieldsSetting, PersonListedFieldsSetting, SessionCreationSetting, ToccoSetting} from "../../../../@types/settings";
import {Button, ButtonProps} from "../../../../components/buttons/button";
import {getSessionActions, SessionActions, SessionActionsCallback} from "../../../../components/buttons/session-actions";
import {Icon, IconKey} from "../../../../components/icons";
import {Image} from "../../../../components/images/image";
import {ButtonInput} from "../../../../components/inputs/button";
import {ListElement, ListElementProps} from "../../../../components/list/items/list-element";
import {Map} from "../../../../components/maps/map";
import {HeaderMenu} from "../../../../components/menus/header";
import {FlatList} from "../../../../components/scrollables/flat-list";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {Call} from "../../../../components/sessions/call";
import {SessionFooter} from "../../../../components/sessions/footer";
import {SessionHeader} from "../../../../components/sessions/session-header";
import {TimerImmediate} from "../../../../components/sessions/timer-immediate";
import {StatusBar} from "../../../../components/status-bar";
import {Drawer} from "../../../../components/views/drawer";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {getReceiverEditableFields, getReceiverListedFields, getSessionDetails} from "../../../../requests/common/activities/details";
import {ArrayElementType, filterTruthy} from "../../../../utils/arrays";
import {formatBillingAddress} from "../../../../utils/billing-addresses";
import {DEFAULT_SPACING, IS_CLIENT, IS_INTERPRETER, SMALL_SPACING} from "../../../../utils/constants";
import {formatDateTime, formatDuration, formatShortDateTime, formatTime} from "../../../../utils/date-time/format";
import {computeEndTime} from "../../../../utils/date-time/helpers";
import {useSessionStatusChangedWS} from "../../../../utils/hooks/use-session-status-changed";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {corporationIdentity, getDescriptionLabel, personIdentity, receiverIdentity} from "../../../../utils/identities";
import {FeedbackKey, TranslationFeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {linkToAddress, linkToCall, linkToMail} from "../../../../utils/network/links";
import {getKeys} from "../../../../utils/objects";
import {getProvidedServiceIcon} from "../../../../utils/sessions/services";
import {BACKGROUND_COLOR, CONTRAST, PRIMARY_2, SUBTLE} from "../../../../utils/styles/colors";
import {ps, s} from "../../../../utils/switch";
import {createInterpreterPreviewInfo} from "../../../screens/client/modals/interpreter";

const DRAWER_BUTTONS_HEIGHT = 72; // This should be the height of the button + bottom margin

const availableInfosOrder = [ // List of infos in order
	"providers",
	"subject",
	"providedService",
	"duration",
	"price",
	"requester",
	"receiver",
	"medicalProfessional",
	"otherProfessional",
	"toccoPeopleNumber",
	"interpreters",
	"creationDate",
	"confirmedFeedback",
	"shareFeedbacks",
	"paymentAuthority",
	"category",
	"billingAddress",
	"toccoBillingInformation",
	"mediation",
	"providerComment",
	"internalComment",
	"validationPicture",
] as const;

const personIdentitySubElementOrder: (keyof PersonIdentityPreview)[] = [
	"email",
	"phone",
	"origin",
];
const interpreterSubElementOrder: (keyof InterpreterPreview)[] = [
	"phone", "email",
];
const medicalProfessionalSubElementOrder: (keyof MedicalProfessionalPreview)[] = [
	"directPhone",
	"function",
	"institution",
];
const otherProfessionalSubElementOrder: (keyof OtherProfessionalPreview)[] = [
	"directPhone",
	"function",
];
const receiverSubElementOrder: (keyof ReceiverPreview)[] = [
	"evamNumber",
	"ippNumber",
	"insurance",
	"ageGroup",
	"description",
];

const keyExtractor = (item: ListElementProps, index: number): string => `${String(item.label)}-${index}`;
const onPressNavigate = (s: InPerson) => () => linkToAddress(s.place.address);
const getEmailFeedbackSubElement = (emailFeedback: Partial<EmailFeedback>): ListElementProps["subElements"] => {
	const subElements: ListElementProps["subElements"] = [];
	getKeys(emailFeedback).forEach((key) => {
		switch (key) {
			case "receiverEmail": {
				subElements.push({
					buttons: [{icon: "letter", key: "linkToMail", onPress: () => linkToMail(emailFeedback[key])}],
					children: emailFeedback[key],
					icon: "letter",
				});
				break;
			}
			case "ccEmail": {
				subElements.push({
					buttons: [{icon: "letter", key: "linkToMail", onPress: () => linkToMail(emailFeedback[key])}],
					children: emailFeedback[key],
					icon: "letter",
				});
				break;
			}
			// No default
		}
	});
	return subElements;
};
export const Session = ({
	route,
	navigation,
	inModal,
}: SharedScreenProps<"SessionModal"> & {inModal?: boolean}): JSX.Element | null => {
	const {
		sessionId,
		onSessionCreationSuccess,
		onSessionCreationFailed,
		actionCallback: actionCallbackParam,
	} = route.params;
	const {ct, t} = useTranslation();
	const {session: toCreate, slot} = React.useContext(SessionCreationContext);
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			detailsStep: {paymentAuthority, sessionCategory, medicalProfessional, otherProfessional, receiver},
			termsConditionsURL,
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {value: {detailsStep: {}}};
	const [fetchedSession, setSession] = React.useState<SessionT>();
	const {value: receiverListedFieldsSetting} = getSetting<PersonListedFieldsSetting<Receiver>>(
		"person/listed-fields", "requester/receiver") ?? {value: undefined};
	const receiverSettingRequesterApp = getSetting<IdentityEditableFieldsSetting<Receiver>>(
		"identity/editable-fields", "requester/receiver");
	const [receiverListedFields, setReceiverListedFields] =
		React.useState<PersonListedFields<Receiver> | undefined>(receiverListedFieldsSetting);
	const [receiverEditableFields, setReceiverEditableFields] =
		React.useState<IdentityEditableFields<Receiver> | undefined>(receiverSettingRequesterApp?.value);
	const {value: interpreterListedFields} = getSetting<PersonListedFieldsSetting<WaveInterpreterProvider>>(
		"person/listed-fields", "requester/interpreter") ?? {value: undefined};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};
	const creationMode = !!onSessionCreationSuccess;
	const session = creationMode ? {...(toCreate as SessionT), ...slot} : fetchedSession!;
	const [loadingSessionData, setLoadingSessionData] = React.useState(!creationMode);
	const [loadingReceiverSettings, setLoadingReceiverSettings] = React.useState(IS_INTERPRETER);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey | null>(null);
	const {columns} = React.useContext(ResponsiveContext);
	const flatListRef = React.useRef<FlatList<ListElementProps>>(null);
	const keyboardOpened = React.useRef(new Animated.Value(0));
	const keyboardWillShowListenerRef = React.useRef<EmitterSubscription>();
	const keyboardWillHideListenerRef = React.useRef<EmitterSubscription>();
	const onRefresh = (): void => {
		setErrorMessage(null);
		getSessionDetails(sessionId)
			.then(setSession)
			.catch((error_: Error) => {
				ps(error_.message, {
					default: () => {
						Log.error()(error_);
						setErrorMessage("feedbacks:getSessionDetailsFailed");
					},
					sessionAlreadyAttributed: () => setErrorMessage("feedbacks:sessionAlreadyAttributed"),
					unavailableTimeslot: () => setErrorMessage("feedbacks:unavailableTimeslot"),
				});
			});
	};
	const sessionActions = creationMode ? [] : getSessionActions("focus", session);
	const renderItem = ({item, index}: ListRenderItemInfo<ListElementProps>): JSX.Element => (
		<>
			{index === 1 && (
				IS_CLIENT && !creationMode && session.immediate && session.status === "sent"
					? <TimerImmediate session={session}/>
					: session.communication.type === "inPerson"
						? <Map place={session.communication.place}/>
						: !creationMode && session.status !== "refused" && session.status !== "canceled" && (
							<Call
								start={creationMode && session.immediate ? new Date() : session.start}
								end={creationMode && session.immediate ? computeEndTime(new Date(), session.duration) : session.end}
								communication={session.communication}
								sessionStatus={session.status}
								sessionId={session.activityId}
							/>
						)
			)}
			<ListElement {...item} {...index === 0 && {separators: null, style: {backgroundColor}}} />
		</>
	);
	const createInfos = (): ListElementProps[] => {
		let infos: (Falsy | ListElementProps)[] = [];
		if (session.type === "interpreterMandate") {
			if (IS_CLIENT && session.providers.length === 1) {
				infos.push({
					buttons: [
						{icon: "calendar", key: "interpreterSessions", onPress: () => onPressCalendar(session)},
						!!session.providers[0].phone && {
							icon: "phone",
							key: "phone",
							onPress: () => linkToCall(session.providers[0].phone),
						},
						!!session.providers[0].email && {
							icon: "mail",
							key: "email",
							onPress: () => linkToMail(session.providers[0].email),
						},
					],
					children: personIdentity(session.providers[0]),
					image: session.providers[0].picture,
					label: ct("common:interpreter"),
					onPress: () => onPressInterpreter(session),
				});
			}
			if (IS_INTERPRETER && session.mandator) {
				infos.push({
					buttons: [
						!!session.mandator.phone &&
						{icon: "phone", key: "phone", onPress: () => linkToCall(session.mandator.phone)},
						!!session.mandator.email && {icon: "mail", key: "email", onPress: () => linkToMail(session.mandator.email)},
					],
					children: corporationIdentity(session.mandator),
					image: session.mandator.picture,
					label: ct("common:mandator"),
				});
			}
		}

		// Session types specifics
		if (session.communication.type === "inPerson") {
			const {place, placeInfos} = session.communication;
			if (place) {
				infos.push({
					buttons: [
						{
							icon: "copy",
							key: "copy",
							onPress: () => {
								Clipboard.setStringAsync(place.address).catch(Log.error());
								Log.success("copyAddressSuccess")();
							},
						},
						{
							icon: "navigate",
							key: "navigate",
							onPress: onPressNavigate(session.communication),
						},
					],
					children: place.address,
					icon: "location",
					label: ct("activities:sessions.address"),
				});
			}
			if (placeInfos) {
				infos.push({
					children: placeInfos,
					icon: "map",
					label: t("activities:sessions.addressInfos"),
				});
			}
		}
		// General infos
		if (session.type === "interpreterMandate") {
			availableInfosOrder.forEach((infoKey) => {
				// Only add infos that exists in the session object or that are separate from the session
				if (infoKey === "interpreters" || infoKey === "duration" || infoKey === "mediation" || session[infoKey]) {
					if (infoKey === "shareFeedbacks") {
						const shareInfos = session[infoKey]?.map(info => createShareFeedback(info));
						if (shareInfos) {
							infos = [...infos, ...shareInfos];
						}
					} else if (infoKey === "mediation" && session.providedService.type === "mediation") {
						const mediationInfos = createMediationInfos(session.providedService);
						infos = [...infos, ...mediationInfos];
					} else {
						const info = createInterpreterMandateInfo(session, infoKey);
						if (info) {
							infos.push(info);
						}
					}
				}
			});
		}
		return filterTruthy(infos);
	};
	const createShareFeedback = (info: ArrayElementType<InterpreterMandate["shareFeedbacks"]>): ListElementProps => ({
		children: formatShortDateTime(info.time),
		icon: "share",
		label: ct("activities:sessions.shareInfo"),
		subElements: getEmailFeedbackSubElement(info),
	});

	const createMediationInfos = (mediation: Mediation): ListElementProps[] => {
		const {involvedPeople, involvedCorporations, reason, goal, expectation} = mediation;
		return [
			involvedPeople.length > 0 && {
				children: involvedPeople.map((person) => personIdentity(person)).join(", "),
				icon: "people",
				label: ct("activities:sessions.mediation.involvedPeople"),
				subElements: involvedPeople.map((person) => ({
					children: `${personIdentity(person)}\n${person.description ?? ""}`,
					icon: "person",
					light: true,
				})),
			},
			involvedCorporations.length > 0 && {
				children: involvedCorporations.map((corporation) => corporationIdentity(corporation)).join(", "),
				icon: "corporation",
				label: ct("activities:sessions.mediation.involvedCorporations"),
				subElements: involvedCorporations.map((corporation) => ({
					children: `${corporationIdentity(corporation)}\n${corporation.description ?? ""}`,
					icon: "corporation",
					light: true,
				})),
			},
			{
				children: reason,
				icon: "comment",
				label: t("activities:sessions.mediation.reason"),
			},
			{
				children: goal,
				icon: "comment",
				label: t("activities:sessions.mediation.goal"),
			},
			{
				children: expectation,
				icon: "comment",
				label: t("activities:sessions.mediation.expectation"),
			},
		].filter(Boolean) as ListElementProps[];
	};
	const createInterpreterMandateInfo = (
		interpreterMandate: InterpreterMandate,
		elementKey: typeof availableInfosOrder[number],
	): Falsy | ListElementProps => {
		switch (elementKey) {
			case "providers": {
				return creationMode
					? {
						children: session.providers.map(r => personIdentity(r)).join(", "),
						icon: "people",
						label: ct("screens:creation.toXInterpreters", {n: session.providers.length}),
					}
					: null;
			}
			case "subject": {
				return {
					children: interpreterMandate[elementKey],
					icon: "id",
					label: ct("common:subject"),
				};
			}
			case "providedService": {
				return interpreterMandate[elementKey].type !== "standard" && {
					children: t(`activities:sessions.providedService.${interpreterMandate[elementKey].type}`),
					icon: getProvidedServiceIcon(interpreterMandate[elementKey].type),
					label: ct("common:service"),
				};
			}
			case "creationDate": {
				return IS_CLIENT && interpreterMandate.creationDate && {
					children: formatDateTime(interpreterMandate.creationDate),
					icon: "timer",
					label: ct("common:creationDate"),
				};
			}
			case "duration": {
				return interpreterMandate.start && interpreterMandate.end && {
					children: `${formatDuration(interpreterMandate.start, interpreterMandate.end)} ${interpreterMandate.status ===
					"completed"
						? `(${t("common:booked")}: ${formatTime(interpreterMandate.expectedStart)} - ${formatTime(
							interpreterMandate.expectedEnd)})`
						: ""}`,
					icon: "timer",
					label: interpreterMandate.status === "completed"
						? t("activities:sessions.validate.validatedDuration")
						: ct(
							"common:duration"),
				};
			}
			case "price": {
				const {base, reduced, increased, travel = {}, currency} = interpreterMandate[elementKey]!;
				const fees = base + increased + reduced;
				const {time = 0, distance = 0, flatRate = 0} = travel;
				const travelSum = distance + time + flatRate;
				return {
					children: `≈ ${(fees + travelSum).toFixed(2)} ${currency}`,
					icon: "money",
					label: ct("common:total_plural"),
					subElements: [
						base !== 0 && {
							children: `${t("activities:price.base")}: + ${base.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						increased !== 0 && {
							children: `${t("activities:price.increased")}: + ${increased.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						reduced !== 0 && {
							children: `${t("activities:price.reduced")}: + ${reduced.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						{
							children: `${t("screens:salary.fees")}: ≈ ${fees.toFixed(2)} ${currency}`,
							icon: "money",
						},
						!!travel.flatRate && travel.flatRate !== 0 && {
							children: `${t("activities:price.abbrev.travel.flatRate")}: + ${travel.flatRate.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						!!travel.time && travel.time !== 0 && {
							children: `${t("activities:price.abbrev.travel.time")}: + ${travel.time.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						!!travel.distance && travel.distance !== 0 && {
							children: `${t("activities:price.abbrev.travel.distance")}: + ${travel.distance.toFixed(2)} ${currency}`,
							icon: "money",
							light: true,
						},
						travelSum > 0 && {
							children: `${t("activities:price.abbrev.travel.total")}: ≈ ${travelSum.toFixed(2)} ${currency}`,
							icon: "money",
						},
					],
				};
			}
			case "requester": {
				return {
					children: personIdentity(interpreterMandate[elementKey]),
					icon: "personOutline",
					label: ct("common:requester"),
					subElements: getIdentitySubElements(interpreterMandate[elementKey]),
				};
			}
			case "receiver": {
				const element = interpreterMandate[elementKey];
				return {
					children: receiverIdentity(element, receiverListedFields, getDescriptionLabel(receiverEditableFields)),
					icon: "receiver",
					label: receiver?.label ?? ct("common:receiver"),
					subElements: element && getIdentitySubElements<Receiver>(element, receiverListedFields),
				};
			}
			case "interpreters": {
				const people = [...session.providers, ...session.excludedInterpreters.map(el => ({...el, status: "excluded"}))];
				return IS_CLIENT &&
					(session.status === "sent" || session.status === "refused" || session.status === "confirmed") && {
					children: session.providers.map((provider) => personIdentity(provider)).join(", "),
					icon: people.length === 1 ? "person" : "people",
					label: people.length === 1 ? ct("common:interpreter") : ct("common:interpreter_plural"),
					subElements: session.status === "sent" || (session.status === "refused" && people.length > 1)
						? people.map((provider) => ({
							children: personIdentity(provider),
							icon: provider.status
								? s(provider.status, {
									accepted: "check",
									excluded: "excluded",
									outdated: "help",
									pending: "help",
									refused: "close",
								})
								: undefined,
							light: true,
						}))
						: getIdentitySubElements<WaveInterpreterProvider>(session.providers[0], interpreterListedFields),
				};
			}
			case "confirmedFeedback": {
				const element = interpreterMandate[elementKey];
				const time = element?.time;
				return {
					children: time && formatShortDateTime(time),
					icon: "info",
					label: ct("activities:sessions.acceptanceTime"),
					subElements: element && getEmailFeedbackSubElement(element),
				};
			}
			case "medicalProfessional": {
				const element = interpreterMandate[elementKey];
				return {
					children: personIdentity(element),
					icon: "medical",
					label: medicalProfessional?.label ?? ct("common:medicalProfessional"),
					subElements: element && getIdentitySubElements(element),
				};
			}
			case "otherProfessional": {
				const element = interpreterMandate[elementKey];
				return {
					children: personIdentity(element),
					icon: "person",
					label: otherProfessional?.label ?? ct("common:otherProfessional"),
					subElements: element && getIdentitySubElements(element),
				};
			}
			case "toccoPeopleNumber": {
				return {
					children: String(interpreterMandate[elementKey]),
					icon: "people",
					label: t("activities:sessions.toccoPeopleNumber"),
				};
			}
			case "paymentAuthority": {
				return {
					children: interpreterMandate[elementKey]?.name,
					icon: "payment",
					label: paymentAuthority?.label ?? ct("common:paymentAuthority"),
				};
			}
			case "category": {
				return {
					children: interpreterMandate[elementKey]?.name,
					icon: "category",
					label: sessionCategory?.label ?? ct("common:sessionCategory"),
				};
			}
			case "billingAddress": {
				const element = interpreterMandate[elementKey];
				return (IS_INTERPRETER || !element)
					? null
					: {
						children: formatBillingAddress(element),
						icon: "address",
						label: ct("common:billingAddress"),
					};
			}
			case "toccoBillingInformation": {
				const element = interpreterMandate[elementKey];
				return (IS_INTERPRETER || !element)
					? null
					: {
						children: element,
						icon: "comment",
						label: t("activities:sessions.toccoBillingInformation.label"),
					};
			}
			case "providerComment": {
				return {
					children: interpreterMandate[elementKey],
					icon: "commentMore",
					label: ct("activities:sessions.interpreterComment"),
				};
			}
			case "internalComment": {
				return IS_INTERPRETER
					? null
					: {
						children: interpreterMandate[elementKey],
						icon: "commentMoreOutline",
						label: ct("activities:sessions.internalComment"),
					};
			}
			case "validationPicture": {
				return session.validationPicture && {
					children: <Image
						source={session.validationPicture.source}
						fullHeight={session.validationPicture.fullHeight}
						mime={session.validationPicture.mime}
						sourceType={session.validationPicture.sourceType}
						width={session.validationPicture.width}
						height={session.validationPicture.height}
						resizeMode="contain"
						style={styles.validationPicture}
						fullWidth
					/>,
					icon: "picture",
					label: ct("activities:sessions.validation-card-picture"),
				};
			}
			default: {
				return null;
			}
		}
	};
	const getIdentitySubElements = <I extends PersonIdentity | PersonIdentityPreview>(
		person: I,
		listedFields?: PersonListedFields<I>,
	): ListElementProps["subElements"] => {
		const subElements: ListElementProps["subElements"] = [];
		personIdentitySubElementOrder.forEach(key => {
			if (person[key] && !listedFields?.has(key as PersonListedField<I>)) {
				switch (key) {
					case "email": {
						subElements.push({
							buttons: [{icon: "letter", key: "linkToMail", onPress: () => linkToMail(person[key])}],
							children: person[key],
							icon: "letter",
						});
						break;
					}
					case "phone": {
						subElements.push({
							buttons: [{icon: "phone", key: "linkToCall", onPress: () => linkToCall(person[key])}],
							children: person[key],
							icon: "phonePad",
						});
						break;
					}
					case "origin": {
						const origin = person[key]!;
						if (person.type !== "interpreter") {
							subElements.push({
								children: t(`origins:${origin}`),
								icon: "world",
							});
						}
						break;
					}
					// No default
				}
			}
		});
		switch (person.type) {
			case "interpreter": {
				interpreterSubElementOrder.forEach((key) => {
					// Only add infos that exists in the session object
					if (person?.[key]) {
						subElements.push(createInterpreterPreviewInfo(person, key, usingTocco, ct, t));
					}
				});
				break;
			}
			case "receiver": {
				receiverSubElementOrder.forEach(key => {
					if (person[key] && !listedFields?.has(key as PersonListedField<I>)) {
						switch (key) {
							case "evamNumber": {
								subElements.push({
									children: person[key]?.value,
									icon: "person",
								});
								break;
							}
							case "ippNumber": {
								subElements.push({
									children: person[key]?.value,
									icon: "person",
								});
								break;
							}
							case "insurance": {
								subElements.push({
									children: person[key]?.name,
									icon: "corporation",
								});
								break;
							}
							case "ageGroup": {
								subElements.push({
									children: person[key],
									icon: "ageGroup",
								});
								break;
							}
							case "description": {
								subElements.push({
									children: person[key],
									icon: "comment",
								});
								break;
							}
							// No default
						}
					}
				});

				break;
			}
			case "medicalProfessional": {
				medicalProfessionalSubElementOrder.forEach(key => {
					if (person[key]) {
						switch (key) {
							case "institution": {
								subElements.push({
									children: person[key],
									icon: "institution",
								});
								break;
							}
							case "function": {
								subElements.push({
									children: person[key],
									icon: "medical",
								});
								break;
							}
							case "directPhone": {
								subElements.push({
									buttons: [{icon: "phone", key: "linkToCall", onPress: () => linkToCall(person[key])}],
									children: person[key],
									icon: "directPhone",
								});
								break;
							}
							// No default
						}
					}
				});

				break;
			}
			case "otherProfessional": {
				otherProfessionalSubElementOrder.forEach(key => {
					if (person[key]) {
						switch (key) {
							case "function": {
								subElements.push({
									children: person[key],
									icon: "medical",
								});
								break;
							}
							case "directPhone": {
								subElements.push({
									buttons: [{icon: "phone", key: "linkToCall", onPress: () => linkToCall(person[key])}],
									children: person[key],
									icon: "directPhone",
								});
								break;
							}
							// No default
						}
					}
				});

				break;
			}
			// No default
		}
		return subElements;
	};

	const scrollToEnd = (): void => flatListRef.current?.scrollToEnd();
	const onPressInterpreter = (s: InterpreterMandate): void =>
		navigation.push(
			"InterpreterModal",
			{interpreterId: s.providers[0].identityId},
		);
	const onPressCalendar = (s: InterpreterMandate): void =>
		navigation.push(
			"CalendarModal",
			{
				filters: JSON.stringify(IS_INTERPRETER ? {mandator: s.mandator} : {provider: s.providers[0]}),
				title: IS_CLIENT ? personIdentity(s.providers[0]) : corporationIdentity(s.mandator),
			},
		);
	const renderAction = (
		key: string,
		icon: IconKey,
		label: string,
		onPress: ButtonProps["onPress"],
		buttonStyle?: {
			backgroundColor?: string;
			contentActiveColor?: string;
			contentColor?: string;
		},
		disabled?: boolean,
	): JSX.Element => (
		<View key={key} style={styles.buttonCenter}>
			<Button
				size="small"
				labelProps={{color: PRIMARY_2, type: "emphasis_3"}}
				disabled={disabled}
				allowFontScaling={false}
				{...{icon, label, onPress, ...buttonStyle}}
			/>
		</View>
	);
	const actionCallback = actionCallbackParam ?? ((action: SessionActionsCallback) => {
		setErrorMessage(null);
		return getSessionDetails(sessionId)
			.then(setSession)
			.catch((error_) => {
				let feedback: FeedbackKey = "getSessionDetailsFailed";
				switch (action) {
					case "acceptSessionSuccess": {
						feedback = "acceptSessionFailed";
						break;
					}
					case "refuseSessionSuccess": {
						feedback = "refuseSessionFailed";
						break;
					}
					case "cancelSessionSuccess": {
						feedback = "cancelSessionSuccess";
						break;
					}
				}
				// the updateSessionValidationSuccess callback use the default "getSessionDetailsFailed" LogKey

				Log.error()(error_);
				setErrorMessage(`feedbacks:${feedback}`);
			})
			.finally(() => setLoadingSessionData(false));
	});

	React.useEffect(
		() => {
			if (IS_INTERPRETER) {
				Promise.all([
					getReceiverListedFields(sessionId)
						.then(setReceiverListedFields),
					getReceiverEditableFields(sessionId)
						.then(setReceiverEditableFields),
				])
					.catch((error_) => {
						Log.error()(error_);
						setErrorMessage("feedbacks:getDataFailed");
					})
					.finally(() => setLoadingReceiverSettings(false));
			}
		},
		[sessionId],
	);

	React.useEffect(
		() => {
			if (creationMode) {
				return () => null;
			}
			setErrorMessage(null);
			getSessionDetails(sessionId)
				.then(setSession)
				.catch((error_: Error) => {
					ps(error_.message, {
						default: () => {
							Log.error()(error_);
							setErrorMessage("feedbacks:getSessionDetailsFailed");
						},
						sessionAlreadyAttributed: () => setErrorMessage("feedbacks:sessionAlreadyAttributed"),
						unavailableTimeslot: () => setErrorMessage("feedbacks:unavailableTimeslot"),
					});
				})
				.finally(() => setLoadingSessionData(false));
			keyboardWillShowListenerRef.current = Keyboard.addListener(
				"keyboardDidShow",
				() => Animated.timing(
					keyboardOpened.current,
					{duration: 160, toValue: 1, useNativeDriver: false},
				).start(() => scrollToEnd()),
			);
			keyboardWillHideListenerRef.current = Keyboard.addListener(
				"keyboardDidHide",
				() => Animated.timing(
					keyboardOpened.current,
					{duration: 160, toValue: 0, useNativeDriver: false},
				).start(() => scrollToEnd()),
			);
			if (route.params.scrollToEnd) {
				setTimeout(scrollToEnd, 600);
			}

			return () => {
				keyboardWillShowListenerRef.current?.remove();
				keyboardWillHideListenerRef.current?.remove();
			};
		},
		[route.params.scrollToEnd, sessionId, creationMode],
	);

	useSessionStatusChangedWS({
		onData: (sessionWS) => {
			if (session.activityId === sessionWS.activityId) {
				setSession(prev => ({...prev!, providers: sessionWS.providers, status: sessionWS.status}));
			}
		},
	});

	const backgroundColor = session?.immediate ? CONTRAST : SUBTLE;
	const header = (
		<>
			{!inModal && columns === 1 && <StatusBar {...{backgroundColor}} />}
			<HeaderMenu
				{...{backgroundColor}}
				center={`${ct("common:session")}${creationMode ? "" : ` #${sessionId}`}`}
				right={{beforeHelp: !creationMode && <Icon icon="refresh" onPress={onRefresh}/>}}
			/>
		</>
	);

	if (loadingSessionData || loadingReceiverSettings || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingSessionData || loadingReceiverSettings}
				message={errorMessage ? {translationKey: errorMessage, type: "error"} : undefined}
			/>
		);
	}
	/*
	 * When session created, session is reset in context -> this prevents crash due missing required fields if the render
	 * happens before we close this screen
	 */
	if (!session?.communication?.type) {
		return null;
	}

	const data = createInfos();

	return (
		<>
			{header}
			<FlatList
				keyExtractor={keyExtractor}
				ref={flatListRef}
				ItemSeparatorComponent={null}
				// Works because interpreter is last in the data array + ListHeaderComponent being index 0
				stickyHeaderIndices={[1]}
				ListHeaderComponent={<SessionHeader session={session}/>}
				data={data}
				renderItem={renderItem}
				ListFooterComponent={
					<SessionFooter
						session={session}
						onSessionCreationSuccess={onSessionCreationSuccess}
						onSessionCreationFailed={onSessionCreationFailed}
						termsAndConditionsURL={usingTocco ? "https://www.arge.ch/agb" : termsConditionsURL}
					/>
				}
			/>
			{sessionActions.length > 0 && (
				<Drawer verticalPosition="bottom">
					<ScrollView
						style={styles.drawerContainer}
						bounces={false}
						overScrollMode="never"
						keyboardDismissMode="none"
					>
						<>
							<Animated.View
								style={{
									backgroundColor: BACKGROUND_COLOR,
									height: keyboardOpened.current.interpolate({
										inputRange: [0, 1],
										outputRange: [DEFAULT_SPACING, SMALL_SPACING],
									}),
								}}
							/>
							<Animated.View
								style={[
									styles.row, styles.drawerButtons,
									{
										height: keyboardOpened.current.interpolate({
											inputRange: [0, 1],
											outputRange: [DRAWER_BUTTONS_HEIGHT, 0],
										}),
										opacity: keyboardOpened.current.interpolate({
											inputRange: [0, 1],
											outputRange: [100, 0],
										}),
									},
								]}
							>
								<SessionActions
									mode="focus"
									session={session}
									renderAction={renderAction}
									scrollToEnd={scrollToEnd}
									actions={sessionActions}
									callbacks={actionCallback}
								/>
							</Animated.View>
							{session.type === "interpreterMandate" && sessionActions.includes("message") && (
								<>
									<ButtonInput
										leftIcon="edit"
										rightIcon={{icon: "send"}}
										placeholder={t("forms:inputs.typeTo", {name: personIdentity(session.providers[0])})}
										multiline
									/>
									<Animated.View
										style={{
											backgroundColor: BACKGROUND_COLOR,
											height: keyboardOpened.current.interpolate({
												inputRange: [0, 1],
												outputRange: [DEFAULT_SPACING, SMALL_SPACING],
											}),
										}}
									/>
								</>
							)}
						</>
					</ScrollView>
				</Drawer>
			)}
		</>
	);
};

export const SessionModal = (props: SharedScreenProps<"SessionModal">): JSX.Element => (
	<ModalWrapper>
		<Session inModal {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	buttonCenter: {
		alignItems: "center",
		flex: 1,
	},
	drawerButtons: {
		alignContent: "center",
		justifyContent: "space-evenly",
		width: "100%",
	},
	drawerContainer: {
		paddingHorizontal: DEFAULT_SPACING,
	},
	row: {
		flexDirection: "row",
	},
	validationPicture: {
		borderRadius: SMALL_SPACING,
		marginTop: 6,
	},
});
