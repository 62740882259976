import * as React from "react";
import {LocaleContext} from "../../../../contexts/locale";
import {changePasswordClient} from "../../../../requests/clients/account/change-password";
import {changePasswordInterpreter} from "../../../../requests/interpreters/account/change-password";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {AuthScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {AuthTemplate} from "./auth-template";

export const ChangePassword = ({navigation, route}: AuthScreenProps<"ChangePassword">): JSX.Element => {
	const {ct, t} = useTranslation();
	const [password, setPassword] = React.useState("");
	const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
	const {locale} = React.useContext(LocaleContext);

	return (
		<AuthTemplate
			label={t("screens:changePassword.label")}
			title={ct("screens:changePassword.newPassword")}
			inputs={[
				{
					icon: "key",
					label: ct("screens:changePassword.newPassword"),
					rules: [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "password",
						onChangeValue: setPassword,
						value: password,
					},
				}, {
					icon: "key",
					label: ct("screens:changePassword.newPasswordConfirmation"),
					rules: [
						{rule: Rules.notEmpty, type: "error"},
						{rule: Rules.equalTo(password, ct("screens:changePassword.newPassword")), type: "error"},
					],
					type: {
						key: "password",
						onChangeValue: setPasswordConfirmation,
						value: passwordConfirmation,
					},
				},
			]}
			validation={{
				onValidation: () => {
					(IS_INTERPRETER ? changePasswordInterpreter : changePasswordClient)(
						password, route.params?.token, locale,
					)
						.then(() => {
							Log.success("changePasswordSuccess")();
							navigation.navigate("SignIn");
						})
						.catch((error: Error) => Log.error("serverDownError")(error));
				},
				text: ct("screens:changePassword.action"),
			}}
		/>
	);
};
