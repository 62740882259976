import {Education, InterpreterMandate, Mediation, Standard} from "../../@types/activities/mandate";
import {Communication, EmailFeedback, SessionAction} from "../../@types/activities/session";
import {Corporation} from "../../@types/identities/corporation";
import {PersonIdentityPreview} from "../../@types/identities/person";
import {ImageMime, URLImage} from "../../@types/medias/image";
import {IS_INTERPRETER} from "../../utils/constants";
import {t} from "../../utils/locales/translations";
import {TransformCommunicationRequest} from "../@types/communication";
import {DetailedSession} from "../@types/session";
import {
	generateCompletedSessionStartDate, generateSessionDate, transformBackendInterpreterToProviders, transformLanguageISOCodeBackToFront,
	transformRescheduleProvidersType, transformSessionContextBackToFront, transformSessionStatus,
	transformSessionTypeBackToFront,
	transformToCommunication,
} from "./transform";
import {transformBillingAddressBackToFront} from "./transform-billing-addresses";
import {
	transformBackendUserToCorporation, transformMedicalProfessionalBackToFront, transformOtherProfessionalBackToFront,
	transformOwnerToRequester,
} from "./transform-person";
import {transformPatientToReceiverPreview} from "./transform-receiver";

export const transformCreateSessionBackToFront = (sessionDetail: DetailedSession): InterpreterMandate => {
	const {
		interpreter: interpreters, session, isFollowUpNotAllowed, isUserFollowUpNotAllowed, sessionCancelledDescription, cancelable,
		acceptanceInfo, shareFeedbacks, channelId, token, secret, patient,
	} = sessionDetail;
	const {
		id: sessionId, sessionType, startTime, endTime, status, fromLanguage, toLanguage,
		formattedAddress: inPersonLocation, locationDetails: inPersonAdditionalLocation,
		user, otherUser, owner, userAccount: {
			id: userAccountId, userAccountManager: {
				firstName: userFirstName, lastName: userLastName, role, email: userEmail,
				telephone, countryCode, orgName,
			},
		}, sessionCategory, title, purpose,
		sessionStartTime, sessionEndTime, bookingDate, locked, multipleNotifyInterpreters,
		price, internalComments, bookingType, createdDate,
		latitude, longitude, expectationOfMediation, reasonForConversation, goalOfConversation, sessionImageUrl,
		sessionImageHeight, sessionImageWidth, paymentAuthorityOptionsData, categoryOptionsData, excludedInterpreters,
		personInvolvedInConversation, otherInvolvedPositions, logo,
		toccoBillingInformation, toccoNbPersons, userBillingAddress,
	} = session;

	const address = (sessionType === "INPERSON" || sessionType === "INTERCULTURALMEDIATION" ||
        sessionType === "COURSEMANAGEMENT")
		? inPersonLocation
		: null;
	const addressInfos = (sessionType === "INPERSON" || sessionType === "INTERCULTURALMEDIATION" ||
        sessionType === "COURSEMANAGEMENT")
		? inPersonAdditionalLocation
		: null;
	const frontendSessionType = transformSessionTypeBackToFront(sessionType);
	const frontendSessionStatus = transformSessionStatus(status);

	const medicalProfessional: InterpreterMandate["medicalProfessional"] = user
		? transformMedicalProfessionalBackToFront(user)
		: null;
	const otherProfessional: InterpreterMandate["otherProfessional"] = otherUser
		? transformOtherProfessionalBackToFront(otherUser)
		: null;
	const userAccountData: InterpreterMandate["mandator"] = transformBackendUserToCorporation({
		firstName: userFirstName,
		lastName: userLastName,
		email: userEmail,
		logo,
		orgName,
		countryCode,
		telephone,
		role,
		userAccountId,
		credentials: {
			userId: userAccountId,
		},
	});

	let sessionStart = new Date(startTime);
	let sessionEnd = new Date(endTime);
	if (startTime === 0 || endTime === 0) {
		sessionStart = generateSessionDate(bookingDate, "start");
		sessionEnd = generateSessionDate(bookingDate, "end");
	}
	const expectedStart = sessionStart;
	const expectedEnd = sessionEnd;
	if (sessionCategory !== "IMMEDIATE") {
		expectedStart.setSeconds(0, 0);
		expectedEnd.setSeconds(0, 0);
	}
	if (status === "COMPLETED") {
		sessionStart = generateCompletedSessionStartDate(startTime, sessionStartTime);
		sessionEnd = generateCompletedSessionStartDate(endTime, sessionEndTime);
	}
	if (sessionCategory !== "IMMEDIATE") {
		sessionStart.setSeconds(0, 0);
		sessionEnd.setSeconds(0, 0);
	}
	const disabledActions: SessionAction[] = [];
	if ((isFollowUpNotAllowed || isUserFollowUpNotAllowed) && IS_INTERPRETER) {
		disabledActions.push("followUp" as SessionAction);
	}
	if (locked) {
		disabledActions.push("validate" as SessionAction);
	}
	const providers = transformBackendInterpreterToProviders(interpreters);
	const transformCommunicationRequest: TransformCommunicationRequest = {
		type: frontendSessionType,
		address,
		lat: latitude,
		lng: longitude,
		channelId,
		token,
		secret,
		phoneNumber: IS_INTERPRETER ? owner?.telephone : providers?.[0].phone,
		addressInfos,
	};
	const communication: Communication = transformToCommunication(transformCommunicationRequest);
	let providedService: InterpreterMandate["providedService"];
	if (sessionType === "INTERCULTURALMEDIATION") {
		let involvedPeople: PersonIdentityPreview[];
		try {
			involvedPeople = JSON.parse(personInvolvedInConversation);
		} catch {
			involvedPeople = [];
		}
		let involvedCorporations: Corporation[];
		try {
			involvedCorporations = JSON.parse(otherInvolvedPositions);
		} catch {
			involvedCorporations = [];
		}
		providedService = {
			involvedPeople,
			involvedCorporations,
			type: "mediation",
			reason: reasonForConversation,
			goal: goalOfConversation,
			expectation: expectationOfMediation,
		} as Mediation;
	} else if (sessionType === "COURSEMANAGEMENT") {
		providedService = {
			type: "education",
		} as Education;
	} else {
		providedService = {
			type: "standard",
		} as Standard;
	}
	const context: InterpreterMandate["context"] = {
		type: transformSessionContextBackToFront(bookingType),
		rescheduleData: {
			sendOnlyToCurrentProvider: sessionCategory === "SCHEDULED" ? transformRescheduleProvidersType(status) : undefined,
		},
	};
	const details: InterpreterMandate = {
		billingAddress: userBillingAddress && transformBillingAddressBackToFront(userBillingAddress),
		locked,
		communication,
		type: "interpreterMandate",
		duration: sessionEnd.getTime() - sessionStart.getTime(),
		requester: transformOwnerToRequester(owner),
		disabledActions,
		subject: title,
		providedService,
		mandator: userAccountData,
		activityId: sessionId.toString(),
		start: sessionStart,
		end: sessionEnd,
		expectedStart,
		expectedEnd,
		status: frontendSessionStatus,
		language: transformLanguageISOCodeBackToFront(fromLanguage.isoCode),
		toLanguage: transformLanguageISOCodeBackToFront(toLanguage.isoCode),
		immediate: sessionCategory === "IMMEDIATE",
		onlyRecipient: !multipleNotifyInterpreters,
		cancelable: !cancelable,
		context,
		providers,
		excludedInterpreters: transformBackendInterpreterToProviders(excludedInterpreters) || [],
		toccoPeopleNumber: toccoNbPersons,
		toccoBillingInformation,
	};
	if (createdDate) {
		details.creationDate = new Date(createdDate);
	}
	if (internalComments) {
		details.internalComment = internalComments;
	}
	if (sessionImageUrl) {
		const validationPicture: URLImage = {
			sourceType: "url",
			source: sessionImageUrl,
			mime: "image/jpeg" as ImageMime,
			mediaType: "image",
			width: sessionImageWidth,
			height: sessionImageHeight,
		};
		details.validationPicture = validationPicture;
	}
	if (acceptanceInfo) {
		const confirmedFeedback: EmailFeedback = {
			ccEmail: acceptanceInfo.ccEmail,
			receiverEmail: acceptanceInfo.receiverEmail,
			time: new Date(acceptanceInfo.time),
		};
		details.confirmedFeedback = confirmedFeedback;
	}
	if (shareFeedbacks) {
		details.shareFeedbacks = shareFeedbacks.map(
			(feedback) => ({receiverEmail: feedback.receiverEmail, time: new Date(feedback.time)}));
	}
	if (paymentAuthorityOptionsData) {
		details.paymentAuthority = {
			id: paymentAuthorityOptionsData.id.toString(),
			name: paymentAuthorityOptionsData.paymentOption,
			address: paymentAuthorityOptionsData.address,
		};
	}
	if (categoryOptionsData) {
		details.category = {
			id: categoryOptionsData.id.toString(),
			name: categoryOptionsData.categoryOption,
		};
	}
	if (purpose) {
		details.providerComment = purpose;
	}
	if (medicalProfessional) {
		details.medicalProfessional = medicalProfessional;
	}
	if (otherProfessional) {
		details.otherProfessional = otherProfessional;
	}
	if (patient) {
		details.receiver = transformPatientToReceiverPreview(patient);
	}
	if (price && (status === "COMPLETED" || status === "LATE CANCELLATION" || status === "LATE RESCHEDULING")) {
		details.price = IS_INTERPRETER ? price.priceInterpreter : price.priceClient;
	}
	if (sessionCancelledDescription) {
		details.providerComment = `${details.providerComment ? `${details.providerComment}\n\n` : ""}${t("activities:sessions.cancellationReason")}:\n${sessionCancelledDescription}`;
	}
	return details;
};
