import {PickerImage} from "../utils/inputs/pickers/image-picker";
import {Partialize, SubUnion} from "../utils/types";
import {CommunicationType, InPerson, Phone, SessionStatus, Video} from "./activities/session";
import {PersonIdentityPreview} from "./identities/person";
import {ReceiverPreview} from "./identities/receiver";
import {FeedbackOption} from "./validation-settings";

export type ValidationTypesSettings = {[communicationType in CommunicationType]: SessionValidationType[]};
// for now, only "picture" and "none" types will be handled
export const sessionValidationTypes = ["picture", "email", "numeric", "none"] as const;
export type SessionValidationType = typeof sessionValidationTypes[number];

export const issueTypes = [
	"absentReceiver",
	"cancellationTime",
	"effectiveEndTime",
	"effectiveStartTime",
	"feedback",
	"incident",
	"other",
	"communicationTypeChanged",
] as const;
export type IssueType = typeof issueTypes[number];

export type ValidationStatus = SubUnion<SessionStatus, "canceled" | "completed">;

export interface IssueCommon {
	description: string;
	type: IssueType;
}

export interface FeedbackIssue extends Partialize<IssueCommon, "description"> {
	feedback: FeedbackOption;
	type: "feedback";
}

export interface EffectiveEndTimeIssue extends Partialize<IssueCommon, "description"> {
	effectiveEndTime: Date;
	// Used to validate that end time is correct
	effectiveStartTime?: Date;
	type: "effectiveEndTime";
}

export interface EffectiveStartTimeIssue extends Partialize<IssueCommon, "description"> {
	// Used to validate that start time is correct
	effectiveEndTime?: Date;
	effectiveStartTime: Date;
	type: "effectiveStartTime";
}

export interface CommunicationTypeChangedIssue extends Partialize<IssueCommon, "description"> {
	changed: {communication: InPerson | Phone | Video};
	type: "communicationTypeChanged";
}

export interface IncidentIssue extends IssueCommon {
	adminReview: boolean;
	intervision: boolean;
	person?: PersonIdentityPreview;
	supervision: boolean;
	type: "incident";
}

export interface AbsentReceiverIssue extends Partialize<IssueCommon, "description"> {
	person: ReceiverPreview;
	personName?: string;
	type: "absentReceiver";
}

export interface CancellationTimeIssue extends Partialize<IssueCommon, "description"> {
	cancellationTime: Date;
	type: "cancellationTime";
}

export interface OtherIssue extends IssueCommon {
	type: "other";
}

export type Issue =
	AbsentReceiverIssue
	| CancellationTimeIssue
	| CommunicationTypeChangedIssue
	| EffectiveEndTimeIssue
	| EffectiveStartTimeIssue
	| FeedbackIssue
	| IncidentIssue
	| OtherIssue;

export interface Issues {
	absentReceiver?: AbsentReceiverIssue | null;
	cancellationTime?: CancellationTimeIssue | null;
	communicationTypeChanged?: CommunicationTypeChangedIssue | null;
	effectiveEndTime?: EffectiveEndTimeIssue | null;
	effectiveStartTime?: EffectiveStartTimeIssue | null;
	feedback?: FeedbackIssue | null;
	incident?: IncidentIssue | null;
	other?: OtherIssue | null;
}

interface ValidationTypeCommon {
	type: SessionValidationType;
}

export interface PictureValidation extends ValidationTypeCommon {
	picture: PickerImage;
	type: "picture";
}

export interface NoneValidation extends ValidationTypeCommon {
	type: "none";
}

export interface EmailValidation extends ValidationTypeCommon {
	email: string;
	type: "email";
}

export interface NumericValidation extends ValidationTypeCommon {
	signature: undefined;
	type: "numeric"; // TODO
}

export type ValidationType =
	EmailValidation | NoneValidation | NumericValidation | PictureValidation;

interface Rating {
	comment?: string;
	value: 1 | 2 | 3 | 4 | 5;
}

interface SessionValidationCommon {
	issues: Issues;
	rating?: Rating;
	selectedValidation: ValidationType;
	sessionId: string;
	status: ValidationStatus;
}

export interface SessionValidationCompleted extends SessionValidationCommon {
	issues: Pick<Issues, "absentReceiver" | "communicationTypeChanged" | "effectiveEndTime" | "effectiveStartTime" | "incident" | "other">;
	status: "completed";
	toccoPeopleNumber?: number;
}

interface SessionValidationCanceled extends SessionValidationCommon {
	issues: Pick<Issues, "cancellationTime" | "other">;
	status: "canceled";
}

export type SessionValidation =
	SessionValidationCanceled | SessionValidationCompleted;
