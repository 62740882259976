import * as React from "react";
import {StyleSheet} from "react-native";
import {Pagination} from "../../../../@types/pagination";
import {SessionCreationSetting} from "../../../../@types/settings";
import {TextInputDropdownSuggestionItem} from "../../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {ListElement} from "../../../../components/list/items/list-element";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {OpenSans} from "../../../../components/texts/fonts";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {
	addSubjectSuggestion,
	deleteSubjectSuggestion,
	getSubjectSuggestions as getSubjectSuggestionsRequest,
	searchSubjectSuggestions as searchSubjectSuggestionsRequest,
} from "../../../../requests/clients/new-session/subject-suggestion";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {searchIndexOf} from "../../../../utils/searches";
import {SUBTLE_2} from "../../../../utils/styles/colors";
import {forceBack} from "../../../navigation";

export const SelectSubjectSuggestion = ({
	navigation,
	route,
}: ClientStackNavigatorScreenProps<"SelectSubjectSuggestionModal">): JSX.Element => {
	const {onPressSelect: onPressSelectProp, onSelect} = route.params ?? {};
	const {ct, t} = useTranslation();
	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);

	const {
		value: {detailsStep: {subject}},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {value: {detailsStep: {}}};

	const onPressSelect = React.useMemo(
		() => onPressSelectProp && ({
			...onPressSelectProp,
			onPress: (subject: TextInputDropdownSuggestionItem[] | string) => {
				onPressSelectProp.onPress(subject);
				if (onPressSelectProp.canSelectSearch) {
					navigation.dispatch(forceBack);
				}
			},
		}),
		[navigation, onPressSelectProp],
	);

	const getSubjectSuggestions = React.useCallback(
		(pagination: Pagination) => getSubjectSuggestionsRequest(pagination, accountId!),
		[accountId],
	);

	const searchSubjectSuggestions = React.useMemo(
		() => ({
			placeholder: onPressSelectProp && t("activities:sessions.subjectPlaceholder"),
			request: (pagination: Pagination, search: string) => searchSubjectSuggestionsRequest(
				pagination, search, accountId!),
		}),
		[accountId, onPressSelectProp, t],
	);

	const onPress = React.useCallback(
		(item: TextInputDropdownSuggestionItem) => () => {
			onSelect!(item);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelect],
	);

	const request = React.useCallback(
		(item: TextInputDropdownSuggestionItem) => (
			addSubjectSuggestion(accountId!, item)
				.then((added) => {
					if (onSelect) {
						onPress(added)();
					}
					return added;
				})
		),
		[accountId, onPress, onSelect],
	);

	const renderItem = React.useCallback(
		({
			info: {item},
			buttons,
			currentSearch,
			focusedStyle,
		}: FlatListRenderItemParams<"id", TextInputDropdownSuggestionItem>) => {
			const searchIndex = searchIndexOf(currentSearch, item.displayed, true);

			return (
				<ListElement
					key={item.id}
					onPress={onSelect && onPress(item)}
					buttons={buttons}
					style={[focusedStyle, currentSearch === item.displayed && {backgroundColor: SUBTLE_2}]}
				>
					{currentSearch?.length > 0 && searchIndex > -1
						? (
							<Text>
								<Text type="emphasis_1" style={styles.regular}>
									{item.displayed.slice(0, searchIndex)}
								</Text>
								<Text type="emphasis_1" style={styles.bold}>
									{item.displayed.slice(searchIndex, searchIndex + currentSearch.length)}
								</Text>
								<Text type="emphasis_1" style={styles.regular}>
									{item.displayed.slice(searchIndex + currentSearch.length)}
								</Text>
							</Text>
						)
						: (
							<Text type="emphasis_1">
								{item.displayed}
							</Text>
						)}
				</ListElement>
			);
		},
		[onPress, onSelect],
	);

	return (
		<>
			<HeaderMenu
				center={ct("forms:items.select", {item: ct("common:subject")})}
			/>
			<SelectFlatList
				getRequest={getSubjectSuggestions}
				onPressAdd={subject?.editRights?.canAddNew
					? {
						request,
						screen: {
							name: "EditSubjectSuggestionModal",
						},
					}
					: undefined
				}
				deleteRequest={!onPressSelect && subject?.editRights?.canDelete
					? (removed: TextInputDropdownSuggestionItem) => deleteSubjectSuggestion(accountId!, removed)
					: undefined
				}
				onSearch={searchSubjectSuggestions}
				onPressSelect={onPressSelect?.disableMultipleSelection ? undefined : onPressSelect}
				itemTranslationKey="common:subject"
				renderItem={renderItem}
				idKey="id"
			/>
		</>
	);
};

export const SelectSubjectSuggestionModal = (props: ClientStackNavigatorScreenProps<"SelectSubjectSuggestionModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectSubjectSuggestion {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	bold: {
		fontFamily: OpenSans.Bold,
	},
	regular: {
		fontFamily: OpenSans.Regular,
	},
});
