import * as React from "react";
import {StyleSheet, View} from "react-native";
import {DAY_HEIGHT} from "../../utils/constants";
import {Day as DayProps} from "../../utils/date-time/activities";
import {daysOfTheWeek} from "../../utils/date-time/helpers";
import {
	CALENDAR_SUNDAY,
	CONTRAST_3,
	PRIMARY_2,
	SUBTLE_2,
	SUBTLE_4,
	TRANSPARENT,
	WHITE,
} from "../../utils/styles/colors";
import {Text} from "../texts/text";
import {Pressable} from "../views/pressable";

export const Day = ({day, disabled, excluded, active, today, activity, onDayPress}: DayProps): JSX.Element => {
	const onPress = React.useCallback(
		() => !disabled && onDayPress?.(day),
		[day, disabled, onDayPress],
	);
	return (
		<Pressable onPress={onPress} style={styles.container} disabled={disabled}>
			{!excluded && (
				<>
					<View
						style={[
							styles.background,
							{backgroundColor: active ? PRIMARY_2 : today ? CONTRAST_3 : TRANSPARENT},
						]}
					>
						<Text
							type="emphasis_1"
							color={
								(active || today)
									? WHITE
									: disabled
										? SUBTLE_2
										: daysOfTheWeek[day?.getDay()] === "sunday"
											? CALENDAR_SUNDAY
											: PRIMARY_2
							}
							selectable={false}
						>
							{day && day.getDate()}
						</Text>
					</View>
					{activity && (
						<View
							style={[
								styles.dot,
								{backgroundColor: active ? PRIMARY_2 : SUBTLE_4},
							]}
						/>
					)}
				</>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	background: {
		alignItems: "center",
		borderRadius: 14,
		height: 28,
		justifyContent: "center",
		width: 28,
	},
	container: {
		alignItems: "center",
		flexDirection: "column",
		height: DAY_HEIGHT,
		justifyContent: "space-between",
		width: "14.2857142857%",
	},
	dot: {
		borderRadius: 3,
		height: 6,
		width: 6,
	},
});
