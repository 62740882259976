import * as React from "react";
import {JsonValue} from "react-use-websocket/dist/lib/types";
import {AvailabilityState} from "../../@types/availability";
import {InterpreterPreview} from "../../@types/identities/interpreter";
import {IconKey} from "../../components/icons";
import {
	getInterpreterAvailabilityData,
	getInterpretersAvailabilitiesMessage,
} from "../../requests/common/availabilities";
import {IS_CLIENT} from "../constants";
import {Log} from "../logs/logs";
import {GREEN, RED, SUBTLE_4} from "../styles/colors";
import {s} from "../switch";
import {useWebSocket} from "./use-websocket";

export type AvailabilityKey = "available" | "occupied" | "unavailable";

export const getAvailabilityIconKey = (availability: AvailabilityKey): IconKey =>
	s(availability, {
		available: "signalOn",
		occupied: "signalOff",
		unavailable: "signalOff",
	});
export const getAvailabilityColorFromReason = (reason?: AvailabilityState): string => reason === "available"
	? GREEN
	: reason === "inEmergenciesDisabled"
		? RED
		: IS_CLIENT ? RED : SUBTLE_4; // requester app make no difference in the color between unavailable and occupied
export const getAvailabilityFromState = (availabilityState: AvailabilityState): AvailabilityKey =>
	availabilityState === "available"
		? "available"
		: availabilityState === "inEmergenciesDisabled"
			? "unavailable"
			: "occupied";

interface Props {
	connect?: boolean;
	onData: (data: Record<string, AvailabilityState | undefined>) => void;
}

export const useInterpretersAvailabilities = (
	{onData, connect = true}: Props,
): {
		fetchAvailabilities: (ids: InterpreterPreview["identityId"][]) => void;
	} => {
	const {sendJsonMessage} = useWebSocket({
		connect,
		handlers: [
			{
				dataGetter: getInterpreterAvailabilityData,
				onData,
				updateOnAppFocus: false,
			},
		],
		onWSError: Log.error(),
	});

	const fetchAvailabilities = React.useCallback(
		(ids: InterpreterPreview["identityId"][]) =>
			// JsonArray doesn't accept string[]
			sendJsonMessage(getInterpretersAvailabilitiesMessage(ids) as unknown as JsonValue),
		[sendJsonMessage],
	);

	return {fetchAvailabilities};
};
