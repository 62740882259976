import * as React from "react";
import {StyleSheet} from "react-native";
import {RequesterCalendarMandatePreview} from "../../../../@types/activities/mandate";
import {SessionCancelOrReschedule} from "../../../../@types/activities/session";
import {CancelOrRescheduleSetting, SessionCancelAndRescheduleSetting} from "../../../../@types/settings";
import {Form, FormInput} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {SessionCreationContext, SessionCreationContextVal} from "../../../../contexts/session-creation";
import {cancelSession} from "../../../../requests/clients/activities/cancel";
import {useForm} from "../../../../utils/hooks/use-form";
import {useReasonInputs} from "../../../../utils/hooks/use-reason-inputs";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {personIdentity} from "../../../../utils/identities";
import {Log} from "../../../../utils/logs/logs";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

export const toRescheduleCreation = (
	session: RequesterCalendarMandatePreview,
	rescheduleData: SessionCancelOrReschedule,
	reset: SessionCreationContextVal["reset"],
	navigation: ClientStackNavigatorScreenProps<"CancelOrRescheduleSessionModal">["navigation"],
): void => {
	// Reschedule can only appear when in focus mode, so we have the full session here
	reset({
		rescheduleData,
		type: "reschedule",
	}, {
		initialExcludedInterpreterIds: rescheduleData.sendOnlyToCurrentProvider ?? rescheduleData.keepExcludedInterpreters
			? session.excludedInterpreters.map(e => e.accountId)
			: undefined,
		initialProvider: rescheduleData.sendOnlyToCurrentProvider ? session.providers[0] : undefined,
		session,
	});
	navigation.navigate("HomeTabNavigator", {screen: "NewSession"});
};

const CancelOrRescheduleSession = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"CancelOrRescheduleSessionModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {callbacks, context, session} = route.params;
	const {reset} = React.useContext(SessionCreationContext);
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {cancel: cancelSetting, reschedule: rescheduleSetting} = {
			cancel: {} as CancelOrRescheduleSetting, reschedule: {} as CancelOrRescheduleSetting,
		},
	} = getSetting<SessionCancelAndRescheduleSetting>("session/cancel-or-reschedule", "organization/requesters") ?? {};

	const base = React.useMemo(
		() => ({
			keepExcludedInterpreters: true,
		}) as SessionCancelOrReschedule,
		[],
	);
	const {
		displayed, isUpdated, setUpdated, errorMessage, loading: loadingForm,
	} = useForm<SessionCancelOrReschedule>(base);

	const onPressCancel = (): Promise<void> =>
		cancelSession(session.activityId, displayed)
			.then(() => {
				callbacks?.("cancelSessionSuccess");
				Log.success("cancelSessionSuccess")();
				navigation.dispatch(forceBack);
			})
			.catch(Log.error("refuseSessionFailed"));

	const onPressReschedule = React.useCallback(
		() => toRescheduleCreation(session, displayed, reset, navigation),
		[displayed, navigation, reset, session],
	);

	const reasonInputs = useReasonInputs(
		context === "cancel"
			? cancelSetting
			: rescheduleSetting,
		displayed,
		setUpdated,
		context === "cancel"
			? t("activities:sessions.cancellationReason")
			: t("activities:sessions.reschedule.reason"),
	);

	const inputs = React.useMemo(
		(): FormInput[] => {
			const createInputs = ({displayCancelDueToInterpreter}: CancelOrRescheduleSetting): FormInput[] => {
				let inputs: FormInput[] = [...reasonInputs];
				if (displayCancelDueToInterpreter) {
					inputs = [
						...inputs,
						"spacer",
						{
							label: t("activities:sessions.canceledByInterpreter"),
							type: {
								key: "checkbox",
								onChangeValue: (canceledByProvider: boolean) => setUpdated(prev => ({...prev, canceledByProvider})),
								value: displayed.canceledByProvider,
							},
						},
					];
				}
				return inputs;
			};

			if (context === "cancel") {
				if (cancelSetting?.dropdown?.values.length === 1) {
					setUpdated(prev => ({...prev, selectedReasonId: cancelSetting.dropdown?.values[0].id}));
				}
				return createInputs(cancelSetting);
			}

			const keepExcludedInput: FormInput = {
				icon: "personExcluded",
				label: t("activities:sessions.reschedule.excluded"),
				type: {
					key: "checkbox",
					onChangeValue: (keepExcluded: boolean) =>
						setUpdated(prev => ({...prev, keepExcludedInterpreters: keepExcluded})),
					value: displayed.keepExcludedInterpreters,
				},
			};

			const sendToInputs: FormInput[] = session.status === "sent"
				? []
				: [
					"spacer", {
						icon: "person",
						label: t("activities:sessions.reschedule.toOneInterpreter", {
							interpreter: personIdentity(session.providers[0]),
						}),
						type: {
							key: "select",
							onChangeValue: (sendOnlyToCurrentProvider: boolean) =>
								setUpdated(prev => ({...prev, sendOnlyToCurrentProvider})),
							value: displayed.sendOnlyToCurrentProvider,
						},
					}, {
						icon: "people",
						label: t("activities:sessions.reschedule.toAllInterpreters"),
						type: {
							key: "select",
							onChangeValue: (sendToAllProviders: boolean) =>
								setUpdated(prev => ({...prev, sendOnlyToCurrentProvider: !sendToAllProviders})),
							value: !displayed.sendOnlyToCurrentProvider,
						},
					},
					!displayed.sendOnlyToCurrentProvider && session.excludedInterpreters && session.excludedInterpreters.length >
					0 && keepExcludedInput,
				];

			if (session.status === "confirmed") {
				if (rescheduleSetting?.dropdown?.values.length === 1) {
					setUpdated(prev => ({...prev, selectedReasonId: rescheduleSetting?.dropdown?.values[0].id}));
				}
				return [...createInputs(rescheduleSetting), ...sendToInputs];
			}

			// Reschedule a non-confirmed session with excluded interpreters
			return [keepExcludedInput];
		},
		[
			context,
			cancelSetting,
			rescheduleSetting,
			displayed.canceledByProvider,
			displayed.sendOnlyToCurrentProvider,
			displayed.keepExcludedInterpreters,
			session.excludedInterpreters,
			session.providers,
			session.status,
			setUpdated,
			reasonInputs,
			t,
		],
	);

	const header = <HeaderMenu right={{help: false}} exitConfirmation={isUpdated}/>;

	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
				style={styles.loadingContainer}
			/>
		);
	}

	return (
		<>
			{header}
			<Form
				hideReset
				title={ct(
					context === "cancel"
						? "activities:sessions.cancelConfirmation"
						: session.status === "confirmed"
							? "activities:sessions.reschedule.confirmation"
							: "common:reschedule",
				)}
				validation={{
					buttonProps: {
						disabled: (
							context === "cancel"
								? cancelSetting?.dropdown?.mandatory && !displayed.selectedReasonId
								: context === "reschedule" && session.status === "confirmed" && rescheduleSetting?.dropdown?.mandatory && !displayed.selectedReasonId
						),
						icon: context === "cancel" ? "check" : "forward",
						iconPosition: context === "cancel" ? "before" : "after",
						text: context === "cancel" ? ct("common:confirm") : ct("common:reschedule"),
					},
					onValidation: () => context === "cancel" ? onPressCancel() : onPressReschedule(),
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const CancelOrRescheduleSessionModal = (
	props: ClientStackNavigatorScreenProps<"CancelOrRescheduleSessionModal">,
): JSX.Element => (
	<ModalWrapper>
		<CancelOrRescheduleSession {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	loadingContainer: {
		flexBasis: 370, // Content height estimation
	},
});
