import * as React from "react";
import {SafeAreaView, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {CONTAINERS, DEFAULT_SPACING} from "../../utils/constants";
import {KeyboardAvoidingView} from "./keyboard-avoiding-view";

interface DrawerProps {
	children: React.ReactNode | React.ReactNode[];
	verticalPosition: "bottom" | "center";
}

export const Drawer = ({children, verticalPosition}: DrawerProps): JSX.Element => {
	const insets = useSafeAreaInsets();
	return (
		<KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={-insets.bottom}>
			<SafeAreaView
				style={[
					CONTAINERS.DRAWER,
					{
						borderBottomLeftRadius: verticalPosition === "center" ? DEFAULT_SPACING : 0,
						borderBottomRightRadius: verticalPosition === "center" ? DEFAULT_SPACING : 0,
						borderTopLeftRadius: DEFAULT_SPACING,
						borderTopRightRadius: DEFAULT_SPACING,
					},
				]}>
				<View style={CONTAINERS.MAIN}>
					{children}
				</View>
			</SafeAreaView>
		</KeyboardAvoidingView>
	);
};
