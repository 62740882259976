import {users as enUSUsers} from "../en-US/users";

export const users: typeof enUSUsers = {
	interpreter: {
		accepts: "{{firstName}} accepte:",
		availabilities: {
			available: "Disponible",
			inEmergenciesDisabled: "Indisponible pour urgences & immédiat",
			inNights: "Indisponible de nuit",
			inPrivateEvent: "Occupé dans un évènement privé",
			inSession: "Occupé dans une session",
			inWeekend: "Indisponible durant le week-end",
			occupied: "Occupé",
			unavailable: "Indisponible jusqu'à demain",
		},
		copyAddress: "Copier",
		new: "Nouvelle demande",
		qualifications: {
			certificate: "Certificat INTERPRET",
			exam: "Formation interprète",
			federalCertificate: "Brevet fédéral",
			module1: "Module 1 INTERPRET",
			module2: "Module 2 INTERPRET",
			module3: "Module 3 INTERPRET",
			module4: "Module 4 INTERPRET",
			module4a: "Module 4a INTERPRET",
			module5: "Module 5 INTERPRET",
			module6: "Module 6 INTERPRET",
			module7: "Module 7 INTERPRET",
			module8: "Module 8 INTERPRET",
			module9: "Module 9 INTERPRET",
			module10: "Module 10 INTERPRET",
			qualificationA: "Qualification A",
			qualificationB: "Qualification B",
			qualificationC: "Qualification C",
		},
		ticket: "Billets CFF",
	},
	menu: {
		calendar: "Calendrier",
	},
	person: {
		dateOfBirth: "Date de naissance",
		unknownPerson: "Personne inconnue",
	},
	receiver: {
		ageGroup: {
			adult: "Adulte",
			child: "Enfant ou adolescent",
			label: "Groupe d'âge",
		},
		insurance: {
			asylblg: "AsylbLG",
			gkv: "GKV",
			label: "Assurance",
			none: "Pas d'assurance",
			other: "Autre",
			unknown: "Inconnue",
		},
	},
	requester: {
		cancel: "Annuler",
	},
	settings: {
		availabilityScheduled: "Disponibilité programmée",
		availableImmediate: "Disponible pour les sessions immédiates",
		availableNights: "Disponible de nuit ({{nightStart}} - {{nightEnd}})",
		availableWeekend: "Disponible le week-end ({{WEStart}} - {{WEEnd}})",
		exportAgreement: "Les montants et décomptes sont indicatifs et sont sujets à des ajustements appliqués au moment de la préparation des salaires.",
		exportCSV: "Exporter CSV",
		exportPDF: "Exporter PDF",
		feedback: "Feedback",
		notifications: {
			SMS: "Notifications par SMS",
			SMSShort: "SMS",
			eMail: "Notifications par Email",
			eMailShort: "Email",
			push: "Notifications push",
			pushShort: "Push",
		},
		orderCards: "Commander cartes de validation",
		personalisedData: "Données personnalisées",
		privacy: "Protection des données",
		profile: "Profil",
		savedAddresses: "Adresses enregistrées",
		savedSubjects: "Sujets enregistrés",
		sessionCourse: "Session de cours",
		sessionInPerson: "Sessions en personne",
		sessionMediation: "Sessions de médiations",
		sessionModels: "Modèles de session",
		sessionPhone: "Sessions par téléphone",
		sessionVideo: "Sessions en vidéo-conférence",
		unavailableImmediate: "Indisponible pour les sessions immédiates",
		unavailableNights: "Indisponible de nuit ({{nightStart}} - {{nightEnd}})",
		unavailableWeekend: "Indisponible le week-end ({{WEStart}} - {{WEEnd}})",
		update: "Enregistrer les paramètres",
	},
};
