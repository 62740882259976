import * as React from "react";
import {communicationTypes} from "../../../../@types/activities/session";
import {AvailabilitySetting, SessionCommunicationSetting} from "../../../../@types/settings";
import {Form} from "../../../../components/inputs/form";
import {ListInputProps} from "../../../../components/inputs/list";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {IS_INTERPRETER} from "../../../../utils/constants";
import {formatDayTime, formatTime} from "../../../../utils/date-time/format";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {capitalize} from "../../../../utils/strings";

const Availabilities = (_: InterpreterStackNavigatorScreenProps<"AvailabilitiesModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {settings: {loading, errorMessage, getSetting, updateSetting}} = React.useContext(AuthentifiedContext);

	const communicationTypesSetting = getSetting<SessionCommunicationSetting>(
		"session/communication", "organization/all");
	const availabilitySetting = getSetting<AvailabilitySetting>("availability/availabilities", "account");

	const header = <HeaderMenu center={ct("common:availability_plural")}/>;
	if (errorMessage || !availabilitySetting || !communicationTypesSetting) {
		return (
			<SplashView
				centered
				headerComponent={header}
				message={errorMessage
					? {translationKey: errorMessage, type: "error"}
					: !availabilitySetting || !communicationTypesSetting
						? {
							translationKey: IS_INTERPRETER
								? "feedbacks:getInterpreterSettingsFailed"
								: "feedbacks:getUserSettingsFailed",
							type: "error",
						}
						: undefined
				}
				loading={loading}
			/>
		);
	}

	const communicationTypesInputs: ListInputProps[] = communicationTypes.map(type => {
		return ({
			disabled: true,
			icon: getCommunicationTypeIcon(type),
			label: t(`users:settings.session${capitalize(type)}`),
			type: {
				key: "checkbox",
				onChangeValue: (value: boolean) => updateSetting({
					...communicationTypesSetting,
					value: {
						...communicationTypesSetting.value,
						[type]: value,
					},
				}),
				value: communicationTypesSetting.value[type],
			},
		});
	});

	const {weekend, nights} = availabilitySetting.value;
	return (
		<>
			{header}
			<Form
				hideReset
				title={undefined}
				inputs={[
					{
						disabled: loading,
						icon: "weekEnd",
						label: t(
							weekend ? "users:settings.availableWeekend" : "users:settings.unavailableWeekend",
							{WEEnd: formatDayTime(weekend.end), WEStart: formatDayTime(weekend.start)},
						),
						type: {
							key: "checkbox",
							onChangeValue: (w: boolean) => {
								updateSetting({
									...availabilitySetting,
									value: {
										...availabilitySetting.value,
										weekend: {...weekend, value: w},
									},
								}).catch(Log.error());
							},
							value: weekend.value,
						},
					}, {
						disabled: loading,
						icon: "night",
						label: t(
							nights ? "users:settings.availableNights" : "users:settings.unavailableNights",
							{nightEnd: formatTime(nights.end), nightStart: formatTime(nights.start)},
						),
						type: {
							key: "checkbox",
							onChangeValue: (n: boolean) => {
								updateSetting({
									...availabilitySetting,
									value: {
										...availabilitySetting.value,
										nights: {...nights, value: n},
									},
								}).catch(Log.error());
							},
							value: nights.value,
						},
					}, {
						disabled: true,
						icon: "calendar",
						label: t("users:settings.availabilityScheduled"),
						type: {
							key: "checkbox",
							value: true,
						},
					},
					"spacer",
					...communicationTypesInputs,
				]}
			/>
		</>
	);
};

export const AvailabilitiesModal = (props: InterpreterStackNavigatorScreenProps<"AvailabilitiesModal">): JSX.Element => (
	<ModalWrapper>
		<Availabilities {...props} />
	</ModalWrapper>
);
