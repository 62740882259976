import * as React from "react";
import {StyleSheet, View} from "react-native";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {Button, ButtonProps} from "../../buttons/button";

export interface InlineSelectListProps {
	choices?: readonly any[] | null;
	disableBreakpoint?: boolean;
	getLabelText: (item: any) => string;
	itemProps?: (item: any) => ButtonProps;
	key: "inlineSelect";
	keyExtractor?: (item: any) => string;
	multiple?: boolean;
	onChangeValue: (value: any[] | null) => void;
	onPressItem?: (item: any) => void;
	value?: any[] | null;
}

type InlineSelectInputProps = Omit<InlineSelectListProps, "key"> & {
	collapsed?: boolean;
	disabled?: boolean;
	toggleCollapse?: () => void;
};

const DISPLAY_MORE_BREAKPOINT = 5;

export const InlineSelectInput = ({
	choices,
	getLabelText,
	keyExtractor,
	itemProps,
	multiple,
	onChangeValue,
	value,
	disabled,
	collapsed,
	toggleCollapse,
	onPressItem,
	disableBreakpoint,
}: InlineSelectInputProps): JSX.Element => {
	const {t} = useTranslation();

	const values = React.useMemo(
		() => (value ?? []).map(el => ({key: (keyExtractor ?? getLabelText)(el), value: el})),
		[getLabelText, keyExtractor, value],
	);

	return (
		<View style={styles.multiSelectContainer}>
			{(choices ?? []).map((choice, index) => {
				if (!disableBreakpoint && collapsed && index >= DISPLAY_MORE_BREAKPOINT) {
					return null;
				}
				const key = (keyExtractor ?? getLabelText)(choice);
				const selected = values.some(el => el.key === key);
				return (
					<Button
						key={key}
						size="xsmall"
						type="secondary"
						active={selected}
						disabled={disabled}
						onPress={onPressItem
							? () => onPressItem(choice)
							: () => {
								onChangeValue(
									multiple
										? selected
											? values.filter(value => key !== value.key).map(el => el.value)
											: [...values.map(el => el.value), choice]
										: selected
											? null
											: [choice],
								);
							}}
						style={styles.multiSelectButton}
						text={getLabelText(choice)}
						{...itemProps?.(choice)}
					/>
				);
			})}
			{!disableBreakpoint && (choices ?? []).length > DISPLAY_MORE_BREAKPOINT && (
				<Button
					style={styles.multiSelectAdd}
					text={collapsed ? t("forms:inputs.showMore") : t("forms:inputs.showLess")}
					icon={collapsed ? "chevronBottom" : "chevronUp"}
					type="secondary"
					size="xsmall"
					onPress={toggleCollapse}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	multiSelectAdd: {
		marginLeft: 3,
	},
	multiSelectButton: {
		flexShrink: 1,
		margin: 3,
	},
	multiSelectContainer: {
		flexDirection: "row",
		flexShrink: 1,
		flexWrap: "wrap",
		marginHorizontal: -8,
	},
});
