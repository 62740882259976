import {EventArg, useNavigation} from "@react-navigation/native";
import React from "react";

export const useBeforeRemove = (callback: (e: EventArg<any>) => void, enabled = false): void => {
	const navigation = useNavigation();
	React.useEffect(
		() => {
			const unsubscribe = navigation.addListener("beforeRemove", (e) => {
				if (
					!enabled ||
					(e.data.action.type !== "GO_BACK" && e.data.action.type !== "RESET") ||
					(e.data.action?.payload as Record<string, any>)?.force
				) {
					return null;
				}
				e.preventDefault();
				return callback(e.data.action);
			});
			return unsubscribe;
		},
		[callback, enabled, navigation],
	);
};
