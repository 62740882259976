import * as React from "react";
import {Pagination} from "../../../../@types/pagination";
import {ListElement} from "../../../../components/list/items/list-element";
import {Item} from "../../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {paginate} from "../../../../utils/pagination";
import {forceBack} from "../../../navigation";

export type PeopleNumberListItem = Item<"id", {displayed: string; id: string}>;

export const getPeopleNumberListItems = (minimum: number, maximum: number, search?: string): PeopleNumberListItem[] =>
	Array
		.from({length: maximum - minimum + 1}, (_, index) => minimum + index)
		.map(el => ({displayed: String(el), id: String(el)}))
		.filter(el => !search || el.displayed.includes(search));

const SelectPeopleNumber = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"SelectPeopleNumberModal">): JSX.Element => {
	const {maximum, minimum, onSelect: onSelectParam} = route.params ?? {};
	const {ct} = useTranslation();

	const list = React.useMemo(
		() => getPeopleNumberListItems(minimum, maximum),
		[minimum, maximum],
	);

	const onSelect = React.useCallback(
		(value: number) => () => {
			onSelectParam?.(value);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelectParam],
	);

	const renderItem = React.useCallback(
		({info: {item}, buttons}: FlatListRenderItemParams<"id", PeopleNumberListItem>) => (
			<ListElement
				onPress={onSelect(Number(item.id))}
				buttons={buttons}
			>
				{item.displayed}
			</ListElement>
		),
		[onSelect],
	);

	const getRequest = React.useCallback(
		(pagination: Pagination) => Promise.resolve(paginate(list, pagination)),
		[list],
	);

	return (
		<>
			<HeaderMenu center={ct("forms:items.select", {item: ct("common:person_plural")})}/>
			<SelectFlatList
				getRequest={getRequest}
				renderItem={renderItem}
				itemTranslationKey="common:person_plural"
				idKey="id"
			/>
		</>
	);
};

export const SelectPeopleNumberModal = (props: ClientStackNavigatorScreenProps<"SelectPeopleNumberModal">): JSX.Element => (
	<ModalWrapper>
		<SelectPeopleNumber {...props} />
	</ModalWrapper>
);
