import * as Notifications from "expo-notifications";
import {Notification, NotificationRequest} from "expo-notifications/src/Notifications.types";
import {NotificationTarget, NotificationsChannel, NotificationData} from "../../@types/notifications";

export class DeviceTokenHolder {
	public static token: string;
}

export interface ReceivedNotification extends Omit<Notifications.NotificationResponse, "notification"> {
	notification: Omit<Notification, "request"> & {
		request: Omit<NotificationRequest, "content"> & {
			content: Omit<Notifications.NotificationContent, "data"> & {
				data: NotificationData;
			};
		};
	};
}

export interface CommonNotificationManager {
	appropriatePushChannel: () => NotificationsChannel;
	checkPermissions: () => Promise<boolean>;
	getLastNotificationResponse: () => Promise<Notifications.NotificationResponse | null>;
	getThisTargetInfos: () => Pick<NotificationTarget, "appVersion" | "channel" | "deletable" | "displayed" | "id">;
	requestPermissions: () => Promise<boolean>;
	subscribeNotification: () => Notifications.Subscription | null;
	unsubscribeNotification: (listener?: Notifications.Subscription | undefined) => void;
	updateBadge: (badgeCount: number) => Promise<boolean>;
}
