import * as React from "react";
import {LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING, TITLEBAR_HEIGHT} from "../../utils/constants";
import {Log} from "../../utils/logs/logs";
import {PRIMARY_2, SUBTLE, WHITE} from "../../utils/styles/colors";
import {Icon, IconKey} from "../icons";
import {Text} from "../texts/text";
import {Pressable} from "../views/pressable";

interface Segment {
	active?: boolean;
	icon?: IconKey;
	onPress: () => void;
	text: string;
}

interface Props {
	iconsThreshold?: number;
	segments: Segment[];
	style?: StyleProp<ViewStyle>;
}

export const SegmentedBar = ({style, segments, iconsThreshold}: Props): JSX.Element => {
	const [width, setWidth] = React.useState<number>(0);
	const onLayout = React.useCallback(
		({nativeEvent: {layout: {width}}}: LayoutChangeEvent) => {
			setWidth(width);
		},
		[],
	);

	if (segments.some(s => s.icon) !== !!iconsThreshold) {
		Log.debug()("segments icons and iconsThreshold must be used together");
	}

	return (
		<View
			style={[CONTAINERS.MAIN, styles.container, style]}
			onLayout={onLayout}
		>
			{segments.map(({text, icon, onPress, active}, index) => (
				<Pressable
					key={text}
					onPress={onPress}
					style={[
						styles.segment,
						active && styles.activeSegment,
						index === 0 && styles.firstSegment,
						index === segments.length - 1 && styles.lastSegment,
						index !== 0 && styles.hasPreviousSegment,
					]}
				>
					{!!iconsThreshold && width <= iconsThreshold && !!icon
						? (
							<Icon
								icon={icon}
								size={18}
								containerSize={18}
								color={active ? WHITE : PRIMARY_2}
							/>
						)
						: (
							<Text
								numberOfLines={1}
								lineBreakMode="tail"
								color={active ? WHITE : PRIMARY_2}
								type="button_2"
								selectable={false}
							>
								{text}
							</Text>
						)}
				</Pressable>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	activeSegment: {
		backgroundColor: PRIMARY_2,
		zIndex: 100,
	},
	container: {
		alignItems: "center",
		backgroundColor: SUBTLE,
		flexDirection: "row",
		height: TITLEBAR_HEIGHT,
		paddingHorizontal: DEFAULT_SPACING,
	},
	firstSegment: {
		borderBottomLeftRadius: SMALL_SPACING,
		borderTopLeftRadius: SMALL_SPACING,
	},
	hasPreviousSegment: {
		marginLeft: -2,
	},
	lastSegment: {
		borderBottomRightRadius: SMALL_SPACING,
		borderTopRightRadius: SMALL_SPACING,
	},
	segment: {
		alignItems: "center",
		borderColor: PRIMARY_2,
		borderWidth: 2,
		flex: 1,
		justifyContent: "center",
		padding: 7,
	},
});
