import {getFocusedRouteNameFromRoute, getStateFromPath, NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import * as React from "react";
import {Translations} from "../../@types/@react-i18next.d.ts";
import {SplashView} from "../../components/views/splash-view";
import {ActivitiesProvider} from "../../contexts/activities";
import {AuthentifiedContext} from "../../contexts/authentified";
import {InboxProvider} from "../../contexts/inbox";
import {SessionCreationProvider} from "../../contexts/session-creation";
import {APP, APP_NAME, ENV, IS_INTERPRETER} from "../../utils/constants";
import {useTranslation} from "../../utils/hooks/use-translation";
import {Locales, SupportedLocaleKey, supportedLocales} from "../../utils/locales/locales";
import {Log} from "../../utils/logs/logs";
import {LogsService} from "../../utils/logs/logs-service";
import {defaultStackScreenOptions} from "../../utils/navigation/defaults";
import {StackParamList} from "../../utils/navigation/paramLists/root-param-list";
import {authPaths} from "../../utils/navigation/paths/auth-paths";
import {clientHomeTabPaths} from "../../utils/navigation/paths/client-paths";
import {interpreterHomeTabPaths} from "../../utils/navigation/paths/interpreter-paths";
import {modalPaths} from "../../utils/navigation/paths/modal-paths";
import {rootPaths} from "../../utils/navigation/paths/root-paths";
import {getKeys} from "../../utils/objects";
import {Navigation} from "../navigation";
import {AuthScreensGroup} from "./auth-screens-group";
import {AuthentifiedScreensGroup as ClientAuthentifiedScreensGroup} from "./client/authentified-screens-group";
import {AuthentifiedScreensGroup as InterpreterAuthentifiedScreensGroup} from "./interpreter/authentified-screens-group";
import {PublicScreensGroup} from "./public-screens-group";

const onNavigationReady = (): void => {
	LogsService.routingInstrumentation?.registerNavigationContainer?.(Navigation.navigationRef);
	Navigation.navigationReady();
};

const RootStack = createNativeStackNavigator<StackParamList>();
export const AppContainer = (): JSX.Element => {
	const {authToken, settings: {loading, errorMessage}, accountId} = React.useContext(AuthentifiedContext);
	const {t, ct} = useTranslation();

	const InboxOrSessionCreationProvider = IS_INTERPRETER ? InboxProvider : SessionCreationProvider;

	return loading || errorMessage
		? <SplashView centered
			loading={loading}
			message={errorMessage && {translationKey: errorMessage, type: "error"}}
		/>
		: <NavigationContainer
			linking={{
				config: rootPaths,
				enabled: true,
				getStateFromPath: (path, options) => {
					const [route, query] = path.split("?");

					const params = new URLSearchParams(query);
					// Extract a locale from the path if it exists, then apply it.
					const localeParam = params.get("locale") as SupportedLocaleKey;
					if (localeParam && getKeys(supportedLocales).includes(localeParam) && Locales.currentLocale !== localeParam) {
						Locales.set(localeParam).catch(Log.error());
					}

					const defaultState = getStateFromPath(
						IS_INTERPRETER
							? interpreterHomeTabPaths.HomeTabNavigator.screens.Inbox
							: clientHomeTabPaths.HomeTabNavigator.screens.NewSession,
						options,
					);

					// If it's a session modal, we handle it in a special way: first default page and then the modal on top of it
					if (new RegExp(`/${modalPaths.SessionModal.split(":")[0]}[0-9]+`).test(route)) {
						return {
							routes: [
								...(defaultState?.routes ?? []),
								...(getStateFromPath(path, options)?.routes ?? []),
							],
						} satisfies ReturnType<typeof getStateFromPath>;
					}

					// If it's the video session url, we want to access it, even if it's a modal
					if (route === `/${modalPaths.VideoSessionModal}`) {
						return getStateFromPath(path, options);
					}

					/**
					 * Redirect to default page when:
					 * - we are logged in but trying to access paths of AuthScreensGroup which is not mounted when user is logged in
					 * - we try to access a modal
					 */
					const authUrls = Object.values(authPaths).map(screen => `/${screen}`);
					const modalUrls = Object.values(modalPaths).map(screen => `/${screen}`);
					if ((!!accountId && authUrls.includes(route)) || modalUrls.includes(route)) {
						return defaultState;
					}

					return getStateFromPath(path, options);
				},
				prefixes: [
					Linking.createURL("/"),
					`https://${APP}.${ENV === "staging" ? "staging." : ""}bhaasha.ch`,
					`https://${APP}.${ENV === "staging" ? "staging." : ""}bhaasha.com`,
				],
			}}
			fallback={<SplashView centered loading/>}
			onReady={onNavigationReady}
			ref={Navigation.navigationRef}
			documentTitle={{
				formatter: (_, route) => {
					if (route) {
						const routeName = (getFocusedRouteNameFromRoute(route) ?? route.name) as keyof Translations["routeTitles"];
						const routeTitle = t(`routeTitles:${routeName}`);
						return `${routeTitle}${routeTitle.length === 0 ? "" : " - "}${APP_NAME} - ${ct(
							`common:${IS_INTERPRETER ? "interpreter" : "client"}`)}`;
					}
					return `${APP_NAME} - ${ct(`common:${IS_INTERPRETER ? "interpreter" : "client"}`)}`;
				},
			}}
		>
			<ActivitiesProvider>
				<InboxOrSessionCreationProvider>
					<RootStack.Navigator screenOptions={{...defaultStackScreenOptions, gestureEnabled: false}}>
						{authToken
							? IS_INTERPRETER
								? InterpreterAuthentifiedScreensGroup
								: ClientAuthentifiedScreensGroup
							: AuthScreensGroup
						}
						{PublicScreensGroup}
					</RootStack.Navigator>
				</InboxOrSessionCreationProvider>
			</ActivitiesProvider>
		</NavigationContainer>;
};
