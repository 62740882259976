export const routeTitles = {
	AddressesListModal: "Saved addresses",
	AuthScreensGroup: "Sign in",
	AvailabilitiesModal: "Availabilities",
	Calendar: "Calendar",
	CalendarModal: "Calendar",
	CancelOrRescheduleSessionModal: "Cancel Session",
	ClientAccount: "Account",
	ComponentsModal: "Components",
	ConfirmationModal: "Confirmation",
	DebugModal: "Debug",
	DetailsStep: "Details step",
	EditCorporationModal: "Edit corporation",
	EditPaymentAuthorityModal: "Edit payment authority",
	EditPersonModal: "Edit person",
	EditProfileModal: "Edit profile",
	EditSessionCategoryModal: "Edit session category",
	EditSubjectSuggestionModal: "Edit subject",
	FeedbackModal: "Feedback",
	FollowUpModal: "Follow-up",
	HomeTabNavigator: "",
	Inbox: "Inbox",
	Interpreter: "Interpreter",
	InterpreterAccount: "Account",
	InterpreterModal: "Interpreter",
	InterpreterRootNavigator: "Inbox",
	Interpreters: "Interpreters",
	NewSession: "New session",
	NotificationsModal: "Notifications",
	OverlappingSessionsModal: "Unavailability warning",
	SalaryModal: "Salaries",
	ScheduleStep: "Schedule step",
	SearchInterpretersModal: "Search",
	SearchSessionsModal: "Search",
	SelectDateCalendarModal: "Select a date",
	SelectDurationModal: "Select duration",
	SelectInterpreterModal: "Select interpreter",
	SelectInterpretersFilterModal: "Select filter",
	SelectLanguageModal: "Select a language",
	SelectMediaDeviceModal: "Select media device",
	SelectMonthModal: "Select a month",
	SelectNotificationTargetModal: "Select a notification target",
	SelectOriginModal: "Select an origin",
	SelectPaymentAuthorityModal: "Select payment authority",
	SelectPeopleNumberModal: "Select number of people",
	SelectPersonModal: "Select a person",
	SelectPlaceModal: "Select an address",
	SelectQualificationModal: "Select a qualification",
	SelectReceiverModal: "Select a receiver",
	SelectRequesterModal: "Select a requester",
	SelectSessionCategoryModal: "Select session category",
	SelectSessionModal: "Select a session",
	SelectSessionOptionsModal: "Select option",
	SelectSessionStatusModal: "Select session status",
	SelectStatusModal: "Select status",
	SelectSubjectSuggestionModal: "Select subject",
	SelectTransportModal: "Select transport",
	SelectValidationIssueModal: "Session's validation",
	SelectValidationTypeModal: "Session's validation",
	SessionCreationFiltersModal: "Filters",
	SessionModal: "Session",
	SessionValidationModal: "Session's validation",
	SetupStep: "Setup step",
	ShareSessionModal: "Share",
	SignIn: "Sign in",
	Slider: "Slider",
	SupportModal: "Contact support",
	UnavailabilityModal: "Unavailability",
	UserRootNavigator: "New session",
	VideoSession: "Video session",
};
