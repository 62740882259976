import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterRecoverPasswordRequests = {
	POST: {
		queryParams: {
			lang: string,
		},
		body: {email: string},
	},
};

export const recoverInterpreter = async (
	identifier: string, locale: SupportedLocaleKey,
): Promise<void> => {
	const response = await api(
		"interpreterRecoverPassword",
		"POST",
		{
			queryParams: {
				lang: locale.slice(0, 2),
			},
			body: {email: identifier},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? Promise.resolve() : Promise.reject(new Error(response.message));
};
