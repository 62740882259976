import * as React from "react";
import {RefreshControlProps} from "react-native";
import {Page, Pagination} from "../../@types/pagination";
import {TranslationFeedbackKey} from "../locales/translations";
import {Log} from "../logs/logs";
import {defaultPagination, nextPagination, updatePageItems} from "../pagination";
import {useRefresh} from "./use-refresh";

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const usePagination = <I,>(
	request: (pagination: Pagination) => Promise<Page<I>>,
	initialRequest = true,
): {
	errorMessage: TranslationFeedbackKey | null;
	isMoreAfter: boolean;
	items: I[];
	loadMoreAfter: () => void;
	loading: boolean;
	loadingMoreAfter: boolean;
	refreshControl: React.ReactElement<RefreshControlProps>;
	reloadItems: () => void;
	setItems: React.Dispatch<React.SetStateAction<Page<I>>>;
} => {
	const [loading, setLoading] = React.useState(initialRequest);
	const [loadingMoreAfter, setLoadingMoreAfter] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey | null>(null);
	// pages is an aggregation containing each downloaded pages
	const [pages, setPages] = React.useState<Page<I>>({
		hasNextPage: false,
		hasPreviousPage: false,
		items: [],
		totalCount: 0,
	});
	const paginationRef = React.useRef<Pagination>(defaultPagination);
	const fetchItems = React.useCallback(
		(resetData = false) =>
			request(paginationRef.current)
				.then((page: Page<I>) => {
					setPages(prev => updatePageItems(
						resetData ? page.items : [...prev.items, ...page.items],
						page,
					));
					setErrorMessage(null);
				})
				.catch((error) => {
					Log.error()(error);
					setErrorMessage("feedbacks:getDataFailed");
				}),
		[request],
	);
	const refreshControl = useRefresh(() => {
		paginationRef.current = defaultPagination;
		return fetchItems(true);
	});
	const loadMoreAfter = React.useCallback(
		() => {
			if (pages.hasNextPage && !loadingMoreAfter) {
				setLoadingMoreAfter(true);
				paginationRef.current = nextPagination(paginationRef.current);
				fetchItems().finally(() => setLoadingMoreAfter(false));
			}
		},
		[fetchItems, loadingMoreAfter, pages.hasNextPage],
	);
	const reloadItems = React.useCallback(
		() => {
			setLoading(true);
			paginationRef.current = defaultPagination;
			fetchItems(true).finally(() => setLoading(false));
		},
		[fetchItems],
	);

	React.useEffect(
		() => {
			if (initialRequest) reloadItems();
		},
		[initialRequest, reloadItems],
	);

	return ({
		errorMessage,
		isMoreAfter: pages.hasNextPage,
		items: pages.items,
		loadMoreAfter,
		loading,
		loadingMoreAfter,
		refreshControl,
		reloadItems,
		setItems: setPages,
	});
};
