import {InterpreterSalaryMandatePreview} from "../../../@types/activities/mandate";
import {Communication} from "../../../@types/activities/session";
import {URL} from "../../../@types/medias/image";
import {Page, Pagination} from "../../../@types/pagination";
import {SessionsAccountingSum} from "../../../navigation/screens/interpreter/modals/salary";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {TransformCommunicationRequest} from "../../@types/communication";
import {BackendSession} from "../../@types/session";
import {
	transformLanguageISOCodeBackToFront, transformSessionStatus, transformSessionTypeBackToFront, transformToCommunication,
} from "../../utils/transform";

export type InterpreterSalaryRequests = {
	GET: {
		pathParams: {
			fromDate: string,
			toDate: string,
		},
		queryParams?: {
			limit: string,
			offset: string,
		},
	},
};
export type InterpreterExportSalaryRequests = {
	GET: {
		queryParams: {
			fromDate: string,
			toDate: string,
			limit?: string,
			offset?: string,
			status?: string,
			exportFormat?: string,
			sendPdfMail?: string,
			docType?: string,
			email?: string,
			download?: string,
			exportFor?: string,
		},
	},
};
export type InterpreterSalaryResponse = {
	base: number,
	increased: number,
	reduced: number,
	sessions: number,
	travel: {
		flatRate?: number,
		distance?: number,
		time?: number,
	},
	workDuration: number,
	currency: "CHF" | "EUR",
};

export const getFinancialSessions = async (
	from: Date, to: Date, pagination: Pagination): Promise<Page<InterpreterSalaryMandatePreview>> => {
	const {offset, limit} = pagination;
	const backendOffset = offset === 0 ? 1 : (offset / limit + 1);
	const interpreterResponse = await api(
		"interpreterBilledSessions",
		"GET",
		{
			pathParams: {
				fromDate: new Date(from.getFullYear(), from.getMonth(), 1).getTime().toString(),
				toDate: to.getTime().toString(),
			},
			queryParams: {
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	) as ApiResponse<{sessionList: BackendSession[], total: number}>;

	let frontendSessions: InterpreterSalaryMandatePreview[] = [];
	const {sessionList, total} = interpreterResponse.data;
	if (interpreterResponse.isSuccess) {
		frontendSessions = sessionList.map((session): InterpreterSalaryMandatePreview => {
			const {
				id, sessionType, status, fromLanguage, toLanguage,
				formattedAddress, sessionStartTime, sessionCategory, multipleNotifyInterpreters,
				sessionEndTime, channelId, token, secret, locationDetails, price, latitude, longitude,
				owner,
			} = session;
			const address = (sessionType === "INPERSON" || sessionType ===
				"INTERCULTURALMEDIATION" ||
				sessionType === "COURSEMANAGEMENT")
				? formattedAddress
				: null;
			const addressInfos = (sessionType === "INPERSON" || sessionType ===
				"INTERCULTURALMEDIATION" ||
				sessionType === "COURSEMANAGEMENT")
				? locationDetails
				: null;
			const type = transformSessionTypeBackToFront(sessionType);
			const sessionStatus = transformSessionStatus(status);
			const sessionStart = new Date(sessionStartTime);
			const sessionEnd = new Date(sessionEndTime);
			sessionStart.setSeconds(0, 0);
			sessionEnd.setSeconds(0, 0);
			const transformCommunicationRequest: TransformCommunicationRequest = {
				type,
				address,
				lat: latitude,
				lng: longitude,
				channelId,
				token,
				secret,
				phoneNumber: owner && owner.telephone && owner.telephone.length === 9 ? `0${owner.telephone}` : owner.telephone,
				addressInfos,
			};
			const communication: Communication = transformToCommunication(transformCommunicationRequest);
			const financialSession: InterpreterSalaryMandatePreview = ({
				communication,
				activityId: id.toString(),
				type: "interpreterMandate",
				start: sessionStart,
				end: sessionEnd,
				status: sessionStatus,
				language: transformLanguageISOCodeBackToFront(fromLanguage.isoCode),
				toLanguage: transformLanguageISOCodeBackToFront(toLanguage.isoCode),
				immediate: sessionCategory === "IMMEDIATE",
				onlyRecipient: !multipleNotifyInterpreters,
			});
			if (price) {
				financialSession.price = price.priceInterpreter;
			}
			return financialSession;
		});
		return {
			items: frontendSessions,
			hasPreviousPage: offset > 1,
			hasNextPage: total > offset + limit,
			totalCount: total,
		};
	}
	throw new Error(interpreterResponse.message);
};

export const getSalary = async (from: Date, to: Date): Promise<SessionsAccountingSum> => {
	const interpreterResponse = await api(
		"interpreterStatistics",
		"GET",
		{
			pathParams: {
				fromDate: new Date(from.getFullYear(), from.getMonth(), 1).getTime().toString(),
				toDate: to.getTime().toString(),
			},
		},
	) as ApiResponse<InterpreterSalaryResponse>;
	if (interpreterResponse.isSuccess) {
		const {base, increased, reduced, workDuration, sessions, travel, currency} = interpreterResponse.data;
		return ({
			travel,
			currency,
			from,
			base: base || 0,
			increased: increased || 0,
			reduced: reduced || 0,
			workDuration: workDuration || 0,
			sessions: sessions || 0,
			to,
		});
	}
	throw new Error(interpreterResponse.message);
};

export const downloadSalaryPDF = async (from: Date, to: Date, _locale: SupportedLocaleKey): Promise<URL> => {
	const interpreterResponse = await api(
		"interpreterExportStatistics",
		"GET",
		{
			queryParams: {
				fromDate: new Date(from.getFullYear(), from.getMonth(), 1).getTime().toString(),
				toDate: new Date(to.getFullYear(), to.getMonth() + 1, 0, 23, 59, 59).getTime().toString(),
				status: "CANCELLED,COMPLETED,RESCHEDULED",
				exportFormat: "MultipleSession",
				sendPdfMail: "false",
				docType: "PDF",
				email: "false",
				download: "true",
				exportFor: "interpreter",
			},
		},
	) as ApiResponse<{filePath: URL}>;
	if (interpreterResponse.isSuccess) {
		const {filePath} = interpreterResponse.data;
		return filePath;
	}
	throw new Error(interpreterResponse.message);
};

export const downloadSalaryFile = async (
	from: Date, to: Date, fileType: "csv" | "pdf", _locale: SupportedLocaleKey): Promise<URL> => {
	const interpreterResponse = await api(
		"interpreterExportStatistics",
		"GET",
		{
			queryParams: {
				fromDate: new Date(from.getFullYear(), from.getMonth(), 1).getTime().toString(),
				toDate: new Date(to.getFullYear(), to.getMonth() + 1, 0, 23, 59, 59).getTime().toString(),
				status: "CANCELLED,COMPLETED,RESCHEDULED",
				exportFormat: "MultipleSession",
				sendPdfMail: "false",
				docType: fileType.toUpperCase(),
				email: "false",
				download: "true",
				exportFor: "interpreter",
			},
		},
	) as ApiResponse<{filePath: URL}>;
	if (interpreterResponse.isSuccess) {
		return interpreterResponse.data.filePath;
	}
	throw new Error(interpreterResponse.message);
};
