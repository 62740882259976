import * as Notifications from "expo-notifications";
import {infos} from "../infos";
import {CommonNotificationManager, DeviceTokenHolder} from "./manager.common";

export const onNotification = (): void => { /* do nothing */ };

export const configurePushNotifications = (): void => { /* do nothing */ };

// At the moment, notifications are not handled on the web
export const NotificationManager: CommonNotificationManager = {
	appropriatePushChannel: () => "WEB",
	checkPermissions: () => Promise.resolve(false),
	getLastNotificationResponse: () => Promise.resolve(null),
	getThisTargetInfos: () => ({
		channel: NotificationManager.appropriatePushChannel(),
		deletable: true,
		displayed: infos["device.name"],
		id: DeviceTokenHolder.token,
	}),
	requestPermissions: () => Promise.resolve(false),
	subscribeNotification: () => null,
	unsubscribeNotification: (_responseListener?: Notifications.Subscription | null) => null,
	updateBadge: () => Promise.resolve(false),
};
