import i18next from "i18next";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import {initReactI18next} from "react-i18next";
import {deDE} from "../../translations/de-DE/de-de";
import {enUS} from "../../translations/en-US/en-us";
import {frFR} from "../../translations/fr-FR/fr-fr";
import {itIT} from "../../translations/it-IT/it-it";
import {I18N_DEFAULT_LANGUAGE} from "../constants";
import {AsyncStorage} from "../externals/storages/async-storage";
import {Log} from "../logs/logs";
import {SessionLanguagesKey} from "../sessions/languages";
import {ps} from "../switch";
import {contextLocales} from "./context-locales";

export type SupportedLocaleKey = "de-DE" | "en-US" | "fr-FR" | "it-IT";
export const supportedLocales: {[key in SupportedLocaleKey]: string} = {
	"de-DE": "Deutsch",
	"en-US": "English",
	"fr-FR": "Français",
	"it-IT": "Italiano",
};

export class Locales {
	public static currentLocale: SupportedLocaleKey = I18N_DEFAULT_LANGUAGE;

	private static readonly get = (): Promise<SupportedLocaleKey | null> =>
		AsyncStorage.getItem("Locale") as Promise<SupportedLocaleKey | null>;

	private static readonly normalize = (locale: string): SupportedLocaleKey => {
		if (!locale || typeof locale !== "string") {
			return I18N_DEFAULT_LANGUAGE;
		}
		// match the language to the related locale "en-GB" -> "en-US"
		return ps(Locales.localeToLanguage(locale as SupportedLocaleKey), {
			de: "de-DE",
			default: I18N_DEFAULT_LANGUAGE,
			en: "en-US",
			fr: "fr-FR",
			it: "it-IT",
		});
	};

	public static set = (locale: SupportedLocaleKey): Promise<void> =>
		AsyncStorage.setItem("Locale", locale).then(() => Locales.setLocale(locale));

	public static localeToLanguage = (locale: SupportedLocaleKey): string =>
		locale.slice(0, 2); // (ex: en-US => en)

	public static compare = (a: string, b: string): number => a.localeCompare(
		b,
		Locales.localeToLanguage(Locales.currentLocale),
		{sensitivity: "accent"},
	);

	public static init = (): void => {
		/*
		 * Mappings of each namespace for each locale. It's an object for each locale that contains all the namespaces
		 * as keys, and as value, the translation for the namespace.
		 * As the enUS object holds all the translations grouped by namespaces, its structure is already the right one.
		 */
		const resources: {[_ in SupportedLocaleKey]: typeof enUS} = {
			"de-DE": deDE,
			"en-US": enUS,
			"fr-FR": frFR,
			"it-IT": itIT,
		};

		/*
		 * The names of the namespaces for the translations.
		 * They're the same as enUS keys since it holds all the translations grouped by namespace.
		 */
		const namespaces: (keyof typeof enUS)[] = [
			"activities",
			"common",
			"commonSentences",
			"feedbacks",
			"forms",
			"languages",
			"origins",
			"screens",
			"users",
		];

		i18next
			.use(initReactI18next)
			.init({
				compatibilityJSON: "v3",
				fallbackLng: "en-US",
				interpolation: {
					escapeValue: false, // React already safe from xss (advised to do so in react-i18next getting started page)
				},
				ns: namespaces,
				resources,
			}).then(() => Locales.get().then((localeFromStorage) => {
				const locale = Locales.normalize(localeFromStorage ?? contextLocales()[0]?.localeKey);
				Locales.setLocale(locale);
				if (!localeFromStorage) {
					Locales.set(locale).catch(Log.error());
				}
			})).catch(Log.error());
	};

	private static readonly setLocale = (locale: SupportedLocaleKey): void => {
		Locales.currentLocale = locale;
		i18next.changeLanguage(locale).catch(Log.error());
		moment.locale(Locales.localeToLanguage(locale));
	};
}

// TODO: those two functions should not exist, we should think about merging locales & session languages
export const localeToSessionLanguage = (locale: SupportedLocaleKey): SessionLanguagesKey =>
	ps(locale, {
		"de-DE": "german",
		default: "english",
		"fr-FR": "french",
		"it-IT": "italian",
	});
export const sessionLanguageToLocale = (languageKey: SessionLanguagesKey): SupportedLocaleKey =>
	ps(languageKey, {
		default: "en-US",
		french: "fr-FR",
		german: "de-DE",
		italian: "it-IT",
	});
