import {ViewStyle} from "react-native";
import {Place} from "../../@types/places";
import {placesExamples} from "../../utils/development/fake/places/data";
import {SUBTLE} from "../../utils/styles/colors";

export const loadingBackgroundColor = SUBTLE;
export const defaultMapHeight = 180;
export const defaultPlace: Place = placesExamples[0];
export const defaultZoom = 11;

export interface MapsProps {
	height?: number;
	place?: Place;
	style?: ViewStyle;
	zoom?: number;
}

export const mapStyle: ViewStyle = {
	backgroundColor: loadingBackgroundColor,
	height: defaultMapHeight,
	justifyContent: "center",
	overflow: "hidden",
	position: "relative",
	width: "100%",
};

export {SUBTLE_4 as loadingIndicatorColor} from "../../utils/styles/colors";
