import {LinkingOptions} from "@react-navigation/native";
import {
	ClientAuthentifiedScreensGroupParamList,
	ClientHomeTabNavigatorParamList,
} from "../paramLists/client-param-list";
import {modalPaths} from "./modal-paths";

export const clientPaths: {[Key in keyof Omit<ClientAuthentifiedScreensGroupParamList, "HomeTabNavigator">]: string} = {
	AddressesListModal: modalPaths.AddressesListModal,
	CancelOrRescheduleSessionModal: modalPaths.CancelOrRescheduleSessionModal,
	EditBillingAddressModal: modalPaths.EditBillingAddressModal,
	EditCorporationModal: modalPaths.EditCorporationModal,
	EditPaymentAuthorityModal: modalPaths.EditPaymentAuthorityModal,
	EditPersonModal: modalPaths.EditPersonModal,
	EditSessionCategoryModal: modalPaths.EditSessionCategoryModal,
	EditSubjectSuggestionModal: modalPaths.EditSubjectSuggestionModal,
	FeedbackModal: modalPaths.FeedbackModal,
	Interpreter: "interpreter",
	InterpreterModal: modalPaths.InterpreterModal,
	SearchInterpretersModal: modalPaths.SearchInterpretersModal,
	SelectBillingAddressModal: modalPaths.SelectBillingAddressModal,
	SelectDurationModal: modalPaths.SelectDurationModal,
	SelectPaymentAuthorityModal: modalPaths.SelectPaymentAuthorityModal,
	SelectSessionCategoryModal: modalPaths.SelectSessionCategoryModal,
	SelectSubjectSuggestionModal: modalPaths.SelectSubjectSuggestionModal,
	Session: "session/:sessionId",
	SessionCreationFiltersModal: modalPaths.SessionCreationFiltersModal,
};

export const clientHomeTabPaths = {
	HomeTabNavigator: {
		screens: {
			Calendar: "/calendar",
			ClientAccount: "/account",
			Interpreters: "/interpreters",
			NewSession: "/new-session",
		},
	},
} satisfies {[Key in keyof Pick<ClientAuthentifiedScreensGroupParamList, "HomeTabNavigator">]: LinkingOptions<ClientHomeTabNavigatorParamList>["config"]};
