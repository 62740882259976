import * as React from "react";
import {
	ActivityIndicator,
	Falsy,
	GestureResponderEvent,
	Keyboard,
	StyleProp,
	StyleSheet,
	View,
	ViewProps,
	ViewStyle,
} from "react-native";
import {PRIMARY, PRIMARY_2, RED, SUBTLE_2, TRANSPARENT, WHITE} from "../../utils/styles/colors";
import {s} from "../../utils/switch";
import {Icon, IconKey} from "../icons";
import {Label} from "../labels/label";
import {Spacer} from "../spacer";
import {Text, TextProps} from "../texts/text";
import {Pressable, PressableProps} from "../views/pressable";

export type Buttons = (Falsy | ButtonProps & {key: string})[];

export interface ButtonProps extends PressableProps {
	active?: boolean;
	align?: "center" | "left" | "right";
	allowFontScaling?: boolean;
	backgroundActiveColor?: string;
	backgroundColor?: string;
	children?: React.ReactNode;
	contentActiveColor?: string;
	contentColor?: string;
	fullWidth?: boolean;
	icon?: IconKey;
	iconHitSlop?: ViewProps["hitSlop"];
	iconPosition?: "after" | "before";
	innerActiveStyle?: StyleProp<ViewStyle>;
	innerStyle?: StyleProp<ViewStyle>;
	label?: string;
	labelProps?: TextProps;
	notif?: number;
	notifBackgroundColor?: string;
	notifColor?: string;
	onPress?: ((event: GestureResponderEvent) => (Promise<unknown> | null | void));
	onPressIcon?: (event: GestureResponderEvent) => (Promise<unknown> | void);
	size?: "large" | "medium" | "small" | "xsmall" | "xxsmall";
	spaceBetween?: boolean;
	text?: string;
	textProps?: TextProps;
	type?: "blank" | "primary" | "secondary";
}

export const Button = ({
	active: activeProp,
	size = "medium",
	type = "primary",
	icon,
	iconHitSlop,
	iconPosition = "before",
	onPressIcon,
	text,
	notif = 0,
	notifColor = WHITE,
	notifBackgroundColor = RED,
	label,
	fullWidth = false,
	innerStyle,
	innerActiveStyle,
	contentColor,
	contentActiveColor,
	backgroundColor: backgroundColorProp,
	backgroundActiveColor,
	spaceBetween,
	labelProps,
	disabled,
	style,
	children,
	onPress: onPressProp,
	align = "center",
	allowFontScaling = true,
	textProps,
	...props
}: ButtonProps): JSX.Element => {
	const [active, setActive] = React.useState(activeProp);
	const [pressed, setPressed] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const mounted = React.useRef(false);
	const lastPress = React.useRef(0);

	React.useEffect(
		() => setActive(activeProp || pressed),
		[activeProp, pressed],
	);
	React.useEffect(
		() => {
			mounted.current = true;
			return () => { mounted.current = false; };
		},
		[],
	);

	const color = React.useMemo(
		() => active && contentActiveColor
			? contentActiveColor
			: contentColor ?? (active && type !== "primary"
				? type === "blank"
					? PRIMARY
					: WHITE
				: type === "primary"
					? WHITE
					: type === "blank"
						? PRIMARY_2
						: PRIMARY_2),
		[active, contentActiveColor, contentColor, type],
	);

	const backgroundColor = React.useMemo(
		() => active
			? backgroundActiveColor ?? (type === "primary"
				? PRIMARY_2
				: type === "blank"
					? TRANSPARENT
					: PRIMARY_2)
			: backgroundColorProp ?? (type === "primary"
				? PRIMARY
				: type === "blank"
					? TRANSPARENT
					: SUBTLE_2),
		[active, backgroundActiveColor, backgroundColorProp, type],
	);

	const onPressIn = React.useCallback(
		() => setPressed(true),
		[setPressed],
	);

	const onPressOut = React.useCallback(
		() => setPressed(false),
		[setPressed],
	);

	const actionCompleted = React.useCallback(
		(arg: unknown) => {
			if (mounted.current) {
				setLoading(false);
			}
			return arg;
		},
		[],
	);

	const onPress = React.useCallback(
		(event: GestureResponderEvent) => {
			// Throttle the onPress
			const now = Date.now();
			if (!loading && now - lastPress.current >= 400) {
				Keyboard.dismiss(); // Because the keyboard behaviors are strange
				lastPress.current = now;
				if (onPressProp) {
					const ret = onPressProp(event);
					// If onPress returned something, wait on it and then unlock the button
					if (ret !== undefined) {
						setLoading(true);
						Promise.resolve(ret).then(
							actionCompleted,
							actionCompleted, // Also unlock the button if the promise was rejected
						);
					}
				}
			}
		},
		[actionCompleted, loading, onPressProp],
	);

	const renderIcon = React.useCallback(
		(position: ButtonProps["iconPosition"]) => icon
			? iconPosition === position && (
				<>
					{position === "after" && !!text && <Spacer size={size === "medium" ? 6 : 4} mode="vertical"/>}
					<Icon
						icon={icon}
						color={color}
						size={type === "blank"
							? s(size, {large: 34, medium: 30, small: 28, xsmall: 23, xxsmall: 18})
							: s(size, {large: 28, medium: 24, small: 22, xsmall: 17, xxsmall: 12})}
						containerSize={s(size, {large: 28, medium: 24, small: 22, xsmall: 17, xxsmall: 12})}
						onPress={onPressIcon}
						hitSlop={iconHitSlop}
					/>
					{position === "before" && !!text && <Spacer size={size === "medium" ? 6 : 4} mode="vertical"/>}
				</>
			)
			: null,
		[color, icon, iconHitSlop, iconPosition, onPressIcon, size, text, type],
	);

	const getPadding = React.useCallback(
		(small: boolean) => size === "medium"
			? small
				? 12
				: 18
			: small
				? 7
				: 9,
		[size],
	);

	return (
		<Pressable
			onPressIn={onPressIn}
			onPressOut={onPressOut}
			activeOpacity={1}
			disabled={!onPressProp || (disabled ?? loading)}
			hitSlop={{bottom: 4, left: 4, right: 4, top: 4}}
			{...props}
			onPress={onPress}
			style={[styles.wrapper, fullWidth && {alignSelf: "stretch", width: "100%"}, disabled && {opacity: 0.66}, style]}
		>
			<>
				<View
					style={[
						styles.container,
						styles[size],
						{
							backgroundColor,
							justifyContent: align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center",
							paddingLeft: getPadding(!text || (Boolean(icon) && iconPosition === "before")),
							paddingRight: getPadding(!text || (Boolean(icon) && iconPosition === "after")),
						},
						spaceBetween && {justifyContent: "space-between"},
						fullWidth && {alignSelf: "stretch", width: "100%"},
						innerStyle,
						active && innerActiveStyle,
					]}
				>
					{loading
						? <ActivityIndicator color={color}/>
						: (
							<>
								{renderIcon("before")}
								{!!text && (
									<Text
										type={size === "medium" ? "button_1" : "button_2"}
										style={[
											{textAlign: align},
											styles.text,
										]}
										color={color}
										allowFontScaling={allowFontScaling}
										selectable={false}
										{...textProps}
									>
										{text}
									</Text>
								)}
								{children}
								{renderIcon("after")}
								{notif > 0 && (
									<View
										style={[
											styles.notifContainer,
											{left: type === "blank" ? -14 : -4, top: size === "medium" ? -26 : -24},
										]}
									>
										<Label
											style={styles.notif}
											size="small"
											backgroundColor={notifBackgroundColor}
											color={notifColor}
											text={notif}
											allowFontScaling={allowFontScaling}
										/>
									</View>
								)}
							</>
						)}
				</View>
				{!!label && (
					<Text
						type="emphasis_2"
						allowFontScaling={allowFontScaling}
						selectable={false}
						color={color}
						{...labelProps}
						centered
						style={[styles.label, type === "blank" && {top: -8}, labelProps?.style]}
					>
						{label}
					</Text>
				)}
			</>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		alignSelf: "center",
		flexDirection: "row",
		maxWidth: "100%",
		overflow: "visible",
		paddingVertical: 6,
	},
	label: {
		marginTop: 4,
	},
	// Used by `styles[size]`
	// eslint-disable-next-line react-native/no-unused-styles
	large: {
		borderRadius: 30,
		minHeight: 60,
		minWidth: 60,
	},
	// Used by `styles[size]`
	// eslint-disable-next-line react-native/no-unused-styles
	medium: {
		borderRadius: 24,
		minHeight: 48,
		minWidth: 48,
	},
	notif: {
		alignSelf: "flex-start",
		position: "absolute",
		top: 0,
	},
	notifContainer: {
		overflow: "visible",
		position: "relative",
	},
	// Used by `styles[size]`
	// eslint-disable-next-line react-native/no-unused-styles
	small: {
		borderRadius: 18,
		minHeight: 36,
		minWidth: 36,
	},
	text: {
		maxWidth: "100%",
		paddingHorizontal: 2,
	},
	wrapper: {
		alignSelf: "center",
		flexDirection: "column",
		maxWidth: "100%",
		overflow: "visible",
	},
	// eslint-disable-next-line react-native/no-unused-styles
	xsmall: {
		borderRadius: 15,
		minHeight: 30,
		minWidth: 30,
	},
	// eslint-disable-next-line react-native/no-unused-styles
	xxsmall: {
		borderRadius: 12,
		minHeight: 24,
		minWidth: 24,
	},
});
