import * as React from "react";
import {GestureResponderEvent, StyleSheet, View} from "react-native";
import {Session} from "../../@types/activities/session";
import {SMALL_SPACING} from "../../utils/constants";
import {Icon} from "../icons";
import {Image} from "../images/image";
import {Text} from "../texts/text";

interface ContextBoxProps {
	backgroundColor?: string;
	color?: string;
	onPressReset?: (event: GestureResponderEvent) => void;
	picture?: Session["providers"][0]["picture"];
	subtitle?: string;
	title: string;
}

const PICTURE_SIZE = 50;
export const ContextBox = ({backgroundColor, color, onPressReset, picture, subtitle, title}: ContextBoxProps): JSX.Element =>
	(<View style={[
		styles.container,
		!!backgroundColor && {backgroundColor},
	]}>
		{!!picture && (<Image
			source={picture.source}
			fullHeight={picture.fullHeight}
			mime={picture.mime}
			sourceType={picture.sourceType}
			fullWidth={picture.fullWidth}
			resizeMode={picture.resizeMode}
			width={PICTURE_SIZE}
			height={PICTURE_SIZE}
			style={styles.avatarImageContainer}
		/>)}
		<View style={styles.texts}>
			<Text type="emphasis_1" color={color} style={!!subtitle && {marginBottom: 4}}>
				{title}
			</Text>
			{
				subtitle &&
				<Text type="label" color={color}>
					{subtitle}
				</Text>
			}
		</View>
		{onPressReset && <Icon icon="close" onPress={onPressReset} color={color} />}
	</View>);

const styles = StyleSheet.create({
	avatarImageContainer: {
		borderRadius: PICTURE_SIZE / 2,
		marginRight: SMALL_SPACING,
	},
	container: {
		alignItems: "center",
		borderRadius: SMALL_SPACING,
		flexDirection: "row",
		margin: SMALL_SPACING,
		minHeight: 18,
		minWidth: 18,
		padding: SMALL_SPACING,
	},
	texts: {
		flex: 1,
		flexDirection: "column",
		marginRight: SMALL_SPACING,
	},
});
