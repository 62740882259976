import * as React from "react";
import {Pressable, View} from "react-native";
import {Tooltip, TooltipProps} from "./tooltip";

export type TooltipWrapperProps = Pick<TooltipProps, "content" | "offset">;

export const TooltipWrapperMobile = ({content, offset, children}: React.PropsWithChildren<TooltipWrapperProps>): JSX.Element => {
	const [isOpen, setIsOpen] = React.useState(false);
	const wrapperRef = React.useRef<View>(null);

	const toggleOpen = React.useCallback(
		() => {
			setIsOpen(prev => !prev);
		},
		[],
	);

	const onPressOut = React.useCallback(
		() => {
			setIsOpen(false);
		},
		[],
	);

	return (
		<Pressable onPress={toggleOpen} ref={wrapperRef}>
			{children}
			<Tooltip
				content={content}
				offset={offset}
				isOpen={isOpen}
				wrapperRef={wrapperRef}
				onPressOut={onPressOut}
			/>
		</Pressable>
	);
};
