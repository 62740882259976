import {Account} from "../../@types/accounts";
import {CommunicationType} from "../../@types/activities/session";
import {Interpreter} from "../../@types/identities/interpreter";
import {ImageMime, URLImage} from "../../@types/medias/image";
import {Origin} from "../../navigation/screens/common/modals/select-origin";
import {Transport} from "../../navigation/screens/interpreter/modals/select-transport";
import {RequestTokenHolder} from "../../utils/auth";
import {API_HOST, HTTP_PROTOCOL, IS_WEB} from "../../utils/constants";
import {Images} from "../../utils/externals/images";
import {PickerImage} from "../../utils/inputs/pickers/image-picker";
import {api} from "../../utils/network/api";
import {ApiResponse} from "../../utils/network/api-response";
import {BackendGender, BackendTransport, ProfilePictureProps} from "../@types/interpreter";
import {DynamicField} from "../@types/organisation";
import {BackendStatus} from "../@types/session";
import {CombinedAvailabilities} from "../common/availabilities";
import {interpreterQualificationBackToFront, transformLanguageISOCodeBackToFront} from "../utils/transform";
import {transformGenderBackToFront, transformGenderFrontToBack} from "../utils/transform-common";

export type Type = "base64" | "require" | "url";
export type Language = {
	id?: number,
	name?: string,
	isoCode: string,
	frenchName?: string,
	germanName?: string,
	italianName?: string,
};
export type LanguagesSkill = {
	id?: number,
	languageOne: Language,
	languageTwo: Language,
};
type Country = {
	iso: string,
	name?: string,
	frenchName?: string,
	germanName?: string,
};
export type QualificationSkill = {
	id?: number,
	isSelected?: boolean,
	label: string,
	isFixedQualificationEnabled?: boolean,
};
export type BackendInterpreter = {
	wave?: "ONE" | "TWO",
	latitude?: number,
	longitude?: number,
	id: string,
	firstName: string,
	lastName: string,
	inPersonAvailability?: boolean,
	mediationAvailability?: boolean,
	interpreterAvailability?: CombinedAvailabilities,
	videoCallAvailability?: boolean,
	voiceCallAvailability?: boolean,
	courseAvailability?: boolean,
	travelMode: BackendTransport,
	notifySessionBySms: boolean,
	notifySessionByMail: boolean,
	country: Country,
	email: string,
	formattedAddress: string,
	city: string,
	fullName: string,
	telephone: string,
	countryCode: string,
	gender: BackendGender,
	isActive: boolean,
	languagesSkills: LanguagesSkill[],
	qualificationSkill: QualificationSkill[],
	profilePicName: string,
	sessionsNotifs: number,
	billingTravelCost?: number,
	idNumber?: string,
	credentials?: {
		userId: string,
	},
	dynamicFields?: DynamicField[],
	emailVerified?: boolean,
	role?: string,
	interpreterQualificationAndModuleData: {[section: string]: string[]},
	status: BackendStatus,
	dob?: string,
	description?: string,
};
export type InterpreterRequests = {
	GET: {
		pathParams: {
			interpreterId: string,
		},
	},
	PUT: {
		body: Pick<Partial<BackendInterpreter>,
		"country" | "email" | "firstName" | "formattedAddress" | "gender" | "languagesSkills" | "lastName" | "notifySessionByMail" | "notifySessionBySms" | "qualificationSkill" | "telephone" | "travelMode">,
		pathParams: {
			interpreterId: string,
		},
	},
};
export type InterpreterAvailabilitiesRequests = {
	GET: {},
};
export type InterpreterProfilePictureUpdateRequests = {
	POST: {
		pathParams: {
			personId: string,
		},
		body: FormData,
	},
};
export type InterpreterProfilePictureGetRequests = {
	GET: {},
};
export type GetInterpreterResponse = BackendInterpreter;
export type UpdateInterpreterResponse = BackendInterpreter;
type InterpreterAvailabilitiesResponse = {
	inPersonAvailability: boolean,
	videoCallAvailability: boolean,
	voiceCallAvailability: boolean,
	mediationAvailability: boolean,
	courseAvailability: boolean,
};

export const getInterpreterAvailabilities = async (): Promise<InterpreterAvailabilitiesResponse> => {
	const interpreterResponse = await api(
		"interpreterAvailabilities",
		"GET",
		{},
	) as ApiResponse<InterpreterAvailabilitiesResponse>;
	return interpreterResponse.data;
};

export const getInterpreterProfilePicture = async (): Promise<URLImage> => {
	const interpreterResponse = await api(
		"interpreterProfilePictureGet",
		"GET",
		{},
	) as ApiResponse<ProfilePictureProps>;
	const {profilePicName, height, width, mime} = interpreterResponse.data;

	return {
		height,
		width,
		mime,
		source: profilePicName,
		sourceType: "url",
		mediaType: "image",
	};
};

export const getInterpreter = async (interpreterAccountId: Account["accountId"]): Promise<Interpreter> => {
	const interpreterResponse = await api(
		"interpreter",
		"GET",
		{pathParams: {interpreterId: interpreterAccountId}},
	) as ApiResponse<GetInterpreterResponse>;
	if (interpreterResponse.isSuccess) {
		const {
			firstName, lastName, formattedAddress: address, telephone, email, travelMode,
			languagesSkills, profilePicName, country, gender, interpreterQualificationAndModuleData,
			latitude, longitude, inPersonAvailability, voiceCallAvailability, videoCallAvailability,
			courseAvailability, mediationAvailability,
		} = interpreterResponse.data;
		const communicationTypes: CommunicationType[] = [];
		if (inPersonAvailability) {
			communicationTypes.push("inPerson");
		}
		if (voiceCallAvailability) {
			communicationTypes.push("phone");
		}
		if (videoCallAvailability) {
			communicationTypes.push("video");
		}
		if (courseAvailability && !communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		if (mediationAvailability && !communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		const qualifications: Interpreter["qualifications"] = interpreterQualificationBackToFront(interpreterQualificationAndModuleData);
		return {
			communicationTypes,
			qualifications,
			identityId: interpreterAccountId,
			accountId: interpreterAccountId,
			providedServices: ["standard", "mediation", "education"],
			type: "interpreter",
			place: {
				address,
				geocode: {
					lat: latitude,
					lng: longitude,
				},
			},
			fullName: {
				firstName,
				lastName,
			},
			email,
			gender: transformGenderBackToFront(gender),
			transport: travelMode === "TRANSIT" ? "Public" as Transport : "Private" as Transport,
			languages: languagesSkills
				.map(skill => ({
					from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
					to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
				})),
			phone: telephone,
			picture: profilePicName
				? {
					mime: `image/${profilePicName.slice(profilePicName.lastIndexOf(".") + 1,
						profilePicName.lastIndexOf("?"))}` as ImageMime,
					sourceType: "url" as Type,
					mediaType: "image",
					source: profilePicName,
				}
				: {
					mime: "image/png",
					sourceType: "require",
					mediaType: "image",
					source: Images.Avatar,
				},
			origin: country?.iso as Origin,
		};
	}
	throw new Error(interpreterResponse.interpreterMessage);
};

export const updateInterpreter = async (
	interpreterId: Interpreter["identityId"],
	updatedInterpreterProperties: Omit<Partial<Interpreter>, "picture">,
): Promise<Interpreter> => {
	let interpreterTransport: BackendTransport;
	if (updatedInterpreterProperties.transport === "Public") {
		interpreterTransport = "TRANSIT";
	} else if (updatedInterpreterProperties.transport === "Private") {
		interpreterTransport = "DRIVING";
	}
	const response = await api(
		"interpreter",
		"PUT",
		{
			pathParams: {
				interpreterId,
			},
			body: {
				firstName: updatedInterpreterProperties.fullName && updatedInterpreterProperties.fullName.firstName,
				lastName: updatedInterpreterProperties.fullName && updatedInterpreterProperties.fullName.lastName,
				email: updatedInterpreterProperties.email,
				country: {iso: updatedInterpreterProperties.origin},
				gender: transformGenderFrontToBack(updatedInterpreterProperties.gender),
				travelMode: interpreterTransport,
				formattedAddress: updatedInterpreterProperties.place && updatedInterpreterProperties.place.address,
				telephone: updatedInterpreterProperties.phone,
			},
		},
	) as ApiResponse<UpdateInterpreterResponse>;

	if (response.isSuccess) {
		const {
			firstName, lastName, formattedAddress: address, email, travelMode,
			languagesSkills, country, gender, id, telephone, latitude, longitude, inPersonAvailability,
			voiceCallAvailability, videoCallAvailability, courseAvailability, mediationAvailability,
			interpreterQualificationAndModuleData, profilePicName,
		} = response.data;
		const communicationTypes: CommunicationType[] = [];
		if (inPersonAvailability) {
			communicationTypes.push("inPerson");
		}
		if (voiceCallAvailability) {
			communicationTypes.push("phone");
		}
		if (videoCallAvailability) {
			communicationTypes.push("video");
		}
		if (courseAvailability && !communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		if (mediationAvailability && !communicationTypes.includes("inPerson")) {
			communicationTypes.push("inPerson");
		}
		const qualifications: Interpreter["qualifications"] = interpreterQualificationBackToFront(interpreterQualificationAndModuleData);
		return {
			qualifications,
			communicationTypes,
			identityId: id,
			accountId: id,
			providedServices: ["standard", "mediation", "education"],
			type: "interpreter",
			place: {
				address,
				geocode: {
					lat: latitude,
					lng: longitude,
				},
			},
			fullName: {
				firstName,
				lastName,
			},
			email,
			gender: transformGenderBackToFront(gender),
			transport: travelMode === "TRANSIT" ? "Public" as Transport : "Private" as Transport,
			languages: languagesSkills
				.map(skill => ({
					from: transformLanguageISOCodeBackToFront(skill.languageOne.isoCode),
					to: transformLanguageISOCodeBackToFront(skill.languageTwo.isoCode),
				})),
			picture: profilePicName
				? {
					mime: `image/${profilePicName.slice(profilePicName.lastIndexOf(".") + 1,
						profilePicName.lastIndexOf("?"))}` as ImageMime,
					sourceType: "url" as Type,
					mediaType: "image",
					source: profilePicName,
				}
				: {
					mime: "image/png",
					sourceType: "require",
					mediaType: "image",
					source: Images.Avatar,
				},
			phone: telephone.replace(/ +/g, ""),
			origin: country?.iso as Origin,
		};
	}
	throw new Error(response.interpreterMessage);
};

export const updateInterpreterPicture = async (
	interpreterId: Interpreter["identityId"], picture: PickerImage,
): Promise<void> => {
	let profileName;
	switch (picture.mime) {
		case "image/jpeg": {
			profileName = "profile_pic.jpeg";
			break;
		}
		case "image/png": {
			profileName = "profile_pic.png";
			break;
		}
		case "image/gif": {
			profileName = "profile_pic.gif";
			break;
		}
		default:
	}
	const formData = new FormData();
	formData.append(
		"file",
		IS_WEB
			? picture.blob
			: {
				uri: picture.path,
				type: picture.mime,
				name: profileName,
			} as any as Blob,
	);
	const url = `${HTTP_PROTOCOL}://${API_HOST}/v4/person/${interpreterId}/profile-pic`;
	const response = await fetch(url, {
		method: "POST",
		body: formData,
		headers: {
			"x-xsrf-token": RequestTokenHolder.token,
		},
	});
	if (!response.ok) {
		throw new Error("File size is too large");
	}
};
