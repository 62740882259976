import React from "react";
import {Communication, CommunicationType, communicationTypes} from "../../@types/activities/session";
import {SessionCommunicationSetting} from "../../@types/settings";
import {AuthentifiedContext} from "../../contexts/authentified";
import {getEntries} from "../objects";

const useExcludedCommunicationTypes = (excludedCommunicationTypes: Communication["type"][] = []): {excludedCommunicationTypes: CommunicationType[] | null} => {
	const {settings: {getSetting, loading}} = React.useContext(AuthentifiedContext);
	if (loading) return {excludedCommunicationTypes: null};
	const communicationTypesSetting =
		getSetting<SessionCommunicationSetting>("session/communication", "organization/all")?.value || {
			inPerson: true,
			phone: true,
			video: true,
		};
	const filteredExcludedCommunicationTypes: CommunicationType[] = [];
	getEntries(communicationTypesSetting).forEach(([key, isAuthorized]) => {
		if (!isAuthorized || excludedCommunicationTypes.includes(key)) {
			filteredExcludedCommunicationTypes.push(key);
		}
	});
	return {excludedCommunicationTypes: filteredExcludedCommunicationTypes};
};

export const useCommunicationTypes = (excludedCommunicationTypes: Communication["type"][] = []): CommunicationType[] => {
	const {excludedCommunicationTypes: types} = useExcludedCommunicationTypes(excludedCommunicationTypes);
	return communicationTypes
		.filter(communicationType => !types?.includes(communicationType));
};
