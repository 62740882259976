import {useNavigation} from "@react-navigation/native";
import React from "react";
import {
	FlatList,
	GestureResponderEvent,
	ListRenderItemInfo,
	StyleSheet,
	ViewabilityConfig,
	ViewToken,
} from "react-native";
import {CalendarActivityPreview} from "../../@types/activities/activity";
import {
	ActivitiesContext,
	BOTTOM_ITEM_SPACING,
	BOTTOM_SECTION_SPACING,
	CalendarItem,
	TOP_SECTION_SPACING,
} from "../../contexts/activities";
import {ResponsiveContext} from "../../contexts/responsive";
import {CalendarScreenProps} from "../../navigation/screens/common/modals/calendar";
import {getClientDaysActivities} from "../../requests/clients/activities/day";
import {getInterpreterDaysActivities} from "../../requests/interpreters/activities/day";
import {DEFAULT_SPACING, IS_INTERPRETER} from "../../utils/constants";
import {formatDayKey} from "../../utils/date-time/format";
import {compareDays, consecutiveDays, dayKeyToDate} from "../../utils/date-time/helpers";
import {Debouncer} from "../../utils/debouncer";
import {Log} from "../../utils/logs/logs";
import {StackNavigationProp} from "../../utils/navigation/paramLists/root-param-list";
import {flatListDefaultProps} from "../../utils/scrollables";
import {SUBTLE} from "../../utils/styles/colors";
import {CollapsedDates} from "../list/items/collapsed-dates";
import {ListEmpty} from "../list/items/list-empty";
import {Spacer} from "../spacer";
import {SplashView} from "../views/splash-view";
import {ActivityPlaceholder} from "./activity-placeholder";
import {WeekCalendar} from "./calendar-week";
import {DayActivity} from "./day-activity";
import {SectionHeader} from "./section-header";

interface Props {
	getDaysData?: typeof getInterpreterDaysActivities;
	onActivityPress?: CalendarScreenProps["onActivityPress"];
	selectedActivity?: CalendarScreenProps["selectedActivity"];
}

export const DefaultActivities = ({
	getDaysData = IS_INTERPRETER ? getInterpreterDaysActivities : getClientDaysActivities,
	onActivityPress,
	selectedActivity,
}: Props): JSX.Element => {
	const navigation = useNavigation<StackNavigationProp<"CalendarModal">>();
	const {columns} = React.useContext(ResponsiveContext);
	const {
		activities,
		activitiesCache,
		activitiesFetching,
		focusDate,
		focusIndex,
		setFocusDate,
		list,
		transitionning,
		filters,
	} = React.useContext(ActivitiesContext);
	const [render, setRender] = React.useState<number | null>(null);
	const flatListRef = React.useRef<FlatList<CalendarItem>>(null);
	const viewableItemsRef = React.useRef<ViewToken[]>();
	const reRenderDebouncerRef = React.useRef(new Debouncer());
	const filtersRef = React.useRef(filters);
	const viewabilityConfig: ViewabilityConfig = {
		minimumViewTime: 60, // Avoids a scrollTo to trigger the fetch on all rows in between
		viewAreaCoveragePercentThreshold: 5, // Also determines which is the active date since it's the first viewable
		waitForInteraction: false,
	};
	const onPress = React.useCallback(
		(activity: CalendarActivityPreview) => (event: GestureResponderEvent) => {
			const {screen, params} = activity.type === "unavailability"
				? ({
					params: {unavailabilityId: activity.activityId},
					screen: "UnavailabilityModal" as const,
				})
				: ({
					params: {sessionId: activity.activityId},
					screen: "SessionModal" as const,
				});
			return onActivityPress
				? onActivityPress(activity)(event)
				: navigation.push(screen, params);
		},
		[navigation, onActivityPress],
	);
	const keyExtractor = React.useCallback(
		(item: CalendarItem) => item.key,
		[],
	);
	const getItemLayout = React.useCallback(
		(data: CalendarItem[] | null | undefined, index: number) => ({
			index,
			length: data?.[index]?.length ?? 0,
			offset: data?.[index]?.offset ?? 0,
		}),
		[],
	);
	const getFetchDates = (items: ViewToken[], beforeOffset = 6, afterOffset = 6): Date[] => {
		// Ref can be null in some cases, for example when closing the session search modal
		if (!flatListRef.current) {
			return [];
		}

		/*
		 * The list state is not going to be available because it's called inside useRef.
		 * Use `flatListRef.current.props.data` instead
		 */
		// eslint-disable-next-line unicorn/prefer-spread
		const listData = flatListRef.current.props.data ? Array.from(flatListRef.current.props.data) : undefined;
		const firstIndex = items[0].index;
		const lastIndex = items[items.length - 1].index;
		if (!listData || firstIndex === null || lastIndex === null) {
			return [];
		}
		const expectedFirstIndex = firstIndex - beforeOffset;
		const expectedLastIndex = lastIndex + afterOffset;
		const start = expectedFirstIndex < 0 ? 0 : expectedFirstIndex;
		const end = expectedLastIndex > listData.length - 1 ? listData.length : expectedLastIndex + 1;
		return listData.slice(start, end)
			.filter(({key}) => (
				(
					activitiesCache.current[key].state === "unfetched" ||
					activitiesCache.current[key].state === "stale"
				) &&
				// Only fetch days where there is something to fetch
				activitiesCache.current[key].activities.length > 0
			))
			.map(({key}) => {
				activitiesCache.current[key].state = "fetching";
				return dayKeyToDate(key);
			});
	};
	const fetchViewableItems = React.useRef(() => {
		new Promise((resolve) => {
			if (viewableItemsRef.current === undefined || viewableItemsRef.current?.length < 1) {
				return resolve(false);
			}
			const datesToFetch = getFetchDates(viewableItemsRef.current);
			if (datesToFetch.length === 0) {
				return resolve(false);
			}
			getDaysData(datesToFetch, filtersRef.current)
				.then((daysData) => {
					datesToFetch.forEach((date) => {
						const dayKey = formatDayKey(date);
						const dayData = daysData[dayKey];
						if (!dayData) {
							Log.error()(
								"Missing day data in activities",
								{
									date,
									datesToFetch,
									fetchedActivities: daysData,
									filters: filtersRef.current,
									initialActivities: activitiesCache.current[dayKey],
									key: dayKey,
								},
							);
							activitiesCache.current[dayKey].state = "failed";
						} else if (activitiesCache.current[dayKey].activities.length !== dayData?.length) {
							Log.warning()(
								"Initial activities & fetched activities do not have the same amount of activities",
								{
									fetchedActivities: daysData,
									initialActivities: activitiesCache.current[dayKey],
									key: dayKey,
								},
							);
						}
						if (dayData) {
							activitiesCache.current[dayKey].state = "fetched";
							activitiesCache.current[dayKey].activities = dayData;
						}
					});
				})
				.catch(error => {
					Log.error()(error);
					datesToFetch.forEach(day => {
						activitiesCache.current[formatDayKey(day)].state = "failed";
					});
				})
				.finally(() => resolve(true));
		})
			.then((cacheUpdated) => {
				reRenderDebouncerRef.current.debounce(
					() => {
						if (transitionning.current) {
							transitionning.current = false;
						} else if (viewableItemsRef.current?.[0]) {
							// We don't want to update the focus date during transitions, so it's in an else if
							setFocusDate.current(dayKeyToDate(viewableItemsRef.current[0].key), false);
						}
						if (cacheUpdated) {
							setRender(Date.now());
						}
					},
					256,
				);
			})
			.catch(Log.error());
	});
	const handleViewableItemsChanged = React.useRef(
		({viewableItems}: {viewableItems: ViewToken[]}) => {
			viewableItemsRef.current = viewableItems;
			fetchViewableItems.current();
		},
	);

	/*
	 * Trigger viewable items fetching when activities change (useful when activities are updated and we need to refetch
	 * the current items, for example when focusing calendar)
	 */
	React.useEffect(
		() => { fetchViewableItems.current(); },
		[activities],
	);
	React.useEffect(
		() => {
			if (!activitiesFetching) {
				const dayCache = activitiesCache.current?.[formatDayKey(focusDate)];
				if (
					columns > 1 &&
					!selectedActivity &&
					onActivityPress &&
					dayCache?.state === "fetched" &&
					dayCache.activities.length > 0
				) {
					onActivityPress(dayCache.activities[0] as CalendarActivityPreview)(null);
				}
			}
		},
		[activitiesCache, activitiesFetching, columns, focusDate, onActivityPress, selectedActivity, render],
	);
	React.useEffect(
		() => {
			/*
			 * We need a filters ref because `handleViewableItemsChanged` needs to be a ref (RN limitation)
			 * Because of that `handleViewableItemsChanged` won't ever update its ref to filters.
			 */
			filtersRef.current = filters;
		},
		[filters],
	);
	React.useEffect(
		() => {
			if (
				list.length > 0 &&
				viewableItemsRef.current?.[0]?.key &&
				!compareDays(focusDate, dayKeyToDate(viewableItemsRef.current[0].key)) &&
				focusIndex >= 0 &&
				focusIndex < list.length
			) {
				flatListRef.current?.scrollToIndex({
					animated: true,
					index: focusIndex,
				});
			}
		},
		[focusDate, focusIndex, list.length],
	);

	const renderItem = React.useCallback(
		({item, index}: ListRenderItemInfo<CalendarItem>) => {
			const {key} = item;
			const date = dayKeyToDate(key);

			const nextItem = index === list.length - 1 ? null : list[index + 1];
			const consecutiveDates = nextItem?.key ? consecutiveDays(key, nextItem?.key) : false;

			const fetching = activitiesCache.current[key].state === "unfetched" ||
				activitiesCache.current[key].state === "fetching";
			const failed = activitiesCache.current[key].state === "failed";
			return (
				<>
					<Spacer size={TOP_SECTION_SPACING}/>
					<SectionHeader date={date}/>
					{activitiesCache.current[key].activities.map((activity, activityIndex) => (
						<React.Fragment key={typeof activity === "object" ? activity.activityId : `${activity}-${activityIndex}`}>
							{typeof activity === "object"
								? (
									<DayActivity
										activity={activity}
										onPressActivity={onPress}
										selected={activity.activityId === selectedActivity?.activityId}
									/>
								)
								: <ActivityPlaceholder activity={activity} fetching={fetching} fetchFailed={failed}/>
							}
							<Spacer size={BOTTOM_ITEM_SPACING}/>
						</React.Fragment>
					))}
					{activitiesCache.current[key].activities.length > 0 && (
						<Spacer size={BOTTOM_SECTION_SPACING} style={{backgroundColor: SUBTLE}}/>
					)}
					{index !== list.length - 1 && !consecutiveDates && <CollapsedDates/>}
				</>
			);
		},
		[activitiesCache, list, onPress, selectedActivity?.activityId],
	);

	return (
		<>
			{columns < 3 && <WeekCalendar/>}
			{activitiesFetching
				? <SplashView centered loading/>
				: (
					<FlatList
						{...flatListDefaultProps}
						ref={flatListRef}
						data={list}
						renderItem={renderItem}
						getItemLayout={getItemLayout}
						ListEmptyComponent={<ListEmpty itemTranslationKey="common:event"/>}
						onViewableItemsChanged={handleViewableItemsChanged.current}
						viewabilityConfig={viewabilityConfig}
						ItemSeparatorComponent={null}
						initialScrollIndex={focusIndex}
						contentContainerStyle={styles.contentContainer}
						scrollsToTop={false}
						keyExtractor={keyExtractor}
						maxToRenderPerBatch={3}
						windowSize={11}
						style={styles.list}
						contentInsetAdjustmentBehavior="always"
					/>
				)
			}
		</>
	);
};

const styles = StyleSheet.create({
	contentContainer: {
		paddingBottom: DEFAULT_SPACING,
	},
	list: {
		backgroundColor: SUBTLE,
	},
});
