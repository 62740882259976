import * as React from "react";
import {View} from "react-native";
import {FeedbackIssue} from "../../../../@types/validation";
import {TranslationFunc} from "../../../../utils/locales/translations";
import {SummaryRow} from "../../../inputs/summary-row";
import {DescriptionSummary} from "./description";

export const FeedbackSummary = ({issue: {feedback, description}, t}: {issue: FeedbackIssue; t: TranslationFunc}): JSX.Element => (
	<View>
		<SummaryRow label={t("activities:sessions.validate.issue.type.feedback.label")}>
			{feedback?.text}
		</SummaryRow>
		{!!description && <DescriptionSummary description={description} t={t} />}
	</View>
);
