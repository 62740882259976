import {severityLevelFromString} from "@sentry/utils";
import {toast} from "../../components/feedbacks/toast";
import {BUNDLE_BUILD, BUNDLE_IDENTIFIER, BUNDLE_VERSION, ENV, SENTRY_DSN, USE_LOGS_SERVICE} from "../constants";
import {FeedbackKey} from "../locales/translations";
import {Indexable} from "../objects";
import {LogError} from "./log-error";
import {LogsService} from "./logs-service";

export type Severity = "debug" | "error" | "fatal" | "info" | "log" | "success" | "warning";

// Errors that mostly come from the backend / connection to backend and that we don't want to report to Sentry
const blackListedStrings = [
	"Software caused connection abort",
	"Der Vorgang konnte nicht abgeschlossen werden.",
	"L’opération n’a pas pu s’achever.",
	"The operation couldn’t be completed",
	"Impossibile completare l’operazione.",
	"İşlem tamamlanamadı.",
	"No se ha podido completar la operación.",
	"Não foi possível concluir a operação.",
	"تعذر إكمال العملية.",
	"Не вдалося завершити операцію.",
	"Unable to resolve host \"api.production.bhaasha.ch\": No address associated with hostname",
	"failed to connect to api.production.bhaasha.ch",
	"Network request failed",
	"Connection reset",
	"Connection timed out",
	"Load failed",
	"Keine Sitzungen gefunden",
	"No Sessions found.",
	"Pas de sessions trouvé.",
	"Nessuna sessione trovata.",
	"Der Patient existiert bereits mit der gleichen Evam-Nummer.",
	"Patient existe déjà avec le même nombre Evam.",
	"Cannot read property 'isSuccess' of undefined",
	"Cannot read properties of undefined (reading 'isSuccess')",
];
const blackListedRegExps = [
	"undefined is not an object \\(evaluating '.\\.isSuccess'\\)",
];

export class Log {
	private static readonly capture =
		(severity: Severity, toLogService?: boolean, feedback?: FeedbackKey) =>
			(log?: unknown, extra?: Indexable): void => {
				if (feedback) toast({key: feedback, severity});
				if (ENV === "development") {
					if (severity === "log" || severity === "debug" || severity === "success") {
						if (extra) {
							// eslint-disable-next-line no-console
							console.log(log, extra);
						} else {
							// eslint-disable-next-line no-console
							console.log(log);
						}
					} else if (severity === "warning" || severity === "info") {
						if (extra) {
							// eslint-disable-next-line no-console
							console.warn(log, extra);
						} else {
							// eslint-disable-next-line no-console
							console.warn(log);
						}
					} else {
						if (extra) {
							// eslint-disable-next-line no-console
							console.error(log, extra);
						} else {
							// eslint-disable-next-line no-console
							console.error(log);
						}
						if (USE_LOGS_SERVICE) Log.toLogService(severity, log, extra);
					}
				} else if (toLogService) {
					Log.toLogService(severity, log, extra);
				}
			};

	private static readonly toLogService = (severity: Severity, log: unknown, extra?: Indexable): void => {
		if (severity === "error" || severity === "fatal") {
			const [exception, extraData] = log instanceof Error
				? [log, {extra}]
				: typeof log === "string"
					? [new LogError(log), {extra}]
					: (log as {message: string})?.message
						? [new LogError((log as {message: string}).message), {extra: {...extra, log}}]
						: [new LogError("Unknown error"), {extra: {...extra, log}}];
			if (
				blackListedStrings.every(blackListed => !exception.message.includes(blackListed)) &&
				blackListedRegExps.every(blackListed => !new RegExp(blackListed).test(exception.message))
			) {
				LogsService.captureException(exception, extraData);
			}
		} else {
			LogsService.captureMessage(JSON.stringify(log), {
				extra,
				level: severityLevelFromString(severity),
			});
		}
	};

	public static debug =
		(feedback?: FeedbackKey, toLogService = false) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("debug", toLogService, feedback)(log, extra);

	public static error =
		(feedback?: FeedbackKey, toLogService = true) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("error", toLogService, feedback)(log, extra);

	public static fatal =
		(feedback?: FeedbackKey, toLogService = true) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("fatal", toLogService, feedback)(log, extra);

	public static info =
		(feedback?: FeedbackKey, toLogService = false) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("info", toLogService, feedback)(log, extra);

	public static success =
		(feedback?: FeedbackKey, toLogService = false) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("success", toLogService, feedback)(log, extra);

	public static warning =
		(feedback?: FeedbackKey, toLogService = false) =>
			(log?: unknown, extra?: Indexable): void =>
				Log.capture("warning", toLogService, feedback)(log, extra);

	public static init = (): void => {
		USE_LOGS_SERVICE &&
		LogsService.init({
			attachStacktrace: true,
			debug: ENV === "development" || ENV === "staging",
			dist: BUNDLE_BUILD,
			dsn: SENTRY_DSN,
			environment: ENV,
			release: `${BUNDLE_IDENTIFIER}@${BUNDLE_VERSION}`,
			tracesSampleRate: 0.2,
		});
	};

	public static setTags = (tags: Indexable): void => {
		USE_LOGS_SERVICE && LogsService.setTags(tags);
	};

	public static setUser = (user: Indexable | null): void => {
		USE_LOGS_SERVICE && LogsService.setUser(user);
	};
}
