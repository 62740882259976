import {useFocusEffect} from "@react-navigation/native";
import * as React from "react";
import {BackHandler, StatusBar} from "react-native";
import {URLImage} from "../../../../@types/medias/image";
import {Slide} from "../../../../components/gallery-slider/gallery-slider";
import {WelcomeSlider} from "../../../../components/gallery-slider/welcome-slider";
import {Images} from "../../../../utils/externals/images";
import {AsyncStorage} from "../../../../utils/externals/storages/async-storage";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {s} from "../../../../utils/switch";

export interface SlidersInfos {
	// we leave the name "welcome" for the interpreters in order to not redisplay the slides for those who have already had them (stored in AsyncStorage)
	welcome: "calendar" | "checks" | "dots" | "inbox" | "incomes" | "notifications" | "validation";
	welcomeRequester: "account" | "calendar" | "checks" | "communication" | "languages" | "support" | "timeslots" | "welcome";
	welcomeRequesterMobile: "calendar" | "checks" | "communication" | "home" | "session" | "welcome";
}

export interface WelcomeSlide extends Slide {
	description?: string;
	image: URLImage;
	title: string;
}

const onBackPress = (): boolean => true;

export const SliderModal = ({route}: SharedScreenProps<"SliderModal">): JSX.Element => {
	const {sliderKey} = route.params;

	// https://reactnavigation.org/docs/5.x/custom-android-back-button-handling
	useFocusEffect(
		React.useCallback(
			() => {
				BackHandler.addEventListener("hardwareBackPress", onBackPress);
				return () => BackHandler.removeEventListener("hardwareBackPress", onBackPress);
			},
			[],
		),
	);

	AsyncStorage.setItem(
		"Sliders",
		JSON.stringify({[sliderKey]: Images.sliders[sliderKey].version}),
	).catch(Log.error());
	React.useEffect(
		() => {
			StatusBar.setHidden(true, "fade");
			return () => StatusBar.setHidden(false, "fade");
		},
		[],
	);
	return s(sliderKey, {
		welcome: <WelcomeSlider type="interpreter"/>,
		welcomeRequester: <WelcomeSlider type="requesterWeb"/>,
		welcomeRequesterMobile: <WelcomeSlider type="requesterMobile"/>,
	});
};
