import {Account, Organization} from "../../../@types/accounts";
import {Activity} from "../../../@types/activities/activity";
import {SessionCategory} from "../../../@types/activities/session-category";
import {BillingAddress} from "../../../@types/billing-address";
import {Identity} from "../../../@types/identities/identity";
import {ExternalMedia} from "../../../@types/medias/medias";
import {PaymentAuthority} from "../../../@types/payment-authority";
import {Place} from "../../../@types/places";
import {Setting} from "../../../@types/settings";
import {SessionValidation} from "../../../@types/validation";
import {TextInputDropdownSuggestionItem} from "../../../components/inputs/list-inputs/text-input-dropdown-suggestion";
import {accountExamples, organizationExamples} from "./accounts/data";
import {activitiesExamples, subjectSuggestionsExamples} from "./activities/data";
import {billingAddressesExamples} from "./billing-addresses/data";
import {identitiesExamples} from "./identities/data";
import {mediasExamples} from "./medias/data";
import {paymentAuthoritiesExamples} from "./payment-authorities/data";
import {placesExamples} from "./places/data";
import {sessionCategoriesExamples} from "./session-categories/data";
import {settingsExamples} from "./settings/data";

interface Bill {
	billId: string;
	price: {
		base: number;
		currency: "CHF" | "EUR";
		increased?: number;
		reduced?: number;
		travel?: number;
	};
	sessionId: string;
	type: "session";
}

interface Message {
	content: string;
	context: string;
	messageId: string;
	sentAt: Date;
}

interface Room {
	context?: string;
	endAt?: Date;
	participantsIds?: string[];
	roomId: string;
	startAt?: Date;
}

export interface FakeDatabase {
	accounts: Account[];
	activities: Activity[];
	authorizations: [];
	billingAddresses: BillingAddress[];
	bills: Bill[];
	connect: Room[];
	identitities: Identity[];
	medias: ExternalMedia[];
	messages: Message[];
	organizations: Organization[];
	paymentAuthorities: PaymentAuthority[];
	places: Place[];
	sessionCategories: SessionCategory[];
	settings: Setting[];
	subjects: TextInputDropdownSuggestionItem[];
	validations: SessionValidation[];
}

export const fakeDatabase: FakeDatabase = {
	accounts: accountExamples,
	activities: activitiesExamples,
	authorizations: [], // TODO
	billingAddresses: billingAddressesExamples,
	bills: [], // TODO
	connect: [], // TODO
	identitities: identitiesExamples,
	medias: mediasExamples,
	messages: [], // TODO
	organizations: organizationExamples,
	paymentAuthorities: paymentAuthoritiesExamples,
	places: placesExamples,
	sessionCategories: sessionCategoriesExamples,
	settings: settingsExamples,
	subjects: subjectSuggestionsExamples,
	validations: [],
};
