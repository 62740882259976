import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {Animated, ListRenderItemInfo, SectionListData, StyleSheet, View} from "react-native";
import {Corporation} from "../../@types/identities/corporation";
import {InterpreterPreview} from "../../@types/identities/interpreter";
import {ClientHeader} from "../../components/headers/client-header";
import {InterpreterHeader} from "../../components/headers/interpreter-header";
import {Icon} from "../../components/icons";
import {HeaderMenu} from "../../components/menus/header";
import {Spacer} from "../../components/spacer";
import {StatusBar} from "../../components/status-bar";
import {Text} from "../../components/texts/text";
import {AuthentifiedContext} from "../../contexts/authentified";
import {LocaleContext} from "../../contexts/locale";
import {
	BUNDLE_BUILD,
	BUNDLE_VERSION,
	CONTAINERS,
	DEFAULT_SPACING,
	IS_INTERPRETER,
	MENU_HEIGHT,
	SMALL_SPACING,
} from "../constants";
import {corporationIdentity, personIdentity} from "../identities";
import {sectionListDefaultProps} from "../scrollables";
import {SUBTLE_4} from "../styles/colors";
import {onPressVersion, SectionData} from "./use-account-sections";

export const keyExtractor = (item: SectionData): string => item.text;

interface Props {
	renderListItem: ({item}: ListRenderItemInfo<SectionData>) => React.ReactElement;
	sections: SectionListData<SectionData>[];
}

export const useAccountElements = ({
	renderListItem, sections,
}: Props): {
	header: React.ReactElement;
	menuList: React.ReactElement;
} => {
	const navigation = useNavigation();
	const {locale} = React.useContext(LocaleContext);
	const {identity} = React.useContext(AuthentifiedContext);
	const scrollY = React.useRef(new Animated.Value(0));

	const onPressHelp = (): void => navigation.navigate("SliderModal", {sliderKey: "welcome"});
	return {
		header: (
			<>
				<StatusBar/>
				<HeaderMenu
					left={null}
					right={locale === "de-DE"
						? { // TODO: this test need to be removed when other languages are ready!
							beforeHelp: IS_INTERPRETER ? <Icon icon="help" onPress={onPressHelp}/> : undefined,
							help: false,
						}
						: undefined}
					centerComponent={(
						<Animated.View
							style={
								{
									transform: [
										{
											translateY:
												scrollY.current.interpolate({
													inputRange: [Number.MIN_SAFE_INTEGER, 70, 140, Number.MAX_SAFE_INTEGER],
													outputRange: [-MENU_HEIGHT, -MENU_HEIGHT, 0, 0],
												}),
										},
									],
								}
							}
						>
							<Text type="emphasis_1">
								{IS_INTERPRETER
									? personIdentity(identity as InterpreterPreview)
									: corporationIdentity(
										identity as Corporation)}
							</Text>
						</Animated.View>
					)}
				/>
			</>
		),
		menuList: (
			<View style={styles.menuList}>
				<Animated.SectionList
					{...sectionListDefaultProps}
					keyExtractor={keyExtractor}
					onScroll={Animated.event(
						[{nativeEvent: {contentOffset: {y: scrollY.current}}}],
						{useNativeDriver: true},
					)}
					contentContainerStyle={styles.contentContainer}
					ListHeaderComponent={(
						<>
							{IS_INTERPRETER
								? <InterpreterHeader interpreter={identity as InterpreterPreview} scrollY={scrollY.current}/>
								: <ClientHeader corporation={identity as Corporation} scrollY={scrollY.current}/>
							}
							<Spacer size={SMALL_SPACING}/>
						</>
					)}
					ListFooterComponent={(
						<View style={[CONTAINERS.MAIN, styles.versionContainer]}>
							<Text type="touchable_label"
								onPress={onPressVersion}
								color={SUBTLE_4}
								centered
							>
								{`${BUNDLE_VERSION} - ${BUNDLE_BUILD}`}
							</Text>
						</View>
					)}
					renderItem={renderListItem}
					sections={sections}
				/>
			</View>
		),
	};
};

const styles = StyleSheet.create({
	contentContainer: {
		paddingBottom: DEFAULT_SPACING,
	},
	menuList: {
		flex: 1,
	},
	versionContainer: {
		paddingVertical: DEFAULT_SPACING,
	},
});
