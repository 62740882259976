export const forms = {
	inputs: {
		addEvent: "Add event",
		allDay: "All day",
		defaultStatusExplanation: "You can choose the session status you want you calendar to display by default",
		deleteAllEvents: "Delete all events",
		deleteEvent: "Delete event",
		deleteThisEvent: "Delete this event",
		editAllEvents: "Edit all events",
		editEvent: "Edit event",
		endDate: "End date",
		endTime: "End time",
		logUnavailability: "Log unavailability",
		recurrence: "Recurrence",
		selectCommunicationType: "Select communication type",
		selectDate: "Select a date",
		selectDateTime: "Select a date & time",
		selectDuration: "Select duration",
		selectGender: "Select gender",
		selectInterpretersFilter: "Select filter",
		selectLanguage: "Select a language",
		selectMonth: "Select a month",
		selectNotificationChannel: "Select a notification type",
		selectNotificationTarget: "Select a notification target",
		selectOneOption: "Select one of the options below",
		selectOrigin: "Select an origin",
		selectPlace: "Select exact address",
		selectProvidedService: "Select a service",
		selectQualification: "Select a qualification",
		selectQualificationInfo: "Only interpreters with all selected qualifications will be displayed. If you do not make a selection, all interpreters will be searched.",
		selectRecurrence: "Select a recurrence",
		selectSession: "Select a session",
		selectSessionOptions: "Select option",
		selectStatus: "Select status",
		selectTransport: "Select transport",
		showCanceledSessions: "Show canceled and rescheduled sessions",
		showLess: "Show less",
		showMore: "Show more",
		showUnavailabilities: "Show unavailabilities",
		startDate: "Start date",
		startTime: "Start time",
		toLanguage: "To language",
		typeHere: "Type here...",
		typeTo: "Write to {{name}}...",
		unavailability: "Unavailability",
		unsupportedFile: "The file format is unsupported (extension or name).",
	},
	items: {
		add: "add {{item}}",
		delete: "delete {{item}}",
		empty: "no {{item}}",
		select: "select {{item}}",
		update: "update {{item}}",
	},
	rules: {
		atLeastOneDigit: "This field must contain one number",
		atLeastOneLetter: "This field must contain one letter",
		atLeastOneSpecialLetter: "This field must contain one special character",
		between: "This field must be between {{min}} and {{max}} characters",
		durationGreaterOrEqualTo: "The duration must be at least {{minimumDuration}} {{durationUnit}}",
		email: "This email is not valid",
		equalTo: "This field must be equal to \"{{otherLabel}}\"",
		max: "This field must be at most {{max}} characters",
		min: "This field must be at least {{min}} characters",
		mustChange: "You must change the initial value of this field",
		notEmpty: "You have to fill this field",
		onlyDigits: "This field can only contain numbers",
		phone: "This phone number is not valid",
		time: "Exemple: 09.30 or 14.45 (expressed in 24h)",
		url: "This url is not valid",
	},
};
