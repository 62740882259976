import * as React from "react";
import {ReactElement} from "react";
import {ScrollView as RNScrollView, ScrollViewProps} from "react-native";
import {scrollViewDefaultProps} from "../../utils/scrollables";

const ScrollViewRenderer = (
	props: ScrollViewProps & {children?: React.ReactNode}, ref: React.Ref<RNScrollView>,
): ReactElement =>
	<RNScrollView ref={ref} {...scrollViewDefaultProps} {...props} />;

export type ScrollView = RNScrollView;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScrollView = React.forwardRef(ScrollViewRenderer);
