import {Session} from "./activities/session";

export class OverlappingSessionsError extends Error {
	public readonly sessionIds: Session["activityId"][];

	constructor (message: string, sessionIds: Session["activityId"][]) {
		super(message);
		this.name = "OverlappingSessionsError";
		this.sessionIds = sessionIds;
	}
}
