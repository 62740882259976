import {AsyncStorage} from "./externals/storages/async-storage";
import {Log} from "./logs/logs";

// Stores the token for easier requests without having to connect to context each time and pass token to them
export class RequestTokenHolder {
	public static token: string | null;
}

// Stores the logout function for easier requests without having to connect to context
export class Auth {
	public static logout: () => Promise<void>;
}

// remove Auth store and token and re-render tree (which reset child contexts and brings to login)
export const logoutUtil = (): void => {
	removeAuth()
		.then(() => Auth.logout?.())
		.catch(Log.error("logoutFailed"));
};

export const removeAuth = async ({
	asyncStorage = true,
	requestTokenHolder = true,
	log = true,
} = {}): Promise<void> => {
	if (asyncStorage) {
		await AsyncStorage.removeItems(["AuthToken", "AccountId"]);
	}
	if (requestTokenHolder) {
		RequestTokenHolder.token = null;
	}
	if (log) {
		Log.setUser(null);
	}
};

export const setAuth = async (
	authToken: string,
	accountId: string,
	{
		asyncStorage = true,
		requestTokenHolder = true,
		log = true,
	} = {},
): Promise<void> => {
	if (asyncStorage) {
		await AsyncStorage.setItems([["AuthToken", authToken], ["AccountId", accountId]]);
	}
	if (requestTokenHolder) {
		RequestTokenHolder.token = authToken;
	}
	if (log) {
		Log.setUser({id: accountId});
	}
};
