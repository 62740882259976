import * as React from "react";
import {StyleSheet, View} from "react-native";
import {TimelineElement} from "../../@types/timeline";
import {DEFAULT_SPACING, SMALL_SPACING, WINDOW_WIDTH} from "../../utils/constants";
import {formatDate, formatTime} from "../../utils/date-time/format";
import {useTranslation} from "../../utils/hooks/use-translation";
import {PRIMARY, PRIMARY_2, SUBTLE_2, WHITE} from "../../utils/styles/colors";
import {Dash} from "../dash";
import {StatusLabel} from "../labels/session-status";
import {Text} from "../texts/text";

const DOT_WIDTH = 10;

const UserBubble = ({message, date, side, sideName}: TimelineElement): JSX.Element => {
	const {t} = useTranslation();
	return (
		<View style={styles.container && side === "client" && styles.right}>
			<View
				style={[
					styles.bubbleContainer,
					side === "client" ? styles.bubbleUser : styles.bubbleInterpreter,
				]}
			>
				<Text
					type="emphasis_2"
					color={side === "client" ? WHITE : PRIMARY_2}
					style={side === "client" && styles.right}
				>
					{sideName}
				</Text>
				<Text
					type="default_2"
					color={side === "client" ? WHITE : PRIMARY_2}
					style={side === "client" && styles.right}
				>
					{(Array.isArray(message) ? t(message[0], message[1]) : t(message)) as string}
				</Text>
			</View>
			<Text
				type="label"
				style={[styles.date, side === "client" && styles.right]}
			>
				{`${formatDate(date)}, ${formatTime(date)}`}
			</Text>
		</View>
	);
};

const SystemBubble = ({system, message, date}: TimelineElement): JSX.Element => {
	const {t} = useTranslation();
	return (
		<View style={styles.systemContainer}>
			<View style={styles.colLine}>
				{!system.first && (system.connectedBefore
					? <View style={[styles.line, styles.lineBefore, {backgroundColor: PRIMARY_2}]} />
					: <Dash style={[styles.line, styles.lineBefore]} gaps={{empty: 2, full: 3}} color={PRIMARY_2} />)}
				<View style={styles.dot} />
				{!system.last && (system.connectedAfter
					? <View style={[styles.line, styles.lineAfter, {backgroundColor: PRIMARY_2}]} />
					: <Dash style={[styles.line, styles.lineAfter]} gaps={{empty: 2, full: 3}} color={PRIMARY_2} />)}
			</View>
			<View style={styles.colContent}>
				<StatusLabel status={system.status} style={styles.label} size="small" />
				<Text type="default_2" style={styles.systemText}>
					{(Array.isArray(message) ? t(message[0], message[1]) : t(message)) as string}
				</Text>
				<Text type="label">
					{`${formatDate(date)}, ${formatTime(date)}`}
				</Text>
			</View>
		</View>
	);
};

export const Bubble = ({system, ...props}: TimelineElement): JSX.Element => system
	? <SystemBubble system={system} {...props} />
	: <UserBubble system={system} {...props} />;

const styles = StyleSheet.create({
	bubbleContainer: {
		borderRadius: DEFAULT_SPACING,
		maxWidth: WINDOW_WIDTH * 0.77,
		paddingHorizontal: 20,
		paddingVertical: 10,
	},
	bubbleInterpreter: {
		alignSelf: "flex-start",
		backgroundColor: SUBTLE_2,
		borderBottomLeftRadius: 0,
	},
	bubbleUser: {
		alignSelf: "flex-end",
		backgroundColor: PRIMARY,
		borderBottomRightRadius: 0,
	},
	colContent: {
		alignItems: "flex-start",
		flex: 1,
		flexDirection: "column",
	},
	colLine: {
		height: "100%",
		marginRight: SMALL_SPACING,
		position: "relative",
		width: DOT_WIDTH,
	},
	container: {
		alignItems: "flex-start",
	},
	date: {
		paddingTop: 4,
	},
	dot: {
		backgroundColor: PRIMARY_2,
		borderRadius: DOT_WIDTH / 2,
		height: DOT_WIDTH,
		marginTop: 7,
		width: DOT_WIDTH,
	},
	label: {
		alignSelf: "flex-start",
		paddingHorizontal: 8,
	},
	line: {
		left: DOT_WIDTH / 2 - 0.5,
		position: "absolute",
		width: 1,
	},
	lineAfter: {
		bottom: -SMALL_SPACING, top: 10,
	},
	lineBefore: {
		height: 20, top: -SMALL_SPACING,
	},
	right: {
		alignSelf: "flex-end",
	},
	systemContainer: {
		flexDirection: "row",
	},
	systemText: {
		marginTop: 4,
	},
});
