export type SubUnion<T, U extends T> = U;
export type Partialize<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type Nullify<T, K extends keyof T> = Omit<T, K> & {
	[P in K]: T[P] | null;
};
export type Requalize<T, K extends keyof T> = Omit<T, K> & {
	[P in K]-?: NonNullable<T[P]>;
};
export type RemoveNull<T, K extends keyof T> = {
	[P in K]: NonNullable<T[P]>;
};
export type Await<T> = T extends {
	then(onfulfilled?: (value: infer U) => unknown): unknown;
} ? U : T;
export type OmitNever<T> = {[K in keyof T as T[K] extends never ? never : K]: T[K]};

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = <T extends Function>(value: unknown): value is T =>
	Object.prototype.toString.call(value) === "[object Function]";

type AllKeys<T> = T extends any ? keyof T : never;
type CommonKeys<T extends object> = keyof T;
type Subtract<A, C> = A extends C ? never : A;
type PickType<T, K extends AllKeys<T>> = T extends {[k in K]?: any}
	? T[K]
	: undefined;
type PickTypeOf<T, K extends number | string | symbol> = K extends AllKeys<T>
	? PickType<T, K>
	: never;
type NonCommonKeys<T extends object> = Subtract<AllKeys<T>, CommonKeys<T>>;
export type Merge<T extends object> = {
	[k in CommonKeys<T>]: PickTypeOf<T, k>;
} & {
	[k in NonCommonKeys<T>]?: PickTypeOf<T, k>;
};

/*
 * Create an object that can have only one of the properties of the base object. That property will be required.
 * The second parameter allow to ignore some base object keys.
 * More info here: https://stackoverflow.com/a/49725198
 */
export type OnlyOneOf<T, ExcludedKeys extends keyof T = keyof T> =
	{[K in ExcludedKeys]-?: Partial<Record<Exclude<ExcludedKeys, K>, undefined>> & Required<Pick<T, K>>}[ExcludedKeys]
	& Pick<T, Exclude<keyof T, ExcludedKeys>>;
