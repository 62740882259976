import * as React from "react";
import {ReactElement} from "react";
import {FlatList as RNFlatList, FlatListProps as RNFlatListProps} from "react-native";
import {flatListDefaultProps} from "../../utils/scrollables";

export type FlatList<T> = RNFlatList<T>;
export type FlatListProps<T> = RNFlatListProps<T>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FlatListRenderer = <Item, >(
	props: FlatListProps<Item>, ref: React.Ref<FlatList<Item>>,
): ReactElement =>
	<RNFlatList ref={ref} {...flatListDefaultProps} {...props} />;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlatList = React.forwardRef(FlatListRenderer);
