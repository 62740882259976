export const fakeRequest = <T>(resolved?: T, _rejected?: any): Promise<T> => {
	if (resolved) {
		return new Promise((resolve, _reject) => setTimeout(
			() => resolve(resolved),
			// () => reject(_rejected),
			Math.random() * 200 + 80,
		));
	}
	const result = new Promise((resolve, _reject) => setTimeout(
		() => resolve(resolved),
		// () => reject(_rejected),
		Math.random() * 200 + 80,
	));
	return result.then();
};
