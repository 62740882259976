import * as React from "react";
import {Gender, genders, Person} from "../../../../@types/identities/person";
import {MedicalProfessional, OtherProfessional} from "../../../../@types/identities/professional";
import {AgeGroup, ageGroups, Insurance, insurances, Receiver} from "../../../../@types/identities/receiver";
import {IdentityEditableFieldsSetting} from "../../../../@types/settings";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {filterTruthy} from "../../../../utils/arrays";
import {addTime} from "../../../../utils/date-time/helpers";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getTitle} from "../../../../utils/identities";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {removeNonPhoneNumberCharacters} from "../../../../utils/strings";
import {forceBack} from "../../../navigation";
import {Origin} from "../../common/modals/select-origin";

export const EditPerson = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"EditPersonModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {selectionOrBase: personParam, onSelect, title: titleParam, authorizedFields, context} = route.params;
	const base = React.useMemo(
		() => personParam ?? (
			context === "requester/receiver"
				? {type: "receiver"} as Receiver
				: context === "requester/person"
					? {type: "person"} as Person
					: context === "requester/medicalProfessional"
						? {type: "medicalProfessional"} as MedicalProfessional
						: {type: "otherProfessional"} as OtherProfessional
		),
		[context, personParam],
	);
	const {loading: loadingForm, displayed, isUpdated, setUpdated, errorMessage} = useForm(base);
	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const editablesSetting = getSetting<IdentityEditableFieldsSetting<MedicalProfessional | OtherProfessional | Person | Receiver>>(
		"identity/editable-fields",
		context,
	);

	const addOrUpdateTitle = personParam
		? ct("forms:items.update", {item: t("common:person")})
		: ct("forms:items.add", {item: t("common:person")});

	const title = titleParam ?? addOrUpdateTitle;
	const header = <HeaderMenu center={title} exitConfirmation={isUpdated}/>;
	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}

	const {fullName, gender, email, phone, origin, dateOfBirth, description, type} = displayed;
	const {
		// [editable, mandatory, availableValues, optional fieldLabel]
		gender: [genderEditable, genderMandatory, , genderLabel] = [],
		fullName: [
			fullNameEditable, fullNameMandatory, fullNameSettingData,
		] = [false, false, {}],
		dateOfBirth: [dateOfBirthEditable, dateOfBirthMandatory, , dateOfBirthLabel] = [],
		email: [emailEditable, emailMandatory, , emailLabel] = [],
		phone: [phoneEditable, phoneMandatory, , phoneLabel] = [],
		origin: [originEditable, originMandatory, authorizedOrigins, originLabel] = [],
		description: [descriptionEditable, descriptionMandatory, , descriptionLabel] = [],
	} = authorizedFields ?? editablesSetting?.value ?? {};

	const {
		firstName: [firstNameEditable, firstNameMandatory, , firstNameLabel] = [],
		lastName: [lastNameEditable, lastNameMandatory, , lastNameLabel] = [],
	} = fullNameSettingData ?? {firstName: [], lastName: []};

	let inputs: FormProps["inputs"] = [
		genderEditable != null && {
			disabled: !genderEditable || loadingForm,
			icon: "gender",
			label: genderLabel ?? ct("common:title"),
			resetable: false,
			rules: genderMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: genders,
				getLabelText: (g: Gender) => getTitle(g),
				key: "inlineSelect",
				onChangeValue: (gs?: Gender[] | null) => setUpdated(prev => ({...prev, gender: gs?.[0] ?? "man"})),
				value: gender ? [gender] : null,
			},
		}, firstNameEditable != null && {
			disabled: !fullNameEditable || !firstNameEditable || loadingForm,
			icon: "person",
			label: firstNameLabel ?? ct("common:firstName"),
			rules: (fullNameMandatory || firstNameMandatory) && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "firstName",
				onChangeValue: (fn: string) => setUpdated(prev => ({
					...prev, fullName: {...(prev?.fullName ?? fullName), firstName: fn},
				})),
				value: fullName?.firstName,
			},
		}, lastNameEditable != null && {
			disabled: !fullNameEditable || !lastNameEditable || loadingForm,
			icon: "person",
			label: lastNameLabel ?? ct("common:lastName"),
			rules: (fullNameMandatory || lastNameMandatory) && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "lastName",
				onChangeValue: (ln: string) => setUpdated(prev =>
					({...prev, fullName: {...(prev?.fullName ?? fullName), lastName: ln}})),
				value: fullName?.lastName,
			},
		}, emailEditable != null && {
			disabled: !emailEditable || loadingForm,
			icon: "mail",
			label: emailLabel ?? ct("common:email"),
			rules: filterTruthy([
				emailMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.email, type: "error"},
			]),
			type: {
				key: "email",
				onChangeValue: (m: string) => setUpdated(prev => ({...prev, email: m})),
				value: email,
			},
		}, phoneEditable != null && {
			disabled: !phoneEditable || loadingForm,
			icon: "phone",
			label: phoneLabel ?? ct("common:phone"),
			rules: filterTruthy([
				phoneMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.phone, type: "error"},
			]),
			type: {
				key: "phone",
				onChangeValue: (p?: string) => setUpdated(prev => ({...prev, phone: p ? removeNonPhoneNumberCharacters(p) : undefined})),
				value: phone,
			},
		}, originEditable != null && (
			authorizedOrigins?.length === 1
				? {
					disabled: true,
					icon: "world",
					label: originLabel ?? ct("common:origin"),
					type: {
						key: "text",
						value: origin ?? authorizedOrigins[0],
					},
				}
				: {
					disabled: !originEditable || loadingForm,
					icon: "world",
					label: originLabel ?? ct("common:origin"),
					rules: originMandatory && [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "screen",
						onChangeValue: (o?: Origin[]) => setUpdated(prev => ({...prev, origin: o?.[0]})),
						params: authorizedOrigins?.length === 0
							? undefined
							: {includedOrigins: authorizedOrigins!}, // if authorizedOrigins is empty, then user can select any Origin
						renderValue: (o: Origin) => t(`origins:${o}`),
						screen: "SelectOriginModal",
						value: origin,
					},
				}),
		dateOfBirthEditable != null && {
			disabled: !dateOfBirthEditable || loadingForm,
			icon: "birthday",
			label: dateOfBirthLabel ?? t("users:person.dateOfBirth"),
			rules: dateOfBirthMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "date",
				onChangeValue: (dob: Date) => setUpdated(prev => ({...prev, dateOfBirth: dob})),
				props: {maximumDate: new Date(), minimumDate: addTime(new Date(), -100, "year")},
				value: dateOfBirth,
			},
		},
		descriptionEditable != null && {
			disabled: !descriptionEditable || loadingForm,
			icon: "about",
			label: descriptionLabel ?? ct("common:description"),
			rules: descriptionMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (desc: string) => setUpdated(prev =>
					({...prev, description: desc})),
				value: description,
			},
		},
	];

	if (type === "receiver") {
		const {insurance, ageGroup, ippNumber, evamNumber, combinedNumber} = displayed;
		const receiverSetting = getSetting<IdentityEditableFieldsSetting<Receiver>>(
			"identity/editable-fields",
			context,
		);
		const {
			// [editable, mandatory, availableValues, optional fieldLabel]
			insurance: [insuranceEditable, insuranceMandatory, , insuranceLabel] = [],
			ageGroup: [ageGroupEditable, ageGroupMandatory, , ageGroupLabel] = [],
			ippNumber: [ippNumberEditable, ippNumberMandatory, , ippNumberLabel] = [],
			evamNumber: [evamNumberEditable, evamNumberMandatory, , evamNumberLabel] = [],
			combinedNumber: [combinedNumberEditable, combinedNumberMandatory, , combinedNumberLabel] = [],
		} = receiverSetting?.value ?? {};
		inputs = [
			...inputs,
			insuranceEditable != null && {
				disabled: !insuranceEditable || loadingForm,
				icon: "corporation",
				label: insuranceLabel ?? ct("users:receiver.insurance.label"),
				resetable: false,
				rules: insuranceMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: insurances,
					getLabelText: (i: Insurance) => ct(`users:receiver.insurance.${i}`),
					key: "inlineSelect",
					onChangeValue: (i?: Insurance[] | null) => setUpdated(
						prev => ({...prev, insurance: {name: i?.[0] ?? "unknown"}})),
					value: insurance ? [insurance?.name] : null,
				},
			}, insuranceEditable != null && insurance?.name === "other" && {
				disabled: !insuranceEditable || loadingForm,
				icon: "corporation",
				label: insuranceLabel ?? ct("users:receiver.insurance.label"),
				resetable: false,
				rules: insuranceMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "text",
					onChangeValue: (i: string | null) => setUpdated(prev => ({
						...prev,
						insurance: {
							name: (prev as Partial<Receiver>).insurance?.name ?? "unknown",
							...(prev as Partial<Receiver>).insurance,
							details: i ?? undefined,
						},
					})),
					value: insurance?.details,
				},
			},
			ageGroupEditable != null && {
				disabled: !ageGroupEditable || loadingForm,
				icon: "ageGroup",
				label: ageGroupLabel ?? ct("users:receiver.ageGroup.label"),
				resetable: false,
				rules: ageGroupMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					choices: ageGroups,
					getLabelText: (ag: AgeGroup) => ct(`users:receiver.ageGroup.${ag}`),
					key: "inlineSelect",
					onChangeValue: (ag?: AgeGroup[] | null) => setUpdated(prev => ({...prev, ageGroup: ag?.[0]})),
					value: ageGroup ? [ageGroup] : null,
				},
			}, ippNumberEditable != null && {
				disabled: !ippNumberEditable || loadingForm,
				icon: "person",
				label: ippNumberLabel ?? ct("common:ippNumber"),
				rules: ippNumberMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "text",
					onChangeValue: (ipp: string) => setUpdated(prev => ({
						...prev, ippNumber: {...(prev as Partial<Receiver>).ippNumber, value: ipp},
					})),
					value: ippNumber?.value,
				},
			}, evamNumberEditable != null && {
				disabled: !evamNumberEditable || loadingForm,
				icon: "person",
				label: evamNumberLabel ?? ct("common:evamNumber"),
				rules: filterTruthy([
					evamNumberMandatory && {rule: Rules.notEmpty, type: "error"},
					{rule: Rules.onlyDigits, type: "error"},
				]),
				type: {
					key: "evamNumber",
					onChangeValue: (evam: string) => setUpdated(prev => ({
						...prev, evamNumber: {...(prev as Partial<Receiver>).evamNumber, value: evam},
					})),
					value: evamNumber?.value,
				},
			}, combinedNumberEditable != null && {
				disabled: !combinedNumberEditable || loadingForm,
				icon: "person",
				label: combinedNumberLabel ?? ct("common:combinedNumber"),
				rules: combinedNumberMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "text",
					onChangeValue: (combined: string) => setUpdated(prev => ({
						...prev, combinedNumber: {...(prev as Partial<Receiver>).combinedNumber, value: combined},
					})),
					value: combinedNumber?.value,
				},
			},
		];
	}

	if (type === "medicalProfessional" || type === "otherProfessional") {
		let professionalSettings: IdentityEditableFieldsSetting<MedicalProfessional> | IdentityEditableFieldsSetting<OtherProfessional> | undefined;
		if (type === "medicalProfessional") {
			const {institution} = displayed;
			professionalSettings = getSetting<IdentityEditableFieldsSetting<MedicalProfessional>>(
				"identity/editable-fields",
				context,
			);
			const {
				// [editable, mandatory, availableValues, optional fieldLabel]
				institution: [institutionEditable, institutionMandatory, , institutionLabel] = [],
			} = professionalSettings?.value ?? {};
			inputs = [
				institutionEditable != null && {
					disabled: !institutionEditable || loadingForm,
					icon: "institution",
					label: institutionLabel ?? ct("common:institution"),
					resetable: false,
					rules: institutionMandatory && [{rule: Rules.notEmpty, type: "error"}],
					type: {
						key: "text",
						onChangeValue: (value: string) => setUpdated(prev => ({...prev, institution: value})),
						value: institution,
					},
				},
				...inputs,
			];
		} else {
			professionalSettings = getSetting<IdentityEditableFieldsSetting<OtherProfessional>>(
				"identity/editable-fields",
				context,
			);
		}

		const {directPhone, function: functionValue} = displayed;
		const {
			// [editable, mandatory, availableValues, optional fieldLabel]
			directPhone: [directPhoneEditable, directPhoneMandatory, , directPhoneLabel] = [],
			function: [functionEditable, functionMandatory, , functionLabel] = [],
		} = professionalSettings?.value ?? {};
		inputs = [
			...inputs,
			directPhoneEditable != null && {
				disabled: !directPhoneEditable || loadingForm,
				icon: "directPhone",
				label: directPhoneLabel ?? ct("common:directPhone"),
				resetable: false,
				rules: directPhoneMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "text",
					onChangeValue: (value: string) => setUpdated(prev => ({...prev, directPhone: value})),
					value: directPhone,
				},
			}, functionEditable != null && {
				disabled: !functionEditable || loadingForm,
				icon: "medical",
				label: functionLabel ?? ct("common:function"),
				resetable: false,
				rules: functionMandatory && [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "text",
					onChangeValue: (value: string) => setUpdated(prev => ({...prev, function: value})),
					value: functionValue,
				},
			},
		];
	}

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: title},
					onValidation: () => {
						onSelect?.(displayed);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const EditPersonModal = (props: ClientStackNavigatorScreenProps<"EditPersonModal">): JSX.Element => (
	<ModalWrapper>
		<EditPerson {...props} />
	</ModalWrapper>
);
