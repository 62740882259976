import {forms as enUSForms} from "../en-US/forms";

export const forms: typeof enUSForms = {
	inputs: {
		addEvent: "Ajouter un évènement",
		allDay: "Journée entière",
		defaultStatusExplanation: "Vous pouvez choisir les status des sessions que votre calendrier va afficher par défaut",
		deleteAllEvents: "Supprimer tous les évènements",
		deleteEvent: "Supprimer l'évènement",
		deleteThisEvent: "Supprimer cet évènement",
		editAllEvents: "Éditer tous les évènements",
		editEvent: "Éditer l'évènement",
		endDate: "Date de fin",
		endTime: "Heure de fin",
		logUnavailability: "Saisir une indisponibilité",
		recurrence: "Récurrence",
		selectCommunicationType: "Sélectionner un mode de communication",
		selectDate: "Sélectionner une date",
		selectDateTime: "Sélectionner une date & heure",
		selectDuration: "Sélectionner une durée",
		selectGender: "Sélectionner un genre",
		selectInterpretersFilter: "Sélectionner un filtre",
		selectLanguage: "Sélectionner une langue",
		selectMonth: "Sélectionner un mois",
		selectNotificationChannel: "Sélectionner un type de notification",
		selectNotificationTarget: "Sélectionner une adresse de notification",
		selectOneOption: "Sélectionner l'une des options ci-dessous",
		selectOrigin: "Sélectionner une origine",
		selectPlace: "Sélectionner l'adresse exacte",
		selectProvidedService: "Sélectionner un service",
		selectQualification: "Sélectionner une qualification",
		selectQualificationInfo: "Seuls les interprètes possédant toutes les qualifications sélectionnées seront affichés. Si vous ne faites pas de choix, par défaut tous les interprètes seront recherchés.",
		selectRecurrence: "Sélectionner une récurrence",
		selectSession: "Sélectionner une session",
		selectSessionOptions: "Sélectionner une option",
		selectStatus: "Sélectionner un status",
		selectTransport: "Sélectionner un transport",
		showCanceledSessions: "Afficher les sessions annulées et replanifiées",
		showLess: "Montrer moins",
		showMore: "Montrer plus",
		showUnavailabilities: "Afficher les indisponibilités",
		startDate: "Date de début",
		startTime: "Heure de début",
		toLanguage: "Langue vers",
		typeHere: "Écrire ici...",
		typeTo: "Écrire à {{name}}...",
		unavailability: "Indisponibilité",
		unsupportedFile: "Le format du fichier n'est pas supporté (extension ou nom).",
	},
	items: {
		add: "ajouter {{item}}",
		delete: "supprimer {{item}}",
		empty: "aucun {{item}}",
		select: "Sélectionner {{item}}",
		update: "mettre à jour {{item}}",
	},
	rules: {
		atLeastOneDigit: "Ce champ doit contenir au moins un chiffre",
		atLeastOneLetter: "Ce champ doit contenir au moins une lettre",
		atLeastOneSpecialLetter: "Ce champ doit contenir au moins un caractère spécial",
		between: "Ce champ peut faire entre {{min}} et {{max}} caractères",
		durationGreaterOrEqualTo: "La durée doit être d'au moins {{minimumDuration}} {{durationUnit}}",
		email: "Cet email n'est pas valide",
		equalTo: "Ce champ doit être égal à \"{{otherLabel}}\"",
		max: "Ce champ doit faire au plus {{max}} caractères",
		min: "Ce champ doit faire au moins {{min}} caractères",
		mustChange: "Vous ne pouvez pas garder la valeur initiale de ce champ",
		notEmpty: "Vous devez remplir ce champ",
		onlyDigits: "Ce champ ne peut contenir que des chiffres",
		phone: "Ce numéro de téléphone n'est pas valide",
		time: "Exemple: 09:30 ou 14:45 (exprimé en 24h)",
		url: "Cette url n'est pas valide",
	},
};
