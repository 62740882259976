import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Corporation} from "../../../../@types/identities/corporation";
import {Page, Pagination} from "../../../../@types/pagination";
import {Button} from "../../../../components/buttons/button";
import {CustomIcon} from "../../../../components/icons";
import {Image} from "../../../../components/images/image";
import {DropdownSelectInput} from "../../../../components/inputs/list-inputs/dropdown-select-input";
import {TranslationLabel} from "../../../../components/labels/translation";
import {Item} from "../../../../components/list/list-props.common";
import {Text} from "../../../../components/texts/text";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {getTargetTranslationSuggestions} from "../../../../requests/clients/new-session/target-translation-suggestion";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../../../utils/constants";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {personIdentity} from "../../../../utils/identities";
import {Locales, sessionLanguageToLocale} from "../../../../utils/locales/locales";
import {Log} from "../../../../utils/logs/logs";
import {ClientHomeTabNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {paginate} from "../../../../utils/pagination";
import {textContains} from "../../../../utils/searches";
import {SessionLanguagesKey, sessionLanguagesKeys} from "../../../../utils/sessions/languages";
import {PLACEHOLDER, PRIMARY_2} from "../../../../utils/styles/colors";

const NB_SUGGESTIONS = 10;

type DropdownListItem = Item<"id", {displayed: string; id: SessionLanguagesKey}>;
const sort = (a: DropdownListItem, b: DropdownListItem): number => Locales.compare(a.displayed, b.displayed);

export const LanguageStep = ({
	navigation,
	inlineMode,
	includedLanguages,
	onValidate,
	languagesLocked = false,
}: ClientHomeTabNavigatorScreenProps<"NewSession"> & {
	includedLanguages?: Set<SessionLanguagesKey>;
	inlineMode?: boolean;
	languagesLocked?: boolean;
	onValidate?: () => void;
}): JSX.Element => {
	const {columns, mobileDisplay} = React.useContext(ResponsiveContext);
	const {t} = useTranslation();
	const {session, setSession, initialProvider} = React.useContext(SessionCreationContext);
	const {accountId, identity, settings: {loading: settingsLoading}} = React.useContext(AuthentifiedContext);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [targetTranslationSuggestions, setTargetTranslationSuggestions] = React.useState<Set<SessionLanguagesKey>>(
		new Set<SessionLanguagesKey>());
	const sessionLanguages = React.useMemo(
		() => [...(includedLanguages ?? new Set<SessionLanguagesKey>(sessionLanguagesKeys))]
			.map((language: SessionLanguagesKey) => ({displayed: t(`languages:${language}`), id: language}))
			.sort(sort),
		[includedLanguages, t],
	);
	const getLanguageRequest = React.useCallback(
		(pagination: Pagination): Promise<Page<DropdownListItem>> =>
			Promise.resolve(paginate(sessionLanguages, pagination)),
		[sessionLanguages],
	);

	const searchLanguageRequest = React.useCallback(
		(pagination: Pagination, search: string): Promise<Page<DropdownListItem>> =>
			Promise.resolve(paginate(
				sessionLanguages.filter(item => search
					? textContains(search, item.displayed, true)
					: true,
				),
				pagination,
			),
			),
		[sessionLanguages],
	);

	const onSearch = React.useMemo(
		() => ({
			placeholder: t("screens:home.choose"),
			request: searchLanguageRequest,
		}),
		[searchLanguageRequest, t],
	);

	const languageCallBack = React.useCallback(
		(languageKey?: SessionLanguagesKey) => {
			setSession(prev => ({...prev, toLanguage: languageKey}));
			if (inlineMode) {
				onValidate?.();
			}
		},
		[inlineMode, onValidate, setSession],
	);

	const onSelectPress = React.useCallback(
		() => navigation.navigate("SelectLanguageModal", {includedLanguages, onSelect: languageCallBack}),
		[includedLanguages, languageCallBack, navigation],
	);

	const onChangeLanguageDropdown = React.useCallback(
		(selectedLanguages: DropdownListItem[] | null) => languageCallBack(selectedLanguages?.[0].id),
		[languageCallBack],
	);

	const getLabelText = React.useCallback(
		(item: DropdownListItem) => item.displayed,
		[],
	);

	const onHistoryLanguagePress = (languageKey: SessionLanguagesKey) => () => languageCallBack(languageKey);
	React.useEffect(
		() => {
			setLoading(true);
			getTargetTranslationSuggestions(accountId!, sessionLanguageToLocale(session.language))
				.then(setTargetTranslationSuggestions)
				.catch(Log.error())
				.finally(() => setLoading(false));
		},
		[accountId, session.language],
	);

	const baseSuggestions = React.useMemo(
		() => [
			...targetTranslationSuggestions,
			...([...(includedLanguages ?? new Set())].filter(element => !targetTranslationSuggestions.has(element))),
		],
		[includedLanguages, targetTranslationSuggestions],
	);

	const suggestions = React.useMemo(
		() => baseSuggestions.filter(element => includedLanguages?.has(element)).slice(0, NB_SUGGESTIONS),
		[baseSuggestions, includedLanguages],
	);

	React.useEffect(
		() => {
			if (!loading && !settingsLoading && !session.toLanguage && suggestions.length === 1) {
				languageCallBack(suggestions[0]);
			}
		},
		[languageCallBack, loading, session.toLanguage, settingsLoading, suggestions],
	);

	const renderSuggestionLabel = (language: SessionLanguagesKey): JSX.Element => (<Button
		key={language}
		size="xsmall"
		type="secondary"
		text={t(`languages:${language}`)}
		onPress={onHistoryLanguagePress(language)}
		active={session.toLanguage === language}
		style={styles.label}
	/>);

	const displayHeader = !session?.toLanguage || columns === 1;
	return (
		<View style={[CONTAINERS.MAIN, columns === 1 && styles.container]}>
			{displayHeader && <View style={styles.firstHalf}>
				<Image
					source={(identity as Corporation).group.logo?.source}
					fullHeight={(identity as Corporation).group.logo?.fullHeight}
					mime={(identity as Corporation).group.logo?.mime}
					sourceType={(identity as Corporation).group.logo?.sourceType}
					width={(identity as Corporation).group.logo?.width}
					height={(identity as Corporation).group.logo?.height}
					fullWidth={(identity as Corporation).group.logo?.fullWidth}
					style={styles.image}
					resizeMode="contain"
				/>
			</View>}
			<>
				{displayHeader && !initialProvider && <Text type="title_2" centered style={styles.text}>
					{t(`screens:home.${languagesLocked ? "sessionLanguages" : "find"}`)}
				</Text>}
				{languagesLocked
					? <TranslationLabel from={session.language} to={session.toLanguage!}/> // if languagesLocked is true, then language and toLanguage are always set
					: (
						<View style={styles.languagesContainer}>
							{loading || settingsLoading
								? <SplashView centered loading/>
								: (includedLanguages ?? new Set()).size > 0
									? <>
										{mobileDisplay
											? <Button
												size="xsmall"
												type="secondary"
												icon="chevronBottom"
												iconPosition="after"
												fullWidth
												onPress={onSelectPress}
												text={session?.toLanguage
													? t(`languages:${session.toLanguage}`)
													: t(
														"screens:creation.selectLanguage")}
												contentColor={session?.toLanguage ? undefined : PLACEHOLDER}
												spaceBetween
											/>
											: <DropdownSelectInput
												key="dropdownSelect"
												getRequest={getLanguageRequest}
												onSearch={onSearch}
												getLabelText={getLabelText}
												onChangeValue={onChangeLanguageDropdown}
												value={session.toLanguage
													? [{displayed: t(`languages:${session.toLanguage}`), id: session.toLanguage}]
													: undefined
												}
												itemTranslationKey="common:language"
												idKey="id"
												wordsBeginBySearch
											/>
										}
										{(!session?.toLanguage || columns === 1) &&
											<View style={styles.labelsContainer}>
												{session.toLanguage && !suggestions.includes(session.toLanguage) &&
													renderSuggestionLabel(session.toLanguage)
												}
												{suggestions.map(tts => renderSuggestionLabel(tts))}
											</View>
										}
									</>
									: <Text type="emphasis_2" centered>
										{t(`screens:creation.${initialProvider ? "notSpeak" : "noLanguage"}`)}
									</Text>
							}
						</View>
					)
				}
				{displayHeader && <View style={styles.powered}>
					<Text type="label" style={styles.poweredText}>Powered by</Text>
					<CustomIcon name="logo" size={12} color={PRIMARY_2}/>
				</View>}
				{!inlineMode && !!session.toLanguage && (
					<View style={styles.findButtonWrapper}>
						<Button
							type="primary"
							icon="forward"
							iconPosition="after"
							text={
								initialProvider
									? t("screens:home.book", {name: personIdentity(initialProvider)})
									: t("screens:home.find")
							}
							innerStyle={styles.findButton}
							onPress={onValidate}
							fullWidth
						/>
					</View>
				)}
			</>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
	},
	findButton: {
		marginTop: SMALL_SPACING,
	},
	findButtonWrapper: {
		paddingBottom: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
	},
	firstHalf: {
		justifyContent: "center",
		padding: DEFAULT_SPACING,
	},
	image: {
		alignSelf: "center",
		height: 100,
		justifyContent: "center",
		maxWidth: "80%",
	},
	label: {
		marginHorizontal: 3,
		marginVertical: 3,
	},
	labelsContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		marginTop: SMALL_SPACING,
	},
	languagesContainer: {
		marginVertical: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
	},
	powered: {
		flexDirection: "row",
		justifyContent: "flex-end",
		marginRight: DEFAULT_SPACING + SMALL_SPACING,
		marginVertical: SMALL_SPACING,
	},
	poweredText: {
		marginRight: 4,
	},
	text: {
		marginVertical: SMALL_SPACING,
	},
});
