import {Account} from "../../../../@types/accounts";
import {Corporation} from "../../../../@types/identities/corporation";
import {InterpreterFilters} from "../../../../@types/identities/filters";
import {Identity} from "../../../../@types/identities/identity";
import {Interpreter} from "../../../../@types/identities/interpreter";
import {ExternalImageProps} from "../../../../@types/medias/image";
import {Page, Pagination} from "../../../../@types/pagination";
import {Qualification} from "../../../../@types/qualifications";
import {Locales} from "../../../locales/locales";
import {fakeRequest} from "../../../network/fake-request";
import {paginate} from "../../../pagination";
import {nRandomlyFrom, randomNumber, randomString} from "../../../randoms";
import {fakeDatabase} from "../database";
import {getMedia} from "../medias/api";
import {qualificationsExamples} from "./data";
import {fakeFilterInterpreters, fakeIdentitySort, fakePersonFilter, fakeReceiverFilter} from "./utils";

const createFakeIdentity = <T extends Identity>(newIdentity: Omit<T, "identityId">): T => {
	const identityToAdd = {identityId: randomString(), ...newIdentity} as T;
	fakeDatabase.identitities = [...fakeDatabase.identitities, identityToAdd]
		.sort(fakeIdentitySort);
	return identityToAdd;
};

const findFakeIdentityIndex = (identityId: Identity["identityId"]): number =>
	fakeDatabase.identitities.findIndex(a => a.identityId === identityId);

export const findFakeIdentityByIdentityId = <T extends Identity>(identityId: T["identityId"]): T =>
	fakeDatabase.identitities.find(a => a.identityId === identityId) as T;

export const findFakeIdentityByAccountId = <T extends Identity>(accountId: Account["accountId"]): T =>
	fakeDatabase.identitities.find(a => !!a.accountId && a.accountId === accountId) as T;

export const filterFakeIdentities = <T extends Identity>(predicate: (
	value: T, index: number, array: T[]) => unknown): T[] =>
	(fakeDatabase.identitities as T[]).filter((element: T, index, array: T[]) => predicate(element, index, array));

const updateFakeIdentity = <T extends Identity>(update: Partial<T> & {identityId: T["identityId"]}): T => {
	const index = findFakeIdentityIndex(update.identityId);
	fakeDatabase.identitities[index] = {
		...fakeDatabase.identitities[index],
		...update,
	};
	return fakeDatabase.identitities[index] as T;
};

const deleteFakeIdentity = (activityId: Identity["identityId"]): void => {
	const index = findFakeIdentityIndex(activityId);
	fakeDatabase.identitities.splice(index, 1);
};

export const getIdentityByAccountIdAPI = <T extends Corporation | Interpreter>(accountId: Account["accountId"]): Promise<T> => {
	const identity = findFakeIdentityByAccountId<T>(accountId);
	if (!identity) {
		return Promise.reject(new Error(`Could not find Identity with account id ${accountId}`));
	}
	const pictureSource = identity.type === "interpreter"
		? "interpreter-Picture.png"
		: identity.type === "corporation"
			? "user-Picture.png"
			: undefined;
	if (pictureSource) {
		const picture = getMedia<ExternalImageProps>(pictureSource);
		if (picture) {
			identity.picture = {
				...picture,
				mediaType: "image",
				sourceType: "url",
			};
		}
	}
	return fakeRequest(identity);
};

export const getIdentitiesAPI = <T extends Identity>(
	type: T["type"], pagination?: Pagination, search?: string, filters?: InterpreterFilters,
): Promise<Page<T>> => fakeRequest(paginate(filterFakeIdentities(
	i => {
		const activeFilters: {[key in "interpreterFilters" | "search" | "type"]?: boolean} = {
			type: i.type === type,
		};
		if (search) {
			activeFilters.search = (i.type === "person" || i.type === "interpreter")
				? fakePersonFilter(search)(i)
				: i.type === "receiver"
					? fakeReceiverFilter(search)(i)
					: undefined;
		}
		if (filters) {
			activeFilters.interpreterFilters = i.type === "interpreter" &&
				fakeFilterInterpreters(i as Interpreter, filters);
		}
		// eslint-disable-next-line unicorn/no-array-reduce
		return Object.values(activeFilters).reduce((f1, f2) => f1 && f2, true);
	},
)), pagination);

export const createIdentityAPI = <T extends Identity>(identityToAdd: T): Promise<T> => {
	const identity = createFakeIdentity<T>(identityToAdd);
	return identity ? fakeRequest(identity) : Promise.reject(new Error("Unable to add the new identity"));
};

export const updateIdentityAPI = <T extends Identity>(update: Partial<T> & {identityId: T["identityId"]}): Promise<T> =>
	fakeRequest(updateFakeIdentity<T>(update));

export const deleteIdentityAPI = (identityId: Identity["identityId"]): Promise<void> => {
	deleteFakeIdentity(identityId);
	return fakeRequest();
};

const sort = (a: Qualification, b: Qualification): number => Locales.compare(a.value, b.value);

const quals = Object.values(qualificationsExamples).flat();
const ig1Quals = [qualificationsExamples.modules[0], ...nRandomlyFrom(quals, randomNumber(quals.length - 1))];
const ig2Quals = nRandomlyFrom(quals, randomNumber(quals.length - 1));
const ig3Quals = nRandomlyFrom(quals, randomNumber(quals.length - 1));

export const getQualificationsAPI = (interpretersGroups?: InterpreterFilters["interpretersGroups"]): Promise<Qualification[]> => {
	const qualifications: Set<Qualification> = new Set();
	if (interpretersGroups?.map(ig => ig.id).includes("1")) {
		ig1Quals.map(q => qualifications.add(q));
	}
	if (interpretersGroups?.map(ig => ig.id).includes("2")) {
		ig2Quals.map(q => qualifications.add(q));
	}
	if (interpretersGroups?.map(ig => ig.id).includes("3")) {
		ig3Quals.map(q => qualifications.add(q));
	}
	return fakeRequest([...qualifications].sort(sort));
};
