import * as React from "react";
import {StyleSheet, View} from "react-native";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {SUBTLE_2} from "../../../utils/styles/colors";

const COLLAPSED_DATES_HEIGHT = 13;

export const getCollapsedDatesHeight = (): number => COLLAPSED_DATES_HEIGHT;

export const CollapsedDates = (): JSX.Element => (
	<View style={[CONTAINERS.MAIN, styles.container]}>
		<View style={styles.collapsedDates} />
	</View>
);

const styles = StyleSheet.create({
	collapsedDates: {
		borderColor: SUBTLE_2,
		borderWidth: 1,
		height: 0,
	},
	container: {
		height: COLLAPSED_DATES_HEIGHT,
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING / 2,
	},
});
