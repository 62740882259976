import React from "react";
import {Animated, StyleSheet, View} from "react-native";
import {SMALL_SPACING} from "../../../utils/constants";
import {Dot} from "./dot";

export interface DotsProps {
	animatedIndex: Animated.AnimatedInterpolation<number>;
	slidesDots: {
		activeColor: string;
		inactiveColor: string;
	}[];
}

export const Dots = ({slidesDots, animatedIndex}: DotsProps): JSX.Element => (
	<View style={styles.dotsContainer}>
		{slidesDots?.map((_, index) => {
			const inputRange = slidesDots.map((__, i) => i);
			const backgroundColor = animatedIndex.interpolate({
				extrapolate: "clamp",
				inputRange,
				outputRange: slidesDots.map((dots, i) =>
					index === i ? dots.activeColor : dots.inactiveColor),
			});
			const width = animatedIndex.interpolate({
				extrapolate: "clamp",
				inputRange,
				outputRange: slidesDots.map((__, i) =>
					index === i ? SMALL_SPACING * 2 : SMALL_SPACING),
			});
			return (
				<Dot
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					backgroundColor={backgroundColor}
					width={width}
					borderRadius={SMALL_SPACING / 2}
				/>
			);
		})}
	</View>
);

const styles = StyleSheet.create({
	dotsContainer: {
		flexDirection: "row",
		paddingHorizontal: SMALL_SPACING,
	},
});
