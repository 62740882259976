import {Account} from "../../../@types/accounts";
import {InterpreterInboxMandatePreview} from "../../../@types/activities/mandate";
import {DataGetter} from "../../../utils/hooks/use-websocket";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {InboxSessionList} from "../../@types/activities/session";
import {WebSocketMandates} from "../../@types/session";
import {transformBackendResponseToCalendarInterpreterMandatePreview} from "../../utils/transform";

export type InterpreterSessionInvitationsRequests = {
	GET: {},
};

export const getInterpreterMandatesData: DataGetter<InterpreterInboxMandatePreview[]> = {
	getData: (message: WebSocketMandates) => message.data.map(
		(session) => transformBackendResponseToCalendarInterpreterMandatePreview(session)),
	key: "interpreterMandate",
};

export const getUnvalidatedSessions = async (accountId: Account["accountId"]): Promise<InterpreterInboxMandatePreview[]> => {
	const interpreterResponse = await api(
		"commonSessionsFilter",
		"GET",
		{
			queryParams: {
				interpreterId: accountId,
				status: "MISSED",
				lockType: "unlocked",
				startingTime: new Date(2021, 0, 1).getTime().toString(),
			},
		},
	) as ApiResponse<InboxSessionList[]>;
	let frontendSessions: InterpreterInboxMandatePreview[] = [];
	if (interpreterResponse.isSuccess) {
		frontendSessions = interpreterResponse.data.map(
			(session) => transformBackendResponseToCalendarInterpreterMandatePreview(session));
		return frontendSessions;
	}
	throw new Error(interpreterResponse.message);
};
