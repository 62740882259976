import {Account} from "../../../@types/accounts";
import {NotificationTarget} from "../../../@types/notifications";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export const getInterpreterNotificationTargets = async (pagination: Pagination): Promise<Page<NotificationTarget>> => {
	const {limit, offset} = pagination;
	const backendOffset = offset === 0 ? 1 : offset / limit;
	const interpreterResponse = await api(
		"commonNotifications",
		"GET",
		{
			queryParams: {
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	) as ApiResponse<{list: NotificationTarget[], count: number}>;
	if (interpreterResponse.isSuccess) {
		const {count} = interpreterResponse.data;
		let {list} = interpreterResponse.data;
		list = list.map(target => ({...target, deletable: target.channel === "FCM" || target.channel === "APNS"}));
		return {
			items: list,
			hasPreviousPage: offset > 0,
			hasNextPage: count > offset + limit,
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};

export const updateInterpreterNotificationTarget = async (target: NotificationTarget): Promise<NotificationTarget> => {
	const interpreterResponse = await api(
		"commonNotifications",
		"POST",
		{
			body: target,
		},
	) as ApiResponse<{}>;
	if (interpreterResponse.isSuccess) {
		return {...target, deletable: target.channel === "FCM" || target.channel === "APNS"};
	}
	throw new Error(interpreterResponse.message);
};

export const deleteInterpreterNotificationTarget = async (
	accountId: Account["accountId"],
	targetId: string,
): Promise<void> => {
	const interpreterResponse = await api(
		"commonNotifications",
		"DELETE",
		{
			body: {deviceToken: targetId, interpreterId: accountId},
		},
	) as ApiResponse<{}>;
	if (!interpreterResponse.isSuccess) {
		throw new Error(interpreterResponse.message);
	}
};
