import {NativeStackNavigationOptions} from "@react-navigation/native-stack";
import {StyleProp, ViewStyle} from "react-native";
import {BACKGROUND_COLOR} from "../styles/colors";
import {StackParamList} from "./paramLists/root-param-list";

export const defaultStackScreenOptions: NativeStackNavigationOptions = {
	contentStyle: {backgroundColor: BACKGROUND_COLOR},
	gestureEnabled: true,
	headerShown: false,
};

export const defaultSceneContainerStyle: StyleProp<ViewStyle> = {
	backgroundColor: BACKGROUND_COLOR,
};

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace ReactNavigation {
		interface RootParamList extends StackParamList {}
	}
}
