import * as React from "react";
import {Month as MonthProps, OnDatePress} from "../../utils/date-time/activities";
import {Week} from "./week";

interface Props {
	focusDate?: Date;
	month: MonthProps;
	onDayPress: OnDatePress;
}

export const Month = ({month, onDayPress, focusDate}: Props): JSX.Element => (
	<>
		{month.map(week => (
			<Week
				key={week[0].day.getTime()}
				week={week}
				onDayPress={onDayPress}
				focusDate={focusDate}
			/>
		))}
	</>
);
