import {BillingAddress} from "../../@types/billing-address";

export type BackendBillingAddress = {
	address: string;
	department: string;
	id?: number;
	name: string;
	poBox: string;
	postalCode: string;
	town: string;
	userAccountId: number;
};

export const transformBillingAddressFrontToBack = (billingAddress: BillingAddress, accountId: string): BackendBillingAddress => ({
	address: billingAddress.address,
	department: billingAddress.department,
	name: billingAddress.name,
	poBox: billingAddress.postOfficeBox,
	postalCode: billingAddress.postalCode,
	town: billingAddress.town,
	userAccountId: Number(accountId),
	...(billingAddress.id ? {id: Number(billingAddress.id)} : {}),
});

export const transformBillingAddressBackToFront = (billingAddress: BackendBillingAddress): BillingAddress => ({
	address: billingAddress.address,
	department: billingAddress.department,
	id: billingAddress.id?.toString(),
	name: billingAddress.name,
	postOfficeBox: billingAddress.poBox,
	postalCode: billingAddress.postalCode,
	town: billingAddress.town,
});
