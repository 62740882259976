import {origins as enUSOrigins} from "../en-US/origins";

export const origins: typeof enUSOrigins = {
	AD: "Andorra",
	AE: "Emirati Arabi Uniti",
	AF: "Afghanistan",
	AG: "Antigua e Barbuda",
	AI: "Anguilla",
	AL: "Albania",
	AM: "Armenia",
	AN: "Antille Olandesi",
	AO: "Angola",
	AQ: "Antartide",
	AR: "Argentina",
	AS: "Samoa americane",
	AT: "Austria",
	AU: "Australia",
	AW: "Aruba",
	AX: "Isole Aland",
	AZ: "Azerbaijan",
	BA: "Bosnia ed Erzegovina",
	BB: "Barbados",
	BD: "Bangladesh",
	BE: "Belgio",
	BF: "Burkina Faso",
	BG: "Bulgaria",
	BH: "Bahrain",
	BI: "Burundi",
	BJ: "Benin",
	BL: "Saint Barthelemy",
	BM: "Bermuda",
	BN: "Brunei Darussalam",
	BO: "Bolivia",
	BR: "Brasile",
	BS: "Bahamas",
	BT: "Bhutan",
	BV: "Isola di Bouvet",
	BW: "Botswana",
	BY: "Bielorussia",
	BZ: "Belize",
	CA: "Canada",
	CC: "Cocos (Keeling)",
	CD: "Repubblica Democratica del Congo",
	CE: "Cecenia",
	CF: "Repubblica Centrafricana",
	CG: "Congo",
	CH: "Svizzera",
	CI: "Costa d'Avorio",
	CK: "Isole Cook",
	CL: "Cile",
	CM: "Camerun",
	CN: "Cina",
	CO: "Colombia",
	CR: "Costa Rica",
	CS: "Serbia e Montenegro",
	CU: "Cuba",
	CV: "Capo Verde",
	CW: "Curaçao",
	CY: "Cipro",
	CZ: "Repubblica Ceca",
	DE: "Germania",
	DJ: "Gibuti",
	DK: "Danimarca",
	DM: "Dominica",
	DO: "Repubblica Dominicana",
	DZ: "Algeria",
	EC: "Ecuador",
	EE: "Estonia",
	EG: "Egitto",
	EH: "Sahara occidentale",
	ER: "Eritrea",
	ES: "Spagna",
	ET: "Etiopia",
	FI: "Finlandia",
	FJ: "Figi",
	FK: "Isole Falkland",
	FM: "Micronesia",
	FO: "Isole Faroe",
	FR: "Francia",
	GA: "Gabon",
	GB: "Regno Unito",
	GD: "Grenada",
	GE: "Georgia",
	GF: "Guinea francese",
	GG: "Guernsey",
	GH: "Ghana",
	GI: "Gibilterra",
	GL: "Groenlandia",
	GM: "Gambia",
	GN: "Guinea",
	GP: "Guadalupa",
	GQ: "Guinea Equatoriale",
	GR: "Grecia",
	GT: "Guatemala",
	GU: "Guam",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HK: "Hong Kong",
	HM: "Isola Heard e McDonald",
	HN: "Honduras",
	HR: "Croazia",
	HT: "Haiti",
	HU: "Ungheria",
	ID: "Indonesia",
	IE: "Irlanda",
	IL: "Israele",
	IM: "Isola di Man",
	IN: "India",
	IQ: "Iraq",
	IR: "Iran",
	IS: "Islanda",
	IT: "Italia",
	JE: "Jersey",
	JM: "Giamaica",
	JO: "Giordania",
	JP: "Giappone",
	KE: "Kenya",
	KG: "Kirghizistan",
	KH: "Cambogia",
	KM: "Comore",
	KN: "Saint Kitts e Nevis",
	KR: "Corea, Repubblica di",
	KW: "Kuwait",
	KY: "Isole Cayman",
	KZ: "Kazakistan",
	LA: "Repubblica democratica popolare del Laos",
	LB: "Libano",
	LC: "Santa Lucia",
	LI: "Liechtenstein",
	LK: "Sri Lanka",
	LR: "Liberia",
	LS: "Lesotho",
	LT: "Lituania",
	LU: "Lussemburgo",
	LV: "Lettonia",
	LY: "Jamahiriya araba libica",
	MA: "Marocco",
	MC: "Monaco",
	MD: "Moldavia",
	ME: "Montenegro",
	MF: "San Martino",
	MG: "Madagascar",
	MH: "Isole Marshall",
	MK: "Macedonia",
	ML: "Mali",
	MM: "Myanmar",
	MN: "Mongolia",
	MO: "Macao",
	MP: "Isole Marianne Settentrionali",
	MQ: "Martinica",
	MR: "Mauritania",
	MS: "Montserrat",
	MT: "Malta",
	MU: "Mauritius",
	MV: "Maldive",
	MW: "Malawi",
	MX: "Messico",
	MY: "Malesia",
	MZ: "Mozambico",
	NA: "Namibia",
	NC: "Nuova Caledonia",
	NE: "Niger",
	NF: "Isola di Norfolk",
	NG: "Nigeria",
	NI: "Nicaragua",
	NL: "Paesi Bassi",
	NO: "Norvegia",
	NP: "Nepal",
	NR: "Nauru",
	NU: "Niue",
	NZ: "Nuova Zelanda",
	OM: "Oman",
	PA: "Panama",
	PE: "Perù",
	PF: "Polinesia francese",
	PG: "Papua Nuova Guinea",
	PH: "Filippine",
	PK: "Pakistan",
	PL: "Polonia",
	PM: "PalSaint Pierre e Miquelonau",
	PN: "Pitcairn",
	PR: "Porto Rico",
	PS: "Territori palestinesi",
	PT: "Portogallo",
	PW: "Palau",
	PY: "Paraguay",
	QA: "Qatar",
	RE: "Riunione",
	RO: "Romania",
	RS: "Serbia",
	RU: "Russia",
	RW: "Ruanda",
	SA: "Arabia Saudita",
	SB: "Isole Salomone",
	SC: "Seychelles",
	SD: "Sudan",
	SE: "Svezia",
	SG: "Singapore",
	SH: "Sant'Elena",
	SI: "Slovenia",
	SJ: "Svalbard e Jan Mayen",
	SK: "Slovacchia",
	SL: "Sierra Leone",
	SM: "San Marino",
	SN: "Senegal",
	SO: "Somalia",
	SR: "Suriname",
	SS: "Sud Sudan",
	ST: "São Tomé e Principe",
	STAT: "Apolide",
	SV: "El Salvador",
	SX: "Sint Maarten",
	SY: "Siria",
	SZ: "Swaziland",
	TB: "Tibet (Repubblica Popolare Cinese)",
	TC: "Isole Turks e Caicos",
	TD: "Chad",
	TG: "Vai",
	TH: "Thailandia",
	TJ: "Tajikistan",
	TK: "Tokelau",
	TL: "Timor-Leste",
	TM: "Turkmenistan",
	TN: "Tunisia",
	TO: "Tonga",
	TR: "Turchia",
	TT: "Trinidad e Tobago",
	TV: "Tuvalu",
	TW: "Taiwan",
	TZ: "Tanzania",
	UA: "Ucraina",
	UG: "Uganda",
	US: "Stati Uniti",
	UY: "Uruguay",
	UZ: "Uzbekistan",
	VA: "Santa Sede (Città del Vaticano)",
	VC: "Saint Vincent e Grenadine",
	VE: "Venezuela",
	VG: "Isole Vergini Britanniche",
	VI: "Isole Vergini, Stati Uniti",
	VN: "Vietnam",
	VU: "Vanuatu",
	WF: "Wallis e Futuna",
	WS: "Samoa",
	XK: "Kosovo",
	YE: "Yemen",
	YT: "Mayotte",
	ZA: "Sudafrica",
	ZM: "Zambia",
	ZW: "Zimbabwe",
};
