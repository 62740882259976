import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {
	Animated,
	FlatList,
	LayoutChangeEvent,
	LayoutRectangle,
	ListRenderItemInfo,
	StyleSheet,
	View,
	ViewabilityConfig,
	ViewToken,
} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {IS_WEB, WINDOW_HEIGHT, WINDOW_WIDTH} from "../../utils/constants";
import {BACKGROUND_COLOR} from "../../utils/styles/colors";
import {Icon} from "../icons";
import {Pagination, PaginationProps} from "./pagination/pagination";

export interface Slide {
	backgroundColor?: string;
	buttons?: PaginationProps["slidesPagination"][0]["buttons"];
	contentColor?: string;
	dots?: PaginationProps["slidesPagination"][0]["dots"];
	key: string;
}

export interface GallerySliderRenderItemParams<S extends Slide> {
	animatedIndex: Animated.AnimatedInterpolation<number>;
	info: ListRenderItemInfo<S>;
}

export type GallerySliderRenderItem<S extends Slide> = (
	{info, animatedIndex}: GallerySliderRenderItemParams<S>,
) => React.ReactElement | null;

interface GallerySliderProps<S extends Slide> {
	closable?: boolean;
	renderItem: GallerySliderRenderItem<S>;
	slides: S[];
}

/*
 * This is used to fix the incorrect offset if pagingEnabled is true on web
 * see: https://github.com/necolas/react-native-web/issues/1798
 */
const FixOffsetOnWeb = ({props, width}: {props: any; width: number}): JSX.Element => {
	// eslint-disable-next-line react/prop-types
	const {onLayout, ...other} = props;
	if (IS_WEB) {
		const fixOffsetOnLayout = (e: LayoutChangeEvent): void => {
			if (onLayout) {
				onLayout({
					...e,
					nativeEvent: {
						...e.nativeEvent,
						layout: {
							...e.nativeEvent.layout,
							// workaround: override the x offset
							x: other.index * width,
						},
					},
				});
			}
		};
		return <View {...other} onLayout={fixOffsetOnLayout}/>;
	}
	return <View {...props} />;
};

export const GallerySlider = <S extends Slide>({
	slides,
	renderItem: renderItemProp,
	closable,
}: GallerySliderProps<S>): JSX.Element => {
	const insets = useSafeAreaInsets();
	const navigation = useNavigation();
	const [layout, setLayout] = React.useState<LayoutRectangle>({height: WINDOW_HEIGHT, width: WINDOW_WIDTH, x: 0, y: 0});
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const scrollXRef = React.useRef(new Animated.Value(0));
	const flatListRef = React.useRef<FlatList<S>>(null);
	const handleViewableItemsChanged = React.useRef(
		({viewableItems}: {viewableItems: ViewToken[]}) => {
			setCurrentIndex(viewableItems.length > 0 ? viewableItems[0].index ?? 0 : 0);
		},
	);
	const onLayout = React.useCallback(
		(event: LayoutChangeEvent) => setLayout(event.nativeEvent.layout),
		[],
	);
	const animatedIndex = scrollXRef.current.interpolate({
		inputRange: slides.map((_, i) => i * layout.width),
		outputRange: slides.map((_, i) => i),
	});
	const renderItem = React.useCallback(
		(info: ListRenderItemInfo<S>) => (
			<View style={{height: layout.height, width: layout.width}}>
				{renderItemProp({animatedIndex, info})}
			</View>
		),
		[animatedIndex, layout.height, layout.width, renderItemProp],
	);
	const cellRenderer = React.useCallback(
		(props: any) => <FixOffsetOnWeb width={layout.width} props={props}/>,
		[layout.width],
	);
	const scrollTo = (move: "next" | "previous") => () => {
		const index = move === "next" ? currentIndex + 1 : currentIndex - 1;
		if (flatListRef.current?.props.data && flatListRef.current.props.data.length > 0 && index >= 0 && index <
			flatListRef.current.props.data.length) {
			flatListRef.current.scrollToIndex({
				animated: true,
				index,
			});
		}
	};
	const viewabilityConfig: ViewabilityConfig = {
		viewAreaCoveragePercentThreshold: 50,
	};

	return (
		<Animated.View
			style={[
				styles.container,
				{
					backgroundColor: scrollXRef.current.interpolate({
						inputRange: slides.map((_, i) => i * layout.width),
						outputRange: slides.map(slide => slide.backgroundColor ?? BACKGROUND_COLOR),
					}),
					paddingBottom: insets.bottom,
					paddingTop: insets.top,
				},
			]}
		>
			{!!closable &&
				<View style={styles.close}>
					<Icon
						icon="close"
						size={36}
						containerSize={48}
						color={slides[currentIndex].contentColor}
						onPress={() => navigation.goBack()}
					/>
				</View>
			}
			<FlatList
				decelerationRate="fast"
				data={slides}
				horizontal
				onLayout={onLayout}
				onScroll={Animated.event(
					[{nativeEvent: {contentOffset: {x: scrollXRef.current}}}],
					{useNativeDriver: false},
				)}
				onViewableItemsChanged={handleViewableItemsChanged.current}
				viewabilityConfig={viewabilityConfig}
				pagingEnabled
				ref={flatListRef}
				renderItem={renderItem}
				showsHorizontalScrollIndicator={false}
				CellRendererComponent={cellRenderer}
			/>
			<Pagination
				currentIndex={currentIndex}
				onNextPress={scrollTo("next")}
				onPreviousPress={scrollTo("previous")}
				animatedIndex={animatedIndex}
				slidesPagination={slides.map(({buttons, dots}) => ({buttons, dots}))}
			/>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	close: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	container: {
		flex: 1,
	},
});
