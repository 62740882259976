import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {Log} from "../../../utils/logs/logs";
import {StackParamList} from "../../../utils/navigation/paramLists/root-param-list";
import {Button, ButtonProps} from "../../buttons/button";
import {
	Item,
	ListPropsCommon,
	OnlyKeysExtendingSelectListType,
	OnlyParamListExtendingSelectListType,
} from "../../list/list-props.common";

export interface MultiSelectListProps<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> {
	// Used to select multiple values + called with null when the field is reset
	getItems: {
		onChangeValue: (values: I[] | null) => void;
		params?: OnlyParamListExtendingSelectListType<StackParamList, I>[RouteName];
		screen: RouteName;
	};
	getLabelText: (item: I) => string;
	itemProps?: (item: I) => ButtonProps;
	key: "multiSelect";
	keyExtractor: (item: I) => number | string;
	/*
	 * Allow to specify what happens when clicking on add and the actions related to handling the new value.
	 * By default, getItems is used when clicking on add.
	 */
	onAddItem?: ListPropsCommon<IdKey, I, RouteName>["onPressAdd"];
	onDeleteItem: ListPropsCommon<IdKey, I, RouteName>["deleteRequest"];
	onUpdateItem?: ListPropsCommon<IdKey, I, RouteName>["onPressEdit"];
	value?: I[];
}

type MultiSelectInputProps<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> = Omit<MultiSelectListProps<IdKey, I, RouteName>, "key"> & {
	collapsed?: boolean;
	disabled?: boolean;
	toggleCollapse?: () => void;
};

const DISPLAY_MORE_BREAKPOINT = 5;

export const MultiSelectInput = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
>({
	getItems,
	getLabelText,
	keyExtractor,
	onDeleteItem,
	value,
	itemProps,
	onAddItem,
	onUpdateItem,
	collapsed,
	disabled,
	toggleCollapse,
}: MultiSelectInputProps<IdKey, I, RouteName>): JSX.Element => {
	const {t} = useTranslation();
	const navigation = useNavigation();

	const onPressItem = React.useCallback(
		(item: I) => () => {
			if (!onUpdateItem) {
				return;
			}
			navigation.navigate(
				// @ts-expect-error FIXME: Typescript error to FIX
				onUpdateItem.screen.name,
				{
					...onUpdateItem.screen.params,
					onSelect: (i: I) => {
						onUpdateItem.request(i).catch(Log.error());
					},
					selectionOrBase: item,
				},
			);
		},
		[navigation, onUpdateItem],
	);

	const onPressDeleteItem = React.useCallback(
		(item: I) => () => onDeleteItem?.(item),
		[onDeleteItem],
	);

	const onPressAdd = React.useCallback(
		() => {
			if (onAddItem) {
				navigation.navigate(
					// @ts-expect-error FIXME: Typescript error to FIX
					onAddItem.screen.name,
					{
						...onAddItem.screen.params,
						onSelect: (i: I) => {
							onAddItem.request(i).catch(Log.error());
						},
					},
				);
			} else {
				navigation.navigate(
					// @ts-expect-error FIXME: Typescript error to FIX
					getItems.screen,
					{
						...getItems.params,
						onSelect: (i: I[]) => getItems.onChangeValue(i),
					},
				);
			}
		},
		[getItems, navigation, onAddItem],
	);

	return (
		<View style={styles.multiSelectContainer}>
			{(value ?? [] as I[]).map((item, index) => {
				if (collapsed && index >= DISPLAY_MORE_BREAKPOINT) {
					return null;
				}
				return (
					<Button
						key={keyExtractor(item)}
						size="xsmall"
						type="secondary"
						disabled={disabled}
						{...(!disabled && {
							icon: "close",
							iconPosition: "after",
							onPressIcon: onPressDeleteItem(item),
						})}
						onPress={onPressItem(item)}
						style={styles.multiSelectButton}
						text={getLabelText(item)}
						{...itemProps?.(item)}
					/>
				);
			})}
			{(value ?? []).length > DISPLAY_MORE_BREAKPOINT && (
				<Button
					style={styles.multiSelectAdd}
					text={collapsed ? t("forms:inputs.showMore") : t("forms:inputs.showLess")}
					icon={collapsed ? "chevronBottom" : "chevronUp"}
					type="secondary"
					size="xsmall"
					onPress={toggleCollapse}
				/>
			)}
			{!disabled && (
				<Button
					style={styles.multiSelectAdd}
					icon="plus"
					type="secondary"
					size="xsmall"
					onPress={onPressAdd}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	multiSelectAdd: {
		marginLeft: 3,
	},
	multiSelectButton: {
		margin: 3,
	},
	multiSelectContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginHorizontal: -8,
	},
});
