import {FeedbackKey} from "../../../../utils/locales/translations";
import {Log} from "../../../../utils/logs/logs";
import {ps} from "../../../../utils/switch";

export const onSessionCreationError = (feedback: FeedbackKey, onError?: () => void) => (error: Error) => {
	ps(error.message, {
		default: () => Log.error(feedback)(error),
		hourLimitExceeded: () => Log.error("hourLimitExceeded", false)(error),
	});
	onError && onError();
};
