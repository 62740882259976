import * as React from "react";
import {ListRenderItemInfo, SectionListData, StyleSheet} from "react-native";
import {SessionCreationSetting, ToccoSetting} from "../../../@types/settings";
import {ListElement} from "../../../components/list/items/list-element";
import {Spacer} from "../../../components/spacer";
import {Column} from "../../../components/views/columns/column";
import {Columns} from "../../../components/views/columns/columns";
import {SplashView} from "../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {ResponsiveContext} from "../../../contexts/responsive";
import {filterTruthy} from "../../../utils/arrays";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useAccountElements} from "../../../utils/hooks/use-account-elements";
import {SectionData, useAccountSections} from "../../../utils/hooks/use-account-sections";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {
	ClientHomeTabNavigatorScreenProps,
	ClientStackNavigatorScreenProps,
} from "../../../utils/navigation/paramLists/client-param-list";
import {SharedScreenProps, StackParamList} from "../../../utils/navigation/paramLists/root-param-list";
import {getScreenKey} from "../../../utils/navigation/utils";
import {BACKGROUND_COLOR, CONTRAST, SUBTLE_2} from "../../../utils/styles/colors";
import {SelectPerson} from "../common/modals/select-person";
import {SelectPlace} from "../common/modals/select-place";
import {AddressesList} from "./modals/address-list";
import {EditPerson} from "./modals/edit-person";
import {SelectBillingAddress} from "./modals/select-billing-address";
import {SelectPaymentAuthority} from "./modals/select-payment-authority";
import {SelectSubjectSuggestion} from "./modals/select-subject-suggestion";

const screenMapping: {
	AddressesListModal: React.FC<ClientStackNavigatorScreenProps<"AddressesListModal">>;
	EditPersonModal: React.FC<ClientStackNavigatorScreenProps<"EditPersonModal">>;
	SelectBillingAddressModal: React.FC<ClientStackNavigatorScreenProps<"SelectBillingAddressModal">>;
	SelectPaymentAuthorityModal: React.FC<ClientStackNavigatorScreenProps<"SelectPaymentAuthorityModal">>;
	SelectPersonModal: React.FC<SharedScreenProps<"SelectPersonModal">>;
	SelectPlaceModal: React.FC<SharedScreenProps<"SelectPlaceModal">>;
	SelectSubjectSuggestionModal: React.FC<ClientStackNavigatorScreenProps<"SelectSubjectSuggestionModal">>;
} = {
	AddressesListModal: AddressesList,
	EditPersonModal: EditPerson,
	SelectBillingAddressModal: SelectBillingAddress,
	SelectPaymentAuthorityModal: SelectPaymentAuthority,
	SelectPersonModal: SelectPerson,
	SelectPlaceModal: SelectPlace,
	SelectSubjectSuggestionModal: SelectSubjectSuggestion,
};

type AccountScreen = keyof typeof screenMapping;

interface ScreenWithParameters<S extends AccountScreen> {
	key: string;
	params: StackParamList[S];
	screen: S;
}

export const ClientAccount = ({
	navigation,
	route,
}: ClientHomeTabNavigatorScreenProps<"ClientAccount">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {columns} = React.useContext(ResponsiveContext);
	const {settings: {getSetting, loading}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			detailsStep: {
				billingAddress,
				medicalProfessional,
				otherProfessional,
				paymentAuthority,
				receiver,
				subject,
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") ?? {value: {detailsStep: {}}};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};
	const [column2, setColumn2] = React.useState<ScreenWithParameters<AccountScreen>>();

	const personalisedSection: SectionListData<SectionData> = React.useMemo(
		() => {
			const createScreenProperties = <Screen extends AccountScreen>(
				screen: ScreenWithParameters<Screen>["screen"],
				params: ScreenWithParameters<Screen>["params"],
				columnSetter?: React.Dispatch<React.SetStateAction<ScreenWithParameters<AccountScreen> | undefined>>,
			): {key: string; onPress: () => void} => {
				const key = getScreenKey(screen, params);
				return {
					key,
					onPress: columnSetter
						? () => columnSetter({key, params, screen})
						// @ts-expect-error FIXME: Typescript error to FIX
						: () => navigation.navigate(screen, params),
				};
			};

			const columnSetter = columns >= 2 ? setColumn2 : undefined;
			return {
				data: filterTruthy<SectionData>([
					{
						icon: "personOutline",
						text: ct("common:requester_plural"),
						...createScreenProperties(
							"SelectPersonModal", {exportType: "requester"}, columnSetter),
					},
					!usingTocco && !!receiver && {
						icon: "receiver",
						text: receiver?.label ?? ct("common:receiver_plural"),
						...createScreenProperties(
							"SelectPersonModal", {exportType: "receiver"}, columnSetter),
					},
					!!medicalProfessional && {
						icon: "medical",
						text: medicalProfessional?.label ?? ct("common:medicalProfessional_plural"),
						...createScreenProperties(
							"SelectPersonModal", {exportType: "medicalProfessional"}, columnSetter),
					},
					!!otherProfessional && {
						icon: "person",
						text: otherProfessional?.label ?? ct("common:otherProfessional_plural"),
						...createScreenProperties(
							"SelectPersonModal", {exportType: "otherProfessional"}, columnSetter),
					},
					{
						icon: "place",
						text: t("users:settings.savedAddresses"),
						...createScreenProperties(
							"AddressesListModal", undefined, columnSetter,
						),
					},
					!usingTocco && !!paymentAuthority && {
						icon: "payment",
						text: paymentAuthority?.label ?? ct("common:paymentAuthority"),
						...createScreenProperties(
							"SelectPaymentAuthorityModal", undefined, columnSetter,
						),
					},
					usingTocco && !!billingAddress && {
						icon: "address",
						text: ct("common:billingAddress_plural"),
						...createScreenProperties(
							"SelectBillingAddressModal", undefined, columnSetter,
						),
					},
					!!subject && {
						icon: "id",
						text: ct("users:settings.savedSubjects"),
						...createScreenProperties(
							"SelectSubjectSuggestionModal", undefined, columnSetter,
						),
					},
				]),
				key: "personalised",
				title: t("users:settings.personalisedData"),
			};
		},
		[
			columns, ct, usingTocco, receiver, medicalProfessional, otherProfessional, t, paymentAuthority, billingAddress,
			subject, navigation,
		],
	);

	const accountSections = useAccountSections();
	const sections = [personalisedSection, ...(columns === 1 ? accountSections : [])];

	React.useEffect(
		() => {
			if (columns >= 2 && column2 === undefined) {
				personalisedSection.data[0].onPress?.();
			}
		},
		[column2, columns, personalisedSection],
	);

	const renderListItem = ({item}: ListRenderItemInfo<SectionData>): JSX.Element =>
		(
			<ListElement
				icon={item.icon}
				onPress={item.onPress}
				style={columns >= 2 && item.key === column2?.key && {backgroundColor: CONTRAST}}
			>
				{item.text}
			</ListElement>
		);

	const {header, menuList} = useAccountElements({renderListItem, sections});

	const Column2 = column2 && screenMapping[column2.screen];

	return (
		<>
			{header}
			<Columns style={{backgroundColor: BACKGROUND_COLOR}}>
				{loading
					? <SplashView centered loading/>
					: columns === 1
						? menuList
						: (
							<>
								<Column style={styles.mainColumn}>{menuList}</Column>
								<Spacer mode="vertical" size={SMALL_SPACING}/>
							</>
						)
				}
				{columns >= 2 && (
					<Column>
						{Column2 && (
							<Column2
								navigation={navigation as never}
								route={{key: route.key, name: column2.screen, params: column2.params} as never}
							/>
						)}
					</Column>
				)}
			</Columns>
		</>
	);
};

const styles = StyleSheet.create({
	mainColumn: {
		borderColor: SUBTLE_2,
		borderRadius: DEFAULT_SPACING,
		borderWidth: 1,
	},
});
