import {MediaDevice} from "../../../@types/medias/media-device";
import {FeedbackKey} from "../../locales/translations";

// Agora uids range from 1 to 2^31 - 1, so we can use the 0 value to express we don't have any uid.
export const UNSET_UID = 0;

export interface AgoraUser {
	hasAudio: boolean;
	hasVideo: boolean;
	id: number;
}

export type InitState =
	// Used when a fatal error occurs during initialization
	| {feedback: FeedbackKey; status: "error"}
	| {status: "initializing"}
	| {status: "ready"};

export type UseAgoraType = (channelId: string, token: string, sessionId: string, secret?: string) => {
	audio: boolean;
	backgroundBlur: boolean;
	cameraName: string;
	getCameraDevices: () => Promise<MediaDevice[]>;
	getMicrophoneDevices: () => Promise<MediaDevice[]>;
	initState: InitState;
	joinChannel: () => void;
	joined: boolean;
	leaveChannel: () => void;
	microphoneName: string;
	setCamera: (device: MediaDevice) => Promise<void>;
	setMicrophone: (device: MediaDevice) => Promise<void>;
	switchCamera: () => void;
	toggleAudio: () => void;
	toggleBackgroundBlur: () => void;
	toggleVideo: () => void;
	uid: number;
	users: AgoraUser[];
	video: boolean;
};
