import * as React from "react";
import {ResponsiveContext} from "../../contexts/responsive";
import {Tooltip} from "./tooltip";
import {TooltipWrapperMobile, TooltipWrapperProps} from "./tooltip-wrapper.common";

export const TooltipWrapper = ({content, offset, children}: React.PropsWithChildren<TooltipWrapperProps>): JSX.Element => {
	const {mobileDisplay} = React.useContext(ResponsiveContext);

	return mobileDisplay
		? (
			<TooltipWrapperMobile content={content} offset={offset}>
				{children}
			</TooltipWrapperMobile>
		)
		: (
			<TooltipWrapperWeb content={content} offset={offset}>
				{children}
			</TooltipWrapperWeb>
		);
};

const TooltipWrapperWeb = ({content, offset, children}: React.PropsWithChildren<TooltipWrapperProps>): JSX.Element => {
	const [hovered, setHovered] = React.useState(false);
	const wrapperRef = React.useRef(null);

	const onMouseEnter = React.useCallback(
		() => setHovered(true),
		[],
	);

	const onMouseLeave = React.useCallback(
		() => setHovered(false),
		[],
	);

	return (
		<div
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			ref={wrapperRef}
		>
			{children}
			<Tooltip content={content} isOpen={hovered} offset={offset} wrapperRef={wrapperRef} />
		</div>
	);
};
