import * as React from "react";
import {StyleSheet} from "react-native";
import {AvailabilityState} from "../../../@types/availability";
import {InterpreterFilters} from "../../../@types/identities/filters";
import {InterpreterPreview} from "../../../@types/identities/interpreter";
import {AvailableSessionLanguagesCommunication} from "../../../@types/language-translation";
import {Pagination} from "../../../@types/pagination";
import {SessionCreationSetting, ToccoSetting} from "../../../@types/settings";
import {InterpreterElement, InterpreterElementProps} from "../../../components/list/items/interpreter";
import {Item} from "../../../components/list/list-props.common";
import {FlatListRenderItemParams, SelectFlatList} from "../../../components/list/select-flatlist";
import {TitleBar} from "../../../components/menus/title-bar";
import {FlatList} from "../../../components/scrollables/flat-list";
import {Spacer} from "../../../components/spacer";
import {StatusBar} from "../../../components/status-bar";
import {Text} from "../../../components/texts/text";
import {Column} from "../../../components/views/columns/column";
import {Columns} from "../../../components/views/columns/columns";
import {SplashView} from "../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {ResponsiveContext} from "../../../contexts/responsive";
import {SessionCreationContext} from "../../../contexts/session-creation";
import {getClientInterpreters} from "../../../requests/clients/interpreter";
import {SMALL_SPACING} from "../../../utils/constants";
import {useInterpretersAvailabilities} from "../../../utils/hooks/use-interpreters-availabilities";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {personIdentity} from "../../../utils/identities";
import {getAllAvailableLanguages} from "../../../utils/languages";
import {ClientHomeTabNavigatorScreenProps} from "../../../utils/navigation/paramLists/client-param-list";
import {linkToCall} from "../../../utils/network/links";
import {getKeys} from "../../../utils/objects";
import {mapPageItems} from "../../../utils/pagination";
import {BACKGROUND_COLOR, SUBTLE_2} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Interpreter as InterpreterComponent} from "./modals/interpreter";
import {SearchInterpreters} from "./modals/search-interpreters";

type ListItem = Item<"id", {displayed: string; id: InterpreterPreview["identityId"]; interpreter: InterpreterPreview}>;

export const Interpreters = ({
	route, navigation, inModal,
}: ClientHomeTabNavigatorScreenProps<"Interpreters"> & {inModal?: boolean}): JSX.Element => {
	const {ct} = useTranslation();
	const listRef = React.useRef<FlatList<InterpreterElementProps>>();
	const {reset} = React.useContext(SessionCreationContext);
	const {settings: {getSetting, loading}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			interpreterFilters: {
				interpretersGroups,
				origins,
			},
			availableLanguagesCommunication: availableSessionLanguagesCommunication,
		} = {
			availableLanguagesCommunication: {} as AvailableSessionLanguagesCommunication,
			interpreterFilters: {
				interpretersGroups: [] as SessionCreationSetting["value"]["interpreterFilters"]["interpretersGroups"],
				origins: [] as SessionCreationSetting["value"]["interpreterFilters"]["origins"],
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};
	const {value: {usingTocco} = {usingTocco: false}} = getSetting<ToccoSetting>("tocco", "organization/requesters") ?? {};
	const availableLanguages = getAllAvailableLanguages(availableSessionLanguagesCommunication);
	const [filters, setFilters] = React.useState<InterpreterFilters>({});
	const [qualificationsFetched, setQualificationsFetched] = React.useState(false);
	const {columns} = React.useContext(ResponsiveContext);
	const [selectedInterpreter, setSelectedInterpreter] = React.useState<InterpreterPreview["identityId"]>();
	// Not in a ref because we pass down the value to children, so we need to re-render when its value is updated anyway
	const [availabilities, setAvailabilities] = React.useState<Record<InterpreterPreview["identityId"], AvailabilityState>>(
		{});

	React.useEffect(() => {
		if (!loading && !filters.interpretersGroups) {
			setFilters(old => ({
				...old,
				// we select all interpretersGroups by default so all the interpreters are displayed
				interpretersGroups: interpretersGroups?.map(ig => ({...ig, default: true})),
			}));
		}
	}, [loading, filters?.interpretersGroups, interpretersGroups]);

	const onPress = (id: InterpreterPreview["identityId"]): void =>
		columns >= 2
			? setSelectedInterpreter(id)
			: navigation.navigate("InterpreterModal", {interpreterId: id});
	const onPressFilters = (): void => navigation.navigate(
		"SearchInterpretersModal",
		{
			availableLanguages,
			availableOrigins: origins,
			filters: JSON.stringify(filters),
			onValidate: setFilters,
		},
	);

	useInterpretersAvailabilities({
		onData: (data) => {
			setAvailabilities(prev => {
				const res = {...prev};
				Object.entries(data).forEach(([key, value]) => {
					if (value) {
						res[key] = value;
					}
				});
				return res;
			});
		},
	});

	const renderItemSelectFlatList = ({info: {item}, buttons}: FlatListRenderItemParams<"id", ListItem>): JSX.Element => (
		<>
			<InterpreterElement
				selected={columns >= 2 && item.id === selectedInterpreter}
				onPress={() => onPress(item.id)}
				buttons={(buttons.length > 0)
					? buttons
					: [
						{
							icon: "phone",
							key: "phone",
							onPress: () => linkToCall(item.interpreter.phone),
						}, {
							icon: "plus",
							key: "createSession",
							onPress: () => {
								reset(
									{type: "new"},
									{
										filters: {
											interpretersGroups,
										},
										initialProvider: item.interpreter,
									},
								);
								navigation.navigate("NewSession");
							},
						},
					]}
				{...item}
				interpreter={availabilities[item.id]
					? {...item.interpreter, availability: availabilities[item.id]}
					: item.interpreter
				}
			/>
			<Spacer size={SMALL_SPACING}/>
		</>
	);

	/*
	 * When the navigation button relative to this screen is pressed and this screen was already active,
	 * it will trigger a refocus which is the moment we want to scroll top and maybe reload the feed
	 * https://github.com/react-navigation/native/blob/main/src/createNavigationAwareScrollable.js
	 * types does not include refocus for some reasons
	 */
	React.useEffect(
		() => {
			const unsubscribe = navigation.addListener(
				"focus",
				() => listRef.current && listRef.current.scrollToOffset({animated: true, offset: 0}),
			);
			return unsubscribe;
		},
		[navigation],
	);

	const searchRequest = React.useCallback(
		(pagination: Pagination) =>
			getClientInterpreters(pagination, filters)
				.then(data => {
					if (pagination?.offset === 0) {
						setSelectedInterpreter(data.items.length > 0 ? data.items[0]?.accountId : undefined);
					}
					return mapPageItems(data, (interpreter: InterpreterPreview) =>
						({displayed: personIdentity(interpreter), id: interpreter.identityId, interpreter}));
				}),
		[filters],
	);

	const onQualificationsFetched = React.useCallback(
		() => setQualificationsFetched(true),
		[],
	);

	return (
		<>
			{!inModal && <StatusBar/>}
			<Columns>
				{columns >= 3 && (
					<>
						<Column style={styles.filtersColumn}>
							<SearchInterpreters
								navigation={navigation as any}
								route={{
									key: route.key,
									name: "SearchInterpretersModal",
									params: {
										availableLanguages,
										availableOrigins: origins,
										filters: JSON.stringify(filters),
										onValidate: setFilters,
									},
								}
								}
								title={ct("common:filter_plural")}
								onQualificationsFetched={onQualificationsFetched}
							/>
						</Column>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
					</>
				)}
				<Column>
					<TitleBar
						title={ct("common:interpreter_plural")}
						buttons={[
							columns <= 2 && !inModal && {
								icon: "search",
								key: "filter",
								notif: getKeys(filters)
									.filter(el =>
										el !== "interpretersGroups" &&
										(usingTocco
											? el !== "qualifications"
											: true
										) &&
										filters[el],
									)
									.length,
								onPress: onPressFilters,
							},
							inModal && {
								icon: "close",
								key: "close",
								onPress: () => navigation.goBack(),
							},
						]}
						style={styles.titleBar}
					/>
					{!qualificationsFetched && columns >= 3
						? <SplashView centered loading/>
						: (
							<SelectFlatList
								getRequest={searchRequest}
								renderItem={renderItemSelectFlatList}
								itemTranslationKey="common:interpreter"
								idKey="id"
								flatListProps={{ItemSeparatorComponent: null}}
							/>
						)
					}
				</Column>
				{columns >= 2 && !inModal && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING}/>
						<Column style={[styles.activitiesColumn, !selectedInterpreter && styles.centeredContent]}>
							{selectedInterpreter
								? (
									<InterpreterComponent
										navigation={navigation as any}
										route={{key: route.key, name: "InterpreterModal", params: {interpreterId: selectedInterpreter}}}
									/>
								)
								: <Text type="label">{ct("forms:items.select", {item: ct("common:interpreter")})}</Text>
							}
						</Column>
					</>
				)}
			</Columns>
		</>
	);
};

const styles = StyleSheet.create({
	activitiesColumn: {
		backgroundColor: BACKGROUND_COLOR,
		borderColor: SUBTLE_2,
		borderWidth: 1,
		...ELEVATIONS[4],
	},
	centeredContent: {
		alignItems: "center",
		justifyContent: "center",
	},
	filtersColumn: {
		borderColor: SUBTLE_2,
		borderWidth: 1,
		flex: -1,
		flexBasis: 350,
	},
	titleBar: {
		zIndex: 200,
	},
});
