import {users as enUSUsers} from "../en-US/users";

export const users: typeof enUSUsers = {
	interpreter: {
		accepts: "{{firstName}} akzeptiert:",
		availabilities: {
			available: "Verfügbar",
			inEmergenciesDisabled: "Für Notfälle und Sofortsitzungen nicht verfügbar",
			inNights: "Für Nächte nicht verfügbar",
			inPrivateEvent: "In einer privaten Termin belegt",
			inSession: "In einer Sitzung belegt",
			inWeekend: "Für Wochenende nicht verfügbar",
			occupied: "Belegt",
			unavailable: "Bis morgen nicht verfügbar",
		},
		copyAddress: "Kopieren",
		new: "Neue Sitzung",
		qualifications: {
			certificate: "Zertifikat INTERPRET",
			exam: "Dolmetschprüfung",
			federalCertificate: "Eidgenössischer Fachausweis",
			module1: "Modul 1 INTERPRET",
			module2: "Modul 2 INTERPRET",
			module3: "Modul 3 INTERPRET",
			module4: "Modul 4 INTERPRET",
			module4a: "Modul 4a INTERPRET",
			module5: "Modul 5 INTERPRET",
			module6: "Modul 6 INTERPRET",
			module7: "Modul 7 INTERPRET",
			module8: "Modul 8 INTERPRET",
			module9: "Modul 9 INTERPRET",
			module10: "Modul 10 INTERPRET",
			qualificationA: "Qualifikation A",
			qualificationB: "Qualifikation B",
			qualificationC: "Qualifikation C",
		},
		ticket: "SBB Tickets",
	},
	menu: {
		calendar: "Auftragsübersicht",
	},
	person: {
		dateOfBirth: "Geburtsdatum",
		unknownPerson: "Unbekannte Person",
	},
	receiver: {
		ageGroup: {
			adult: "Erwachsener",
			child: "Kind oder Jugendlicher",
			label: "Altersgruppe",
		},
		insurance: {
			asylblg: "AsylbLG",
			gkv: "GKV",
			label: "Versicherung",
			none: "Nicht versichert",
			other: "Anders",
			unknown: "Unbekannt",
		},
	},
	requester: {
		cancel: "Stornieren",
	},
	settings: {
		availabilityScheduled: "Geplante Verfügbarkeit",
		availableImmediate: "Verfügbar für Sofortsessionen",
		availableNights: "Nachtverfügbarkeit ({{nightStart}} - {{nightEnd}})",
		availableWeekend: "Wochenendeverfügbarkeit ({{WEStart}} - {{WEEnd}})",
		exportAgreement: "Die Beträge und Aufschlüsselungen sind Richtwerte und unterliegen Anpassungen, die zum Zeitpunkt der Erstellung des Honorars vorgenommen werden.",
		exportCSV: "CSV exportieren",
		exportPDF: "PDF exportieren",
		feedback: "Feedback",
		notifications: {
			SMS: "SMS Benachrichtigungen",
			SMSShort: "SMS",
			eMail: "E-Mail Benachrichtigungen",
			eMailShort: "E-Mail",
			push: "Push-Benachrichtigungen",
			pushShort: "Push",
		},
		orderCards: "Validierungskarten bestellen",
		personalisedData: "Personalisierte Daten",
		privacy: "Datenschutz",
		profile: "Profil",
		savedAddresses: "Gespeicherte Adressen",
		savedSubjects: "Gespeicherte Themen",
		sessionCourse: "Kursleitung Sitzungen",
		sessionInPerson: "Vor Ort Sitzungen",
		sessionMediation: "Interkulturelle Vermittlungen Sitzungen",
		sessionModels: "Sitzungsmodelle",
		sessionPhone: "Telefon Sitzungen",
		sessionVideo: "Video Sitzungen",
		unavailableImmediate: "Nicht verfügbar für Sofortsessionen",
		unavailableNights: "Nachtverfügbarkeit ({{nightStart}} - {{nightEnd}})",
		unavailableWeekend: "Wochenendeverfügbarkeit ({{WEStart}} - {{WEEnd}})",
		update: "Einstellungen aktualisieren",
	},
};
