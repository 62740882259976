import * as React from "react";
import {SetStateAction} from "react";
import {SessionReason} from "../../@types/activities/session";
import {ReasonSettings} from "../../@types/settings";
import {FormInput} from "../../components/inputs/form";
import {Rules} from "../inputs/rules/rules";
import {useTranslation} from "./use-translation";

export const useReasonInputs = (
	settings: ReasonSettings | undefined,
	displayed: SessionReason,
	setUpdated: React.Dispatch<SetStateAction<SessionReason>>,
	textLabel: string,
): FormInput[] => {
	const {t} = useTranslation();

	const createFeedbackInput = React.useCallback(
		({id, reason}: {id: string; reason: string}, mandatory?: boolean): FormInput => ({
			icon: "feedback",
			label: reason,
			rules: mandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "select",
				onChangeValue: (checked: boolean) => checked
					? setUpdated(prev => ({...prev, selectedReasonId: id}))
					: setUpdated(prev => ({...prev, selectedReasonId: undefined})),
				value: id === displayed.selectedReasonId,
			},
		}),
		[displayed.selectedReasonId, setUpdated],
	);

	return React.useMemo(
		(): FormInput[] => {
			if (!settings) {
				return [];
			}

			const {dropdown, textField} = settings;
			let inputs: FormInput[] = [];
			if (dropdown) {
				inputs = [
					...inputs,
					dropdown.values.length > 1 && {
						disabled: true,
						label: `${t("forms:inputs.selectOneOption")}`,
						type: {
							key: "text" as const,
						},
					},
					...dropdown.values.map(c => createFeedbackInput(c, dropdown.mandatory)),
				];
			}
			if (textField) {
				inputs = [
					...inputs,
					"spacer",
					{
						icon: "comment" as const,
						label: textLabel,
						requiredLabel: true,
						rules: textField.mandatory && [{rule: Rules.notEmpty, type: "error" as const}],
						type: {
							key: "text" as const,
							onChangeValue: (reason: string) => setUpdated(prev => ({...prev, reason})),
							props: {multiline: true},
							value: displayed.reason,
						},
					},
				];
			}
			return inputs;
		},
		[settings, t, createFeedbackInput, textLabel, displayed.reason, setUpdated],
	);
};
