import {screens as enUSScreens} from "../en-US/screens";

export const screens: typeof enUSScreens = {
	addEmail: {
		title: "E-Mail-Adresse hinzufügen",
	},
	changePassword: {
		action: "Passwort ändern",
		label: "Ändern Sie Ihr Passwort",
		newPassword: "Neues Passwort",
		newPasswordConfirmation: "Neues Passwort bestätigen",
	},
	creation: {
		acceptTermsConditions: "Ich stimme den Allgemeinen Geschäftsbedingungen zu",
		comments: "Kommentare",
		durationInfo: "Die Dauer umfasst Vor- und Nachbereitungsgespräche mit der gesprächsführenden Person",
		every15Min: "Jede 15 Minuten anzeigen",
		every15MinShort: "Jede 15 Minuten",
		filters: "Dolmetscher:innenpräferenzen",
		interpretersInfo: {
			firstPart: "Dieses Symbol ",
			secondPart: " zeigt die Anzahl der potenziell verfügbaren Dolmetschenden an. Die Dolmetschenden können Ihre Anfrage je nach Verfügbarkeit annehmen oder ablehnen",
		},
		myAppointment: "Meine Termine",
		noLanguage: "In dieser Hauptsprache sind keine Sprachpaare verfügbar. Bitte ändern Sie Ihre Hauptsprache.",
		notSpeak: "Dieser oder diese Dolmetscher:in spricht nicht Ihre aktuelle Hauptsprache. Bitte ändern Sie Ihre Hauptsprache oder wählen Sie einen oder eine andere Dolmetscher:in.",
		otherProfessional: "Andere Fachperson",
		participants: "Teilnehmende",
		proximity: "Nächstgelegene Dolmetschende",
		scheduleOverlay: {
			error: "Während der Anfrage ist ein Fehler aufgetreten",
			formNotCompleted: "Nicht alle angeforderten Informationen sind vorhanden",
			immediate: "{{interpreters}} Dolmetschende werden benachrichtigt",
			missingMandatoryFields: "Bitte wählen Sie die Suchparameter",
			modifyForm: "Bitte ändern Sie die Suchparameter",
			noInterpreterAvailable: "Es stehen keine Dolmetschenden zur Verfügung",
			noSlots: "Es sind keine Zeitfenster verfügbar",
			waitingTime: "Bitte warten Sie bis zu 10 Min",
		},
		scheduling: {
			immediate: "Sofort",
			scheduled: "Planbar",
			scheduling: "Termin",
		},
		searchAddress: "Eine Adresse suchen",
		seeTermsConditions: "Siehe die Allgemeinen Geschäftsbedingungen",
		selectDate: {
			anotherDay: "Anderen Tag wählen",
			sundayWarning: "Zur Erinnerung: Sonntagsarbeit ist genehmigungspflichtig. Wenn möglich, bitten wir Sie, Ihren Antrag an einem Arbeitstag zu stellen",
		},
		selectLanguage: "Sprache auswählen",
		toXInterpreters: "Sitzung wird an {{n}} Dolmetschende gesendet",
	},
	feedback: {
		actions: {
			sessionCreation: "der Buchungsfunktion",
			sessionSearch: "der Suchfunktion",
		},
		comments: "Kommentare",
		commentsPlaceholder: "Teilen Sie uns Ihre Meinung mit...",
		header: "Feedback",
		otaSubtitle: "Ein neues Minor-Update ist verfügbar, bitte starten Sie die Anwendung neu",
		otaTitle: "Neues Minor-Update verfügbar",
		rating: "Bewertung",
		title: "Wie war Ihre Erfahrung mit {{action}}?",
	},
	home: {
		availability: "Ihre sofortige Verfügbarkeit",
		book: "{{name}} buchen",
		choose: "Sprache auswählen",
		current: "Aktuelle Sitzung",
		emptyMandate: "Derzeit keine Anfrage",
		emptyNotification: "Keine Benachrichtigungen",
		emptyUpcoming: "Keine kommende Sitzung",
		find: "Finden Sie einen oder eine Dolmetscher:in",
		mandate: "Ihre Anfragen",
		sessionLanguages: "Sprachen der Sitzung",
		unavailibilitiesUpdateReminder: {
			no: "Nein, bringen Sie mich zum Kalender",
			subtitle: "Tragen Sie jetzt Ihre Abwesenheiten in die Agenda ein. So wissen die Kunden, wann Sie verfügbar sind.",
			title: "Haben Sie daran gedacht, Ihre Agenda zu aktualisieren?",
			yes: "Ja, sie sind auf dem neuesten Stand",
		},
		unvalidated: "Sitzung zum Validieren",
		unvalidated_plural: "Sitzungen zum Validieren",
		unvalidatedWarning: "Nur die validierten Sitzungen werden bezahlt",
		upcoming: "Kommende Sitzung",
		updateBanner: {
			appStore: "den App Store",
			googlePlay: "den Google Play",
			later: "Später",
			subtitle: "Es gibt eine neuere Version zum Herunterladen! Bitte aktualisieren Sie die App, indem Sie {{store}} besuchen",
			title: "Neue Version verfügbar",
			update: "Update",
		},
		welcome: "Willkommen, {{firstName}}",
	},
	imagePicker: {
		permissions: {
			settings: {
				camera: "Um Fotos zu senden, erlaube Bhaasha den Zugriff auf die Camera in den Einstellungen deines Geräts",
				library: "Um Medien zu senden, erlaube Bhaasha den Zugriff auf Fotos, Medien und Dateien in den Einstellungen deines Geräts",
			},
		},
	},
	notifications: {
		anotherDevice: "Ein anderes Gerät ist bereits für den Empfang von Benachrichtigungen für dieses Konto konfiguriert",
		configure: "Konfigurieren",
		permissions: {
			enable: "Push-Benachrichtigungen aktivieren",
			subtitle: "Um Push-Benachrichtigungen zu erhalten, müssen Sie diese zuerst aktivieren",
		},
		replace: "Mit diesem Gerät ersetzen",
	},
	overlappingSessions: {
		explanation: "Die Abwesenheit konnte nicht erfasst werden, da Sie folgende Sessionen haben:",
	},
	profile: {
		edit: "Profil bearbeiten",
		guide: "Anleitung & FAQ",
		knownIssues: "Bekannte Probleme",
		slides: "Einführungsfolien",
		support: "Kontakt zum Support",
		update: "Profil aktualisieren",
	},
	recover: {
		label: "Passwort zurücksetzen",
	},
	salary: {
		fees: "Betrag",
		monthIncomes: "Einkommen im {{month}}",
	},
	shareSession: {
		title: "Sitzung teilen",
		titleVideo: "Link zur Videositzung teilen",
	},
	signIn: {
		banner: {
			bullet1: "Leichterer Zugang zu Dolmetschern und Dolmetscherinnen und Auftragsübersicht",
			bullet2: "Blitzschneller Buchungsprozess",
			bullet3: "Reibungslose Profil- und Kontoverwaltung",
			subTitle: "Eine noch bessere Benutzeroberfläche, um Dolmetscher:innen so einfach wie möglich zu buchen.",
			title: "Neu! Bhaasha ist jetzt noch leistungsfähiger",
		},
		iam: "Ich bin",
		label: "Anmelden um fortzufahren",
		legacy: "Alte App (provisorischer Zugang)",
		login: "Login",
		requestAccess: {
			button: "Fordere einen Zugang an",
			title: "Noch kein Login?",
		},
		subAction: "Passwort vergessen?",
	},
	signOut: {
		confirm: "Sind Sie sicher, dass Sie sich abmelden möchten?",
	},
	sliders: {
		welcome: {
			calendar: {
				description: "Überprüfen Sie Ihre Sitzungen und passen Sie Ihre Verfügbarkeit genau an, indem Sie private Termine hinzufügen, um Zeit in Ihrer Woche zu blockieren",
				title: "Ein leistungsfähiger Kalender zur Planung Ihres Tages",
			},
			checks: {
				description: "Wir wünschen Ihnen viel Spaß mit der neuen App!",
				title: "Sie können sofort loslegen und entdecken",
			},
			dots: {
				description: "Mit Fokus auf Produktivität und einer neuen intuitiven Benutzeroberfläche wurde die Dolmetscher:in-App von Bhaasha für Sie neu entwickelt",
				title: "Willkommen auf der professionellen Plattform für Dolmetscher:in",
			},
			inbox: {
				description: "Ändern Sie Ihren Status, greifen Sie schnell auf Ihre nächste Sitzung zu und erhalten Sie Ihre neuen Anfragen - alles über Ihren Inbox",
				title: "Ihr Inbox, damit Sie immer einen Überblick haben",
			},
			incomes: {
				description: "Verwalten Sie Ihr Arbeitspensum und verfolgen Sie Ihre Fortschritte mit integrierten Verwaltungsfunktionen",
				title: "Ihre Statistiken und Konten, auf einen Blick",
			},
			notifications: {
				description: "Erhalten Sie Ihre neuen Anfragen in Echtzeit und haben Sie sofortigen Einblick in alle Details, bevor Sie sie annehmen",
				title: "Ihre Benachrichtigungen, zur richtigen Zeit geliefert",
			},
			validation: {
				description: "Der neue Validierungsprozess kombiniert Flexibilität und Genauigkeit, damit Sie mit Zuversicht zur nächsten Sitzung übergehen können",
				title: "Validierung Ihrer Sitzungen, vereinfacht",
			},
		},
		welcomeRequester: {
			account: {
				description: "Ein rundum verbesserter Arbeitsablauf mit erweiterten Verwaltungsfunktionen",
				title: "Verwalten Sie Auftraggebende Personen, Adressen und weiteres direkt in Ihrem Profil",
			},
			calendar: {
				description: "Durchsuchen Sie all Ihre zukünftigen und vergangenen Aufträge mit Leichtigkeit. Planen Sie mit wenigen Klicks neu, stornieren Sie oder erstellen Sie eine Folgebuchung.",
				title: "Eine leistungsstarke Auftragsübersicht, um den Durchblick zu behalten",
			},
			checks: {
				description: "Wir wünschen Ihnen viel Spaß mit der neuen App!",
				title: "Sie sind startklar!",
			},
			communication: {
				description: "Sprechen Sie mit Ihrem oder Ihrer Dolmetscher:in  vor Ort, per Telefon oder Video auf eine sichere Weise mit unserer integrierten Kommunikationstechnologie*\n*Je nach Verfügbarkeit.",
				title: "Kommunizieren Sie überall in der gewünschten Kommunikationsart",
			},
			languages: {
				description: "Wählen Sie zunächst eine Sprache, um eine neue Anfrage zu erstellen und Ihren oder Ihre Dolmetscher:in zu finden.",
				title: "Die neue Startseite",
			},
			support: {
				description: "Schnell zugängliche Ressourcen, um Ihnen einen reibungslosen Start in die neue Benutzeroberfläche zu ermöglichen.",
				title: "Benutzerhandbuch & Support",
			},
			timeslots: {
				description: "Wählen Sie das Profil Ihrers oder Ihrer Dolmetschenden - einschließlich Geschlecht, Herkunftsland und weiteren Kriterien. Planen Sie mithilfe eines intelligenten Kalenders - Dolmetscher:innen, die am nächsten sind, werden als erstes benachrichtigt.",
				title: "Finden Sie den oder die Dolmetscher:in, für sofortige und geplante Einsätze",
			},
			welcome: {
				description: "Mit einer neuen, leistungsfähigeren und intuitiven Benutzeroberfläche finden Sie Ihren oder Ihre Dolmetscher:in noch einfacher und schneller.",
				title: "Unsere neue Plattform",
			},
		},
		welcomeRequesterMobile: {
			calendar: {
				title: "Ein leistungsstarker Kalender zur Verwaltung Ihrer Buchungen",
			},
			communication: {
				title: "Eine Komplettlösung für Präsenz-/, Telefon-/ und Videosessionen",
			},
			home: {
				title: "Hochwertiger Dolmetschservice auf Knopfdruck",
			},
			session: {
				title: "Übersichtliche und einfache Anzeige der Sessionen",
			},
			welcome: {
				description: "Eine neue und leistungsstarke Dolmetschenden- Buchungsoberfläche",
				title: "Bhaasha Klienten Plattform",
			},
		},
	},
	unavailability: {
		addressExplanation: "Die Adresse wird zur Berechnung der Fahrzeiten für nachfolgende Sessionen verwendet. Sie hat keinen Einfluss auf die Vergütung.",
	},
	videoSession: {
		confirmVideoExit: "Möchten Sie den Videoanruf verlassen?",
		copySupportNumber: "Supportnummer Kopieren",
		disableAudio: "Audio deaktivieren",
		disableBlur: "Unschärfe deaktivieren",
		disableVideo: "Video deaktivieren",
		enableAudio: "Audio aktivieren",
		enableBlur: "Unschärfe aktivieren",
		enableVideo: "Video aktivieren",
		flipCamera: "Kamera wechseln",
		joinMessage: "Mit dem Betreten des Raumes bestätige ich, dass ich mich in einer ruhigen Umgebung befinde, in der ich die Privatsphäre garantieren kann.\n\nDie Validierung wird nach der Sitzung automatisch vom System durchgeführt.",
		joinTitle: "Möchten Sie teilnehmen?",
		noParticipants: "Keine anderen Teilnehmer im Anruf.",
		otherOptions: "Sonstige Optionen",
		participantsPending: "Warten auf die anderen Teilnehmer...",
		selectDevice: "Gerät auswählen",
	},
};
