import * as React from "react";
import {StyleSheet, View} from "react-native";
import {InterpreterPreview} from "../../../../@types/identities/interpreter";
import {SupportInfosSetting} from "../../../../@types/settings";
import {SupportMessage} from "../../../../@types/support-message";
import {Button} from "../../../../components/buttons/button";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {Separator} from "../../../../components/separator";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {postSupportMessage} from "../../../../requests/common/account/support";
import {CONTAINERS, DEFAULT_SPACING, IS_CLIENT, IS_INTERPRETER, SMALL_SPACING} from "../../../../utils/constants";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {SharedScreenProps} from "../../../../utils/navigation/paramLists/root-param-list";
import {linkToCall, linkToMail} from "../../../../utils/network/links";
import {removeNonPhoneNumberCharacters} from "../../../../utils/strings";
import {forceBack} from "../../../navigation";

export interface SupportInfos {
	email: string;
	phone: string;
}

type SupportState = SupportInfos & SupportMessage;

const Support = ({route, navigation}: SharedScreenProps<"SupportModal">): JSX.Element => {
	const {sessionId, subject} = route.params ?? {};
	const {ct, t} = useTranslation();
	const {settings: {getSetting}, identity} = React.useContext(AuthentifiedContext);
	const {value} = getSetting<SupportInfosSetting>("support/infos", "organization/all") || {};

	const getBase = React.useMemo(
		() => {
			const data: Partial<SupportState> = ({
				...value,
				identity: undefined,
				message: undefined,
				sessionId,
				subject,
			});
			if (IS_INTERPRETER) {
				const connectedIdentity = identity as InterpreterPreview;
				data.identity = {
					email: connectedIdentity.email,
					fullName: `${connectedIdentity.fullName.firstName} ${connectedIdentity.fullName.lastName}`,
					phone: connectedIdentity.phone,
				};
			}
			return data as SupportState;
		},
		[identity, sessionId, subject, value],
	);
	const {
		loading, errorMessage, displayed, isUpdated, setUpdated,
	} = useForm<SupportState>(getBase, "getSupportInfosFailed");
	const header = <HeaderMenu center={t("screens:profile.support")} exitConfirmation={isUpdated}/>;

	const inputs: FormProps["inputs"] = [];
	if (IS_CLIENT) {
		inputs.push(
			{
				icon: "person",
				label: ct("common:fullName"),
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "firstName",
					onChangeValue: (fullName: string) => setUpdated(prev => ({...prev, identity: {...prev.identity, fullName}})),
					value: displayed.identity?.fullName,
				},
			},
			"spacer",
			{
				icon: "mail",
				label: ct("common:email"),
				rules: [
					{rule: Rules.notEmpty, type: "error"},
					{rule: Rules.validation.email, type: "error"},
				],
				type: {
					key: "email",
					onChangeValue: (email: string) => setUpdated(prev => ({...prev, identity: {...prev.identity, email}})),
					value: displayed.identity?.email,
				},
			},
			{
				icon: "phone",
				label: ct("common:phone"),
				rules: [
					{rule: Rules.notEmpty, type: "error"},
					{rule: Rules.validation.phone, type: "error"},
				],
				type: {
					key: "phone",
					onChangeValue: (p?: string) => setUpdated(prev => ({...prev, identity: {...prev.identity, phone: p ? removeNonPhoneNumberCharacters(p) : undefined}})),
					value: displayed.identity?.phone,
				},
			},
			"spacer",
		);
	}
	if (sessionId) {
		inputs.push({
			disabled: true,
			icon: "id",
			label: t("activities:sessions.validate.sessionId"),
			type: {
				key: "text",
				value: `#${sessionId}`,
			},
		});
	}
	if (subject) {
		inputs.push({
			disabled: true,
			icon: "checkList",
			label: ct("common:subject"),
			type: {
				key: "text",
				value: t(`activities:sessions.support.${subject}`),
			},
		});
	}
	if (subject !== "validation-cards") {
		inputs.push({
			icon: "comment",
			label: ct("common:message"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (message: string) => setUpdated(prev => ({...prev, message})),
				props: {multiline: true},
				value: displayed.message,
			},
		});
	}

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {
						disabled: loading || !!errorMessage,
						icon: "check",
						text: ct("common:send"),
					},
					onValidation: () => postSupportMessage(displayed)
						.then(() => {
							Log.success("messageSentSuccess")();
							navigation.dispatch(forceBack);
						})
						.catch(Log.error("messageSentFailed")),
				}}
				inputs={inputs}
				footerComponent={(
					<View style={[CONTAINERS.MAIN, styles.infosContainer]}>
						<Separator/>
						<Button
							fullWidth
							disabled={loading || !!errorMessage}
							size="small"
							type="secondary"
							text={displayed.email}
							icon="mail"
							style={styles.button}
							onPress={() => linkToMail(displayed.email)}
						/>
						<Button
							fullWidth
							disabled={loading || !!errorMessage}
							size="small"
							type="secondary"
							text={displayed.phone}
							icon="phone"
							style={styles.button}
							onPress={() => linkToCall(displayed.phone)}
						/>
					</View>
				)}
			/>
		</>
	);
};

const styles = StyleSheet.create({
	button: {
		marginTop: SMALL_SPACING,
	},
	infosContainer: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: SMALL_SPACING,
	},
});

export const SupportModal = (props: SharedScreenProps<"SupportModal">): JSX.Element => (
	<ModalWrapper>
		<Support {...props} />
	</ModalWrapper>
);
