import {Transport} from "../../navigation/screens/interpreter/modals/select-transport";
import {Requalize} from "../../utils/types";
import {PersonPreviewProviderCommon, ProvidedServiceType} from "../activities/mandate";
import {CommunicationType} from "../activities/session";
import {AvailabilityState} from "../availability";
import {LanguageTranslation} from "../language-translation";
import {Place} from "../places";
import {Qualification} from "../qualifications";
import {PersonCommon, PersonPreviewCommon} from "./person";

export interface Interpreter extends Requalize<PersonCommon, "email" | "origin" | "phone"> {
	availability?: AvailabilityState;
	communicationTypes: CommunicationType[];
	fullName: {
		firstName: string;
		lastName: string;
	};
	languages: LanguageTranslation[];
	place: Place;
	providedServices: ProvidedServiceType[];
	qualifications: {[section: string]: Qualification[]};
	transport: Transport;
	type: "interpreter";
}

export type InterpreterPreview =
	PersonPreviewCommon
	& Pick<Interpreter, "availability" | "communicationTypes" | "email" | "fullName" | "languages" | "origin" | "picture" | "providedServices" | "type">;

type InterpreterProviderPreview = InterpreterPreview & PersonPreviewProviderCommon;

export const waveTypes = ["first", "last"] as const;
type WaveType = typeof waveTypes[number];

export interface WaveInterpreterProvider extends InterpreterProviderPreview {
	wave?: WaveType;
}
