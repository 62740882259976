import * as Device from "expo-device";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {LocaleContext} from "../../contexts/locale";
import {APP_STORE_URL, DEFAULT_SPACING, IS_MOBILE, IS_WEB, PLAY_STORE_URL} from "../../utils/constants";
import {Images} from "../../utils/externals/images";
import {AsyncStorage} from "../../utils/externals/storages/async-storage";
import {Log} from "../../utils/logs/logs";
import {linkToURL} from "../../utils/network/links";
import {CLIENT_BG, SUBTLE_3} from "../../utils/styles/colors";
import {Image} from "../images/image";
import {Spacer} from "../spacer";
import {Pressable} from "../views/pressable";
import {Banner} from "./banner";

const AppDownload = ({store}: {store: "apple" | "google"}): JSX.Element => {
	const {locale} = React.useContext(LocaleContext);

	const storeRedirect = React.useCallback(
		() => {
			linkToURL(store === "apple" ? APP_STORE_URL : PLAY_STORE_URL).catch(Log.error());
		},
		[store],
	);

	return (
		<Pressable
			onPress={storeRedirect}
		>
			<Image
				sourceType="require"
				mime="image/png"
				source={store === "apple" ? Images.Download[locale].appStore : Images.Download[locale].playStore}
				width={140}
				height={40}
				resizeMode="contain"
			/>
		</Pressable>
	);
};

export const Apps = (): JSX.Element => {
	return (
		<View style={styles.storeButtons}>
			{Device.osName !== "Android" && (
				<AppDownload store="apple" />
			)}
			{Device.osName !== "Android" && Device.osName !== "iOS" && Device.osName !== "iPadOS" && (
				<Spacer size={DEFAULT_SPACING} mode="vertical" />
			)}
			{Device.osName !== "iOS" && Device.osName !== "iPadOS" && (
				<AppDownload store="google" />
			)}
		</View>
	);
};

const bannerName = "BannerApps";
export const BannerApps = ({backgroundColor}: {backgroundColor?: string}): JSX.Element | null => {
	const [showBannerApps, setShowBannerApps] = React.useState(false);

	React.useEffect(
		() => {
			IS_WEB && AsyncStorage.getItem(bannerName)
				.then((bannerDate) => {
					if (!bannerDate) {
						setShowBannerApps(true);
					}
					AsyncStorage.setItem(bannerName, new Date().toISOString()).catch(Log.error());
				})
				.catch(Log.error());
		},
		[],
	);

	if (IS_MOBILE) {
		return null;
	}

	return (
		<Banner backgroundColor={backgroundColor} open={showBannerApps} closable timer={60_000} style={styles.banner}>
			<View style={styles.wrapper}>
				<Apps />
			</View>
		</Banner>
	);
};

const styles = StyleSheet.create({
	banner: {
		backgroundColor: CLIENT_BG,
		borderColor: SUBTLE_3,
		borderWidth: 1,
	},
	storeButtons: {
		flexDirection: "row",
	},
	wrapper: {
		alignItems: "center",
	},
});
