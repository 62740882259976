import * as React from "react";
import {StyleSheet, View} from "react-native";
import Collapsible from "react-native-collapsible";
import {Button, ButtonProps} from "./buttons/button";

interface Props {
	buttonPosition?: "bottom" | "top";
	buttonProps?: ButtonProps;
	children: React.ReactElement;
	collapsedTitle?: string;
}

export const CollapsibleBlock = ({buttonProps, children, collapsedTitle, buttonPosition = "top"}: Props): JSX.Element => {
	const [collapsed, setCollapsed] = React.useState(false);

	const onPressCollapse = React.useCallback(
		() => setCollapsed(prev => !prev),
		[],
	);

	const ButtonElement = (
		<Button
			size="xsmall"
			type="secondary"
			onPress={onPressCollapse}
			text={collapsed ? collapsedTitle : undefined}
			icon={collapsed
				? buttonPosition === "top"
					? "chevronUp"
					: "chevronBottom"
				: buttonPosition === "top"
					? "chevronBottom"
					: "chevronUp"
			}
			iconPosition="after"
			{...buttonProps}
			style={[buttonProps?.style, styles.button, !collapsed && (buttonPosition === "top" ? styles.openedTop : styles.openedBottom)]}
		/>
	);

	return (
		<View style={styles.container}>
			{buttonPosition === "top" && ButtonElement}
			<Collapsible collapsed={collapsed}>
				{children}
			</Collapsible>
			{buttonPosition === "bottom" && ButtonElement}
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		zIndex: 100,
	},
	container: {
		flexDirection: "column",
	},
	openedBottom: {
		marginTop: -15,
	},
	openedTop: {
		marginBottom: -15,
	},
});
