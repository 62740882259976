import {InterpreterInboxMandatePreview} from "../../../@types/activities/mandate";
import {DataGetter} from "../../../utils/hooks/use-websocket";
import {WebSocketMandates} from "../../@types/session";
import {transformBackendResponseToCalendarInterpreterMandatePreview} from "../../utils/transform";

export type InterpreterSessionsFilterRequests = {
	GET: {
		queryParams: {
			interpreterId: string,
			order?: string,
			limit?: string,
			offset?: string,
			status?: string,
			lockType?: string,
			searchText?: string,
			startingTime?: string,
		},
	},
};

export const getUpcomingSessionsData: DataGetter<InterpreterInboxMandatePreview[]> = {
	getData: (message: WebSocketMandates) => message.data.map(
		(session) => transformBackendResponseToCalendarInterpreterMandatePreview(session)),
	key: "upcomingSessions",
};
