import {SessionLanguagesKey} from "../../utils/sessions/languages";
import {Merge} from "../../utils/types";
import {BillingAddress} from "../billing-address";
import {InterpreterPreview} from "../identities/interpreter";
import {ExternalImageProps} from "../medias/image";
import {Place} from "../places";
import {ActivityCommon} from "./activity";
import {
	InterpreterCalendarMandatePreview,
	InterpreterInboxMandatePreview,
	InterpreterMandate,
	InterpreterSalaryMandatePreview,
	RequesterCalendarMandatePreview,
} from "./mandate";

export const sessionStatus = [
	"sent",
	"confirmed",
	"refused",
	"canceled",
	"unvalidated",
	"completed",
	"inProgress",
	"stopped",
	"rescheduled",
	"deleted",
] as const;
export type SessionStatus = typeof sessionStatus[number];

export const contexts = ["validate", "reschedule", "message", "refused"] as const;
export type Context = typeof contexts[number];

export type SessionAction =
	| "accept"
	| "calendar"
	| "cancel"
	| "CFF"
	| "copyAddress"
	| "fairtiq"
	| "followUp"
	| "ignore"
	| "message"
	| "navigate"
	| "phoneCall"
	| "read"
	| "refuse"
	| "reschedule"
	| "share"
	| "validate"
	| "videoCall";

export const communicationTypes = ["inPerson", "phone", "video"] as const;
export type CommunicationType = typeof communicationTypes[number];

interface CommunicationTypeCommon {
	type: CommunicationType;
}

export interface InPerson extends CommunicationTypeCommon {
	place: Place;
	placeInfos?: string;
	type: "inPerson";
}

export interface Video extends CommunicationTypeCommon {
	channelId: string;
	secret?: string;
	token?: string;
	type: "video";
}

export interface Phone extends CommunicationTypeCommon {
	phoneNumber: string;
	type: "phone";
}

export type Communication = InPerson | Phone | Video;

export const sessionTypes = ["interpreterMandate"] as const;
export type SessionType = typeof sessionTypes[number];

export interface EmailFeedback {
	ccEmail: string;
	receiverEmail: string;
	time: Date;
}

export const sessionContextTypes = ["new", "followUp", "reschedule"] as const;
export type SessionContextType = typeof sessionContextTypes[number];

export interface FollowUpSettings {
	disableCommunicationTypeChange: boolean;
	// In minutes. 0 means no limitation.
	minimumDuration: number;
}

export interface SessionReason {
	reason?: string;
	selectedReasonId?: string;
}

export type SessionRefusal = SessionReason;

export interface SessionCancelOrReschedule extends SessionReason {
	canceledByProvider?: boolean;
	keepExcludedInterpreters?: boolean; // if true, keep the interpreters who were excluded in the base session
	sendOnlyToCurrentProvider?: boolean; // if true, send only to the provider who accepted the base session
}

interface SessionContextCommon {
	type: SessionContextType;
}

interface OtherSessionContext extends SessionContextCommon {
	type: "followUp" | "new";
}

interface RescheduleSessionContext extends SessionContextCommon {
	rescheduleData: SessionCancelOrReschedule;
	type: "reschedule";
}

export type SessionContext = OtherSessionContext | RescheduleSessionContext;

export interface SessionCommon extends ActivityCommon {
	billingAddress?: BillingAddress;
	cancelable?: boolean;
	communication: Communication;
	confirmedFeedback?: EmailFeedback;
	context: SessionContext;
	disabledActions?: SessionAction[];
	expectedEnd: Date;
	expectedStart: Date;
	immediate?: boolean;
	internalComment?: string;
	language?: SessionLanguagesKey;
	locked?: boolean;
	shareFeedbacks?: Omit<EmailFeedback, "ccEmail">[];
	status: SessionStatus;
	subject?: string;
	toccoBillingInformation?: string;
	toccoPeopleNumber?: number;
	type: SessionType;
	validationPicture?: ExternalImageProps;
}

export type SessionPreview = Merge<
InterpreterCalendarMandatePreview | InterpreterInboxMandatePreview | InterpreterSalaryMandatePreview | RequesterCalendarMandatePreview
>;
export type Session = InterpreterMandate;

export interface SessionSlot {
	availableInterpreterIds: InterpreterPreview["accountId"][];
	end: Date;
	start: Date;
}
