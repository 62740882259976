import React from "react";
import {AppState} from "react-native";
import reactUseWebSocket from "react-use-websocket";
import {AuthentifiedContext} from "../../contexts/authentified";
import {DISABLE_WEBSOCKET, IS_INTERPRETER, WS_HOST, WS_PROTOCOL} from "../constants";

export interface MessageReceived {
	[other: string]: any;

	key: string;
}

export type MessageSent = Record<string, unknown>;

export interface DataGetter<T> {
	getData: (message: MessageReceived) => T;
	key: string;
}

interface Handler<T> {
	dataGetter: DataGetter<T>;
	// What happens when a message is received.
	onData: (response: T) => void;
	// Whether to ask for new update of the data when the app is focused back. Defaults to true.
	updateOnAppFocus?: boolean;
}

interface Params<T> {
	/*
	 * What to do when the websocket connection is available.
	 * Also possible to send a message at that time by returning it.
	 */
	// onMount?: () => MessageSent,
	/*
	  * What to do when the hook will unmount. Also possible to send a message at that time by returning it.
	  */
	// onUnmount?: () => MessageSent,
	/*
	   * Whether to open a connection or not.
	   * Used when we want to open a connection only when a condition is true. Default true.
	   */
	connect?: boolean;
	handlers: Handler<T>[];
	// What happens when a websocket connection error happens.
	onWSError?: (error: Event) => void;
	/*
	 * The url to use; by default, will use the WS_PROTOCOL, WS_HOST and current account to create an url and connect
	 * to backend.
	 */
	url?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWebSocket = <T>({
	handlers,
	onWSError,
	/*
	 * onMount,
	 * onUnmount,
	 */
	connect = true,
	url,
}: Params<T>) => {
	const {accountId, authToken} = React.useContext(AuthentifiedContext);
	/*
	 * Used to know whether unMount has been called once to call it as soon as the `connect` is true (which can be false
	 * when this hook mounts, so we need to do it this way.
	 */
	// const onMountCalled = React.useRef(false);

	/*
	 * A reference on our local unmount, always kept up to date according to its dependencies. We need a ref to avoid
	 * any dependency in the useEffect that will run it so that will be called only when this hook unmounts.
	 */
	// const onUnmountRef = React.useRef<() => void>();

	const appState = React.useRef(AppState.currentState);

	const {sendJsonMessage, ...others} = reactUseWebSocket(
		/*
		 * Connect to dummy url if accountId or auth token become null; handles edges cases when authentication is being
		 * done of logout is performed and one of the two value is not there.
		 */
		url ?? (
			accountId && authToken
				? `${WS_PROTOCOL}://${WS_HOST}?id=${accountId}&authToken=${authToken}&role=${IS_INTERPRETER ? "interpreter" : "requester"}`
				: "wss://dummy"
		),
		{
			onError: (error) => onWSError?.(error),
			onMessage: (event: MessageEvent<string>) => {
				const data: MessageReceived = JSON.parse(event.data);
				handlers.forEach(({onData: onMessage, dataGetter: {getData, key}}) => {
					if (data.key === key) {
						onMessage(getData(data));
					}
				});
			},
			/*
			 * Since there no way to set infinite reconnect, we just set a high number.
			 * The reconnect number is not reset even when changing url, so no other way to do it.
			 */
			reconnectAttempts: 1_000_000_000,
			share: true,
			shouldReconnect: () => connect,
		},
		!DISABLE_WEBSOCKET && connect,
	);

	/*
	 * // Used to trigger the actions on mount/unmount and set the ref of what to do when component unmounts.
	 * React.useEffect(
	 *  () => {
	 *   if (connect && onMount && !onMountCalled.current) {
	 *    onMountCalled.current = true;
	 *     const initMessage = onMount();
	 *      if (initMessage) sendJsonMessage(initMessage);
	 *   }
	 *
	 *  onUnmountRef.current = () => {
	 *   if (connect && onUnmount) {
	 *    const endMessage = onUnmount();
	 *    if (endMessage) sendJsonMessage(endMessage);
	 *   }
	 *  };
	 * },
	 * [connect, onMount, onUnmount, sendJsonMessage],
	 * );
	 */

	/*
	 * React.useEffect(
	 *  // We don't want to move function in outer scope, because we don't want any dependency for this useEffect so that
	 *  // it runs only when this hook unmounts. It's also the reason why we're using a ref here.
	 *  // eslint-disable-next-line unicorn/consistent-function-scoping
	 *  () => () => onUnmountRef.current?.(),
	 *  [],
	 * );
	 */

	// Ask for the latest data when app was inactive or in background
	React.useEffect(
		() => {
			const subscription = AppState.addEventListener("change", nextAppState => {
				if (appState.current.includes("background") && nextAppState === "active") {
					// Ask latest data for each of the keys
					handlers.forEach(({dataGetter: {key}, updateOnAppFocus}) =>
						(updateOnAppFocus || updateOnAppFocus === undefined) && sendJsonMessage({key}));
				}
				appState.current = nextAppState;
			});
			return () => subscription?.remove();
		},
		[handlers, sendJsonMessage],
	);

	return {sendJsonMessage, ...others};
};
