import * as React from "react";
import {LayoutChangeEvent, LayoutRectangle, StyleProp, View, ViewStyle} from "react-native";

interface Props {
	color: string;
	direction?: "horizontal" | "vertical";
	gaps: {empty: number; full: number};
	style?: StyleProp<ViewStyle>;
}

export const Dash = ({style, gaps, color, direction: directionProp}: Props): JSX.Element => {
	const [layout, setLayout] = React.useState<LayoutRectangle>({height: 0, width: 0, x: 0, y: 0});
	const direction = directionProp ?? "vertical";

	const length = direction === "vertical" ? layout.height : layout.width;
	const thickness = direction === "vertical" ? layout.width : layout.height;

	// The length of a full + empty gaps dash part.
	const partLength = gaps.full + gaps.empty;
	// Number of bars, excluding the last bigger half-bar.
	const n = Math.floor(length / partLength);

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getDimensions = (l: number) =>
		direction === "vertical" ? {height: l, width: thickness} : {height: thickness, width: l};

	const renderFull = (l = gaps.full): JSX.Element => <View style={[getDimensions(l), {backgroundColor: color}]} />;
	const renderEmpty = (l = gaps.empty): JSX.Element => <View style={getDimensions(l)} />;

	const onLayout = (event: LayoutChangeEvent): void => setLayout(event.nativeEvent.layout);

	return (
		<View style={[getDimensions(length), style]} {...{onLayout}}>
			{Array.from({length: n}).map((_, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<React.Fragment key={i}>
					{renderFull()}
					{renderEmpty()}
				</React.Fragment>
			))}
			{renderFull(length - (n * partLength))}
		</View>
	);
};
