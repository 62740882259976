import * as React from "react";
import {StyleSheet, View} from "react-native";
import {InterpreterPreview} from "../../../@types/identities/interpreter";
import {filterTruthy} from "../../../utils/arrays";
import {CONTAINERS, SMALL_SPACING} from "../../../utils/constants";
import {getAvailabilityColorFromReason} from "../../../utils/hooks/use-interpreters-availabilities";
import {personIdentity} from "../../../utils/identities";
import {BACKGROUND_COLOR, CONTRAST, SUBTLE, WHITE} from "../../../utils/styles/colors";
import {ELEVATIONS} from "../../../utils/styles/elevations";
import {Button, Buttons} from "../../buttons/button";
import {Image} from "../../images/image";
import {ProviderLabels} from "../../labels/provider";
import {Spacer} from "../../spacer";
import {Text} from "../../texts/text";
import {Pressable, PressableProps} from "../../views/pressable";

export interface InterpreterElementProps extends PressableProps {
	buttons?: Buttons;
	interpreter: InterpreterPreview;
	selected?: boolean;
}

const PICTURE_SIZE = 50;

export const InterpreterElement = ({interpreter, buttons, selected, style, ...touchableProps}: InterpreterElementProps): JSX.Element => {
	const {communicationTypes, picture, providedServices, availability} = interpreter;
	const color = availability
		? getAvailabilityColorFromReason(availability)
		: undefined;

	return (
		<Pressable
			style={[styles.wrapper, style]}
			disabled={!touchableProps.onPress}
			underlayColor={SUBTLE}
			{...touchableProps}
		>
			<View style={[CONTAINERS.MAIN, styles.container, {backgroundColor: selected ? CONTRAST : BACKGROUND_COLOR}]}>
				<View>
					{picture && <Image
						source={picture?.source}
						fullWidth={picture?.fullWidth}
						fullHeight={picture?.fullHeight}
						resizeMode={picture?.resizeMode}
						mime={picture?.mime}
						sourceType={picture?.sourceType}
						width={PICTURE_SIZE}
						height={PICTURE_SIZE}
						style={styles.picture}
					/>}
					{color && <View style={[styles.available, {backgroundColor: color}]} />}
				</View>
				<View style={styles.col2}>
					<Text type="emphasis_1" style={styles.name}>{personIdentity(interpreter)}</Text>
					<ProviderLabels
						communicationTypes={communicationTypes}
						providedServices={providedServices}
					/>
				</View>
				{/* b => !!b let us do some x && ... in the array just like in styles */}
				{buttons && filterTruthy(buttons).map((buttonProps) => (
					<React.Fragment key={buttonProps?.key}>
						<Spacer size={SMALL_SPACING} mode="vertical" />
						<Button type="secondary" size="small" {...buttonProps}/>
					</React.Fragment>
				))}
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	available: {
		borderColor: WHITE,
		borderRadius: 8,
		borderWidth: 2,
		bottom: 0,
		height: 16,
		position: "absolute",
		right: 0,
		width: 16,
	},
	col2: {
		flex: 1,
		marginLeft: SMALL_SPACING,
	},
	container: {
		alignItems: "center",
		borderRadius: SMALL_SPACING,
		flexDirection: "row",
		padding: SMALL_SPACING,
		...ELEVATIONS[4],
	},
	name: {
		marginBottom: 3,
		marginTop: -2,
	},
	picture: {
		borderRadius: PICTURE_SIZE / 2,
	},
	wrapper: {
		paddingHorizontal: SMALL_SPACING,
	},
});
