import * as React from "react";
import {StyleSheet, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {CONTENT_CONTAINER_MAX_WIDTH, IS_WEB, SMALL_SPACING} from "../../../utils/constants";
import {ct} from "../../../utils/locales/translations";
import {BLACK, GREEN, PRIMARY, RED, SUBTLE_4} from "../../../utils/styles/colors";
import {Button} from "../../buttons/button";

export interface VideoSessionActionsProps {
	audio: boolean;
	backgroundBlur: boolean;
	canJoin?: boolean;
	onAudioSelect: () => void;
	onJoin?: () => void;
	onLeave?: () => void;
	onVideoSelect: () => void;
	showLabels?: boolean;
	switchCamera: () => void;
	toggleAudio: () => void;
	toggleBackgroundBlur: () => void;
	toggleVideo: () => void;
	video: boolean;
}

export const VideoSessionActions = ({
	audio,
	backgroundBlur,
	canJoin,
	onAudioSelect,
	onVideoSelect,
	onJoin,
	onLeave,
	showLabels,
	switchCamera,
	toggleAudio,
	toggleBackgroundBlur,
	toggleVideo,
	video,
}: VideoSessionActionsProps): JSX.Element => (
	<SafeAreaView style={styles.actions} edges={["bottom"]}>
		{!IS_WEB && (
			<>
				<Button
					onPress={switchCamera}
					icon="cameraFlip"
					label={showLabels ? ct("screens:videoSession.flipCamera") : undefined}
					style={styles.action}
				/>
				<Button
					onPress={toggleBackgroundBlur}
					icon="backgroundBlur"
					backgroundColor={backgroundBlur ? PRIMARY : SUBTLE_4}
					label={showLabels ? ct(`screens:videoSession.${backgroundBlur ? "disableBlur" : "enableBlur"}`) : undefined}
					style={styles.action}
				/>
			</>
		)}
		<View style={styles.action}>
			<Button
				onPress={toggleAudio}
				icon={audio ? "microphone" : "microphoneOff"}
				backgroundColor={audio ? PRIMARY : SUBTLE_4}
				label={showLabels ? ct(`screens:videoSession.${audio ? "disableAudio" : "enableAudio"}`) : undefined}
			/>
			{IS_WEB && (
				<Button
					onPress={onAudioSelect}
					icon="carretBottom"
					backgroundColor={SUBTLE_4}
					size="xxsmall"
					style={styles.dropdown}
					innerStyle={styles.innerDropdown}
				/>
			)}
		</View>
		<View style={styles.action}>
			<Button
				onPress={toggleVideo}
				icon={video ? "video" : "videoOff"}
				backgroundColor={video ? PRIMARY : SUBTLE_4}
				label={showLabels ? ct(`screens:videoSession.${video ? "disableVideo" : "enableVideo"}`) : undefined}
			/>
			{IS_WEB && (
				<Button
					onPress={onVideoSelect}
					icon="carretBottom"
					backgroundColor={SUBTLE_4}
					size="xxsmall"
					style={styles.dropdown}
					innerStyle={styles.innerDropdown}
				/>
			)}
		</View>
		{!IS_WEB && showLabels && <View style={styles.break} />}
		{onJoin && (
			<Button
				style={styles.action}
				disabled={!canJoin}
				onPress={onJoin}
				icon="check"
				backgroundColor={GREEN}
				label={showLabels ? ct("common:join") : undefined}
			/>
		)}
		{onLeave && (
			<Button
				style={styles.action}
				onPress={onLeave}
				icon="close"
				backgroundColor={RED}
				label={showLabels ? ct("common:leave") : undefined}
			/>
		)}
	</SafeAreaView>
);

const styles = StyleSheet.create({
	action: {
		alignSelf: "flex-start",
		flex: 1,
		paddingHorizontal: 1,
	},
	actions: {
		alignSelf: "center",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-around",
		maxWidth: CONTENT_CONTAINER_MAX_WIDTH,
		padding: SMALL_SPACING,
		paddingBottom: SMALL_SPACING + 3, // +3 for absolute dropdown buttons
		width: "100%",
	},
	break: {
		height: SMALL_SPACING,
		width: "100%",
	},
	dropdown: {
		left: "60%",
		position: "absolute",
		top: 24,
	},
	innerDropdown: {
		borderColor: BLACK,
		borderWidth: 2,
	},
});
