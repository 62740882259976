import {SupportMessage} from "../../../@types/support-message";
import {ct} from "../../../utils/locales/translations";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterSupportMailGetRequests = {
	GET: {},
};
export type InterpreterSupportMailSendRequests = {
	POST: {
		body: {
			message?: string,
			subject?: string,
			userId?: string,
			sessionId?: string,
			supportReason?: string,
			telephone?: string,
			name?: string,
			userEmail?: string,
		},
	},
};

export const postSupportMessage = async (
	supportMessage: SupportMessage,
): Promise<void> => {
	let body = {};
	if (supportMessage?.subject === "validation-cards") {
		body = {
			subject: ct(`activities:sessions.support.${supportMessage?.subject}`),
			message: supportMessage?.message,
			name: supportMessage?.identity?.fullName,
			userEmail: supportMessage?.identity?.email,
			telephone: supportMessage?.identity?.phone,
		};
	} else if (supportMessage?.subject) {
		body = {
			message: supportMessage.message,
			subject: ct(`activities:sessions.support.${supportMessage?.subject}`),
			sessionId: supportMessage?.sessionId,
			supportReason: supportMessage?.subject,
			name: supportMessage?.identity?.fullName,
			userEmail: supportMessage?.identity?.email,
			telephone: supportMessage?.identity?.phone,
		};
	} else {
		body = {
			subject: "INTERPRETER",
			message: supportMessage?.message,
			name: supportMessage?.identity?.fullName,
			userEmail: supportMessage?.identity?.email,
			telephone: supportMessage?.identity?.phone,
		};
	}
	const interpreterResponse: ApiResponse<{}> = await api(
		"interpreterSupportMailSend",
		"POST",
		{body},
	) as ApiResponse<{}>;
	return interpreterResponse.isSuccess
		? Promise.resolve()
		: Promise.reject(new Error(interpreterResponse.message));
};
