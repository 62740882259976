import {RequesterCalendarMandatePreview} from "../../../@types/activities/mandate";
import {DataGetter} from "../../../utils/hooks/use-websocket";
import {WebSocketConfirmMandate} from "../../@types/session";
import {transformBackendResponseToCalendarRequesterMandatePreview} from "../../utils/transform";

export const getSessionStatusChangedData: DataGetter<RequesterCalendarMandatePreview> = {
	key: "sessionStatusChanged",
	getData: (message: WebSocketConfirmMandate) =>
		transformBackendResponseToCalendarRequesterMandatePreview(message.data),
};
