export const common = {
	about: "about",
	accept: "accept",
	account: "account",
	add: "add",
	address: "address",
	address_plural: "addresses",
	addressNumber: "address number",
	administrator: "administrator",
	all: "all",
	availability: "availability",
	availability_plural: "availabilities",
	billingAddress: "billing address",
	billingAddress_plural: "billing addresses",
	booked: "booked",
	calendar: "calendar",
	call: "call",
	camera: "camera",
	cancel: "cancel",
	category: "category",
	category_plural: "categories",
	choose: "choose",
	client: "client",
	close: "close",
	combinedNumber: "combined number",
	communicationType: "communication type",
	confirm: "confirm",
	copy: "copy",
	corporation: "corporation",
	creationDate: "creation date",
	crop: "crop",
	date: "date",
	day: "day",
	day_plural: "days",
	delete: "delete",
	description: "description",
	detail: "detail",
	detail_plural: "details",
	directPhone: "direct phone",
	duplicate: "duplicate",
	duration: "duration",
	education: "course",
	email: "email",
	email_plural: "emails",
	enabled: "enabled",
	evamNumber: "EVAM number",
	event: "event",
	event_plural: "events",
	exitAnyway: "exit anyway",
	export: "export",
	filter: "filter",
	filter_plural: "filters",
	firstName: "first name",
	followUp: "follow-up",
	fromTo: "{{from}} to {{to}}",
	fullName: "first and last name",
	function: "function",
	gender: "gender",
	gender_plural: "genders",
	general: "general",
	group: "group",
	"half-hour": "half an hour",
	"half-hour_plural": "half hours",
	hello: "hello",
	help: "help",
	home: "home",
	hour: "hour",
	hour_plural: "hours",
	ignore: "ignore",
	immediate: "immediate",
	inbox: "inbox",
	institution: "institution",
	interpreter: "interpreter",
	interpreter_plural: "interpreters",
	interpreterMandate: "interpreter mandate",
	interpretersGroups: "interpreters groups",
	ippNumber: "IPP number",
	join: "join",
	language: "language",
	language_plural: "languages",
	languageUI: "main language",
	lastName: "last name",
	later: "later",
	leave: "leave",
	library: "library",
	link: "link",
	loading: "loading",
	login: "login",
	"madam-abr": "Ms.",
	man: "man",
	man_plural: "men",
	mandate: "mandate",
	mandate_plural: "mandates",
	mandator: "mandator",
	medicalProfessional: "medical professional",
	medicalProfessional_plural: "medical professionals",
	message: "message",
	message_plural: "messages",
	millisecond: "millisecond",
	millisecond_plural: "milliseconds",
	minute: "minute",
	minute_plural: "minutes",
	"mister-abr": "Mr.",
	month: "month",
	month_plural: "months",
	name: "name",
	navigate: "navigate",
	new: "new",
	next: "next",
	notRelevant: "not relevant",
	notification: "notification",
	notification_plural: "notifications",
	offline: "offline",
	ok: "ok",
	online: "online",
	option: "option",
	option_plural: "options",
	optional: "optional",
	origin: "origin",
	origin_plural: "origins",
	other: "other",
	otherProfessional: "other professional",
	otherProfessional_plural: "other professionals",
	password: "password",
	paymentAuthority: "payment authority",
	paymentAuthority_plural: "payment authorities",
	person: "person",
	person_plural: "people",
	phone: "phone",
	phone_plural: "phones",
	phonePlaceholder: "(e.g. +41 xx xxx xx xx / +49 xxx xxx xxxx)",
	preview: "preview",
	previous: "previous",
	professional: "professional",
	professional_plural: "professionals",
	providedService: "service",
	qualification: "qualification",
	qualification_plural: "qualifications",
	"quarter-hour": "quarter of an hour",
	"quarter-hour_plural": "quarters of an hour",
	read: "read",
	receiver: "receiver",
	receiver_plural: "receivers",
	recover: "recover",
	refuse: "refuse",
	refused: "refused",
	requester: "requester",
	requester_plural: "requesters",
	required: "required",
	reschedule: "reschedule",
	reset: "reset",
	restart: "restart",
	result: "result",
	result_plural: "results",
	retry: "try again",
	salary: "salary",
	salary_plural: "salaries",
	save: "save",
	search: "search",
	second: "second",
	second_plural: "seconds",
	select: "select",
	send: "send",
	service: "service",
	session: "session",
	session_plural: "sessions",
	sessionCategory: "session category",
	sessionCategory_plural: "session categories",
	sessionType: "session type",
	setting: "setting",
	setting_plural: "settings",
	share: "share",
	signIn: "sign in",
	signOut: "logout",
	start: "start",
	status: "status",
	stop: "stop",
	subject: "subject",
	title: "title",
	total: "total",
	total_plural: "totals",
	translation: "translation",
	transport: "transport",
	transport_plural: "transports",
	transportPrivate: "personal transport",
	transportPublic: "public transport",
	travel: "travel",
	travel_plural: "travels",
	type: "type",
	unknown: "unknown",
	update: "update",
	user: "user",
	username: "username",
	validate: "validate",
	week: "week",
	week_plural: "weeks",
	woman: "woman",
	woman_plural: "women",
	year: "year",
	year_plural: "years",
};
