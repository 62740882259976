import {Account} from "../../../@types/accounts";
import {Page, Pagination} from "../../../@types/pagination";
import {Place, PlacesOrderType} from "../../../@types/places";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";

export type InterpreterEventsAddressesRequests = {
	GET: {
		queryParams: {
			limit?: string,
			offset?: string,
			eventName: string,
			language?: string,
			order?: string,
		},
	},
};

export const getInterpreterPlaces = async (
	_accountId: Account["accountId"],
	locale: SupportedLocaleKey,
	pagination: Pagination,
	placesOrder: PlacesOrderType,
):
Promise<Page<Place>> => {
	const {limit, offset} = pagination;
	const backendOffset = offset === 0 ? 1 : offset / limit;
	const interpreterResponse = await api(
		"interpreterEventsAddresses",
		"GET",
		{
			queryParams: {
				limit: limit.toString(),
				offset: backendOffset.toString(),
				eventName: "session",
				language: locale.slice(0, 2),
				order: placesOrder,
			},
		},
	) as ApiResponse<{list: {address: string, latitude: number, longitude: number}[], count: number}>;
	if (interpreterResponse.isSuccess) {
		const {list, count} = interpreterResponse.data;
		const placeList: Place[] = list.map(item => ({
			address: item.address,
			geocode: {lat: item.latitude, lng: item.longitude},
		}));
		return {
			items: placeList,
			hasPreviousPage: offset > 0,
			hasNextPage: count > offset,
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};
