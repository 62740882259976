import React from "react";
import {Animated, StyleSheet} from "react-native";

interface DotProps {
	backgroundColor: Animated.AnimatedInterpolation<string>;
	borderRadius: number;
	width: Animated.AnimatedInterpolation<number>;
}

export const Dot = ({backgroundColor, borderRadius, width}: DotProps): JSX.Element => (
	<Animated.View
		style={[
			styles.dotContainer,
			{
				backgroundColor,
				borderRadius,
				height: 2 * borderRadius,
				width,
			},
		]}
	/>
);

const styles = StyleSheet.create({
	dotContainer: {
		marginHorizontal: 2,
	},
});
