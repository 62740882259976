import * as React from "react";
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {InterpreterSalaryMandatePreview} from "../../../../@types/activities/mandate";
import {Session} from "../../../../@types/activities/session";
import {Pagination} from "../../../../@types/pagination";
import {Button} from "../../../../components/buttons/button";
import {ListInput} from "../../../../components/inputs/list";
import {SessionElement, SessionElementProps} from "../../../../components/list/items/session";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {Separator} from "../../../../components/separator";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {LocaleContext} from "../../../../contexts/locale";
import {downloadSalaryFile, getFinancialSessions, getSalary} from "../../../../requests/interpreters/account/salary";
import {filterTruthy} from "../../../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING, IS_WEB, SMALL_SPACING} from "../../../../utils/constants";
import {customFormatDate, formatDurationInHM} from "../../../../utils/date-time/format";
import {getDateInMonth} from "../../../../utils/date-time/helpers";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {linkToURL} from "../../../../utils/network/links";
import {BACKGROUND_COLOR, SUBTLE, SUBTLE_4} from "../../../../utils/styles/colors";
import {ELEVATIONS} from "../../../../utils/styles/elevations";

export type SessionsAccountingSum = Session["price"] & {
	from: Date;
	sessions: number;
	to: Date;
	workDuration: number; // duration in ms
};

interface TextRowProps {
	content: string;
	label: string;
	light?: boolean;
	style?: StyleProp<ViewStyle>;
}

const TextRow = ({label, content, light = true, style}: TextRowProps): JSX.Element => (
	<View style={[style, styles.textRow]}>
		<Text type={light ? "default_1" : "emphasis_1"} color={SUBTLE_4}>
			{label}
		</Text>
		<Text type={light ? "default_1" : "emphasis_1"}>
			{content}
		</Text>
	</View>
);

const headerComponentKey = (input: TextRowProps | "separator" | "space", index: number): string => input === "space"
	? `${index}-space`
	: input === "separator"
		? `${index}-separator`
		: `${input.label}-${index}`;

export const Salary = (
	{navigation, onSessionPress, selectedSessionId}: InterpreterStackNavigatorScreenProps<"SalaryModal"> & {
		onSessionPress?: (sessionId: InterpreterSalaryMandatePreview["activityId"]) => void;
		selectedSessionId?: InterpreterSalaryMandatePreview["activityId"];
	},
): JSX.Element => {
	const {ct, t} = useTranslation();
	const {locale} = React.useContext(LocaleContext);
	const [from, setFrom] = React.useState(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)));
	const requestStats = React.useCallback(
		() => getSalary(from, getDateInMonth(from, "last", "last")),
		[from],
	);
	const {displayed} = useForm(requestStats, "getInterpreterSalaryFailed");
	const {
		base: displayedBase = 0,
		currency: displayedCurrency = "CHF",
		increased: displayedIncreased = 0,
		reduced: displayedReduced = 0,
		sessions: displayedSessions = 0,
		travel: displayedTravel = {},
		workDuration: displayedWorkDuration = 0,
	} = displayed;
	const {time: displayedTime = 0, distance: displayedDistance = 0, flatRate: displayedFlatRate = 0} = displayedTravel;
	const requestSessions = React.useCallback(
		(pagination: Pagination) =>
			getFinancialSessions(from, getDateInMonth(from, "last", "last"), pagination).then(res => {
				if (pagination?.offset === 0 && onSessionPress && IS_WEB && res.items.length > 0) {
					onSessionPress(res.items[0]?.activityId);
				}
				return res;
			}),
		[from, onSessionPress],
	);

	const header = <HeaderMenu center={ct("common:salary_plural")}/>;
	const fees = displayedBase + displayedIncreased + displayedReduced;
	const travelSum = displayedFlatRate + displayedTime + displayedDistance;
	const total = fees + travelSum;

	const onPress = (sessionId: InterpreterSalaryMandatePreview["activityId"]): void => {
		onSessionPress
			? onSessionPress(sessionId)
			: navigation.navigate("SessionModal", {sessionId});
	};

	const details = (session: InterpreterSalaryMandatePreview): SessionElementProps["details"] => {
		if (!session || !session.price) {
			return undefined;
		}
		const {base, reduced, increased, travel = {}, currency = "CHF"} = session.price;
		const {time = 0, distance = 0, flatRate = 0} = travel;
		const sessiontTravelSum = flatRate + time + distance;
		return [
			{label: t("activities:price.abbrev.base"), value: `${base.toFixed(2)} ${currency}`},
			{label: t("activities:price.abbrev.increased"), value: `${increased.toFixed(2)} ${currency}`},
			{label: t("activities:price.abbrev.reduced"), value: `${reduced.toFixed(2)} ${currency}`},
			{label: t("activities:price.abbrev.travel.total"), value: `${sessiontTravelSum.toFixed(2)} ${currency}`},
			{label: ct("common:total"), value: `${(base + increased + reduced + sessiontTravelSum).toFixed(2)} ${currency}`},
		];
	};
	const HeaderComponent = (lines: (TextRowProps | "separator" | "space")[]): JSX.Element => (
		<View style={styles.listHeader}>
			{lines.filter(l => !!l).map((line, index) =>
				line === "space"
					? <Separator borderSize={0} key={headerComponentKey(line, index)}/>
					: line === "separator"
						? <Separator key={headerComponentKey(line, index)}/>
						: <TextRow key={headerComponentKey(line, index)} {...line}/>,
			)}
		</View>
	);

	const ListHeaderComponent = (
		<View style={[CONTAINERS.MAIN, styles.listHeaderContainer]}>
			{HeaderComponent(filterTruthy([
				{
					content: displayedSessions.toString(),
					label: ct("common:session", {count: displayedSessions}),
				}, {
					content: formatDurationInHM(displayedWorkDuration),
					label: ct("common:hour", {count: displayedWorkDuration}),
				},
				"space",
				{
					content: `+ ${displayedBase.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.base"),
				}, {
					content: `+ ${displayedIncreased.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.increased"),
				}, {
					content: `+ ${displayedReduced.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.reduced"),
				},
				{
					content: `≈ ${fees.toFixed(2)} ${displayedCurrency}`,
					label: t("screens:salary.fees"),
					light: false,
				},
				"separator",
				!!displayedFlatRate && {
					content: `+ ${displayedFlatRate.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.abbrev.travel.flatRate"),
				},
				!!displayedTime && {
					content: `+ ${displayedTime.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.abbrev.travel.time"),
				},
				!!displayedDistance && {
					content: `+ ${displayedDistance.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.abbrev.travel.distance"),
				},
				{
					content: `≈ ${(displayedFlatRate + displayedTime + displayedDistance)
						.toFixed(2)} ${displayedCurrency}`,
					label: t("activities:price.abbrev.travel.total"),
					light: false,
				},
				"separator",
				{
					content: `≈ ${total.toFixed(2)} ${displayedCurrency}`,
					label: ct("common:total_plural"),
					light: false,
				},
			]))}
			<Separator/>
			<Text color={SUBTLE_4} centered style={styles.agreementText}>
				{t("users:settings.exportAgreement")}
			</Text>
			<Separator/>
		</View>
	);

	const ListFooterComponent = (
		<View style={[CONTAINERS.MAIN, styles.listFooter]}>
			<Button
				size="small"
				text={t("users:settings.exportPDF")}
				icon="download"
				disabled={total === 0}
				fullWidth
				onPress={() => downloadSalaryFile(from, getDateInMonth(from, "last", "last"), "pdf", locale)
					.then(url => linkToURL(url))
					.catch(Log.error("getInterpreterSalaryFailed"))}
				style={styles.exportPDFButton}
			/>
			<Button
				size="small"
				text={t("users:settings.exportCSV")}
				icon="download"
				disabled={total === 0}
				fullWidth
				onPress={() => downloadSalaryFile(from, getDateInMonth(from, "last", "last"), "csv", locale)
					.then(url => linkToURL(url))
					.catch(Log.error("getInterpreterSalaryFailed"))}
				style={styles.exportCSVButton}
			/>
		</View>
	);

	const renderItem = ({info: {item}}: FlatListRenderItemParams<"activityId", InterpreterSalaryMandatePreview>): JSX.Element => (
		<SessionElement
			selected={item.activityId === selectedSessionId}
			session={item}
			onPress={() => onPress(item.activityId)}
			details={details(item)}
			showDate
			style={styles.marginBottom}
		/>
	);
	return (
		<View style={styles.container}>
			{header}
			<Separator spacingSize={0}/>
			<ListInput
				key="1"
				label={ct("common:month")}
				icon="calendarMonth"
				resetable={false}
				type={{
					key: "screen",
					onChangeValue: setFrom,
					params: {
						onSelect: setFrom,
						selectionOrBase: from,
						to: new Date().toISOString(),
					},
					screen: "SelectMonthModal",
					value: t("screens:salary.monthIncomes", {month: customFormatDate(from, "MMMM YYYY")}),
				}}
			/>
			<Separator spacingSize={0}/>
			<SelectFlatList
				getRequest={requestSessions}
				renderItem={renderItem}
				itemTranslationKey="common:session"
				flatListProps={{
					ItemSeparatorComponent: null,
					ListFooterComponent,
					ListHeaderComponent,
				}}
				idKey="activityId"
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	agreementText: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	container: {
		backgroundColor: SUBTLE,
		flex: 1,
	},
	exportCSVButton: {
		flex: 1,
	},
	exportPDFButton: {
		flex: 1,
		marginRight: SMALL_SPACING,
	},
	listFooter: {
		flexDirection: "row",
		marginTop: SMALL_SPACING,
		padding: SMALL_SPACING,
	},
	listHeader: {
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: SMALL_SPACING,
		justifyContent: "space-between",
		marginBottom: SMALL_SPACING,
		marginHorizontal: SMALL_SPACING,
		padding: SMALL_SPACING,
		...ELEVATIONS[4],
	},
	listHeaderContainer: {
		margin: SMALL_SPACING,
	},
	marginBottom: {
		marginBottom: SMALL_SPACING,
	},
	textRow: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
});

export const SalaryModal = (props: InterpreterStackNavigatorScreenProps<"SalaryModal">): JSX.Element => (
	<ModalWrapper>
		<Salary {...props} />
	</ModalWrapper>
);
