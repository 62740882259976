import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {LocaleContext} from "../../contexts/locale";
import {WelcomeSlide} from "../../navigation/screens/common/modals/slider";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {Images} from "../../utils/externals/images";
import {useTranslation} from "../../utils/hooks/use-translation";
import {CLIENT_BG, PRIMARY, PRIMARY_2, SUBTLE, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Image} from "../images/image";
import {Text} from "../texts/text";
import {GallerySlider, GallerySliderRenderItemParams} from "./gallery-slider";

interface WelcomeRequesterSlidersProps {
	type: "interpreter" | "requesterMobile" | "requesterWeb";
}

export const WelcomeSlider = ({type}: WelcomeRequesterSlidersProps): JSX.Element => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation();
	const {locale} = React.useContext(LocaleContext);

	const slides: WelcomeSlide[] = React.useMemo(
		() => {
			if (type === "interpreter") {
				// TODO: remove fallback & cast when all the translations exist
				const sliderImages = Images.sliders.welcome[locale as "de-DE"] ?? Images.sliders.welcome["de-DE"];
				return [
					{
						backgroundColor: PRIMARY_2,
						contentColor: WHITE,
						description: t("screens:sliders.welcome.dots.description"),
						dots: {
							activeColor: WHITE,
							inactiveColor: SUBTLE_4,
						},
						image: sliderImages.dots,
						key: "dots",
						title: t("screens:sliders.welcome.dots.title"),
					}, {
						description: t("screens:sliders.welcome.inbox.description"),
						image: sliderImages.inbox,
						key: "inbox",
						title: t("screens:sliders.welcome.inbox.title"),
					}, {
						description: t("screens:sliders.welcome.calendar.description"),
						image: sliderImages.calendar,
						key: "calendar",
						title: t("screens:sliders.welcome.calendar.title"),
					}, {
						description: t("screens:sliders.welcome.notifications.description"),
						image: sliderImages.notifications,
						key: "notifications",
						title: t("screens:sliders.welcome.notifications.title"),
					}, {
						description: t("screens:sliders.welcome.validation.description"),
						image: sliderImages.validation,
						key: "validation",
						title: t("screens:sliders.welcome.validation.title"),
					}, {
						description: t("screens:sliders.welcome.incomes.description"),
						image: sliderImages.incomes,
						key: "incomes",
						title: t("screens:sliders.welcome.incomes.title"),
					}, {
						buttons: {
							right: {
								backgroundColor: PRIMARY,
								contentColor: WHITE,
								onPress: () => navigation.goBack(),
								text: ct("common:start"),
							},
						},
						description: t("screens:sliders.welcome.checks.description"),
						image: sliderImages.checks,
						key: "checks",
						title: t("screens:sliders.welcome.checks.title"),
					},
				];
			}

			if (type === "requesterWeb") {
				// TODO: remove fallback & cast when all the translations exist
				const sliderImages = Images.sliders.welcomeRequester[locale as "de-DE"] ??
					Images.sliders.welcomeRequester["de-DE"];
				return [
					{
						backgroundColor: CLIENT_BG,
						buttons: {
							right: {
								backgroundColor: PRIMARY_2,
								contentColor: SUBTLE,
							},
						},
						description: t("screens:sliders.welcomeRequester.welcome.description"),
						dots: {
							activeColor: PRIMARY_2,
							inactiveColor: SUBTLE,
						},
						image: sliderImages.welcome,
						key: "welcome",
						title: t("screens:sliders.welcomeRequester.welcome.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.languages.description"),
						image: sliderImages.languages,
						key: "languages",
						title: t("screens:sliders.welcomeRequester.languages.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.timeslots.description"),
						image: sliderImages.timeslots,
						key: "timeslots",
						title: t("screens:sliders.welcomeRequester.timeslots.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.calendar.description"),
						image: sliderImages.calendar,
						key: "calendar",
						title: t("screens:sliders.welcomeRequester.calendar.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.communication.description"),
						image: sliderImages.communication,
						key: "communication",
						title: t("screens:sliders.welcomeRequester.communication.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.account.description"),
						image: sliderImages.account,
						key: "account",
						title: t("screens:sliders.welcomeRequester.account.title"),
					}, {
						description: t("screens:sliders.welcomeRequester.support.description"),
						image: sliderImages.support,
						key: "support",
						title: t("screens:sliders.welcomeRequester.support.title"),
					}, {
						buttons: {
							right: {
								backgroundColor: PRIMARY,
								contentColor: WHITE,
								onPress: () => navigation.goBack(),
								text: ct("common:start"),
							},
						},
						description: t("screens:sliders.welcomeRequester.checks.description"),
						image: sliderImages.checks,
						key: "check",
						title: t("screens:sliders.welcomeRequester.checks.title"),
					},
				];
			}

			if (type === "requesterMobile") {
				const sliderImages = Images.sliders.welcomeRequesterMobile[locale];
				return [
					{
						backgroundColor: CLIENT_BG,
						buttons: {
							right: {
								backgroundColor: PRIMARY_2,
								contentColor: SUBTLE,
							},
						},
						description: t("screens:sliders.welcomeRequesterMobile.welcome.description"),
						dots: {
							activeColor: PRIMARY_2,
							inactiveColor: SUBTLE,
						},
						image: sliderImages.welcome,
						key: "welcome",
						title: t("screens:sliders.welcomeRequesterMobile.welcome.title"),
					}, {
						image: sliderImages.home,
						key: "home",
						title: t("screens:sliders.welcomeRequesterMobile.home.title"),
					},
					{
						image: sliderImages.calendar,
						key: "calendar",
						title: t("screens:sliders.welcomeRequesterMobile.calendar.title"),
					}, {
						image: sliderImages.session,
						key: "session",
						title: t("screens:sliders.welcomeRequesterMobile.session.title"),
					}, {
						image: sliderImages.communication,
						key: "communication",
						title: t("screens:sliders.welcomeRequesterMobile.communication.title"),
					}, {
						buttons: {
							right: {
								backgroundColor: PRIMARY,
								contentColor: WHITE,
								onPress: () => navigation.goBack(),
								text: ct("common:start"),
							},
						},
						description: t("screens:sliders.welcomeRequester.checks.description"),
						image: sliderImages.checks,
						key: "check",
						title: t("screens:sliders.welcomeRequester.checks.title"),
					},
				];
			}
			return [];
		},
		[ct, locale, navigation, t, type],
	);

	const renderItem = ({info: {item}}: GallerySliderRenderItemParams<WelcomeSlide>): React.ReactElement | null =>
		/*
		 * // Could be used if usenativedriver would be set to true
		 * const imageTranslateY = animatedIndex.interpolate({
		 *  inputRange: [index - 1, index, index + 1],
		 *  outputRange: [-300, 0, -300],
		 * });
		 * const textTranslateY = animatedIndex.interpolate({
		 *  inputRange: [index - 1, index, index + 1],
		 *  outputRange: [300, 0, 300],
		 * });
		 */
		(
			<View style={styles.container}>
				<View style={styles.imageContainer}>
					<Image
						sourceType={item.image.sourceType}
						mime={item.image.mime}
						source={item.image.source}
						width={item.image.width}
						height={item.image.height}
						fullWidth
						fullHeight
						resizeMode="contain"
					/>
				</View>
				<View style={[CONTAINERS.MAIN, styles.textContainer]}>
					<Text type="title" style={styles.title} color={item.contentColor}>
						{item.title}
					</Text>
					<Text color={item.contentColor}>
						{item.description}
					</Text>
				</View>
			</View>
		)
	;
	return (
		<GallerySlider
			slides={slides}
			renderItem={renderItem}
			closable
		/>
	);
};

const styles = StyleSheet.create({
	container: {
		alignSelf: "center",
		flex: 1,
		padding: DEFAULT_SPACING,
		width: "100%",
	},
	imageContainer: {
		flex: 5,
		justifyContent: "flex-end",
		paddingBottom: DEFAULT_SPACING,
	},
	textContainer: {
		flex: 2,
		paddingTop: DEFAULT_SPACING,
	},
	title: {
		marginBottom: SMALL_SPACING,
	},
});
