import {Place} from "../../@types/places";
import {ILocationResponse} from "../@types/place";

export const transformPlaceBackToFront = ({location}: ILocationResponse): Place => ({
	address: location.formattedAddress,
	geocode: {
		lat: location.latitude,
		lng: location.longitude,
	},
});
