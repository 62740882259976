import * as React from "react";
import {Falsy, Keyboard, StyleSheet, View} from "react-native";
import {ImagePropsWithoutDimensions} from "../../@types/medias/image";
import {CONTAINERS, DEFAULT_SPACING, IS_WEB, SMALL_SPACING} from "../../utils/constants";
import {Debouncer} from "../../utils/debouncer";
import {useTranslation} from "../../utils/hooks/use-translation";
import {getValidationColor, getValidationIcon, ValidationState, ValidationType} from "../../utils/inputs/inputs";
import {Rule, Rules} from "../../utils/inputs/rules/rules";
import {Log} from "../../utils/logs/logs";
import {StackParamList} from "../../utils/navigation/paramLists/root-param-list";
import {PRIMARY_2, RED, SUBTLE, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {Icon, IconKey} from "../icons";
import {Image} from "../images/image";
import {Item, OnlyKeysExtendingSelectListType} from "../list/list-props.common";
import {Text} from "../texts/text";
import {TooltipWrapper} from "../tooltip/tooltip-wrapper";
import {CheckboxSelectInput, CheckboxSelectListProps} from "./list-inputs/checkbox-select-input";
import {DateInput, DateListProps, NativeDatePickerInput} from "./list-inputs/date-input";
import {DropdownSelectInput, DropdownSelectListProps} from "./list-inputs/dropdown-select-input";
import {ImageInput, ImageListProps} from "./list-inputs/image-input";
import {InfoInput, InfoListProps} from "./list-inputs/info-input";
import {InlineSelectInput, InlineSelectListProps} from "./list-inputs/inline-select-input";
import {MultiSelectInput, MultiSelectListProps} from "./list-inputs/multi-select-input";
import {RatingInput, RatingListProps} from "./list-inputs/rating-input";
import {ScreenInput, ScreenListProps} from "./list-inputs/screen-input";
import {TextInput, TextListProps} from "./list-inputs/text-input";
import {TextInputDropdownSuggestion, TextInputDropdownSuggestionItem, TextInputDropdownSuggestionListProps} from "./list-inputs/text-input-dropdown-suggestion";

const VALIDATION_DEBOUNCE_DURATION = 300;

export type ListInputTypes<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> =
	| CheckboxSelectListProps
	| DateListProps
	| DropdownSelectListProps<IdKey, I, RouteName>
	| ImageListProps
	| InfoListProps
	| InlineSelectListProps
	| MultiSelectListProps<IdKey, I, RouteName>
	| RatingListProps
	| ScreenListProps<IdKey, I, RouteName>
	| TextInputDropdownSuggestionListProps<"id", TextInputDropdownSuggestionItem>
	| TextListProps;

interface ValidationMessage {
	message: string;
	type: ValidationType;
}

export interface ListInputProps<Key = string> {
	collapsed?: boolean;
	disabled?: boolean;
	explanation?: Falsy | string;
	icon?: IconKey;
	image?: ImagePropsWithoutDimensions;
	key?: Key; // Identifier that can be used to know which input is related to which property in form data
	label?: string;
	preview?: boolean;
	requiredLabel?: boolean;
	resetable?: boolean;
	rules?: {
		rule: Rule;
		type: ValidationType;
	}[] | Falsy;
	toggleCollapse?: () => void; // Callback by <Form> to tell if the input is valid
	tooltip?: string;
	type: ListInputTypes<any, any, any>;
	validation?: (valid: boolean) => void;
}

interface State {
	collapsed: boolean;
	mandatory: boolean;
	validationMessages: ValidationMessage[];
	validity: ValidationState;
}

const defaultState = (disabled: boolean, collapsed: boolean): State => ({
	collapsed: collapsed ?? true,
	mandatory: false,
	validationMessages: [] as ValidationMessage[],
	validity: disabled ? "disabled" : "unvalidated",
});

export const ListInput = ({
	icon,
	disabled: disabledProp,
	type,
	rules,
	validation,
	image,
	preview,
	label,
	tooltip,
	resetable = true,
	requiredLabel,
	explanation,
	collapsed: collapsedProp = false,
	toggleCollapse: toggleCollapseProp,
}: ListInputProps): React.ReactElement => {
	const {t} = useTranslation();
	const disabled = (disabledProp || preview) ?? false;
	const validationDebouncerRef = React.useRef(new Debouncer());
	const defaultStateMemo = React.useMemo(
		() => defaultState(disabled, collapsedProp),
		[collapsedProp, disabled],
	);
	const [state, setState] = React.useState<State>(defaultStateMemo);
	const value = type.key === "textSuggestions" ? type.onSearch?.value : type.value;

	const validate = React.useCallback(
		(v: unknown, quiet?: boolean) => {
			if (!rules || disabled) {
				validation?.(true);
				return Promise.resolve();
			}
			setState(prev => ({...prev, validity: "validating"}));

			// This intermediate variable let us modify the state only once
			const newValidationMessages: ValidationMessage[] = [];

			// Rules only operate on string
			const stringified: string = v || typeof v === "boolean"
				? typeof v === "string"
					? v
					: (type.key === "time" ||
						type.key === "date" ||
						type.key === "datetime" ||
						type.key === "duration")
						? String(v)
						: JSON.stringify(v)
				: "";

			const rulesToValidate = rules.filter(r => value || r.rule.id === Rules.notEmpty.id);

			return Promise.all(rulesToValidate.map(r =>
				r.rule.validate(stringified)
					.catch(error => newValidationMessages.push({message: error, type: r.type})),
			)).then(() => {
				// there is rules to apply --> we save the results in the state
				if (rulesToValidate.length > 0) {
					setState(prev => ({
						...prev,
						validationMessages: quiet ? prev.validationMessages : newValidationMessages,
						validity: newValidationMessages.length > 0 ? "invalid" : "valid",
					}));
					validation?.(newValidationMessages.filter(message => message.type === "error").length === 0);
				} else {
					// there is no rules to apply --> we reset the field's validation state to default
					setState(prev => ({
						...prev,
						validationMessages: defaultStateMemo.validationMessages,
						validity: defaultStateMemo.validity,
					}));
					validation?.(true);
				}
			});
		},
		[defaultStateMemo.validationMessages, defaultStateMemo.validity, disabled, rules, type.key, validation, value],
	);
	React.useEffect(
		() => {
			if (disabled) {
				setState(prev => ({
					...prev,
					mandatory: false,
					validity: "disabled",
				}));
			} else if (rules && rules.some(r => r.rule.id === Rules.notEmpty.id)) {
				setState(prev => ({
					...prev,
					mandatory: true,
				}));
			}
		},
		[disabled, rules, t],
	);
	React.useEffect(
		() => {
			// Don't show errors on first validation (default value) or when field is re-enabled
			const quiet = (state.validity === "unvalidated" && !value) || (state.validity === "disabled" && !disabled);
			// The validation debouncer is not in the condition because the first value needs to be validated
			validationDebouncerRef.current.debounce(
				() => validate(value, quiet).catch(Log.error()),
				quiet ? 0 : VALIDATION_DEBOUNCE_DURATION,
			);
		},
		// Not sure why adding `validate` or `validity` creates an infinity loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disabled, value],
	);

	React.useEffect(
		() => {
			setState(prev => ({
				...prev,
				collapsed: collapsedProp ?? prev.collapsed,
			}));
		},
		[collapsedProp],
	);

	const onPressReset = React.useCallback(
		() => type.key === "multiSelect"
			? type.getItems.onChangeValue(null)
			: type.key === "textSuggestions"
				? type?.onSearch?.onChangeSearch?.(null)
				// onChangeValue have trouble computing its arguments type. The amount of OR statements can compute to `never`
				: type.onChangeValue?.(null as never),
		[type],
	);

	const toggleCollapse = React.useCallback(
		() => {
			Keyboard.dismiss();
			setState(prev => ({
				...prev,
				collapsed: !prev.collapsed,
			}));
			toggleCollapseProp?.();
		},
		[toggleCollapseProp],
	);

	const oneLineLabel = (
		type.key === "checkbox" ||
		type.key === "select" ||
		type.key === "date" ||
		type.key === "datetime" ||
		type.key === "time" ||
		type.key === "duration"
	);
	const complexComponent = (
		type.key === "inlineSelect" ||
		type.key === "dropdownSelect" ||
		type.key === "textSuggestions"
	);
	const resetables = !oneLineLabel && !complexComponent;
	const backgroundColor = disabled ? WHITE : SUBTLE;

	return (
		<View style={{backgroundColor}}>
			<View style={[CONTAINERS.MAIN, styles.container]}>
				<View style={styles.row}>
					{!!image && (<Image
						source={image.source}
						fullWidth={image.fullWidth}
						fullHeight={image.fullHeight}
						resizeMode={image.resizeMode}
						mime={image.mime}
						sourceType={image.sourceType}
						width={32}
						height={32}
						style={styles.imageContainer}
					/>)}
					{!!icon && (
						<View style={[{marginTop: label ? oneLineLabel ? 1 : 5 : -4}, styles.icon]}>
							{getValidationIcon(
								disabled ? "disabled" : state.validity,
								icon,
								[
									...state.validationMessages ? state.validationMessages.map(el => el.type) : [],
									...state.validity === "invalid" ? ["warning" as ValidationType] : [],
								],
							)}
						</View>
					)}
					<View style={styles.content}>
						<View style={styles.labelWrapper}>
							{!!label && (
								<Text
									type={disabled ? "label" : "touchable_label"}
									color={disabled ? SUBTLE_4 : PRIMARY_2}
									style={[styles.label, oneLineLabel && {marginTop: 1}]}
								>
									{state.mandatory && !disabled && state.validity === "invalid" &&
										<Text color={RED} type="button_2">* </Text>}
									{label + " "}
									{state.validity !== "unvalidated" && requiredLabel && !disabled &&
										(<Text type="label">({t(`common:${state.mandatory ? "required" : "optional"}`)}) </Text>)}
								</Text>
							)}
							{!!tooltip && (
								<View style={styles.tooltip}>
									<TooltipWrapper content={tooltip}>
										<Icon icon="info" size={16} containerSize={16}/>
									</TooltipWrapper>
								</View>
							)}
						</View>

						{(
							type.key === "text" ||
							type.key === "email" ||
							type.key === "phone" ||
							type.key === "username" ||
							type.key === "password" ||
							type.key === "firstName" ||
							type.key === "lastName" ||
							type.key === "address" ||
							type.key === "evamNumber"
						) && (
							<TextInput
								type={type.key}
								props={type.props}
								onChangeValue={type.onChangeValue}
								value={value}
								disabled={disabled}
								hasLabel={!!label}
								hasIcon={!!icon || !!image}
							/>
						)}
						{type.key === "multiSelect" && (
							<MultiSelectInput
								itemProps={type.itemProps}
								getLabelText={type.getLabelText}
								keyExtractor={type.keyExtractor}
								value={type.value}
								getItems={type.getItems}
								onAddItem={type.onAddItem}
								onUpdateItem={type.onUpdateItem}
								onDeleteItem={type.onDeleteItem}
								disabled={disabled}
								toggleCollapse={toggleCollapse}
								collapsed={state.collapsed}
							/>
						)}
						{type.key === "inlineSelect" && (
							<InlineSelectInput
								choices={type.choices}
								getLabelText={type.getLabelText}
								keyExtractor={type.keyExtractor}
								itemProps={type.itemProps}
								multiple={type.multiple}
								onChangeValue={type.onChangeValue}
								onPressItem={type.onPressItem}
								value={value}
								disabled={disabled}
								toggleCollapse={toggleCollapse}
								collapsed={state.collapsed}
								disableBreakpoint={type.disableBreakpoint}
							/>
						)}
						{type.key === "dropdownSelect" && (
							<DropdownSelectInput
								getRequest={type.getRequest}
								deleteRequest={type.deleteRequest}
								onPressAdd={type.onPressAdd}
								onPressEdit={type.onPressEdit}
								onPressSelect={type.onPressSelect}
								onSearch={type.onSearch}
								{...(type.itemTranslation
									? {itemTranslation: type.itemTranslation}
									: {itemTranslationKey: type.itemTranslationKey}
								)}
								idKey={type.idKey}
								refreshable={type.refreshable}
								getLabelText={type.getLabelText}
								onChangeValue={type.onChangeValue}
								value={value}
								disabled={disabled}
								selectedItemsProps={type.selectedItemsProps}
								multiple={type.multiple}
								containerStyle={type.containerStyle}
								dropDown={type.dropDown}
								wordsBeginBySearch={type.wordsBeginBySearch}
							/>
						)}
						{type.key === "textSuggestions" && (
							<TextInputDropdownSuggestion
								key={type.key}
								getRequest={type.getRequest}
								deleteRequest={type.deleteRequest}
								onPressAdd={type.onPressAdd}
								onPressEdit={type.onPressEdit}
								onPressSelect={type.onPressSelect}
								onSearch={type.onSearch}
								{...(type.itemTranslation
									? {itemTranslation: type.itemTranslation}
									: {itemTranslationKey: type.itemTranslationKey!}
								)}
								idKey={type.idKey}
								refreshable={type.refreshable}
								dropDown={type.dropDown}
							/>
						)}
						{type.key === "screen" && (
							<ScreenInput
								screen={type.screen as never}
								renderValue={type.renderValue}
								props={type.props}
								onChangeValue={type.onChangeValue}
								value={value}
								params={type.params as never}
								disabled={disabled}
								hasLabel={!!label}
								hasIcon={!!icon || !!image}
							/>
						)}
						{type.key === "image" && (
							<ImageInput
								props={type.props}
								onChangeValue={type.onChangeValue}
								value={value}
								options={type.options}
								disabled={disabled}
								hasLabel={!!label}
								hasIcon={!!icon || !!image}
							/>
						)}
						{type.key === "rating" && (
							<RatingInput
								onChangeValue={type.onChangeValue}
								value={value}
								disabled={disabled}
							/>
						)}
						{type.key === "info" && (
							<InfoInput
								renderValue={type.renderValue}
								props={type.props}
								onChangeValue={type.onChangeValue}
								value={value}
							/>
						)}
						{state.validationMessages.length > 0 && state.validationMessages.map((el) => (
							<Text
								key={`${el.type}-${el.message}`}
								type="label"
								color={getValidationColor(disabled ? "disabled" : el.type)}
								style={styles.validation}
							>
								{el.message}
							</Text>
						))}
						{!!explanation && !disabled && (
							<Text
								type="label"
								color={SUBTLE_4}
								style={styles.validation}
							>
								{explanation}
							</Text>
						)}
					</View>
					{(type.key === "checkbox" || type.key === "select") && (
						<CheckboxSelectInput
							type={type.key}
							value={value}
							props={type.props}
							disabled={disabled}
							onChangeValue={type.onChangeValue}
						/>
					)}
					{(type.key === "date" || type.key === "datetime" || type.key === "time" || type.key === "duration") &&
						<DateInput
							type={type.key}
							value={value}
							props={type.props}
							onChangeValue={type.onChangeValue}
							disabled={disabled}
							toggleCollapse={toggleCollapse}
							collapsed={state.collapsed}
						/>}
					{!!value && resetable && !disabled && resetables &&
						(
							<Icon icon="close"
								onPress={onPressReset}
								size={20}
								color={SUBTLE_4}
								containerStyle={styles.closeIcon}
								focusable={false}
							/>
						)}
				</View>
				{(
					type.key === "date" ||
					type.key === "datetime" ||
					type.key === "time" ||
					type.key === "duration"
				) && !IS_WEB && (
					<NativeDatePickerInput
						type={type.key}
						value={value}
						props={type.props}
						onChangeValue={type.onChangeValue}
						disabled={disabled}
						toggleCollapse={toggleCollapse}
						collapsed={state.collapsed}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	closeIcon: {
		marginLeft: SMALL_SPACING,
	},
	container: {
		paddingHorizontal: DEFAULT_SPACING,
		paddingVertical: SMALL_SPACING,
	},
	content: {
		flex: 1,
		justifyContent: "center",
	},
	icon: {
		alignSelf: "flex-start",
		marginBottom: -2,
		marginRight: SMALL_SPACING,
	},
	imageContainer: {
		marginLeft: -2,
		marginRight: DEFAULT_SPACING - 2,
	},
	label: {
		marginTop: -2, // Label being clearer, the visual effect of center needs to be compensated
	},
	labelWrapper: {
		flexDirection: "row",
	},
	row: {
		alignItems: "center",
		flexDirection: "row",
	},
	tooltip: {
		marginLeft: "auto",
	},
	validation: {
		marginBottom: -2,
		marginTop: DEFAULT_SPACING / 4,
	},
});
