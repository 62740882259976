import * as React from "react";
import {Animated, Keyboard, StyleSheet} from "react-native";
import {Interpreter} from "../../../../@types/identities/interpreter";
import {Gender, genders} from "../../../../@types/identities/person";
import {LanguageTranslation} from "../../../../@types/language-translation";
import {IdentityEditableFieldsSetting} from "../../../../@types/settings";
import {InterpreterHeader} from "../../../../components/headers/interpreter-header";
import {Form, FormProps} from "../../../../components/inputs/form";
import {TranslationLabel} from "../../../../components/labels/translation";
import {HeaderMenu} from "../../../../components/menus/header";
import {Text} from "../../../../components/texts/text";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {Authentified, AuthentifiedContext} from "../../../../contexts/authentified";
import {updateInterpreter, updateInterpreterPicture} from "../../../../requests/interpreters/interpreter";
import {filterTruthy} from "../../../../utils/arrays";
import {DEFAULT_SPACING, MENU_HEIGHT} from "../../../../utils/constants";
import {addTime} from "../../../../utils/date-time/helpers";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {getTitle} from "../../../../utils/identities";
import {ImagePicker, PickerImage} from "../../../../utils/inputs/pickers/image-picker";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {scrollViewDefaultProps} from "../../../../utils/scrollables";
import {capitalize, removeNonPhoneNumberCharacters} from "../../../../utils/strings";
import {forceBack} from "../../../navigation";
import {AddressListItem} from "../../client/modals/address-list";
import {Origin} from "../../common/modals/select-origin";
import {Transport} from "./select-transport";

export const EditProfile = ({
	navigation,
	goBackOnSave = true,
}: InterpreterStackNavigatorScreenProps<"EditProfileModal"> & {goBackOnSave?: boolean}): JSX.Element => {
	const {ct, t} = useTranslation();
	// The authentified interpreter is used for default values
	const {identity, setAuthentified, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const base = React.useMemo(
		() => identity as Interpreter,
		[identity],
	);
	const {
		loading: loadingForm, displayed, isUpdated, updated, setUpdated,
	} = useForm(base);
	// The image picked by the user on his OS
	const [updatedPicture, setUpdatedPicture] = React.useState<PickerImage | null>(null);
	const scrollY = React.useRef(new Animated.Value(0));
	const editablesSetting = getSetting<IdentityEditableFieldsSetting<Interpreter>>(
		"identity/editable-fields", "account");

	React.useEffect(
		() => setUpdated(prev => ({...prev, picture: updatedPicture ?? identity?.picture})),
		[identity?.picture, setUpdated, updatedPicture],
	);

	const header = (
		<HeaderMenu
			centerComponent={(
				<Animated.View
					style={
						{
							transform: [
								{
									translateY:
										scrollY.current.interpolate({
											inputRange: [Number.MIN_SAFE_INTEGER, 70, 140, Number.MAX_SAFE_INTEGER],
											outputRange: [-MENU_HEIGHT, -MENU_HEIGHT, 0, 0],
										}),
								},
							],
						}
					}
				>
					<Text type="emphasis_1">
						{t("screens:profile.edit")}
					</Text>
				</Animated.View>
			)}
			exitConfirmation={isUpdated}
		/>
	);

	const {fullName, gender, email, phone, place, dateOfBirth, languages, origin, transport, qualifications} = displayed;

	const {
		// [editable, mandatory, availableValues, optional fieldLabel]
		gender: [genderEditable, genderMandatory] = [],
		fullName: [
			fullNameEditable, fullNameMandatory, fullNameSettingData,
		] = [false, false, {}],
		languages: [languagesEditable, languagesMandatory] = [],
		qualifications: [qualificationsEditable, qualificationsMandatory] = [],
		dateOfBirth: [dateOfBirthEditable, dateOfBirthMandatory] = [],
		transport: [transportEditable, transportMandatory] = [],
		place: [placeEditable, placeMandatory] = [],
		email: [emailEditable, emailMandatory] = [],
		phone: [phoneEditable, phoneMandatory] = [],
		picture: [pictureEditable] = [],
		origin: [originEditable, originMandatory] = [],
	} = editablesSetting?.value ?? {};

	const {
		firstName: [firstNameEditable, firstNameMandatory] = [],
		lastName: [lastNameEditable, lastNameMandatory] = [],
	} = fullNameSettingData ?? {firstName: [], lastName: []};

	const onAvatarPress = (): void => {
		Keyboard.dismiss();
		ImagePicker.pick({
			cropping: true,
			from: "both",
			multiple: false,
			square: true,
		}).then((picked) => {
			if (picked) {
				setUpdatedPicture(picked);
			}
		}).catch(Log.error("imagePickerFailed"));
	};

	const inputs: FormProps["inputs"] = [
		// if the editable field is null or undefined, then the row should be hidden
		firstNameEditable != null && {
			disabled: !fullNameEditable || !firstNameEditable || loadingForm,
			icon: "person",
			label: ct("common:firstName"),
			rules: fullNameMandatory && firstNameMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "firstName",
				onChangeValue: (fn: string) => setUpdated(prev => ({
					...prev, fullName: {...(prev.fullName || fullName), firstName: fn},
				})),
				value: fullName.firstName,
			},
		}, lastNameEditable != null && {
			disabled: !fullNameEditable || !lastNameEditable || loadingForm,
			icon: "person",
			label: ct("common:lastName"),
			rules: fullNameMandatory && lastNameMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "lastName",
				onChangeValue: (ln: string) => setUpdated(prev => ({
					...prev,
					fullName: {...(prev.fullName || fullName), lastName: ln},
				})),
				value: fullName.lastName,
			},
		}, emailEditable != null && {
			disabled: !emailEditable || loadingForm,
			icon: "mail",
			label: ct("common:email"),
			rules: filterTruthy([
				emailMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.email, type: "error"},
			]),
			type: {
				key: "email",
				onChangeValue: (m: string) => setUpdated(prev => ({...prev, email: m})),
				value: email,
			},
		}, phoneEditable != null && {
			disabled: !phoneEditable || loadingForm,
			icon: "phone",
			label: ct("common:phone"),
			rules: filterTruthy([
				phoneMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.phone, type: "error"},
			]),
			type: {
				key: "phone",
				onChangeValue: (p?: string) => setUpdated(prev => ({...prev, phone: p ? removeNonPhoneNumberCharacters(p) : ""})),
				value: phone,
			},
		}, placeEditable != null && {
			disabled: !placeEditable || loadingForm,
			icon: "address",
			label: ct("common:address"),
			rules: placeMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (p: AddressListItem) => setUpdated(prev => ({...prev, place: p?.displayed})),
				params: {place},
				screen: "SelectPlaceModal",
				value: place?.address,
			},
		}, genderEditable != null && {
			disabled: !genderEditable || loadingForm,
			icon: "gender",
			label: ct("common:title"),
			resetable: false,
			rules: genderMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: genders,
				getLabelText: (g: Gender) => getTitle(g),
				key: "inlineSelect",
				onChangeValue: (gs?: Gender[] | null) => setUpdated(prev => ({...prev, gender: gs?.[0] ?? "man"})),
				value: gender ? [gender] : null,
			},
		}, dateOfBirth && !dateOfBirthEditable && {
			disabled: !dateOfBirthEditable || loadingForm,
			icon: "birthday",
			label: t("users:person.dateOfBirth"),
			rules: dateOfBirthMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "date",
				onChangeValue: (dob: Date) => setUpdated(prev => ({...prev, dateOfBirth: dob})),
				props: {maximumDate: new Date(), minimumDate: addTime(new Date(), -100, "year")},
				value: dateOfBirth,
			},
		}, languagesEditable != null && {
			disabled: !languagesEditable || loadingForm,
			icon: "language",
			label: ct("common:language_plural"),
			resetable: false,
			rules: languagesMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "info",
				props: {style: styles.translationWrapper}, // We may want to create a screen to select languages one day
				renderValue: (translations: LanguageTranslation[]) => (
					<>
						{translations.map(({from, to}) => (
							<TranslationLabel
								key={`${from}-${to}`}
								from={from}
								to={to}
								style={styles.translationItem}
								disabled={!languagesEditable || loadingForm}
							/>
						))}
					</>
				),
				value: languages,
			},
		}, originEditable != null && {
			disabled: !originEditable || loadingForm,
			icon: "world",
			label: ct("common:origin"),
			rules: originMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (o: Origin[]) => setUpdated(prev => ({...prev, origin: o[0]})),
				renderValue: (o: Origin) => t(`origins:${o}`),
				screen: "SelectOriginModal",
				value: origin,
			},
		}, transportEditable != null && {
			disabled: !transportEditable || loadingForm,
			icon: "travel",
			label: ct("common:transport"),
			rules: transportMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (value: Transport) => setUpdated(prev => ({...prev, transport: value})),
				renderValue: (value: Transport) => ct(`common:transport${value === "Private" ? "Private" : "Public"}`),
				screen: "SelectTransportModal",
				value: transport,
			},
		}, qualificationsEditable != null && {
			disabled: true,
			icon: "school",
			label: ct("common:qualification_plural"),
			rules: qualificationsMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				value: Object.entries(qualifications).map(([key, values]) =>
					`${capitalize(key)}:\n  ✓ ${values.map(v => v.value).join("\n  ✓ ")}`).join("\n\n"),
			},
		},
	];

	return (
		<>
			{header}
			<Animated.ScrollView
				{...scrollViewDefaultProps}
				onScroll={Animated.event(
					[{nativeEvent: {contentOffset: {y: scrollY.current}}}],
					{useNativeDriver: true},
				)}
				contentInsetAdjustmentBehavior="always"
			>
				<InterpreterHeader
					scrollY={scrollY.current}
					editable={pictureEditable ? onAvatarPress : undefined}
					interpreter={displayed}
					disableAvailabilityWebsocket
				/>
				<Form
					hideReset
					requiredLabels
					validation={{
						buttonProps: {icon: "check", text: t("screens:profile.update")},
						onValidation: () => updateInterpreter(identity!.identityId, updated)
							.then((res) => {
								if (updatedPicture) {
									updateInterpreterPicture(identity!.identityId, updatedPicture)
										.then(() => {
											setAuthentified((prev: Authentified | null) => prev &&
												({...prev, identity: {...res, picture: updatedPicture}}));
											Log.success("updateInterpreterProfileSuccess")();
											if (goBackOnSave) {
												navigation.dispatch(forceBack);
											}
										})
										.catch((error) => {
											Log.error("updateInterpreterPictureFailed")(error);
											setUpdatedPicture(null);
										});
								} else {
									setAuthentified((prev: Authentified | null) => prev && ({...prev, identity: res}));
									Log.success("updateInterpreterProfileSuccess")();
									if (goBackOnSave) {
										navigation.dispatch(forceBack);
									}
								}
							})
							.catch(Log.error("updateInterpreterFailed")),
					}}
					inputs={inputs}
				/>
			</Animated.ScrollView>
		</>
	);
};

const styles = StyleSheet.create({
	translationItem: {
		margin: DEFAULT_SPACING / 4,
	},
	translationWrapper: {
		marginHorizontal: -DEFAULT_SPACING / 4,
	},
});

export const EditProfileModal = (props: InterpreterStackNavigatorScreenProps<"EditProfileModal">): JSX.Element => (
	<ModalWrapper>
		<EditProfile {...props} />
	</ModalWrapper>
);
