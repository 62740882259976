import {useNavigation} from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import * as React from "react";
import {InterpreterCalendarMandatePreview, RequesterCalendarMandatePreview} from "../../@types/activities/mandate";
import {Context, InPerson, Session, SessionAction, SessionPreview} from "../../@types/activities/session";
import {SessionValidation} from "../../@types/validation";
import {AuthentifiedContext} from "../../contexts/authentified";
import {SessionCreationContext} from "../../contexts/session-creation";
import {toRescheduleCreation} from "../../navigation/screens/client/modals/cancel-reschedule-session";
import {acceptSession} from "../../requests/interpreters/activities/actions/accept";
import {IS_CLIENT, IS_INTERPRETER, IS_MOBILE} from "../../utils/constants";
import {useTranslation} from "../../utils/hooks/use-translation";
import {FeedbackKey} from "../../utils/locales/translations";
import {Log} from "../../utils/logs/logs";
import {ClientStackNavigatorScreenProps} from "../../utils/navigation/paramLists/client-param-list";
import {StackNavigationProp} from "../../utils/navigation/paramLists/root-param-list";
import {linkToAddress, linkToCall, linkToURL} from "../../utils/network/links";
import {fairtiqURL, swissPublicTransportUrl} from "../../utils/public-transports";
import {GREEN, PRIMARY_2, RED, SUBTLE_2, SUBTLE_4, WHITE} from "../../utils/styles/colors";
import {ps, s} from "../../utils/switch";
import {SubUnion} from "../../utils/types";
import {sessionConfirmation} from "../feedbacks/confirmation";
import {IconKey} from "../icons";
import {beforeCallDelay} from "../sessions/call";
import {ButtonProps} from "./button";

const secondaryButtonStyle = {backgroundColor: SUBTLE_2, contentActiveColor: WHITE, contentColor: PRIMARY_2};
const secondaryLightButtonStyle = {backgroundColor: SUBTLE_2, contentActiveColor: WHITE, contentColor: SUBTLE_4};
const cffButtonStyle = {backgroundColor: "#eb0000", contentActiveColor: WHITE, contentColor: WHITE};

export type SessionActionsCallback = SubUnion<
FeedbackKey,
"acceptSessionSuccess" | "cancelSessionSuccess" | "refuseSessionSuccess" | "updateSessionValidationSuccess"
>;

interface Props {
	actions: SessionAction[];
	callbacks?: (action: SessionActionsCallback, sessionValidation?: SessionValidation) => void;
	mode: "focus" | "list";
	// List is when displayed in session list & focus is on the session page
	renderAction: (
		key: string,
		icon: IconKey,
		label: string,
		onPress: ButtonProps["onPress"],
		buttonStyle?: {
			backgroundColor?: string;
			contentActiveColor?: string;
			contentColor?: string;
		},
		disabled?: boolean,
	) => JSX.Element;
	scrollToEnd?: () => void;
	session: SessionPreview;
}

const onPressIgnore = (): null => null; // TODO
const onPressFairtiq = async (): Promise<unknown> => await linkToURL(fairtiqURL());

export const getSessionActions = (
	mode: Props["mode"], // List is when displayed in session list & focus is on the session page itself
	session: Props["session"],
	context?: Context,
): SessionAction[] => {
	const sessionActions: SessionAction[] = [];
	if (!session) {
		return sessionActions;
	}
	const {disabledActions = [], communication, immediate, start} = session;

	s(session.status, {
		canceled: () => {
			if (!disabledActions.includes("followUp")) {
				sessionActions.push("followUp");
			}
			if (mode === "focus" && !disabledActions.includes("share")) {
				sessionActions.push("share");
			}
		},
		completed: () => {
			if (!disabledActions.includes("followUp")) {
				sessionActions.push("followUp");
			}
			if (mode === "focus" && !disabledActions.includes("share")) {
				sessionActions.push("share");
			}
		},
		confirmed: () => {
			if (communication.type === "phone" && !disabledActions.includes("phoneCall")) {
				sessionActions.push("phoneCall");
			}
			if (communication.type === "video" && !disabledActions.includes("videoCall")) {
				sessionActions.push("videoCall");
			}
			if (IS_INTERPRETER) {
				if (communication.type === "inPerson") {
					if (!disabledActions.includes("navigate")) {
						sessionActions.push("navigate");
					}
					if (mode === "list" && !disabledActions.includes("fairtiq")) {
						sessionActions.push("fairtiq");
					}
					if (mode === "list" && !disabledActions.includes("copyAddress")) {
						sessionActions.push("copyAddress");
					}
				}
				if (communication.type !== "video" && mode === "focus" && !disabledActions.includes("validate")) {
					sessionActions.push("validate");
				}
			}
			if (mode === "focus") {
				if (!disabledActions.includes("followUp")) {
					sessionActions.push("followUp");
				}
				if (!disabledActions.includes("share")) {
					sessionActions.push("share");
				}
				if (IS_CLIENT && new Date() <= start) {
					if (!disabledActions.includes("cancel")) {
						sessionActions.push("cancel");
					}
					if (!immediate && !disabledActions.includes("reschedule")) {
						sessionActions.push("reschedule");
					}
				}
			}
		},
		deleted: () => {
			/* do nothing */
		},
		inProgress: () => {
			if (communication.type === "phone" && !disabledActions.includes("phoneCall")) {
				sessionActions.push("phoneCall");
			}
			if (communication.type === "video" && !disabledActions.includes("videoCall")) {
				sessionActions.push("videoCall");
			}
			if (IS_INTERPRETER) {
				if (communication.type === "inPerson") {
					if (!disabledActions.includes("navigate")) {
						sessionActions.push("navigate");
					}
					if (mode === "list" && !disabledActions.includes("navigate")) {
						sessionActions.push("copyAddress");
					}
				}
				if (communication.type !== "video" && mode === "focus" && !disabledActions.includes("validate")) {
					sessionActions.push("validate");
				}
			}
			if (mode === "focus") {
				if (!disabledActions.includes("followUp")) {
					sessionActions.push("followUp");
				}
				if (!disabledActions.includes("share")) {
					sessionActions.push("share");
				}
			}
		},
		refused: () => {
			if (IS_CLIENT && !disabledActions.includes("reschedule")) {
				sessionActions.push("reschedule");
			}
		},
		rescheduled: () => {
			if (mode === "focus" && !disabledActions.includes("share")) {
				sessionActions.push("share");
			}
		},
		sent: () => {
			if (mode === "focus") {
				if (IS_INTERPRETER) {
					if (!disabledActions.includes("accept")) {
						sessionActions.push("accept");
					}
					if (!disabledActions.includes("refuse")) {
						sessionActions.push("refuse");
					}
				}
				if (!disabledActions.includes("calendar")) {
					sessionActions.push("calendar");
				}
				if (IS_CLIENT) {
					if (!disabledActions.includes("cancel")) {
						sessionActions.push("cancel");
					}
					if (!disabledActions.includes("reschedule")) {
						sessionActions.push("reschedule");
					}
				}
			}
		},
		stopped: () => {
			if (IS_INTERPRETER && !disabledActions.includes("validate")) {
				sessionActions.push("validate");
			}
			if (!disabledActions.includes("followUp")) {
				sessionActions.push("followUp");
			}
			if (mode === "focus" && !disabledActions.includes("share")) {
				sessionActions.push("share");
			}
		},
		unvalidated: () => {
			if (IS_INTERPRETER && !disabledActions.includes("validate")) {
				sessionActions.push("validate");
			}
			if (!disabledActions.includes("followUp")) {
				sessionActions.push("followUp");
			}
			if (mode === "focus" && !disabledActions.includes("share")) {
				sessionActions.push("share");
			}
		},
	});

	if (context && context === "message") {
		if (!disabledActions.includes("ignore")) {
			sessionActions.push("ignore");
		}
		if (!disabledActions.includes("read")) {
			sessionActions.push("read");
		}
	}
	return sessionActions;
};

export const SessionActions = ({session, actions, renderAction, scrollToEnd, callbacks, mode}: Props): JSX.Element => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation<StackNavigationProp<"SessionModal">>();
	const {accountId} = React.useContext(AuthentifiedContext);
	const {reset} = React.useContext(SessionCreationContext);

	const onPressReschedule = (): void => {
		const requesterCalendarMandatePreview = session as RequesterCalendarMandatePreview;
		requesterCalendarMandatePreview.status === "confirmed" ||
		requesterCalendarMandatePreview.excludedInterpreters.length > 0
			? navigation.navigate(
				"CancelOrRescheduleSessionModal",
				{callbacks, context: "reschedule", session: requesterCalendarMandatePreview},
			)
			: toRescheduleCreation(requesterCalendarMandatePreview, {}, reset, navigation as never);
	};

	const onPressAccept = (): void =>
		sessionConfirmation({
			actions: [
				{
					backgroundColor: GREEN,
					isPromise: true,
					key: "accept",
					onPress: () => acceptSession(accountId!, session.activityId)
						.then(() => {
							callbacks?.("acceptSessionSuccess");
							Log.success("acceptSessionSuccess")();

							if (mode === "list") {
								sessionConfirmation({
									actions: [
										{
											key: "accept",
											onPress: () => navigation.navigate("SessionModal", {sessionId: session.activityId}),
											text: t("activities:sessions.accept.viewAction"),
										},
										{
											key: "closeDrawer",
											onPress: () => null,
											text: ct("common:close"),
											type: "secondary",
										},
									],
									icon: "validationOk",
									iconColor: GREEN,
									session,
									text: t("activities:sessions.accept.assigned", {id: session.activityId}),
								});
							}
						})
						.catch((error: Error) =>
							ps(error.message, {
								default: () => Log.error("acceptSessionFailed")(error),
								sessionAlreadyAttributed: () => Log.error("sessionAlreadyAttributed", false)(error),
								unavailableTimeslot: () => Log.error("unavailableTimeslot", false)(error),
							}),
						),
					text: ct("common:accept"),
				},
				{
					key: "closeDrawer",
					onPress: () => null,
					text: ct("common:cancel"),
					type: "secondary",
				},
			],
			session,
			text: t("activities:sessions.accept.validation"),
		});
	const onPressRefuse = (): void =>
		navigation.navigate(
			"RefuseSessionModal",
			{
				callbacks,
				session: session as InterpreterCalendarMandatePreview,
			},
		);
	const onPressViewCalendar = (): void => navigation.push("CalendarModal", {focusDate: session.start.toISOString()});
	const onPressRead = (): void => navigation.push("SessionModal", {scrollToEnd: true, sessionId: session.activityId});
	const onPressValidate = (): void => navigation.navigate(
		"SessionValidationModal",
		{
			sessionId: session.activityId,
			validationCallback: () => callbacks?.("updateSessionValidationSuccess"),
		},
	);
	const onPressStartPhoneCall = (): Promise<unknown> => session.communication.type === "phone"
		? linkToCall(session.communication.phoneNumber)
		: Promise.resolve();
	const onPressStartVideoCall = (): void => {
		if (session.communication.type === "video") {
			navigation.navigate(
				"VideoSessionModal",
				{
					channelId: session.communication.channelId,
					secret: session.communication.secret,
					sessionId: session.activityId,
					token: session.communication.token!,
				},
			);
		}
	};
	const onPressNavigate = (): Promise<unknown> => session.communication.type === "inPerson"
		? linkToAddress(session.communication.place.address)
		: Promise.resolve();
	const onPressCFF = (): Promise<unknown> =>
		session.communication.type === "inPerson"
			? linkToURL(
				swissPublicTransportUrl({
					date: new Date(),
					departure: true,
					from: "", // I  let the user fill from where he wants to take the public transports
					to: session.communication.place.address,
				}))
			: Promise.resolve();
	const onPressCopy = (): void => {
		Clipboard.setStringAsync((session.communication as InPerson).place.address).catch(Log.error());
		Log.success("copyAddressSuccess")();
	};
	const onPressShare = (): void => navigation.navigate(
		"ShareSessionModal",
		{sessionId: session.activityId, shareType: "standard"},
	);
	const onPressFollowUp = (): void => {
		if (IS_INTERPRETER) {
			navigation.navigate("FollowUpModal", {sessionId: session.activityId});
		} else {
			// Requester app
			const sessionPreview = session as RequesterCalendarMandatePreview;
			const providers: Session["providers"] = sessionPreview.providers.map((p) => ({...p, status: "pending"}));
			reset(
				{type: "followUp"},
				{
					initialProvider: sessionPreview.providers[0],
					session: {...sessionPreview, context: {type: "followUp"}, providers},
				},
			);
			(navigation as ClientStackNavigatorScreenProps<"SessionModal">["navigation"])
				.navigate("HomeTabNavigator", {screen: "NewSession"});
		}
	};
	const onPressCancel = (): void => navigation.navigate(
		"CancelOrRescheduleSessionModal",
		{
			callbacks,
			context: "cancel",
			session: session as RequesterCalendarMandatePreview,
		},
	);

	// Button order
	return (
		<>
			{actions.map((action, index) =>
				s(action, {
					CFF: IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "train", t("users:interpreter.ticket"), onPressCFF, cffButtonStyle),
					accept: IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "check", ct("common:accept"), onPressAccept, {backgroundColor: GREEN}),
					calendar: renderAction(
						`${action}-${index}`,
						"calendar",
						IS_INTERPRETER ? ct("common:calendar") : t("users:menu.calendar"),
						onPressViewCalendar,
						secondaryButtonStyle,
					),
					cancel: IS_CLIENT &&
						renderAction(`${action}-${index}`, "cancel", ct("users:requester.cancel"), onPressCancel),
					copyAddress:
						IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "copy", t("users:interpreter.copyAddress"), onPressCopy,
							secondaryButtonStyle,
						),
					fairtiq: IS_INTERPRETER && IS_MOBILE &&
						renderAction(`${action}-${index}`, "toggleOn", "Fairtiq", onPressFairtiq, secondaryButtonStyle),
					followUp: renderAction(`${action}-${index}`, "recover", ct("common:followUp"), onPressFollowUp),
					ignore: renderAction(
						`${action}-${index}`, "close", ct("common:ignore"), onPressIgnore, secondaryLightButtonStyle),
					message: renderAction(`${action}-${index}`, "forward", ct("common:message_plural"), scrollToEnd),
					navigate: IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "navigate", ct("common:navigate"), onPressNavigate),
					phoneCall: renderAction(
						`${action}-${index}`,
						"play",
						ct("common:call"),
						onPressStartPhoneCall,
						{backgroundColor: GREEN},
						session.start.getTime() - beforeCallDelay > Date.now(),
					),
					read: renderAction(`${action}-${index}`, "letter", ct("common:read"), onPressRead),
					refuse: IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "close", ct("common:refuse"), onPressRefuse, {backgroundColor: RED}),
					reschedule: !IS_INTERPRETER &&
						renderAction(`${action}-${index}`, "recover", ct("common:reschedule"), onPressReschedule),
					share: renderAction(`${action}-${index}`, "share", ct("common:share"), onPressShare),
					validate:
						(session.start < new Date() || (session.start > new Date() && session.cancelable)) &&
						renderAction(
							`${action}-${index}`, "check", ct("common:validate"), onPressValidate, undefined, session.locked),
					videoCall: renderAction(
						`${action}-${index}`,
						"play",
						ct("common:join"),
						onPressStartVideoCall,
						{backgroundColor: GREEN},
						session.start.getTime() - beforeCallDelay > Date.now() ||
						(session.communication.type === "video" && !session.communication.token),
					),
				}),
			)}
		</>
	);
};
