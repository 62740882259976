import {Account} from "../../../@types/accounts";
import {SessionCategory} from "../../../@types/activities/session-category";
import {Page, Pagination} from "../../../@types/pagination";
import {
	createSessionCategoryAPI, deleteSessionCategoryAPI, updateSessionCategoryAPI,
} from "../../../utils/development/fake/session-categories/api";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {BackendCategory} from "../../@types/user";

export type UserCategoriesRequests = {
	GET: {},
};

type QueryParams = {
	limit: string,
	offset: string,
	search?: string,
};
export const getSessionCategories = async (_accountId: Account["accountId"], pagination: Pagination, search?: string):
Promise<Page<SessionCategory>> => {
	const {offset, limit} = pagination;
	const queryParams: QueryParams = {
		limit: limit.toString(),
		offset: (offset + 1).toString(),
	};
	if (search) {
		queryParams.search = search;
	}
	const response = await api(
		"userCategories",
		"GET",
		{
			queryParams,
		},
	) as ApiResponse<{total: number, category: BackendCategory}>;
	if (response.isSuccess) {
		const categoryOptions: SessionCategory[] = response.data.category.categoryOptions.map(item => ({
			id: item.id.toString(),
			name: item.categoryOption,
		}));
		return {
			items: categoryOptions,
			hasPreviousPage: offset > 0,
			hasNextPage: response.data.total > offset,
			totalCount: response.data.total,
		};
	}
	throw new Error(response.message);
};

export const addSessionCategory = (_accountId: Account["accountId"], added: SessionCategory): Promise<SessionCategory> =>
	createSessionCategoryAPI(added);

export const updateSessionCategory = (_accountId: Account["accountId"], updated: SessionCategory): Promise<SessionCategory> =>
	updateSessionCategoryAPI(updated);

export const deleteSessionCategory = (_accountId: Account["accountId"], deleted: SessionCategory): Promise<void> =>
	deleteSessionCategoryAPI(deleted);
