import {createIconSetFromIcoMoon, MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";
import * as React from "react";
import {
	Animated,
	ColorValue,
	GestureResponderEvent,
	StyleProp,
	StyleSheet,
	TextStyle,
	View,
	ViewProps,
} from "react-native";
import {PRIMARY_2} from "../utils/styles/colors";
import {Pressable} from "./views/pressable";
// eslint-disable-next-line unicorn/prefer-module, @typescript-eslint/no-var-requires
const LogoFont = require("../../assets/fonts/logo/selection.json");

export const CustomIcon = createIconSetFromIcoMoon(LogoFont, "logo", "logo.ttf");

export type IconKey =
	| "about"
	| "address"
	| "ageGroup"
	| "alert"
	| "appIcon"
	| "availability"
	| "back"
	| "backgroundBlur"
	| "birthday"
	| "book"
	| "calendar"
	| "calendarAlert"
	| "calendarClock"
	| "calendarMonth"
	| "calendarPlus"
	| "calendarQuestion"
	| "calendarUser"
	| "camera"
	| "cameraFlip"
	| "cancel"
	| "card"
	| "carretBottom"
	| "carretRight"
	| "category"
	| "check"
	| "checkBoxEmpty"
	| "checkBoxMarked"
	| "checkList"
	| "chevronBottom"
	| "chevronLeft"
	| "chevronRight"
	| "chevronUp"
	| "close"
	| "comment"
	| "commentMore"
	| "commentMoreOutline"
	| "communicationMode"
	| "component"
	| "copy"
	| "corporation"
	| "day"
	| "delete"
	| "directPhone"
	| "download"
	| "edit"
	| "endDate"
	| "endTime"
	| "excluded"
	| "feedback"
	| "focus"
	| "forward"
	| "gender"
	| "guide"
	| "handshake"
	| "help"
	| "history"
	| "home"
	| "id"
	| "inbox"
	| "info"
	| "institution"
	| "interpreters"
	| "key"
	| "knownIssues"
	| "language"
	| "letter"
	| "lightbulb"
	| "link"
	| "list"
	| "location"
	| "lock"
	| "logout"
	| "mail"
	| "map"
	| "medical"
	| "microphone"
	| "microphoneOff"
	| "money"
	| "moreVertical"
	| "navigate"
	| "night"
	| "notifications"
	| "notificationsOff"
	| "notificationsOn"
	| "payment"
	| "people"
	| "peopleOutline"
	| "person"
	| "personExcluded"
	| "personOutline"
	| "phone"
	| "phonePad"
	| "picture"
	| "place"
	| "placeholder"
	| "play"
	| "plus"
	| "plusOutline"
	| "position"
	| "rating1"
	| "rating1Outline"
	| "rating2"
	| "rating2Outline"
	| "rating3"
	| "rating3Outline"
	| "rating4"
	| "rating4Outline"
	| "rating5"
	| "rating5Outline"
	| "ratingEmpty"
	| "ratingFull"
	| "receiver"
	| "recover"
	| "refresh"
	| "review"
	| "school"
	| "search"
	| "selectEmpty"
	| "selectMarked"
	| "send"
	| "settings"
	| "share"
	| "signalOff"
	| "signalOn"
	| "startDate"
	| "startTime"
	| "stats"
	| "status"
	| "support"
	| "sync"
	| "time"
	| "timer"
	| "toggleOn"
	| "train"
	| "travel"
	| "tune"
	| "unvalidated"
	| "validationError"
	| "validationOk"
	| "video"
	| "videoOff"
	| "warning"
	| "weekEnd"
	| "world";

const iconsProps = (): {
	[icon in IconKey]: Omit<IconProps, "icon"> & {
		name: string;
		type?: "community" | "custom" | "official";
	};
} => ({
	about: {
		name: "description",
	},
	address: {
		name: "home",
	},
	ageGroup: {
		name: "human-male-male",
		type: "community",
	},
	alert: {
		name: "alert-circle-outline",
		type: "community",
	},
	appIcon: {
		name: "app-icon",
		type: "custom",
	},
	availability: {
		name: "calendar-account",
		type: "community",
	},
	back: {
		name: "arrow-back",
	},
	backgroundBlur: {
		name: "flip-to-front",
	},
	birthday: {
		name: "cake",
		type: "community",
	},
	book: {
		name: "book-open-variant",
		type: "community",
	},
	calendar: {
		name: "calendar-text",
		type: "community",
	},
	calendarAlert: {
		name: "calendar-alert",
		type: "community",
	},
	calendarClock: {
		name: "calendar-clock",
		type: "community",
	},
	calendarMonth: {
		name: "calendar-month",
		type: "community",
	},
	calendarPlus: {
		name: "calendar-plus",
		type: "community",
	},
	calendarQuestion: {
		name: "calendar-question",
		type: "community",
	},
	calendarUser: {
		name: "perm-contact-calendar",
	},
	camera: {
		name: "camera-alt",
	},
	cameraFlip: {
		name: "camera-flip",
		type: "community",
	},
	cancel: {
		name: "cancel",
	},
	card: {
		name: "cards-outline",
		type: "community",
	},
	carretBottom: {
		name: "arrow-drop-down",
	},
	carretRight: {
		containerStyle: {transform: [{rotate: "-90deg"}]},
		name: "arrow-drop-down",
	},
	category: {
		name: "category",
	},
	check: {
		name: "check",
	},
	checkBoxEmpty: {
		name: "checkbox-blank-outline",
		type: "community",
	},
	checkBoxMarked: {
		name: "checkbox-marked",
		type: "community",
	},
	checkList: {
		name: "playlist-add-check",
	},
	chevronBottom: {
		name: "expand-more",
	},
	chevronLeft: {
		name: "chevron-left",
	},
	chevronRight: {
		name: "chevron-right",
	},
	chevronUp: {
		name: "expand-less",
	},
	close: {
		name: "close",
	},
	comment: {
		name: "comment",
	},
	commentMore: {
		name: "comment-processing",
		type: "community",
	},
	commentMoreOutline: {
		name: "comment-processing-outline",
		type: "community",
	},
	communicationMode: {
		name: "swap-calls",
	},
	component: {
		name: "dashboard",
	},
	copy: {
		name: "content-copy",
	},
	corporation: {
		name: "business",
	},
	day: {
		name: "calendar-week-begin",
		type: "community",
	},
	delete: {
		name: "delete",
	},
	directPhone: {
		name: "card-account-phone-outline",
		type: "community",
	},
	download: {
		name: "file-download",
	},
	edit: {
		name: "edit",
	},
	endDate: {
		name: "calendar-end",
		type: "community",
	},
	endTime: {
		name: "clock-end",
		type: "community",
	},
	excluded: {
		name: "block-flipped",
	},
	feedback: {
		name: "feedback",
	},
	focus: {
		name: "filter-center-focus",
	},
	forward: {
		name: "arrow-forward",
	},
	gender: {
		name: "face",
	},
	guide: {
		name: "menu-book",
	},
	handshake: {
		name: "handshake",
		type: "community",
	},
	help: {
		name: "help",
	},
	history: {
		name: "restore",
	},
	home: {
		name: "home",
	},
	id: {
		name: "bookmark",
	},
	inbox: {
		name: "inbox",
	},
	info: {
		name: "info-outline",
	},
	institution: {
		name: "account-balance",
	},
	interpreters: {
		name: "people",
	},
	key: {
		name: "vpn-key",
	},
	knownIssues: {
		name: "alert-circle-check-outline",
		type: "community",
	},
	language: {
		name: "translate",
	},
	letter: {
		name: "email",
	},
	lightbulb: {
		name: "lightbulb",
	},
	link: {
		name: "link-variant",
		type: "community",
	},
	list: {
		name: "format-list-bulleted",
	},
	location: {
		name: "location-on",
	},
	lock: {
		name: "lock",
	},
	logout: {
		name: "logout",
		type: "community",
	},
	mail: {
		name: "email",
	},
	map: {
		name: "map-search",
		type: "community",
	},
	medical: {
		name: "medical-services",
	},
	microphone: {
		name: "microphone",
		type: "community",
	},
	microphoneOff: {
		name: "microphone-off",
		type: "community",
	},
	money: {
		name: "attach-money",
	},
	moreVertical: {
		name: "more-vert",
	},
	navigate: {
		name: "near-me",
	},
	night: {
		name: "moon-waning-crescent",
		type: "community",
	},
	notifications: {
		name: "notifications",
	},
	notificationsOff: {
		name: "notifications-off",
	},
	notificationsOn: {
		name: "notifications-active",
	},
	payment: {
		name: "credit-card",
		type: "community",
	},
	people: {
		name: "people",
	},
	peopleOutline: {
		name: "people-outline",
	},
	person: {
		name: "person",
	},
	personExcluded: {
		name: "account-cancel",
		type: "community",
	},
	personOutline: {
		name: "person-outline",
	},
	phone: {
		name: "local-phone",
	},
	phonePad: {
		name: "dialpad",
	},
	picture: {
		name: "insert-photo",
	},
	place: {
		name: "place",
	},
	placeholder: {
		name: "placeholder",
	},
	play: {
		name: "play-arrow",
	},
	plus: {
		name: "add",
	},
	plusOutline: {
		name: "add-circle-outline",
	},
	position: {
		name: "my-location",
	},
	rating1: {
		name: "emoticon-dead",
		type: "community",
	},
	rating1Outline: {
		name: "emoticon-dead-outline",
		type: "community",
	},
	rating2: {
		name: "emoticon-sad",
		type: "community",
	},
	rating2Outline: {
		name: "emoticon-sad-outline",
		type: "community",
	},
	rating3: {
		name: "emoticon-neutral",
		type: "community",
	},
	rating3Outline: {
		name: "emoticon-neutral-outline",
		type: "community",
	},
	rating4: {
		name: "emoticon-happy",
		type: "community",
	},
	rating4Outline: {
		name: "emoticon-happy-outline",
		type: "community",
	},
	rating5: {
		name: "emoticon-excited",
		type: "community",
	},
	rating5Outline: {
		name: "emoticon-excited-outline",
		type: "community",
	},
	ratingEmpty: {
		name: "star-outline",
	},
	ratingFull: {
		name: "star",
	},
	receiver: {
		name: "assignment-ind",
	},
	recover: {
		name: "update",
	},
	refresh: {
		name: "refresh",
	},
	review: {
		name: "rate-review",
	},
	school: {
		name: "school",
	},
	search: {
		name: "search",
	},
	selectEmpty: {
		name: "checkbox-blank-circle-outline",
		type: "community",
	},
	selectMarked: {
		name: "checkbox-marked-circle",
		type: "community",
	},
	send: {
		name: "send",
	},
	settings: {
		name: "settings",
	},
	share: {
		name: "share",
		type: "community",
	},
	signalOff: {
		name: "portable-wifi-off",
	},
	signalOn: {
		name: "wifi-tethering",
	},
	startDate: {
		name: "calendar-start",
		type: "community",
	},
	startTime: {
		name: "clock-start",
		type: "community",
	},
	stats: {
		name: "chart-line",
		type: "community",
	},
	status: {
		name: "linear-scale",
	},
	support: {
		name: "contact-support",
	},
	sync: {
		name: "sync",
	},
	time: {
		name: "access-time",
	},
	timer: {
		name: "av-timer",
		type: "community",
	},
	toggleOn: {
		name: "toggle-on",
	},
	train: {
		name: "train",
	},
	travel: {
		name: "road-variant",
		type: "community",
	},
	tune: {
		name: "tune",
	},
	unvalidated: {
		name: "calendar-check",
		type: "community",
	},
	validationError: {
		name: "error-outline",
	},
	validationOk: {
		name: "check-circle-outline",
		type: "community",
	},
	video: {
		name: "videocam",
	},
	videoOff: {
		name: "video-off",
		type: "community",
	},
	warning: {
		name: "warning",
	},
	weekEnd: {
		name: "calendar-weekend",
		type: "community",
	},
	world: {
		name: "language",
	},
});

const ICON_CONTAINER_SIZE = 28;
const ICON_SIZE = 24;

export interface IconProps {
	animated?: boolean;
	color?: ColorValue;
	containerSize?: number;
	containerStyle?: StyleProp<TextStyle | {}>;
	disabled?: boolean;
	focusable?: boolean;
	hitSlop?: ViewProps["hitSlop"];
	icon: IconKey;
	onPress?: (event: GestureResponderEvent) => void;
	size?: number;
	style?: StyleProp<TextStyle | {}>;
}

type IconComponentType = React.ComponentType<Omit<IconProps, "icon"> & {name: any}>;
const IconRender = (
	{
		size = ICON_SIZE,
		containerSize = ICON_CONTAINER_SIZE,
		color = PRIMARY_2,
		containerStyle,
		onPress,
		animated = false,
		icon,
		focusable,
		hitSlop,
		style,
		disabled,
	}: IconProps,
	ref: React.Ref<View>,
): JSX.Element => {
	const scaledContainerSize = size > containerSize ? size : containerSize;
	const {type, name, ...props} = iconsProps()[icon];
	const wrapperStyle = [
		styles.iconContainer,
		{height: scaledContainerSize, width: scaledContainerSize},
		props.containerStyle,
		containerStyle,
	];
	const renderIcon = (): JSX.Element => {
		const iconProps = {color, name, size, style, ...props};
		let IconComponent: Animated.AnimatedComponent<IconComponentType> | IconComponentType;

		if (name === "placeholder") {
			return <View style={{height: size, width: size}}/>;
		}
		switch (type) {
			case "community": {
				IconComponent = MaterialCommunityIcons;
				break;
			}
			case "custom": {
				IconComponent = CustomIcon;
				break;
			}
			default: {
				IconComponent = MaterialIcons;
			}
		}
		if (animated) {
			IconComponent = Animated.createAnimatedComponent<IconComponentType>(IconComponent);
		}
		return <IconComponent {...iconProps} />;
	};

	return onPress && !disabled
		? (
			<Pressable
				ref={ref}
				style={wrapperStyle}
				onPress={onPress}
				hitSlop={hitSlop ?? {bottom: 4, left: 4, right: 4, top: 4}}
				focusable={focusable}
				pointerEvents="box-only"
			>
				{renderIcon()}
			</Pressable>
		)
		: (
			<View ref={ref} style={wrapperStyle}>
				{renderIcon()}
			</View>
		);
};

export const Icon = React.forwardRef(IconRender);

const styles = StyleSheet.create({
	iconContainer: {
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
	},
});
