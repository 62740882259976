import {entities as enUSEntities} from "../en-US/entities";

export const entities: typeof enUSEntities = {
	billingAddress: {
		address: "Indirizzo",
		department: "Dipartimento",
		name: "Nome/Azienda",
		postOfficeBox: "Casella postale",
		postalCode: "Codice postale",
		town: "Città",
	},
};
