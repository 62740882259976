import {Session} from "../../@types/activities/session";
import {Corporation} from "../../@types/identities/corporation";
import {Person} from "../../@types/identities/person";
import {MedicalProfessional, OtherProfessional} from "../../@types/identities/professional";
import {ImageMime} from "../../@types/medias/image";
import {Owner} from "../@types/person";
import {BackendUser, UserAccountPerson} from "../@types/user";
import {transformPrefixFrontToBack, transformPrefixToGender} from "./transform-common";

export const transformPersonFrontToBack = ({gender, fullName, email, phone}: Person): UserAccountPerson => ({
	firstName: fullName?.firstName,
	lastName: fullName?.lastName,
	email,
	prefix: transformPrefixFrontToBack(gender),
	telephone: phone,
});

export const transformMedicalProfessionalFrontToBack = (
	{gender, fullName, email, phone, directPhone, institution, function: personFunction}: MedicalProfessional,
): UserAccountPerson => ({
	firstName: fullName?.firstName,
	lastName: fullName?.lastName,
	email,
	prefix: transformPrefixFrontToBack(gender),
	telephone: phone,
	mobileNumber: directPhone,
	function: personFunction,
	institution,
});

export const transformOtherProfessionalFrontToBack = (
	{gender, fullName, email, phone, directPhone, function: personFunction}: OtherProfessional,
): UserAccountPerson => ({
	firstName: fullName?.firstName,
	lastName: fullName?.lastName,
	email,
	prefix: transformPrefixFrontToBack(gender),
	telephone: phone,
	mobileNumber: directPhone,
	function: personFunction,
});

export const transformPersonBackToFront = (
	{id, firstName, lastName, email, telephone, prefix, country}: UserAccountPerson,
): Person => ({
	identityId: id.toString(),
	accountId: id.toString(),
	fullName: (firstName || lastName)
		? {
			firstName,
			lastName,
		}
		: undefined,
	email,
	phone: telephone,
	gender: transformPrefixToGender(prefix),
	origin: country?.iso,
	type: "person",
});

export const transformMedicalProfessionalBackToFront = (
	{id, firstName, lastName, email, telephone, prefix, country, mobileNumber, institution, function: personFunction}: UserAccountPerson,
): MedicalProfessional => ({
	identityId: id.toString(),
	accountId: id.toString(),
	fullName: (firstName || lastName)
		? {
			firstName,
			lastName,
		}
		: undefined,
	email,
	phone: telephone,
	gender: transformPrefixToGender(prefix),
	origin: country?.iso,
	type: "medicalProfessional",
	directPhone: mobileNumber,
	function: personFunction,
	institution,
});

export const transformOtherProfessionalBackToFront = (
	{id, firstName, lastName, email, telephone, prefix, country, mobileNumber, function: personFunction}: UserAccountPerson,
): OtherProfessional => ({
	identityId: id.toString(),
	accountId: id.toString(),
	fullName: (firstName || lastName)
		? {
			firstName,
			lastName,
		}
		: undefined,
	email,
	phone: telephone,
	gender: transformPrefixToGender(prefix),
	origin: country?.iso,
	type: "otherProfessional",
	directPhone: mobileNumber,
	function: personFunction,
});

export const transformOwnerToRequester = (owner: Owner): Session["requester"] => owner
	? {
		identityId: owner.id?.toString(),
		accountId: owner.id?.toString(),
		fullName: (owner.firstName || owner.lastName)
			? {
				firstName: owner.firstName,
				lastName: owner.lastName,
			}
			: undefined,
		email: owner?.email,
		phone: owner?.telephone.length === 9 ? `0${owner.telephone}` : owner?.telephone,
		gender: undefined,
		type: "person",
	}
	: null;

export const transformBackendUserToCorporation = (user: BackendUser): Corporation => {
	const {
		userAccountId, firstName, lastName, email, countryCode, telephone,
		orgName, logo,
	} = user;
	return {
		identityId: userAccountId,
		type: "corporation",
		name: `${firstName}${firstName && lastName ? " " : ""}${lastName}`,
		email,
		phone: countryCode + telephone,
		group: {
			name: orgName,
			logo: {
				sourceType: "url",
				mime: logo.mime as ImageMime,
				width: logo.width,
				height: logo.height,
				source: logo.url,
				mediaType: "image",
			},
		},
	};
};
