import {Account} from "../../../@types/accounts";
import {Page, Pagination} from "../../../@types/pagination";
import {Place, PlacesOrderType} from "../../../@types/places";
import {SupportedLocaleKey} from "../../../utils/locales/locales";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {ILocationResponse} from "../../@types/place";
import {transformPlaceBackToFront} from "../../utils/transform-place";

export type UserLocationsGetRequests = {
	GET: {
		pathParams: {userAccountId: string},
		queryParams: {
			limit?: string,
			offset?: string,
			search?: string,
			language?: string,
			order?: string,
		},
	},
};
export type UserLocationAddRequests = {
	POST: {
		body: {
			location: {
				latitude?: number,
				longitude?: number,
				formattedAddress: string,
			},
			organization?: {id: string},
			userAccount: {id: string},
		},
	},
};
export type UserLocationDeleteRequests = {
	DELETE: {
		body: {
			userAccountId: string,
			formattedAddress: string,
		},
	},
};
type UserLocation = {
	userAccountLocation: {
		formattedAddress: string,
		latitude: number,
		longitude: number,
	},
};
export type UserLocationsGetResponse = {
	locations: UserLocation[],
	TotalLocations: number,
};

export const getClientPlaces = async (
	accountId: Account["accountId"],
	locale: SupportedLocaleKey,
	pagination: Pagination,
	placesOrder: PlacesOrderType,
): Promise<Page<Place>> => {
	const {offset, limit} = pagination;
	// const backendOffset = offset === 0 ? 1 : offset / limit;
	const userResponse = await api(
		"userLocationsGet",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				limit: limit.toString(),
				offset: (offset + 1).toString(),
				language: locale.slice(0, 2),
				order: placesOrder,
			},
		},
	) as ApiResponse<UserLocationsGetResponse>;
	if (userResponse.isSuccess) {
		const {locations, TotalLocations} = userResponse.data;
		const locationList: Place[] = locations.map((data) => {
			const {formattedAddress, latitude, longitude} = data.userAccountLocation;
			return {
				address: formattedAddress,
				geocode: {lat: latitude, lng: longitude},
			};
		});
		return {
			items: locationList,
			hasPreviousPage: offset > 0,
			hasNextPage: TotalLocations > offset,
			totalCount: TotalLocations,
		};
	}
	throw new Error(userResponse.message);
};

export const addClientPlace = async (accountId: Account["accountId"], addedAddress: Place["address"]): Promise<Place> => {
	const userResponse = await api(
		"userLocationAdd",
		"POST",
		{
			body: {
				location: {
					formattedAddress: addedAddress,
				},
				userAccount: {id: accountId},
			},
		},
	) as ApiResponse<ILocationResponse>;
	return userResponse.isSuccess
		? Promise.resolve(transformPlaceBackToFront(userResponse?.data))
		: Promise.reject(new Error(userResponse?.message));
};

export const deleteClientPlace = async (accountId: Account["accountId"], deletedAddress: Place["address"]): Promise<void> => {
	const userResponse = await api(
		"userLocationDelete",
		"DELETE",
		{
			body: {
				userAccountId: accountId,
				formattedAddress: deletedAddress,
			},
		},
	) as ApiResponse<{}>;
	return userResponse.isSuccess ? Promise.resolve() : Promise.reject(new Error(userResponse.message));
};

export const searchClientPlace = async (
	accountId: Account["accountId"],
	locale: SupportedLocaleKey,
	search: string, pagination: Pagination,
):
Promise<Page<Place>> => {
	const {offset, limit} = pagination;
	// const backendOffset = offset === 0 ? 1 : offset / limit;
	const userResponse = await api(
		"userLocationsGet",
		"GET",
		{
			pathParams: {
				userAccountId: accountId,
			},
			queryParams: {
				search,
				limit: limit.toString(),
				offset: (offset + 1).toString(),
				language: locale.slice(0, 2),
			},
		},
	) as ApiResponse<UserLocationsGetResponse>;
	if (userResponse.isSuccess) {
		const {locations, TotalLocations} = userResponse.data;
		const locationList: Place[] = locations.map((data) => {
			const {formattedAddress, latitude, longitude} = data.userAccountLocation;
			return {
				address: formattedAddress,
				geocode: {lat: latitude, lng: longitude},
			};
		});
		return {
			items: locationList,
			hasPreviousPage: offset > 0,
			hasNextPage: TotalLocations > offset,
			totalCount: TotalLocations,
		};
	}
	throw new Error(userResponse.message);
};
