import {useRoute} from "@react-navigation/native";
import * as React from "react";
import {Falsy, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {filterTruthy} from "../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING, TITLEBAR_HEIGHT} from "../../utils/constants";
import {SUBTLE} from "../../utils/styles/colors";
import {Button, ButtonProps} from "../buttons/button";
import {Spacer} from "../spacer";
import {Text} from "../texts/text";

interface Props {
	buttons?: (Falsy | Pick<ButtonProps, "icon" | "notif" | "onPress"> & {key: string})[];
	buttonsText?: string;
	style?: StyleProp<ViewStyle>;
	title: string;
}

export const TitleBar = ({title, buttons, buttonsText, style}: Props): JSX.Element => {
	const route = useRoute();
	const inModal = route.name.endsWith("Modal");
	return (
		<View style={[styles.container, style]}>
			<View style={[CONTAINERS.MAIN, styles.title]}>
				<View style={styles.left}>
					<Text type={inModal ? "title_2" : "title"} numberOfLines={1}>{title}</Text>
				</View>
				<View style={styles.right}>
					{!!buttonsText && <Text type="default_1" numberOfLines={1} style={styles.buttonsText}>{buttonsText}</Text>}
					{buttons && filterTruthy(buttons).map(({key, icon, onPress, notif}, i) => (
						<View style={styles.right} key={key}>
							{i > 0 && <Spacer size={SMALL_SPACING} mode="vertical"/>}
							<Button icon={icon} onPress={onPress} size="xsmall" type="secondary" notif={notif}/>
						</View>
					))}
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	buttonsText: {
		marginRight: SMALL_SPACING,
	},
	container: {
		backgroundColor: SUBTLE,
	},
	left: {
		flex: 1,
	},
	right: {
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "center",
	},
	title: {
		alignItems: "center",
		flexDirection: "row",
		height: TITLEBAR_HEIGHT,
		justifyContent: "space-between",
		paddingHorizontal: DEFAULT_SPACING,
	},
});
