import moment from "moment";
import "moment-timezone";
import {Locales} from "../locales/locales";
import {t} from "../locales/translations";
import {computeDuration, getTimeCoef} from "./helpers";

const SWISS_TIMEZONE = "Europe/Zurich";

export const customFormatDate = (date: Date, format: string, timezone = SWISS_TIMEZONE): string => timezone
	? moment(date).tz(timezone).format(format)
	: moment(date).format(format);
export const formatDate = (date: Date): string =>
	customFormatDate(date, "dddd D MMMM YYYY");
export const formatShortDate = (date: Date): string =>
	customFormatDate(date, "D MMMM YYYY");
export const formatShortestDate = (date: Date): string =>
	customFormatDate(date, "DD/MM/YY");
export const formatTime = (date: Date): string =>
	customFormatDate(date, "HH:mm");
export const formatShortTimeRange = (start: Date, end: Date): string =>
	`${formatTime(start)}${end ? ` - ${formatTime(end)}` : ""}`;
export const formatDay = (date: Date): string =>
	customFormatDate(date, "dddd");
export const formatDayMonth = (date: Date): string =>
	customFormatDate(date, "D MMMM");
export const formatDateTime = (date: Date): string =>
	customFormatDate(date, "dddd D MMMM YYYY, HH:mm");
export const formatDateTimeRange = (start: Date, end: Date): string =>
	`${customFormatDate(start, "dddd D MMMM YYYY, HH:mm")} - ${customFormatDate(end, "HH:mm")}`;
export const formatShortDateTimeRange = (start: Date, end: Date): string =>
	`${customFormatDate(start, "D MMMM YYYY, HH:mm")} - ${customFormatDate(end, "HH:mm")}`;
export const formatShortDateTime = (date: Date): string =>
	customFormatDate(date, "D MMMM YYYY · HH:mm");
export const formatDayTime = (date: Date): string =>
	customFormatDate(date, Locales.currentLocale === "de-DE" ? "dd HH:mm" : "ddd HH:mm");
export const formatMonthKey = (date: Date): string =>
	customFormatDate(date, "YYYY-MM", SWISS_TIMEZONE);
export const formatDayKey = (date: Date): string =>
	customFormatDate(date, "YYYY-MM-DD", SWISS_TIMEZONE);
export const formatDuration = (from: Date, to: Date): string =>
	formatDurationFromMS(computeDuration(from, to));
export const getDuration = (duration: number):
{days: number; hours: number; minutes: number; months: number; years: number} => {
	const d = moment.duration(duration);
	const years = Math.abs(d.years());
	const months = Math.abs(d.months());
	const days = Math.abs(d.days());
	const hours = Math.abs(d.hours());
	const minutes = Math.abs(d.minutes());
	return {days, hours, minutes, months, years};
};
export const formatDurationFromMS = (duration: number): string => {
	const {years, months, days, hours, minutes} = getDuration(duration);
	const yearsString = years ? `${years} ${t("common:year", {count: years})} ` : "";
	if (yearsString.length > 0) return yearsString.trim();
	const monthsString = months ? `${months} ${t("common:month", {count: months})} ` : "";
	if (monthsString.length > 0) return monthsString.trim();

	const daysString = days ? `${days} ${t("common:day", {count: days})} ` : "";
	if (daysString.length > 0) return daysString.trim();

	const hourString = hours ? `${hours} ${t("common:hour", {count: hours})} ` : "";
	const minuteString = minutes ? `${minutes} ${t("common:minute", {count: minutes})}` : "";

	if (hourString.length === 0 && minuteString.length === 0) return t("activities:sessions.call.lessOneMinute");
	return `${hourString}${minuteString}`;
};
export const formatDurationInHM = (duration: number, abbrev = false): string => {
	const hours = Math.floor(duration / getTimeCoef("hour"));
	const minutes = Math.floor((duration / getTimeCoef("minute")) % 60);
	const hourString = hours ? `${hours} ${t(abbrev ? "activities:calendar.abbreviation.hour" : "common:hour", {count: hours})} ` : "";
	const minuteString = minutes ? `${minutes} ${t(abbrev ? "activities:calendar.abbreviation.minute" : "common:minute", {count: minutes})}` : "";
	return `${hourString}${minuteString}`;
};
export const formatSecondsDurationInMS = (seconds: number): string => {
	const minutes = Math.floor(seconds / 60);
	const secondes = Math.floor(seconds % 60);
	return `${minutes < 10 ? "0" : ""}${minutes}:${secondes < 10 ? "0" : ""}${secondes}`;
};
