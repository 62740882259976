import * as React from "react";
import {StyleSheet, View, ViewProps} from "react-native";
import {filterTruthy} from "../../utils/arrays";
import {MENU_HEIGHT, SMALL_SPACING} from "../../utils/constants";
import {SUBTLE} from "../../utils/styles/colors";
import {Button, Buttons} from "../buttons/button";
import {ScrollView} from "../scrollables/scroll-view";

interface Props {
	buttons: Buttons;
	viewProps?: ViewProps;
}

export const ButtonsBar = ({buttons, viewProps}: Props): JSX.Element => (
	<View {...viewProps} style={[styles.outerWrapper, viewProps && viewProps.style]}>
		<ScrollView
			contentContainerStyle={styles.container}
			showsHorizontalScrollIndicator={false}
			showsVerticalScrollIndicator={false}
			horizontal
			contentInsetAdjustmentBehavior="never"
		>
			<View style={styles.wrapper}>
				{filterTruthy(buttons).map((button, index) => (
					<Button
						size="small"
						type="secondary"
						{...button}
						key={button.key}
						style={index < buttons.length - 1 && {marginRight: SMALL_SPACING}}
					/>
				))}
			</View>
		</ScrollView>
	</View>
);

const WRAPPER_HEIGHT = 36;
const WRAPPER_PADDING = 2;
const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		flexDirection: "row",
		flexGrow: 1,
		height: MENU_HEIGHT,
		justifyContent: "center",
	},
	outerWrapper: {
		backgroundColor: SUBTLE,
		height: MENU_HEIGHT,
	},
	wrapper: {
		borderRadius: WRAPPER_HEIGHT / 2,
		flexDirection: "row",
		height: WRAPPER_HEIGHT,
		padding: WRAPPER_PADDING,
	},
});
