import * as React from "react";
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {ResponsiveContext} from "../../../contexts/responsive";
import {SMALL_SPACING} from "../../../utils/constants";
import {SUBTLE} from "../../../utils/styles/colors";

interface Props {
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
}

export const Columns = ({children, style: extraStyles}: Props): JSX.Element => {
	const {columns, contentWidth} = React.useContext(ResponsiveContext);
	return (
		<View style={[styles.columnsWrapper, extraStyles]}>
			<View style={[styles.columnsContainer, {padding: columns >= 2 ? SMALL_SPACING : 0, width: contentWidth}]}>
				{children}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	columnsContainer: {
		flex: 1,
		flexDirection: "row",
	},
	columnsWrapper: {
		alignItems: "center",
		backgroundColor: SUBTLE,
		flex: 1,
	},
});
