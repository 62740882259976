import {forms as enUSForms} from "../en-US/forms";

export const forms: typeof enUSForms = {
	inputs: {
		addEvent: "Aggiungere evento",
		allDay: "Tutto il giorno",
		defaultStatusExplanation: "Puoi scegliere quale stato di sessione il tuo calendario visualizzerà di default",
		deleteAllEvents: "Cancellare tutti gli eventi",
		deleteEvent: "Cancellare l'evento",
		deleteThisEvent: "Cancellare questo evento",
		editAllEvents: "Modifica tutti gli eventi",
		editEvent: "Modificare l'evento",
		endDate: "Data di fine",
		endTime: "Termina",
		logUnavailability: "Registrare l'assenza",
		recurrence: "Ricorrenza",
		selectCommunicationType: "Selezionare un tipo di comunicazione",
		selectDate: "Selezionare una data",
		selectDateTime: "Selezionare una data e un'ora",
		selectDuration: "Selezionare una durata",
		selectGender: "Selezionare un genere",
		selectInterpretersFilter: "Selezionare un filtro",
		selectLanguage: "Selezionare una lingua",
		selectMonth: "Seleziona un mese",
		selectNotificationChannel: "Selezionare un tipo di notifica",
		selectNotificationTarget: "Selezionare un indirizzo di notifica",
		selectOneOption: "Selezionare una delle opzioni seguenti",
		selectOrigin: "Seleziona un'origine",
		selectPlace: "Selezionare l'indirizzo esatto",
		selectProvidedService: "Selezionare un servizio",
		selectQualification: "Selezionare una qualifica",
		selectQualificationInfo: "Verranno visualizzati solo gli interpreti con tutte le qualifiche selezionate. Se non si effettua una selezione, verranno cercati tutti gli interpreti.",
		selectRecurrence: "Selezionare una ricorrenza",
		selectSession: "Selezionare una sessione",
		selectSessionOptions: "Selezionare l'opzione",
		selectStatus: "Selezionare lo stato",
		selectTransport: "Seleziona il trasporto",
		showCanceledSessions: "Visualizzare le sessioni cancellate e riprogrammate",
		showLess: "Mostra meno",
		showMore: "Mostra di più",
		showUnavailabilities: "Mostra indisponibilità",
		startDate: "Data di inizio",
		startTime: "Ora di inizio",
		toLanguage: "Lingua a",
		typeHere: "Scrivere qui...",
		typeTo: "Scrivere a {{name}}...",
		unavailability: "Indisponibilità",
		unsupportedFile: "Il formato del file non è supportato (estensione o nome).",
	},
	items: {
		add: "aggiungere {{item}}",
		delete: "elimina {{item}}",
		empty: "nessun {{item}}",
		select: "Selezionare {{item}}",
		update: "aggiorna {{item}}",
	},
	rules: {
		atLeastOneDigit: "Questo campo deve contenere un numero",
		atLeastOneLetter: "Questo campo deve contenere una lettera",
		atLeastOneSpecialLetter: "Questo campo deve contenere un carattere speciale",
		between: "Questo campo deve essere compreso tra {{min}} e {{max}} caratteri",
		durationGreaterOrEqualTo: "La durata deve essere almeno {{minimumDuration}} {{durationUnit}}",
		email: "Questa email non è valida",
		equalTo: "Questo campo deve essere uguale a \"{{otherLabel}}\"",
		max: "Questo campo non deve essere più lungo di {{max}} caratteri",
		min: "Questo campo deve essere lungo almeno {{min}} caratteri.",
		mustChange: "Devi cambiare il valore iniziale di questo campo",
		notEmpty: "Dovete riempire questo campo",
		onlyDigits: "Questo campo può contenere solo numeri",
		phone: "Questo numero di telefono non è valido",
		time: "Esempio: 09.30 o 14.45 (espresso in 24 ore)",
		url: "Questo url non è valido",
	},
};
