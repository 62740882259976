import * as React from "react";
import {FlatList, LayoutChangeEvent, ListRenderItemInfo, StyleSheet, View} from "react-native";
import {ActivitiesContext} from "../../contexts/activities";
import {ACTUAL_CONTAINER_SIZE, CONTAINERS, LABELS_HEIGHT, WEEK_HEIGHT} from "../../utils/constants";
import {OnDatePress, Week} from "../../utils/date-time/activities";
import {formatDayKey} from "../../utils/date-time/format";
import {computeDuration, FIRST_DAY_CALENDAR, getDayInWeek} from "../../utils/date-time/helpers";
import {flatListDefaultProps} from "../../utils/scrollables";
import {SUBTLE, SUBTLE_2} from "../../utils/styles/colors";
import {SplashView} from "../views/splash-view";
import {DaysRow} from "./days-row";
import {Week as WeekComponent} from "./week";

const FIRST_MONDAY_IN_CALENDAR = getDayInWeek(FIRST_DAY_CALENDAR, "monday");

export const WeekCalendar = ({onDayPress}: {onDayPress?: OnDatePress}): JSX.Element => {
	const [contentWidth, setContentWidth] = React.useState<number>(ACTUAL_CONTAINER_SIZE);
	const {weeks, focusDate, setFocusDate, activitiesFetching, transitionning} = React.useContext(ActivitiesContext);
	const flatListRef = React.useRef<FlatList<Week>>(null);

	const getIndex = React.useCallback(
		(date: Date) =>
			Math.ceil(computeDuration(FIRST_MONDAY_IN_CALENDAR, getDayInWeek(date, "sunday"), "week") - 1),
		[],
	);

	React.useEffect(
		() => {
			if (!activitiesFetching) {
				const index = getIndex(focusDate);
				if (flatListRef.current?.props?.data && flatListRef.current.props.data.length > 0 && index > 0 && index <
					flatListRef.current.props.data.length) {
					flatListRef.current.scrollToIndex({
						animated: true,
						index,
					});
				}
			}
		},
		[getIndex, focusDate, activitiesFetching, transitionning],
	);

	const renderItem = React.useCallback(
		({item}: ListRenderItemInfo<Week>) => (
			<WeekComponent
				onDayPress={onDayPress ?? setFocusDate.current}
				week={item}
				width={contentWidth}
				focusDate={focusDate}
			/>
		),
		[contentWidth, focusDate, onDayPress, setFocusDate],
	);

	const onLayout = React.useCallback(
		(event: LayoutChangeEvent) => setContentWidth(event.nativeEvent.layout.width),
		[],
	);

	const getItemLayout = React.useCallback(
		(_: Week[] | null | undefined, index: number) => ({
			index,
			length: contentWidth,
			offset: contentWidth * index,
		}),
		/*
		 * Even if it's not a true dependency, we NEED focusDate here, otherwise the items won't re-render even when the
		 * focus date changes, which would not show the selected focus date correctly.
		 * We also need setFocusDate.current because otherwise the items won't have the latest version of the function.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contentWidth, focusDate, setFocusDate.current],
	);

	const keyExtractor = React.useCallback(
		(week: Week) => formatDayKey(week[0].day),
		[],
	);

	return (
		<View style={[styles.background, styles.wrapper, CONTAINERS.MAIN, styles.container]}>
			<DaysRow/>
			{activitiesFetching
				? <SplashView centered loading/>
				: (
					<FlatList
						{...flatListDefaultProps}
						ref={flatListRef}
						keyExtractor={keyExtractor}
						horizontal
						pagingEnabled
						data={weeks}
						renderItem={renderItem}
						decelerationRate="fast"
						ItemSeparatorComponent={null}
						getItemLayout={getItemLayout}
						onLayout={onLayout}
						initialScrollIndex={getIndex(focusDate)}
						scrollsToTop={false}
						maxToRenderPerBatch={3}
						initialNumToRender={3}
						showsHorizontalScrollIndicator={false}
					/>
				)
			}
		</View>
	);
};

const styles = StyleSheet.create({
	background: {
		backgroundColor: SUBTLE,
	},
	container: {
		height: WEEK_HEIGHT + LABELS_HEIGHT,
	},
	wrapper: {
		borderBottomColor: SUBTLE_2,
		borderBottomWidth: 1,
		zIndex: 100,
	},
});
