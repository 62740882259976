export const modalPaths = {
	AddEmailModal: "add-email",
	AddressesListModal: "addresses",
	AvailabilitiesModal: "availabilities",
	CalendarModal: "calendar-modal",
	CancelOrRescheduleSessionModal: "cancel-reschedule-session-modal",
	ComponentsModal: "components",
	ConfirmationModal: "confirmation",
	DebugModal: "debug",
	EditBillingAddressModal: "edit-billing-address",
	EditCorporationModal: "edit-corporation",
	EditPaymentAuthorityModal: "edit-payment-authority",
	EditPersonModal: "edit-person",
	EditProfileModal: "edit-profile",
	EditSessionCategoryModal: "edit-session-category",
	EditSubjectSuggestionModal: "edit-subject-suggestion",
	FeedbackModal: "feedback",
	FollowUpModal: "follow-up",
	InterpreterModal: "interpreter-modal",
	NotificationsModal: "notifications",
	OverlappingSessionsModal: "unavailability-warning",
	RefuseSessionModal: "refuse-session",
	SalaryModal: "salary",
	SearchInterpretersModal: "search-interpreters",
	SearchSessionsModal: "search-session",
	SelectBillingAddressModal: "select-billing-address",
	SelectDateCalendarModal: "select-date-calendar",
	SelectDurationModal: "select-duration",
	SelectLanguageModal: "select-language",
	SelectMediaDeviceModal: "media-devices",
	SelectMonthModal: "select-month",
	SelectNotificationTargetModal: "select-notification-target",
	SelectOriginModal: "select-origin",
	SelectPaymentAuthorityModal: "select-payment-authority",
	SelectPeopleNumberModal: "select-people-number",
	SelectPersonModal: "select-person",
	SelectPlaceModal: "select-address",
	SelectQualificationModal: "select-qualification",
	SelectSessionCategoryModal: "select-session-category",
	SelectSessionStatusModal: "select-session-status",
	SelectSubjectSuggestionModal: "select-subject-suggestion",
	SelectTransportModal: "select-transport",
	SelectValidationIssueModal: "select-validation-issue",
	SelectValidationTypeModal: "select-validation-type",
	SessionCreationFiltersModal: "session-creation-filters",
	SessionModal: "session-modal/:sessionId",
	SessionValidationModal: "session-validation",
	ShareSessionModal: "share-session",
	SliderModal: "slider",
	SupportModal: "support",
	UnavailabilityModal: "unavailability",
	VideoSessionModal: "connect",
};
