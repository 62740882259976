import React from "react";
import {LocaleContext} from "../../contexts/locale";
import {formatDay, formatShortDate} from "../../utils/date-time/format";
import {isToday} from "../../utils/date-time/helpers";
import {capitalize} from "../../utils/strings";
import {CONTRAST_3} from "../../utils/styles/colors";
import {SectionTitle} from "../list/items/section-title";

interface Props {
	date: Date;
}

export const SectionHeader = ({date}: Props): JSX.Element => {
	// Allows updating formatted dates when locale changes
	React.useContext(LocaleContext);
	return (
		<SectionTitle color={isToday(date) ? CONTRAST_3 : undefined} subtitle={capitalize(formatDay(date))}>
			{formatShortDate(date)}
		</SectionTitle>
	);
};
