import {LinkingOptions} from "@react-navigation/native";
import {
	InterpreterAuthentifiedScreensGroupParamList,
	InterpreterHomeTabNavigatorParamList,
} from "../paramLists/interpreter-param-list";
import {modalPaths} from "./modal-paths";

export const interpreterPaths: {[Key in keyof Omit<InterpreterAuthentifiedScreensGroupParamList, "HomeTabNavigator">]: string} = {
	AvailabilitiesModal: modalPaths.AvailabilitiesModal,
	EditProfileModal: modalPaths.EditProfileModal,
	FollowUpModal: modalPaths.FollowUpModal,
	OverlappingSessionsModal: modalPaths.OverlappingSessionsModal,
	RefuseSessionModal: modalPaths.RefuseSessionModal,
	SalaryModal: modalPaths.SalaryModal,
	SelectMonthModal: modalPaths.SelectMonthModal,
	SelectTransportModal: modalPaths.SelectTransportModal,
	SelectValidationIssueModal: modalPaths.SelectValidationIssueModal,
	SelectValidationTypeModal: modalPaths.SelectValidationTypeModal,
	SessionValidationModal: modalPaths.SessionValidationModal,
	UnavailabilityModal: modalPaths.UnavailabilityModal,
};

export const interpreterHomeTabPaths = {
	HomeTabNavigator: {
		screens: {
			Calendar: "/calendar",
			Inbox: "/inbox",
			InterpreterAccount: "/account",
		},
	},
} satisfies {[Key in keyof Pick<InterpreterAuthentifiedScreensGroupParamList, "HomeTabNavigator">]: LinkingOptions<InterpreterHomeTabNavigatorParamList>["config"]};
