import {TOptions as TranslationOptions} from "i18next";
import * as React from "react";
import {ActivityIndicator, StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {useTranslation} from "../../utils/hooks/use-translation";
import {TranslationKey} from "../../utils/locales/translations";
import {Severity} from "../../utils/logs/logs";
import {SUBTLE_4} from "../../utils/styles/colors";
import {Text} from "../texts/text";

interface Props {
	centered?: boolean;
	headerComponent?: React.ReactElement;
	loading?: boolean;
	message?: {
		options?: TranslationOptions;
		translationKey: TranslationKey;
		type?: Severity;
	};
	style?: StyleProp<ViewStyle>;
}

export const SplashView = ({centered, message, headerComponent, loading, style}: Props): JSX.Element => {
	const {t} = useTranslation();
	return (
		<>
			{headerComponent}
			<View style={[styles.container, style, centered && {alignItems: "center", justifyContent: "center"}]}>
				{loading && <ActivityIndicator color={SUBTLE_4}/>}
				{message && <Text style={styles.message} color={SUBTLE_4}>{t(message.translationKey, message.options) as string}</Text>}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "flex-start",
		padding: DEFAULT_SPACING,
	},
	message: {
		marginTop: SMALL_SPACING,
	},
});
