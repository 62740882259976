import {Account} from "../../../@types/accounts";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {IPaymentAuthorityOptions, PaymentAuthority} from "../../@types/payment-authority";
import {BackendPaymentAuthority} from "../../@types/user";
import {transformPaymentBackToFront} from "../../utils/transform-payment";

export type UserPaymentAuthority = {
	id?: string,
	addressNumber: string,
	paymentOption: string,
};

export type UserPaymentAuthorityAddRequest = {
	POST: {
		body: UserPaymentAuthority,
	},
};

export type UserPaymentAuthorityUpdateRequest = {
	PUT: {
		body: UserPaymentAuthority,
	},
};

export type UserPaymentAuthorityDeleteRequest = {
	DELETE: {
		pathParams: {
			paymentAuthorityId: string,
		},
	},
};
export type UserPaymentAuthoritiesRequests = {
	GET: {},
};

type QueryParams = {
	limit: string,
	offset: string,
	search?: string,
};
export const getPaymentAuthorities = async (_accountId: Account["accountId"], pagination: Pagination, search?: string): Promise<Page<PaymentAuthority>> => {
	const {offset, limit} = pagination;
	const queryParams: QueryParams = {
		limit: limit.toString(),
		offset: (offset + 1).toString(),
	};
	if (search) {
		queryParams.search = search;
	}
	const response = await api(
		"userPaymentAuthorities",
		"GET",
		{
			queryParams,
		},
	) as ApiResponse<{total: number, paymentAuthority: BackendPaymentAuthority}>;
	if (response.isSuccess) {
		const {paymentAuthorityOptions} = response.data.paymentAuthority;
		const paymentAuthority: PaymentAuthority[] =
			paymentAuthorityOptions.map(item => ({id: item.id.toString(), name: item.paymentOption, address: item.addressNumber}));
		return {
			items: paymentAuthority,
			hasPreviousPage: offset > 0,
			hasNextPage: response.data.total > offset,
			totalCount: response.data.total,
		};
	}
	throw new Error(response.message);
};

export const addPaymentAuthority = async (_accountId: Account["accountId"], added: PaymentAuthority): Promise<PaymentAuthority> => {
	const paymentResponse = await api(
		"userPaymentAuthorityAdd",
		"POST",
		{
			body: {
				paymentOption: added.name,
				addressNumber: added.address,
			},
		},
	) as ApiResponse<IPaymentAuthorityOptions>;

	return paymentResponse.isSuccess
		? Promise.resolve(transformPaymentBackToFront(paymentResponse?.data))
		: Promise.reject(new Error(paymentResponse?.message));
};

export const updatePaymentAuthority = async (_accountId: Account["accountId"], updated: PaymentAuthority): Promise<PaymentAuthority> => {
	const response = await api(
		"userPaymentAuthorityUpdate",
		"PUT",
		{
			body: {
				id: updated.id,
				paymentOption: updated.name,
				addressNumber: updated.address,
			},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? updated : Promise.reject(new Error(response.message));
};

export const deletePaymentAuthority = async (_accountId: Account["accountId"], deleted: PaymentAuthority): Promise<void> => {
	const response = await api(
		"userPaymentAuthorityDelete",
		"DELETE",
		{
			pathParams: {
				paymentAuthorityId: deleted.id,
			},
		},
	) as ApiResponse<{}>;
	return response.isSuccess ? Promise.resolve() : Promise.reject(new Error(response.message));
};
