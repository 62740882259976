import {Account} from "../@types/accounts";
import {screens} from "../translations/en-US/screens";
import {compareMonths} from "./date-time/helpers";
import {AsyncStorage} from "./externals/storages/async-storage";
import {Log} from "./logs/logs";

// Between 0 and 1, the chance that the feedback modal appears when calling tryShowingFeedbackModal. 0 = 0%, 1 = 100%
const MODAL_APPEAR_CHANCE = 1;

export type RatingValue = 1 | 2 | 3 | 4 | 5;
export type FeedbackAction = keyof typeof screens.feedback.actions;
// For each account: for each action, a date is stored in string format
type StoredFeedbacks = Record<Account["accountId"], Record<FeedbackAction, string>>;

const getFeedbacks = (): Promise<StoredFeedbacks | null> => AsyncStorage.getItem("Feedbacks")
	.then(value => value ? JSON.parse(value) : null);

const feedbackAvailable = (action: FeedbackAction, accountId: Account["accountId"]): Promise<boolean> => getFeedbacks()
	.then(feedbacks => {
		if (!feedbacks?.[accountId]?.[action]) return true;
		const lastDate = new Date(feedbacks[accountId][action]);
		return !compareMonths(new Date(), lastDate);
	});

export const tryShowingFeedbackModal = (
	action: FeedbackAction,
	accountId: Account["accountId"],
	showModal: () => void,
	onModalNotShown?: () => void,
): void => {
	feedbackAvailable(action, accountId)
		.then(available => {
			if (available && Math.random() < MODAL_APPEAR_CHANCE) {
				showModal();
			} else {
				onModalNotShown?.();
			}
		}).catch(Log.error);
};

export const saveFeedback = (action: FeedbackAction, accountId: Account["accountId"]): Promise<void> =>
	getFeedbacks().then(feedbacks => AsyncStorage.setItem("Feedbacks", JSON.stringify({
		...feedbacks,
		[accountId]: {
			...feedbacks?.[accountId],
			[action]: new Date().toISOString(),
		},
	})));
