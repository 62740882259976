import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {modalScreenOptions} from "../../components/views/modal-wrapper";
import {ENV} from "../../utils/constants";
import {SharedModalsScreensGroupParamList} from "../../utils/navigation/paramLists/shared-screens-param-list";
import {SelectPeopleNumberModal} from "../screens/client/modals/select-people-number";
import {ComponentsModal} from "../screens/common/development/components";
import {DebugModal} from "../screens/common/development/debug";
import {AddEmailModal} from "../screens/common/modals/add-email";
import {CalendarModal} from "../screens/common/modals/calendar";
import {NotificationsModal} from "../screens/common/modals/notifications";
import {SearchSessionsModal} from "../screens/common/modals/search-sessions";
import {SelectDateCalendarModal} from "../screens/common/modals/select-date-calendar";
import {SelectLanguageModal} from "../screens/common/modals/select-language";
import {SelectNotificationTargetModal} from "../screens/common/modals/select-notification-target";
import {SelectOriginModal} from "../screens/common/modals/select-origin";
import {SelectPersonModal} from "../screens/common/modals/select-person";
import {SelectPlaceModal} from "../screens/common/modals/select-place";
import {SelectQualificationModal} from "../screens/common/modals/select-qualifications";
import {SelectSessionStatusModal} from "../screens/common/modals/select-session-status";
import {SessionModal} from "../screens/common/modals/session";
import {ShareSessionModal} from "../screens/common/modals/share-session";
import {SliderModal} from "../screens/common/modals/slider";
import {SupportModal} from "../screens/common/modals/support";

const Stack = createNativeStackNavigator<SharedModalsScreensGroupParamList>();

const DevelopmentScreenGroup = ENV !== "production" && (
	<Stack.Group screenOptions={modalScreenOptions}>
		<Stack.Screen name="ComponentsModal" component={ComponentsModal}/>
		<Stack.Screen name="DebugModal" component={DebugModal}/>
	</Stack.Group>
);

export const SharedScreensGroup = (
	<Stack.Group screenOptions={modalScreenOptions}>
		{/* CAREFUL THE ORDER OF THE SCREENS IMPACTS THE NAVIGATION!!! FIRST SCREEN = DEFAULT SCREEN */}
		<Stack.Screen name="CalendarModal" component={CalendarModal}/>
		<Stack.Screen name="AddEmailModal" component={AddEmailModal}/>
		<Stack.Screen name="NotificationsModal" component={NotificationsModal}/>
		<Stack.Screen name="SearchSessionsModal" component={SearchSessionsModal}/>
		<Stack.Screen name="SelectDateCalendarModal" component={SelectDateCalendarModal}/>
		<Stack.Screen name="SelectLanguageModal" component={SelectLanguageModal}/>
		<Stack.Screen name="SelectNotificationTargetModal" component={SelectNotificationTargetModal}/>
		<Stack.Screen name="SelectOriginModal" component={SelectOriginModal}/>
		<Stack.Screen name="SelectPersonModal" component={SelectPersonModal}/>
		<Stack.Screen name="SelectPlaceModal" component={SelectPlaceModal}/>
		<Stack.Screen name="SelectQualificationModal" component={SelectQualificationModal}/>
		<Stack.Screen name="SelectSessionStatusModal" component={SelectSessionStatusModal}/>
		<Stack.Screen name="SessionModal" component={SessionModal}/>
		<Stack.Screen name="ShareSessionModal" component={ShareSessionModal}/>
		<Stack.Screen name="SliderModal" component={SliderModal} options={{gestureEnabled: false}}/>
		<Stack.Screen name="SupportModal" component={SupportModal}/>
		<Stack.Screen name="SelectPeopleNumberModal" component={SelectPeopleNumberModal}/>
		{DevelopmentScreenGroup}
	</Stack.Group>
);
