import * as React from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {AvailableSessionLanguagesCommunication} from "../../../@types/language-translation";
import {SessionCreationSetting} from "../../../@types/settings";
import {BannerApps} from "../../../components/banner/apps";
import {BannerUpdate} from "../../../components/banner/update";
import {ContextInfo} from "../../../components/labels/context-info";
import {NotificationsPermission} from "../../../components/notifications-permission";
import {Spacer} from "../../../components/spacer";
import {StatusBar} from "../../../components/status-bar";
import {Text} from "../../../components/texts/text";
import {Column} from "../../../components/views/columns/column";
import {Columns} from "../../../components/views/columns/columns";
import {KeyboardAvoidingView} from "../../../components/views/keyboard-avoiding-view";
import {AuthentifiedContext} from "../../../contexts/authentified";
import {ResponsiveContext} from "../../../contexts/responsive";
import {SessionCreationContext} from "../../../contexts/session-creation";
import {CONTAINERS, EXTRA_LARGE_SPACING, SMALL_SPACING} from "../../../utils/constants";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {generateAvailableLanguagesCommunication, getAvailableToLanguages} from "../../../utils/languages";
import {ClientHomeTabNavigatorScreenProps} from "../../../utils/navigation/paramLists/client-param-list";
import {scrollViewDefaultProps} from "../../../utils/scrollables";
import {SUBTLE_2, WHITE} from "../../../utils/styles/colors";
import {DetailsStep} from "./creation/details-step";
import {LanguageStep} from "./creation/language-step";
import {ScheduleStep} from "./creation/schedule-step";
import {SetupStep} from "./creation/setup-step";

const sessionSteps = ["SelectLanguageStep", "SetupStep", "ScheduleStep", "DetailsStep"] as const;
export type NewSessionSteps = typeof sessionSteps[number];

export const NewSession = ({
	navigation,
	route,
	...props
}: ClientHomeTabNavigatorScreenProps<"NewSession">): JSX.Element => {
	const {t} = useTranslation();
	const {columns} = React.useContext(ResponsiveContext);

	const {settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {availableLanguagesCommunication} = {
			availableLanguagesCommunication: {} as AvailableSessionLanguagesCommunication,
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};

	const {
		session,
		reset,
		activeStep,
		setActiveStep,
		setSetupStepValid,
		scheduleStepDisabled,
		detailStepDisabled,
		initialProvider,
	} = React.useContext(SessionCreationContext);
	const {context, immediate, toLanguage, providers, excludedInterpreters} = session;

	const setupStepScrollView = React.useRef<ScrollView>(null);

	const onCreateNewSession = (): void => {
		setupStepScrollView.current?.scrollTo({animated: true, x: 0, y: 0});
		setActiveStep("SelectLanguageStep");
	};

	const onPressReset = React.useCallback(
		() => reset({type: "new"}),
		[reset],
	);

	const activeStepFunctions = React.useMemo(
		// eslint-disable-next-line unicorn/no-array-reduce
		() => sessionSteps.reduce(
			(acc, el) => {
				acc[el] = () => setActiveStep(el);
				return acc;
			},
			{} as Record<NewSessionSteps, () => void>,
		),
		[setActiveStep],
	);

	const setActiveStepFunc = React.useCallback(
		(step: NewSessionSteps) => activeStepFunctions[step],
		[activeStepFunctions],
	);

	React.useEffect(
		() => {
			// Manage specific cases happening when the columns number changes during the session creation
			setActiveStep(prev => {
				// When we go from 1 column to 2 or 3
				if (columns !== 1 && prev === "SetupStep" && !scheduleStepDisabled) {
					return immediate ? "DetailsStep" : "ScheduleStep";
				}
				// When we go from 3 columns to 2 or 1
				if (prev === "ScheduleStep" && immediate) {
					return "DetailsStep";
				}
				return prev === "ScheduleStep" && scheduleStepDisabled ? "SetupStep" : prev;
			});
		},
		[columns, immediate, scheduleStepDisabled, setActiveStep],
	);

	React.useEffect(
		() => {
			// Manage specific cases happening when the columns number changes during the session creation
			setActiveStep(prev => {
				// When we go from 3 columns to 2 or 1
				if (prev === "DetailsStep" && detailStepDisabled) {
					return immediate ? "SetupStep" : "ScheduleStep";
				}
				return prev;
			});
		},
		[columns, detailStepDisabled, immediate, setActiveStep],
	);

	/*
	 * Displays welcome sliders if they are not in AsyncStorage
	 *
	 * React.useEffect(
	 * () => {
	 *  AsyncStorage.getItem("Sliders")
	 *    .then((slidersJSON) => {
	 *      if (IS_WEB) {
	 *        const lastViewedVersion = JSON.parse(slidersJSON)?.welcomeRequester;
	 *        // TODO: this test need to be removed when other languages are ready!
	 *        if ((locale === "fr-FR" || locale === "de-DE") &&
	 *        (!lastViewedVersion || lastViewedVersion < Images.sliders.welcomeRequester.version)) {
	 *          const {screen, params} = getStackPath("Slider", {sliderKey: "welcomeRequester"});
	 *          navigation.navigate(screen, params);
	 *        }
	 *      } else {
	 *        const lastViewedVersion = JSON.parse(slidersJSON)?.welcomeRequesterMobile;
	 *        if (!lastViewedVersion || lastViewedVersion < Images.sliders.welcomeRequesterMobile.version) {
	 *          const {screen, params} = getStackPath("Slider", {sliderKey: "welcomeRequesterMobile"});
	 *          navigation.navigate(screen, params);
	 *        }
	 *      }
	 *    })
	 *   .catch(Log.error());
	 * },
	 * [locale, navigation],
	 * );
	 */

	const selectLanguage = (
		<View style={styles.containers}>
			<ScrollView contentContainerStyle={styles.scrollView}>
				<BannerUpdate backgroundColor={WHITE}/>
				<NotificationsPermission/>
				{context && <ContextInfo
					context={context}
					initialProvider={initialProvider}
					onPressReset={onPressReset}
				/>}
				<View style={activeStep === "SelectLanguageStep" && styles.selectLanguage}>
					<LanguageStep
						navigation={navigation}
						route={route}
						inlineMode={columns >= 2}
						includedLanguages={getAvailableToLanguages(
							initialProvider
								? generateAvailableLanguagesCommunication(initialProvider)
								: availableLanguagesCommunication,
						)}
						onValidate={setActiveStepFunc("SetupStep")}
						languagesLocked={context?.type === "followUp" || context?.type === "reschedule"}
						{...props}
					/>
				</View>
			</ScrollView>
		</View>
	);

	const detailsStep = (
		<DetailsStep
			onError={setActiveStepFunc("SelectLanguageStep")}
			onValidate={onCreateNewSession}
			onBack={setActiveStepFunc(immediate ? "SetupStep" : "ScheduleStep")}
		/>
	);

	const setupStep = (
		<KeyboardAvoidingView style={styles.containers}>
			<ScrollView
				{...scrollViewDefaultProps}
				ref={setupStepScrollView}
				contentContainerStyle={activeStep === "SelectLanguageStep" && styles.containers}
			>
				{columns >= 2 && selectLanguage}
				{!!toLanguage && (
					<View>
						<SetupStep
							inlineMode={columns >= 2}
							onValidate={setActiveStepFunc(immediate ? "DetailsStep" : "ScheduleStep")}
							onValidationChange={setSetupStepValid}
							onBack={setActiveStepFunc("SelectLanguageStep")}
						/>
					</View>
				)}
			</ScrollView>
		</KeyboardAvoidingView>
	);

	const scheduleOrDetailsStep = (
		<>
			{!scheduleStepDisabled && <ScheduleStep
				inlineMode={columns >= 2}
				onError={setActiveStepFunc("SelectLanguageStep")}
				onBack={setActiveStepFunc("SetupStep")}
				onValidate={setActiveStepFunc("DetailsStep")}
			/>}
			{columns === 1 && activeStep === "DetailsStep" && !detailStepDisabled && (
				<View style={[StyleSheet.absoluteFill, styles.forms]}>
					{detailsStep}
				</View>
			)}
			{columns === 2 && activeStep === "DetailsStep" && !detailStepDisabled && (
				<Column style={[StyleSheet.absoluteFill, styles.forms, styles.formsBorder]}>
					{detailsStep}
				</Column>
			)}
		</>
	);

	return (
		<>
			<StatusBar/>
			<BannerApps backgroundColor={columns === 1 ? WHITE : undefined}/>
			{
				columns === 1 && (
					activeStep === "SelectLanguageStep"
						? selectLanguage
						: activeStep === "SetupStep"
							? setupStep
							: (activeStep === "ScheduleStep" || activeStep === "DetailsStep") &&
								scheduleOrDetailsStep
				)
			}
			{columns >= 2 && (
				<Columns>
					<Column style={[styles.forms, styles.formsBorder]}>
						{setupStep}
					</Column>
					<Spacer mode="vertical" size={SMALL_SPACING}/>
					<Column>
						{activeStep === "SetupStep" && scheduleStepDisabled && (
							<View style={[styles.missingMandatoryMessage, CONTAINERS.MAIN, CONTAINERS.LIST]}>
								<Text type="title_2" centered>{t("screens:creation.scheduleOverlay.missingMandatoryFields")}</Text>
							</View>
						)}
						{scheduleOrDetailsStep}
					</Column>
					{columns === 3 && (
						<>
							<Spacer mode="vertical" size={SMALL_SPACING}/>
							<Column style={!detailStepDisabled &&
									(providers.length > 0 || excludedInterpreters.length > 0) &&
									[styles.forms, styles.formsBorder]
							}>
								{!detailStepDisabled && detailsStep}
							</Column>
						</>
					)}
				</Columns>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	containers: {
		flex: 1,
	},
	forms: {
		backgroundColor: WHITE,
		borderColor: SUBTLE_2,
	},
	formsBorder: {
		borderWidth: 1,
	},
	missingMandatoryMessage: {
		alignItems: "center",
		height: "100%",
		justifyContent: "center",
		paddingHorizontal: EXTRA_LARGE_SPACING,
	},
	scrollView: {
		flexGrow: 1,
	},
	selectLanguage: {
		flex: 1,
		justifyContent: "center",
	},
});
