import {Dispatch, SetStateAction} from "react";
import * as React from "react";
import {Falsy, StyleSheet, View} from "react-native";
import {Session} from "../../../../@types/activities/session";
import {
	AbsentReceiverIssue,
	CancellationTimeIssue,
	CommunicationTypeChangedIssue,
	EffectiveEndTimeIssue,
	EffectiveStartTimeIssue,
	FeedbackIssue,
	IncidentIssue,
	Issue,
	Issues,
	IssueType,
	SessionValidation,
	SessionValidationCompleted,
	sessionValidationTypes,
	ValidationType,
} from "../../../../@types/validation";
import {ValidationSettings} from "../../../../@types/validation-settings";
import {confirmation} from "../../../../components/feedbacks/confirmation";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {SessionValidationCard} from "../../../../components/sessions/validation/card";
import {AbsentReceiverSummary} from "../../../../components/sessions/validation/issues-summaries/absent-receiver";
import {CancellationTimeSummary} from "../../../../components/sessions/validation/issues-summaries/cancellation-time";
import {
	CommunicationTypeChangedSummary,
} from "../../../../components/sessions/validation/issues-summaries/communication-type-changed";
import {DescriptionSummary} from "../../../../components/sessions/validation/issues-summaries/description";
import {EffectiveEndTimeSummary} from "../../../../components/sessions/validation/issues-summaries/effective-end-time";
import {
	EffectiveStartTimeSummary,
} from "../../../../components/sessions/validation/issues-summaries/effective-start-time";
import {EmptyIssueSummary} from "../../../../components/sessions/validation/issues-summaries/empty-issue";
import {FeedbackSummary} from "../../../../components/sessions/validation/issues-summaries/feedback";
import {IncidentSummary} from "../../../../components/sessions/validation/issues-summaries/incident";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {getSessionDetails} from "../../../../requests/common/activities/details";
import {
	getSessionValidationSettings,
	updateSessionValidation,
	updateSessionValidationPicture,
} from "../../../../requests/interpreters/activities/actions/validate";
import {filterTruthy} from "../../../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING} from "../../../../utils/constants";
import {useExcludedValidationType} from "../../../../utils/hooks/use-excluded-validation-type";
import {useForm} from "../../../../utils/hooks/use-form";
import {useToccoPeopleNumberInput} from "../../../../utils/hooks/use-tocco-people-number-input";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {PickerImage} from "../../../../utils/inputs/pickers/image-picker";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {Log} from "../../../../utils/logs/logs";
import {InterpreterStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/interpreter-param-list";
import {
	getDefaultCanceledStatusIssues,
	getDefaultCompletedStatusIssues,
	getIssueTypeIcon,
	validateSession,
} from "../../../../utils/sessions/validation";
import {GREEN, PRIMARY} from "../../../../utils/styles/colors";
import {ps, s} from "../../../../utils/switch";
import {forceBack} from "../../../navigation";

const issuesOrder: IssueType[] = [
	"absentReceiver",
	"effectiveStartTime",
	"effectiveEndTime",
	"cancellationTime",
	"communicationTypeChanged",
	"feedback",
	"incident",
];

const SessionValidationScreen = ({
	route,
	navigation,
}: InterpreterStackNavigatorScreenProps<"SessionValidationModal">): JSX.Element => {
	const {sessionId, validationCallback} = route.params;
	const {ct, t} = useTranslation();
	const [preview, setPreview] = React.useState(false);
	const sessionRef = React.useRef<Session>();
	const [validatedSession, setValidatedSession] = React.useState<Session | null>(null);
	const scrollRef = React.useRef<ScrollView>(null);
	const [
		{
			disableAbsentReceiver,
			disableCommunicationTypeChanged,
			disableStartTime,
			feedback: feedbackSetting,
		}, setValidationSettings,
	] = React.useState<ValidationSettings>({
		disableAbsentReceiver: false,
		disableCommunicationTypeChanged: false,
		disableStartTime: false,
		feedback: undefined,
	});

	const getBase = React.useCallback(
		() => Promise.all([getSessionValidationSettings(sessionId), getSessionDetails(sessionId)])
			.then(([validationSettingsResult, sd]) => {
				setValidationSettings(validationSettingsResult);
				sessionRef.current = sd;
				return ({
					issues: new Date() > sd.start
						? getDefaultCompletedStatusIssues(sd.start, validationSettingsResult?.feedback)
						: getDefaultCanceledStatusIssues(),
					selectedValidation: {type: "none"},
					sessionId,
					status: new Date() > sd.start ? "completed" : "canceled",
					toccoPeopleNumber: sd.toccoPeopleNumber,
				}) as SessionValidation;
			}),
		[sessionId],
	);
	const {
		loading: loadingForm,
		errorMessage,
		isUpdated,
		setUpdated,
		displayed,
	} = useForm(getBase, "getSessionDetailsFailed");
	const {issues, status} = displayed;
	const {
		errorMessage: errorMessageEVT,
		excludedValidationTypes,
		loading: loadingEVT,
		onChangeValidationType,
		selectedValidation,
		setSelectedValidation,
	} = useExcludedValidationType(sessionId, (validatedSession ?? sessionRef.current)?.communication.type);

	React.useEffect(
		() => sessionRef.current && setValidatedSession(validateSession(sessionRef.current, displayed)),
		[displayed],
	);

	React.useEffect(
		() => {
			if ((issues as Issues)?.absentReceiver === null && sessionRef.current?.type === "interpreterMandate") {
				// absentReceiver checkbox has been checked. We need to auto-fill the absentReceiverIssue.
				setUpdated(prev => ({
					...prev,
					issues: {
						absentReceiver: {
							person: sessionRef.current!.receiver!,
							type: "absentReceiver",
						},
						...getDefaultCompletedStatusIssues(sessionRef.current!.start, feedbackSetting),
						effectiveEndTime: {
							effectiveEndTime: sessionRef.current!.end,
							type: "effectiveEndTime",
						},
						other: prev.issues.other,
					},
					status: "completed",
				}));
			}
		},
		[feedbackSetting, issues, setUpdated],
	);
	const EmptyIssueSummaryComp = React.useCallback(
		() => EmptyIssueSummary(ct),
		[ct],
	);

	const header = (
		<HeaderMenu
			center={t("activities:sessions.validate.validation")}
			right={{help: false}}
			exitConfirmation={isUpdated}
		/>
	);

	const usingTocco = status === "completed" && sessionRef.current?.toccoPeopleNumber !== undefined;
	const toccoPeopleNumberInput = useToccoPeopleNumberInput(
		usingTocco,
		sessionRef.current?.communication.type,
		setUpdated as Dispatch<SetStateAction<SessionValidationCompleted>>,
		displayed as SessionValidationCompleted,
		preview,
	);

	if (loadingEVT || loadingForm || errorMessage || errorMessageEVT) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingEVT || loadingForm}
				style={styles.loadingContainer}
				message={errorMessage
					? {translationKey: errorMessage, type: "error"}
					: errorMessageEVT
						? {translationKey: errorMessageEVT, type: "error"}
						: undefined
				}
			/>
		);
	}

	const sendValidation = (): Promise<(() => void) | void> => updateSessionValidation(
		{...displayed, selectedValidation: selectedValidation as ValidationType})
		.then(() => {
			Log.success("updateSessionValidationSuccess")();
			// callback to update the session information (status, start, end and type)
			validationCallback();
			navigation.dispatch(forceBack);
		})
		.catch((error: Error) => ps(error.message, {
			default: () => Log.error("updateSessionValidationFailed")(error),
			sessionAlreadyValidated: () => Log.error("sessionAlreadyValidated", false)(error),
		}));
	const deleteIssue = (issueType: IssueType): void => {
		setUpdated(prev => {
			const is: Issues = {...(prev.issues ?? issues)};
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete is[issueType];
			return {...prev, issues: is};
		});
	};
	const updateIssue = (issue: Issue): void => {
		setUpdated(prev => {
			const updatedIssues: Issues = {...prev.issues ?? issues, [issue.type]: issue};
			if (issue.type === "effectiveStartTime" && updatedIssues.effectiveEndTime) {
				updatedIssues.effectiveEndTime.effectiveStartTime = issue.effectiveStartTime;
			} else if (issue.type === "effectiveEndTime" && updatedIssues.effectiveStartTime) {
				updatedIssues.effectiveStartTime.effectiveEndTime = issue.effectiveEndTime;
			}
			return {...prev, issues: updatedIssues};
		});
	};
	const IssueSummary = (i: Issue): Falsy | JSX.Element => sessionRef.current && s(i.type, {
		absentReceiver: <AbsentReceiverSummary issue={i as AbsentReceiverIssue} t={t} sessionId={sessionId}/>,
		cancellationTime: <CancellationTimeSummary issue={i as CancellationTimeIssue} t={t}/>,
		communicationTypeChanged:
			<CommunicationTypeChangedSummary
				issue={i as CommunicationTypeChangedIssue}
				originalCommunicationType={sessionRef.current.communication.type}
				ct={ct}
				t={t}
			/>,
		effectiveEndTime: <EffectiveEndTimeSummary issue={i as EffectiveEndTimeIssue} t={t}/>,
		effectiveStartTime: <EffectiveStartTimeSummary issue={i as EffectiveStartTimeIssue} t={t}/>,
		feedback: <FeedbackSummary issue={i as FeedbackIssue} t={t}/>,
		incident: <IncidentSummary issue={i as IncidentIssue} t={t}/>,
		other: !!i.description && <View><DescriptionSummary description={i.description} t={t}/></View>,
	});
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const defaultIssue = (issueType: IssueType) => ps(issueType, {
		cancellationTime: {cancellationTime: new Date(), type: issueType},
		default: {type: issueType},
		effectiveEndTime: {
			effectiveEndTime: ((issues as Issues).effectiveEndTime?.effectiveEndTime ?? sessionRef.current?.end) ??
				new Date(),
			effectiveStartTime: (issues as Issues).effectiveStartTime?.effectiveStartTime ?? sessionRef.current?.start,
			type: issueType,
		} as EffectiveEndTimeIssue,
		feedback: {feedback: feedbackSetting?.options?.[0], type: issueType},
	});

	const inputs: FormProps["inputs"] = [];
	issuesOrder.forEach(issueType => {
		/*
		 * We cast issues as Issues here since issues is an union of subsets of Issues properties and we just want to handle
		 * all possible cases independently of the subset.
		 */
		const issue = (issues as Issues)[issueType];
		// If it's undefined, input should not appear. If it's null, then the field will appear but empty.
		if (issue === undefined) {
			return;
		}
		inputs.push({
			disabled:
				(!!(issues as Issues).absentReceiver &&
					(issueType === "effectiveEndTime" || issueType === "effectiveStartTime")) ||
				(issueType === "effectiveStartTime" && disableStartTime),
			icon: getIssueTypeIcon(issueType),
			label: issueType === "feedback" && !!feedbackSetting?.label
				? feedbackSetting.label
				: t(`activities:sessions.validate.issue.type.${issueType}.label`),
			preview,
			requiredLabel: true,
			resetable: issueType !== "effectiveEndTime" && issueType !== "effectiveStartTime" && issueType !== "feedback",
			rules: (issueType !== "feedback" || feedbackSetting?.mandatory) && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (value: Issue | null) => value
					? updateIssue(value)
					: deleteIssue(issueType),
				params: {
					excludedCommunicationType: sessionRef.current!.communication.type,
					feedbackSetting,
					issue: issue ?? defaultIssue(issueType),
					sessionId: sessionRef.current!.activityId,
					sessionValidationStatus: status,
					start: sessionRef.current!.start.toISOString(),
				},
				props: {style: styles.issues},
				renderValue: issue ? IssueSummary : EmptyIssueSummaryComp,
				screen: "SelectValidationIssueModal",
				value: issue,
			},
		});
	});

	if (status === "completed" && (preview ? !!issues.other?.description : true)) {
		inputs.push({
			icon: getIssueTypeIcon("other"),
			label: t("activities:sessions.validate.issue.type.other.label"),
			preview,
			requiredLabel: true,
			type: {
				key: "screen",
				onChangeValue: (value: Issue | null) => value
					? updateIssue(value)
					: deleteIssue("other"),
				params: {
					excludedCommunicationType: sessionRef.current!.communication.type,
					issue: issues.other ?? defaultIssue("other"),
					sessionId: sessionRef.current!.activityId,
					sessionValidationStatus: status,
					start: sessionRef.current!.start.toISOString(),
				},
				props: {style: styles.issues},
				renderValue: issues.other ? IssueSummary : EmptyIssueSummaryComp,
				screen: "SelectValidationIssueModal",
				value: issues.other,
			},
		});
	}

	/*
	 * Shown only if session status is "completed" and there are multiple validationTypes.
	 * The interpreter can choose which one he wants to use
	 */
	if (status === "completed" && excludedValidationTypes.length < sessionValidationTypes.length - 1) {
		inputs.push(
			"spacer",
			{
				icon: "check",
				label: t("activities:sessions.validate.type.label"),
				preview,
				requiredLabel: true,
				rules: [{rule: Rules.notEmpty, type: "error"}],
				type: {
					key: "screen",
					onChangeValue: onChangeValidationType,
					params: {
						excludedValidationTypes: filterTruthy([
							...excludedValidationTypes,
							(validatedSession ?? sessionRef.current)!.communication?.type !== "inPerson" && "picture",
						]),
					},
					renderValue: (value: ValidationType | null) => value && t(`activities:sessions.validate.type.${value.type}`),
					screen: "SelectValidationTypeModal",
					value: selectedValidation,
				},
			},
		);
	}

	// Add number of people for tocco sessions
	if (usingTocco) {
		inputs.push(toccoPeopleNumberInput);
	}

	// Selected Validation Inputs
	if (status === "completed" && selectedValidation?.type === "picture") {
		inputs.push({
			icon: "picture",
			label: t("activities:sessions.validate.type.picture"),
			preview,
			requiredLabel: true,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "image",
				onChangeValue: (picture: PickerImage) => setSelectedValidation({...selectedValidation, picture}),
				options: {cropping: false, from: "both"},
				value: selectedValidation.picture ?? undefined,
			},
		});
	}

	return (
		<>
			{header}
			<Form
				hideReset
				scrollRef={scrollRef}
				headerComponent={(
					<View style={[CONTAINERS.MAIN, styles.cardContainer]}>
						<SessionValidationCard
							id={sessionId}
							start={(validatedSession ?? sessionRef.current!).start}
							end={(validatedSession ?? sessionRef.current!).end}
							immediate={(validatedSession ?? sessionRef.current!).immediate}
							disabled={preview}
							disableAbsentReceiver={disableAbsentReceiver ?? !sessionRef.current!.receiver}
							disableCommunicationTypeChanged={disableCommunicationTypeChanged}
							sessionValidation={displayed}
							setSessionValidation={setUpdated}
							cancelable={(validatedSession ?? sessionRef.current!).cancelable}
							feedbackSetting={feedbackSetting}
						/>
					</View>
				)}
				validation={{
					buttonProps: {
						backgroundColor: preview ? GREEN : PRIMARY,
						icon: "check",
						text: ct(`common:${preview ? "validate" : "preview"}`),
					},
					onValidation: preview
						? () => confirmation({
							actions: [
								{
									backgroundColor: GREEN,
									icon: "check",
									isPromise: true,
									key: "validate",
									onPress: () => (
										selectedValidation?.type === "picture" && status !== "canceled" && selectedValidation.picture
											? updateSessionValidationPicture(
												sessionId,
												selectedValidation.picture,
											).then(sendValidation)
												.catch(Log.error("uploadSessionValidationPictureFailed"))
											: sendValidation()
									),
									text: ct("common:validate"),
								}, {
									icon: "close",
									key: "closeDrawer",
									onPress: () => null,
									text: ct("common:cancel"),
									type: "secondary",
								},
							],
							content: t("activities:sessions.validate.agreement"),
						})
						: () => {
							setPreview(true);
							scrollRef.current?.scrollTo({animated: true, x: 0, y: 0});
						},
					secondaryButtonProps: preview
						? {
							icon: "close",
							onPress: () => setPreview(false),
							text: ct("common:cancel"),
						}
						: undefined,
				}}
				inputs={inputs.filter(i => !preview || i !== "spacer")}
			/>
		</>
	);
};

export const SessionValidationModal = (props: InterpreterStackNavigatorScreenProps<"SessionValidationModal">): JSX.Element => (
	<ModalWrapper>
		<SessionValidationScreen {...props} />
	</ModalWrapper>
);

const styles = StyleSheet.create({
	cardContainer: {
		marginBottom: DEFAULT_SPACING,
		paddingHorizontal: DEFAULT_SPACING,
	},
	issues: {
		flexDirection: "column",
	},
	loadingContainer: {
		flexBasis: 434, // Content height estimation
	},
});
