import {Session} from "../../../@types/activities/session";
import {PersonPreview} from "../../../@types/identities/person";
import {Page, Pagination} from "../../../@types/pagination";
import {api} from "../../../utils/network/api";
import {transformGenderBackToFront} from "../../utils/transform-common";

export type InterpreterSessionPersonsRequests = {
	GET: {
		pathParams: {
			sessionId: string,
		},
		queryParams: {
			searchText?: string,
			limit?: string,
			offset?: string,
		},
	},
};

export const getSessionPeople = async (sessionId: Session["activityId"], pagination: Pagination): Promise<Page<PersonPreview>> => {
	const {limit, offset} = pagination;
	const backendOffset = offset === 0 ? 1 : offset / limit;
	const interpreterResponse = await api(
		"interpreterSessionPersons",
		"GET",
		{
			pathParams: {
				sessionId,
			},
			queryParams: {
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	);
	const persons: PersonPreview[] = [];
	Object.keys(interpreterResponse.data).forEach((key) => {
		interpreterResponse.data[key].gender = transformGenderBackToFront(interpreterResponse.data[key].gender);
		switch (key) {
			case "owner": {
				interpreterResponse.data[key].role = "requester";
				persons.push(interpreterResponse.data[key]);
				break;
			}
			case "patient": {
				interpreterResponse.data[key].role = "receiver";
				persons.push(interpreterResponse.data[key]);
				break;
			}
			case "user": {
				interpreterResponse.data[key].role = "medicalProfessional";
				persons.push(interpreterResponse.data[key]);
				break;
			}
			case "userAccount": {
				interpreterResponse.data[key].role = "user";
				persons.push(interpreterResponse.data[key]);
				break;
			}
			default: {
				interpreterResponse.data[key].role = "interpreter";
				persons.push(interpreterResponse.data[key]);
			}
		}
	});
	if (interpreterResponse.isSuccess) {
		const {count} = interpreterResponse.data;
		return {
			items: persons,
			hasPreviousPage: offset > 0,
			hasNextPage: count > offset,
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};

export const searchSessionPeople = async (
	search: string, sessionId: Session["activityId"], pagination: Pagination,
): Promise<Page<PersonPreview>> => {
	const {limit, offset} = pagination;
	const backendOffset = offset === 0 ? 1 : offset / limit;
	const interpreterResponse = await api(
		"interpreterSessionPersons",
		"GET",
		{
			pathParams: {
				sessionId,
			},
			queryParams: {
				searchText: search,
				limit: limit.toString(),
				offset: backendOffset.toString(),
			},
		},
	);
	const persons: PersonPreview[] = [];
	Object.keys(interpreterResponse.data).forEach((key) => {
		const isEmpty = Object.keys(interpreterResponse.data[key]).length === 0;
		if (key === "owner" && !isEmpty) {
			interpreterResponse.data[key].role = "requester";
			persons.push(interpreterResponse.data[key]);
		} else if (key === "patient" && !isEmpty) {
			interpreterResponse.data[key].role = "receiver";
			persons.push(interpreterResponse.data[key]);
		} else if (key === "user" && !isEmpty) {
			interpreterResponse.data[key].role = "medicalProfessional";
			persons.push(interpreterResponse.data[key]);
		} else if (key === "userAccount" && !isEmpty) {
			interpreterResponse.data[key].role = "user";
			persons.push(interpreterResponse.data[key]);
		} else if (key === "interpreter" && !isEmpty) {
			interpreterResponse.data[key].role = "interpreter";
			persons.push(interpreterResponse.data[key]);
		}
	});

	if (interpreterResponse.isSuccess) {
		const {count} = interpreterResponse.data;
		return {
			items: persons,
			hasPreviousPage: offset > 0,
			hasNextPage: count > offset,
			totalCount: count,
		};
	}
	throw new Error(interpreterResponse.message);
};
