import {NavigationAction, useNavigation, useRoute} from "@react-navigation/native";
import * as React from "react";
import {Falsy} from "react-native";
import {ResponsiveContext} from "../../contexts/responsive";
import {IS_WEB} from "../../utils/constants";
import {useBeforeRemove} from "../../utils/hooks/use-before-remove";
import {useTranslation} from "../../utils/hooks/use-translation";
import {confirmation} from "../feedbacks/confirmation";
import {Icon} from "../icons";
import {Text} from "../texts/text";
import {HeaderLayout, Props as HeaderLayoutProps} from "./header-layout";

interface Props extends Pick<HeaderLayoutProps, "backgroundColor" | "equalColumns"> {
	center?: string;
	centerComponent?: React.ReactElement;
	exitConfirmation?: boolean;
	left?: React.ReactElement | null;
	onExit?: () => void;
	right?: {beforeHelp?: Falsy | React.ReactElement; help?: boolean};
}

export const HeaderMenu = ({
	center, centerComponent, left, right, backgroundColor, equalColumns, exitConfirmation, onExit,
}: Props): JSX.Element | null => {
	const {ct, t} = useTranslation();
	const navigation = useNavigation();
	const route = useRoute();
	const {columns} = React.useContext(ResponsiveContext);
	const inModal = route.name.endsWith("Modal");
	const inNewSessionScreen = route.name.endsWith("NewSession");
	const openConfirmationDrawer = React.useCallback(
		(action: NavigationAction) => confirmation({
			actions: [
				{
					icon: "close",
					key: "closeDrawer",
					onPress: () => null,
					text: ct("common:cancel"),
					type: "secondary",
				}, {
					icon: "check",
					key: "exitAnyway",
					onPress: () => {
						onExit?.();
						navigation.dispatch(action);
					},
					text: ct("common:exitAnyway"),
				},
			],
			actionsLayout: "row",
			content: t("commonSentences:closeWithoutSaveWarning"),
		}),
		[ct, t, onExit, navigation],
	);
	useBeforeRemove(openConfirmationDrawer, exitConfirmation);
	const back = (): void => navigation.goBack();
	React.useEffect(
		() => {
			if (exitConfirmation) {
				navigation.setOptions({gestureEnabled: false});
			}
		},
		[exitConfirmation, navigation],
	);
	if ((!inModal && columns > 1) || (!inModal && IS_WEB && !inNewSessionScreen) ||
		(inNewSessionScreen && columns !== 1)) {
		return null;
	}
	return (
		<HeaderLayout
			backgroundColor={backgroundColor}
			equalColumns={equalColumns}
			left={left || (
				left === undefined
					? (inModal
						? <Icon icon="close" onPress={back}/>
						: <Icon icon="back" onPress={back}/>)
					: <Icon icon="placeholder"/>
			)}
			center={
				<>
					{!!center && <Text type="emphasis_1" numberOfLines={1}>{center}</Text>}
					{!!centerComponent && centerComponent}
				</>
			}
			right={
				<>
					{!!right?.beforeHelp && right.beforeHelp}
					{right?.help
						? <Icon icon="help"/>
						: (right?.beforeHelp ? null : <Icon icon="placeholder"/>)
					}
				</>
			}
		/>
	);
};
