import * as React from "react";
import {SessionCategory} from "../../../../@types/activities/session-category";
import {SessionCreationSetting} from "../../../../@types/settings";
import {ListElement} from "../../../../components/list/items/list-element";
import {FlatListRenderItemParams, SelectFlatList} from "../../../../components/list/select-flatlist";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {
	addSessionCategory,
	deleteSessionCategory,
	getSessionCategories,
	updateSessionCategory,
} from "../../../../requests/clients/activities/categories";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {forceBack} from "../../../navigation";

const SelectSessionCategory = ({
	navigation,
	route,
}: ClientStackNavigatorScreenProps<"SelectSessionCategoryModal">): JSX.Element => {
	const {onSelect} = route.params ?? {};
	const {ct} = useTranslation();
	const {accountId, settings: {getSetting}} = React.useContext(AuthentifiedContext);
	const {
		value: {detailsStep: {sessionCategory}},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {value: {detailsStep: {}}};

	const onPress = React.useCallback(
		(item: SessionCategory) => () => {
			onSelect?.(item);
			navigation.dispatch(forceBack);
		},
		[navigation, onSelect],
	);

	const request = React.useCallback(
		(item: SessionCategory) => (
			addSessionCategory(accountId!, item)
				.then((added) => {
					if (onSelect) {
						onPress(added)();
					}
					return added;
				})
		),
		[accountId, onPress, onSelect],
	);

	const renderItem = ({info: {item}, buttons}: FlatListRenderItemParams<"id", SessionCategory>): JSX.Element => (
		<ListElement
			key={item.id}
			onPress={onSelect && onPress(item)}
			buttons={buttons}
		>
			{item.name}
		</ListElement>
	);

	return (
		<>
			<HeaderMenu
				center={ct("forms:items.select", {item: sessionCategory?.label ?? ct("common:sessionCategory")})}
			/>
			<SelectFlatList
				getRequest={(pagination) => getSessionCategories(accountId!, pagination)}
				onSearch={{request: (pagination, search) => getSessionCategories(accountId!, pagination, search)}}
				deleteRequest={!onSelect && sessionCategory?.editRights?.canDelete
					? (removed: SessionCategory) => deleteSessionCategory(accountId!, removed)
					: undefined
				}
				onPressAdd={sessionCategory?.editRights?.canAddNew
					? {
						request,
						screen: {
							name: "EditSessionCategoryModal",
						},
					}
					: undefined}
				onPressEdit={!onSelect && sessionCategory?.editRights?.canEdit
					? {
						request: (updated: SessionCategory) => updateSessionCategory(accountId!, updated),
						screen: {
							name: "EditSessionCategoryModal",
						},
					}
					: undefined}
				itemTranslationKey="common:sessionCategory"
				renderItem={renderItem}
				idKey="id"
			/>
		</>
	);
};

export const SelectSessionCategoryModal = (props: ClientStackNavigatorScreenProps<"SelectSessionCategoryModal">): JSX.Element => (
	<ModalWrapper fullHeight>
		<SelectSessionCategory {...props} />
	</ModalWrapper>
);
