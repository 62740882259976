import {ActivitiesFilters} from "../../../@types/activities/filters";
import {ActivitiesStructure} from "../../../utils/date-time/activities";
import {nDaysInMonth} from "../../../utils/date-time/helpers";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {transformCommunicationTypeFrontToBack, transformLanguageISOCodeFrontToBack} from "../../utils/transform";

export type InterpreterFilteredActivitiesStructureRequests = {
	GET: {
		pathParams: {
			startDate: string,
			endDate: string,
		},
		queryParams: {
			timeZoneOffset: string,
			sessions?: string,
			toLanguage?: string,
			requester?: string,
			status?: string,
			type?: string,
			showCanceledSessions?: string,
		},
	},
};

export const getInterpreterActivitiesStructure = async (
	from: Date,
	to: Date,
	filters: ActivitiesFilters,
): Promise<ActivitiesStructure> => {
	from.setDate(1);
	const fromTime = new Date(from.getFullYear(), from.getMonth(), from.getDate());
	const toTime = new Date(to.getFullYear(), to.getMonth(), nDaysInMonth(to), 23, 59, 59);
	const {id, toLanguage, communicationTypes, requester, showCanceledSessions, showUnavailabilities} = filters;
	let status = "CONFIRMED,MISSED,COMPLETED,INPROGRESS,STOPPED";
	if (showCanceledSessions) {
		status += ",CANCELLED,RESCHEDULED";
	}
	const requestData = {
		sessions: id || null,
		toLanguage: toLanguage ? transformLanguageISOCodeFrontToBack(toLanguage) : null,
		status,
		type: communicationTypes && communicationTypes.length > 0
			? transformCommunicationTypeFrontToBack(communicationTypes).toString()
			: "",
		requester: requester ? `${requester.fullName.firstName} ${requester.fullName.lastName}` : null,
		timeZoneOffset: new Date().getTimezoneOffset().toString(),
		showUnavailabilities: showUnavailabilities ? "true" : "false",
	};
	// from.getTimezoneOffset().toString()
	const interpreterResponse = await api(
		"interpreterFilteredActivitiesStructure",
		"GET",
		{
			pathParams: {
				startDate: fromTime.getTime().toString(),
				endDate: toTime.getTime().toString(),
			},
			queryParams: requestData,
		},
	) as ApiResponse<ActivitiesStructure>;
	return interpreterResponse.isSuccess
		? interpreterResponse.data
		: Promise.reject(new Error(interpreterResponse.message));
};
