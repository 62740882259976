import avatarBlue from "../../../assets/images/avatar/default-avatar-blue.png";
import avatarOrange from "../../../assets/images/avatar/default-avatar-orange.png";
import avatar from "../../../assets/images/avatar/default-avatar.png";
import appStoreDE from "../../../assets/images/download/de-DE/appStore.png";
import googlePlayDE from "../../../assets/images/download/de-DE/googlePlay.png";
import appStoreEN from "../../../assets/images/download/en-US/appStore.png";
import googlePlayEN from "../../../assets/images/download/en-US/googlePlay.png";
import appStoreFR from "../../../assets/images/download/fr-FR/appStore.png";
import googlePlayFR from "../../../assets/images/download/fr-FR/googlePlay.png";
import appStoreIT from "../../../assets/images/download/it-IT/appStore.png";
import googlePlayIT from "../../../assets/images/download/it-IT/googlePlay.png";
import logo from "../../../assets/images/logo/logo.png";
import {Require, URLImage} from "../../@types/medias/image";
import {SlidersInfos} from "../../navigation/screens/common/modals/slider";
import {IS_INTERPRETER} from "../constants";
import {SupportedLocaleKey} from "../locales/locales";

const sliderImage: Pick<URLImage, "mediaType" | "mime" | "sourceType"> = {
	mediaType: "image",
	mime: "image/png",
	sourceType: "url",
};

const checks: URLImage = {
	...sliderImage,
	height: 767,
	source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/checks.png",
	width: 750,
};
const dots: URLImage = {
	...sliderImage,
	height: 750,
	source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/dots.png",
	width: 750,
};

export class Images {
	public static Logo: Require = logo;
	public static Avatar: Require = avatar;
	public static AvatarTouchable: Require = IS_INTERPRETER
		? avatarBlue
		: avatarOrange;

	public static Download = {
		"de-DE": {
			appStore: appStoreDE,
			playStore: googlePlayDE,
		},
		"en-US": {
			appStore: appStoreEN,
			playStore: googlePlayEN,
		},
		"fr-FR": {
			appStore: appStoreFR,
			playStore: googlePlayFR,
		},
		"it-IT": {
			appStore: appStoreIT,
			playStore: googlePlayIT,
		},
	} satisfies {
		[locale in SupportedLocaleKey]: {
			appStore: Require;
			playStore: Require;
		}
	};

	public static sliders = {
		welcome: {
			"de-DE": {
				calendar: {
					...sliderImage,
					height: 1481,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/calendar-de.png",
					width: 750,
				},
				checks,
				dots,
				inbox: {
					...sliderImage,
					height: 1481,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/inbox-de.png",
					width: 750,
				},
				incomes: {
					...sliderImage,
					height: 1481,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/income-de.png",
					width: 750,
				},
				notifications: {
					...sliderImage,
					height: 1481,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/notification-de.png",
					width: 750,
				},
				validation: {
					...sliderImage,
					height: 1481,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcome/validation-de.png",
					width: 750,
				},
			},
			version: 1,
		},
		welcomeRequester: {
			"de-DE": {
				account: {
					...sliderImage,
					height: 1683,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/6-account.png",
					width: 2651,
				},
				calendar: {
					...sliderImage,
					height: 2037,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/4-calendar.png",
					width: 2472,
				},
				checks,
				communication: {
					...sliderImage,
					height: 1122,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/5-communication.png",
					width: 1846,
				},
				languages: {
					...sliderImage,
					height: 1830,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/2-languages.png",
					width: 1743,
				},
				support: {
					...sliderImage,
					height: 1830,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/7-support.png",
					width: 1773,
				},
				timeslots: {
					...sliderImage,
					height: 1797,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/3-timeslots.png",
					width: 2979,
				},
				welcome: {
					...sliderImage,
					height: 1215,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/de/1-welcome.png",
					width: 1215,
				},
			},
			"fr-FR": {
				account: {
					...sliderImage,
					height: 1122,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/6-account.png",
					width: 1768,
				},
				calendar: {
					...sliderImage,
					height: 1336,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/4-calendar.png",
					width: 1614,
				},
				checks,
				communication: {
					...sliderImage,
					height: 1122,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/5-communication.png",
					width: 1846,
				},
				languages: {
					...sliderImage,
					height: 1214,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/2-languages.png",
					width: 1162,
				},
				support: {
					...sliderImage,
					height: 1098,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/7-support.png",
					width: 1002,
				},
				timeslots: {
					...sliderImage,
					height: 1194,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/3-timeslots.png",
					width: 1974,
				},
				welcome: {
					...sliderImage,
					height: 1215,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequester/fr/1-welcome.png",
					width: 1215,
				},
			},
			version: 1,
		},
		welcomeRequesterMobile: {
			"de-DE": {
				calendar: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/de/3-calendar.png",
					width: 431,
				},
				checks,
				communication: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/5-communication.png",
					width: 431,
				},
				home: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/de/2-home.png",
					width: 431,
				},
				session: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/de/4-session.png",
					width: 431,
				},
				welcome: {
					...sliderImage,
					height: 440,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/1-welcome.png",
					width: 440,
				},
			},
			"en-US": {
				calendar: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/en/3-calendar.png",
					width: 431,
				},
				checks,
				communication: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/5-communication.png",
					width: 431,
				},
				home: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/en/2-home.png",
					width: 431,
				},
				session: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/en/4-session.png",
					width: 431,
				},
				welcome: {
					...sliderImage,
					height: 440,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/1-welcome.png",
					width: 440,
				},
			},
			"fr-FR": {
				calendar: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/fr/3-calendar.png",
					width: 431,
				},
				checks,
				communication: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/5-communication.png",
					width: 431,
				},
				home: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/fr/2-home.png",
					width: 431,
				},
				session: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/fr/4-session.png",
					width: 431,
				},
				welcome: {
					...sliderImage,
					height: 440,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/1-welcome.png",
					width: 440,
				},
			},
			"it-IT": {
				calendar: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/it/3-calendar.png",
					width: 431,
				},
				checks,
				communication: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/5-communication.png",
					width: 431,
				},
				home: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/it/2-home.png",
					width: 431,
				},
				session: {
					...sliderImage,
					height: 856,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/it/4-session.png",
					width: 431,
				},
				welcome: {
					...sliderImage,
					height: 440,
					source: "https://storage.googleapis.com/bhaasha_frontend_assets/sliders/welcomeRequesterMobile/1-welcome.png",
					width: 440,
				},
			},
			version: 1,
		},
	} satisfies {
		[sliderKey in keyof SlidersInfos]: {
			[locale in SupportedLocaleKey]?: {
				[image in SlidersInfos[sliderKey]]: URLImage
			}
		} & {version: number}
	};
}
