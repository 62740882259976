import * as React from "react";
import {StyleSheet} from "react-native";
import Collapsible from "react-native-collapsible";
import {LocaleContext} from "../../../contexts/locale";
import {DEFAULT_SPACING, IS_IOS, IS_MOBILE, IS_WEB, SMALL_SPACING} from "../../../utils/constants";
import {formatDate, formatDateTime, formatDuration, formatTime} from "../../../utils/date-time/format";
import {roundTime} from "../../../utils/date-time/helpers";
import {Locales} from "../../../utils/locales/locales";
import {PRIMARY, PRIMARY_2, SUBTLE, SUBTLE_4, WHITE} from "../../../utils/styles/colors";
import {DatePicker} from "../../date-picker/date-picker";
import {DatePickerProps} from "../../date-picker/date-picker.common";
import {Icon} from "../../icons";
import {Text} from "../../texts/text";
import {Pressable} from "../../views/pressable";

export type DateInputKey = "date" | "datetime" | "duration" | "time";

export interface DateListProps {
	key: DateInputKey;
	onChangeValue?: (date: Date) => void;
	props?: Omit<DatePickerProps, "onDateChange">;
	value?: Date;
}

type DateInputProps = Omit<DateListProps, "key"> & {
	collapsed?: boolean;
	disabled?: boolean;
	// we have to deactivate this rule because of a false positive: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#false-positives-sfc
	// eslint-disable-next-line react/no-unused-prop-types
	toggleCollapse?: () => void;
	type: DateListProps["key"];
};

const DatePickerComp = ({value, onChangeValue, type, backgroundColor, props}: Omit<DateInputProps, "toggleCollapse"> & {
	backgroundColor: string;
	onChangeValue: DateInputProps["onChangeValue"];
}): JSX.Element => {
	const {locale} = React.useContext(LocaleContext);
	return (
		<DatePicker
			date={value}
			onDateChange={onChangeValue}
			mode={type === "duration" ? "time" : type}
			// "fr" has no am/pm
			locale={type === "duration" ? "fr" : Locales.localeToLanguage(locale)}
			textColor={PRIMARY_2}
			minuteInterval={15}
			fadeToColor={backgroundColor}
			{...props}
		/>
	);
};

export const DateInput = ({
	type,
	onChangeValue,
	value,
	props,
	disabled,
	toggleCollapse,
	collapsed,
}: DateInputProps): JSX.Element | null => {
	const roundedValue = value ? roundTime(value, props?.precision) : undefined;
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const backgroundColor = disabled ? WHITE : SUBTLE;

	if (IS_MOBILE || disabled) {
		return (
			<Pressable
				hitSlop={{bottom: 10, left: DEFAULT_SPACING, right: DEFAULT_SPACING, top: 10}}
				{...!disabled && {onPress: toggleCollapse}}
				style={[styles.row, styles.alignSelfCenter]}
			>
				{roundedValue && (
					<Text color={disabled ? SUBTLE_4 : PRIMARY}>
						{type === "date" && formatDate(roundedValue)}
						{type === "datetime" && formatDateTime(roundedValue)}
						{type === "time" && formatTime(roundedValue)}
						{type === "duration" && formatDuration(today, roundedValue)}
					</Text>
				)}
				{!disabled && (collapsed
					? <Icon icon="chevronBottom" color={SUBTLE_4}/>
					: <Icon icon="chevronUp" color={SUBTLE_4}/>)}
			</Pressable>
		);
	}
	if (IS_WEB && !disabled) {
		return (
			<DatePickerComp
				type={type}
				value={roundedValue}
				onChangeValue={onChangeValue}
				backgroundColor={backgroundColor}
				props={props}
			/>
		);
	}
	return null;
};

export const NativeDatePickerInput = ({
	type,
	onChangeValue,
	value,
	props,
	disabled,
	collapsed,
}: DateInputProps): JSX.Element => {
	const roundedValue = value ? roundTime(value, props?.precision) : new Date();
	const backgroundColor = disabled ? WHITE : SUBTLE;

	return (
		<Collapsible collapsed={collapsed} style={styles.pickerWrapper}>
			<DatePickerComp
				type={type}
				value={roundedValue}
				onChangeValue={onChangeValue}
				backgroundColor={backgroundColor}
				props={props}
			/>
		</Collapsible>
	);
};

const styles = StyleSheet.create({
	alignSelfCenter: {
		alignSelf: "center",
	},
	pickerWrapper: {
		alignItems: "center",
		marginTop: IS_IOS ? -SMALL_SPACING : 0,
	},
	row: {
		alignItems: "center",
		flexDirection: "row",
	},
});
