import * as React from "react";
import {StyleSheet, View} from "react-native";
import {DEFAULT_SPACING, SMALL_SPACING} from "../utils/constants";
import {BACKGROUND_COLOR} from "../utils/styles/colors";
import {ELEVATIONS} from "../utils/styles/elevations";
import {Button, ButtonProps} from "./buttons/button";
import {Text} from "./texts/text";

interface InfoBoxProps {
	buttons?: (ButtonProps & {key: string})[];
	subTitle?: string;
	title?: string;
}

export const InfoBox = ({buttons, subTitle, title}: InfoBoxProps): JSX.Element => {
	return (
		<View style={styles.container}>
			{title && (<Text type="title_2" centered>{title}</Text>)}
			{subTitle && (<Text centered>{subTitle}</Text>)}
			{buttons?.map(button => (
				// Key is already contained in "button" which is spread
				// eslint-disable-next-line react/jsx-key
				<Button
					style={styles.button}
					size="small"
					fullWidth
					{...button}
				/>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		marginTop: SMALL_SPACING,
	},
	container: {
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: SMALL_SPACING,
		marginBottom: DEFAULT_SPACING,
		marginHorizontal: SMALL_SPACING,
		padding: DEFAULT_SPACING,
		...ELEVATIONS[4],
	},
});
