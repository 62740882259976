import React from "react";
import {ActivityType} from "../../@types/activities/activity";
import {IS_CLIENT} from "../../utils/constants";
import {personIdentity} from "../../utils/identities";
import {ct} from "../../utils/locales/translations";
import {SessionElementPlaceHolder} from "../list/items/session";
import {UnavailabilityElementPlaceHolder} from "../list/items/unavailability";

interface Props {
	activity: ActivityType;
	fetchFailed: boolean;
	fetching: boolean;
}

export const ActivityPlaceholder = ({activity, fetching, fetchFailed}: Props): JSX.Element => (
	activity === "unavailability"
		? <UnavailabilityElementPlaceHolder showIndicator={fetching} fetchFailed={fetchFailed}/>
		: (
			<SessionElementPlaceHolder
				showIndicator={fetching}
				fetchFailed={fetchFailed}
				details={IS_CLIENT
					? [
						{
							label: ct("common:interpreter"),
							value: personIdentity(null),
							width: 200,
						},
						{
							label: ct("common:requester"),
							value: personIdentity(null),
							width: 200,
						},
					]
					: undefined
				}
			/>
		)
);
