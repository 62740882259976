import {origins as enUSOrigins} from "../en-US/origins";

export const origins: typeof enUSOrigins = {
	AD: "Andorre",
	AE: "Emirats Arabes Unis",
	AF: "Afghanistan",
	AG: "Antigua-et-Barbuda",
	AI: "Anguilla",
	AL: "Albanie",
	AM: "Arménie",
	AN: "Antilles néerlandaises",
	AO: "Angola",
	AQ: "Antarctique",
	AR: "Argentine",
	AS: "Samoa américaines",
	AT: "Autriche",
	AU: "Australie",
	AW: "Aruba",
	AX: "Iles Aland",
	AZ: "Azerbaijan",
	BA: "Bosnie Herzégovine",
	BB: "Barbade",
	BD: "Bangladesh",
	BE: "Belgique",
	BF: "Burkina Faso",
	BG: "Bulgarie",
	BH: "Bahreïn",
	BI: "Burundi",
	BJ: "Bénin",
	BL: "Saint -Barthélemy",
	BM: "Bermudes",
	BN: "Brunei Darussalam ",
	BO: "Bolivie",
	BR: "Brésil",
	BS: "Bahamas",
	BT: "Bhoutan",
	BV: "Île Bouvet",
	BW: "Botswana",
	BY: "Biélorussie",
	BZ: "Belize",
	CA: "Canada",
	CC: "Cocos ( Keeling )",
	CD: "République Démocratique du Congo",
	CE: "Tchétchénie",
	CF: "République centrafricaine",
	CG: "Congo",
	CH: "Suisse",
	CI: "Côte d'Ivoire",
	CK: "Les Îles Cook",
	CL: "Chili",
	CM: "Cameroun",
	CN: "Chine",
	CO: "Colombie",
	CR: "Costa Rica",
	CS: "Serbie et Monténégro",
	CU: "Cuba",
	CV: "Cap-Vert",
	CW: "Curaçao",
	CY: "Chypre",
	CZ: "République Tchèque",
	DE: "Allemagne",
	DJ: "Djibouti",
	DK: "Danemark",
	DM: "Dominique",
	DO: "République Dominicaine",
	DZ: "Algérie",
	EC: "Equateur",
	EE: "Estonie",
	EG: "Egypte",
	EH: "Sahara occidental",
	ER: "Érythrée",
	ES: "Espagne",
	ET: "Ethiopie",
	FI: "Finlande",
	FJ: "Fidji",
	FK: "Îles Falkland",
	FM: "Micronésie",
	FO: "Îles Féroé",
	FR: "France",
	GA: "Gabon",
	GB: "Royaume-Uni",
	GD: "Grenada",
	GE: "Géorgie",
	GF: "Guinée Française",
	GG: "Guernesey",
	GH: "Ghana",
	GI: "Gibraltar",
	GL: "Groenland",
	GM: "Gambie",
	GN: "Guinée",
	GP: "Guadeloupe",
	GQ: "Guinée Équatoriale",
	GR: "Grèce",
	GT: "Guatemala",
	GU: "Guam",
	GW: "Guinée- Bissau",
	GY: "Guyane",
	HK: "Hong Kong",
	HM: "Île îles Heard et McDonald",
	HN: "Honduras",
	HR: "Croatie",
	HT: "Haïti",
	HU: "Hongrie",
	ID: "Indonésie",
	IE: "Irlande",
	IL: "Israël",
	IM: "Isle of Man",
	IN: "Inde",
	IQ: "Irak",
	IR: "Iran",
	IS: "Islande",
	IT: "Italie",
	JE: "Jersey",
	JM: "Jamaïque",
	JO: "Jordanie",
	JP: "Japon",
	KE: "Kenya",
	KG: "Kirghizistan",
	KH: "Cambodge",
	KM: "Comores",
	KN: "Saint-Christophe-et-Niévès",
	KR: "Corée, République de",
	KW: "Koweit",
	KY: "Îles Caïmans",
	KZ: "Kazakhstan",
	LA: "République démocratique populaire lao",
	LB: "Liban",
	LC: "Sainte-Lucie",
	LI: "Liechtenstein",
	LK: "Sri Lanka",
	LR: "Libéria",
	LS: "Lesotho",
	LT: "Lituanie",
	LU: "Luxembourg",
	LV: "Lettonie",
	LY: "Jamahiriya arabe libyenne",
	MA: "Maroc",
	MC: "Monaco",
	MD: "Moldavie",
	ME: "Monténégro",
	MF: "Saint Martin",
	MG: "Madagascar",
	MH: "Iles Marshall",
	MK: "Macédoine",
	ML: "Mali",
	MM: "Myanmar",
	MN: "Mongolie",
	MO: "Macao",
	MP: "Îles Mariannes du Nord",
	MQ: "Martinique",
	MR: "Mauritanie",
	MS: "Montserrat",
	MT: "Malte",
	MU: "Ile Maurice",
	MV: "Maldives",
	MW: "Malawi",
	MX: "Mexique",
	MY: "Malaisie",
	MZ: "Mozambique",
	NA: "Namibie",
	NC: "Nouvelle Calédonie",
	NE: "Niger",
	NF: "Île Norfolk",
	NG: "Nigeria",
	NI: "Nicaragua",
	NL: "Pays-Bas",
	NO: "Norvège",
	NP: "Népal",
	NR: "Nauru",
	NU: "Niue",
	NZ: "Nouvelle-Zélande",
	OM: "Oman",
	PA: "Panama",
	PE: "Pérou",
	PF: "Polynésie française",
	PG: "Papouasie Nouvelle Guinée",
	PH: "Philippines",
	PK: "Pakistan",
	PL: "Pologne",
	PM: "PalSaint Pierre et Miquelonau",
	PN: "Pitcairn",
	PR: "Porto Rico",
	PS: "Territoires palestiniens",
	PT: "Portugal",
	PW: "Palau",
	PY: "Paraguay",
	QA: "Qatar",
	RE: "Réunion",
	RO: "Roumanie",
	RS: "Serbie",
	RU: "Russie",
	RW: "Rwanda",
	SA: "Arabie Saoudite",
	SB: "Les îles Salomon",
	SC: "Les Seychelles",
	SD: "Soudan",
	SE: "Suède",
	SG: "Singapour",
	SH: "Saint Helena",
	SI: "Slovénie",
	SJ: "Svalbard et Jan Mayen",
	SK: "Slovaquie",
	SL: "Sierra Leone",
	SM: "Saint Marin",
	SN: "Sénégal",
	SO: "Somalie",
	SR: "Suriname",
	SS: "Soudan du sud",
	ST: "Sao Tomé et Principe",
	STAT: "Apatride",
	SV: "Le Salvador",
	SX: "Sint Maarten",
	SY: "Syrie",
	SZ: "Swaziland",
	TB: "Tibet (République populaire de Chine)",
	TC: "îles Turques-et-Caïques",
	TD: "Tchad",
	TG: "Aller",
	TH: "Thaïlande",
	TJ: "Tajikistan",
	TK: "Tokelau",
	TL: "Timor-Leste",
	TM: "Turkménistan",
	TN: "Tunisie",
	TO: "Tonga",
	TR: "Turquie",
	TT: "Trinité-et-Tobago",
	TV: "Tuvalu",
	TW: "Taiwan",
	TZ: "Tanzanie",
	UA: "Ukraine",
	UG: "Ouganda",
	US: "États Unis",
	UY: "Uruguay",
	UZ: "Ouzbékistan",
	VA: "Saint-Siège (Cité du Vatican)",
	VC: "Saint-Vincent-et-les-Grenadines",
	VE: "Venezuela",
	VG: "Îles Vierges britanniques",
	VI: "Virgin Islands, États-Unis",
	VN: "Viêt-Nam",
	VU: "Vanuatu",
	WF: "Wallis et Futuna",
	WS: "Samoa",
	XK: "Kosovo",
	YE: "Yémen",
	YT: "Mayotte",
	ZA: "Afrique du Sud",
	ZM: "Zambie",
	ZW: "Zimbabwe",
};
