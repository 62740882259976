import * as React from "react";
import {StyleSheet, View} from "react-native";
import {
	InterpreterMandate,
	Mediation,
	ProvidedServiceType,
	providedServiceTypes,
} from "../../../../@types/activities/mandate";
import {
	Communication,
	CommunicationType,
	communicationTypes as initialCommunicationTypes,
} from "../../../../@types/activities/session";
import {InterpretersGroup} from "../../../../@types/identities/interpreters-group";
import {AvailableSessionLanguagesCommunication} from "../../../../@types/language-translation";
import {Pagination} from "../../../../@types/pagination";
import {SessionCreationSetting} from "../../../../@types/settings";
import {Button} from "../../../../components/buttons/button";
import {Icon} from "../../../../components/icons";
import {Form, FormInput} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ScrollView} from "../../../../components/scrollables/scroll-view";
import {SplashView} from "../../../../components/views/splash-view";
import {AuthentifiedContext} from "../../../../contexts/authentified";
import {ResponsiveContext} from "../../../../contexts/responsive";
import {SessionCreationContext} from "../../../../contexts/session-creation";
import {getOrigins} from "../../../../requests/clients/new-session/origins";
import {containsSameElements} from "../../../../utils/arrays";
import {CONTAINERS, DEFAULT_SPACING} from "../../../../utils/constants";
import {formatDate, formatDurationInHM} from "../../../../utils/date-time/format";
import {getTimeCoef} from "../../../../utils/date-time/helpers";
import {useCommunicationTypes} from "../../../../utils/hooks/use-communication-types";
import {
	DurationListItem,
	SessionDurationKey,
	useGetDurationListItems,
} from "../../../../utils/hooks/use-get-duration-list-items";
import {useInterpreterFiltersInputs} from "../../../../utils/hooks/use-interpreter-filters-inputs";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {personIdentity} from "../../../../utils/identities";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {generateAvailableLanguagesCommunication, getAvailableToLanguages} from "../../../../utils/languages";
import {Log} from "../../../../utils/logs/logs";
import {paginate} from "../../../../utils/pagination";
import {getCommunicationTypeIcon} from "../../../../utils/sessions/communications";
import {SessionLanguagesKey} from "../../../../utils/sessions/languages";
import {getProvidedServiceIcon} from "../../../../utils/sessions/services";
import {WHITE_OVERLAY} from "../../../../utils/styles/colors";
import {Origin} from "../../common/modals/select-origin";
import {transformPlaceToAddressListItem} from "../../common/modals/select-place";
import {AddressListItem} from "../modals/address-list";

const onChangeCommunication = (
	mandate: Partial<InterpreterMandate>, newType: CommunicationType,
): Communication | undefined => newType
	? {
		// Keep data in case the user changes his mind
		...mandate?.communication,
		type: newType,
	} as Communication
	: undefined;

const getLabelDurations = (duration: DurationListItem): string => duration.displayed;

export const SetupStep = ({inlineMode, onBack, onValidate, onValidationChange}: {
	inlineMode?: boolean;
	onBack: () => void;
	onValidate: () => void;
	onValidationChange: (stepValid: boolean) => void;
}): JSX.Element => {
	const {ct, t} = useTranslation();
	const {columns, mobileDisplay} = React.useContext(ResponsiveContext);
	const {settings: {getSetting, loading: settingsLoading}} = React.useContext(AuthentifiedContext);
	const {
		value: {
			availableLanguagesCommunication,
			interpreterFilters: {
				interpretersGroups: interpretersGroupsSetting,
			},
			setupStep: {availableServices, availableSessionTypes, firstAllowedBookingDate, lastAllowedBookingDate, minimumDuration},
		} = {
			availableLanguagesCommunication: {} as AvailableSessionLanguagesCommunication,
			interpreterFilters: {
				interpretersGroups: [] as InterpretersGroup[],
			},
			setupStep: {
				availableServices: [] as SessionCreationSetting["value"]["setupStep"]["availableServices"],
				availableSessionTypes: [] as SessionCreationSetting["value"]["setupStep"]["availableSessionTypes"],
				firstAllowedBookingDate: undefined,
				lastAllowedBookingDate: undefined,
				minimumDuration: 1,
			},
		},
	} = getSetting<SessionCreationSetting>("session/creation", "organization/all") || {};

	const oneSessionTypeAvailable = availableSessionTypes.length === 1;
	const {
		filters,
		setFilters,
		setSession,
		session,
		reset,
		setSlot,
		initialProvider,
		loadingProviders,
	} = React.useContext(SessionCreationContext);
	const getDurationListItems = useGetDurationListItems();
	const {context, communication, duration, immediate, providedService, start, language, toLanguage} = session;
	const [validations, setValidations] = React.useState({filters: !!initialProvider, session: false});
	const [origins, setOrigins] = React.useState<Origin[]>([]);

	const options = React.useMemo(
		() => ({
			filters: {filters, setFilters},
			firstName: false,
			gender: "includeNotRelevant" as const,
			language: false,
			lastName: false,
			origins: {includeNotRelevant: true, values: origins},
		}),
		[filters, origins, setFilters],
	);
	const {inputs: interpreterFiltersInputs} = useInterpreterFiltersInputs(options);
	const providerToLanguages: Set<SessionLanguagesKey> = React.useMemo(
		() => initialProvider
			? getAvailableToLanguages(generateAvailableLanguagesCommunication(initialProvider))
			: new Set(),
		[initialProvider],
	);

	const excludedCommunicationTypesParam = React.useMemo(
		() =>
			initialCommunicationTypes.filter(type => providedService.type === "standard"
				? initialProvider
					? !initialProvider.communicationTypes.includes(type)
					: !availableLanguagesCommunication[language]?.get(toLanguage!)?.has(type) &&
					!availableLanguagesCommunication[toLanguage!]?.get(language)?.has(type) // Courses and Mediations providedServices can only occur in person
				: type !== "inPerson")
		,
		[initialProvider, providedService, availableLanguagesCommunication, language, toLanguage],
	);
	const communicationTypes = useCommunicationTypes(excludedCommunicationTypesParam);
	const oneCommunicationTypesAvailable = communicationTypes.length === 1;

	const availableProvidedServices = React.useMemo(
		() => providedServiceTypes.filter(service => initialProvider?.providedServices
			? initialProvider.providedServices.includes(service) && availableServices.includes(service) &&
			(communicationTypes?.includes("inPerson") || service === "standard")
			: availableServices.includes(service) && (communicationTypes?.includes("inPerson") || service === "standard")),
		[availableServices, communicationTypes, initialProvider],
	);

	const oneProvidedServiceAvailable = availableProvidedServices.length === 1;

	// select the first communication type available
	React.useEffect(
		() => {
			setSession(prev => !settingsLoading && communicationTypes && prev.communication &&
				!communicationTypes.includes(prev.communication.type)
				? ({...prev, communication: onChangeCommunication(prev, communicationTypes[0])})
				: prev,
			);
		},
		[communication?.type, communicationTypes, setSession, settingsLoading],
	);

	// Reset duration if it is smaller than the minimum / greater than the maximum for the current communication type
	React.useEffect(
		() => {
			const durations = getDurationListItems(minimumDuration, communication?.type);
			// We assume the first element of the list is the minimum and the last is the maximum
			setSession(
				prev => prev.duration && (
					prev.duration < Number(durations[0].id) * getTimeCoef("minute") ||
					prev.duration > Number(durations[durations.length - 1].id) * getTimeCoef("minute")
				)
					? ({...prev, duration: undefined})
					: prev,
			);
		},
		[communication?.type, minimumDuration, setSession, getDurationListItems],
	);

	// Prefills the fields when creating a session with an interpreter
	React.useEffect(
		() => {
			if (initialProvider) {
				setSession(prev => {
					const newSession = {...prev} satisfies Partial<InterpreterMandate>;
					if (initialProvider.communicationTypes.length === 1) {
						newSession.communication = onChangeCommunication(prev, initialProvider.communicationTypes[0]);
					} else if (prev.communication) {
						newSession.communication = prev.communication;
					}
					if (providerToLanguages?.size === 1 && context?.type !== "reschedule" && context?.type !== "followUp") {
						newSession.toLanguage = [...providerToLanguages][0];
					} else if (prev.toLanguage) {
						newSession.toLanguage = prev.toLanguage;
					}
					if (initialProvider.providedServices.length === 1) {
						newSession.providedService = {
							...(prev.providedService as Mediation),
							type: initialProvider.providedServices[0],
						};
					} else if (prev.providedService) {
						newSession.providedService = prev.providedService;
					} else {
						newSession.providedService = {type: "standard"};
					}
					return newSession;
				});
			}
		},
		[context?.type, initialProvider, providerToLanguages, setSession, setFilters, interpretersGroupsSetting],
	);

	// Updates the origin dropdown when the toLanguage changes
	React.useEffect(
		() => {
			if (toLanguage) {
				getOrigins(toLanguage).then(setOrigins).catch(Log.error());
			} else {
				setOrigins([]);
			}
		},
		[toLanguage],
	);

	// Prefills the communication types and provided services based on the number of choices available
	React.useEffect(
		() => {
			if (!settingsLoading &&
				(oneSessionTypeAvailable || oneProvidedServiceAvailable || oneCommunicationTypesAvailable)) {
				setSession(prev => ({
					...prev,
					communication: oneCommunicationTypesAvailable
						? onChangeCommunication(prev, communicationTypes[0])
						: oneProvidedServiceAvailable && availableProvidedServices[0] !== "standard"
							? onChangeCommunication(prev, "inPerson")
							: prev.communication,
					providedService: oneProvidedServiceAvailable
						? {
							...(prev.providedService as Mediation),
							type: availableProvidedServices[0],
						}
						: prev.providedService,
					// Courses and Mediations providedServices can only occur in person
					type: oneSessionTypeAvailable ? availableSessionTypes[0] : prev.type,
				}));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			availableSessionTypes,
			oneCommunicationTypesAvailable,
			oneProvidedServiceAvailable,
			oneSessionTypeAvailable,
			setSession,
			settingsLoading,
		],
	);

	// Updates the selected interpretersGroups if the settings have changed
	React.useEffect(
		() => {
			if (filters.interpretersGroups && !containsSameElements(filters.interpretersGroups, interpretersGroupsSetting)) {
				setFilters(prev => ({
					...prev,
					interpretersGroups: interpretersGroupsSetting.filter((group) => group.default),
				}));
			}
		},
		// We don't want filters.interpretersGroup in the deps because if we do, we won't be able to select specific interpretersGroups in the session creation
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[interpretersGroupsSetting, setFilters],
	);

	const onPressReset = React.useCallback(
		() => reset({type: "new"}),
		[reset],
	);

	const onSessionValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, session: isValid})),
		[],
	);
	const onFiltersValidation = React.useCallback(
		(isValid: boolean) => setValidations(prev => ({...prev, filters: isValid})),
		[],
	);

	React.useEffect(() => {
		onValidationChange(validations.session && validations.filters);
	}, [onValidationChange, validations.session, validations.filters]);

	const getDurations = React.useCallback(
		(pagination: Pagination) => Promise.resolve(paginate(
			getDurationListItems(minimumDuration, communication?.type), pagination),
		),
		[communication?.type, getDurationListItems, minimumDuration],
	);

	const onSearchDuration = React.useMemo(
		() => ({
			request: (pagination: Pagination, search: string) => Promise.resolve(
				paginate(
					getDurationListItems(minimumDuration, communication?.type, search),
					pagination,
				),
			),
		}),
		[communication?.type, getDurationListItems, minimumDuration],
	);

	const onChangeDuration = React.useCallback(
		(duration: DurationListItem[] | null) =>
			setSession(prev => ({
				...prev,
				duration: duration?.[0].id ? Number.parseInt(duration?.[0].id, 10) * getTimeCoef("minute") : undefined,
			})),
		[setSession],
	);

	const sessionInputs: FormInput[] = [
		columns === 1 && context && {
			disabled: true,
			explanation: context.type === "reschedule" && t("activities:sessions.context.type.explanation"),
			icon: "about",
			label: ct("common:type"),
			type: {
				key: "text",
				value: t(
					`activities:sessions.context.type.${initialProvider ? "withProvider" : "withoutProvider"}.${context.type}`,
					initialProvider ? {provider: personIdentity(initialProvider)} : {},
				),
			},
		}, {
			icon: "status",
			label: ct("screens:creation.scheduling.scheduling"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: [false, true],
				getLabelText: (value: boolean) => ct(`screens:creation.scheduling.${value ? "immediate" : "scheduled"}`),
				key: "inlineSelect",
				onChangeValue: (values?: boolean[] | null) => {
					const value = values?.[0];
					if (value == null) {
						return;
					}
					setSession(prev => {
						const res = {...prev, immediate: value};
						delete res.start;
						delete res.end;
						return res;
					});
					setSlot(null);
				},
				value: typeof immediate === "boolean" ? [immediate] : undefined,
			},
		},
		!immediate && {
			icon: "calendar",
			label: ct("common:date"),
			resetable: false,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (value: Date) => setSession(prev => ({...prev, start: value})),
				params: {
					firstAllowedBookingDate,
					focusDate: start,
					inSessionCreation: true,
					lastAllowedBookingDate,
				},
				renderValue: formatDate,
				screen: "SelectDateCalendarModal",
				value: start,
			},
		},
		"spacer",
		{
			disabled: oneCommunicationTypesAvailable || initialProvider?.communicationTypes.length === 1,
			icon: "communicationMode",
			label: ct("common:communicationType"),
			resetable: false,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: communicationTypes,
				getLabelText: (type: CommunicationType) => t(`activities:sessions.communicationTypes.${type}`),
				itemProps: (type: CommunicationType) => ({icon: getCommunicationTypeIcon(type)}),
				key: "inlineSelect",
				onChangeValue: (types?: CommunicationType[] | null) =>
					setSession(prev => {
						const newCommunicationType = types?.[0] ? onChangeCommunication(prev, types?.[0]) : undefined;
						return types ? {...prev, communication: newCommunicationType} : prev;
					}),
				value: communicationTypes?.length > 0 && communication?.type ? [communication?.type] : undefined,
			},
		},
		communication?.type === "inPerson" && {
			icon: "place",
			label: ct("activities:sessions.address"),
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "screen",
				onChangeValue: (place: AddressListItem) =>
					setSession(prev => ({
						...prev,
						communication: {
							...prev.communication!,
							place: place?.displayed,
						},
					})),
				renderValue: (place: AddressListItem) => place.displayed?.address,
				screen: "SelectPlaceModal",
				value: communication.place ? transformPlaceToAddressListItem(communication.place) : null,
			},
		},
		communication?.type === "inPerson" && {
			icon: "map",
			label: ct("activities:sessions.addressInfos"),
			type: {
				key: "text",
				onChangeValue: (placeInfos: string) =>
					setSession(prev => ({...prev, communication: {...prev.communication!, placeInfos}})),
				value: communication?.placeInfos,
			},
		},
		"spacer",
		{
			icon: "timer",
			label: ct("common:duration"),
			resetable: false,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			tooltip: t("screens:creation.durationInfo"),
			type: mobileDisplay
				? {
					key: "screen",
					onChangeValue: (duration: number) =>
						setSession(prev => ({
							...prev,
							duration,
						})),
					params: {communicationType: communication?.type},
					renderValue: (value: number) => formatDurationInHM(value),
					screen: "SelectDurationModal",
					value: duration,
				}
				: {
					getLabelText: getLabelDurations,
					getRequest: getDurations,
					idKey: "id",
					itemTranslationKey: "common:duration",
					key: "dropdownSelect",
					onChangeValue: onChangeDuration,
					onSearch: onSearchDuration,
					value: duration
						? typeof duration === "object"
							? [duration]
							: [
								{
									displayed: t(`activities:sessions.durations.${
										(Math.round(duration / getTimeCoef("minute"))).toString() as SessionDurationKey
									}`),
									id: Math.round(duration / getTimeCoef("minute")),
								},
							]
						: undefined,
					wordsBeginBySearch: true,
				},
		},
		"spacer",
		!(oneProvidedServiceAvailable || initialProvider?.providedServices.length === 1) && {
			disabled: settingsLoading,
			icon: "handshake",
			label: ct("common:providedService"),
			resetable: false,
			rules: [{rule: Rules.notEmpty, type: "error"}],
			type: {
				choices: availableProvidedServices,
				getLabelText: (value: ProvidedServiceType) => ct(`activities:sessions.providedService.${value}`),
				itemProps: (value: ProvidedServiceType) => ({icon: getProvidedServiceIcon(value)}),
				key: "inlineSelect",
				onChangeValue: (values?: ProvidedServiceType[] | null) =>
					setSession(prev => values
						? {...prev, providedService: {...(prev.providedService as Mediation), type: values[0]}}
						: prev),
				value: providedService?.type ? [providedService?.type] : undefined,
			},
		},
	];

	return (
		<>
			<HeaderMenu left={<Icon icon="back" onPress={onBack}/>} center={ct("screens:creation.myAppointment")}/>
			<ScrollView>
				{settingsLoading
					? <SplashView centered loading/>
					: (
						<>
							<Form
								title={context?.type === "reschedule" ? ct("common:reschedule") : t("activities:sessions.createNew")}
								inputs={sessionInputs}
								onValidationChange={onSessionValidation}
								onPressReset={onPressReset}
								hideReset
								requiredLabels
							/>
							{!initialProvider && (
								<Form
									title={ct("screens:creation.filters")}
									inputs={interpreterFiltersInputs}
									onValidationChange={onFiltersValidation}
									hideReset
									requiredLabels
								/>
							)}
							{!inlineMode && (
								<View style={[CONTAINERS.MAIN, styles.buttonContainer]}>
									<Button
										text={ct("common:next")}
										onPress={onValidate}
										disabled={!validations.session || !validations.filters}
										size="small"
										fullWidth
									/>
								</View>
							)}
						</>
					)}
			</ScrollView>
			{loadingProviders && <View style={styles.overlay}/>}
		</>
	);
};

const styles = StyleSheet.create({
	buttonContainer: {
		padding: DEFAULT_SPACING,
		paddingTop: 0,
	},
	overlay: {
		backgroundColor: WHITE_OVERLAY,
		...StyleSheet.absoluteFillObject,
	},
});
