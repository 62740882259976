import {Account} from "../../../@types/accounts";
import {Session} from "../../../@types/activities/session";
import {Interpreter} from "../../../@types/identities/interpreter";
import {NotificationTarget} from "../../../@types/notifications";
import {IdentityEditableFieldsSetting, InterpreterSetting, NotificationTargetsSetting, Setting} from "../../../@types/settings";
import {api} from "../../../utils/network/api";
import {ApiResponse} from "../../../utils/network/api-response";
import {BackendStatus} from "../../@types/session";

export type InterpreterFollowUpDurationGetRequests = {
	GET: {
		queryParams: {sessionId: string},
	},
};

type BackendInterpreterSettings = {
	immediate?: boolean,
	nights?: boolean,
	nightStart?: number,
	nightEnd?: number,
	weekend?: boolean,
	weekendStart?: number,
	weekendEnd?: number,
	inPersonAvailability?: boolean,
	videoCallAvailability?: boolean,
	voiceCallAvailability?: boolean,
	mediationAvailability?: boolean,
	courseAvailability?: boolean,
	status?: BackendStatus[],
};
export type InterpreterSettingsResponse = ApiResponse<{interpreterSetting: BackendInterpreterSettings}>;
export type InterpreterSettingsGetRequests = {
	GET: {
		pathParams: {
			interpreterId: string,
		},
	},
};
export type CommonNotificationsRequests = {
	GET: {
		queryParams?: {
			limit?: string,
			offset?: string,
		},
	},
	POST: {
		body: Partial<NotificationTarget>,
	},
	DELETE: {
		body: {
			deviceToken: string,
			interpreterId?: string,
		},
	},
};
export type InterpreterSettingsUpdateRequests = {
	PUT: {
		body: {
			immediate?: boolean,
			nights?: boolean,
			weekend?: boolean,
			status?: BackendStatus[],
		},
	},
};
export type SessionRefusalSettingsRequests = {
	GET: {
		pathParams: {
			sessionId: Session["activityId"],
		},
	}
};

export const getInterpreterSettings = async (accountId: Account["accountId"]): Promise<Setting[]> => {
	const setting: Setting[] = [];
	// availabilities and communication settings
	const otherResponse = await api(
		"interpreterSettingsGet",
		"GET",
		{
			pathParams: {
				interpreterId: accountId,
			},
		},
	) as InterpreterSettingsResponse;
	if (otherResponse.isSuccess) {
		const {
			inPersonAvailability,
			videoCallAvailability, voiceCallAvailability, weekend,
			immediate, nights, nightStart, nightEnd, weekendStart, weekendEnd,
		} = otherResponse.data.interpreterSetting;
		setting.push({
			settingId: "1",
			context: "account",
			owner: accountId,
			key: "availability/availabilities",
			value: {
				weekend: {
					value: weekend,
					start: weekendStart ? new Date(weekendStart) : new Date(1970, 0, 2, 20, 0, 0),
					end: weekendEnd ? new Date(weekendEnd) : new Date(1970, 0, 5, 7, 0, 0),
				},
				immediate,
				nights: {
					value: nights,
					start: nightStart ? new Date(nightStart) : new Date(1970, 0, 0, 20, 0, 0),
					end: nightEnd ? new Date(nightEnd) : new Date(1970, 0, 0, 7, 0, 0),
				},
			},
		},
		{
			settingId: "2",
			context: "organization/all",
			owner: accountId,
			key: "session/communication",
			value: {
				inPerson: inPersonAvailability,
				video: videoCallAvailability,
				phone: voiceCallAvailability,
			},
		});
	}

	// notification settings
	const notificationResponse = await api(
		"commonNotifications",
		"GET",
		{},
	) as ApiResponse<{list: NotificationTarget[], count: number}>;
	if (notificationResponse.isSuccess) {
		let {list} = notificationResponse.data;
		list = list.map(target => ({...target, deletable: target.channel === "FCM" || target.channel === "APNS"}));
		const notificationSetting: NotificationTargetsSetting = {
			settingId: "3",
			context: "account",
			owner: accountId,
			key: "notification/targets",
			value: list,
		};
		setting.push(notificationSetting);
	}

	// profile, validation types and support infos settings
	setting.push({
		settingId: "4",
		context: "account",
		owner: accountId,
		key: "identity/editable-fields",
		value: {
			transport: [true, true, null],
			origin: [false, true, null],
			picture: [true, false, null],
			gender: [false, true, null],
			fullName: [false, false, {
				firstName: [false, false, null],
				lastName: [true, true, null],
			}],
			dateOfBirth: [false, false, null],
			email: [false, true, null],
			phone: [false, true, null],
			place: [false, true, null],
			languages: [false, true, null],
			qualifications: [false, true, null],
		},
	} as IdentityEditableFieldsSetting<Interpreter>,
	);
	const supportResponse = await api(
		"interpreterSupportMailGet",
		"GET",
		{},
	) as ApiResponse<{emails: string[], phones: string[]}>;
	if (supportResponse.isSuccess) {
		const {emails, phones} = supportResponse.data;
		const email: string = emails[1] ?? emails[0];
		const phone: string = phones[1] ?? phones[0];
		setting.push({
			settingId: "7",
			context: "organization/all",
			owner: accountId,
			key: "support/infos",
			value: {email, phone},
		});
	}
	return (otherResponse.isSuccess && notificationResponse.isSuccess && supportResponse.isSuccess)
		? setting
		: Promise.reject(new Error(otherResponse.message || notificationResponse.message || supportResponse.message));
};

export const updateInterpreterSetting = async (updated: InterpreterSetting): Promise<void> => {
	const {key, value} = updated;
	let interpreterResponse: ApiResponse<{}>;
	switch (key) {
		case "notification/targets": {
			const targets = value;
			interpreterResponse = await api(
				"commonNotifications",
				"POST",
				{
					body: targets[0],
				},
			) as ApiResponse<{}>;
			break;
		}
		case "availability/availabilities": {
			const {weekend, nights, immediate} = value;
			interpreterResponse = await api(
				"interpreterSettingsUpdate",
				"PUT",
				{
					body: {
						weekend: weekend && weekend.value,
						immediate,
						nights: nights && nights.value,
					},
				},
			) as ApiResponse<{}>;
			break;
		}
	}
	if (!interpreterResponse.isSuccess) {
		throw new Error(interpreterResponse.interpreterMessage);
	}
};
