import * as React from "react";
import {useTranslation as UT} from "react-i18next";
import {capitalize, uncapitalize} from "../strings";

/*
 * Wrapper hook for react-i18next's useTranslation, with also a capitalization option.
 * TODO: allow passing namespace and options to be forwarded to react-i18next's hook.
 */
// TODO: type this function's return type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTranslation = () => {
	const {t, ...others} = UT();
	const ctCallback: typeof t = (key, options) => capitalize(t(key, options) as string);
	const ct = React.useCallback(ctCallback, [t]);
	const uctCallback: typeof t = (key, options) => uncapitalize(t(key, options) as string);
	const uct = React.useCallback(uctCallback, [t]);
	return {...others, ct, t, uct};
};
