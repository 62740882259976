import {SessionInfo} from "../../../@types/activities/connect";
import {DataGetter, MessageReceived} from "../../../utils/hooks/use-websocket";

interface KeepAliveMessage extends MessageReceived {
	key: "KEEP_ALIVE";
}

interface SessionInfoMessage extends MessageReceived {
	data: {
		effectiveStart: string | null;
	};
	key: "SESSION_INFO";
}

export const sendKeepAliveMessage = (): KeepAliveMessage => ({
	key: "KEEP_ALIVE",
});

export const getSessionInfos: DataGetter<SessionInfo> = {
	getData: (message) => {
		const data = (message as SessionInfoMessage).data;
		return ({
			...data,
			effectiveStart: data.effectiveStart ? new Date(data.effectiveStart) : null,
		});
	},
	key: "SESSION_INFO",
};
