import * as React from "react";
import {
	ActivityIndicator,
	FlatListProps,
	ListRenderItemInfo,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
	ViewToken,
} from "react-native";
import {Popover} from "react-native-popper";
import {CONTAINERS, DEFAULT_SPACING, SMALL_SPACING} from "../../utils/constants";
import {useSelectList} from "../../utils/hooks/use-select-list";
import {useTranslation} from "../../utils/hooks/use-translation";
import {StackParamList} from "../../utils/navigation/paramLists/root-param-list";
import {BACKGROUND_COLOR, BLUE, SUBTLE_4} from "../../utils/styles/colors";
import {ELEVATIONS} from "../../utils/styles/elevations";
import {Button, Buttons} from "../buttons/button";
import {FlatList} from "../scrollables/flat-list";
import {SplashView} from "../views/splash-view";
import {ListEmpty} from "./items/list-empty";
import {Item, ListPropsCommon, OnlyKeysExtendingSelectListType} from "./list-props.common";

export interface FlatListRenderItemParams<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
> {
	buttons: Buttons;
	currentSearch: string;
	focusedStyle?: StyleProp<ViewStyle>;
	info: ListRenderItemInfo<I>;
}

type FlatListRenderItem<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
> = ({
	info,
	buttons,
	currentSearch,
	focusedStyle,
}: FlatListRenderItemParams<IdKey, I>) => React.ReactElement | null;

type Props<
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
> = ListPropsCommon<IdKey, I, RouteName> & {
	flatListProps?: Omit<Partial<FlatListProps<I>>, "renderItem">;
	renderItem: FlatListRenderItem<IdKey, I>;
};

export const SelectFlatList = <
	IdKey extends keyof I,
	I extends Item<IdKey, {[K in IdKey]: string}>,
	RouteName extends OnlyKeysExtendingSelectListType<StackParamList, I> = OnlyKeysExtendingSelectListType<StackParamList, I>,
>(
	props: Props<IdKey, I, RouteName>,
): JSX.Element => {
	const {
		renderItem: renderItemProp,
		itemTranslation,
		itemTranslationKey,
		flatListProps,
		dropDown,
		onSearch,
		onPressEnter,
		idKey,
	} = props;
	const {ct} = useTranslation();
	const flatListRef = React.useRef<FlatList<I>>(null);
	const viewableItemsRef = React.useRef<ViewToken[]>([]);
	const {
		actionButtons,
		errorMessage,
		dropdownInput,
		headerComponent,
		footerComponent,
		items,
		isMoreAfter,
		keyExtractor,
		loading,
		loadMoreAfter,
		refreshControl,
		reloadItems,
		search,
		focusedItemIndex,
	} = useSelectList(props);

	const renderItem = React.useCallback(
		(info: ListRenderItemInfo<I>) =>
			renderItemProp({
				buttons: actionButtons(info.item),
				currentSearch: onSearch?.value ?? search,
				focusedStyle: !!onPressEnter && info.index === focusedItemIndex && styles.focusedItem,
				info,
			}),
		[actionButtons, focusedItemIndex, onPressEnter, onSearch?.value, renderItemProp, search],
	);

	const handleViewableItemsChanged = React.useRef(
		({viewableItems}: {viewableItems: ViewToken[]}) => { viewableItemsRef.current = viewableItems; },
	);

	React.useEffect(
		() => {
			if (flatListRef.current && focusedItemIndex >= 0) {
				const focusedItem = viewableItemsRef.current.find(i => i.key === items[focusedItemIndex][idKey]);
				if (!focusedItem) {
					flatListRef.current.scrollToIndex({
						animated: true,
						index: focusedItemIndex <= 2 ? 0 : focusedItemIndex - 1,
					});
				}
			}
		},
		// adding viewableItems in the dependencies break the mouse scroll
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[focusedItemIndex, idKey, items],
	);

	const flatListContent = loading || errorMessage
		? (
			<View style={[styles.defaultLoadingContainer, styles.contentContainer]}>
				{/* cast as ReactNode to avoid multiple children were provided error */}
				{flatListProps?.ListHeaderComponent as React.ReactNode}
				<SplashView
					loading={loading}
					centered
					message={!loading && errorMessage ? {translationKey: errorMessage, type: "error"} : undefined}
				/>
				{!loading && errorMessage && <Button
					fullWidth
					size="small"
					type="secondary"
					text={ct("common:retry")}
					icon="refresh"
					style={styles.retry}
					onPress={reloadItems}
				/>}
			</View>
		)
		: (
			<FlatList
				{...flatListProps}
				ref={flatListRef}
				contentContainerStyle={styles.contentContainer}
				data={items}
				keyboardDismissMode="none"
				keyboardShouldPersistTaps="handled"
				keyExtractor={keyExtractor}
				renderItem={renderItem}
				ListEmptyComponent={<ListEmpty {...(itemTranslation
					? {itemTranslation}
					: {itemTranslationKey: itemTranslationKey!})} />}
				onEndReached={loadMoreAfter}
				refreshControl={refreshControl || undefined}
				onViewableItemsChanged={handleViewableItemsChanged.current}
				ListFooterComponent={
					isMoreAfter
						? <ActivityIndicator color={SUBTLE_4} style={styles.placeHolderIndicator}/>
						: flatListProps?.ListFooterComponent
				}
			/>
		);

	const onPressOut = React.useCallback(
		() => dropDown?.setDropdownOpened(false),
		[dropDown],
	);

	return (
		<>
			{dropDown?.inlineSearch
				? headerComponent
				: !dropDown?.disableInput && dropdownInput
			}
			{dropDown
				? !(dropDown.closeIfNoResults && items.length === 0) && dropDown.trigger && (
					<Popover
						trigger={dropDown.trigger}
						isOpen={dropDown.dropdownOpened}
						mode="multiple"
					>
						<Popover.Backdrop onPressOut={onPressOut}/>
						<Popover.Content>
							<View style={[CONTAINERS.MAIN, styles.dropdown, {width: dropDown.width}]}>
								{!dropDown?.inlineSearch && headerComponent}
								{flatListContent}
								{footerComponent}
							</View>
						</Popover.Content>
					</Popover>
				)
				: (
					<>
						{headerComponent}
						{flatListContent}
						{footerComponent}
					</>
				)
			}
		</>
	);
};

const styles = StyleSheet.create({
	contentContainer: {
		paddingVertical: SMALL_SPACING,
	},
	defaultLoadingContainer: {
		flexBasis: 300,
	},
	dropdown: {
		...ELEVATIONS[12],
		backgroundColor: BACKGROUND_COLOR,
		borderRadius: DEFAULT_SPACING,
		marginTop: 1,
		maxHeight: 300,
		shadowOffset: {height: 1, width: 0},
	},
	focusedItem: {
		borderColor: BLUE,
		borderWidth: 1,
	},
	placeHolderIndicator: {
		alignSelf: "center",
		padding: DEFAULT_SPACING,
	},
	retry: {
		paddingHorizontal: SMALL_SPACING,
	},
});
