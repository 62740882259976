import * as React from "react";
import {Corporation} from "../../../../@types/identities/corporation";
import {Form, FormProps} from "../../../../components/inputs/form";
import {HeaderMenu} from "../../../../components/menus/header";
import {ModalWrapper} from "../../../../components/views/modal-wrapper";
import {SplashView} from "../../../../components/views/splash-view";
import {filterTruthy} from "../../../../utils/arrays";
import {useForm} from "../../../../utils/hooks/use-form";
import {useTranslation} from "../../../../utils/hooks/use-translation";
import {Rules} from "../../../../utils/inputs/rules/rules";
import {ClientStackNavigatorScreenProps} from "../../../../utils/navigation/paramLists/client-param-list";
import {removeNonPhoneNumberCharacters} from "../../../../utils/strings";
import {forceBack} from "../../../navigation";

const EditCorporation = ({
	route,
	navigation,
}: ClientStackNavigatorScreenProps<"EditCorporationModal">): JSX.Element => {
	const {ct, t} = useTranslation();
	const {selectionOrBase: corporationParam, onSelect, title, authorizedFields} = route.params ?? {};
	const base = React.useMemo(
		() => corporationParam ?? {} as Corporation,
		[corporationParam],
	);
	const {loading: loadingForm, displayed, isUpdated, setUpdated, errorMessage} = useForm(base);

	const addOrUpdateTitle = corporationParam
		? ct("forms:items.update", {item: t("common:corporation")})
		: ct("forms:items.add", {item: t("common:corporation")});

	const header = <HeaderMenu center={title ?? addOrUpdateTitle} exitConfirmation={isUpdated}/>;
	if (loadingForm || errorMessage) {
		return (
			<SplashView
				centered
				headerComponent={header}
				loading={loadingForm}
				message={errorMessage && {translationKey: errorMessage, type: "error"}}
			/>
		);
	}

	const {name, email, phone, description} = displayed;
	const {
		name: [nameEditable, nameMandatory] = [],
		email: [emailEditable, emailMandatory] = [],
		phone: [phoneEditable, phoneMandatory] = [],
		description: [descriptionEditable, descriptionMandatory] = [],
	} = authorizedFields ?? {};

	const inputs: FormProps["inputs"] = [
		nameEditable != null && {
			disabled: !nameMandatory || loadingForm,
			icon: "corporation",
			label: ct("common:name"),
			rules: nameMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "lastName",
				onChangeValue: (n: string) => setUpdated(prev => ({...prev, name: n})),
				value: name,
			},
		}, emailEditable != null && {
			disabled: !emailEditable || loadingForm,
			icon: "mail",
			label: ct("common:email"),
			rules: filterTruthy([
				emailMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.email, type: "error"},
			]),
			type: {
				key: "email",
				onChangeValue: (m: string) => setUpdated(prev => ({...prev, email: m})),
				value: email,
			},
		}, phoneEditable != null && {
			disabled: !phoneEditable || loadingForm,
			icon: "phone",
			label: ct("common:phone"),
			rules: filterTruthy([
				phoneMandatory && {rule: Rules.notEmpty, type: "error"},
				{rule: Rules.validation.phone, type: "error"},
			]),
			type: {
				key: "phone",
				onChangeValue: (p?: string) => setUpdated(prev => ({...prev, phone: p ? removeNonPhoneNumberCharacters(p) : undefined})),
				value: phone,
			},
		}, descriptionEditable != null && {
			disabled: !descriptionEditable || loadingForm,
			icon: "about",
			label: ct("common:description"),
			rules: descriptionMandatory && [{rule: Rules.notEmpty, type: "error"}],
			type: {
				key: "text",
				onChangeValue: (desc: string) => setUpdated(prev =>
					({...prev, description: desc})),
				value: description,
			},
		},
	];

	return (
		<>
			{header}
			<Form
				hideReset
				requiredLabels
				validation={{
					buttonProps: {icon: "check", text: addOrUpdateTitle},
					onValidation: () => {
						onSelect?.(displayed);
						navigation.dispatch(forceBack);
					},
				}}
				inputs={inputs}
			/>
		</>
	);
};

export const EditCorporationModal = (props: ClientStackNavigatorScreenProps<"EditCorporationModal">): JSX.Element => (
	<ModalWrapper>
		<EditCorporation {...props} />
	</ModalWrapper>
);
