/*
 * We know for sure that date-fns is a dependency of react-datepicker, so we can use the dependency here.
 * Otherwise we would need to install date-fns but we wouldn't use it anywhere else since we already have moment
 */

/* eslint-disable import/no-extraneous-dependencies */
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import * as React from "react";
import ReactDatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {LocaleContext} from "../../contexts/locale";
import {DatePickerProps} from "./date-picker.common";

registerLocale("de-DE", de);
registerLocale("en-US", enUS);
registerLocale("fr-FR", fr);
registerLocale("it-IT", it);

/*
 * The date formats supported when writing in the date input. The first element will determine the display format.
 * These formats follow the format of date-fns. See more here: https://date-fns.org/v2.0.0-alpha.25/docs/format
 */
const DATE_FORMATS = ["/", ".", ",", "-"].flatMap(d => [`dd${d}MM${d}yyyy`, `dd${d}MM${d}yy`]);
const TIME_FORMATS = [":", "."].map(d => `HH${d}mm`);
const DATETIME_FORMATS = DATE_FORMATS.flatMap(df => TIME_FORMATS.map(tf => `${df} ${tf}`));

const doNothing = (): void => { /* do nothing */ };

export const DatePicker = ({
	date,
	minimumDate,
	maximumDate,
	mode,
	minuteInterval,
	onDateChange,
}: DatePickerProps): JSX.Element => {
	const {locale} = React.useContext(LocaleContext);
	return (
		<div>
			<ReactDatePicker
				// https://stackoverflow.com/a/68954011
				portalId="datepicker-portal"
				selected={date}
				minDate={minimumDate}
				maxDate={maximumDate}
				dateFormat={DATE_FORMATS}
				locale={locale}
				onChange={onDateChange ?? doNothing}
				{...(mode === "time" || mode === "datetime") && {
					dateFormat: mode === "time" ? TIME_FORMATS : DATETIME_FORMATS,
					showTimeInput: true,
					showTimeSelectOnly: mode === "time",
					timeIntervals: minuteInterval,
				}}
				{...(mode === "time" ? {inline: true, timeInputLabel: ""} : {})}
			/>
			<style>{styleOverride}</style>
		</div>
	);
};

const styleOverride = `
.react-datepicker--time-only {
	border: none !important;
	background: transparent !important;
}
`;
