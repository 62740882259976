/*
 * Strictly validates an url.
 * The regex comes from https://mathiasbynens.be/demo/url-regex, from @diegoperini, without `ftp`.
 */
export const URL_REGEX = new RegExp([
	"^",
	"(?:https?:\\/\\/)",
	"(?:\\S+(?::\\S*)?@)?",
	"(?:",
	"(?!10(?:\\.\\d{1,3}){3})",
	"(?!127(?:\\.\\d{1,3}){3})",
	"(?!169\\.254(?:\\.\\d{1,3}){2})",
	"(?!192\\.168(?:\\.\\d{1,3}){2})",
	"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})",
	"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])",
	"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}",
	"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|",
	"(?:(?:[a-z\u00A1-\uFFFF0-9]+-?)*[a-z\u00A1-\uFFFF0-9]+)",
	"(?:\\.(?:[a-z\u00A1-\uFFFF0-9]+-?)*[a-z\u00A1-\uFFFF0-9]+)*",
	"(?:\\.(?:[a-z\u00A1-\uFFFF]{2,}))",
	")",
	"(?::\\d{2,5})?(?:\\/[^\\s]*)?",
	"$",
].join(""));

/*
 * original email validation regex found here: https://emailregex.com
 * simplified by eslint, checked and tested manually
 */
export const EMAIL_VALIDATION = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

// Check if the phone number contain only digits and can have a + at the beginning
export const PHONE_VALIDATION = /^\+?\d+$/;

export const TIME_VALIDATION = /^([01]\d|2[0-3])([,.:])[0-5]\d$/;
