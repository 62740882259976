import * as React from "react";
import {InterpreterInboxMandatePreview} from "../@types/activities/mandate";
import {UnavailabilityUpdateState} from "../@types/activities/unavailability";
import {getInterpreterMandatesData} from "../requests/interpreters/activities/inbox";
import {
	getUnavailabilityUpdateStateData,
	sendUnavailabilityUpdateStateMessage,
} from "../requests/interpreters/activities/unavailabilities";
import {getUpcomingSessionsData} from "../requests/interpreters/activities/upcoming";
import {ENV, IS_INTERPRETER} from "../utils/constants";
import {isCurrentSession} from "../utils/date-time/helpers";
import {useWebSocket} from "../utils/hooks/use-websocket";
import {Log} from "../utils/logs/logs";
import {NotificationManager} from "../utils/notifications/manager";
import {AuthentifiedContext} from "./authentified";

export interface InboxContextVal {
	calendarUpdateReminder: boolean;
	current: InterpreterInboxMandatePreview | null;
	mandates: InterpreterInboxMandatePreview[];
	removeCalendarUpdateReminder: () => void;
	setState: React.Dispatch<React.SetStateAction<State>>;
	status: "error" | "loading" | "updated";
	unvalidated: InterpreterInboxMandatePreview[];
	upcomings: InterpreterInboxMandatePreview[];
}

interface State {
	current: InterpreterInboxMandatePreview | null;
	mandates: InterpreterInboxMandatePreview[];
	status: InboxContextVal["status"];
	unavailabilityUpdateState: UnavailabilityUpdateState;
	unvalidated: InterpreterInboxMandatePreview[];
	upcomings: InterpreterInboxMandatePreview[];
}

export const InboxContext = React.createContext<InboxContextVal>({} as InboxContextVal);
export const InboxProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
	const {accountId} = React.useContext(AuthentifiedContext);
	const [
		{
			mandates,
			current,
			upcomings,
			unvalidated,
			status,
			unavailabilityUpdateState,
		}, setState,
	] = React.useState<State>({
		current: null,
		mandates: [],
		status: ENV === "development" ? "updated" : "loading",
		unavailabilityUpdateState: "upToDate",
		unvalidated: [],
		upcomings: [],
	});
	React.useEffect( // Set the OS notification badge
		() => { NotificationManager.updateBadge(mandates.length).catch(Log.error()); },
		[mandates.length],
	);

	useWebSocket({
		connect: !!accountId && IS_INTERPRETER,
		handlers: [
			{
				dataGetter: getInterpreterMandatesData,
				onData: res => setState(prev => ({
					...prev,
					mandates: res,
					status: "updated",
				})),
			},
			{
				dataGetter: getUpcomingSessionsData,
				onData: ([first, second]) => setState(prev => {
					const next: State = {...prev, status: "updated"};
					if (first) {
						const firstIsCurrentSession = isCurrentSession(first);
						next.current = firstIsCurrentSession ? first : null;
						next.upcomings = firstIsCurrentSession
							? second
								? [second]
								: []
							: [first];
					} else {
						next.upcomings = [];
					}
					return next;
				}),
			},
		],
		onWSError: _ => setState(prev => ({...prev, status: "error"})),
	});

	const {sendJsonMessage} = useWebSocket({
		connect: !!accountId && IS_INTERPRETER,
		handlers: [
			{
				dataGetter: getUnavailabilityUpdateStateData,
				onData: res => {
					setState(prev => ({...prev, unavailabilityUpdateState: res}));
				},
			},
		],
	});

	const removeCalendarUpdateReminder = React.useCallback(
		() => sendJsonMessage(sendUnavailabilityUpdateStateMessage("upToDate")),
		[sendJsonMessage],
	);

	return (
		<InboxContext.Provider
			value={{
				calendarUpdateReminder: unavailabilityUpdateState === "needsUpdate",
				current,
				mandates,
				removeCalendarUpdateReminder,
				setState,
				status,
				unvalidated,
				upcomings,
			}}
		>
			{children}
		</InboxContext.Provider>
	);
};
