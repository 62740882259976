import * as React from "react";
import {GestureResponderEvent} from "react-native";
import {SessionContext} from "../../@types/activities/session";
import {SessionCreationContextVal} from "../../contexts/session-creation";
import {personIdentity} from "../../utils/identities";
import {ct, t} from "../../utils/locales/translations";
import {CONTRAST, PRIMARY_2} from "../../utils/styles/colors";
import {confirmation} from "../feedbacks/confirmation";
import {ContextBox} from "./context-box";

interface ContextInfoProps {
	context: SessionContext;
	initialProvider: SessionCreationContextVal["initialProvider"];
	onPressReset?: (event: GestureResponderEvent) => void;
}

export const ContextInfo = ({
	context,
	onPressReset: onPressResetProp,
	initialProvider,
}: ContextInfoProps): JSX.Element | null => {
	const onPressReset = React.useCallback(
		() => confirmation({
			actions: [
				{
					icon: "check",
					key: "confirm",
					onPress: onPressResetProp,
					text: ct("common:reset"),
				}, {
					icon: "close",
					key: "closeDrawer",
					onPress: () => null,
					text: ct("common:cancel"),
					type: "secondary",
				},
			],
			content: t("activities:sessions.context.confirmReset"),
		}),
		[onPressResetProp],
	);
	return (context.type !== "new" || !!initialProvider)
		? (
			<ContextBox
				title={t(
					`activities:sessions.context.type.${initialProvider ? "withProvider" : "withoutProvider"}.${context.type}`,
					initialProvider ? {provider: personIdentity(initialProvider)} : {},
				)}
				subtitle={context.type === "reschedule" ? t("activities:sessions.context.type.explanation") : undefined}
				picture={initialProvider?.picture ?? undefined}
				onPressReset={onPressReset}
				color={PRIMARY_2}
				backgroundColor={CONTRAST}
			/>
		)
		: null;
};
